import { makeStyles } from '@material-ui/core/styles';

export const rowHeight = 90;
export const dropTargetHeight = 10;

const useStyles = makeStyles((theme) => ({
  viewer: {
    width: '100%',
    height: 300,
    '& .cesium-widget-credits': {
      display: 'none !important',
    },
  },
  mousePos: {
    position: 'relative',
    bottom: 48,
    left: 0,
    width: 'fit-content',
    height: 48,
    backgroundColor: theme.palette.background.main + 'BF',
  },
  waypointList: {
    ...theme.mixins.noScroll,
  },
  topShadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 100,
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%)',
    pointerEvents: 'none',
    borderRadius: 10,
  },
  bottomShadow: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 100,
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 100%)',
    pointerEvents: 'none',
    borderRadius: 10,
  },
  waypointRow: {
    display: 'inline-flex',
    alignItems: 'center',
    height: rowHeight - dropTargetHeight,
  },
  inputRow: {
    width: 275,
    display: 'inline-flex',
    justifyContent: 'space-between',
    '& .MuiInputBase-root': {
      margin: 0,
    },
  },
  deleteIcon: {
    padding: 4,
  },
}));

export default useStyles;
