/* eslint no-template-curly-in-string: 0 */
import { ISelectOption } from 'components/general/types';
import { LoadVables } from 'utils/vable';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  // WARN: This error message ties to the "Source Type" field, but the code reference uses "...OutputType"
  epsOutputType: Yup.object().required('A source type is required.'),

  busRegulator: Yup.object().when('epsOutputType', {
    is: (epsOutputType: ISelectOption) =>
      epsOutputType?.value === LoadVables.EpsOutputType.BUS_REGULATOR.value,
    then: Yup.object().required('A Bus Regulator is required.'),
  }),

  dutyCycled: Yup.boolean(),

  dutyCyclePeriod: Yup.object().when('dutyCycled', {
    is: true,
    then: Yup.object({
      min: Yup.number()
        .required('A duty cycle period is required.')
        .moreThan(0, 'Duty cycle period must be greater than ${more}.'),
    }),
  }),

  dutyCyclePercentage: Yup.mixed().when('dutyCycled', {
    is: true,
    then: Yup.number()
      .required('A duty cycle percentage is required.')
      .min(0, 'Duty cycle percentage cannot be negative.')
      .max(100, 'Duty cycle percentage must not exceed ${max}%'),
  }),

  type: Yup.object().required('A load type is required.'),

  loadDefParams: Yup.object()
    .when('type', {
      is: (type: ISelectOption) => type?.value === LoadVables.Type.PowerLoad.value,
      then: Yup.object({
        power: Yup.number()
          .required('Power is required.')
          .moreThan(0, 'Power must be greater than 0.'),
      }),
    })
    .when('type', {
      is: (type: ISelectOption) => type?.value === LoadVables.Type.ResistanceLoad.value,
      then: Yup.object({
        resistance: Yup.number()
          .required('Resistance is required.')
          .moreThan(0, 'Resistance must be greater than 0.'),
      }),
    }),
});

export default validation;
