import { IModelBlock } from 'components/general/types';
import { useCallback } from 'react';
import useActiveEntities from './useActiveEntities';

/**
 * Provides a function that returns the list of superclasses in the entire hierarchy for the given block or block type
 * Implicitly subscribes the component to the ActiveBranchProvider
 */
const useBlockSupers = () => {
  const { model } = useActiveEntities();

  const getBlockSupers = useCallback(
    (block?: IModelBlock | string) => {
      if (!block || !model) return [];

      const supers = model._supers;

      const result = new Set();

      const recurse = (blockType: IModelBlock['type']) => {
        result.add(blockType);
        supers[blockType].forEach((superBlock) => {
          recurse(superBlock);
        });
      };

      recurse(typeof block === 'string' ? block : block.type);

      return Array.from(result) as string[];
    },
    [model]
  );

  return getBlockSupers;
};

export default useBlockSupers;
