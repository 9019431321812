import _ from 'lodash';
import { binarySearch } from 'utils/arrays';

// For detailed documentation, see https://gitlab.sedaro.com/sedaro-satellite/common/-/wikis/SeriesData

export const SeriesDataEvents = {
  CONCAT: 'CONCAT',
  REPLACE: 'REPLACE',
};

/**
 * Initialize a new SeriesData structure. Mainly due to React/Redux considerations, this is a simple javascript object.
 * It will need to be passed to each function below when invoked.
 * @return {Object} SeriesData instance
 */
export const initSeriesData = () => ({
  streams: {},
  columnar: {},
  responsibility: {},
  hierarchical: {},
  kerf: {
    hierarchy: {},
    leaves: {},
  },
  startTimes: {},
  stopTimes: {},
  subscribers: {},
});

/**
 * Return the start timestamp for the SeriesData structure. An object is returned of the following
 * form, where common is the earliest timestamp that is shared by all streams and absolute is the timestamp that is most
 * earliest.
 * @param  {Object} seriesData SeriesData instance
 * @return {Object} common and absolute start timestamps
 */
export const getSeriesDataStartTimestamp = (seriesData) => {
  const values = Object.values(seriesData.startTimes);
  if (values.length === 0) return { common: null, absolute: null };
  return {
    common: Math.max(...values),
    absolute: Math.min(...values),
  };
};

/**
 * Return the stop timestamp for the SeriesData structure. An object is returned of the following
 * form, where common is the latest timestamp that is shared by all streams and absolute is the timestamp that is most
 * latest.
 * @param  {Object} seriesData SeriesData instance
 * @return {Object} common and absolute stop timestamps
 */
export const getSeriesDataStopTimestamp = (seriesData) => {
  const values = Object.values(seriesData.stopTimes);
  if (values.length === 0) return { common: null, absolute: null };
  return {
    common: Math.min(...values),
    absolute: Math.max(...values),
  };
};

/**
 * Return the metadata for the Stream that contains the passed column (dot-delimited) key (without the streamId).
 * @param  {Object} seriesData SeriesData instance
 * @param  {String} streamlessColumnKey the dot-delimited key to lookup, without the streamId
 * @throws {Error} if the key is not found or if the parent key spans more than 1 Stream
 * @return {Object} Stream metadata
 */
export const getResponsibleStream = (seriesData, streamlessColumnKey) => {
  let stream = seriesData.responsibility[streamlessColumnKey];
  if (!stream) {
    const subset = _.get(seriesData.hierarchical, streamlessColumnKey);
    if (!subset) throw new Error('Stream not found for key ' + streamlessColumnKey);
    const streamIds = new Set();
    for (const key in flatten(subset)) {
      streamIds.add(seriesData.responsibility[streamlessColumnKey + '.' + key].id);
    }
    if (streamIds.size > 1)
      throw new Error('Series data parent key ' + streamlessColumnKey + ' spans multiple streams');
    if (streamIds.size < 1)
      throw new Error(
        'This should never happen. Talk to Bas if it does. It would indicate that empty parent keys exist in seriesData'
      );
    stream = seriesData.streams[streamIds.values().next().value];
  }
  return stream;
};

/**
 * Returns an Array of Series for the passed column (dot-delimited) keys (without the streamId).
 * @param  {Object} seriesData SeriesData instance
 * @param  {String} streamlessColumnKey the dot-delimited key to lookup, without the streamId
 * @throws {Error} if the key is not found or if the parent key spans more than 1 Stream
 * @return {Array} List of Series
 */
export const getSeriesForKeys = (seriesData, streamlessColumnKeys) => {
  const streamIds = new Set();
  for (const key of streamlessColumnKeys) {
    const stream = getResponsibleStream(seriesData, key);
    if (!stream) throw Error(`State variable ${key} not found.`);
    streamIds.add(stream.id);
  }
  if (streamIds.size > 1) {
    throw Error(
      `State variables ${streamlessColumnKeys.join(
        ', '
      )} are not contained within the same stream and therefore do not share a time axis.`
    );
  }
  const streamId = streamIds.values().next().value;
  return ['time', ...streamlessColumnKeys].map((key) => {
    const series = seriesData.columnar[streamId][key];
    if (!series) return _.get(seriesData.hierarchical, key);
    return series;
  });
};

/**
 * Warning: For performance reasons, this function mutates all previously sliced Kerfs. There is only a single Kerf per SeriesData "instance".
 * Populate a Kerf for a given timestamp. If an Agent ID is provided, only the Kerf for that Agent will be populated and returned. If no Agent ID is provided, the Kerf will be populated for all Agents.
 * @param  {Object} seriesData SeriesData instance
 * @param  {Number} timestamp Timestamp to slice by
 * @param  {String} agentId Agent ID to filter by (optional)
 * @return {Object} Populated Kerf
 */
export const sliceSeriesDataByTimestamp = (seriesData, timestamp, agentId) => {
  // FUTURE: consider adding caching later of prior slices.  If timestamp doesn't result in a new columnIndex for a
  // given stream, data is unchanged for all keys in that stream.

  // Clone kerf
  // Disabled for now due to performance reasons
  // const kerf = _.cloneDeep(seriesData.kerf); // Existing inefficiency - we clone the entire kerf, even if we only need a single agent
  const kerf = seriesData.kerf;

  // Populate it by iterating over leaves for agent(s), slicing columns, and populating cloned kerf
  const agentIds = agentId ? [agentId] : Object.keys(kerf.leaves);
  const columnIndexCache = {};
  for (const agentId of agentIds) {
    for (const key in kerf.leaves[agentId]) {
      const streamId = seriesData.responsibility[key].id;
      if (!columnIndexCache[streamId]) {
        columnIndexCache[streamId] = binarySearch(seriesData.columnar[streamId].time, timestamp, {
          fuzzy: true,
        });
      }
      const leaf = kerf.leaves[agentId][key];
      if (columnIndexCache[streamId] < 0) {
        // Not found
        leaf.p[leaf.r] = null; // Leave values as null for now, consider following exception later
        // throw new Error('Timestamp ' + timestamp + ' does not exist in stream ' + streamId);
      } else {
        leaf.p[leaf.r] = seriesData.columnar[streamId][key][columnIndexCache[streamId]];
      }
    }
  }

  if (!agentId) return kerf.hierarchy;
  return { [agentId]: kerf.hierarchy[agentId] };
};

// Convert nested objects to an object of depth == 1 with dot delim'd keys
// Stops at arrays
const flatten = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;
    // Don't flatten beyond Array of primitives (these are leaves (i.e. columns))
    if (
      (Array.isArray(value) && value[0] && typeof value[0] === 'object') ||
      (!Array.isArray(value) && typeof value === 'object')
    ) {
      return { ...acc, ...flatten(value, newKey) };
    }
    return { ...acc, [newKey]: value };
  }, {});
};

/**
 * Deprecated. Use upsertSeriesData instead.
 */
export const concatSeriesData = (...args) => {
  console.warn('concatSeriesData is deprecated. Use upsertSeriesData instead.')
  return upsertSeriesData(...args);
}

/**
 * Insert/Update Data Service series data into the SeriesData "instance". This function makes the following assumptions about incoming data:
 *   - Each series is locally sequential
 *   - Future series for an existing stream are continuous (i.e. no gaps)
 * See https://gitlab.sedaro.com/sedaro-satellite/common/-/wikis/SeriesData#concatseriesdata for additional details
 * @param  {Object} seriesData SeriesData instance
 * @param  {Object} incomingSeries Series data to concatenate
 * @param  {Object} config Configuration object
 */
export const upsertSeriesData = (seriesData, incomingSeries, config = {}) => {
  for (const streamId in incomingSeries) {
    let [time, data] = incomingSeries[streamId];
    // console.log('Incoming Data', data);

    if (!seriesData.startTimes[streamId] || seriesData.startTimes[streamId] > time[0])
      seriesData.startTimes[streamId] = time[0];
    if (!seriesData.stopTimes[streamId] || seriesData.stopTimes[streamId] < time[time.length - 1])
      seriesData.stopTimes[streamId] = time[time.length - 1];

    // Init stream if it doesn't exist
    if (!seriesData.streams[streamId]) {
      seriesData.streams[streamId] = { length: 0, id: streamId };
      seriesData.columnar[streamId] = { time: [] };
    }
    const streamsColumns = seriesData.columnar[streamId];

    // Flatten for easy handling
    const flatData = flatten(data);

    const incomingStartTime = time[0];
    const incomingStopTime = time[time.length - 1];
    let event = SeriesDataEvents.REPLACE;
    if (
      streamsColumns.time.length === 0 ||
      incomingStartTime > streamsColumns.time[streamsColumns.time.length - 1]
    ) {
      event = SeriesDataEvents.CONCAT;
    }

    let startIndex, endIndex;
    if (event === SeriesDataEvents.REPLACE) {
      startIndex = binarySearch(streamsColumns.time, incomingStartTime, { fuzzy: true }); // Replacement starts at this index, inclusive
      endIndex = binarySearch(streamsColumns.time, incomingStopTime, { fuzzy: true }); // Replacements ends at this index, exclusive
      if (streamsColumns.time[startIndex] !== incomingStartTime) startIndex++;
      endIndex++;
    }

    // Add new keys to seriesData
    const streamLength = seriesData.streams[streamId].length;
    for (const key in flatData) {
      // {} -> {a: []} // delete key containing parent key
      // {a: []} -> {} // pad a: this will happen during length reconciliation

      // If key doesn't already exist, pad with nulls up to pre-concat length
      if (!streamsColumns[key]) {
        streamsColumns[key] = new Array(streamLength).fill(null);
        seriesData.responsibility[key] = seriesData.streams[streamId];
      }
      // Concat/Replace
      if (event === SeriesDataEvents.CONCAT) {
        streamsColumns[key] = streamsColumns[key].concat(flatData[key]);
      } else {
        streamsColumns[key] = _replace(streamsColumns[key], flatData[key], startIndex, endIndex);
      }
    }

    // Concat/Replace time
    if (event === SeriesDataEvents.CONCAT) {
      streamsColumns.time = streamsColumns.time.concat(time);
    } else {
      streamsColumns.time = _replace(streamsColumns.time, time, startIndex, endIndex);
    }

    // Remove invalid parent series columns
    // This is expected to be faster than avoiding creating them in the first place
    const alphabetizedKeys = Object.keys(streamsColumns).sort();
    for (let i = 0; i < alphabetizedKeys.length - 1; i++) {
      const key = alphabetizedKeys[i];
      if (alphabetizedKeys[i + 1].startsWith(key + '.')) {
        delete streamsColumns[key];
        const key0 = key.split('.')[0];
        if (seriesData.kerf.leaves[key0] && seriesData.kerf.leaves[key0][key])
          delete seriesData.kerf.leaves[key0][key];
        if (seriesData.responsibility[key]) delete seriesData.responsibility[key];
      }
    }

    // Reconcile length of any series in stream but not in flatData
    // i.e. added during a previous concat
    for (const key in streamsColumns) {
      if (streamsColumns[key].length === streamLength && streamsColumns.time.length !== streamLength) { // If stream length is changed by event and columns are still the old length, pad with nulls
        if (event === SeriesDataEvents.CONCAT) {
          streamsColumns[key] = streamsColumns[key].concat(new Array(time.length).fill(null));
        } else {
          streamsColumns[key] = _replace(streamsColumns[key], new Array(time.length).fill(null), startIndex, endIndex);
        }
      }
    }

    // Update pointers in hierarchical format
    // Also update kerf
    // Do this after all the reconciliation above to avoid potential errors regarding _.set and dot-delimited keys
    for (const key in streamsColumns) {
      // This could be sped up with caching of hierarchy "tiers"
      if (key !== 'time') {
        const keys = key.split('.');
        const key0 = keys[0];
        const keyN = keys[keys.length - 1];

        _.setWith(seriesData.hierarchical, key, streamsColumns[key], Object);

        // Avoid unnecessary work
        if (!seriesData.kerf.leaves[key0]) seriesData.kerf.leaves[key0] = {};
        if (!seriesData.kerf.leaves[key0][key]) {
          const pr = { r: keyN };
          seriesData.kerf.leaves[key0][key] = pr;
          _.setWith(seriesData.kerf.hierarchy, key, null, (nsValue, _key, nsObject) => {
            if (!nsValue) {
              pr.p = Object();
              return pr.p;
            } else {
              pr.p = nsValue;
            }
          });
        }
      }
    }

    // Update stream meta data
    seriesData.streams[streamId].length = streamsColumns.time.length;

    // Call subscribers
    if (seriesData.subscribers[streamId]) {
      for (const { streamlessColumnKeys, callback } of seriesData.subscribers[streamId]) {
        callback(
          event,
          Series([
            time,
            ...streamlessColumnKeys.map((k) => flatData[k] || new Array(time.length).fill(null)), // Fill missing series with nulls
          ])
        );
      }
    }
  }
};

/**
 * Subscribe to stream events. The callback will be invoked with the following arguments:
 *  - event: SeriesDataEvents.CONCAT or SeriesDataEvents.REPLACE
 *  - series: Series instance
 * @param  {Object} seriesData SeriesData instance
 * @param  {String} streamId Stream ID to subscribe to
 * @param  {Array} streamlessColumnKeys List of dot-delimited keys to subscribe to, without the streamId
 * @param  {Function} callback Callback function
 */
export const subscribeToStream = (seriesData, streamId, streamlessColumnKeys, callback) => {
  if (!seriesData.subscribers[streamId]) seriesData.subscribers[streamId] = [];
  seriesData.subscribers[streamId].push({ streamlessColumnKeys, callback });
};

export const _sliceHierarchy = (h, i) => {
  return Object.keys(h).reduce((acc, key) => {
    if (Array.isArray(h[key])) {
      acc[key] = h[key][i];
    } else {
      acc[key] = _sliceHierarchy(h[key], i);
    }
    return acc;
  }, {});
};

export function* makeSeriesTransposeIterator(series) {
  for (let i = 0; i < series[0].length; i++) {
    yield series.map((s) => {
      if (Array.isArray(s)) {
        return s[i];
      }
      return _sliceHierarchy(s, i);
    });
  }
}

export const Series = (series) => ({
  series,
  transposeSeries: () => makeSeriesTransposeIterator(series),
});

const _replace = (existingSeries, incomingSeries, startIndex, endIndex) => {
  return existingSeries
          .slice(0, startIndex)
          .concat(incomingSeries)
          .concat(existingSeries.slice(endIndex));
}

/*
 * Note: We will need to update stats module to work with new series data features
 */
