import { IGenericObject, IMenu } from 'components/general/types';
import { CHART_SPEC, ITEMS, SUB_ITEMS, TITLE, WIDGETS } from 'config';
import Routes from 'routes';

const AgentAnalyzeMenu = (activeEntities?: IGenericObject) => {
  const menuItems = activeEntities?.model.Menu.first()?.menuItems;
  const subItems = new Set<string>();
  const menu: IGenericObject = {};
  const playbackItems: IGenericObject = {};

  const createMenuItem = (item: IGenericObject, keys: string) => {
    const menuOptions: IGenericObject = {};
    if (item.playback) {
      playbackItems[keys] = { playback: item.playback, chartspec: item.widgetSpecs };
    } else {
      menuOptions[CHART_SPEC] = item.widgetSpecs;
    }
    if (item.menuItems?.length > 0) {
      const subItems: IGenericObject = {};
      item.menuItems.forEach(
        (x: IGenericObject) => (subItems[x.id] = createMenuItem(x, keys + '.' + x.id))
      );
      menuOptions[SUB_ITEMS] = subItems;
    }
    return {
      [TITLE]: item.title,
      [WIDGETS]: item.playback || item.widgetSpecs?.length ? true : false,
      ...menuOptions,
    };
  };

  menuItems?.forEach((item: IGenericObject) => {
    if (item.menuItems?.length > 0) {
      item.menuItems.forEach((x: IGenericObject) => subItems.add(x.id));
    }
  });

  menuItems?.forEach((item: IGenericObject, i: number) => {
    if (!subItems.has(item.id)) {
      menu[item.id] = createMenuItem(item, item.id);
    }
  });

  return {
    menu: {
      routeFn: Routes.AGENT_ANALYZE,
      dynamicItems: [],
      [ITEMS]: {
        ...menu,
      },
    } as IMenu,
    playbackItems: playbackItems,
  };
};

export default AgentAnalyzeMenu;
