import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import clsx from 'clsx';
import { FallbackInlay } from 'components/general/ViewPortInlay';
import Widget from 'components/general/widgets/Widget';
import { MomentContext } from 'providers';
import { Fragment, useContext, useMemo, useState } from 'react';
import useStyles from './styles';

const ConOpsLogicPlaybackWidget = (props) => {
  const { className } = props;
  const { model } = useContext(MomentContext);

  const operationalModes = useMemo(
    () => [...model.OperationalMode.all()].sort((a, b) => b.priority - a.priority),
    [model]
  );

  const [open, setOpen] = useState([]);
  const classes = useStyles();

  const handleClick = (index) => {
    const newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };

  return (
    <Widget
      maxWidth={400}
      minWidth={250}
      className={clsx(className, classes.root)}
      title="Condition Compliance"
      subtitle="In Priority Order"
      collapsibleConfig
    >
      {
        // TODO: This check is temporary until we get routines working
        operationalModes.length ? (
          <List disablePadding>
            {operationalModes.map((opMode, i) => {
              return (
                <Fragment key={opMode.name}>
                  <ListItem
                    button={opMode.conditions.length > 0}
                    onClick={() => handleClick(i)}
                    dense
                  >
                    <ListItemIcon className={classes.opModeIcon}>
                      {opMode.compliance ? (
                        <CheckCircleIcon className={classes.activeIcon} />
                      ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.opModeName }}
                      primary={opMode.name}
                      secondary={`${opMode.conditions.length} Condition${
                        opMode.conditions.length !== 1 ? 's' : ''
                      }`}
                    />
                    {opMode.conditions.length > 0 && (open[i] ? <ExpandLess /> : <ExpandMore />)}
                  </ListItem>
                  <Collapse in={open[i]} timeout="auto" unmountOnExit>
                    <List className={classes.conditionsList} disablePadding>
                      {opMode.conditions.map((condition, j) => (
                        <Fragment key={condition.name}>
                          <ListItem dense>
                            <ListItemIcon className={classes.conditionIcon}>
                              {condition.compliance ? (
                                <CheckCircleIcon className={classes.activeIcon} />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              classes={{ primary: classes.conditionName }}
                              primary={condition.name}
                            />
                          </ListItem>
                        </Fragment>
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              );
            })}
          </List>
        ) : (
          <FallbackInlay
            text="This chart is under construction."
            subText="Our team is working to restore this feature."
          />
        )
      }
    </Widget>
  );
};

export default ConOpsLogicPlaybackWidget;
