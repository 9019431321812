import { wGroupIndicesATCDH } from 'components/AgentTemplateEditView/menu/cdh';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import useTableStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityDialogControl } from 'hooks';
import { useEffect, useState } from 'react';
import { ConditionVables } from 'utils/vable';
import ConditionDialogSwitch from './specializedDialogs/ConditionDialogSwitch';

const columns = [
  {
    title: 'Name',
    field: 'name',
  },
];

/**
 * A dropdown list allowing users to create new conditions using new dialogs,
 * avoiding the non-scalable complexity of putting all condition types in one dialog.
 */
const AdditionalConditions = () => {
  const dialogControl = useEntityDialogControl();
  const { openDialogForNew } = dialogControl;
  const [conditionType, setConditionType] = useState(null);
  const classes = useTableStyles();

  useEffect(() => {
    if (dialogControl.dialogConfig.open === false) {
      setConditionType(null);
    }
  }, [dialogControl.dialogConfig.open]);

  return (
    <>
      <LabeledSelect
        options={ConditionVables.NewConditionTypes.options}
        label={'Looking for new condition types? Try out:'}
        onChange={(option) => {
          setConditionType(option.value);
          openDialogForNew();
        }}
        className={classes.table}
      />
      {conditionType && dialogControl && dialogControl.dialogConfig.open && (
        <ConditionDialogSwitch control={dialogControl} type={conditionType} />
      )}
    </>
  );
};

const ConditionsSegment = () => {
  const { conditions } = useActiveEntities();

  return (
    <EntityTableSegment
      title="Conditions"
      index={wGroupIndicesATCDH.CONDITIONS}
      guidance={{
        heading: 'Create and Edit Conditions',
        body: [
          {
            chunk:
              'Conditions are assigned to operational modes to drive ConOps logic. For example, you can use a condition to require line of sight to your groundstation for entry into downlink operational mode.',
          },
          {
            subHeading: 'Link to Operational Modes',
            chunk:
              'Each operational mode in your ConOps can be assigned one or more conditions. For any non-default operational mode to be the active mode, all of its conditions at that time step must be satisfied.',
          },
        ],
      }}
      entityColumns={columns}
      entityData={conditions}
      DialogComponent={ConditionDialogSwitch}
      modelName="conditions"
      suffix={<AdditionalConditions />}
    ></EntityTableSegment>
  );
};

export default ConditionsSegment;
