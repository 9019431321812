import LoadingView from 'components/LoadingView';
import Nav from 'components/general/Nav';
import SimulationControls from 'components/general/SimulationControls';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import { useActiveEntities } from 'hooks';
import mdLogo from 'multimedia/icons/md.png';
import { JoyrideContext } from 'providers';
import { useContext } from 'react';
import EditBoard from './EditBoard';

const ScenarioEditView = () => {
  const { branch } = useActiveEntities();
  const joyride = useContext(JoyrideContext);
  joyride.setLoading(!branch.model?.ready);
  if (!branch.model?.ready) return <LoadingView message="Sedaro is loading..." />;

  return (
    <ViewContainer>
      <Nav contextName="Scenario - Edit" contextImg={mdLogo} control={<SimulationControls />}></Nav>
      <ViewPort minWidth={650}>
        <EditBoard />
      </ViewPort>
    </ViewContainer>
  );
};

export default ScenarioEditView;
