import { makeStyles } from '@material-ui/core/styles';
import theme, { borderRadius } from 'theme';

export const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.mainNested,
    borderRadius,
    padding: '1rem',
  },
  slider: {
    width: '90%',
    alignSelf: 'center',
  },
  sliderTitleFirst: {
    marginBlockStart: '0',
    marginBlockEnd: '0.5rem',
  },
  sliderTitle: {
    marginBlockStart: '0.75rem',
    marginBlockEnd: '0.5rem',
  },
}));

// Outer node wrapper handled by ReactFlow
export const defaultNodeStyles = {
  borderRadius: borderRadius,
  borderStyle: 'solid',
  borderColor: theme.palette.background.darkest,
  borderWidth: '1.5px',
  width: 200,
  height: 88,
  display: 'flex',
  flexDirection: 'column',
};

export const useStylesNode = makeStyles((theme) => ({
  nodeWrapper: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.dark,
    borderStyle: 'solid',
    borderColor: theme.palette.background.dark,
    borderWidth: '5px',
    width: '100%',
    borderRadius: borderRadius,
  },

  newNodeWrapper: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.dark,
    borderStyle: 'solid',
    borderColor: theme.palette.background.dark,
    borderWidth: '5px',
    width: '100%',
    borderRadius: borderRadius,
    animation: `$myEffect 3000ms`,
  },

  labelIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    padding: '5px 5px 0px',
    width: '100%',
    // height: '100%',
  },
  iconWrapper: {
    width: 40,
    color: 'steelblue',
    paddingTop: 3,
    height: 36,
  },
  labelWrapper: {
    display: 'inline-block !important',
    width: 160,
    height: 36,
  },
  componentLabel: {
    color: theme.palette.text.primary,
    paddingTop: 5,
    fontSize: 14,
    lineHeight: '14px',
    letterSpacing: '-0.5px', // squish letters closer together
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subsystemLabel: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: '14px',
    letterSpacing: '-0.5px', // squish letters closer together
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  detailWrapper: {
    backgroundColor: theme.palette.background.light,
    margin: 5,
    padding: 3,
    borderRadius: borderRadius,
  },

  temp: {
    margin: 'auto',
    color: theme.palette.text.primary,
    width: '100%',
    textAlign: 'center',
  },
  childWrapper: {
    backgroundColor: theme.palette.background.lightest,
  },
  child: {
    margin: 0,
    color: theme.palette.text.primary,
    textAlign: 'center',
  },

  '@keyframes myEffect': {
    '0%': {
      borderColor: theme.palette.background.dark,
    },
    '10%': {
      borderColor: theme.palette.background.dark,
    },
    '20%': {
      borderColor: 'yellow',
    },
    '30%': {
      borderColor: theme.palette.background.dark,
    },
    '40%': {
      borderColor: 'yellow',
    },
    '50%': {
      borderColor: theme.palette.background.dark,
    },
    '60%': {
      borderColor: 'yellow',
    },
    '70%': {
      borderColor: theme.palette.background.dark,
    },
    '80%': {
      borderColor: 'yellow',
    },
    '90%': {
      borderColor: theme.palette.background.dark,
    },
    '100%': {
      borderColor: theme.palette.background.dark,
    },
  },
}));

export const useStylesToolTipTitle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      margin: 0,
      padding: 0,
    },
  },
});

export const useStylesInterface = makeStyles({
  layoutControlsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
  },
  layoutSelectWrapper: {
    width: '100%',
    maxWidth: '400px !important',
    marginLeft: 'auto',
  },
  layoutSelect: {
    width: '100% !important',
  },
  buttonsWrapper: {
    alignSelf: 'flex-end',
    paddingBottom: 3,
  },
  button: {
    position: 'relative',
    display: 'inline-block',
  },
  buttonDirty: {
    position: 'relative',
    display: 'inline-block',
    color: 'red',
  },
  layoutNameInput: {
    width: '400px !important',
  },
});
