import { IGenericObject } from 'components/general/types';
import { makeGuidance } from 'hooks';
const algorithmHeading =
  'Create and Edit Algorithms for: Attitude Control, Attitude Determination, and Orbit Determination.';

const algorithmMainParagraph =
  'Algorithms represent the components of on-board decision making for Guidance, Navigation, and Control. Each Algorithm will only run in certain, user-defined operational modes dictated by the active Pointing Mode. Use the Algorithms table to add or edit Algorithms.';

const pidChunk =
  'A basic linear control method. This method relies on Reaction Wheels as the primary actuator with Magnetorquers as an optional secondary for continuous desaturation of the wheels.';
const slideChunk =
  'A nonlinear attitude control method. This method relies on Reaction Wheels as the primary actuator with Magnetorquers as an optional secondary for continuous desaturation of the wheels.';
const averageChunk =
  'A simple sensor averaging algorithm that requires at least one attitude and one angular rate sensor. This algorithm only considers the current sensor estimates and does not account for satellite dynamics.';
const MEKFChunk =
  'A Multiplicative Extended Kalman Filter that fuses input from any number of sensors into a single attitude estimate. The filter factors in the expected attitude dynamics of the satellite to predict future states and corrects those predictions based on sensor measurements. At least one attitude sensor is required.';
const triadChunk =
  'The Triad algorithm produces attitude estimates based on the input from two or more direction sensors. Associated angular rate estimates are derived by averaging measurements from angular rate sensors.';
const EKFChunk =
  'An Extended Kalman Filter that fuses input from any number of sensors into a single position and velocity estimate. The filter factors in the expected orbital dynamics of the satellite to preduct future states and corrects those predictions based on sensor measurements. At least one sensor is required.';
const GPSChunk =
  'A simple estimation method based on the output of a single GPS unit. Position estimates are derived directly from the sensor and velocity measurements are estimated from two sequential measurements.';
const GenericAdChunk =
  'A generic attitude determination algorithm that does not have associated sensors but has user-defined error.'
const GenericOdChunk =
  'A generic orbit determination algorithm that does not have associated sensors but has user-defined error.'
const InitializerChunk =
  'An initializer is used to produce an initial estimate for another estimation algorithm. Initializers only run at the beginning of the simulation and when a filter that references it diverges.'
const DirectMeasurementAttitudeInitializerChunk =
  'A coarse initial attitude determination algorithm that relies on direct attitude and angular velocity measurements to produce a solution. The initializer estimates an attitude and angular velocity by averaging measurements from all available sensors, weighted by the inverse of the assumed variance of that sensor.';
const TriadAttitudeInitializerChunk =
  'A coarse initial attitude determination algorithm that relies on vector observations and direct angular velocity measurements to produce a solution. The initializer estimates an attitude using the Triad algorithm to solve Wahba\'s problem and estimates an angular velocity by averaging measurements from all available angular velocity sensors.'
const StaticAttitudeInitializerChunk =
  'A simple attitude initialization algorithm that assumes a constant initial attitude, angular velocity, and uncertainty.'
const FiniteDifferenceOrbitInitializerChunk =
  'A coarse initial orbit determination algorithm that relies on direct position measurements to produce a solution.'

const algoSubTypeGuidance = {
  SlidingModeAlgorithm: {
    heading: 'Sliding Mode Control',
    body: [
      {
        chunk: slideChunk,
      },
      {
        subHeading: 'Gains',
        chunk:
          'This controller requires tuning specific to the spacecraft properties. Four parameters must be configured here:',
      },
      {
        chunk:
          'K - alters the relative weighting between angular rate and angle error, higher values give more weight to angle error.',
      },
      {
        chunk:
          'G - scales the overall speed of convergence. Higher values will converge faster but may saturate the actuators.',
      },
      {
        chunk: 'C - dictates the strength of the secondary actuator desaturation torques.',
      },
      {
        chunk:
          'Epsilon - defines a neutral zone for the controller near the desired attitude. Larger values produce more error, but reduce chattering and power use.',
      },
      {
        subHeading: 'Actuators',
        chunk:
          'Select any number of actuators from the list for use by the controller using the actuator table. Unselected actuators will remain idle. It`s important to select a set of actuators that provide full control authority for the satellite or else the controller may fail to achieve the desired pointing.',
      },
    ],
  },
  PidAlgorithm: {
    heading: 'Proportional-Integral-Derivative Control',
    body: [
      {
        chunk: pidChunk,
      },
      {
        subHeading: 'Gains',
        chunk:
          'This controller requires tuning specific to the spacecraft properties. Four parameters must be configured here:',
      },
      {
        chunk: 'P - weighting applied to the multiplicative quaternion error.',
      },
      {
        chunk: 'I - weighting applied to the integral of the multiplicative quaternion error.',
      },
      {
        chunk: 'D - weighting applied to the angular rate error.',
      },
      {
        chunk: 'C - dictates the strength of the secondary actuator desaturation torques.',
      },
      {
        subHeading: 'Actuators',
        chunk:
          'Select any number of actuators from the list for use by the controller using the actuator table. Unselected actuators will remain idle. It`s important to select a set of actuators that provide full control authority for the satellite or else the controller may fail to achieve the desired pointing.',
      },
    ],
  },
  AveragingAlgorithm: {
    heading: 'Averaging Algorithm',
    body: [
      {
        chunk: averageChunk,
      },
      {
        subHeading: 'Sensors',
        chunk:
          'Assign sensors to this algorithm using the sensor table. The Averaging Algorithm requires at least one angular velocity and one attitude sensor to produce an estimate.',
      },
    ],
  },
  MekfAlgorithm: {
    heading: 'MEKF Algorithm',
    body: [
      {
        chunk: MEKFChunk,
      },
      {
        chunk: 'Optionally defined the assumed standard deviation of the prediction process noise. If undefined, a reasonable default will be assumed.',
      },
      {
        subHeading: 'Sensors',
        chunk: 'Specify one or more sensors and an assumed 1-sigma uncertainty for each. At least one attitude, vector, or direction sensor is required.',
      },
    ],
  },
  TriadAlgorithm: {
    heading: 'Triad Algorithm',
    body: [
      {
        chunk: triadChunk,
      },
      {
        subHeading: 'Sensors',
        chunk:
          'Assign sensors to this algorithm using the sensor table. The Triad Algorithm requires at least two direction sensors and one angular velocity sensor to produce an estimate.',
      },
    ],
  },
  EkfAlgorithm: {
    heading: 'EKF Algorithm',
    body: [
      {
        chunk: EKFChunk,
      },
      {
        chunk: 'Optionally defined the assumed standard deviation of the prediction process noise. If undefined, a reasonable default will be assumed.',
      },
      {
        subHeading: 'Sensors',
        chunk:
          'Specify one or more position sensors and an assumed 1-sigma uncertainty for each.',
      },
    ],
  },
  GpsAlgorithm: {
    heading: 'GPS Direct',
    body: [
      {
        chunk: GPSChunk,
      },
      {
        subHeading: 'GPS Sensor',
        chunk: 'Select the GPS sensor this algorithm will use.',
      },
    ],
  },
  StaticThrustControlAlgorithm: {
    heading: 'Static Thrust Control',
    body: [
      {
        subHeading: 'Static',
        chunk:
          'The static thrust control algorithm directly assigns thrust values to the associated thrusters as part of the algorithm definition. Assign the thrust values and add this Thrust Control Algorithm to a Pointing Mode to perform the specified burn.\nThis is currently the only available Thrust Control Algorithm, but more are coming soon.',
      },
    ],
  },
  GenericAdAlgorithm: {
    heading: 'Generic Attitude Determination',
    body: [
      {
        chunk: GenericAdChunk,
      },
      {
        subHeading: 'One Sigma Errors',
        chunk: 'Specify the one sigma errors applied to the attitude and angular velocity estimates.',
      },
      {
        chunk: 'One Sigma Yaw Error - one sigma error applied to the yaw (Z body frame) axis of the attitude estimate'
      },
      {
        chunk: 'One Sigma Pitch Error - one sigma error applied to the pitch (Y body frame) axis of the attitude estimate'
      },
      {
        chunk: 'One Sigma Roll Error - one sigma error applied to the roll (X body frame) axis of the attitude estimate'
      },
      {
        chunk: 'One Sigma Angular Velocity Error - one sigma error applied to all body frame axes of the angular velocity estimate'
      },
    ],
  },
  GenericOdAlgorithm: {
    heading: 'Generic Orbit Determination',
    body: [
      {
        chunk: GenericOdChunk,
      },
      {
        subHeading: 'One Sigma Errors',
        chunk: 'Specify the one sigma errors applied to the position and velocity estimates.',
      },
      {
        chunk: 'One Sigma Position Error - one sigma error applied to all axes of the position estimate'
      },
      {
        chunk: 'One Sigma Velocity Error - one sigma error applied to all axes of the velocity estimate'
      },
    ],
  },
  DirectMeasurementAttitudeInitializer: {
    heading: 'Direct Measurment Attitude Initializer',
    body: [
      {
        chunk: DirectMeasurementAttitudeInitializerChunk,
      },
      {
        chunk: InitializerChunk,
      },
      {
        subHeading: 'Angular Velocity Sensors',
        chunk: 'Specify zero or more angular velocity sensors and an assumed 1-sigma uncertanity for each.',
      },
      {
        chunk: 'If no angular velocity sensor measurements are available, the initializer will assume an initial angular velocity of 0 with the an initial uncertainty equal to the Default Angular Velocity Sigma in all directions.'
      },
      {
        subHeading: 'Attitude Sensors',
        chunk: 'Specify one or more attitude sensors and an assumed 1-sigma angle uncertainty for each.',
      },
    ],
  },
  TriadAttitudeInitializer: {
    heading: 'Triad Attitude Initializer',
    body: [
      {
        chunk: TriadAttitudeInitializerChunk,
      },
      {
        chunk: InitializerChunk,
      },
      {
        subHeading: 'Angular Velocity Sensors',
        chunk: 'Specify zero or more angular velocity sensors and an assumed 1-sigma uncertanity for each.',
      },
      {
        chunk: 'If no angular velocity sensor measurements are available, the initializer will assume an initial angular velocity of 0 with the an initial uncertainty equal to the Default Angular Velocity Sigma in all directions.'
      },
      {
        subHeading: 'Attitude Sensors',
        chunk: 'Specify one or more vector or direction sensors and an assumed 1-sigma angle uncertainty for each.',
      },
    ],
  },
  StaticAttitudeInitializer: {
    heading: 'Static Attitude Initializer',
    body: [
      {
        chunk: StaticAttitudeInitializerChunk,
      },
      {
        chunk: InitializerChunk,
      },
      {
        chunk: 'Optionally specify an initial attitude quaternion, angular velocity, and 1-sigma uncertainties. Reasonable default values are provided for all fields.'
      },
    ],
  },
  FiniteDifferenceOrbitInitializer: {
    heading: 'Finite Difference Orbit Initializer',
    body: [
      {
        chunk: FiniteDifferenceOrbitInitializerChunk,
      },
      {
        chunk: InitializerChunk,
      },
      {
        chunk: 'This initializer estimates a position by averaging measurements from all available position sensors, weighted by the inverse of the assumed variance of that sensor. To estimate the velocity, this algorithm uses a finite difference approximation from sequential position estimates.'
      },
      {
        subHeading: 'Position Sensors',
        chunk: 'Specify one or more position sensors and an assumed 1-sigma uncertainty for each.',
      },
    ],
  },
};

export const algorithmGuidance = {
  _default: {
    heading: algorithmHeading,
    body: [
      {
        chunk:
          'Input a descriptive name for the algorithm and select the apropriate algorithm type.',
      },
    ],
  },

  type: (values: IGenericObject) => {
    const type = values.type.value;
    const selection = algoSubTypeGuidance[type as keyof typeof algoSubTypeGuidance];
    if (!selection) return { alias: '_default' };
    return {
      heading: selection.heading,
      body: selection.body,
    };
  },
  thrusts: { alias: 'type' },
  angularVelocitySensors: { alias: 'type' },
  gainK: { alias: 'angularVelocitySensors' },
  gainG: { alias: 'angularVelocitySensors' },
  gainC: { alias: 'angularVelocitySensors' },
  gainP: { alias: 'angularVelocitySensors' },
  gainI: { alias: 'angularVelocitySensors' },
  gainD: { alias: 'angularVelocitySensors' },
  epsilon: { alias: 'angularVelocitySensors' },
  opticalAttitudeSensors: { alias: 'angularVelocitySensors' },
  positionSensor: { alias: 'angularVelocitySensors' },
  positionSensors: { alias: 'angularVelocitySensors' },
  vectorSensors: { alias: 'angularVelocitySensors' },
  oneSigmaYawError: { alias: 'angularVelocitySensors' },
  oneSigmaPitchError: { alias: 'angularVelocitySensors' },
  oneSigmaRollError: { alias: 'angularVelocitySensors' },
  oneSigmaAngularVelocityError: { alias: 'angularVelocitySensors' },
  oneSigmaPositionError: { alias: 'angularVelocitySensors' },
  oneSigmaVelocityError: { alias: 'angularVelocitySensors' },
};

export const useGuidance = makeGuidance(algorithmGuidance);

const algorithmsSegmentGuidance = {
  heading: 'Algorithms',
  body: [
    {
      chunk: algorithmMainParagraph,
    },
  ],
};

export default algorithmsSegmentGuidance;
