import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import theme from 'theme';

export interface IRowProps {
  noHover?: boolean;
}

export const rowStyles = {
  buttonContainer: {
    '& button': {
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    },
    '& span': {
      margin: '0px 5px',
      '& div': {
        color: `${theme.palette.text.primary} !important`,
      },
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  } as CSSProperties,
  iconButton: {
    // Override minWidth to scale for icons
    '& button': {
      minWidth: '42px !important',
    },
    '& .Mui-disabled': {
      backgroundColor: `${theme.palette.action.disabled} !important`,
      border: 'transparent 1px solid !important',
    },
  } as CSSProperties,
  row: (props?: IRowProps) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '0.5px solid #dddddd',
    marginLeft: 20,
    padding: 10,
    '&:hover': props?.noHover
      ? undefined
      : {
          cursor: 'pointer',
          backgroundColor: theme.palette.background.mainNested,
        },
  }),
  infoContainer: {
    paddingTop: '2px',
    width: '100%',
    minWidth: '250px',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    overflowWrap: 'break-word',
  } as CSSProperties,
  deleteButton: () => ({
    '& button': {
      '&.MuiButtonBase-root': {
        border: 'transparent 1px solid',
        backgroundColor: theme.palette.tertiary.main,
      } as CSSProperties,
    },
  }),
  description: {
    color: theme.palette.text.tertiary,
    marginTop: 3,
  } as CSSProperties,
  subDescription: {
    color: theme.palette.primary.main,
  } as CSSProperties,
};

const useStyles = makeStyles((theme) => rowStyles);

export default useStyles;
