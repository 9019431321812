import Slider from '@material-ui/core/Slider';
import ThermalInterfaceDialog from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/ThermalInterfacesSegment/ThermalInterfacesDialog';
import { gaEvents } from 'config';
import ELK from 'elkjs';
import { useActiveEntities, useEntityDialogControl, useSnackbar } from 'hooks';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, { Controls, useEdgesState, useNodesState } from 'react-flow-renderer';
import ReactGA from 'react-ga4';
import theme, { borderRadius } from 'theme';
import { FloatingConnectionLine, FloatingEdge, FloatingEdgeWithDialog } from './edges';
import { ChildNode, ConnectableChildNode, ConnectableTempNode, TempNode } from './nodes';
import { useStyles } from './styles';

//
/**
 * Intakes all nodes, filters out nodes that already have positions, leaving only new nodes to be run through ELK.
 * The real value of this function occurs for the first (default) layout. All nodes added incrementally therafter are prepositioned on the righthand side of the screen.
 * Returns the original nodes object, augmented with positions.
 * @param {*} nodes
 * @param {*} edges
 * @param {*} boundsRight
 * @param {*} layout
 * @param {*} direction
 * @returns
 */
const getLayoutedElk = async (nodes, edges, boundsRight, layout, direction = 'TB') => {
  const isHorizontal = direction === 'LR';

  const elk = new ELK({
    algorithms: ['layered', 'stress', 'mrtree', 'radial', 'force', 'disco', 'fixed'],
  });

  const graph = {
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'org.eclipse.elk.stress',
      'elk.stress.desiredEdgeLength': 375,
    },
    children: [],
    edges: [],
  };

  const ignore = {};

  // Assemble components in graph to run through ELK
  nodes.forEach((node, i) => {
    // Exclude nodes that already have a layout
    if (node.position && node.data.isNew !== true) {
      node.targetPosition = isHorizontal ? 'left' : 'top';
      node.sourcePosition = isHorizontal ? 'right' : 'bottom';
      ignore[node.id] = node;
      return;
    }
    //Make elk budget more room
    const graph_child = {
      id: node.id,
      width: node.style.width * 1.1,
      height: node.style.height * 1.1,
    };

    graph.children.push(graph_child);
  });

  // Assemble edges in graph to run through ELK
  edges.forEach((edge) => {
    if (ignore[edge.source] || ignore[edge.target]) {
      return;
    }
    graph.edges.push({ id: edge.id, sources: [edge.source], targets: [edge.target] });
  });

  // Create lookup
  const nodeLookup = {};
  for (const node of nodes) {
    nodeLookup[node.id] = node;
  }

  const { children } = await elk.layout(graph);

  // Cycle through the ELK output and transfer positions back onto original nodes
  children.forEach((child, i) => {
    // TODO: Don't mutate `nodes`
    const node = nodeLookup[child.id];
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).

    if (boundsRight !== 0) {
      node.position = {
        x: boundsRight + 200 - node.style.width / 2,
        y: i * 165, //child.y - node.style.height / 2,
      };
    } else {
      node.position = {
        x: child.x - node.style.height / 2,
        y: child.y - node.style.height / 2,
      };
    }
  });
  return { nodes, edges };
};

// Left for sake of example
// const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);

function tempToColor(temp, maxTemp, minTemp, maxHue = 260, minHue = 0) {
  const hue =
    Math.max(0, Math.min(1 - (temp - minTemp) / (maxTemp - minTemp), 1)) * (maxHue - minHue) +
    minHue;
  return `hsl(${hue}, 100%, 50%)`;
}

/**
 * Adds updated temp data to a node
 * @param {*} node
 * @param {*} temps
 * @param {*} max
 * @param {*} min
 * @returns
 */
const updateNode = (node, temps, max, min) => {
  let newStyle = {
    ...node.style,
    border: `1px solid ${theme.palette.background.darkest}`,
    borderRadius,
  };
  // Child nodes get a more default-looking style
  node.style = node.parentNode
    ? newStyle
    : {
        ...newStyle,
        // paddingLeft: '2px',
        // paddingRight: '2px',
        // backgroundColor: tempToColor(temps[node.id] || temps[node.id.split('::')[0]], max, min),
      };
  node.data = {
    ...node.data,
    temp: temps[node.id] || temps[node.id.split('::')[0]],
    tempColor: tempToColor(temps[node.id] || temps[node.id.split('::')[0]], max, min),
  };
  return node;
};

/**
 * Positions the edge with the higher temp as the source; lower temp as the target
 * This allows the marching ants to flow from the higher temp node to the lower temp node, in line with heat transfer
 * @param {*} edge
 * @param {*} temps
 * @param {*} max
 * @returns
 */
const updateEdge = (edge, temps, max) => {
  const a = temps[edge.source] || temps[edge.source.split('::')[0]];
  const b = temps[edge.target] || temps[edge.target.split('::')[0]];
  return {
    ...edge,
    source: a > b ? edge.source : edge.target,
    target: a > b ? edge.target : edge.source,
  };
};

// ----------------- Slider Vars -----------------
const ZERO_KELVIN = -273.15;
const H2O_FREEZING_POINT = 0;
const H2O_BOILING_POINT = 100;
const HOT = 200;
const min = -55;
const max = 125;
const tempRangeMarks = [ZERO_KELVIN, H2O_FREEZING_POINT, H2O_BOILING_POINT, HOT].map((t) => ({
  value: t,
  label: `${t}°C`,
}));

const ThermalMap = memo(
  (props) => {
    const { nodes: _nodes, edges: _edges, temps, editable, layout, setLayoutEdited } = props;
    const classes = useStyles();
    const rootRef = useRef(null);
    const sliderContainerRef = useRef(null);
    const sliderUsedRef = useRef(false);
    const nodeTypes = useMemo(
      () => ({
        temp: editable ? ConnectableTempNode : TempNode,
        'temp-group': TempNode, // Group node not editable
        child: editable ? ConnectableChildNode : ChildNode,
      }),
      [editable]
    );
    const edgeTypes = useMemo(
      () => ({
        floating: editable ? FloatingEdgeWithDialog : FloatingEdge,
      }),
      [editable]
    );

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [go, setGo] = useState(false);
    const [tempRange, setTempRange] = useState([min, max]);

    // Re-run elk if nodes, edges or layout have changed
    useEffect(() => {
      if (
        layout.current ||
        _nodes.length !== nodes.length ||
        _edges.filter((e) => !e.hidden).length !== edges.length
      ) {
        const bounds = { upper: 0, lower: 0, left: 0, right: 0 };

        if (layout) {
          const layoutNodes = Object.entries(layout.current.nodes);
          const reviewedNodes = [];

          if (layoutNodes.length > 0 && _nodes.length > 0) {
            _nodes.forEach((_node) => {
              // ignore nested components, e.g. nested cooler components
              if (!_node.ignoreLayout) {
                const id = _node.id.split('::')[0];

                // Retrieve the stored position if the node has one
                if (layout.current.nodes[id]) {
                  const layoutNode = layout.current.nodes[id];
                  _node.position = { x: layoutNode.x, y: layoutNode.y };

                  _node.data['isNew'] = false;
                  bounds.upper = layoutNode.y < bounds.upper ? layoutNode.y : bounds.upper;
                  bounds.lower = layoutNode.y > bounds.lower ? layoutNode.y : bounds.lower;
                  bounds.left = layoutNode.x < bounds.left ? layoutNode.x : bounds.left;
                  bounds.right = layoutNode.x > bounds.right ? layoutNode.x : bounds.right;
                } else {
                  _node.data['isNew'] = layout.current.id === '1st' ? false : true;
                }
                reviewedNodes.push(id);
              }

              // if the layout holds a reference to a non-existant node, delete the layout reference.
              layoutNodes.forEach((ln) => {
                if (!reviewedNodes.includes(ln[0])) {
                  delete layout.current.nodes[ln];
                }
              });
            });
          } else if (!layoutNodes.length) {
            //if there are no layout nodes, do nothing
          } else {
            //if no graph nodes
            layout.current.nodes = {};
          }
        }

        getLayoutedElk(_nodes, _edges, bounds.right, layout).then(({ nodes, edges }) => {
          let editedLayout = false;
          const updatedNodes = nodes.map((node) => {
            const n = node.id.split('::')[0];

            if (!node.ignoreLayout) {
              if (layout.current.nodes?.[n]) {
                layout.current.nodes[n].x = node.position.x;
                layout.current.nodes[n].y = node.position.y;
                if (
                  layout.current.nodes[n].x !== node.position.x ||
                  layout.current.nodes[n].y !== node.position.y
                ) {
                  editedLayout = true;
                }
              } else {
                layout.current.nodes[n] = {
                  x: node.position.x,
                  y: node.position.y,
                  visible: true,
                  icon: 'any',
                  iconColor: 'any',
                  type: node.data.nodeType,
                };
                editedLayout = true;
              }
            }
            return updateNode(node, temps, tempRange[1], tempRange[0]);
          });
          if (editedLayout === true) {
            setLayoutEdited(true);
          }

          setNodes(updatedNodes);

          edges.filter((edge) => {
            return true;
          });
          setEdges(
            edges
              .filter(
                // Filter out all hidden edges, e.g. edges pointing at "groups" as they were only used for the layout engine
                (_e) => {
                  return !_e.hidden;
                  // return !source.includes('::group') && !target.includes('::group');
                }
              )
              .map((edge) => updateEdge(edge, temps))
          );
        });
        setGo(true);
      } else {
        // If nodes.length and edges.length hasn't changed but this useEffect is still called,
        // it must be that an interface (i.e. edge) has been updated by the user.
        // Run through the list and reassign an interface's data to its corresponding edge.
        // This way the ThermalInterfaceDialog will have fresh data when it's reopened.
        // Assumes edges[i] maps to _filteredEdges[i].
        const _filteredEdges = _edges.filter(
          // Filter out all edges pointing at "groups" as they were only used for the layout engine
          (_e) => {
            return !_e.hidden;
          }
        );
        setEdges((prev) =>
          prev.map((edge, i) =>
            updateEdge(
              { ...edge, data: _filteredEdges[i].data, label: _filteredEdges[i].data.name },
              temps
            )
          )
        );
      }
    }, [_nodes, _edges, layout, layout.current.name]); //eslint-disable-line

    //Update Nodes and Edges if temp changes
    useEffect(() => {
      if (go) {
        setNodes((nds) => nds.map((node) => updateNode(node, temps, tempRange[1], tempRange[0])));
        setEdges((eds) => eds.map((edge) => updateEdge(edge, temps)));
      }
    }, [go, temps, setNodes, setEdges, tempRange]);

    const onDragStop = useCallback(
      (event, node, nodes) => {
        const n = node.id.split('::')[0];

        if (layout.current.nodes[n]) {
          if (
            layout.current.nodes[n].x !== node.position.x ||
            layout.current.nodes[n].y !== node.position.y
          ) {
            setLayoutEdited(true);
          }
          layout.current.nodes[n].x = node.position.x;
          layout.current.nodes[n].y = node.position.y;
        } else {
          layout.current.nodes[n] = {
            icon: 'any',
            iconColor: 'any',
            type: node.data.nodeType,
            visible: true,
            x: node.position.x,
            y: node.position.y,
          };

          setLayoutEdited(true);
        }
      },
      [layout, setLayoutEdited]
    );

    const { components, surfaces } = useActiveEntities();
    const dialogControl = useEntityDialogControl();
    const { openDialogForNew } = dialogControl;
    const { enqueueSnackbar } = useSnackbar();

    // If opening an edit dialog, it needs to know which components the
    // interface is between. This state defines those components.
    const [interfaceEndpoints, setInterfaceEndpoints] = useState({
      source: null,
      target: null,
      sourceCooler: null,
      targetCooler: null,
    });

    // Opens dialog to create new interface, using interfaceEndpoints
    const newInterfaceDialog = useCallback(
      ({ source, target }) => {
        // source & target are ids as strings
        const allThermalEndpoints = components.concat(surfaces);
        // get components based on ids
        // some ids have suffixes (e.g. if cooler is id '257', source might be '257::SubComponentB' or '257::group')
        const sourceNode = allThermalEndpoints.find((el) => el.id === source.split('::')[0]);
        const targetNode = allThermalEndpoints.find((el) => el.id === target.split('::')[0]);
        // get types of source and target
        const sourceSurface = sourceNode.surfaceMaterial;
        const sourceCooler = source.includes('::nestedComponentA');
        const targetSurface = targetNode.surfaceMaterial;
        const targetCooler = target.includes('::nestedComponentA');

        // Can't connect two surfaces
        if (sourceSurface && targetSurface)
          enqueueSnackbar('Thermal interfaces between two external surfaces are not allowed.', {
            variant: 'warning',
          });
        // Can't connect a surface and a cooler
        else if ((sourceSurface && targetCooler) || (sourceCooler && targetSurface))
          enqueueSnackbar('An external surface cannot be regulated by a cooler.', {
            variant: 'warning',
          });
        else {
          setInterfaceEndpoints({
            source: sourceNode,
            target: targetNode,
            sourceCooler,
            targetCooler,
          });
          openDialogForNew();
        }
      },
      [components, surfaces, setInterfaceEndpoints, openDialogForNew, enqueueSnackbar]
    );

    const reactFlowHeight = 700;

    const onSliderChange = useCallback(() => {
      if (sliderUsedRef.current === false) {
        ReactGA.event(gaEvents.ADJUST_TEMP, {
          category: 'UI Feature',
          action: 'Adjust Standard Temp',
          label: 'Standard Temp Slider',
        });
        sliderUsedRef.current = true;
      }
    }, []);

    return (
      <div ref={rootRef}>
        <ReactFlow
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={newInterfaceDialog}
          onNodeDragStop={onDragStop}
          minZoom={0.2}
          // onInit={onInit}
          fitView
          attributionPosition="bottom-right"
          connectionLineComponent={FloatingConnectionLine}
          style={{ height: reactFlowHeight }}
          deleteKeyCode={[]}
          connectionMode="loose"
        >
          <Controls />
        </ReactFlow>
        <div className={classes.sliderContainer} ref={sliderContainerRef}>
          <p className={classes.sliderTitleFirst}>Temperature Range:</p>
          <Slider
            className={classes.slider}
            name="Temperature Range"
            defaultValue={[min, max]}
            min={ZERO_KELVIN}
            max={HOT}
            track={false}
            value={tempRange}
            marks={tempRangeMarks}
            onChange={(c, v) => {
              onSliderChange();
              setTempRange(v);
            }}
          />
        </div>
        {dialogControl.dialogConfig.open && editable && (
          <ThermalInterfaceDialog
            control={dialogControl}
            source={interfaceEndpoints.source}
            target={interfaceEndpoints.target}
            sourceCooler={interfaceEndpoints.sourceCooler}
            targetCooler={interfaceEndpoints.targetCooler}
          />
        )}
      </div>
    );
  },
  (prevProps, newProps) => {
    return false;
    // Save code for controlling rendering in forthcoming features
    // if (
    //   prevProps.selectedLayout.label === newProps.selectedLayout.label ||
    //   newProps.editable ||
    //   prevProps.selectedNode !== newProps.selectedNode ||
    //   prevProps.useCustomTemp !== newProps.useCustomTemp
    // ) {
    //   return false;
    // } else {
    //   return true;
    // }
  }
);

ThermalMap.displayName = 'ThermalMap';

export default ThermalMap;
