import { isProd } from 'config';
import { useActiveEntities, useLatestJob, useSelectById } from 'hooks';
import useMountStatus from 'hooks/useMountStatus';
import _ from 'lodash';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { makeModel } from 'middleware/SatelliteApi/template';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSearchParams, useSearchParams } from 'routes';
import {
  Series,
  concatSeriesData,
  getResponsibleStream,
  getSeriesDataStartTimestamp,
  getSeriesDataStopTimestamp,
  getSeriesForKeys,
  initSeriesData,
  sliceSeriesDataByTimestamp,
} from './series';

/**
 * Provides all the simulation data in bulk for the current scenario.
 * - Includes the simulation data itself _and_ the metadata.
 * - Includes utility functions for querying the data and compiling data for echarts.
 * - Includes useful mappings to better interact with the data.
 * - Includes uncompiled models _without_ simulation data.
 *
 * Subscribing to this context implicitly subscribes a component to:
 * - ActiveBranchContext: provides the current branch for which to fetch data
 */
export const DataContext = createContext();
export const useDataContext = () => useContext(DataContext);

const INITIAL_LIMIT = 10000; // points
const INITIAL_OFFSET = 0.5; // days
const TIME_RESOLUTION = 10 ** -11; // days

const DataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    Data: {
      actions: { getData },
    },
    Job: {
      actions: { updateAnalyzeState },
    },
  } = SatelliteApi;

  const { share } = getSearchParams();
  const { branch, model } = useActiveEntities();

  const [state, setState] = useState({
    seriesData: initSeriesData(),
    metaData: {},
    jobId: null,
  });

  const [latestJob, targetJob] = useLatestJob();
  const stateJob = useSelectById('Job', state.jobId);
  // If no data exists make current job empty so that the scenario loads
  const currentJob = useMemo(
    () => (_.isEmpty(targetJob) && !stateJob ? targetJob : stateJob),
    [stateJob, targetJob]
  );

  const [injestConfig, setInjestConfig] = useState({
    rate: null, // Rate data is available (sim MJDs per wall second)
    seq: [],
    start: null,
    simTime: null,
  });

  const startTime = getSeriesDataStartTimestamp(state.seriesData).common || 0;
  const stopTime = getSeriesDataStopTimestamp(state.seriesData).common || 0;
  const start = injestConfig.start || targetJob?.startTime;

  // eslint-disable-next-line
  const updateInjestConfig = useCallback(
    (simTime, end) => {
      if (simTime < 0) return; // Unchanged
      setInjestConfig((curr) => {
        const newState = {
          ...curr,
          simTime,
        };
        if (end) {
          const midTime = start + (end - start) / 2;
          const wallTime = new Date().getTime() / 1000;
          if (curr.seq.length > 0) {
            const prev = curr.seq[curr.seq.length - 1];
            const prevMidTime = prev[0];
            const prevWallTime = prev[1];
            newState.rate = ((midTime - prevMidTime) * 86400) / (wallTime - prevWallTime);
            if (curr.rate) {
              const alpha = 0.75;
              newState.rate = alpha * curr.rate + (1 - alpha) * newState.rate;
            }
          }
          newState.seq = [...curr.seq, [midTime, wallTime]];
          newState.start = end + TIME_RESOLUTION; // Extend slightly past last fetched data
        }
        return newState;
      });
    },
    [start]
  );

  let [fetching, setFetching] = useState(false);
  let [fetchError, setFetchError] = useState(null);
  const isMounted = useMountStatus();

  const [searchParams, setSearchParams] = useSearchParams();

  const _fetchData = useCallback(
    (start, stop, requestedJob, options) => {
      setFetching(true);
      const queryParams = {
        start,
        stop,
        share,
        sampleRate: options.resolution,
      };
      if (options.continuationToken) {
        delete queryParams.sampleRate;
        queryParams.continuationToken = options.continuationToken;
      }

      dispatch(
        getData({
          id: requestedJob.dataArray,
          queryParams,
          successCallback: ({ meta: metaData, series }) => {
            // If user has navigated away, don't continue the fetching loop and bog down the front-end
            if (!isMounted()) {
              setFetching(false);
              return;
            }

            const seriesData = options.seriesData || initSeriesData();
            options.structure = options.structure || metaData.structure;
            concatSeriesData(seriesData, series);

            if (metaData.continuationToken) {
              _fetchData(start, stop, requestedJob, {
                ...options,
                continuationToken: metaData.continuationToken,
                seriesData,
                structure: options.structure,
              });
            } else {
              metaData = { ...metaData, structure: options.structure };
              setState((state) => ({ ...state, metaData, seriesData, jobId: requestedJob.id }));
              dispatch(
                updateAnalyzeState({
                  id: requestedJob.id,
                  dataState: {
                    seriesData,
                    metaData,
                    jobId: requestedJob.id,
                    start,
                    stop,
                    res: options.resolution.toString(),
                  },
                  fetchWhenTrue: false,
                })
              );
              setFetching(false);
              setFetchError(null);
            }
          },
          failureCallback: (response) => {
            if (!isMounted()) {
              setFetching(false);
              return;
            }
            if (response) console.log('error:', response);
            else console.log('error: an error occurred while digesting data');
            setFetching(false);
            setFetchError(response);
          },
        })
      );
    },
    [dispatch, getData, updateAnalyzeState, share, isMounted]
  );

  const _fetchDataOld = useCallback(
    (start, stop, limit, requestedJob) => {
      setFetching(true);
      const requestedJobId = requestedJob.id;

      dispatch(
        getData({
          id: requestedJob.dataArray,
          queryParams: {
            start,
            stop,
            share,
            axisOrder: 'TIME_MINOR',
            limit,
          },
          successCallback: ({ meta: metaData, series }) => {
            // If user has navigated away, don't continue the fetching loop and bog down the front-end
            if (!isMounted()) {
              setFetching(false);
              return;
            }

            let { seriesData, jobId } = state;

            if (jobId !== requestedJobId) seriesData = initSeriesData();
            else {
              const cache = requestedJob?.analyzeState?.dataState;
              if (
                // If the cache is stale, reset seriesdata in preparation for the new data
                cache &&
                (cache.start !== searchParams.start ||
                  cache.stop !== searchParams.stop ||
                  cache.limit !== searchParams.limit)
              )
                seriesData = initSeriesData();
            }
            concatSeriesData(seriesData, series);

            // Store state for this provider
            setState((state) => ({ ...state, metaData, seriesData, jobId: requestedJobId }));

            // Immediately cache state in redux
            dispatch(
              updateAnalyzeState({
                id: requestedJobId,
                dataState: {
                  seriesData,
                  metaData,
                  jobId: requestedJobId,
                  start,
                  stop,
                  limit,
                },
                fetchWhenTrue: false,
              })
            );
            setFetching(false);
            setFetchError(null);
          },
          failureCallback: (response) => {
            if (!isMounted()) {
              setFetching(false);
              return;
            }
            if (response) console.log('error:', response);
            else console.log('error: an error occurred while digesting data');
            setFetching(false);
            setFetchError(response);
          },
        })
      );
    },
    [dispatch, getData, updateAnalyzeState, share, isMounted, state, searchParams]
  );

  const fetchData = useCallback(
    ({ start, stop, job, ...options }) => {
      const requestedJob = job || latestJob;
      // Support DSv2 API
      // TODO: Remove when DSv2 is no longer supported
      if (requestedJob?.dataArrayVersion === 2) {
        _fetchDataOld(start, stop, options.limit, requestedJob);
        setSearchParams({ job: requestedJob.id, start, stop, limit: options.limit });
      } else {
        // Round resolution up to next power of 2
        options.resolution = Math.pow(2, Math.ceil(Math.log2(Math.ceil(options.resolution))));

        // TODO: add recursive field once supported
        _fetchData(start, stop, requestedJob, options);
        setSearchParams({ job: requestedJob.id, start, stop, res: options.resolution.toString() });
      }
    },
    [_fetchData, _fetchDataOld, setSearchParams, latestJob]
  );

  // Get simulation data
  useEffect(() => {
    if (targetJob?.id && !fetching) {
      if (targetJob?.analyzeState?.dataState?.jobId) {
        // Cache is populated
        if (targetJob?.analyzeState.dataState.jobId !== state.jobId) {
          // Avoid unnecessary renders
          setState(targetJob?.analyzeState.dataState);
        }
        // Maintain search params in URL
        // TODO: Remove when DSv2 is no longer supported
        targetJob?.dataArrayVersion === 2
          ? setSearchParams({
              start: searchParams.start || targetJob?.analyzeState.dataState.start,
              stop: searchParams.stop || targetJob?.analyzeState.dataState.stop,
              limit: searchParams.limit || targetJob?.analyzeState.dataState.limit,
            })
          : setSearchParams({
              start: searchParams.start || targetJob?.analyzeState.dataState.start,
              stop: searchParams.stop || targetJob?.analyzeState.dataState.stop,
              res: searchParams.res || targetJob?.analyzeState.dataState.res,
            });
      } else if (
        model.Agent.all().length > 0 &&
        (targetJob?.analyzeState?.fetchWhenTrue || (!state.jobId && targetJob?.dataArray))
      ) {
        // Cache invalid or empty and job complete (the only thing that sets invalid truthy is getJob when job is complete)
        const options =
          targetJob.dataArrayVersion === 2
            ? { limit: searchParams.limit || Math.ceil(INITIAL_LIMIT / model.Agent.all().length) }
            : {
                resolution:
                  searchParams.res !== undefined
                    ? searchParams.res
                    : model.Agent.all().length === 1
                    ? 1
                    : Math.log2(model.Agent.all().length),
              };
        fetchData({
          // Current search params trump defaults but clamp to job start/stop
          start: Math.max(targetJob.startTime, searchParams.start || start),
          stop: Math.min(
            targetJob.progress?.currentTime || targetJob.exitTime || targetJob.stopTime,
            searchParams.stop || start + INITIAL_OFFSET
          ),
          job: searchParams.job ? targetJob : null,
          ...options,
        });
      } // Else noop while job is running
    }
  }, [targetJob?.id, targetJob?.analyzeState, branch, state]); // eslint-disable-line

  const queryData = useCallback(
    (timestamp, agentId) => {
      const { seriesData } = state;
      return sliceSeriesDataByTimestamp(seriesData, timestamp, agentId);
    },
    [state]
  );

  const staticModels = useMemo(() => {
    const structure = state.metaData.structure;
    const results = {
      agents: {},
    };
    if (structure) {
      results.scenario = makeModel(structure.scenario);
      for (const simulatedAgentId in structure.agents) {
        results.agents[simulatedAgentId] = makeModel(structure.agents[simulatedAgentId]);
      }
    }
    return results;
  }, [state]);

  const resolveSeriesDataKeyPair = useCallback(
    (agentId, agentLocalPath) => {
      const columnKey = `${agentId}.${agentLocalPath}`;
      try {
        const streamId = getResponsibleStream(state.seriesData, columnKey).id;
        return { xKey: `${streamId}.time`, yKey: `${streamId}.${columnKey}` };
      } catch (e) {
        if (!isProd()) {
          console.warn(
            `Series data under the key ${columnKey} does not exist. This means either (1) the key is invalid or (2) this model does not contain the state referenced by the key.`
          );
        }
      }
    },
    [state]
  );

  let value = useMemo(
    () => ({
      seriesData: state.seriesData,
      meta: state.metaData,
      startTime: startTime || 0,
      stopTime: stopTime || 0,
      queryData,
      staticModels,
      _state: state,
      injestConfig,
      resolveSeriesDataKeyPair,
      fetchData,
      fetching,
      fetchError,
      jobId: currentJob?.id,
      currentJob,
    }),
    [
      queryData,
      state,
      staticModels,
      startTime,
      stopTime,
      injestConfig,
      resolveSeriesDataKeyPair,
      fetchData,
      fetching,
      fetchError,
      currentJob,
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;

export const useStream = (agentId, ...keys) => {
  const { _state } = useContext(DataContext);
  const _keys = JSON.stringify(keys);

  return useMemo(() => {
    return makeStream(_keys, _state, agentId);
    // NOTE: We want the contents of `keys` to cause a re-memo, not a reference to the array itself
    // Use a string to do that until react lets us just spread `keys` in the dep array
    // https://github.com/facebook/react/issues/18229
  }, [_keys, _state, agentId]);
};

// Returns empty data if agentId is falsy
export const makeStream = (_keys, _state, simulatedAgentId) => {
  const keys = typeof _keys === 'string' ? JSON.parse(_keys) : _keys;
  const { seriesData } = _state;
  if (keys.length < 1) {
    return {
      series: [],
      transposeSeries: () => [],
    };
  }

  // TODO: Is this conditional ever hit anymore?
  if (!simulatedAgentId) {
    let series = [[]].concat(keys.map(() => []));
    return Series(series);
  }

  const simulatedAgentKeys = keys.map((key) => `${simulatedAgentId}.${key}`);
  const series = getSeriesForKeys(seriesData, simulatedAgentKeys);
  return Series(series);
};
