import { faPersonWalkingArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LoadingView from 'components/LoadingView';
import WorkspaceDialog from 'components/RootView/WorkspaceDialog';
import {
  setActiveMissionVersionId,
  setActiveWorkspaceId,
  setLatestJobId,
} from 'components/RootView/missionExplorerSlice';
import ClipboardCopy from 'components/general/ClipboardCopy';
import InfoBadge from 'components/general/InfoBadge';
import Nav from 'components/general/Nav';
import StyledButton from 'components/general/StyledButton';
import StyledDivider from 'components/general/StyledDivider';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import { ILicense, IWorkspace } from 'components/general/types';
import { IUser } from 'components/general/types/general';
import { useEntityDialogControl, usePermissionCheck, useSelectById, useUser } from 'hooks';
import useWorkspace from 'hooks/useWorkspace';
import moment from 'moment';
import peopleImg from 'multimedia/icons/workspace.png';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { WorkspaceVables } from 'utils/vable';
import MembersBoard from './MembersBoard';
import MemberDeleteDialog from './MembersBoard/MemberDialog/Delete';
import ReposBoard from './ReposBoard';
import RolesBoard from './RolesBoard';
import useStyles from './styles';

const WorkspaceView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useUser();
  const { id: workspaceId } = useParams<{ id: string }>();
  const workspace = useWorkspace();
  const license = useSelectById('License', workspace?.license) as ILicense;
  const [canEdit, canDelete] = usePermissionCheck([
    WorkspaceVables.Permission.EDIT_WORKSPACE,
    WorkspaceVables.Permission.DELETE_WORKSPACE,
  ]);

  const workspaceDialogControl = useEntityDialogControl<IWorkspace>();
  const { openDialogForExisting: openDialogForExistingWorkspace } = workspaceDialogControl;
  const memberDialogControl = useEntityDialogControl<IUser>();
  const [settingsOpen, setSettingsOpen] = useState(false);

  // Unset active mission version ID whenever user navigates to repos. This unsets the nav bar.
  // Unset latest job ID so ActiveBranch provider knows to fetch a new one when switching between repos.
  useEffect(() => {
    dispatch(setActiveWorkspaceId(workspaceId));
    dispatch(setActiveMissionVersionId(undefined));
    dispatch(setLatestJobId(undefined));
  }, [dispatch, workspaceId]);

  if (!workspace?.id) return <LoadingView message="Sedaro is loading..." />;

  return (
    <ViewContainer>
      <Nav
        contextName="Workspace"
        contextImg={peopleImg}
        contextHeader={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {workspace.name}
            <InfoBadge content={`Workspace ID: ${workspace.id}`} style={{ marginLeft: 5 }} />
            <ClipboardCopy text={workspace.id} style={{ paddingLeft: 5 }} displayLabel={false} />
          </div>
        }
        contextSubHeader={
          workspace?.isActive
            ? // Active workspace shows expiration date to admins
              license &&
              `License expire${
                moment(license.dateExpires).unix() > moment.now() / 1000 ? 's' : 'd'
              }: ${moment(license.dateExpires).local().format('MMM Do, YYYY')}`
            : // Inactive workspace shows "expired" message to all members
              [
                <StyledDivider key="divider" $margin="bottom" />,
                "Your workspace's license has expired. Please contact us at ",
                <strong key="sales">sales@sedarotech.com</strong>,
                ' to renew your license and continue modeling with Sedaro.',
              ]
        }
        control={
          <>
            <StyledButton
              type="button"
              onClick={() => setSettingsOpen(!settingsOpen)}
              fullWidth
              style={{ marginTop: '10px' }}
              framed
            >
              <ArrowUpIcon className={settingsOpen ? classes.up : classes.down} />
              Settings
            </StyledButton>
            <Collapse in={settingsOpen}>
              {canEdit && (
                <StyledButton
                  type="button"
                  onClick={() => openDialogForExistingWorkspace(workspace, 'edit')}
                  fullWidth
                >
                  <EditIcon />
                  Edit Workspace
                </StyledButton>
              )}
              {canDelete && (
                <StyledButton
                  type="button"
                  onClick={() => openDialogForExistingWorkspace(workspace, 'delete')}
                  fullWidth
                  framed
                >
                  <DeleteIcon />
                  Delete Workspace
                </StyledButton>
              )}
              <StyledButton
                type="button"
                onClick={() => memberDialogControl.openDialogForExisting(user, 'delete')}
                fullWidth
                framed
              >
                <FontAwesomeIcon
                  icon={faPersonWalkingArrowRight}
                  flip="horizontal"
                  style={{ marginRight: 5 }}
                />
                Leave Workspace
              </StyledButton>
            </Collapse>
          </>
        }
      ></Nav>
      <ViewPort>
        <ReposBoard />
        <MembersBoard />
        <RolesBoard />
      </ViewPort>

      <WorkspaceDialog control={workspaceDialogControl} />
      <MemberDeleteDialog
        config={memberDialogControl.dialogConfig}
        workspace={workspace}
        onClose={memberDialogControl.closeDialog}
      />
    </ViewContainer>
  );
};

export default WorkspaceView;
