import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';
import { ModuleVables } from 'utils/vable';

const statusColor = {
  [ModuleVables.SimulationStatuses.RUNNING.value]: theme.palette.primary.light,
  [ModuleVables.SimulationStatuses.PENDING.value]: theme.palette.secondary.light,
  [ModuleVables.SimulationStatuses.QUEUED.value]: theme.palette.secondary.light,
  [ModuleVables.SimulationStatuses.READY.value]: theme.palette.primary.light,
  [ModuleVables.SimulationStatuses.ERROR.value]: theme.palette.error.light,
  [ModuleVables.SimulationStatuses.FAILED.value]: theme.palette.error.light,
  [ModuleVables.SimulationStatuses.TERMINATED.value]: theme.palette.error.light,
  [ModuleVables.SimulationStatuses.INCOMPLETE.value]: theme.palette.error.light,
  [ModuleVables.SimulationStatuses.MD_FAILURE.value]: theme.palette.error.light,
  [ModuleVables.SimulationStatuses.OUTDATED.value]: theme.palette.secondary.light,
  [ModuleVables.SimulationStatuses.PARTIAL_RESULTS.value]: theme.palette.success.light,
  [ModuleVables.SimulationStatuses.SUCCEEDED.value]: theme.palette.success.light,
};

interface IProps {
  sortByBranch?: boolean;
  status?: string;
  clickable?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      color: theme.palette.primary.light,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  interior: ({ sortByBranch }: IProps) => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    maxHeight: 600,
    scrollbarGutter: sortByBranch ? 'stable' : 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.background.paperNested} transparent`,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: theme.palette.background.paperNested,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.text.tertiary,
    },
    '& .MuiIconButton-root': {
      width: 30,
      height: 30,
    },
  }),
  jobContainer: ({ sortByBranch, clickable }: IProps) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '0.5px solid #dddddd',
    marginLeft: sortByBranch ? 8 : 20,
    padding: sortByBranch ? '0px 10px' : 10,
    '&:hover': clickable
      ? {
          cursor: 'pointer',
          backgroundColor: theme.palette.background.mainNested,
        }
      : {},
    '& .MuiLinearProgress-root': {
      height: 8,
      borderRadius: 5,
      backgroundColor: theme.palette.background.paperNested,
    },
    '& .MuiLinearProgress-bar': {
      borderRadius: 5,
      background:
        'linear-gradient(to right, ' +
        theme.palette.secondary.light +
        ', ' +
        theme.palette.primary.dark +
        ')',
    },
  }),
  statusTime: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '275px',
    '& h3': {
      fontSize: 14,
      margin: 4,
      color: theme.palette.text.secondary,
    },
  },
  styledBadge: ({ status }: IProps) => ({
    textAlign: 'center',
    marginTop: 3,
    '& > span': {
      minWidth: 50,
      backgroundColor: statusColor[status || ModuleVables.SimulationStatuses.QUEUED.value],
      color: theme.palette.background.main,
      top: 'unset',
      right: 'unset',
      transform: 'none',
      transformOrigin: 'center',
      position: 'relative',
      cursor: 'default',
    },
  }),
}));

export default useStyles;
