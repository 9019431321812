import { ScenarioEditView } from 'components';
import NotFound from 'components/NotFoundView';
import ScenarioAnalyzeView from 'components/ScenarioView/ScenarioAnalyzeView';
import { IGenericObject } from 'components/general/types';
import { DataProvider } from 'providers';
import { useParams } from 'react-router-dom';
import { routePathsCommon } from 'routes';

const ScenarioView = () => {
  const { moduleAction }: IGenericObject = useParams();
  return moduleAction === routePathsCommon.EDIT ? (
    <ScenarioEditView />
  ) : moduleAction === routePathsCommon.ANALYZE ? (
    <DataProvider>
      <ScenarioAnalyzeView />
    </DataProvider>
  ) : (
    <NotFound />
  );
};
export default ScenarioView;
