import { IGenericObject } from 'components/general/types';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

interface ITableData {
  checked: boolean;
}

export interface INamedBlock {
  name: string;
  id: string;
  dataSide?: IGenericObject;
}

export interface ITableBlock extends INamedBlock {
  tableData: ITableData;
}
const useSelectBlocks = (
  blocks: INamedBlock[] | undefined | '',
  relatedBlocks: INamedBlock[] | undefined | '',
  extraFields: string[] | undefined = undefined
) => {
  const [parsedBlocks, setParsedBlocks] = useState<ITableBlock[]>([]);
  const initBlocks = useCallback(() => {
    let _parsedBlocks: ITableBlock[] = [];
    const relatedOrEmpty = relatedBlocks ? relatedBlocks : [];
    if (blocks) {
      _parsedBlocks = blocks
        .filter((c) => c != null)
        .map((c) => {
          const tableData: ITableData = { checked: false };
          const extra = _.pick(c, extraFields || []);
          const relatedBlock = relatedOrEmpty.find((r) => r.id === c.id);
          if (relatedBlock) {
            tableData.checked = true;
          } else {
            tableData.checked = false;
          }
          return {
            ...extra,
            id: c.id,
            name: c.name,
            tableData,
            dataSide: relatedBlock?.dataSide,
          };
        });
    }
    setParsedBlocks(_parsedBlocks);
  }, [blocks, setParsedBlocks, relatedBlocks, extraFields]);
  useEffect(() => {
    initBlocks();
  }, [initBlocks]);
  return { parsedBlocks, initBlocks, setParsedBlocks };
};

export default useSelectBlocks;
