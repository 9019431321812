import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px dashed ${theme.palette.text.secondary}`,
    borderRadius: '5px',
    color: theme.palette.text.secondary,
    cursor: 'default',
    margin: '1em 0em',
    padding: '2em',
    '& *': {
      textAlign: 'center',
    },
  },
  allowed: {
    fontSize: '0.75rem',
    marginTop: '0.5em',
  },
}));

export default useStyles;
