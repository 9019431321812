import PriorityDecoration from 'components/general/PriorityDecoration';
import { FallbackInlay } from 'components/general/ViewPortInlay';
import ConOpsChart from 'components/general/charts/ConOpsChart';
import ConOpsChartOld from 'components/general/charts/ConOpsChartOld';
import { useActiveStaticModel } from 'hooks';
import { useConOpsData } from 'hooks/useConOpsData';
import { ErrorBoundary } from 'react-error-boundary';
import { getSearchParams } from 'routes';
import useStyles from './styles';

const ConOpsTimeSeriesWidget = () => {
  const { agentId: activeAgentId } = getSearchParams();
  const model = useActiveStaticModel();
  const conOpsData = useConOpsData(model, activeAgentId);

  const classes = useStyles();

  // PARSE DATA, FORMAT FOR CSV
  // const conditions = model.Condition.all();
  // This construction relies on the fact that operationalModeList is ordered by priority
  // and lines up with the order in conOpsData.timeSeries.operationalModeCompliances
  // const csvData = useCallback(() => {
  //   let labels = ['Time (UTC)', 'Time (MJD)', 'Active Operational Mode'];
  //   labels = labels.concat(
  //     operationalModes.map((opMode) => `Op. Mode: ${opMode.name} Compliance`), // Op Mode names
  //     conditions.map((cond) => `Condition: ${cond.name} Compliance`) // Condition names
  //   );
  //   let csvData = [labels];

  //   return csvData.concat(
  //     conOpsData.timeSeries.map((item) => {
  //       let res = [
  //         mjd2Moment(item.times.mjd).format(dateFormatLong), // Time String
  //         item.times.mjd, // Time in MJD
  //         operationalModes[item.operationalModeStatuses.indexOf(1)].name, // activeOpMode
  //         ...item.operationalModeCompliances.map((mode) => (!!mode).toString()), // compliance of each op mode
  //       ];
  //       if (item.conditionCompliances)
  //         // compliance of each condition
  //         return res.concat(item.conditionCompliances.map((mode) => (!!mode).toString()));
  //       else return res;
  //     })
  //   );
  // }, [conOpsData, operationalModes, conditions]);

  return (
    <div className={classes.root}>
      <ErrorBoundary
        FallbackComponent={
          <FallbackInlay
            text="This chart is under construction."
            subText="Our team is working to restore this feature."
          />
        }
      >
        {
          // TODO: Fix this once DSv2 is no longer supported
          conOpsData.times?.length || conOpsData.conOpsData?.timeSeries.length ? (
            <PriorityDecoration className={classes.decoration} />
          ) : null
        }
        <div className={classes.plot}>
          {
            // TODO: This check is temporary until we get routines working
            conOpsData.times?.length ? (
              <ConOpsChart model={model} data={conOpsData} />
            ) : // TODO: Remove this once DSv2 is no longer supported
            conOpsData.conOpsData?.timeSeries.length ? (
              <ConOpsChartOld model={model} data={conOpsData.conOpsData.timeSeries} />
            ) : (
              <FallbackInlay
                text="This chart is under construction."
                subText="Our team is working to restore this feature."
              />
            )
          }
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default ConOpsTimeSeriesWidget;
