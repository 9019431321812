import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem } from '@material-ui/core/';
import A from 'components/general/A';
import StyledDivider from 'components/general/StyledDivider';
import Dialog from 'components/general/dialogs/Dialog';
import { COMMUNITY_SLACK_URL, DOCUMENTATION_URL, hotkeys } from 'config';
import { Fragment, useEffect, useState } from 'react';
import theme from 'theme';
import ContactIcons from './ContactIcons';
import ReleaseNotes from './ReleaseNotes';
import useStyles from './styles';

const SupportDialog = (props) => {
  const {
    config: { open, sales },
    onClose,
  } = props;

  const classes = useStyles();
  const [showHotkeys, setShowHotkeys] = useState(false);

  useEffect(() => {
    if (open) setShowHotkeys(false);
  }, [open]);

  const keyIcons = {
    left: <FontAwesomeIcon icon={faCaretLeft} />,
    right: <FontAwesomeIcon icon={faCaretRight} />,
    up: <FontAwesomeIcon icon={faCaretUp} />,
    down: <FontAwesomeIcon icon={faCaretDown} />,
  };

  return (
    <Fragment>
      <Dialog
        title={showHotkeys ? 'Hotkeys' : sales ? 'Sales' : 'Support'}
        open={open}
        onClose={onClose}
        onSubmit={() => setShowHotkeys(!showHotkeys)}
        submitActionText={showHotkeys ? 'Support' : 'Hotkeys'}
        submitButtonTooltip={showHotkeys ? 'Back to support' : 'View hotkeys'}
        dontDisableInReadOnly
        secondaryActionText={'Close'}
      >
        {!showHotkeys ? (
          <>
            <p>
              Please call or email us using the links below. Our team will get back to you within 24
              hours.
            </p>
            {!sales && (
              <p style={{ marginTop: 5 }}>
                View our public{' '}
                <A href={DOCUMENTATION_URL} target="_blank">
                  documentation
                </A>{' '}
                for general site assistance or help with common questions or join the Sedaro
                Community{' '}
                <A href={COMMUNITY_SLACK_URL} target="_blank">
                  Slack
                </A>
                .
              </p>
            )}
            <ContactIcons sales={sales} />
            <StyledDivider style={{ width: '100%', marginBottom: 12, marginTop: 0 }} />
            <ReleaseNotes />
          </>
        ) : (
          <div>
            <List dense={false}>
              {Object.values(hotkeys).map((hotkey) => (
                <ListItem key={hotkey.description}>
                  <div style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <h3 style={{ padding: 10 }}>{`${hotkey.description}: `}</h3>
                      <div style={{ display: 'flex' }}>
                        {hotkey.keys.split(', ').map((keystroke, i, a) => (
                          <>
                            {keystroke.split('+').map((key) => {
                              return (
                                <kbd key={key} className={classes.key}>
                                  {keyIcons[key.toLowerCase()] || key}
                                </kbd>
                              );
                            })}
                            {i < a.length - 1 && <h6 style={{ padding: 5 }}>or</h6>}
                          </>
                        ))}
                      </div>
                    </div>
                    {hotkey.notes && (
                      <h6
                        style={{
                          margin: '12px 0 0 50px',
                          padding: 4,
                          borderLeft: '1px solid',
                          backgroundColor: theme.palette.background.mainNested,
                        }}
                      >
                        {hotkey.notes}
                      </h6>
                    )}
                  </div>
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </Dialog>
    </Fragment>
  );
};

export default SupportDialog;
