import { useEffect } from 'react';

// NOTE: disabling default scroll behavior on number inputs is a common problem without a simple soluation.
// See: https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number

/**
 * By default, when a user scrolls while focusing and hovering a number input, the input's value will change.
 *
 * This hook disables that behavior while maintaining focus in the input and allowing the user to scroll the page.
 */
function useDisableScrollOnNumberInput() {
  useEffect(() => {
    const disableScroll = () => {
      const el = document?.activeElement;

      if (!(el instanceof HTMLInputElement) || el?.type !== 'number') return;

      el.blur();
      setTimeout(() => el.focus({ preventScroll: true }), 0);
    };

    document.addEventListener('wheel', disableScroll);
    return () => document.removeEventListener('wheel', disableScroll);
  }, []);
}

export default useDisableScrollOnNumberInput;
