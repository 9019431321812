import ELK, { ElkExtendedEdge } from 'elkjs';
import { Edge } from 'react-flow-renderer';

import { isProd } from 'config';
import { useEffect, useMemo, useState } from 'react';
import { MarkerType, Node } from 'react-flow-renderer';
import theme from 'theme';
import ActuatorNode from './ActuatorNode';
import AlgoNode from './AlgoNode';
import SensorNode from './SensorNode';
import ControlModeNode from './SlidingModeNode';
import {
  ACTUATOR_MAGNETORQUER,
  ACTUATOR_REACTION_WHEEL,
  ACTUATOR_THRUSTER,
  IActuatorNode,
  IAlgoNode,
  IControlModeNode,
  ISensorNode,
} from './general/types';

interface IHeight {
  height: number;
}
type IAllNodes =
  | (ISensorNode & IHeight)
  | (IAlgoNode & IHeight)
  | (IControlModeNode & IHeight)
  | (IActuatorNode & IHeight);

// =============================================== Positioning Variables ===============================================
const BASE_NODE_HEIGHT = 42;
const COLUMN_WIDTH = 100;

// SensorNode
const SENSOR_NODE_TYPE = 'sensor-node';
const sensorNodeHeight = BASE_NODE_HEIGHT;
const conditionHeight = 11;

// AlgoNode
const ALGO_NODE_TYPE = 'algo-node';
const algoNodeHeight = BASE_NODE_HEIGHT;

// ControlModeNode
const CONTROL_MODE_NODE_TYPE = 'control-mode-node';

// ActuatorNode
const ACTUATOR_NODE_TYPE = 'actuator-node';
const actuatorHeightMap = {
  [ACTUATOR_REACTION_WHEEL]: 62,
  [ACTUATOR_MAGNETORQUER]: BASE_NODE_HEIGHT,
  [ACTUATOR_THRUSTER]: BASE_NODE_HEIGHT,
};

// ============================================= ELK Layout Options =============================================
// NOTE: there are 5 options total: https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-nodePlacement-strategy.html
// Narrowed it down to 3 here:
export const elkNodePlacementStrategies = [
  'SIMPLE',
  // 'LINEAR_SEGMENTS', // Similar to other options
  'BRANDES_KOEPF',
  'NETWORK_SIMPLEX',
  // 'INTERACTIVE', // Mixes up order so edges harder to decipher
];

// ============================================= Individual Prep Functions =============================================
// --------------- Add height and type for nodes ---------------
// NOTE: ELK uses "height" below to calculate x and y "position"

const prepSensorNodes = (sensorNodes: ISensorNode[]) => {
  return sensorNodes.map((sN) => {
    const len = sN.data.conditions.length;
    const extraHeight = len === 1 ? 6 : len ? 6 + (len - 1) * conditionHeight : 0;
    return {
      ...sN,
      type: SENSOR_NODE_TYPE,
      height: sensorNodeHeight + extraHeight,
    };
  });
};

const prepAlgoNodes = (algoNodes: IAlgoNode[]) => {
  return algoNodes.map((aN) => ({
    ...aN,
    type: ALGO_NODE_TYPE,
    height: algoNodeHeight,
  }));
};

const prepControlModeNodes = (controlModeNodes: IControlModeNode[]) => {
  return controlModeNodes.map((ctlN) => ({
    ...ctlN,
    type: CONTROL_MODE_NODE_TYPE,
    height: algoNodeHeight,
  }));
};

const prepActuatorNodes = (actuatorNodes: IActuatorNode[]) => {
  return actuatorNodes.map((aN) => ({
    ...aN,
    type: ACTUATOR_NODE_TYPE,
    height: actuatorHeightMap[aN.data.type as keyof typeof actuatorHeightMap],
  }));
};

// --------------- Create edges ---------------
// NOTE: we're adding more than is normally just on an Edge type

const prepEdgesFromNodes = (nodes: IAllNodes[]) => {
  return nodes.flatMap((n) => {
    return n.targets
      .filter((id) => id && id !== 'none')
      .map((targetId) => {
        const sending = typeof n.sending === 'function' ? n.sending(targetId) : n.sending;
        return {
          id: `${n.id}-${targetId}`,
          // animated: sending, // comment in for animated dashed lines when `sending` is `true`
          zIndex: sending ? 2 : 1,
          source: n.id,
          target: targetId,
          // ELK expects "sources" and "targets", even though React Flow only needs/uses "source" and "target"
          sources: [n.id],
          targets: [targetId],
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: sending ? theme.palette.success.pastel : theme.palette.background.lightest,
          },
          type: 'smoothstep',
          style: {
            stroke: sending ? theme.palette.success.pastel : theme.palette.background.lightest,
          },
        };
      });
  });
};

// ======================================================== Elk ========================================================
const elk = new ELK();

const elkLayout = (nodes: IAllNodes[], edges: ElkExtendedEdge[], placementStrategyIdx: number) => {
  const nodesForElk = nodes.map((node) => {
    return {
      ...node,
      width: COLUMN_WIDTH,
      // All heights should be explicitly defined in individual prep functions, but default here with `??` just in case.
      // This prevents crashing if height is missing, and then we can update it to something explicitly if it looks
      // weird with `BASE_NODE_HEIGHT`
      height: node.height ?? BASE_NODE_HEIGHT,
    };
  });
  const graph = {
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'RIGHT',
      'nodePlacement.strategy': elkNodePlacementStrategies[placementStrategyIdx],
    },

    children: nodesForElk,
    edges: edges,
  };
  return elk.layout(graph);
};

// ========================================== NodeTypes, and Nodes/Edges Hook ==========================================
export const nodeTypes = {
  [SENSOR_NODE_TYPE]: SensorNode,
  [ALGO_NODE_TYPE]: AlgoNode,
  [CONTROL_MODE_NODE_TYPE]: ControlModeNode,
  [ACTUATOR_NODE_TYPE]: ActuatorNode,
};

/** Creates edges and prepares nodes' initial positions */
const usePrepNodesAndEdges = (
  sensorNodes: ISensorNode[],
  algoNodes: IAlgoNode[],
  controlModeNodes: IControlModeNode[],
  actuatorNodes: IActuatorNode[],
  placementStrategyIdx: number
) => {
  const [preparedNodes, setPreparedNodes] = useState<Node[]>([]);
  const [preparedEdges, setPreparedEdges] = useState<Edge[]>([]);

  const senNs = useMemo(() => prepSensorNodes(sensorNodes), [sensorNodes]);
  const algNs = useMemo(() => prepAlgoNodes(algoNodes), [algoNodes]);
  const ctlNs = useMemo(() => prepControlModeNodes(controlModeNodes), [controlModeNodes]);
  const actNs = useMemo(() => prepActuatorNodes(actuatorNodes), [actuatorNodes]);

  const nodes = useMemo(
    () => [...senNs, ...algNs, ...ctlNs, ...actNs],
    [senNs, algNs, ctlNs, actNs]
  );

  const edges = useMemo(() => prepEdgesFromNodes(nodes), [nodes]);

  useEffect(() => {
    elkLayout(nodes, edges, placementStrategyIdx)
      .then((graph) => {
        const positionedNodes = graph.children?.map((node) => {
          const { x, y, ...nodeRest } = node;
          // ELK puts "x" and "y" in the node object, but React Flow needs them nested under "position"
          return {
            ...nodeRest,
            position: { x, y },
          };
        });
        setPreparedNodes((positionedNodes as Node[]) ?? []);
        // @ts-ignore // typescript doesn't recognize enough similarities between ElkExtendedEdge and Edge
        setPreparedEdges((graph.edges as Edge[]) ?? []);
      })
      .catch((e) => {
        if (!isProd()) console.error(e);
      });
  }, [nodes, edges, placementStrategyIdx]);

  return [preparedNodes, preparedEdges] as [Node[], Edge[]];
};

export default usePrepNodesAndEdges;
