import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faBookOpen,
  faCodeBranch,
  faLayerGroup,
  faLink,
  faUsersBetweenLines,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton, Tooltip } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DOCUMENTATION_URL } from 'config';
import { useActiveEntities, useSelectById, useUser } from 'hooks';
import useWorkspace from 'hooks/useWorkspace';
import logo from 'multimedia/brand/logo_secondary_white.png';
import logo_text from 'multimedia/brand/logo_sedaro_primary_white_text.png';
import { DataContext } from 'providers';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import Routes, { getSearchParams, routePathsCommon } from 'routes';
import theme, { offWhite } from 'theme';
import { mmTypesInfo } from 'utils/repoAndMmType';
import SupportDialog from '../dialogs/SupportDialog';
import useStyles from './styles';

const h1Style = {
  fontSize: 14,
  color: theme.palette.text.tertiary,
};

const SHAREABLE_LINK_NAME = 'Shareable Link';

const Wayfinder = () => {
  const { id: resourceId } = useParams<{ id: string }>();
  const { share, agentId } = getSearchParams();
  const history = useHistory();
  const location = history.location;
  const user = useUser();
  const dataContext = useContext(DataContext);

  const workspace = useWorkspace();
  const { branch } = useActiveEntities();
  const repoId = location.pathname.startsWith('/repositories') ? resourceId : branch?.repository;
  const repo = useSelectById('Mission', repoId);
  const projectId = location.pathname.startsWith('/project') ? resourceId : repo?.project;
  const project = useSelectById('Project', projectId || '');
  const agentAnalyze = useMemo(() => {
    if (agentId && location.pathname.startsWith('/agent-analyze')) {
      const agent = dataContext?.staticModels?.scenario?._blocksById[agentId];
      return agent
        ? {
            ...agent,
            metamodelType: dataContext?.staticModels?.agents[agentId]._metamodel.type,
          }
        : { id: agentId, name: 'Analyze', metamodelType: 'Spacecraft' }; // Default while data is being fetched
    }
    return null;
  }, [location, dataContext, agentId]);

  const classes = useStyles({ user });
  const [supportDialogConfig, setSupportDialogConfig] = useState({
    open: false,
  });

  const pathEntities = useMemo(() => {
    let title = 'Sedaro';
    const entities = [];
    if (workspace?.name) {
      entities.push({
        ...workspace,
        route: Routes.WORKSPACE(workspace.id, routePathsCommon.REPOSITORIES),
        icon: faUsersBetweenLines,
      });
      title = `Sedaro | ${workspace.name}`;
    }
    if (project?.name) {
      entities.push({
        ...project,
        route: Routes.PROJECT(project.id),
        icon: faLayerGroup,
      });
      title = `Sedaro | ${project.name}`;
    }
    if (repo?.name) {
      entities.push({ ...repo, route: Routes.BRANCH(repo.id), icon: faCodeBranch });
      title = `Sedaro | ${repo.name}` + (branch?.name ? ` (${branch.name})` : '');
    } else if (share && branch?.id) {
      entities.push({
        id: SHAREABLE_LINK_NAME,
        name: SHAREABLE_LINK_NAME,
        route: '',
        icon: faLink,
      });
      title = 'Sedaro';
    }
    if (branch?.name) {
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.delete('agentId');
      entities.push({
        ...branch,
        route:
          Routes.SCENARIO(branch.id, routePathsCommon.ANALYZE) +
          '/agents?' +
          newSearchParams.toString(),
        icon: mmTypesInfo[branch.metamodelType].iconSrc,
      });
    }
    if (agentAnalyze) {
      entities.push({
        id: agentAnalyze.id,
        name: agentAnalyze.name,
        route: undefined,
        icon: mmTypesInfo[agentAnalyze.metamodelType as keyof typeof mmTypesInfo]?.iconSrc, // Surely there is a better typescript way to do this
      });
    }
    if (entities.length) entities[entities.length - 1].route = location.pathname + location.search;
    document.title = title;
    return entities;
  }, [workspace, project, repo, share, branch, agentAnalyze, location]);

  // Update the user's stored bookmarks when opening a branch
  useEffect(() => {
    if (user?.id && branch?.id && !share) {
      const userData = JSON.parse(localStorage.getItem(user.id) || '{}');
      const storedBookmarks = userData?.bookmarks;
      if (storedBookmarks && storedBookmarks.length) {
        let i = 0;
        let isPinned = false;
        let pinIndex = false;
        const newBookmarks = [];
        while (newBookmarks.length < 10 && i < storedBookmarks.length) {
          const item = storedBookmarks[i];
          if (item.pinned) {
            if (item.id === branch.id) {
              isPinned = true;
            }
            newBookmarks.push(item);
          } else if (item.id !== branch.id) {
            if (!pinIndex && !isPinned) {
              pinIndex = true;
              newBookmarks.push({ id: branch.id, pinned: false });
            }
            newBookmarks.push(item);
          }
          i++;
        }
        if (newBookmarks.length === 0) newBookmarks.push({ id: branch.id, pinned: false });
        localStorage.setItem(
          user.id,
          JSON.stringify({
            ...userData,
            bookmarks: newBookmarks,
          })
        );
      } else {
        localStorage.setItem(
          user.id,
          JSON.stringify({ ...userData, bookmarks: [{ id: branch.id, pinned: false }] })
        );
      }
    }
  }, [user, branch, share]);

  return (
    <>
      <div className={classes.wayfinderRoot}>
        <NavLink exact to={Routes.ROOT()} className={classes.logoContainer}>
          {/* Logo, top left */}
          <img
            src={logo}
            alt="Unified system modeling and simulation with Sedaro"
            className={classes.logo}
          />
          <img
            src={logo_text}
            alt="Unified system modeling and simulation with Sedaro"
            className={classes.logoText}
          />
        </NavLink>
        {pathEntities.map((entity, i) => (
          // Wayfinder items in the actual path
          <div className={classes.wayfinderItem} key={entity.id}>
            <h1>/</h1>
            <a
              style={
                // Disable hyperlink for shareable link label
                entity?.name === SHAREABLE_LINK_NAME
                  ? {
                      // @ts-ignore-next-line: `pointer-events: none` is technically experimental, but widely supported
                      pointerEvents: 'none',
                      cursor: 'default',
                    }
                  : i === pathEntities.length - 1
                  ? {
                      color: offWhite(1),
                      fontSize: h1Style.fontSize,
                    }
                  : undefined
              }
              href={`/#${entity.route}`}
            >
              {typeof entity.icon !== 'string' ? (
                // FontAwesomeIcon vs img
                <FontAwesomeIcon icon={entity.icon} className={classes.wayfinderIcon} />
              ) : (
                <img
                  src={entity.icon}
                  className={classes.wayfinderIcon}
                  style={i !== pathEntities.length - 1 ? { filter: 'brightness(50%)' } : undefined}
                  alt="scenario-logo"
                />
              )}
              <h1>{entity.name}</h1>
            </a>
          </div>
        ))}
        <div className={classes.helpRoot}>
          {/* Help icons, top right */}
          <span className={classes.helpIconsContainer + ' joyride-support-button'}>
            <Tooltip arrow title="Documentation">
              <a
                href={DOCUMENTATION_URL}
                target="_blank"
                rel="noreferrer"
                className={classes.helpIcon}
              >
                <FontAwesomeIcon icon={faBookOpen} />
              </a>
            </Tooltip>
            <Tooltip arrow title="Support">
              <IconButton
                onClick={() => setSupportDialogConfig({ open: true })}
                disableFocusRipple={true}
                disableRipple={true}
                className={classes.helpIcon}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </IconButton>
            </Tooltip>
          </span>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <Tooltip arrow title={user ? 'Account' : 'Anonymous User'} className={classes.helpIcon}>
            <a href={user ? `/#${Routes.ACCOUNT()}` : undefined}>
              <span className={classes.accountAvatar}>
                {user && <h4>{user.firstName.charAt(0) + user.lastName.charAt(0)}</h4>}
                {!user && <AccountCircleIcon className={classes.anonymousUserIcon} />}
              </span>
            </a>
          </Tooltip>
        </div>
      </div>
      <SupportDialog
        config={supportDialogConfig}
        onClose={() =>
          setSupportDialogConfig({
            ...supportDialogConfig,
            open: false,
          })
        }
      />
    </>
  );
};

export default Wayfinder;
