import { makeStyles } from '@material-ui/core/styles';
import { timing } from 'config';

interface IProps {
  highlight: boolean;
}

const useStyles = makeStyles((theme) => ({
  returnToWorkspaceContainer: (props: IProps) => ({
    background: props.highlight ? theme.palette.background.light : undefined,
    border: `2px dashed ${theme.palette.text.secondary}`,
    color: theme.palette.text.secondary,
    borderRadius: '5px',
    padding: '4em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'default',
    justifyContent: 'center',
    margin: '1em 0em',
    textAlign: 'center',
  }),
  fadeOut: {
    opacity: 0,
    transition: 'opacity 0.5s 0.5s',
  },
  fadeIn: {
    opacity: 1,
    transition: `opacity 0.5s, background ${timing.dragDropHighlightDuration}s ease`,
  },
  up: {
    transition: 'all 0.25s ease',
  },
  down: {
    transition: 'all 0.25s ease',
    transform: 'rotate(180deg)',
  },
}));

export default useStyles;
