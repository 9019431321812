import { setUserId } from 'components/RootView/missionExplorerSlice';
import A from 'components/general/A';
import StyledButton from 'components/general/StyledButton';
import StyledLabel from 'components/general/StyledLabel';
import WaveContainer from 'components/general/WaveContainer';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox/index.jsx';
import LabeledPasswordInput, {
  passwordSchema,
} from 'components/general/inputs/LabeledInput/LabeledPasswordInput.jsx';
import LabeledInput from 'components/general/inputs/LabeledInput/index.jsx';
import LabeledSelect from 'components/general/inputs/LabeledSelect/index.jsx';
import AuthWidget from 'components/general/widgets/AuthWidget';
import {
  DISABLED_RECAPTCHA,
  EMAIL_REGEX,
  LICENSE_AGREEMENT_URL,
  TERMS_OF_USE_URL,
  gaEvents,
} from 'config';
import { useFormikForm, useSnackbar, useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import Routes from 'routes';
import { COUNTRIES } from 'utils/forms';
import * as Yup from 'yup';
import useStyles from './styles';

const defaultValues = {
  firstName: '',
  lastName: '',
  organization: '',
  userCountryCode: '',
  organizationCountryCode: '',
  email: '',
  password: '',
  licenseAgreementAccepted: false,
};

const requiredMessage = 'All fields are required';
const maxMessage = (name, length) => {
  return `${name} must be no more than ${length} characters`;
};

const signUpSchema = Yup.object().shape({
  firstName: Yup.string().required(requiredMessage).max(128, maxMessage('First name', 128)),
  lastName: Yup.string().required(requiredMessage).max(128, maxMessage('Last name', 128)),
  organization: Yup.string().required(requiredMessage).max(128, maxMessage('Organization', 128)),
  userCountryCode: Yup.object().required(requiredMessage),
  organizationCountryCode: Yup.object().required(requiredMessage),
  email: Yup.string()
    .required('Your email is required.')
    .max(320, maxMessage('Email', 320))
    .matches(EMAIL_REGEX, 'Please enter a valid email address.'),
  password: passwordSchema,
  licenseAgreementAccepted: Yup.boolean().oneOf(
    [true],
    'You must agree to the Sedaro License Agreement to continue.'
  ),
});

/* Signup
 * Renders signup component which displays signup form and handles signup validation and dispatch.
 * Redirects to ROOT. If user if already authenticated, redirects to ROOT.
 */
const SignUpView = () => {
  const {
    User: {
      actions: { register, getUser },
    },
  } = SatelliteApi;
  const user = useUser();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  let history = useHistory();
  let location = useLocation();

  const classes = useStyles();

  let { from } = location.state || { from: { pathname: Routes.ROOT() } };

  const { enqueueSnackbar } = useSnackbar();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptcha = useCallback(async () => {
    if (!executeRecaptcha || DISABLED_RECAPTCHA) return null;

    return await executeRecaptcha('signup');
  }, [executeRecaptcha]);

  useEffect(() => {
    // Redirect to ROOT if user is already authenticated
    if (user) {
      history.push(Routes.ROOT());
    }
    // Keep the dependency array empty, otherwise the history change triggers a redirect to ROOT always
  }, []); //eslint-disable-line

  const onSubmit = (values) => {
    setLoading(true);
    handleReCaptcha()
      .then((recaptchaToken) => {
        dispatch(
          register({
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            organization: values.organization,
            userCountryCode: values.userCountryCode.value,
            organizationCountryCode: values.organizationCountryCode.value,
            recaptchaToken,
            successCallback: () => {
              dispatch(
                getUser({
                  successCallback: (user) => {
                    dispatch(setUserId(user.id));
                    setLoading(false);
                    ReactGA.event(gaEvents.SIGNUP, {
                      category: 'Account',
                      action: 'Sign Up',
                      label: 'Sign Up Button',
                    });
                    // Callback to redirect user to their intended route (otherwise root) once successfully authenticated
                    history.replace(from);
                  },
                })
              );
            },
            failureCallback: (response) => {
              // failure callback, backend validation
              if (response.error.code === 'UNIQUENESS_VIOLATION') {
                enqueueSnackbar('An account with that email address already exists.');
              } else {
                enqueueSnackbar(response.error.message);
              }
              setFieldValue('password', '');
              setLoading(false);
            },
          })
        );
      })
      .catch(() => {
        console.log('recaptcha error');
      });
  };

  const { formik } = useFormikForm(defaultValues, onSubmit, signUpSchema);
  const { getFieldProps, setFieldValue, handleSubmit } = formik;

  // Change the default organization country to match the user's country
  const handleCountryChange = (value) => {
    if (!getFieldProps('organizationCountryCode').value) {
      setFieldValue('organizationCountryCode', value);
    }
  };

  return (
    <WaveContainer>
      <AuthWidget>
        <form onSubmit={handleSubmit}>
          <div className="split-inputs">
            <div id="left">
              <LabeledInput
                {...getFieldProps('firstName')}
                label="First Name"
                type="text"
                placeholder="First"
                autoComplete="on"
                autoFocus
              />
            </div>
            <div id="right">
              <LabeledInput
                {...getFieldProps('lastName')}
                label="Last Name"
                type="text"
                placeholder="Last"
                autoComplete="on"
              />
            </div>
          </div>
          <LabeledInput
            {...getFieldProps('organization')}
            label="Organization"
            type="text"
            placeholder="Organization"
            autoComplete="organization"
          />
          <LabeledSelect
            {...getFieldProps('userCountryCode')}
            formikOnChange={handleCountryChange}
            label="Country of Citizenship"
            options={COUNTRIES}
          />
          <LabeledSelect
            {...getFieldProps('organizationCountryCode')}
            label="Country of Organization"
            options={COUNTRIES}
          />
          <LabeledInput
            {...getFieldProps('email')}
            label="Email"
            type="text"
            placeholder="Email"
            autoComplete="on"
          />
          <LabeledPasswordInput label="Password" {...getFieldProps('password')} />
          <div className={classes.disclaimer}>
            <LabeledCheckbox {...getFieldProps('licenseAgreementAccepted')} />
            <StyledLabel>
              I acknowledge and agree to Sedaro's{' '}
              <A href={TERMS_OF_USE_URL} target="_blank">
                Terms of Service (ToS){' '}
              </A>
              and{' '}
              <A href={LICENSE_AGREEMENT_URL} target="_blank">
                SaaS License Agreement
              </A>
              , including export control related restrictions and obligations.
            </StyledLabel>
          </div>
          <StyledButton type="submit" disabled={loading} loading={loading} tallMargin fullWidth>
            Create account
          </StyledButton>
          <StyledButton
            type="button"
            disabled={loading}
            onClick={() => history.push({ pathname: Routes.LOGIN(), state: { from } })}
            framed
            fullWidth
          >
            Login
          </StyledButton>
        </form>
      </AuthWidget>
    </WaveContainer>
  );
};

export default withRouter(SignUpView);
