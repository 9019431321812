import { IJob } from 'components/general/types';
import { useMemo } from 'react';
import { useSearchParams } from 'routes';
import { useSelectById } from './stateHooks';
import { useAppSelector } from './typedHooks';

const empty = {} as IJob; // empty object to return if no latest job.  Not inlinded in order to avoid rerenders

// Returns the latest job in a simulation, based on a redux value that is set in ActiveBranchProvider
// (or reset by SimulationControls in ScenarioView)
const useLatestJob = () => {
  const latestJobId = useAppSelector((state) => state.ui.missionExplorer.latestJobId);
  const latestJob = useSelectById('Job', latestJobId || '');
  const [{ job: oldJobId }] = useSearchParams();
  const oldJob = useSelectById('Job', oldJobId);
  const targetJob = useMemo(
    () => (oldJobId ? oldJob || empty : latestJobId === null ? empty : latestJob),
    [latestJob, oldJob, oldJobId, latestJobId]
  );

  // If latestJobId === undefined, Job fetch either hasn't happened or is still loading
  // If latestJobId === null, there is no latest job on this scenario branch
  return [latestJobId === null ? empty : latestJob, targetJob];
};

export default useLatestJob;
