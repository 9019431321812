import { faAdd, faRefresh, faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Chip, Switch } from '@material-ui/core';
import { wGroupIndicesWorkspace } from 'components/RootView/menu';
import JobStatusBoard from 'components/general/JobStatusBoard';
import LoadingInlay from 'components/general/LoadingInlay';
import RowedExplorer, { IButtonProps } from 'components/general/RowedExplorer';
import ViewPortInlay from 'components/general/ViewPortInlay';
import WGroup from 'components/general/WGroup';
import { IGenericObject, IMission, IProject } from 'components/general/types';
import { usePermissionCheck, useSelectByIds } from 'hooks';
import useEntityDialogControl from 'hooks/EntityDialogControlHook';
import useWorkspace from 'hooks/useWorkspace';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { WorkspaceContext } from 'providers/WorkspaceProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WorkspaceVables } from 'utils/vable';
import ProjectDialog from './ProjectDialog';
import ProjectRow from './ProjectRow';
import RepoDialog from './RepoDialog';
import RepoRow from './RepoRow';
import UploadDialog from './UploadDialog';

const ReposBoard = () => {
  // Workspace
  const { loading, fetchWorkspace } = useContext(WorkspaceContext);
  const workspace = useWorkspace();

  // Utils
  const [showProjects, setShowProjects] = useState(!!workspace.projectViewOn);
  const canCreate = usePermissionCheck(WorkspaceVables.Permission.EDIT_REPOSITORY);
  const repoDialogControl = useEntityDialogControl<IMission>();
  const projectDialogControl = useEntityDialogControl<IProject>();
  const uploadDialogControl = useEntityDialogControl<IGenericObject>();
  const { Workspace } = SatelliteApi;
  const dispatch = useDispatch();

  // Models
  const projects = useSelectByIds('Project', workspace.projects);
  const repos = useSelectByIds('Mission', workspace.repositories).filter(
    (repo) => !(showProjects && repo.project)
  );

  // Project View vs Repo View toggle
  const setProjectViewState = useCallback(
    (projectViewOn: boolean) => {
      dispatch(Workspace.actions.setProjectViewState({ id: workspace.id, projectViewOn }));
      setShowProjects(projectViewOn);
    },
    [dispatch, Workspace, workspace]
  );

  useEffect(() => {
    if (workspace.projectViewOn === undefined) {
      setProjectViewState(true);
    }
  }, [workspace, setProjectViewState]);

  // Custom title component
  const workspaceTitle = (
    <div>
      <h1>{workspace.name}</h1>
      <h6>
        Show Project View
        <Switch
          size="small"
          color="primary"
          checked={showProjects}
          onChange={(e) => setProjectViewState(e.target.checked)}
        />
      </h6>
    </div>
  );

  const refreshReposButton = useMemo(
    () =>
      loading
        ? {
            icon: faSpinner,
            onClick: () => {
              // Do nothing
            },
            tooltip: 'Loading...',
            framed: true,
          }
        : {
            icon: faRefresh,
            onClick: fetchWorkspace,
            tooltip: 'Refresh repositories',
            framed: true,
          },
    [loading, fetchWorkspace]
  );

  const projectButtons: IButtonProps[] = [refreshReposButton];
  const repoButtons: IButtonProps[] = [];

  if (canCreate) {
    projectButtons.unshift({
      icon: faAdd,
      onClick: projectDialogControl.openDialogForNew,
      tooltip: 'Create project',
    });
    repoButtons.push({
      icon: faAdd,
      onClick: repoDialogControl.openDialogForNew,
      tooltip: 'Create repository',
    });
    repoButtons.push({
      icon: faUpload,
      onClick: uploadDialogControl.openDialogForNew,
      tooltip: 'Import',
    });
  }

  if (!showProjects) {
    repoButtons.push(refreshReposButton);
  }

  return (
    <WGroup index={wGroupIndicesWorkspace.REPOSITORIES} masonryConfig={{}}>
      {
        /** Projects */
        showProjects && (
          <RowedExplorer
            name={workspaceTitle}
            description={workspace.description}
            rowsTitle="Projects"
            buttonsProps={projectButtons}
          >
            {loading ? (
              <LoadingInlay text="Your workspace is loading" />
            ) : projects.length ? (
              projects.map((project) => <ProjectRow key={project.id} project={project} />)
            ) : (
              <ViewPortInlay text="No Projects." />
            )}
          </RowedExplorer>
        )
      }
      {/** Repositories **/}
      <RowedExplorer
        name={!showProjects ? workspaceTitle : undefined}
        description={!showProjects ? workspace.description : undefined}
        rowsTitle="Repositories"
        smallTitle
        buttonsProps={repoButtons}
      >
        {loading ? (
          <LoadingInlay text="Your workspace is loading" />
        ) : repos.length ? (
          repos.map((repo) => {
            const project = projects.find((p) => p.id === repo.project);
            return (
              <RepoRow
                key={repo.id}
                repo={repo}
                repoDialogControl={repoDialogControl}
                draggable={showProjects}
                tag={
                  // Tag to show project name when in repo view
                  project && <Chip label={project.name} size={'small'} style={{ height: '20px' }} />
                }
              />
            );
          })
        ) : (
          <ViewPortInlay text="No Repositories." />
        )}
      </RowedExplorer>
      <JobStatusBoard />
      <RepoDialog control={repoDialogControl} />
      <ProjectDialog control={projectDialogControl} />
      <UploadDialog control={uploadDialogControl} />
    </WGroup>
  );
};

export default ReposBoard;
