import { TTemperature } from 'components/general/types';
import * as Yup from 'yup';

const percent = (fieldName: string) => {
  return Yup.number()
    .required(fieldName + ' is required.')
    .max(100, fieldName + ' cannot be greater than 100%.')
    .min(0, fieldName + ' cannot be less than 0%.');
};

const temp = (fieldName: string) => {
  return Yup.object({
    degC: Yup.number()
      .required(fieldName + ' is required.')
      .min(-273.15, fieldName + ' cannot be less than absolute zero.')
  });
};

const validation = Yup.object().shape({
  irEmissivity: percent('IR emissivity'),
  solarAbsorptivity: percent('Solar absorptivity'),
  diffuseSolarReflectivity: percent('Diffuse solar reflectivity'),
  specularSolarReflectivity: percent('Specular solar reflectivity'),
  hotTempRating: temp('Hot temperature rating'),
  coldTempRating: temp('Cold temperature rating').test(
    'Cold less than Hot',
    'Cold temperature rating cannot be greater than hot temperature rating.',
    function (coldTempRating: TTemperature | {degC: undefined}) {
      if (this.parent.hotTempRating?.degC && coldTempRating?.degC) {
        return coldTempRating.degC <= this.parent.hotTempRating.degC;
      }
      return true;
    }
  ),
});

export default validation;
