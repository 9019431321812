/* eslint no-template-curly-in-string: 0 */
import * as Yup from 'yup';

const validation = Yup.object().shape({
  openCircuitVoltage: Yup.number()
    .required('Open circuit voltage is required.')
    .moreThan(0, 'Open circuit voltage must be greater than 0.'),

  shortCircuitCurrent: Yup.number()
    .required('Short circuit current is required.')
    .moreThan(0, 'Short circuit current must be greater than 0.'),

  maxPowerVoltage: Yup.number()
    .required('Max power voltage is required.')
    .moreThan(0, 'Max power voltage must be greater than 0.')
    .lessThan(
      Yup.ref('openCircuitVoltage'),
      'Max power voltage must be less than open circuit voltage.'
    ),

  maxPowerCurrent: Yup.number()
    .required('Max power current is required.')
    .moreThan(0, 'Max power current must be greater than 0.')
    .lessThan(
      Yup.ref('shortCircuitCurrent'),
      'Max power current must be less than short circuit current.'
    ),

  numJunctions: Yup.number()
    .required('Number of junctions is required.')
    .integer('Number of junctions should be a positive integer.')
    .min(1, 'Number of junctions must be greater than 0. '),
});

export default validation;
