const guidance = {
  heading: 'Initial Attitude',
  body: [
    {
      chunk: 'Input the initial attitude and angular velocity for the agent. If you do not want to specify the initial values of these variables, uncheck the "Specify initial attitude" box.',
    },
    {
      subHeading: 'Attitude Quaternion',
      chunk: 'Input the initial body frame to ECI attitude quaternion for the agent.',
    },
    {
      subHeading: 'Angular Velocity',
      chunk: 'Input the initial body frame angular velocity.',
    }
  ],
};

export const attitudeGuidance = {
  attitude: guidance,
  angularVelocity: guidance,
};
