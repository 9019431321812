import { makeGuidance } from 'hooks';
import maxSecAlignGraphic from 'multimedia/guidance/pointing_mode_max_secondary_alignment_graphic.png';
import spinGraphic from 'multimedia/guidance/pointing_mode_spin_graphic.png';

const passiveDescription =
  'Passive pointing modes employ no control algorithms, meaning that the attitude is either not controlled at all or is controlled by passive means (such as gravity gradient or magnetic moments).  Optionally, this pointing mode can be assigned body frame and reference vectors to evaluate the effectiveness of the passive control system.';
const directionLockDescription =
  'Direction Lock pointing mode locks one satellite-fixed (i.e. body frame) vector to a specified lock direction.  Optionally, this pointing mode can be configured to rotate about the lock direction at a specified spin rate.';
const maxSecAlignDescription =
  'Max Secondary Alignment pointing mode locks one satellite-fixed (i.e. body frame) vector to a specified lock direction and simultaneously maximizes the alignment of a secondary satellite body frame vector with a secondary pointing direction.';
const pointDirDescription =
  'Pointing direction options include directions linked to your satellite\'s orbital motion and all of your mission\'s targets. If you select the "Target" option, an additional menu will appear to select a target. \n Nadir points to the geocenter and Zenith points away from the geocenter. \n Ram is coincident with the satellite velocity vector. \n Positive Cross Track is coincident with the angular velocity vector of the orbit. \n Positive Along Track is perpendicular to the Zenith and Cross Track directions and has a positive projection onto Ram. ';
const pointBfDescription =
  'Click on the "+" to open the Spacecraft Dialog > Geometry Tab, where you can add spacecraft body frame vectors or edit existing ones.';

const useGuidance = makeGuidance({
  _default: {
    heading: 'Pointing Mode Inputs',
    body: [
      {
        chunk:
          'Input a descriptive name for the pointing mode and select the appropriate pointing mode type from the options below.',
      },
      {
        subHeading: 'Passive Pointing Mode',
        chunk: passiveDescription,
      },
      {
        subHeading: 'Max Secondary Alignment Pointing Mode',
        chunk: maxSecAlignDescription,
        graphic: maxSecAlignGraphic,
      },
      {
        subHeading: 'Direction Lock Mode',
        chunk: directionLockDescription,
        graphic: spinGraphic,
      },
    ],
  },

  name: {
    alias: '_default',
  },

  type: {
    alias: '_default',
  },

  pointingDirectionA: (values) => {
    let pointingType = values.type.value;
    if (pointingType === 'MaxAlignPointingMode') {
      return {
        heading: 'Max Secondary Alignment Pointing Mode',
        body: [
          {
            chunk: maxSecAlignDescription,
            graphic: maxSecAlignGraphic,
          },
          {
            subHeading: 'Body Frame Vectors',
            chunk:
              'Select the lock and max aligned body frame vector from those defined for your spacecraft. ' +
              pointBfDescription,
          },
          {
            subHeading: 'Pointing Directions',
            chunk: pointDirDescription,
          },
        ],
      };
    } else if (pointingType === 'LockSpinPointingMode') {
      return {
        heading: 'Direction Lock Pointing Mode',
        body: [
          {
            chunk: directionLockDescription,
            graphic: spinGraphic,
          },
          {
            subHeading: 'Body Frame Vector',
            chunk:
              'Select the lock body frame vector from those defined for your spacecraft. ' +
              pointBfDescription,
          },
          {
            subHeading: 'Pointing Direction',
            chunk: pointDirDescription,
          },
          {
            subHeading: 'Spin Rate',
            chunk:
              'Spin rate about the lock direction is positive clockwise and can be negative or zero.',
          },
        ],
      };
    } else if (pointingType === 'PassivePointingMode') {
      return {
        heading: 'Passive Pointing Mode',
        body: [
          {
            chunk: passiveDescription,
          },
          {
            subHeading: 'Tracked Body Frame Vector',
            chunk:
              'Optionally, select a body frame vector to track alignment. This will only affect analytics.',
          },
          {
            subHeading: 'Pointing Direction',
            chunk:
              'Optionally, select a reference vector to track alignment to. This will only affect analytics.',
          },
        ],
      };
    }
  },

  lockBodyFrameVector: {
    alias: 'pointingDirectionA',
  },

  bodyFrameVector: {
    alias: 'pointingDirectionA',
  },

  maxAlignBodyFrameVector: {
    alias: 'pointingDirectionA',
  },

  referenceVector: {
    alias: 'pointingDirectionA',
  },

  lockVector: {
    alias: 'pointingDirectionA',
  },

  maxAlignVector: {
    alias: 'pointingDirectionA',
  },

  pointingModeParams: {
    spinRate: {
      rpm: {
        alias: 'pointingDirectionA',
      },
    },
  },
});
export default useGuidance;
