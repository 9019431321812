import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 8,
  },
  toolStatus: {
    display: 'inline-block',
    width: 40,
    marginBottom: 5,
    marginTop: 5,
  },
  toolBtn: {
    display: 'inline-block',
    marginLeft: 'auto !important',
    marginRight: '0px !important',
  },
  toolName: {
    display: 'inline-block',
    maxWidth: 120,
  },
  simControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
  },
  simDesriptors: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > p': {
      fontSize: 12,
      fontStyle: 'italic',
      textAlign: 'center',
      color: theme.palette.info.note,
      padding: 0,
      margin: 0,
    },
  },
  simulateButton: {
    '& .MuiButtonBase-root': {
      padding: '0px 5px 0px 10px',
      borderRight: '0.1px solid ' + theme.palette.background.main,
      borderRadius: '3px 0px 0px 3px',
    },
  },
  simulateDropdown: {
    '& .MuiButtonBase-root': {
      padding: '0px 10px 0px 5px',
      borderRadius: '0px 3px 3px 0px',
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  jobMenu: {
    height: '90vh',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.noScroll,
  },
  interiorWrapper: {
    backgroundColor: theme.palette.background.main,
    marginLeft: 20,
    padding: 10,
    borderRadius: 5,
    border: '1px solid ' + theme.palette.text.tertiary,
    height: 'min-content',
  },
  jobControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 4,
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.background.mainNested,
    },
  },
  interior: {
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    height: 'min-content',
    '& .MuiIconButton-root': {
      width: 30,
      height: 30,
    },
  },
  runningJobs: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 200,
    scrollbarGutter: 'stable',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.background.paperNested} transparent`,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: theme.palette.background.paperNested,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.text.tertiary,
    },
  },
}));

export default useStyles;
