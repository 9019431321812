const fovOption = { value: 'FOV', label: 'Field Of View' };
const compareOption = { value: 'COMPARE', label: 'Comparison' };

const operatorTypes = [
  { value: 'ALL', label: 'all' },
  { value: 'ANY', label: 'any' },
  { value: 'COUNT', label: 'count' },
];

const topLevelConditionTypes = [
  compareOption,
  fovOption,
  { value: 'MULTI', label: 'Compound Condition' },
  { value: 'SAME', label: 'Same Target Multi-Condition' },
];

const sharedParameterCategories = [
  { value: 'SATELLITE', label: 'Satellite Parameter' },
  { value: 'TARGET', label: 'Target Parameter' },
];

const timeCategories = [
  { value: 'TIME', label: 'Date and Time' },
  { value: 'ELAPSED_TIME', label: 'Elapsed Time', units: 's' },
];

const booleanValues = [
  { value: 1, label: 'True' },
  { value: 0, label: 'False' },
];

const trueAnomOption = {
  value: 'TRUE_ANOM',
  label: 'True Anomaly',
  units: 'deg',
};
const meanAnomOption = {
  value: 'MEAN_ANOM',
  label: 'Mean Anomaly',
  units: 'deg',
};
const localSiderealTimeOption = {
  value: 'LOCAL_SIDEREAL_TIME',
  label: 'Local Sidereal Time',
  units: 'deg',
};
const llaOptions = (includeHeading) => [
  {
    value: 'LAT',
    label: 'Latitude' + (includeHeading ? ' (North)' : ''),
    units: 'deg',
  },
  {
    value: 'LON',
    label: 'Longitude' + (includeHeading ? ' (East)' : ''),
    units: 'deg',
  },
  { value: 'ALT', label: 'Altitude', units: 'km' },
];
const baseTargetOptions = [
  { value: 'LO_SIGHT', label: 'Line of Sight', units: 'bool' },
  { value: 'RANGE', label: 'Range', units: 'km' },
];
const constantOnlyScalarOptions = [
  { value: 'BATTERY_SOC', label: 'Battery State of Charge', units: '%' },
];

const targetOptionSpecs = {
  SATELLITE: [
    {
      value: 'SHADOW',
      label: 'Eclipse State',
      units: 'eclipse',
    },
    { value: 'BETA', label: 'Beta Angle', units: 'deg' },
    meanAnomOption,
    trueAnomOption,
    localSiderealTimeOption,
    ...llaOptions(true),
    ...constantOnlyScalarOptions,
  ],
  SATELLITE_B: [
    {
      value: 'SHADOW',
      label: 'Eclipse State',
      units: 'eclipse',
    },
    { value: 'BETA', label: 'Beta Angle', units: 'deg' },
    meanAnomOption,
    trueAnomOption,
    localSiderealTimeOption,
    ...llaOptions(true),
  ],
  SpaceTarget: [
    ...baseTargetOptions,
    meanAnomOption,
    trueAnomOption,
    localSiderealTimeOption,
    ...llaOptions(false),
  ],
  CelestialTarget: [...baseTargetOptions, ...llaOptions(false)],
  GroundTarget: [
    ...baseTargetOptions,
    ...llaOptions(true),
    { value: 'SOLAR_AZ', label: 'Solar Azimuth', units: 'deg' },
    { value: 'SOLAR_EL', label: 'Solar Elevation', units: 'deg' },
    { value: 'SELF_AZ', label: 'Satellite Azimuth', units: 'deg' },
    { value: 'SELF_EL', label: 'Satellite Elevation', units: 'deg' },
    localSiderealTimeOption,
  ],
};

const boolOptionSpecs = {
  SATELLITE: [
    {
      value: 'SHADOW',
      label: 'Eclipse State',
      units: 'eclipse',
    },
  ],
  SpaceTarget: [{ value: 'LO_SIGHT', label: 'Line of Sight', units: 'bool' }],
  CelestialTarget: [{ value: 'LO_SIGHT', label: 'Line of Sight', units: 'bool' }],
  GroundTarget: [{ value: 'LO_SIGHT', label: 'Line of Sight', units: 'bool' }],
};

const scalarOption = { value: 'SCALAR', label: 'Constant' };

const quantityKindOptions = ['ELAPSED_TIME'];

const options = {
  fovOption,
  compareOption,
  operatorTypes,
  topLevelConditionTypes,
  sharedParameterCategories,
  timeCategories,
  booleanValues,
  trueAnomOption,
  meanAnomOption,
  localSiderealTimeOption,
  llaOptions,
  baseTargetOptions,
  constantOnlyScalarOptions,
  targetOptionSpecs,
  boolOptionSpecs,
  scalarOption,
  quantityKindOptions,
};

export default options;
