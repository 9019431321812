import { makeStyles } from '@material-ui/core/styles';

export const bookmarkWidth = 300;
export const bookmarkMargin = 20;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    maxHeight: 400,
  },
  bookmarkBar: {
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    margin: '10px',
    position: 'relative',
    ...theme.mixins.noScroll,
  },
  leftButton: {
    position: 'absolute',
    left: 5,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 50,
    height: 50,
    fontSize: 30,
    border: '1px solid ' + theme.palette.text.tertiary,
    background:
      'linear-gradient(20deg, ' +
      theme.palette.background.darker +
      ' -50%, ' +
      theme.palette.background.light +
      ' 150%)',
    color: theme.palette.background.contrastText,
    boxShadow: '0 0 5px 2px ' + theme.palette.background.darker,
    '&:hover': {
      boxShadow: '0 0 5px 2px ' + theme.palette.background.light,
    },
  },
  rightButton: {
    position: 'absolute',
    right: 5,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 50,
    height: 50,
    fontSize: 30,
    border: '1px solid ' + theme.palette.text.tertiary,
    background:
      'linear-gradient(-20deg, ' +
      theme.palette.background.darker +
      ' -50%, ' +
      theme.palette.background.light +
      ' 150%)',
    color: theme.palette.background.contrastText,
    boxShadow: '0 0 5px 2px ' + theme.palette.background.darker,
    '&:hover': {
      boxShadow: '0 0 5px 2px ' + theme.palette.background.light,
    },
  },
  bookmark: (props) => ({
    backgroundColor: theme.palette.background.main,
    borderRadius: 10,
    width: bookmarkWidth,
    margin: bookmarkMargin,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    border: '1px solid ' + props.color1,
    boxShadow: theme.shadows[5],
    '&:hover': {
      boxShadow: '0 0 10px 0px ' + props.color1,
    },

    '& .MuiCard-root': {},
    '& .MuiCardHeader-root': {
      background: 'linear-gradient(20deg, ' + props.color1 + ' -50%, ' + props.color2 + ' 150%)',
    },
    '& .MuiCardContent-root': {
      height: 300,
      padding: 0,
    },
  }),
  pinButton: (props) => ({
    height: 40,
    width: 40,
    fontSize: 20,
    padding: 0,
    margin: 0,
    color: props.pinned ? 'gold' : theme.palette.text.primary,
    '&:hover': {
      color: 'gold',
    },
  }),
  modelWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    '& canvas': {
      position: 'absolute',
      left: 0,
      top: 0,
      outline: 'none',
      width: '100%',
      height: '100%',
    },
    '& .cesium-widget-credits': {
      display: 'none !important',
    },
  },
  spinnerWrapper: {
    textAlign: 'center',
    paddingTop: 50,
  },
}));

export default useStyles;
