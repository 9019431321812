import { faRefresh, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { setActiveWorkspaceId } from 'components/RootView/missionExplorerSlice';
import ClipboardCopy from 'components/general/ClipboardCopy';
import LoadingInlay from 'components/general/LoadingInlay';
import RowedExplorer from 'components/general/RowedExplorer';
import Row from 'components/general/RowedExplorer/Row';
import useStyles from 'components/general/RowedExplorer/Row/styles';
import ViewPortInlay from 'components/general/ViewPortInlay';
import { SUPPORT_EMAIL } from 'config';
import { useSelectAll, useSnackbar, useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes, { routePathsCommon } from 'routes';

const WorkspaceExplorer = () => {
  const history = useHistory();
  const user = useUser();
  const workspaces = useSelectAll('Workspace');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const {
    Workspace: {
      actions: { getWorkspaces },
    },
  } = SatelliteApi;

  const fetchWorkspaces = useCallback(() => {
    setLoading(true);
    dispatch(
      getWorkspaces({
        successCallback: () => setLoading(false),
        failureCallback: () => {
          enqueueSnackbar(
            `Failed to retrieve workspaces. Please refresh the page and reach out to ${SUPPORT_EMAIL} if error persists.`
          );
          setLoading(false);
        },
      })
    );
  }, [dispatch, getWorkspaces, enqueueSnackbar]);

  useEffect(() => {
    if (workspaces.length !== user.workspaces.length) fetchWorkspaces();
  }, [workspaces.length, user.workspaces.length, fetchWorkspaces]);

  return (
    <RowedExplorer
      rowsTitle="Workspaces"
      buttonsProps={[
        loading
          ? {
              icon: faSpinner,
              onClick: () => {
                // Do nothing
              },
              tooltip: 'Loading...',
              framed: true,
            }
          : {
              icon: faRefresh,
              onClick: fetchWorkspaces,
              tooltip: 'Refresh workspaces',
              framed: true,
            },
      ]}
    >
      {loading ? (
        <LoadingInlay text="Your workspaces are loading" />
      ) : workspaces.length ? (
        workspaces
          .sort((w1, w2) => w2.dateModified.diff(w1.dateModified))
          .map(({ id, name }) => {
            return (
              <Row
                key={id}
                name={name}
                onClick={() => {
                  dispatch(setActiveWorkspaceId(id));
                  history.push(Routes.WORKSPACE(id, routePathsCommon.REPOSITORIES));
                }}
                description={
                  <div className={classes.subDescription}>
                    ID: <ClipboardCopy text={id} />
                  </div>
                }
              />
            );
          })
      ) : (
        <ViewPortInlay
          text="No Workspaces. Create a new workspace from the navigation bar on the left or contact your
        workspace's administrator(s) to obtain an invitation."
          fontSize={18}
        />
      )}
    </RowedExplorer>
  );
};

export default WorkspaceExplorer;
