import { TARGETS } from 'components/AgentTemplateEditView/menu/targets';
import ClipboardCopy from 'components/general/ClipboardCopy';
import RowedExplorer from 'components/general/RowedExplorer';
import Row from 'components/general/RowedExplorer/Row';
import { IUrlParams } from 'components/general/types';
import WGroup from 'components/general/WGroup';
import LoadingView from 'components/LoadingView';
import { wGroupIndicesScenario } from 'components/ScenarioView/ScenarioAnalyzeView/menu';
import { useActiveEntities, useSnackbar } from 'hooks';
import { useModel } from 'middleware/SatelliteApi/template';
import { useDataContext } from 'providers';
import { useHistory, useParams } from 'react-router-dom';
import Routes, { getSearchParams } from 'routes';

const Agents = () => {
  const { branch, model } = useActiveEntities();
  const history = useHistory();
  const { id } = useParams<IUrlParams>();
  const { share } = getSearchParams();
  const { staticModels, currentJob } = useDataContext();
  const staticModel = useModel(staticModels.scenario);
  const { enqueueSnackbar } = useSnackbar();

  const makeButtonProps = (agentTemplateId: string) => {
    return {
      text: 'Edit Agent Template',
      onClick: () => history.push(Routes.AGENT_TEMPLATE_EDIT(agentTemplateId, TARGETS)),
    };
  };

  // second half of this (after "||") is for when user navigates to "Agent Template" and then clicks "back". The active
  // branch has to reset back to what it should be, which takes some time.
  if (!branch || branch.id !== id) return <LoadingView />;

  return (
    <WGroup index={wGroupIndicesScenario.ANALYZE_AGENTS}>
      <RowedExplorer
        name={branch.name}
        description={branch.description}
        rowsTitle="Templated Agents"
      >
        {staticModel.Agent.all().flatMap(({ id, name, templateRef, peripheral }) => {
          // flatMap turns empty array into nothing, thus nothing rendered for peripheral agents
          if (!staticModels.agents[id] || peripheral) return [];
          return (
            <Row
              key={id}
              name={name}
              onClick={() => {
                const menu = model.Menu.first();
                menu
                  ? history.push(
                      Routes.AGENT_ANALYZE(branch.id, menu.menuItems[0].id) +
                        `?agentId=${id}&job=${currentJob.id}${share ? '&share=' + share : ''}`
                    )
                  : enqueueSnackbar('Error loading analyze menu.');
              }}
              description={
                <span>
                  Source Branch ID: <ClipboardCopy text={templateRef} />
                </span>
              }
              buttonProps={makeButtonProps(templateRef)}
            />
          );
        })}
      </RowedExplorer>
    </WGroup>
  );
};

export default Agents;
