import { InputAdornment } from '@material-ui/core';
import GncAccent from 'components/general/Accent/variants/GncAccent';
import LabeledInput from 'components/general/inputs/LabeledInput';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';

interface ISpec {
  name: string;
  label: string;
  units?: string;
}

interface IProps {
  // Use 'any' type because it depends on where AttitudeForm is called from
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entityForm: ReturnType<typeof useEntityForm<any, any>>;
  deg?: boolean;
}
const AttitudeForm = ({ entityForm, deg }: IProps) => {
  const classes = useStyles();

  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <>
      <div className={classes.inputGroup}>
        <GncAccent header="Attitude Quaternion">
          {[
            {
              name: 'attitude.0',
              label: 'Quaternion X',
            },
            {
              name: 'attitude.1',
              label: 'Quaternion Y',
            },
            {
              name: 'attitude.2',
              label: 'Quaternion Z',
            },
            {
              name: 'attitude.3',
              label: 'Quaternion W',
            },
          ].map((spec: ISpec) => (
            <LabeledInput
              {...getFieldProps(spec.name)}
              key={spec.name}
              type="number"
              label={spec.label}
            />
          ))}
        </GncAccent>
        <GncAccent header="Angular Velocity">
          {[
            {
              name: 'angularVelocity.0',
              label: 'x-Component',
              units: `${deg ? 'deg' : 'rad'}/s`,
            },
            {
              name: 'angularVelocity.1',
              label: 'y-Component',
              units: `${deg ? 'deg' : 'rad'}/s`,
            },
            {
              name: 'angularVelocity.2',
              label: 'z-Component',
              units: `${deg ? 'deg' : 'rad'}/s`,
            },
          ].map((spec: ISpec) => (
            <LabeledInput
              {...getFieldProps(spec.name)}
              key={spec.name}
              type="number"
              endAdornment={
                spec.units && <InputAdornment position="end">{spec.units}</InputAdornment>
              }
              label={spec.label}
            />
          ))}
        </GncAccent>
      </div>
    </>
  );
};

export default AttitudeForm;
