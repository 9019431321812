import { Collapse, Switch } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { BfVectorAccent, SpacecraftAccent } from 'components/general/Accent/variants';
import GncAccent from 'components/general/Accent/variants/GncAccent';
import GuidanceCard from 'components/general/GuidanceCard';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import FallbackDialog from 'components/general/dialogs/FallbackDialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import WidgetTable from 'components/general/widgets/WidgetTable';
import WizardSegment from 'components/general/wizards/WizardSegment';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import {
  useActiveEntities,
  useAppDispatch,
  useEntityDialogControl,
  useEntityForm,
  useFormikForm,
  useSnackbar,
} from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { SpacecraftContext } from 'providers';
import { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { exclude, translateIn, translateOut } from 'utils/forms';
import { array2String } from 'utils/strings';
import { PointingModeVables, isAc, isAd, isOd, isTc } from 'utils/vable';
import * as Yup from 'yup';
import useGuidance from './guidance';
import pointingModeSchema from './validation';

const defaultValues = {
  name: '',
  type: '',
  odAlgorithm: '',
  adAlgorithm: '',
  acAlgorithm: '',
  tcAlgorithm: '',
  lockVector: '',
  lockBodyFrameVector: '',
  maxAlignVector: '',
  maxAlignBodyFrameVector: '',
  spinRateBool: false,
  spinRate: { rpm: '' },
  bodyFrameVector: '',
  referenceVector: '',
};

const typeOptions = PointingModeVables.Type.options;

const PointingModeDialog = ({ control, options }) => {
  const { model } = useActiveEntities();

  // Handle context
  const { setSpacecraftDialogConfig, SpacecraftTabs } = useContext(SpacecraftContext);
  const classes = useStyles();

  /* Need to fetch body frame vectors from ORM instead of attaching them to the dropdown option
   * because if a BFV is selected in the PointingModeDialog and it is then edited in the S/C Dialog,
   * the selected value in RHF won't reflect the change and form validation may be falsely pos/neg.*/
  const validateForm = useCallback(
    (values) => {
      let result = false;
      const a = model.BodyFrameVector.byId(values.lockBodyFrameVector)?.unitVector;
      const b = model.BodyFrameVector.byId(values.maxAlignBodyFrameVector)?.unitVector;
      if (a && b && array2String(a) === array2String(b)) {
        result = 'The locked and max-aligned body frame unit vectors cannot be equivalent';
      }
      return result;
    },
    [model]
  );

  const customTranslateIn = useCallback((pointingMode, defaultValues, options) => {
    if (pointingMode.spinRate?.rpm) {
      pointingMode.spinRateBool = true;
    } else if (pointingMode.spinRate?.rpm === 0) {
      pointingMode.spinRateBool = false;
    }
    return translateIn(pointingMode, defaultValues, options);
  }, []);

  const customTranslateOut = useCallback((pointingMode, allowedEmptyFields, options) => {
    /** If you toggle between pointing mode types, sometimes `lockBodyFrameVector` property will end up having been set
     * even when `type` is passive & the property is no longer needed -- due to our custom `formikOnChange`
     * for "Pointing Mode Type" to save it after it's set. Thus, always reset it when `type` is passive. */
    if (pointingMode.type.value === PointingModeVables.Type.PassivePointingMode.value) {
      pointingMode.lockBodyFrameVector = '';
      delete pointingMode.acAlgorithm;
    }
    if (
      !pointingMode.spinRateBool &&
      pointingMode.type.value === PointingModeVables.Type.LockSpinPointingMode.value
    ) {
      pointingMode.spinRate = { rpm: 0 };
    }
    return translateOut(pointingMode, allowedEmptyFields, options);
  }, []);

  const entityForm = useEntityForm({
    entityTypeText: 'Pointing Mode',
    entityDialogControl: control,
    validateForm,
    valuesToRemove: ['spinRateBool'],
    defaultValues,
    validationSchema: pointingModeSchema,
    formikOptionalParams: {
      useGuidance,
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
      allowedEmptyFields: [
        'odAlgorithm',
        'adAlgorithm',
        'acAlgorithm',
        'tcAlgorithm',
        'lockBodyFrameVector',
        'maxAlignBodyFrameVector',
      ],
    },
  });

  const { formik } = entityForm;

  const { getFieldProps, setFieldValue, values, setValues } = formik;
  const { type, lockBodyFrameVector, spinRateBool } = values;
  const bfVectorDialog = (fieldName, label, isOptional, excludedEntity) => {
    return (
      <>
        <BfVectorAccent
          onAddAction={() =>
            setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
          }
        >
          <LabeledSelect
            label={label}
            options={exclude(options[fieldName], excludedEntity)}
            optional={!!isOptional}
            isClearable
            {...getFieldProps(fieldName)}
            noOptionsMessage={() => 'Create a Body Frame Vector'}
          />
        </BfVectorAccent>
      </>
    );
  };
  const lockishVectorDialog = (fieldName, label, isOptional, excludedEntity) => {
    return (
      <SpacecraftAccent
        header="Reference Vector"
        onAddAction={() =>
          setSpacecraftDialogConfig({
            open: true,
            tabNumber: SpacecraftTabs.REFERENCE_VECTORS,
          })
        }
      >
        <LabeledSelect
          label={label}
          options={exclude(options[fieldName], excludedEntity)}
          optional={!!isOptional}
          {...getFieldProps(fieldName)}
        />
      </SpacecraftAccent>
    );
  };

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Pointing Mode Name"
            type="text"
            placeholder="Pointing Mode Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            label="Pointing Mode Type"
            options={options.type}
            {...getFieldProps('type')}
            formikOnChange={(val) => {
              if (val.value !== type?.value)
                setValues({
                  ...defaultValues,
                  name: values.name,
                  lockBodyFrameVector, // makes it so users don't have to set BFV again if change between types
                });
            }}
          />
          {type?.value && (
            <div className={classes.inputGroup}>
              <GncAccent header="Algorithms">
                <LabeledSelect
                  label="Orbit Determination Algorithm"
                  options={options.odAlgorithm}
                  optional
                  {...getFieldProps('odAlgorithm')}
                  noOptionsMessage={() => 'Create an Algorithm'}
                  formikOnChange={(val) => {
                    if (!val) setFieldValue('odAlgorithm', 'bas');
                  }}
                />
                <LabeledSelect
                  label="Attitude Determination Algorithm"
                  options={options.adAlgorithm}
                  optional
                  {...getFieldProps('adAlgorithm')}
                  noOptionsMessage={() => 'Create an Algorithm'}
                />
                {type?.value !== 'PassivePointingMode' && (
                  <>
                    <LabeledSelect
                      label="Attitude Control Algorithm"
                      options={options.acAlgorithm}
                      {...getFieldProps('acAlgorithm')}
                      noOptionsMessage={() => 'Create an Algorithm'}
                    />
                    <LabeledSelect
                      label="Thrust Control Algorithm"
                      options={options.tcAlgorithm}
                      optional
                      {...getFieldProps('tcAlgorithm')}
                      noOptionsMessage={() => 'Create an Algorithm'}
                    />
                  </>
                )}
              </GncAccent>
            </div>
          )}
          {type?.value && type?.value === 'PassivePointingMode' && (
            <div className={classes.inputGroup}>
              {bfVectorDialog('bodyFrameVector', 'Tracked BF Vector', true)}
              {lockishVectorDialog('referenceVector', 'Pointing Direction', true)}
            </div>
          )}
          {type?.value && type?.value !== 'PassivePointingMode' && (
            <div className={classes.inputGroup}>
              {bfVectorDialog(
                'lockBodyFrameVector',
                'Locked BF Vector',
                false,
                values.maxAlignBodyFrameVector
              )}
              {lockishVectorDialog(
                'lockVector',
                'Pointing Direction',
                false,
                values.maxAlignVector
              )}
            </div>
          )}
          {type?.value === 'MaxAlignPointingMode' && (
            <div className={classes.inputGroup}>
              {bfVectorDialog(
                'maxAlignBodyFrameVector',
                'Max Aligned BF Vector',
                false,
                values.lockBodyFrameVector
              )}
              {lockishVectorDialog(
                'maxAlignVector',
                'Pointing Direction',
                false,
                values.lockVector
              )}
            </div>
          )}
          {type?.value === 'LockSpinPointingMode' && (
            <>
              <LabeledCheckbox
                {...getFieldProps('spinRateBool')}
                label="Spin about locked direction"
                formikOnChange={() => {
                  setFieldValue('spinRate.rpm', defaultValues.spinRate.rpm);
                }}
              />
              {spinRateBool && (
                <LabeledInput
                  {...getFieldProps('spinRate.rpm')}
                  type="number"
                  endAdornment={<InputAdornment position="end">RPM</InputAdornment>}
                  label="Spin Rate"
                />
              )}
            </>
          )}
        </div>
      </div>
    </EntityDialog>
  );
};

const columns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (val) => typeOptions.find((o) => o.value === val.type)?.label,
  },
];

const PointingModesSegment = (props) => {
  const { index, ...remainingProps } = props;

  const pmEntityDialogControl = useEntityDialogControl();
  const { openDialogForExisting, openDialogForNew } = pmEntityDialogControl;

  const classes = useStyles();
  const { pointingModes, bodyFrameVectors, algorithms, referenceVectors, model, branch } =
    useActiveEntities();
  const attitudeDynamics = useMemo(() => model.AttitudeDynamics.first(), [model.AttitudeDynamics]);
  const [loading, setLoading] = useState(false);
  const {
    Block: {
      actions: { updateBlock },
    },
  } = SatelliteApi;
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const validation = Yup.object().shape({
    ideal: Yup.bool().required('Attitude Dynamics is required.'),
  });

  const defaultValues = { ideal: true, maxSlewRate: 100 };

  const { formik } = useFormikForm(
    defaultValues,
    ({ ideal, maxSlewRate }) => {
      const values = {
        type: ideal ? 'IdealOrbitalAttitudeDynamics' : 'OrbitalAttitudeDynamics',
      };
      if (ideal) values.maxSlewRate = maxSlewRate;
      setLoading(true);
      dispatch(
        updateBlock({
          branchId: branch.id,
          id: attitudeDynamics.id,
          ...values,
          successCallback: () => {
            enqueueSnackbar('Attitude dynamics updated successfully', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response) => {
            const errorMessage = response.error.message;
            enqueueSnackbar(errorMessage);
            setLoading(false);
          },
        })
      );
    },
    validation,
    {
      ideal: attitudeDynamics.type === 'IdealOrbitalAttitudeDynamics',
      maxSlewRate: attitudeDynamics.maxSlewRate ? attitudeDynamics.maxSlewRate['rad/s'] : undefined,
    }
  );

  const { handleSubmit, getFieldProps, setFieldValue, resetForm, dirty, values } = formik;

  const referenceVectorsList = useMemo(
    () =>
      referenceVectors?.map((vector) => ({
        value: vector.id,
        label: vector.name,
      })),
    [referenceVectors]
  );

  const bfVectorsList = useMemo(
    () =>
      bodyFrameVectors
        ?.filter((bfVector) => bfVector != null)
        .map((bfVector) => {
          return { value: bfVector.id, label: bfVector.name };
        }),
    [bodyFrameVectors]
  );

  const odAlgoList = useMemo(
    () => algorithms.filter((a) => isOd(a)).map((a) => ({ value: a.id, label: a.name })),
    [algorithms]
  );

  const adAlgoList = useMemo(
    () => algorithms.filter((a) => isAd(a)).map((a) => ({ value: a.id, label: a.name })),
    [algorithms]
  );

  const acAlgoList = useMemo(
    () => algorithms.filter((a) => isAc(a)).map((a) => ({ value: a.id, label: a.name })),
    [algorithms]
  );

  const tcAlgoList = useMemo(
    () => algorithms.filter((a) => isTc(a)).map((a) => ({ value: a.id, label: a.name })),
    [algorithms]
  );

  const options = useMemo(() => {
    return {
      type: typeOptions,
      lockVector: referenceVectorsList,
      referenceVector: referenceVectorsList,
      lockBodyFrameVector: bfVectorsList,
      bodyFrameVector: bfVectorsList,
      maxAlignVector: referenceVectorsList,
      maxAlignBodyFrameVector: bfVectorsList,
      odAlgorithm: odAlgoList,
      adAlgorithm: adAlgoList,
      acAlgorithm: acAlgoList,
      tcAlgorithm: tcAlgoList,
    };
  }, [bfVectorsList, odAlgoList, adAlgoList, acAlgoList, referenceVectorsList, tcAlgoList]);

  return (
    <Fragment>
      <WizardSegment
        title="Pointing Modes"
        index={index}
        onSubmit={handleSubmit}
        onReset={resetForm}
        disableSubmit={!dirty}
        loading={loading}
        {...remainingProps}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.swapRight}>
            <div style={{ maxWidth: 275, margin: 'auto' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p style={{ fontSize: 16, marginBottom: 0, marginTop: 0 }}>Idealized Pointing</p>
                <Switch
                  color="primary"
                  checked={values.ideal ? true : false}
                  onChange={(event) => {
                    setFieldValue('ideal', event.target.checked);
                    setFieldValue('maxSlewRate', '');
                  }}
                />
              </div>
              <Collapse in={values.ideal}>
                <LabeledInput
                  label="Maximum Slew Rate"
                  type="number"
                  optional
                  {...getFieldProps('maxSlewRate')}
                  endAdornment={<InputAdornment position="end">rad/s</InputAdornment>}
                />
              </Collapse>
            </div>
            <WidgetTable
              className={classes.table}
              columns={columns}
              data={pointingModes}
              onFabClick={openDialogForNew}
              onActionClick={openDialogForExisting}
              modelName="pointing mode"
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.swapLeft}>
            <GuidanceCard
              guidance={{
                heading: 'Create and Edit Pointing Modes',
                body: [
                  {
                    chunk:
                      'Pointing Modes determine the attitude (i.e. orientation) of your Satellite at each time step in your simulation. Use the Pointing Modes table to edit or add Pointing Modes.',
                  },
                  {
                    subHeading: 'Idealized Pointing Mode',
                    chunk:
                      'Switch between idealized pointing and full attitude dynamics. If enabled, the vehicle will point at the desired targets, subject to a slew rate constraint, without considering actuators and physical forces. If disabled, the regular physical force model is active and an attitude controller with assigned actuators is necessary to orient the vehicle.',
                  },
                  {
                    subHeading: 'Link to Operational Modes',
                    chunk:
                      'In the Operational Modes dialog under Command & Control, you can connect Pointing Modes to Operational Modes. When an Operational Mode is active, as defined by your ConOps logic, its associated Pointing Mode will dictate the commanded attitude of the vehicle. A Pointing Mode can be associated to multiple Operational Modes.',
                  },
                  {
                    subHeading: 'Relation to Algorithms',
                    chunk:
                      'In order to point at a target, the spacecraft must have Attitude Control, Attitude Determination, and Orbit Determination algorithms defined and assigned to an Active Pointing Mode.',
                  },
                ],
              }}
            />
          </Grid>
        </Grid>
      </WizardSegment>
      <ErrorBoundary
        fallback={
          <FallbackDialog
            config={pmEntityDialogControl.dialogConfig}
            onClose={pmEntityDialogControl.closeDialog}
            title={`Something went wrong while editing this pointing mode.`}
          />
        }
      >
        <PointingModeDialog control={pmEntityDialogControl} options={options} />
      </ErrorBoundary>
    </Fragment>
  );
};

export default PointingModesSegment;
