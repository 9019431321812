import { faFilter, faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import * as Cesium from 'cesium';
import StyledButton from 'components/general/StyledButton';
import Widget from 'components/general/widgets/Widget';
import { CESIUM_ACCESS_TOKEN, GOOGLE_MAPS_API_KEY } from 'config';
import { useActiveEntities } from 'hooks';
import { noop } from 'lodash';
import { TimeContext, useDataContext } from 'providers';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';
import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Camera,
  CameraFlyTo,
  Clock,
  Entity,
  Globe,
  LabelGraphics,
  Scene,
  ScreenSpaceCameraController,
  ShadowMap,
  SkyAtmosphere,
  SkyBox,
  Sun,
  Viewer,
} from 'resium';
import { jd2Mjd, mjd2Moment } from 'utils/time';
import FilterMenu from './FilterMenu';
import InfoBox from './InfoBox';
import SettingsMenu from './SettingsMenu';
import TargetEntity from './TargetEntity';
import Moon from './moon';
import useStyles from './styles';
import { perigeeCadScale } from './utils';

Cesium.Ion.defaultAccessToken = CESIUM_ACCESS_TOKEN;
try {
  Cesium.GoogleMaps.defaultApiKey = GOOGLE_MAPS_API_KEY;
} catch (e) {
  // window.location.reload(true); // bust cache
  // pass
}

export const terrainOptions = [
  {
    label: 'WGS84 Ellipsoid',
  },
  {
    label: 'Google Photorealistic 3D Tiles',
    primitives: async () => [await Cesium.createGooglePhotorealistic3DTileset()],
    disabledImagerySelect: true,
  },
  {
    label: 'Cesium World Terrain',
    terrainProvider: async () =>
      await Cesium.createWorldTerrainAsync({
        requestWaterMask: true,
        requestVertexNormals: true,
      }),
  },
  {
    label: 'Cesium World Terrain w/ OSM Buildings',
    primitives: async () => [await Cesium.createOsmBuildingsAsync()],
    terrainProvider: async () =>
      await Cesium.createWorldTerrainAsync({
        requestWaterMask: true,
        requestVertexNormals: true,
      }),
  },
].map((v, i) => ({ value: i, ...v }));
export const imageryOptions = [
  {
    label: 'Bing Maps Arial',
    imagery: async () => await Cesium.createWorldImageryAsync({}),
  },
  {
    label: 'Bing Maps Arial w/ Labels',
    imagery: async () =>
      await Cesium.createWorldImageryAsync({
        style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
      }),
  },
  {
    label: 'Bing Maps Road',
    imagery: async () =>
      await Cesium.createWorldImageryAsync({
        style: Cesium.IonWorldImageryStyle.ROAD,
      }),
  },
  {
    label: 'OpenStreetMaps',
    imagery: async () => new Cesium.OpenStreetMapImageryProvider(),
  },
  {
    label: 'ArcGIS World Street Maps',
    imagery: async () =>
      Cesium.ArcGisMapServerImageryProvider.fromUrl(
        'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer'
      ),
  },
].map((v, i) => ({ value: i, ...v }));

const flashlight = new Cesium.DirectionalLight({
  intensity: 5,
  direction: new Cesium.Cartesian3(0, 1, 0),
});

const cesiumLabelTextScale = 0.45;
const cesiumOrbitTailFactor = 0.975;
const cesiumSatOrbitColor = Cesium.Color.LIMEGREEN;

// Leap seconds to offset when comparing UTC (Coordinated Universal Time) and TAI (International
// Atomic Time) This is needed because Cesium.JulianDate stores time in TAI, but our data is in
// UTC. The last item in the leapSeconds array is always the most current # leap seconds.
const secondsDiffUtcToTai = (date) => {
  let high = Cesium.JulianDate.leapSeconds.length - 1;
  let low = 0;
  let mid;
  while (low < high) {
    mid = Math.floor((low + high) / 2);
    if (Cesium.JulianDate.compare(Cesium.JulianDate.leapSeconds[mid].julianDate, date) > 0) {
      high = mid;
    }
    if (Cesium.JulianDate.compare(Cesium.JulianDate.leapSeconds[mid].julianDate, date) < 0) {
      low = mid + 1;
    }
  }
  return Cesium.JulianDate.leapSeconds[low].offset;
};

// ==================================================================================================================
// Other
// ==================================================================================================================

const IndexedClock = forwardRef((props, ref) => {
  let { startTime: temp, animate, ...rest } = props;

  const { time } = useContext(TimeContext);
  const { startTime: globalStartTime } = useDataContext();

  const startTime = Cesium.JulianDate.fromIso8601(mjd2Moment(globalStartTime).format());
  const [currentTime, setCurrentTime] = useState(startTime);

  useEffect(() => {
    if (time !== globalStartTime) {
      setCurrentTime(Cesium.JulianDate.fromIso8601(mjd2Moment(time).format()));
    }
  }, []); // eslint-disable-line

  return (
    <Clock
      ref={ref}
      startTime={startTime}
      currentTime={currentTime}
      {...rest}
      shouldAnimate={animate}
    />
  );
});
IndexedClock.displayName = 'IndexedClock';

// ==================================================================================================================
// Generate Mission Module Playback Viewer
// ==================================================================================================================

const PlaybackViewer = (props) => {
  const {
    viewerRef,
    cameraRef,
    clockRef,
    satelliteEntityRef,
    initiallyTrack = true,
    contextValues,
    animate,
    isScenarioPlayback,
    cesiumOptions,
    setCesiumOptions,
  } = props;

  const {
    TimeContext: { setTime },
    DataContext: {
      // REF: sunTrackingVariables
      // TODO: Comment data back in when ready
      // data,
      cesiumData,
      perigeeAlt,
      calculateMultiplier,
      model,
    },
  } = contextValues;

  const { branch } = useActiveEntities();

  const [showFollowSc, setShowFollowSc] = useState(true);
  const [selectedEntity, setSelectedEntity] = useState();
  const [trackedEntity, setTrackedEntity] = useState();
  const [cadScalar, setCadScalar] = useState(1);
  const [filterMenuExpanded, setFilterMenuExpanded] = useState(new Set());
  const [entityFilter, setEntityFilter] = useState(() => {
    const defaultFilter = cesiumData.targets.reduce((obj, target) => {
      obj[target.name] = {};
      target.fovs?.forEach((fov) => {
        obj[target.name][fov.name] = true;
      });
      target.bfVectors?.forEach((vector) => {
        obj[target.name][vector.name] = true;
      });
      return obj;
    }, {});
    return defaultFilter;
  });
  useEffect(() => {
    const savedFilter = JSON.parse(window.localStorage.getItem(`playbackFilter - ${branch.id}`));
    if (savedFilter) {
      setEntityFilter(
        Object.keys(entityFilter).reduce((acc, key) => {
          if (savedFilter[key] !== undefined) {
            acc[key] = savedFilter[key];
          } else {
            acc[key] = entityFilter[key];
          }
          return acc;
        }, {})
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.localStorage.setItem(`playbackFilter - ${branch.id}`, JSON.stringify(entityFilter));
  }, [entityFilter, branch.id]);

  const moonRef = useRef(null);
  const playbackViewerContentRef = useRef(null);

  useEffect(() => {
    if (!viewerRef.current) return;
    viewerRef.current.cesiumElement.selectedEntityChanged.addEventListener((entity) => {
      setSelectedEntity(entity);
    });
    viewerRef.current.cesiumElement.trackedEntityChanged.addEventListener((entity) => {
      setTrackedEntity(entity);
      if (entity !== undefined) {
        cameraRef.current.cesiumElement.cancelFlight();
        setCesiumOptions((prev) => ({ ...prev, useEciCamera: false }));
        setCesiumOptions((prev) => ({ ...prev, followSc: true }));
      } else {
        cameraRef.current.cesiumElement.flyHome();
        setCesiumOptions((prev) => ({ ...prev, useEciCamera: true }));
        setCesiumOptions((prev) => ({ ...prev, followSc: false }));
      }
    });
    let e =
      viewerRef.current.cesiumElement._container.children[0].children[1].children[0].children[0];
    if (e.className === 'cesium-credit-logoContainer') {
      e.style.display = 'none';
    }
    if (viewerRef.current.cesiumElement._animation) {
      viewerRef.current.cesiumElement._animation.container.style.visibility = 'hidden';
    }
    // remove the event handler for double clicking to zoom in on entities
    if (viewerRef.current.cesiumElement.cesiumWidget) {
      viewerRef.current.cesiumElement.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );
    }
  }, [viewerRef, cameraRef, setCesiumOptions]);

  const [terrain, setTerrain] = useState({ selectedValue: terrainOptions[cesiumOptions.terrain] });
  const [imagery, setImagery] = useState({ selectedValue: imageryOptions[cesiumOptions.imagery] });

  const updateImagery = useCallback(
    (selection) => {
      if (viewerRef?.current) {
        const viewer = viewerRef?.current.cesiumElement;
        if (imagery.imagery) viewer.imageryLayers.remove(imagery.imagery);
        (async () => {
          const newImagery = { selectedValue: selection };
          newImagery.imagery =
            selection.imagery &&
            new Cesium.ImageryLayer(
              (await selection.imagery()) || (await Cesium.createWorldImageryAsync())
            );
          if (newImagery.imagery) viewer.imageryLayers.add(newImagery.imagery);
          setImagery(newImagery);
          setCesiumOptions((prev) => ({ ...prev, imagery: selection.value }));
        })();
      }
    },
    [viewerRef, imagery, setCesiumOptions]
  );

  const updateTerrain = useCallback(
    (selection) => {
      if (viewerRef?.current) {
        const viewer = viewerRef?.current.cesiumElement;
        if (terrain.primitives) viewer.scene.primitives.remove(...terrain.primitives);
        (async () => {
          const newTerrain = { selectedValue: selection };
          if (selection.primitives) {
            newTerrain.primitives = await selection.primitives();
            viewer.scene.primitives.add(...newTerrain.primitives);
          }
          viewer.terrainProvider =
            (selection.terrainProvider && (await selection.terrainProvider())) ||
            new Cesium.EllipsoidTerrainProvider();
          setTerrain(newTerrain);
          setCesiumOptions((prev) => ({ ...prev, terrain: selection.value }));
          updateImagery(imagery.selectedValue); // Also update imagery in case the selected terrain removed it
        })();
      }
    },
    [viewerRef, terrain, imagery, updateImagery, setCesiumOptions]
  );

  useEffect(() => {
    updateImagery(imageryOptions[cesiumOptions.imagery]);
    updateTerrain(terrainOptions[cesiumOptions.terrain]);
  }, []); // eslint-disable-line

  // // This is needed for when `requestRenderMode` below is set to `true` -- we need to manually trigger a rerender.
  // useEffect(() => {
  //   if (sceneRef.current) {
  //     sceneRef.current.cesiumElement.requestRender();
  //   }
  // }, [cesiumLabels]);

  const [fullHeight, setFullHeight] = useState(400);
  const [extraZoom2D, setExtraZoom2D] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  const cadScaleFactor = model.cadScaleFactor || 1;
  const cadScale = useMemo(() => {
    return perigeeCadScale(perigeeAlt, cadScaleFactor) * cesiumOptions.cadScaleMultiplier;
  }, [perigeeAlt, cadScaleFactor, cesiumOptions.cadScaleMultiplier]);

  function scaleCesiumVectorData(prevScaleValue, nextScaleValue) {
    setCadScalar((prev) => {
      return prev * (prevScaleValue / nextScaleValue);
    });
  }

  const classes = useStyles();

  useEffect(() => {
    if (initiallyTrack && viewerRef.current && satelliteEntityRef.current) {
      if (!firstLoad) {
        setFirstLoad(true);
      } else {
        viewerRef.current.cesiumElement.trackedEntity = satelliteEntityRef.current.cesiumElement;
      }
    }
  }, [firstLoad, setFirstLoad, satelliteEntityRef, viewerRef, initiallyTrack]);

  useEffect(() => {
    const fitHeight = true;
    if (fitHeight) {
      const el = document.getElementById('viewport');
      const observer = new ResizeObserver((entries) => {
        setFullHeight(el.offsetHeight - 6 - 6 - 6);
      });
      observer.observe(el);
      return () => observer.disconnect(); // disconnect observer on unmount
    }
  }, []);

  const updatePlaybackState = useCallback(
    (currentTime) => {
      const time = jd2Mjd(
        // `Cesium.JulianDate` time is in TAI, & `data[#].time` is in UTC. TAI is `secondsDiffUtcToTai`
        // seconds ahead of UTC (37 as of 2021). So, take away leap seconds to convert back to UTC in
        // order to accurately search our `data` for the closest index.
        currentTime.dayNumber +
          (currentTime.secondsOfDay - secondsDiffUtcToTai(currentTime)) / 86400
      );
      setTime(time);
    },
    [setTime]
  );

  const [currTimeListenerInitialized, setCurrTimeListenerInitialized] = useState(false);
  useEffect(() => {
    // create listener to currentTime, use to trigger `updatePlaybackState`. We previously used onTick or onPostRender
    // to trigger updatePlaybackState, but this caused lag in Cesium animation while updatePlaybackState was executing
    if (!currTimeListenerInitialized && viewerRef.current) {
      Cesium.knockout
        .getObservable(viewerRef.current.cesiumElement.clockViewModel, 'currentTime')
        .subscribe(updatePlaybackState);
      setCurrTimeListenerInitialized(true);
    }
  }, [viewerRef, updatePlaybackState, currTimeListenerInitialized, setCurrTimeListenerInitialized]);

  const targets = useMemo(() => {
    if (!cesiumData.targets) return [];
    return cesiumData.targets.map((target) => {
      return (
        <TargetEntity
          key={`${target.type.charAt(0)}T_${target.name}`}
          target={target}
          data={cesiumData}
          showOrbitTails={cesiumOptions.showOrbitTails}
          orbitTailFactor={cesiumOrbitTailFactor}
          showLabels={cesiumOptions.showTargetNames}
          showComms={cesiumOptions.showCommsLines}
          showWaypoints={true}
          labelTextScale={cesiumLabelTextScale * cesiumOptions.labelScaleMultiplier}
          viewerRef={viewerRef}
          showModels={cesiumOptions.showAgentModels}
          cadSignedUrl={target.cadSignedUrl}
          cadScaleFactor={target.cadScaleFactor}
          scaleToRealSize={cesiumOptions.scaleToRealSize}
          showWayPoints={cesiumOptions.showWayPoints}
          showWayPaths={cesiumOptions.showWayPaths}
          labelScaleMultiplier={cesiumOptions.labelScaleMultiplier}
          cadScaleMultiplier={cesiumOptions.cadScaleMultiplier}
          fovScaleMultiplier={cesiumOptions.fovScaleMultiplier}
          entityFilter={entityFilter[target.name]}
          satelliteEntityRef={target.isModel ? satelliteEntityRef : null}
          cadScalar={cadScalar}
          pathStep={cesiumOptions.pathStep}
        />
      );
    });
  }, [
    cesiumData,
    cesiumOptions.showTargetNames,
    cesiumOptions.showCommsLines,
    cesiumOptions.showOrbitTails,
    viewerRef,
    cesiumOptions.showAgentModels,
    cesiumOptions.labelScaleMultiplier,
    cesiumOptions.scaleToRealSize,
    cesiumOptions.showWayPoints,
    cesiumOptions.showWayPaths,
    cesiumOptions.cadScaleMultiplier,
    cesiumOptions.fovScaleMultiplier,
    cesiumOptions.pathStep,
    entityFilter,
    satelliteEntityRef,
    cadScalar,
  ]);
  const [openSettings, setOpenSettings] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <Widget className={classes.playbackViewer} padding="narrow" minWidth={0}>
      <div
        className={classes.playbackViewerContent + ' joyride-playback-viewer'}
        ref={playbackViewerContentRef}
      >
        <Viewer
          className={classes.cesiumViewer}
          style={{
            // Improve this
            height: fullHeight,
          }}
          ref={viewerRef}
          navigationHelpButton={true}
          timeline={false}
          fullscreenButton={false}
          baseLayerPicker={false}
          sceneModePicker={true}
          trackedEntity={isScenarioPlayback ? null : satelliteEntityRef.current?.cesiumElement}
          infoBox={false}
          //extend={Cesium.viewerCesiumInspectorMixin}
        >
          <SkyAtmosphere show={true} />
          <SkyBox show={cesiumOptions.showSkybox} />
          <ShadowMap darkness={0} />
          <Camera ref={cameraRef} />
          <ScreenSpaceCameraController enableLook={!cesiumOptions.useEciCamera} />
          <IndexedClock
            ref={clockRef}
            startTime={cesiumData.startTime}
            stopTime={cesiumData.stopTime}
            multiplier={calculateMultiplier(cesiumOptions.clockMultiplier)}
            clockRange={
              cesiumOptions.loop ? Cesium.ClockRange.LOOP_STOP : Cesium.ClockRange.CLAMPED
            }
            animate={animate}
          />
          <Globe
            dynamicAtmosphereLighting={true}
            dynamicAtmosphereLightingFromSun={!cesiumOptions.useFlashlight}
            baseColor={Cesium.Color.SKYBLUE}
            enableLighting={true}
            depthTestAgainstTerrain={false}
          />
          <Sun />
          <Moon onlySunLighting={false} />
          <Scene
            // set `requestRenderMode` to `true` to trigger `onPostRender` only when the frame changes rather than 60 times / second
            // also make sure to comment back in the useEffect above that requests a rerender when cesiumLabels change
            // requestRenderMode={true}
            onMorphStart={() => {
              // only show "Follow Spacecraft" button when in 3d view
              viewerRef.current.cesiumElement.clockViewModel.shouldAnimate = false;
              let currentlyOn3dView =
                viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode ===
                Cesium.SceneMode.SCENE3D;
              setShowFollowSc(currentlyOn3dView);
              // Pause playback when transitioning views to make the animation more smooth
            }}
            // When switching to SceneMode.SCENE2D view, camera zooms in at the end of scene transition.
            // This likely comes from an oddity in the Cesium library.
            //    onMorphComplete() here handles a subsequent zoom out.
            onMorphComplete={function () {
              window.requestAnimationFrame(function () {
                // requestAnimationFrame() is necessary to ensure the zoom out happens AFTER the zoom in.
                if (
                  viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode ===
                  Cesium.SceneMode.SCENE2D
                ) {
                  // Toggle the zoom (CameraFlyTo element below) if Scene switched to 2D
                  setExtraZoom2D(true);
                } else {
                  // Turn off the zoom if Scene switch to 3D or Columbus
                  setExtraZoom2D(false);
                }
              });
            }}
            onPreRender={(scene) => {
              if (cesiumOptions.useFlashlight) {
                scene.light.direction = Cesium.Cartesian3.clone(
                  scene.camera.directionWC,
                  scene.light.direction
                );
              }
            }}
            // Camera view in ECI reference frame (Earth spins)
            onPostUpdate={
              cesiumOptions.useEciCamera && !viewerRef.current?.cesiumElement.trackedEntity
                ? (scene, time) => {
                    if (scene.mode !== Cesium.SceneMode.SCENE3D) {
                      return;
                    }

                    const icrfToFixed = Cesium.Transforms.computeIcrfToFixedMatrix(time);
                    if (Cesium.defined(icrfToFixed)) {
                      const camera = scene.camera;
                      const offset = Cesium.Cartesian3.clone(camera.position);
                      const transform = Cesium.Matrix4.fromRotationTranslation(icrfToFixed);
                      camera.lookAtTransform(transform, offset);
                    }
                  }
                : noop
            }
            light={cesiumOptions.useFlashlight ? flashlight : Cesium.SunLight()}
          />
          {extraZoom2D && (
            // Toggled by changing scene to 2D
            <CameraFlyTo
              destination={Cesium.Cartesian3.fromDegrees(0, 0, 40000000)}
              duration={1} // Edit for duration of zoom animation, 0 for no animation, default is ~3
            />
          )}
          {cesiumData.moonPosition && (
            <Entity position={cesiumData.moonPosition} name="Moon" ref={moonRef}>
              <LabelGraphics
                text="Moon"
                scale={cesiumLabelTextScale}
                fillColor={cesiumSatOrbitColor}
                // pixelOffset={Cesium.Cartesian2.fromElements(80, 80, new Cesium.Cartesian2())}
                show={true}
              />
            </Entity>
          )}
          {targets}
        </Viewer>
        <div className={classes.menuButton + ' joyride-playback-menu'}>
          <ClickAwayListener
            onClickAway={() => {
              setOpenSettings(false);
            }}
          >
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => {
                  setOpenSettings(false);
                }}
                open={openSettings}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                interactive
                arrow
                placement="right-start"
                title={
                  <SettingsMenu
                    cesiumData={cesiumData}
                    viewerRef={viewerRef}
                    showFollowSc={showFollowSc}
                    satelliteEntityRef={satelliteEntityRef}
                    trackedEntity={trackedEntity}
                    terrain={terrain}
                    imagery={imagery}
                    terrainOptions={terrainOptions}
                    imageryOptions={imageryOptions}
                    cesiumOptions={cesiumOptions}
                    setCesiumOptions={setCesiumOptions}
                    entityFilter={entityFilter}
                    setEntityFilter={setEntityFilter}
                    scaleCesiumVectorData={scaleCesiumVectorData}
                    updateTerrain={updateTerrain}
                    updateImagery={updateImagery}
                    cadScale={cadScale}
                    cameraRef={cameraRef}
                    isScenarioPlayback={isScenarioPlayback}
                  />
                }
              >
                <div>
                  <Tooltip title="Settings">
                    <div>
                      <StyledButton
                        className={classes.viewerButton}
                        min
                        type="button"
                        onClick={() => {
                          setOpenSettings((prev) => !prev);
                        }}
                        dontDisableInReadOnly
                        fullWidth
                      >
                        <div>
                          <FontAwesomeIcon icon={faGear} />
                        </div>
                      </StyledButton>
                    </div>
                  </Tooltip>
                </div>
              </Tooltip>
            </div>
          </ClickAwayListener>

          <ClickAwayListener
            onClickAway={() => {
              setOpenFilter(false);
            }}
          >
            <div className={classes.filterMenu}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => {
                  setOpenFilter(false);
                }}
                open={openFilter}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                interactive
                arrow
                placement="right-start"
                title={
                  <FilterMenu
                    cesiumData={cesiumData}
                    entityFilter={entityFilter}
                    setEntityFilter={setEntityFilter}
                    filterMenuExpanded={filterMenuExpanded}
                    setFilterMenuExpanded={setFilterMenuExpanded}
                  />
                }
              >
                <div>
                  <Tooltip title="Filter">
                    <div>
                      <StyledButton
                        className={classes.viewerButton}
                        min
                        type="button"
                        onClick={() => {
                          setOpenFilter((prev) => !prev);
                        }}
                        disabled={Object.values(entityFilter).every(
                          (target) => Object.keys(target).length === 0
                        )}
                        dontDisableInReadOnly
                        fullWidth
                      >
                        <div>
                          <FontAwesomeIcon icon={faFilter} />
                        </div>
                      </StyledButton>
                    </div>
                  </Tooltip>
                </div>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      {viewerRef.current?.cesiumElement && (
        <InfoBox
          onClose={() => {
            setSelectedEntity(undefined);
          }}
          selectedEntity={selectedEntity}
          trackedEntity={trackedEntity}
          agentData={cesiumData.targets.find(
            (target) => target.name === selectedEntity?._label?._text._value
          )}
          entityFilter={entityFilter}
          setEntityFilter={setEntityFilter}
          viewer={viewerRef.current.cesiumElement}
        />
      )}
    </Widget>
  );
};

const withReducedContext = (WrappedComponent) => {
  const Result = (props) => {
    const { setTime } = useContext(TimeContext);
    const {
      activeAgentData: {
        cesiumData,
        perigeeAlt,
        calculateMultiplier,
        orbitalElementsSeries,
        staticModel: model,
      },
    } = useAnalyticsContext();

    const contextValues = useMemo(() => {
      return {
        TimeContext: { setTime },
        DataContext: {
          cesiumData,
          perigeeAlt,
          calculateMultiplier,
          orbitalElementsSeries,
          model,
        },
      };
    }, [setTime, cesiumData, perigeeAlt, calculateMultiplier, orbitalElementsSeries, model]);

    return <WrappedComponent {...props} contextValues={contextValues} />;
  };
  Result.displayName = 'WithReducedContext';
  return Result;
};

export default withReducedContext(memo(PlaybackViewer));
