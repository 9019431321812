import { makeStyles } from '@material-ui/core/styles';

export const iconDims = 35;

const useStyles = makeStyles((theme) => ({
  supportChannel: {
    textAlign: 'center',
    margin: '20px 0',

    '& svg': {
      width: iconDims,
      height: iconDims,
    },

    '& a': {
      display: 'block',
      fontSize: 17,
    },
  },
  key: {
    fontSize: 14,
    borderBottom: '1px solid',
    borderColor: theme.palette.text.primary,
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    margin: 2,
    padding: '4px 8px',
  },
  featureCards: {
    display: 'flex',
    width: '100%',
    overflowX: 'hidden',
    ...theme.mixins.noScroll,
    '& .MuiCard-root': {
      // backgroundColor: theme.palette.background.paper,
      width: '60%',
      minWidth: '60%',
      margin: 10,
      '& .MuiButton-root': {
        backgroundColor: theme.palette.background.main,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[10],
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
      },
    },
  },
  secondaryCard: {
    opacity: 0.25,
    transition: 'opacity 0.3s',
    cursor: 'pointer',
    border: '1px solid',
    '&:hover': {
      opacity: 1,
    },
  },
  primaryCard: {
    border: '1px solid',
  },
  focusedCard: {
    '& .MuiButton-root': {
      backgroundColor: theme.palette.background.main,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[10],
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    '& .MuiCardHeader-root': {
      boxShadow: theme.shadows[5],
      padding: 8,
      '& h2': {
        fontWeight: 500,
        color: theme.palette.background.main,
      },
      '& h6': {
        fontSize: 14,
        fontWeight: 'normal',
        color: theme.palette.background.main,
      },
    },
    backgroundColor: theme.palette.background.paper,
    border: '0.5px solid ' + theme.palette.text.primary,
    width: '99%',
    margin: '10px 0px',
  },
  cardHeader: {
    backgroundColor: theme.palette.background.main,
    padding: 8,
    boxShadow: theme.shadows[5],
    '& h6': {
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
  },
  cardContent: {
    height: '75px',
    padding: 8,
    '& h5': {
      fontWeight: 'normal',
      color: theme.palette.background.main,
    },
  },
}));

export default useStyles;
