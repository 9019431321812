import { ISelectOption } from 'components/general/types';
import { DataHandlingDeviceVables } from 'utils/vable';
import * as Yup from 'yup';

const deviceSchema = Yup.object().shape({
  type: Yup.object().required('Device type is required'),
  fieldOfView: Yup.object().when('type', {
    is: (type: ISelectOption) => type?.value !== DataHandlingDeviceVables.Type.Modem.value,
    then: Yup.object().required(
      'Field of View is required for directed energy devices (antennas and laser comms).'
    ),
  }),
});

export default deviceSchema;
