import _, { isPlainObject, isString } from 'lodash';
import { useMemo } from 'react';

const isGroupKey = (str) => str.charAt(0) === str.charAt(0).toUpperCase();

export const makeModel = (def) => {
  if (!def) return { _blocksById: {}, _idsByGroup: {}, _root: {}, _metamodel: {}, ready: false };

  const metamodel = _.cloneDeep(def);

  const { _subs, index, blocks: blocksById, ...root } = def;
  const _relationships = root._relationships;

  // add rels onto root
  root.rels = new Set(Object.keys(_relationships.root));
  // add rels onto blocks
  for (const block of Object.values(blocksById)) {
    block.rels = new Set(Object.keys(_relationships[block.type]));
  }

  // expand index into idsByGroup:
  const idsByGroup = {};
  for (const [blockType, val] of Object.entries(index)) {
    idsByGroup[blockType] = [];

    const recurPushIds = (val) => {
      for (const subOrId of val) {
        if (subOrId in index) {
          recurPushIds(index[subOrId]);
          continue;
        }
        if (!idsByGroup[blockType].includes(subOrId)) idsByGroup[blockType].push(subOrId);
      }
    };

    recurPushIds(val);
  }

  return {
    _blocksById: blocksById,
    _idsByGroup: idsByGroup,
    _root: root,
    _metamodel: metamodel,
    ready: true,
  };
};

export const useModel = (_model) => {
  return useMemo(() => {
    const model = _.cloneDeep(_model);
    const _cache = {
      // Don't need to index by `group` or `rel` because all ids are unique
      _byids: {},
      _get: {},
    };
    const handler = {
      get(target, prop, receiver) {
        if (prop in target) return target[prop];
        if (isString(prop) && isGroupKey(prop)) {
          if (!_cache[prop]) _cache[prop] = { _all: [], _byIds: [] };
          return {
            all: () => _cache[prop]._all,
            byId: () => undefined,
            byIds: () => _cache[prop]._byIds,
            ids: () => [],
            first: () => undefined,
          };
        }
        return undefined;
      },
    };

    if (!model?.ready) {
      // Return proxy object where any key is valid for easier use in components
      return new Proxy({}, handler);
    }

    const { _blocksById: blocksById, _idsByGroup: idsByGroup, _root: root } = model;
    const compiledRoot = { ...root };
    const compiledBlocksById = {};

    const resolver = (ids) => {
      return {
        get: () => {
          // TODO: figuring out side type can be simplified using `_relationships` from SedaroML
          let isManySide = true;
          let isDataSide = false;
          let _ids = ids; // Break pointer
          if (!Array.isArray(ids)) {
            isManySide = false;
            if (isPlainObject(ids)) {
              isDataSide = true;
              _ids = Object.keys(ids);
            } else {
              _ids = [ids]; // Cast to list for consistent handling
            }
          }
          if (isManySide && _cache._get[ids]) return _cache._get[ids];
          if (isDataSide && _cache._get[JSON.stringify(ids)])
            return _cache._get[JSON.stringify(ids)];
          const entities = _ids.map((id) => compiledBlocksById[id]);
          if (isManySide) {
            _cache._get[ids] = entities;
            return _cache._get[ids];
          } else if (isDataSide) {
            _cache._get[JSON.stringify(ids)] = entities.map((entity) => ({
              name: entity.name,
              id: entity.id,
              dataSide: ids[entity.id],
            }));
            return _cache._get[JSON.stringify(ids)];
          } else {
            return entities[0];
          }
        },
      };
    };

    for (const id in blocksById) {
      compiledBlocksById[id] = { ...blocksById[id] };
      const block = compiledBlocksById[id];
      if (block.rels) {
        for (const rel of block.rels) {
          Object.defineProperty(block, rel, resolver(block[rel])); // Consider updating these to just refs
        }
      }
    }
    if (root.rels) {
      for (const rel of root.rels) {
        Object.defineProperty(compiledRoot, rel, resolver(root[rel])); // Consider updating these to just refs
      }
    }
    for (const group in idsByGroup) {
      const _all = idsByGroup[group].map((id) => compiledBlocksById[id]);
      compiledRoot[group] = {
        all: () => _all,
        byId: (id) => compiledBlocksById[id],
        byIds: (ids) => {
          if (_cache._byids[ids]) return _cache._byids[ids];
          _cache._byids[ids] = ids.map((id) => compiledBlocksById[id]);
          return _cache._byids[ids];
        },
        ids: () => idsByGroup[group],
        first: () => _all[0],
      };
    }
    const compiledModel = new Proxy(compiledRoot, handler);
    const exclude = new Set(['describe', 'rels', 'nextId']);
    compiledRoot.describe = () => {
      const blockType = {};
      const obj = { '🧱 Block Types -->': blockType };
      Object.entries(compiledModel).forEach(([k, v]) => {
        if (!exclude.has(k) && v) {
          if (v.all) blockType[`${k}.all()`] = v.all();
          else obj[k] = v;
        }
      });
      console.dir({ '`🛰️ Compiled Model -->`': obj });
      // ^^^ console.dir is intentionally here
    };

    return compiledModel;
  }, [_model]);
};
