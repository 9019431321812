/**
 * A function to download blob data (to the downloads folder)
 *
 * @param name - file name
 * @param data - blob data
 * @param type - file type
 */
export default function downloadBlobData(name: string, data: BlobPart | BlobPart[], type: string) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const blob = new Blob(!Array.isArray(data) ? [data] : data, { type });
  a.href = window.URL.createObjectURL(blob);
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(a.href);
}
