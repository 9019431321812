import Chart from 'components/general/charts/Chart';
import { useMemo } from 'react';
import { dateFormatLong, mjd2Moment } from 'utils/time';

import { timing } from 'config';
import type { EChartOption } from 'echarts';
import _ from 'lodash';
import theme from 'theme';
import { precision } from 'utils/units';
import { IGenericObject, TPlotVariable } from '../types';

interface IProps {
  title?: string;
  leftLabel?: string;
  leftUnits: string;
  rightLabel?: string;
  rightUnits?: string;
  lines: TPlotVariable[];
  data: IGenericObject[] | IGenericObject;
  withZoom?: boolean;
  type?: string;
  step?: string;
}

const rightColor = Object.values(theme.palette.charts.secondary)[0];

const TimeSeriesChart = ({
  title,
  leftLabel = '',
  leftUnits,
  rightLabel,
  rightUnits,
  data,
  type,
  step,
  lines,
  withZoom,
}: IProps) => {
  // Keys that this chart will plot
  const [dimensions, source] = useMemo(() => {
    const dims = Array.from(new Set(lines.flatMap(({ xKey, yKey }) => [xKey, yKey])));
    if (Array.isArray(data)) return [dims, data];
    return [dims, dims.reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})];
  }, [lines, data]);

  // Define colors for this plot
  const colors = useMemo(() => {
    const colors = Object.values(theme.palette.charts.primary);
    if (lines.length && lines[lines.length - 1].right) {
      colors.splice(lines.length - 1, 0, rightColor);
    }
    return colors;
  }, [lines]);

  // Define yAxes for this plot, including right axis if needed
  const yAxes = useMemo(() => {
    const yAxisDef: EChartOption.YAxis = {
      nameLocation: 'center',
      nameGap: 60,
      type: 'value',
      min: (value) => value.min - (value.max - value.min) * 0.05,
      max: (value) => value.max + (value.max - value.min) * 0.05,
      //@ts-ignore:next-line // Types haven't been updated in a while
      animation: false,
      axisLabel: {
        showMinLabel: false,
        showMaxLabel: false, // min & max labels aren't neat due to limit overshoot, so don't show them
        formatter: (value: number) => precision(value),
      },
    };
    const yAxes: EChartOption.YAxis[] = [
      _.merge(
        {
          name: leftUnits ? leftLabel + ` (${leftUnits})` : leftLabel,
          nameRotate: 90,
          // Only include gridlines for left axis
          splitLine: {
            show: true,
            lineStyle: { color: theme.palette.action.disabled },
          },
          splitNumber: 3,
          axisLine: {
            lineStyle: { color: theme.palette.background.contrastText },
          },
        } as EChartOption.YAxis,
        yAxisDef
      ),
    ];
    if (rightLabel) {
      yAxes.push(
        _.merge(
          {
            name: rightUnits ? rightLabel + ` (${rightUnits})` : rightLabel,
            nameRotate: -90,
            splitLine: { show: false },
            // Make right axis match line color
            axisLine: {
              lineStyle: { color: rightColor },
            },
            axisLabel: { color: rightColor },
            nameTextStyle: {
              color: rightColor,
            },
          } as EChartOption.YAxis,
          yAxisDef
        )
      );
    }
    return yAxes;
  }, [leftLabel, leftUnits, rightLabel, rightUnits]);

  const option = useMemo(
    () =>
      ({
        color: colors,
        textStyle: {
          color: theme.palette.background.contrastText,
          ...theme.typography.body,
        },
        animationDuration: timing.lineAnimationDuration,
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
              filterMode: step == null ? 'weakFilter' : 'none',
              // Don't actually draw the toolbox on the widget, but retain its functionality
              icon: null,
            },
            saveAsImage: {
              show: true,
              backgroundColor: theme.palette.background.main,
              name: title || 'Sedaro Time Series Chart',
              pixelRatio: 2, // saved image to displayed container resolution ratio
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              type: 'solid',
            },
          },
          backgroundColor: theme.palette.background.light,
          borderWidth: 0,
          extraCssText: `box-shadow: ${theme.shadows[5]};`,
          // Define custom format for tooltip contents (not container)
          formatter: (params: IGenericObject[]) => {
            // Params is an array of data about each line

            // Skip points that don't have required data
            if (_.size(params[0].data) === 1) return '';

            // Construct HTML string of desired tooltip
            const date = mjd2Moment(params[0].axisValue).format(dateFormatLong);
            const dimensionNames = params[0].dimensionNames.slice(1);
            const style = Object.entries(theme.typography.body).reduce(
              (finalString, entry) => finalString + `${entry[0]}:${entry[1]};`,
              ''
            );
            let res = `<span style="color:${theme.palette.background.contrastText};${style}">${date}</span>`;
            params.forEach((vals: IGenericObject) => {
              const i = vals.seriesIndex;
              const yKey = dimensionNames[i];
              const value = vals.data[i + 1];
              const units = lines.find((item) => item.yKey === yKey)?.right
                ? rightUnits
                : leftUnits;
              if (value !== null && value !== undefined) {
                if (typeof value === 'boolean') {
                  res += `<br><span style="color:${vals.color};${style}">${vals.seriesName} : ${value}</span>`; // print a boolean value outright
                } else {
                  res += `<br><span style="color:${vals.color};${style}">${
                    vals.seriesName
                  } : ${precision(value)} ${units || ''}</span>`;
                }
              }
            });
            return res;
          },
        },
        title: {
          text: title,
          top: 10,
          left: 'center',
          textStyle: {
            color: theme.palette.background.contrastText,
            ...theme.typography.h3,
          },
        },
        legend: {
          top: title && 40,
          width: '60%',
          textStyle: {
            ...theme.typography.body,
            color: theme.palette.background.contrastText,
            fontSize: 12,
          },
          inactiveColor: theme.palette.action.disabled,
          itemHeight: type === 'scatter' ? 10 : 3,
          itemWidth: type === 'scatter' ? 10 : 20,
          // Manually set the icon through the `data` property, instead of using the default `icon` prop
          // Allows us to change icon per line, specifically for dashed lines here
          data: lines.map((lineParams: { name: string }, index: number) => ({
            name: lineParams.name,
            icon:
              // Use a dashed line once we've cycled through all the colors, using an SVG path
              // For some reason, echarts@5.3.3 doesn't match the line type to the legend, so we have to do it manually
              type === 'scatter'
                ? 'circle'
                : index >= colors.length
                ? 'path://M180 1000 l0 -200 400 0 0 200 -400 0 0 -200z, M810 1000 l0 -200 400 0 0 200 -400 0 0 -200zm, M1440 1000 l0 -200 400 0 0 200 -400 0 0 -200z'
                : 'rect',
          })),
        },
        grid: {
          // Params for the whole chart
          // NOTE: If you're looking for gridlines in particular, they're defined by "splitLine" on x/y-Axis
          // NOTE: If you're looking for the top, left, right margins, they're delegated down to the `Chart`
          //       component – hacked to be more dynamic.
        },
        xAxis: {
          name: 'Time (UTC)',
          nameLocation: 'center',
          nameGap: 50,
          type: 'value',
          min: 'dataMin',
          max: 'dataMax',
          animation: false,
          axisLabel: {
            // xAxis plots time, but our time values are in MJD, so format
            formatter: (value: number) => mjd2Moment(value).format('MM-DD-YYYY[\n]HH:mm:ss'),
          },
          axisLine: {
            lineStyle: { color: theme.palette.background.contrastText },
          },
          splitLine: {
            lineStyle: { color: theme.palette.action.disabled, opacity: 0.5 },
          },
        },
        yAxis: yAxes,
        dataset: {
          id: 'main',
          dimensions,
          source,
        },
        // Convert every line definition into a series in the chart
        series: lines.map(
          (lineParams, index) =>
            ({
              name: lineParams.name,
              type: type,
              step: step,
              animation: type === 'line',
              symbol: type === 'line' ? 'none' : undefined,
              symbolSize: 3,
              smooth: 0.3,
              large: true,
              largeThreshold: 5000,
              connectNulls: true,
              lineStyle: {
                // `lineStyle` only applies when type === 'line'
                width: index >= colors.length ? 1.5 : 1, // Make the dashed lines thicker
                color: lineParams.right && rightColor, // if undefined, takes colors from global scheme
                // Use a dashed line once we've cycled through all the colors, using a dash array
                // See https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray
                type: index >= colors.length ? [10, 6] : 'solid',
              },
              encode: { x: lineParams.xKey, y: lineParams.yKey, seriesLayoutBy: 'row' },
              yAxisIndex: lineParams.right && 1,
              sampling: step === undefined ? 'average' : undefined,
            } as EChartOption.SeriesLine | EChartOption.SeriesScatter)
        ),
      } as EChartOption),
    [colors, dimensions, lines, rightUnits, source, title, yAxes, leftUnits, type, step]
  );

  return (
    <Chart
      option={option}
      style={{ height: 400, marginTop: 15, marginBottom: 15, width: 'auto!important' }}
      titled={Boolean(title)}
      withRightAxis={Boolean(rightLabel)}
      withZoom={withZoom}
      dynamicMarginMagicNumbers={
        // REF: Chart/index.tsx::margins
        // These numbers are magic - they happened to work well, change them if you need to.
        // Used to avoid labels getting cut off on the sides of the chart
        {
          leftMargin: [10, 15],
          rightMargin: [3.75, 6.5],
        }
      }
    />
  );
};

export default TimeSeriesChart;
