import { Chip } from '@material-ui/core';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { shade } from 'theme';
import useStyles from './styles';

const WidgetForm = ({
  selectedWidget,
  setDirtyWidgets,
  setSelectedPlot,
  selectedPlot,
  chartTypes,
}) => {
  const classes = useStyles();
  const defaultPlot = {
    name: 'New Plot',
    type: 'line',
    label: '',
    unit: '',
    labelRight: null,
    unitRight: null,
    step: null,
    variables: [{ keys: [''], legend: [''], right: null }],
  };

  return (
    <div className={classes.widgetEdit}>
      <LabeledInput
        value={selectedWidget?.name || ''}
        variant="outlined"
        onChange={(e) => {
          selectedWidget.name = e.target.value;
          setDirtyWidgets((d) => [
            ...d.filter((item) => item.id !== selectedWidget.id),
            selectedWidget,
          ]);
        }}
        label="Widget Name"
      />
      <LabeledInput
        value={selectedWidget?.title || ''}
        variant="outlined"
        onChange={(e) => {
          selectedWidget.title = e.target.value;
          setDirtyWidgets((d) => [
            ...d.filter((item) => item.id !== selectedWidget.id),
            selectedWidget,
          ]);
        }}
        label="Widget Title"
      />
      <LabeledInput
        value={selectedWidget?.subtitle || ''}
        variant="outlined"
        onChange={(e) => {
          selectedWidget.subtitle = e.target.value;
          setDirtyWidgets((d) => [
            ...d.filter((item) => item.id !== selectedWidget.id),
            selectedWidget,
          ]);
        }}
        label="Widget Subtitle"
      />
      <LabeledInput
        value={selectedWidget?.each || ''}
        variant="outlined"
        onChange={(e) => {
          selectedWidget.each = e.target.value;
          setDirtyWidgets((d) => [
            ...d.filter((item) => item.id !== selectedWidget.id),
            selectedWidget,
          ]);
        }}
        label="Widget for each"
      />
      <h3 style={{ padding: 10 }}>Plots</h3>
      {selectedWidget.plots.map((plot, index) => {
        return (
          <Chip
            className={selectedPlot === index ? classes.highlightedChip : classes.widgetChip}
            style={{
              backgroundColor:
                selectedPlot === index
                  ? shade(chartTypes[plot.type].color, -10)
                  : shade(chartTypes[plot.type].color, -30),
            }}
            key={index}
            label={plot.name || chartTypes[plot.type].label}
            clickable
            onClick={() => setSelectedPlot(index)}
            onDelete={() => {
              selectedWidget.plots.splice(index, 1);
              if (selectedPlot === index) setSelectedPlot(0);
              setDirtyWidgets((d) => [
                ...d.filter((item) => item.id !== selectedWidget.id),
                selectedWidget,
              ]);
            }}
          />
        );
      })}
      <Chip
        style={{ margin: 5 }}
        variant="outlined"
        label="Add Plot"
        clickable
        onClick={() => {
          setSelectedPlot(selectedWidget.plots.push(defaultPlot) - 1);
          setDirtyWidgets((d) => [
            ...d.filter((item) => item.id !== selectedWidget.id),
            selectedWidget,
          ]);
        }}
      />
    </div>
  );
};

export default WidgetForm;
