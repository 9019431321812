/**
 * A promise wrapper around the FileReader API
 * @param file - The file to read
 * @returns a promise that resolves to the file contents
 */
export default function fileReader(file: Blob) {
  const fR = new FileReader();

  return new Promise((resolve, reject) => {
    fR.onerror = () => {
      fR.abort();
      reject(new Error('Problem parsing file'));
    };
    fR.onload = () => resolve(fR.result);
    fR.readAsText(file);
  });
}
