import * as Cesium from 'cesium';
import { pi } from 'mathjs';
import { useModel } from 'middleware/SatelliteApi/template';
import { Camera, Entity, Globe, Scene, ScreenSpaceCameraController, Viewer } from 'resium';
import theme from 'theme';
import {
  cesiumModelScaleDown,
  cesiumScaleLinearIntercept,
  cesiumScaleLinearSlope,
} from '../PlaybackWGroups/general/constants';

const AgentThumbnail = ({ branch }) => {
  const model = useModel(branch.model);
  // const bfVectors = model.BodyFrameVector.all();

  return (
    <Viewer
      style={{
        height: '100%',
        width: '100%',
      }}
      timeline={false}
      animation={false}
      shouldAnimate={false}
      baseLayerPicker={false}
      sceneModePicker={false}
      scene3DOnly={true}
      infoBox={false}
      selectionIndicator={false}
      homeButton={false}
      geocoder={false}
      fullscreenButton={false}
      navigationHelpButton={false}
      imageryProvider={false}
      skyBox={false}
      msaaSamples={4}
    >
      <ScreenSpaceCameraController enableLook={false} enableZoom={false} />
      <Camera />
      <Globe show={false} />
      <Scene
        backgroundColor={Cesium.Color.fromCssColorString(theme.palette.background.main)}
        light={new Cesium.DirectionalLight({ direction: new Cesium.Cartesian3(0, -0.5, -1) })}
      />
      {branch && (
        <>
          <Entity
            name={`${branch.repo} - ${branch.name}`}
            key={`${branch.repo} - ${branch.name}`}
            position={new Cesium.Cartesian3(0, 0, 0)}
            orientation={Cesium.Quaternion.fromAxisAngle(Cesium.Cartesian3.UNIT_Z, -pi / 4)}
            point={{
              pixelSize: 15,
              color: Cesium.Color.ORANGE,
              show: !model.cadSignedUrl,
            }}
            model={
              branch && {
                uri: model.cadSignedUrl,
                scale:
                  model.cadScaleFactor > 1
                    ? (cesiumScaleLinearSlope * 1000 + cesiumScaleLinearIntercept) *
                      5 *
                      model.cadScaleFactor
                    : 5000 * cesiumModelScaleDown * model.cadScaleFactor * 3000,
                show: true,
              }
            }
          />
          {/* Add back in if we can figure out a better way to evenly scale them */}
          {/* {bfVectors.map((bfVector) => {
            return (
              <Entity
                name={bfVector.name}
                key={bfVector.name}
                position={Cesium.Cartesian3.fromArray(
                  bfVector.unitVector.map((v) => v * 700000000 * model.cadScaleFactor * 1.1),
                  0,
                  new Cesium.Cartesian3()
                )}
              >
                <PolylineGraphics
                  positions={[
                    new Cesium.Cartesian3(0, 0, 0),
                    Cesium.Cartesian3.fromArray(
                      bfVector.unitVector.map((v) => v * 700000000 * model.cadScaleFactor),
                      0,
                      new Cesium.Cartesian3()
                    ),
                  ]}
                  width={8}
                  material={new Cesium.PolylineArrowMaterialProperty(Cesium.Color.CYAN)}
                  arcType={Cesium.ArcType.NONE}
                  show={true}
                />
                <LabelGraphics
                  text={bfVector.name}
                  show={true}
                  scale={0.35}
                  fillColor={Cesium.Color.WHITE}
                />
              </Entity>
            );
          })} */}
        </>
      )}
    </Viewer>
  );
};

export default AgentThumbnail;
