import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    '& .MuiInputBase-root': {
      maxWidth: '600px !important',
      width: '100% !important',
    },
    '& .MuiChip-label': {
      textAlign: 'center',
      fontSize: 14,
      minWidth: 50,
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.contrastText,
    },
  },
  menuTree: {
    margin: 12,
    height: '100%',
    width: '33%',
    backgroundColor: theme.palette.background.main,
    borderRight: `1px solid ${theme.palette.text.secondary}`,
  },
  treeItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
  },
  menuItemEdit: {
    height: '100%',
    width: '33%',
    marginLeft: '0px 12px',
  },
  widgetView: {
    height: '50%',
    width: '100%',
    margin: 12,
    padding: 12,
    '& h3, h1': {
      paddingTop: 20,
      paddingBottom: 5,
    },
  },
  widgetEdit: {
    height: '50%',
    width: '100%',
    marginTop: 24,
    paddingTop: 24,
    borderTop: `4px double ${theme.palette.text.secondary}`,
    '& h3, h1': {
      paddingTop: 20,
      paddingBottom: 5,
    },
  },
  chartEdit: {
    height: '100%',
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    backgroundColor: theme.palette.background.mainNested,
    margin: 12,
    padding: 12,
    border: `1px dashed ${theme.palette.text.secondary}`,
    borderRadius: 5,
  },
  emptyChart: {
    height: '100%',
    width: '33%',
    margin: '0px 12px',
    padding: '0px 12px',
  },
  widgetChip: {
    margin: 5,
    minWidth: 100,
    backgroundColor: `${theme.palette.primary.dark}`,
    color: theme.palette.primary.contrastText,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      outline: `2px solid ${theme.palette.text.secondary}`,
    },
  },
  highlightedChip: {
    margin: 5,
    minWidth: 100,
    backgroundColor: `${theme.palette.primary.main}`,
    color: theme.palette.primary.contrastText,
    outline: `2px solid ${theme.palette.background.contrastText}`,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  breadcrumbText: {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  textField: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  variable: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    width: '98%',
    margin: 5,
    padding: 5,
    borderRadius: 5,
    border: `0.5px solid ${theme.palette.text.secondary}`,
  },
  variableInputs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    padding: 12,
    margin: 8,
    backgroundColor: theme.palette.background.main,
    borderRadius: 5,
  },
  subVariableInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.background.mainNested,
    border: `0.5px solid ${theme.palette.text.secondary}`,
    borderRadius: 5,
  },
}));

export default useStyles;
