import { makeStyles } from '@material-ui/core/styles';
import { timing } from 'config';
import { IUser } from '../types/general';
import { offWhite } from 'theme';

export const wayfinderHeight = 35;
const avatarDims = 30;

interface IProps {
  user?: IUser;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
  },
  body: {
    width: '100%',
    height: 'calc(100vh - ' + wayfinderHeight + 'px)',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  wayfinderRoot: {
    width: '100%',
    height: wayfinderHeight,
    backgroundColor: theme.palette.background.main,
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 1px 14px 0px rgba(0,0,0,0.12)',
    zIndex: 1000,
  },

  logoContainer: { height: '75%', display: 'flex', alignItems: 'center' },
  logo: { height: '80%', margin: '0px 5px' },
  logoText: { height: '155%' },

  wayfinderItem: {
    marginLeft: 5,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    color: theme.palette.text.tertiary,
    minWidth: 0,
    '& a': {
      display: 'inherit',
      alignItems: 'inherit',
      flexDirection: 'inherit',
      textDecoration: 'none',
      color: 'inherit',
      transition: `color ${timing.dragDropHighlightDuration}s ease-in-out`,
      overflow: 'hidden',
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    '& h1': {
      fontSize: 15,
      color: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginRight: 2,
    },
  },

  wayfinderIcon: {
    margin: '0px 3px 0px 7px',
    height: 12,
    color: 'inherit',
  },

  helpRoot: {
    marginLeft: 'auto',
    marginRight: '5px',
    display: 'flex',
  },
  helpIconsContainer: {
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    marginRight: 3,
  },
  helpIcon: {
    color: theme.palette.background.contrastText,
    textDecoration: 'none',
    display: 'inline-block',
    padding: 0,
    borderRadius: '0%',
    margin: '0px 5px',
    fontSize: 17,
    lineHeight: '14px',
  },
  divider: {
    height: wayfinderHeight * 0.7,
    margin: 'auto',
  },
  accountAvatar: ({ user }: IProps = {}) => ({
    width: avatarDims,
    height: avatarDims,
    textAlign: 'center',
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: user ? theme.palette.tertiary.main : '',
    cursor: user ? 'pointer' : 'default',

    '& h4': {
      lineHeight: '31px',
      fontSize: 15,
    },
  }),
  anonymousUserIcon: {
    height: '100%',
    width: '100%',
    color: offWhite(0.3),
  },
}));

export default useStyles;
