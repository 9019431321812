import MetamodelTypeIcon from 'components/general/MetamodelTypeIcon';
import { IProject } from 'components/general/types';
import { ItemTypes } from 'config';
import { usePermissionCheck, useSelectById, useSelectByIds } from 'hooks';
import moment from 'moment-timezone';
import { ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import { TMetamodelTypes } from 'utils/repoAndMmType';
import { WorkspaceVables } from 'utils/vable';
import useStyles from './styles';

interface IProps {
  project: IProject;
}

const ProjectRow = (props: IProps) => {
  // Utils
  const history = useHistory();
  const canEdit = usePermissionCheck(WorkspaceVables.Permission.EDIT_REPOSITORY);

  // Project
  const { project } = props;
  const repos = useSelectByIds('Mission', project?.repositories || []);

  const createdBy = useSelectById('User', project.createdBy || '');
  const lastModifiedBy = useSelectById('User', project.lastModifiedBy || '');

  // Drag and drop
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.REPO,
      canDrop: () => canEdit,
      drop: () => project,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [canEdit, project]
  );
  const classes = useStyles({ isOver, canDrop });

  return (
    <div
      ref={drop}
      className={classes.row}
      onClick={() => history.push(Routes.PROJECT(project.id))}
    >
      <div className={classes.infoContainer}>
        <h3 style={{ height: '1rem' }}>
          {project.name}
          {Array.from(
            repos.reduce((dataTypes, repo) => {
              dataTypes.add(repo.metamodelType);
              return dataTypes;
            }, new Set<TMetamodelTypes>())
          )
            .sort()
            .map((dataType) => <MetamodelTypeIcon key={dataType} metamodelType={dataType} />)
            .reduce((prev, curr) => {
              // Add an icon for each data type in the project (scenario, satellite template, etc.)
              prev.push(
                <span className={classes.iconSeparator} key={`${curr.key}-dot`}>
                  {' •'}
                </span>
              );
              prev.push(curr);
              return prev;
            }, [] as ReactNode[])}
        </h3>
        <div className={classes.description}>
          {createdBy && `Created by ${createdBy.firstName} ${createdBy.lastName}`}
          {createdBy && lastModifiedBy && ' | '}
          {`Updated ${moment(project.dateModified).fromNow()}${
            lastModifiedBy ? ` by ${lastModifiedBy.firstName} ${lastModifiedBy.lastName}` : ''
          }`}
          <br />
        </div>
      </div>
    </div>
  );
};

export default ProjectRow;
