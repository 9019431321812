import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@material-ui/core';
import { IErrorResponse, IGenericObject } from 'components/general/types';
import { useActiveEntities, usePermissionCheck, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { WorkspaceVables } from 'utils/vable';
import useStyles from './styles';

const ExportIcon = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const canExport = usePermissionCheck(WorkspaceVables.Permission.EXPORT_BRANCH);

  const { export: exportBranch } = SatelliteApi.MissionVersion.actions;
  const { branch } = useActiveEntities();
  const { enqueueSnackbar } = useSnackbar();

  const onClick = () => {
    setLoading(true);
    dispatch(
      exportBranch({
        id: branch.id,
        responseType: 'blob', // used in axios to parse response
        successCallback: (data: IGenericObject) => {
          enqueueSnackbar(`Downloaded ${data.fileName}`, { variant: 'success' });
          setLoading(false);
        },
        failureCallback: (res: IErrorResponse) => {
          enqueueSnackbar(res.error.message);
          setLoading(false);
        },
      })
    );
  };

  return (
    <Tooltip arrow title="Export">
      <IconButton
        className={classes.branchNavIcon}
        disabled={loading || !canExport}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faDownload} />
      </IconButton>
    </Tooltip>
  );
};

export default ExportIcon;
