import { OptionsObject, SnackbarMessage, useSnackbar as useSnackbarBase } from 'notistack';
import { useCallback } from 'react';

/**
 * Extends the notistack `useSnackbar` hook to add defaults as follows:
 * - `variant` defaults to `'error'`
 * - `persist` defaults to `true` if `variant` is `'error'`, otherwise `false`
 */
const useSnackbar = () => {
  const { enqueueSnackbar: enqueueSnackbarBase, closeSnackbar } = useSnackbarBase();

  const enqueueSnackbar = useCallback(
    (message: SnackbarMessage, options: OptionsObject = {}) => {
      options.variant = options.variant ?? 'error';
      options.persist = options.persist ?? options.variant === 'error';
      return enqueueSnackbarBase(message, options);
    },
    [enqueueSnackbarBase]
  );

  return {
    enqueueSnackbar,
    closeSnackbar,
  };
};

export default useSnackbar;
