import { makeGuidance } from 'hooks';

const dataStorageDescription =
  'Data Storage elements store data on an associated Component. Data of one or more types that flows into the component via a Data Interface or produced in a Data Mode will be stored on the Data Storage block, where it can later travel out on a Data Interface.\nThe relationship between a component and a data storage device is one-to-one; a component can only have one data storage device and storage devices cannot be shared between components.';

export const dataStorageGuidance = {
  _default: {
    heading: 'Data Storage',
    body: [{ chunk: dataStorageDescription }],
  },
};

export const useGuidance = makeGuidance(dataStorageGuidance);

const dataStorageSegmentGuidance = {
  heading: 'Data Storage',
  body: [{ chunk: dataStorageDescription }],
};

export default dataStorageSegmentGuidance;
