import * as Yup from 'yup';

const operationalModeSchema = Yup.object({
  // pointingMode: Yup.object().required('Select a pointing mode.'),

  limitDurationAndFrequency: Yup.boolean(),

  maxOccurrenceDuration: Yup.mixed()
    .when('limitDurationAndFrequency.', {
      is: true,
      then: Yup.object({
        min: Yup.number().min(0, 'Maximum occurrence duration must be non-negative.'),
      }),
    })
    .nullable(),

  minTimeBetweenOccurrences: Yup.mixed()
    .when('limitDurationAndFrequency.', {
      is: true,
      then: Yup.object({
        min: Yup.number()
          .required('A minimum time between occurrences is required.')
          .min(0, 'Minimum time between occurrences must be non-negative.'),
      }),
    })
    .nullable(),

  requireMinDuration: Yup.boolean(),

  minOccurrenceDuration: Yup.mixed()
    .when('requireMinDuration', {
      is: true,
      then: Yup.object({
        min: Yup.number()
          .required('A minimum occurrence duration is required.')
          .min(0, 'Minimum occurrence duration must be non-negative.')
          .test(
            'compareMaxOccurrence',
            'The minimum occurrence must be less than the maximum occurrence duration.',
            (minOccurrenceDuration, schema) => {
              const { parent } = schema;
              if (
                parent.limitDurationAndFrequency &&
                typeof parent.maxOccurrenceDuration?.min === 'number'
              ) {
                return minOccurrenceDuration < parent.maxOccurrenceDuration.min;
              }
              return true;
            }
          ),
      }),
    })
    .nullable(),
});

export default operationalModeSchema;
