import { Button, CircularProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { READ_ONLY_TOOLTIP } from 'config';
import { useInReadOnlyBranch } from 'hooks';
import useStyles from './styles';

const StyledButton = (props) => {
  const {
    className,
    children,
    replaceSpinner,
    loading,
    disabled,
    tooltip,
    framed,
    borderless,
    addIcon,
    min,
    tallMargin,
    midMargin,
    error,
    dontDisableInReadOnly,
    off,
    ...remainingPropsForButtonTag
  } = props;

  const disableInReadOnlyBranch = useInReadOnlyBranch() && !dontDisableInReadOnly;
  const readOnlyDisableException = ['Close', 'Cancel'].includes(children);
  const _disabled = loading || disabled || (!readOnlyDisableException && disableInReadOnlyBranch);
  const tooltipTitle =
    (!readOnlyDisableException && disableInReadOnlyBranch ? READ_ONLY_TOOLTIP : tooltip) || '';

  const classes = useStyles({ ...props, disabled: _disabled });

  return (
    <Tooltip title={tooltipTitle} aria-label="add" className={className}>
      <span className={classes.wrapper}>
        <Button className={classes.button} disabled={_disabled} {...remainingPropsForButtonTag}>
          <div className={classes.contentContainer}>
            {loading && !replaceSpinner && <div className={classes.spacer} />}
            {(!replaceSpinner || !loading) && (
              <div className={classes.label}>
                {addIcon && <AddIcon />}
                {children}
              </div>
            )}
            {loading && (
              <div className={classes.spacer}>
                <CircularProgress size={22} thickness={5} className={classes.spinner} />
              </div>
            )}
          </div>
        </Button>
      </span>
    </Tooltip>
  );
};

export default StyledButton;
