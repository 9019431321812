import ViewPortInlay, { FallbackInlay } from 'components/general/ViewPortInlay';
import WGroup from 'components/general/WGroup';
import { hotkeys } from 'config';
import { useActiveEntities } from 'hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';
import { routePathsCommon } from 'routes';
import PlaybackControls from './PlaybackControls';
import PlaybackViewer from './PlaybackViewer';
import { cesiumClockMultiplierScaleDefault } from './general/constants';

const bottomSpacer = { height: 100 }; // Spacing to prevent any controls on the bottom of the view from being covered by the floating playback controls
const scenarioPlaybackRegex = new RegExp(
  // lint thinks escapes here are unneccesary b/c doesn't know it's becoming regex
  `/${routePathsCommon.SCENARIO}/[a-zA-Z0-9_-]*/${routePathsCommon.ANALYZE}/.*` // eslint-disable-line
);

export default function PlaybackWGroups(props) {
  const { index, masonryConfig, children } = props;

  const dataAliasing = useMemo(() => {
    // TODO: Logic for downsampling
    return false;
  }, []);

  const { branch } = useActiveEntities();
  const viewerRef = useRef(null);
  const cameraRef = useRef(null);
  const clockRef = useRef(null);
  const satelliteEntityRef = useRef(null);
  const playbackRef = useRef(null);
  const [inFullscreen, setInFullscreen] = useState(false);
  const { pathname } = useLocation();
  const isScenarioPlayback = useMemo(() => scenarioPlaybackRegex.test(pathname), [pathname]);
  const [cesiumOptions, setCesiumOptions] = useState(() => {
    const defaultSettings = {
      // Player controls
      animate: false,
      loop: false,
      clockMultiplier: cesiumClockMultiplierScaleDefault,

      // Scale multipliers
      cadScaleMultiplier: 1,
      fovScaleMultiplier: 1,
      labelScaleMultiplier: 1,

      // Menu options - top row
      useFlashlight: !isScenarioPlayback,
      useEciCamera: isScenarioPlayback,
      scaleToRealSize: false,
      showTargetNames: true,

      // Menu options - bottom row
      showCommsLines: true,
      showOrbitTails: true,
      followSc: !isScenarioPlayback,
      showAgentModels: true,
      showSkybox: true,

      // Menu options - waypoints
      showWayPoints: [true, true, true],
      showWayPaths: [true, true, true],

      // Menu options - resolution
      pathStep: -180,

      // Menu options - dropdowns
      terrain: 0,
      imagery: 0,
    };
    const savedSettings = JSON.parse(
      window.localStorage.getItem(`playbackSettings - ${branch.repository}`)
    );
    if (savedSettings) {
      return Object.keys(defaultSettings).reduce((acc, key) => {
        if (savedSettings[key] !== undefined) {
          acc[key] = savedSettings[key];
        } else {
          acc[key] = defaultSettings[key];
        }
        return acc;
      }, {});
    }
    return defaultSettings;
  });

  useEffect(() => {
    window.localStorage.setItem(
      `playbackSettings - ${branch.repository}`,
      JSON.stringify(cesiumOptions)
    );
  }, [cesiumOptions, branch]);

  useEffect(() => {
    function onFullscreenChange() {
      setInFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  // Fullscreen Hotkey
  useHotkeys(
    hotkeys.FULLSCREEN.keys,
    () => {
      if (playbackRef.current.innerHTML !== '')
        inFullscreen ? document.exitFullscreen() : playbackRef.current.requestFullscreen();
    },
    [inFullscreen, playbackRef]
  );

  return (
    <ErrorBoundary
      fallback={
        <WGroup index={index}>
          <FallbackInlay
            text="Our playback analytics are under construction."
            subText="Our team is working to restore this feature."
          />
        </WGroup>
      }
    >
      <>
        {!dataAliasing ? (
          <div ref={playbackRef}>
            <WGroup index={index}>
              <PlaybackControls
                viewerRef={viewerRef}
                clockRef={clockRef}
                cesiumOptions={cesiumOptions}
                setCesiumOptions={setCesiumOptions}
              />
            </WGroup>
            <WGroup index={index} masonryConfig={masonryConfig}>
              <PlaybackViewer
                viewerRef={viewerRef}
                cameraRef={cameraRef}
                clockRef={clockRef}
                satelliteEntityRef={satelliteEntityRef}
                cesiumOptions={cesiumOptions}
                setCesiumOptions={setCesiumOptions}
                isScenarioPlayback={isScenarioPlayback}
              />
              {children}
            </WGroup>
            <WGroup index={index}>
              <div style={bottomSpacer}></div>
            </WGroup>
          </div>
        ) : (
          <WGroup index={index}>
            <ViewPortInlay
              text="Simulation playback cannot be displayed for downsampling rates greater than 50%. Please fetch
                a smaller window."
            />
          </WGroup>
        )}
      </>
    </ErrorBoundary>
  );
}

export { default as MissionDesignerPlaybackControls } from './PlaybackControls';
