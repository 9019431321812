import { DataHandlingAccent } from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IDataBus } from 'components/general/types/dataHandling';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectBlocks } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useCallback, useMemo, useRef } from 'react';
import { translateIn, translateOut } from 'utils/forms';
import { DataInterfaceVables } from 'utils/vable';
import * as Yup from 'yup';
import { useGuidance } from './guidance';

interface IForm {
  name: string;
}

interface IProps {
  control: TEntityDialogControl<IDataBus>;
}

const defaultValues = {
  name: '',
};

const tableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

const DataBusDialog = ({ control }: IProps) => {
  const { dialogConfig } = control;
  const { entity: dataBus } = dialogConfig;
  const { dataInterfaces } = useActiveEntities();

  const internalInterfaces = useMemo(
    () =>
      dataInterfaces.filter(
        (_interface) =>
          _interface.type === DataInterfaceVables.InterfaceType.InternalDataInterface.value
      ),
    [dataInterfaces]
  );

  const { parsedBlocks: parsedInterfaces, setParsedBlocks: setInterfaces } = useSelectBlocks(
    internalInterfaces,
    dataBus?.interfaces
  );
  const tableRef = useRef(null);

  const classes = useStyles();

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      values.interfaces = parsedInterfaces
        .filter((_interface) => _interface.tableData?.checked)
        .map((_interface) => _interface.id);
      return translateOut(values, allowedEmptyFields, options);
    },
    [parsedInterfaces]
  );

  const entityForm = useEntityForm<IDataBus, IForm>({
    entityTypeText: 'Data Bus',
    entityDialogControl: control,
    defaultValues,
    validationSchema: Yup.object().shape({}),
    additionalCreateValues: { type: 'DataBus' },
    formikOptionalParams: {
      useGuidance,
      translateIn,
      translateOut: customTranslateOut,
    },
  });
  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm} customDirty={true}>
      <div className={classes.inputs}>
        <LabeledInput
          {...getFieldProps('name')}
          label="Data Bus Name"
          type="text"
          placeholder="Name"
          autoFocus
        />
        <DataHandlingAccent header="Data Interfaces">
          <WidgetTable
            tableRef={tableRef}
            className={classes.table}
            columns={tableColumns}
            data={parsedInterfaces}
            setData={setInterfaces}
            emptyMessage={'No data interfaces found'}
            title="Select Data Interfaces"
            search={true}
            selection={true}
          />
        </DataHandlingAccent>
      </div>
    </EntityDialog>
  );
};

export default DataBusDialog;
