import { InputAdornment } from '@material-ui/core';
import { CdhAccent, MdAccent, SpacecraftAccent } from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { ISelectOption } from 'components/general/types';
import { ISensor } from 'components/general/types/gnc';
import { ILocalVector } from 'components/general/types/spacecraft';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectBlocks } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import getThermalProps from 'hooks/getThermalProps';
import { SpacecraftContext } from 'providers';
import { useCallback, useContext, useMemo, useRef } from 'react';

import { conditionsTableColumns } from 'components/AgentTemplateEditView/EditBoards/CDHEditBoard/OperationalModesSegment/OperationalModesDialog';

import { translateOut } from 'utils/forms';
import {
  ReferenceVectorVables,
  SensorVables,
  SubsystemVables,
  TLocalPointingDirections,
  TReferenceVectorTypes,
  TSensorTypes,
} from 'utils/vable';
import { useGuidance } from './guidance';
import validation from './validation';

export interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  type: ISelectOption | '';
  oneSigmaAngleError: { deg: number | '' };
  oneSigmaCrossAxisError: { deg: number | '' };
  oneSigmaBoresightAxisError: { deg: number | '' };
  oneSigmaPerAxisError: number | '' | { 'rad/s': number | '' };
  oneSigmaDistanceError: { km: number | '' };
  oneSigmaVelocityError: { 'km/s': number | '' };
  fieldOfView: ISelectOption | '';
  referenceVector:
    | (ISelectOption & {
        type: TReferenceVectorTypes;
        localPointingDirection: TLocalPointingDirections;
      })
    | '';
  target: ISelectOption | '';
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  type: '',
  oneSigmaAngleError: { deg: '' },
  oneSigmaCrossAxisError: { deg: '' },
  oneSigmaBoresightAxisError: { deg: '' },
  oneSigmaPerAxisError: '',
  oneSigmaDistanceError: { km: '' },
  oneSigmaVelocityError: { 'km/s': '' },
  fieldOfView: '',
  referenceVector: '',
  target: '',
};

interface IProps {
  control: TEntityDialogControl<ISensor>;
}

const hasFovDialog = [
  SensorVables.Type.VectorSensor.value,
  SensorVables.Type.OpticalAttitudeSensor.value,
];

const SensorDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action },
  } = control;
  const sensorConditions = control?.dialogConfig?.entity?.conditions;
  const { subsystems, fieldsOfView, referenceVectors, targets } = useActiveEntities();
  const { setSpacecraftDialogConfig, SpacecraftTabs } = useContext(SpacecraftContext);

  const { conditions } = useActiveEntities();

  const { parsedBlocks: parsedConditions, setParsedBlocks: setParsedConditions } = useSelectBlocks(
    conditions,
    sensorConditions
  );

  // Set up styles
  const classes = useStyles();

  const options = useMemo(() => {
    return {
      type: SensorVables.Type.options,
      fieldOfView: fieldsOfView.map((f) => {
        return { value: f.id, label: f.name };
      }),
      referenceVector: referenceVectors.map((v) => {
        return {
          value: v.id,
          label: v.name,
          // Below 2 keys are for filtering out some vectors for vector sensors
          type: v.type,
          // localPointingDirection is `undefined` if type isn't "LOCAL"
          localPointingDirection: (v as ILocalVector).localPointingDirection,
        };
      }),
      target: targets.map((t) => {
        // get all targets as options
        return { value: t.id, label: t.name };
      }),
    };
  }, [fieldsOfView, referenceVectors, targets]);

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      values.conditions = parsedConditions.filter((c) => c.tableData.checked).map((c) => c.id);
      return translateOut(values, allowedEmptyFields, options);
    },
    [parsedConditions]
  );

  const entityForm = useEntityForm<ISensor, IForm & typeof thermalDefaultValues>({
    entityTypeText: 'Sensor',
    entityDialogControl: control,
    defaultValues: { ...defaultValues, ...thermalDefaultValues },
    additionalCreateValues: {
      subsystem: subsystems.find((s) => s.category === SubsystemVables.Categories.GNC.value)?.id,
    },
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      translateOut: customTranslateOut,
      options,
      allowedEmptyFields: ['manufacturer', 'partNumber', 'fieldOfView', 'target'],
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setValues } = formik;

  return (
    <EntityDialog entityForm={entityForm} customDirty={true}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Sensor Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            placeholder="Manufacturer"
            type="text"
            optional
          />
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('type')}
            label="Sensor Type"
            options={options.type}
            isDisabled={action !== 'create'}
            formikOnChange={(val: ISelectOption) => {
              if (val !== values.type) {
                // Reset values that are based on type
                setValues((values) => ({
                  ...values,
                  referenceVector: defaultValues.referenceVector,
                  oneSigmaAngleError: defaultValues.oneSigmaAngleError,
                  oneSigmaCrossAxisError: defaultValues.oneSigmaCrossAxisError,
                  oneSigmaBoresightAxisError: defaultValues.oneSigmaBoresightAxisError,
                  oneSigmaPerAxisError: defaultValues.oneSigmaPerAxisError,
                  oneSigmaDistanceError: defaultValues.oneSigmaDistanceError,
                  fieldOfView: defaultValues.fieldOfView,
                  target: defaultValues.target,
                }));
              }
            }}
          />
          <div className={classes.indent}>
            {values.type === SensorVables.Type.DirectionSensor && (
              <>
                <SpacecraftAccent
                  header="Direction Vector"
                  onAddAction={() =>
                    setSpacecraftDialogConfig({
                      open: true,
                      tabNumber: SpacecraftTabs.REFERENCE_VECTORS,
                    })
                  }
                >
                  <LabeledSelect
                    {...getFieldProps('referenceVector')}
                    autoFocus
                    options={options.referenceVector}
                  />
                </SpacecraftAccent>
                <LabeledInput
                  {...getFieldProps('oneSigmaAngleError.deg')}
                  label="One Sigma Angle Error"
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                />
              </>
            )}
            {values.type === SensorVables.Type.OpticalAttitudeSensor && (
              <>
                <LabeledInput
                  {...getFieldProps('oneSigmaCrossAxisError.deg')}
                  label="One Sigma Cross-Axis Error"
                  autoFocus
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                />
                <LabeledInput
                  {...getFieldProps('oneSigmaBoresightAxisError.deg')}
                  label="One Sigma Boresight-Axis Error"
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                />
              </>
            )}
            {(values.type === SensorVables.Type.TargetAttitudeSensor ||
              values.type === SensorVables.Type.TargetPositionSensor ||
              values.type === SensorVables.Type.TargetRangeSensor ||
              values.type === SensorVables.Type.TargetRangeRateSensor) && (
              <>
                <MdAccent header="Space Target">
                  <LabeledSelect
                    {...getFieldProps('target')}
                    options={options.target.filter((t) => {
                      // filter to only space targets
                      let isSpaceTarget = false;
                      targets.forEach((target) => {
                        isSpaceTarget =
                          isSpaceTarget || (target.id === t.value && target.type === 'SpaceTarget');
                      }); // check if target is a space target
                      return isSpaceTarget;
                    })}
                  />
                </MdAccent>
                {values.type === SensorVables.Type.TargetAttitudeSensor && (
                  <>
                    <LabeledInput
                      {...getFieldProps('oneSigmaCrossAxisError.deg')}
                      label="One Sigma Cross-Axis Error"
                      autoFocus
                      type="number"
                      endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                    />
                    <LabeledInput
                      {...getFieldProps('oneSigmaBoresightAxisError.deg')}
                      label="One Sigma Boresight-Axis Error"
                      type="number"
                      endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                    />
                  </>
                )}
                {values.type === SensorVables.Type.TargetPositionSensor && (
                  <LabeledInput
                    {...getFieldProps('oneSigmaDistanceError.km')}
                    label="One Sigma Distance Error"
                    autoFocus
                    type="number"
                    endAdornment={<InputAdornment position="end">km</InputAdornment>}
                  />
                )}
                {values.type === SensorVables.Type.TargetRangeSensor && (
                  <LabeledInput
                    {...getFieldProps('oneSigmaDistanceError.km')}
                    label="One Sigma Distance Error"
                    autoFocus
                    type="number"
                    endAdornment={<InputAdornment position="end">km</InputAdornment>}
                  />
                )}
                {values.type === SensorVables.Type.TargetRangeRateSensor && (
                  <LabeledInput
                    {...getFieldProps('oneSigmaVelocityError.km/s')}
                    label="One Sigma Velocity Error"
                    autoFocus
                    type="number"
                    endAdornment={<InputAdornment position="end">km/s</InputAdornment>}
                  />
                )}
              </>
            )}
            {values.type === SensorVables.Type.VectorSensor && (
              // WARN: Only reference vectors with magnitude are allowed for vector sensors.
              // On 11/15/22, as a possibly temporary fix, it was decided that magnetic field should
              // be the only local reference vector allowed, since it's the only one with inherent
              // magnitude. Its unit is Teslas, and every other option (at present) has units in km.
              // This may no longer be valid if more types of local vectors are added.
              <>
                <SpacecraftAccent
                  header="Direction Vector"
                  onAddAction={() =>
                    setSpacecraftDialogConfig({
                      open: true,
                      tabNumber: SpacecraftTabs.REFERENCE_VECTORS,
                    })
                  }
                >
                  <LabeledSelect
                    {...getFieldProps('referenceVector')}
                    autoFocus
                    options={options.referenceVector.filter(
                      (rv) =>
                        rv.type !== ReferenceVectorVables.Type.LocalVector.value ||
                        rv.localPointingDirection ===
                          ReferenceVectorVables.LocalPointingDirections.MAGNETIC_FIELD.value
                    )}
                  />
                </SpacecraftAccent>
                <LabeledInput
                  {...getFieldProps('oneSigmaPerAxisError')}
                  label="One Sigma Per-Axis Error"
                  type="number"
                  endAdornment={
                    // Dynamic units based on referenceVector selection.
                    // WARN: If more types of local vectors are added, this may no longer be valid.
                    <InputAdornment position="end">
                      {values.referenceVector === '' ? '' : 'T'}
                    </InputAdornment>
                  }
                />
              </>
            )}
            {values.type === SensorVables.Type.AngularVelocitySensor && (
              <LabeledInput
                {...getFieldProps('oneSigmaPerAxisError.rad/s')}
                label="One Sigma Per-Axis Error"
                autoFocus
                type="number"
                endAdornment={<InputAdornment position="end">rad/s</InputAdornment>}
              />
            )}
            {values.type === SensorVables.Type.PositionSensor && (
              <LabeledInput
                {...getFieldProps('oneSigmaDistanceError.km')}
                label="One Sigma Error"
                autoFocus
                type="number"
                endAdornment={<InputAdornment position="end">km</InputAdornment>}
              />
            )}
          </div>
        </div>
        {values.type && hasFovDialog.includes(values.type.value.toString() as TSensorTypes) && (
          <div className={classes.inputGroup}>
            <SpacecraftAccent
              header="Field of View"
              onAddAction={() =>
                setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
              }
            >
              <LabeledSelect
                {...getFieldProps('fieldOfView')}
                label="Sensor Field of View"
                options={options.fieldOfView}
                optional={values.type !== SensorVables.Type.OpticalAttitudeSensor}
              />
            </SpacecraftAccent>
          </div>
        )}
        <div className={classes.inputGroup}>
          <CdhAccent header="Conditions">
            <WidgetTable
              tableRef={useRef(null)}
              className={classes.table}
              columns={conditionsTableColumns}
              setData={setParsedConditions}
              data={parsedConditions}
              emptyMessage={'No conditions found'}
              title="Select Conditions"
              search={true}
              selection={true}
            />
          </CdhAccent>
        </div>
        <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
      </div>
    </EntityDialog>
  );
};

export default SensorDialog;
