import {
  IGuidanceCard,
  INestedSelectOption,
  ISelectOption,
  TDialogConfig,
} from 'components/general/types';
import { FormikValues, useFormik } from 'formik';
import { useSnackbar } from 'hooks';
import _ from 'lodash';
import { useEffect } from 'react';
import { getFirstError } from 'utils/errors';
import {
  translateIn as defaultTranslateIn,
  translateOut as defaultTranslateOut,
} from 'utils/forms';
import * as Yup from 'yup';

type TTranslateIn<EntityType, FormType> = (
  fullData: TDialogConfig<EntityType>['entity'],
  defaultValues: FormType,
  options?: {
    [key: string]: (ISelectOption | INestedSelectOption)[];
  },
  datetimes?: string[],
  percentages?: string[]
) => FormType;

export type TTranslateOut<FormType, ResultType = FormType> = (
  copied: FormType,
  allowedEmptyFields?: string[],
  options?: {
    [key: string]: (ISelectOption | INestedSelectOption)[];
  },
  datetimes?: string[],
  percentages?: string[]
) => ResultType;

export interface ISanitizedParameters<EntityType, FormType, ResultType = FormType> {
  translateIn: TTranslateIn<EntityType, FormType>;
  translateOut: TTranslateOut<FormType, ResultType>;
  options?: {
    [key: string]: (ISelectOption | INestedSelectOption)[];
  };
  datetimes: string[];
  useGuidance: (
    values: FormType
  ) => { guidance: IGuidanceCard; guide: () => void } | { guidance: null; guide: null };
  allowedEmptyFields: string[];
  percentages: string[];
}

// Formik Form Hook Use
// Every formik form will require 4 parameters:
// 1. Default values for the form used in
// 2. The function to run on submit
// 3. Validation Schema. This should be defined using the Yup validation package
// 4. The entity data itself, e.g. condition or pointing mode
// Then there are optional parameters:
// 1. Custom translate in function (will default to just using tranlsate in)
// 2. Custom translate out function (will default to just using tranlsate out)
// 3/4. Options/datatimes for the translate in and out functions
// 5. A guidance hook if the form is using guidance
const useFormikForm = <EntityType, FormType extends FormikValues, ResultType = FormType>(
  defaultValues: FormType,
  // this should represent handleRunningSimulation, which is a function that returns a function of
  // TDispatch, which has the tranlsatedOut values as the first argument and resetForm as second optional
  actualOnSubmit: (handleSubmit: ResultType, resetForm: () => void) => void,
  validationSchema: Yup.AnyObjectSchema | undefined,
  fullData: EntityType | undefined,
  optionalParameters: Partial<ISanitizedParameters<EntityType, FormType, ResultType>> = {}
) => {
  // There are multiple optional parameters that can be passed in for each form so we use a helper function to pass in defaultValues for the arguments that are not provided
  const {
    translateIn,
    translateOut,
    options,
    datetimes,
    useGuidance,
    allowedEmptyFields,
    percentages,
  } = sanitizeOptionalParameters<EntityType, FormType, ResultType>(optionalParameters);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fullData
      ? translateIn(fullData, defaultValues, options, datetimes, percentages)
      : defaultValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    // resetForm is destructured from formik and can be called in any dispatch functions to reset the form
    onSubmit: (values, { resetForm }) => {
      // The translate out function is run before the onSubmit function so the onSubmit function can just worry about the values and not having to format them
      const copied = _.cloneDeep(values);
      actualOnSubmit(
        translateOut(copied, allowedEmptyFields, options, datetimes, percentages),
        resetForm
      );
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  // Hook up guidance and extend the getFieldProps method from Formik to automatically pass our guide into each field
  const { guidance, guide } = useGuidance(formik.values);
  const getFieldProps = (name: string) => {
    return {
      ...formik.getFieldProps(name),
      guide,
      errors: formik.errors,
      setFieldValue: formik.setFieldValue,
    };
  };

  // Error Hook Up
  // isSubmitting for formik is set to true, then validation is run, errors are set, and finally isSubmitting is set to false
  // Due to this order the useEffect can just listen for when isSubmitting is set back to false and check the updated errors list to queue the error
  useEffect(() => {
    const error = getFirstError(formik.errors);
    if (error && !formik.isSubmitting) {
      enqueueSnackbar(error);
    }
  }, [formik.isSubmitting, enqueueSnackbar]); //eslint-disable-line

  return { formik: { ...formik, getFieldProps }, guidance };
};

// Helper function
const sanitizeOptionalParameters = <EntityType, FormType, ResultType = FormType>(
  optionalParameters: Partial<ISanitizedParameters<EntityType, FormType, ResultType>>
): ISanitizedParameters<EntityType, FormType, ResultType> => {
  const defaultValues = {
    // Allow for any custom translate in or out formatting. This mainly required due to checkbox/scalar interactions
    // Note this may be able to be removed if we rename scalar in the backend and normalize all translateIn/Out functions acrossed forms
    translateIn: defaultTranslateIn,
    translateOut: defaultTranslateOut,
    options: {},
    datetimes: [],
    // useGuidance is a hook so cannot be called conditionally
    // To use the hook with forms that do not require guidance we simply have a default function that returns null for guide and guidance since they aren't used
    useGuidance: () => {
      return { guidance: null, guide: null };
    },
    allowedEmptyFields: [],
    percentages: [],
  };

  return { ...defaultValues, ...optionalParameters };
};

export default useFormikForm;
