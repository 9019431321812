// TODO: Delete this component once DSv2 is no longer supported

import Chart from 'components/general/charts/Chart';
import { timing } from 'config';
import type { EChartOption } from 'echarts';
import { graphic } from 'echarts';
import { useCallback, useMemo } from 'react';
import theme, { shade } from 'theme';
import { mjd2Moment } from 'utils/time';
import { IGenericObject } from '../types';
import { ICondition, IOperationalMode } from '../types/cdh';

interface IProps {
  title?: string;
  model: IGenericObject;
  data: IGenericObject[];
}

// Define colors for this plot
const colors = Object.values(theme.palette.charts.primary);

const ConOpsChartOld = ({ title, model, data }: IProps) => {
  const opModes: IOperationalMode[] = model.OperationalMode.all();
  const opModesPriority: IOperationalMode[] = [...opModes].sort(function (a, b) {
    return b.priority - a.priority;
  });
  const conditions: ICondition[] = model.Condition.all();

  const [opModeRows, rowNames] = useMemo(() => {
    let currentRow = 0;
    const modeRows: { [key: string]: number } = {};
    const names = opModesPriority.flatMap((mode: IGenericObject) => {
      const conditions = mode.conditions.map((x: ICondition) => x.name);
      if (conditions.length) {
        modeRows[mode.name] = currentRow + 1;
        currentRow += 4 + conditions.length;
        return [
          {
            value: mode.name,
            textStyle: {
              fontSize: 14,
              fontWeight: 'bold',
              color: colors[(opModes.length - mode.priority - 1) % colors.length],
              verticalAlign: 'top',
            },
          },
          'Mode Compliance',
          {
            value: 'Condition Compliance',
            textStyle: {
              verticalAlign: 'top',
              fontWeight: 'bold',
            },
          },
          ...conditions,
          '',
        ];
      } else {
        modeRows[mode.name] = currentRow;
        currentRow += 2;
        return [
          {
            value: mode.name,
            textStyle: {
              fontSize: 14,
              fontWeight: 'bold',
              color: colors[(opModes.length - mode.priority - 1) % colors.length],
            },
          },
          '',
        ];
      }
    });
    return [modeRows, names];
  }, [opModes, opModesPriority]);

  const conditionToIndex = conditions.reduce((acc: { [key: string]: number }, cond, i) => {
    acc[cond.name] = i;
    return acc;
  }, {});

  const dataOrg = useMemo(() => {
    const dataOrg: number[][] = [];
    const currentIntervals: { [key: number]: { start: number; state: number } } = {};
    rowNames.forEach((r, i) => {
      currentIntervals[i] = { start: data[0].times.mjd, state: -1 };
    });
    data.forEach((d, dataIndex) => {
      opModes.forEach((mode, index) => {
        let status = 0;
        if (d.operationalModeStatuses[index]) {
          status = 2;
        } else if (!status && d.operationalModeCompliances[index]) {
          status = 1;
        }
        mode.conditions.forEach((cond, i) => {
          const conditionStatus = d.conditionCompliances[conditionToIndex[cond.name]]
            ? status === 2
              ? 2
              : 1
            : 0;
          if (currentIntervals[opModeRows[mode.name] + i + 2].state === -1) {
            currentIntervals[opModeRows[mode.name] + i + 2].state = conditionStatus;
          }
          if (currentIntervals[opModeRows[mode.name] + i + 2].state !== conditionStatus) {
            dataOrg.push([
              currentIntervals[opModeRows[mode.name] + i + 2].start,
              d.times.mjd,
              opModeRows[mode.name] + i + 2,
              currentIntervals[opModeRows[mode.name] + i + 2].state,
              0,
              (opModes.length - mode.priority - 1) % colors.length,
            ]);
            currentIntervals[opModeRows[mode.name] + i + 2].start = d.times.mjd;
            currentIntervals[opModeRows[mode.name] + i + 2].state = conditionStatus;
          }
          if (dataIndex === data.length - 1) {
            dataOrg.push([
              currentIntervals[opModeRows[mode.name] + i + 2].start,
              d.times.mjd,
              opModeRows[mode.name] + i + 2,
              conditionStatus,
              0,
              (opModes.length - mode.priority - 1) % colors.length,
            ]);
          }
        });

        if (currentIntervals[opModeRows[mode.name]].state === -1) {
          currentIntervals[opModeRows[mode.name]].state = status;
        }
        if (currentIntervals[opModeRows[mode.name]].state !== status) {
          dataOrg.push([
            currentIntervals[opModeRows[mode.name]].start,
            d.times.mjd,
            opModeRows[mode.name],
            currentIntervals[opModeRows[mode.name]].state,
            1,
            (opModes.length - mode.priority - 1) % colors.length,
          ]);
          currentIntervals[opModeRows[mode.name]].start = d.times.mjd;
          currentIntervals[opModeRows[mode.name]].state = status;
        }
        if (dataIndex === data.length - 1) {
          dataOrg.push([
            currentIntervals[opModeRows[mode.name]].start,
            d.times.mjd,
            opModeRows[mode.name],
            status,
            1,
            (opModes.length - mode.priority - 1) % colors.length,
          ]);
        }
      });
    });
    return dataOrg;
  }, [conditionToIndex, rowNames, data, opModeRows, opModes]);

  const renderItem = useCallback((params: IGenericObject, api: IGenericObject) => {
    const getOpacity = [0.1, 0.35, 1];
    const rowIndex = api.value(2);
    const start = api.coord([api.value(0), rowIndex]);
    const end = api.coord([api.value(1), rowIndex]);
    const height = api.size([0, 1])[1] * (api.value(4) ? 0.8 : 0.5) * (!api.value(3) ? 0.5 : 1);
    const rectShape = graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );
    const lineWidth = 0;
    // const lineWidth = api.value(4) && api.value(3) > 1 ? 1 : 0;
    return (
      rectShape && {
        type: 'rect',
        // transition: ['shape'],
        shape: rectShape,
        style: api.style({
          fill:
            api.value(3) === 0
              ? '#FFFFFF'
              : api.value(4)
              ? colors[api.value(5)]
              : shade(colors[api.value(5)], 75),
          lineWidth: lineWidth,
          opacity: getOpacity[api.value(3)],
          stroke: 'rgba(255,255,255,0.5)',
        }),
        focus: 'none',
      }
    );
  }, []);

  const option = useMemo(
    () =>
      ({
        // color: colors,
        textStyle: {
          color: theme.palette.background.contrastText,
          ...theme.typography.body,
        },
        animationDuration: timing.lineAnimationDuration,
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
              filterMode: 'none',
              // Don't actually draw the toolbox on the widget, but retain its functionality
              icon: null,
            },
            saveAsImage: {
              show: true,
              backgroundColor: theme.palette.background.main,
              name: title || 'Sedaro Boolean Series Chart',
              pixelRatio: 2, // saved image to displayed container resolution ratio
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            snap: true,
            axis: 'x',
            lineStyle: {
              type: 'solid',
            },
          },
          textStyle: {
            color: theme.palette.background.contrastText,
            fontSize: 12,
          },
          position: (pos) => {
            return [pos[0], title ? 40 : 0];
          },
          formatter: (params: IGenericObject[]) => {
            let activeMode = '';
            dataOrg.every((x) => {
              if (
                x[0] <= params[0].axisValue &&
                x[1] > params[0].axisValue &&
                x[4] === 1 &&
                x[3] === 2
              ) {
                activeMode = rowNames[x[2]].value ? rowNames[x[2]].value : rowNames[x[2] - 1].value;
                return false;
              }
              return true;
            });
            return (
              mjd2Moment(params[0].axisValue).format('HH:mm:ss MM-DD-YYYY') +
              '<br />' +
              (activeMode !== '' ? 'Active Mode: ' + activeMode : 'No Active Mode')
            );
          },
          transitionDuration: 0,
          backgroundColor: 'transparent',
          borderWidth: 0,
          shadowColor: 'transparent',
        },
        title: {
          text: title,
          top: 10,
          left: 'center',
          textStyle: {
            color: theme.palette.background.contrastText,
            ...theme.typography.h3,
          },
        },
        legend: {
          top: title && 40,
          width: '60%',
          textStyle: {
            ...theme.typography.body,
            color: theme.palette.background.contrastText,
            fontSize: 12,
          },
          inactiveColor: theme.palette.action.disabled,
          itemHeight: 3,
          itemWidth: 20,
          // Manually set the icon through the `data` property, instead of using the default `icon` prop
          // Allows us to change icon per line, specifically for dashed lines here
        },
        grid: {
          // Params for the whole chart
          // NOTE: If you're looking for gridlines in particular, they're defined by "splitLine" on x/y-Axis
          // NOTE: If you're looking for the top, left, right margins, they're delegated down to the `Chart`
          //       component – hacked to be more dynamic.
        },
        xAxis: {
          name: 'Time (UTC)',
          nameLocation: 'center',
          nameGap: 50,
          animation: false,
          min: 'dataMin',
          max: 'dataMax',
          axisPointer: {
            snap: true,
            axis: 'x',
            lineStyle: {
              type: 'solid',
            },
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            // xAxis plots time, but our time values are in MJD, so format
            formatter: (value: number) => mjd2Moment(value).format('MM-DD-YYYY[\n]HH:mm:ss'),
          },
          axisLine: {
            show: true,
            lineStyle: { color: theme.palette.background.contrastText },
          },
          splitLine: {
            lineStyle: { color: theme.palette.action.disabled, opacity: 0.25 },
          },
        },
        yAxis: {
          inverse: true,
          axisLabel: {
            fontSize: 10,
            width: 110,
            overflow: 'break',
          },
          axisTick: {
            alignWithLabel: true,
            interval: (index: number, value: string) => {
              return (
                value !== '' &&
                value !== 'Condition Compliance' &&
                !Object.values(opModeRows).includes(index + 1)
              );
            },
          },
          data: rowNames,
        },
        series: [
          {
            type: 'custom',
            renderItem: renderItem,
            data: dataOrg,
          },
          {
            name: '',
            symbol: 'none',
            type: 'line',
            lineStyle: {
              color: 'transparent', //Make extra series invisible
            },
            data: data.map((x) => {
              return [x.times.mjd, 0];
            }),
            // yAxisIndex: 1,
            sampling: 'average',
          },
        ],
      } as EChartOption),
    [data, title, dataOrg, opModeRows, renderItem, rowNames]
  );
  return (
    <Chart
      option={option}
      style={{ height: 800, marginTop: 15, marginBottom: 15, width: 'auto!important' }}
      titled={Boolean(title)}
      withZoom={true}
      dynamicMarginMagicNumbers={{
        leftMargin: [8, 24],
        rightMargin: [3.75, 6.5],
        e: 1.004,
        shift: 700,
      }}
    />
  );
};

export default ConOpsChartOld;
