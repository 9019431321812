import { InputAdornment } from '@material-ui/core';
import { CdhAccent } from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { TBlockId } from 'components/general/types';
import { IRoutine } from 'components/general/types/cdh';
import { IControlState, ITempController } from 'components/general/types/thermal';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useCallback, useEffect, useRef, useState } from 'react';
import { translateOut } from 'utils/forms';
import { useGuidance } from './guidance';
import validation from './validation';

interface IForm {
  name: string;
  constantTemperature: { degC: number | '' };
  routines?: IRoutine[];
}

const defaultValues: IForm = {
  name: '',
  constantTemperature: { degC: '' },
};

const routineTableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

interface IProps {
  control: TEntityDialogControl<IControlState>;
  tempController?: ITempController;
}

interface IParsedRoutine {
  id: TBlockId;
  name: string;
  tableData: {
    checked: boolean;
  };
}

const ControlStatesDialog = ({ control, tempController }: IProps) => {
  // Get entity
  const {
    dialogConfig: { entity: controlState },
  } = control;

  // Set up styles
  const classes = useStyles();

  // Set up table data
  const tableRef = useRef(null);
  const { leafRoutines: routines } = useActiveEntities();
  const [parsedRoutines, setParsedRoutines] = useState<IParsedRoutine[]>([]);

  // Set up routines for select table
  const initRoutines = useCallback(() => {
    let _parsedRoutines: IParsedRoutine[] = [];
    if (routines) {
      _parsedRoutines = routines.map((routine: IRoutine) => ({
        id: routine.id,
        name: routine.name,
        tableData: {
          checked: !!controlState?.routines?.map(({ id }) => id).includes(routine.id),
        },
      }));
    }
    setParsedRoutines(_parsedRoutines);
  }, [routines, setParsedRoutines, controlState]);

  useEffect(() => {
    initRoutines();
  }, [initRoutines]);

  const customTranslateOut = useCallback(
    (values) => {
      values.routines = parsedRoutines
        .filter((routine: IParsedRoutine) => routine.tableData?.checked)
        .map((panel) => panel.id);
      return translateOut(values);
    },
    [parsedRoutines]
  );

  // Use entity form hook to hook up form
  const entityForm = useEntityForm<IControlState, IForm>({
    entityTypeText: 'Control State',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    extendReset: initRoutines,
    additionalCreateValues: { tempController: tempController?.id, type: 'TempControllerState' },
    formikOptionalParams: {
      useGuidance,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, setFieldValue } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Control State Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('constantTemperature.degC')}
            type="number"
            endAdornment={<InputAdornment position="end">°C</InputAdornment>}
            label="Constant Temperature"
          />
        </div>
      </div>
      <div className={classes.inputGroup}>
        <CdhAccent header="Operational Modes">
          <WidgetTable
            tableRef={tableRef}
            className={classes.table}
            columns={routineTableColumns}
            data={parsedRoutines}
            setData={setParsedRoutines}
            // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
            // It is used through a level of indirection here to set the value on the form data and therefore mark
            // the form as dirty. This will enable the save button for the form.
            onSelectionChange={(newTableData) => setFieldValue('routines', newTableData)}
            selection={true}
            title="Select Operational Modes"
          />
        </CdhAccent>
      </div>
    </EntityDialog>
  );
};

export default ControlStatesDialog;
