import AddIcon from '@material-ui/icons/Add';
import {
  setActiveMissionVersionId,
  setActiveWorkspaceId,
  setLatestJobId,
} from 'components/RootView/missionExplorerSlice';
import BookmarkBar from 'components/general/BookmarkBar';
import Nav from 'components/general/Nav';
import StyledButton from 'components/general/StyledButton';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import { IWorkspace } from 'components/general/types';
import { useEntityDialogControl, useSelectTotalNum } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WorkspaceDialog from './WorkspaceDialog';
import WorkspaceExplorer from './WorkspaceExplorer';

const RootView = () => {
  const numRepos = useSelectTotalNum('Mission');
  const dispatch = useDispatch();

  const workspaceDialogControl = useEntityDialogControl<IWorkspace>();
  const { openDialogForNew } = workspaceDialogControl;

  // Unset active mission version ID whenever user navigates to workspaces. This unsets the nav bar.
  // Unset latest job ID so ActiveBranch provider knows to fetch a new one when switching between repos.
  useEffect(() => {
    dispatch(setActiveWorkspaceId(undefined));
    dispatch(setActiveMissionVersionId(undefined));
    dispatch(setLatestJobId(undefined));
  }, [dispatch]);

  return (
    <ViewContainer>
      <Nav>
        <p>Create {numRepos > 0 && 'or select'} a workspace in the Workspace Explorer</p>
        <StyledButton type="button" onClick={openDialogForNew} fullWidth midMargin>
          <AddIcon /> New Workspace
        </StyledButton>
      </Nav>
      <ViewPort>
        <BookmarkBar />
        <div className="joyride-workspaces">
          <WorkspaceExplorer />
        </div>
      </ViewPort>
      <WorkspaceDialog control={workspaceDialogControl} />
    </ViewContainer>
  );
};

export default RootView;
