import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';
import { makeGuidance } from 'hooks';
import { SensorVables } from 'utils/vable';
import { IForm } from './SensorDialog';

const sensorsHeading = 'Create and Edit Sensor';

const sensorsMainParagraph =
  'Sensors are used by Algorithms to quantify position, velocity, and attitude. Different Algorithms require different types of sensors. The available types are: Optical Attitude (e.g. Star Tracker), Position (e.g. GPS), Direction (e.g. Earth Sensor), Angular Velocity (e.g. Gyroscope), Target (e.g. Range Sensor), and Vector (e.g. Magnetic Field Sensor).';
//TODO: Add Drawings

const conditionText = {
  subHeading: 'Conditions',
  chunk:
    'Optionally select the conditions under which this sensor is functional, such as FOV keep-ins/outs. If no conditions are selected, the sensor will always operate as normal.',
};

const angularVelocityChunk = {
  subHeading: 'Angular Velocity',
  chunk:
    'Angular velocity sensors measure the angular rate of the spacecraft in the body frame and are used for attitude determination.',
  extra: '\nInput the standard deviation of the sensor error.',
};

const opticalChunk = {
  subHeading: 'Optical Attitude',
  chunk:
    'Optical attitude sensors are used for attitude determination and require the user to spcify planar and roll accuracy.',
  extra:
    '\nOne Sigma Cross-Axis Error is the sensor’s cross-axis standard deviation for attitude, and the One Sigma Boresight-Axis Error is the radial error (standard deviation) around the center vector of the sensor.',
};

const positionChunk = {
  subHeading: 'Position',
  chunk:
    'Position sensors provide a 3D-vector position estimate for the satellite and are used for orbit determination.',
  extra:
    '\nInput the standard deviation of distance error, assumed to be the same for each dimension.',
};

const directionChunk = {
  subHeading: 'Direction',
  chunk:
    'Direction sensors provide information on the orientation of an external reference with respect to the satellite and are used for attitude determination.',
  extra:
    '\nInput a Reference Vector, the angle error (standard deviation) between the actual direction and measured, and optionally select an FoV to limit sense scope and direction.',
};

const targetAttitudeChunk = {
  subHeading: 'Target Attitude',
  chunk:
    "Target attitude sensors provide a quaternion estimate of a target's orientation with respect to the observer's body frame.",
  extra:
    "\nSelect a Space Target and input the sensor's One Sigma Cross-Axis Error and One Sigma Boresight-Axis Error.",
};

const targetPositionChunk = {
  subHeading: 'Target Position',
  chunk:
    "Target position sensors provide a 3D-vector position estimate of a target's position with respect to the observer's body frame.",
  extra:
    "\nSelect a Space Target and input the sensor's One Sigma Distance Error.",
};

const targetRangeChunk = {
  subHeading: 'Target Range',
  chunk:
    'Target range sensors provide a scalar magnitude estimate of the distance between an observer and a target.',
  extra:
    "\nSelect a Space Target and input the sensor's One Sigma Distance Error.",
};

const targetRangeRateChunk = {
  subHeading: 'Target Range Rate',
  chunk:
    'Target range rate sensors provide a scalar estimate of the change in range between an observer and a target.',
  extra:
    "\nSelect a Space Target and input the sensor's One Sigma Velocity Error.",
};

const vectorChunk = {
  subHeading: 'Vector',
  chunk:
    'Vector sensors provide information on the orientation and magnitude of an external reference vector with respect to the satellite and are used for attitude determination. ',
  extra:
    '\nInput a Reference Vector for the vector sensor to measure, the standard deviation of the error and optionally select an FoV to limit sense scope and direction. Selecting a FoV is equivalent to adding a condition that the selected vector is in the field of view.',
};

export const sensorsGuidance = {
  _default: {
    heading: sensorsHeading,
    body: [
      { chunk: sensorsMainParagraph },
      angularVelocityChunk,
      directionChunk,
      opticalChunk,
      positionChunk,
      targetAttitudeChunk,
      targetPositionChunk,
      targetRangeChunk,
      targetRangeRateChunk,
      vectorChunk,
    ],
  },

  oneSigmaPerAxisError: {
    heading: 'Vector Sensor',
    body: [{ chunk: vectorChunk.chunk + vectorChunk.extra }, conditionText],
    'rad/s': (values: IForm) => {
      if (values?.type) {
        return {
          heading: values.type.label,
          body: [{ chunk: angularVelocityChunk.chunk + angularVelocityChunk.extra }, conditionText],
        };
      }
    }
  },

  oneSigmaAngleError: {
    deg: {
      heading: 'Direction Sensor',
      body: [{ chunk: directionChunk.chunk + directionChunk.extra }, conditionText],
    }
  },

  oneSigmaCrossAxisError: {
    deg: (values: IForm) => {
      switch (values.type) {
        case SensorVables.Type.OpticalAttitudeSensor:
          return {
            heading: 'Optical Attitude Sensor',
            body: [{ chunk: opticalChunk.chunk + opticalChunk.extra }, conditionText],
          };
        case SensorVables.Type.TargetAttitudeSensor:
          return {
            heading: 'Target Attitude Sensor',
            body: [{ chunk: targetAttitudeChunk.chunk + targetAttitudeChunk.extra }, conditionText],
          };
      }
    }
  },

  oneSigmaBoresightAxisError: {
    deg: (values: IForm) => {
      switch (values.type) {
        case SensorVables.Type.OpticalAttitudeSensor:
          return {
              heading: 'Optical Attitude Sensor',
              body: [{ chunk: opticalChunk.chunk + opticalChunk.extra }, conditionText],
          };
        case SensorVables.Type.TargetAttitudeSensor:
          return {
            heading: 'Target Attitude Sensor',
            body: [{ chunk: targetAttitudeChunk.chunk + targetAttitudeChunk.extra }, conditionText],
          };
      }
    }
  },

  target: (values: IForm) => {
    switch (values.type) {
      case SensorVables.Type.TargetAttitudeSensor:
        return {
          heading: 'Target Attitude Sensor',
          body: [{ chunk: targetAttitudeChunk.chunk + targetAttitudeChunk.extra }, conditionText],
        };
      case SensorVables.Type.TargetPositionSensor:
        return {
          heading: 'Target Position Sensor',
          body: [{ chunk: targetPositionChunk.chunk + targetPositionChunk.extra }, conditionText],
        };
      case SensorVables.Type.TargetRangeSensor:
        return {
          heading: 'Target Range Sensor',
          body: [{ chunk: targetRangeChunk.chunk + targetRangeChunk.extra }, conditionText],
        };
      case SensorVables.Type.TargetRangeRateSensor:
        return {
          heading: 'Target Range Rate Sensor',
          body: [{ chunk: targetRangeRateChunk.chunk + targetRangeRateChunk.extra }, conditionText],
        };
    }
  },

  oneSigmaDistanceError: {
    km: (values: IForm) => {
      switch (values.type) {
        case SensorVables.Type.PositionSensor:
          return {
            heading: 'Position Sensor',
            body: [{ chunk: positionChunk.chunk + positionChunk.extra }, conditionText],
          };
        case SensorVables.Type.TargetPositionSensor:
          return {
            heading: 'Target Position Sensor',
            body: [{ chunk: targetPositionChunk.chunk + targetPositionChunk.extra }, conditionText],
          };
        case SensorVables.Type.TargetRangeSensor:
          return {
            heading: 'Target Range Sensor',
            body: [{ chunk: targetRangeChunk.chunk + targetRangeChunk.extra }, conditionText],
          };
      }
    }
  },

  oneSigmaVelocityError: {
    'km/s': {
      heading: 'Target Range Rate Sensor',
      body: [{ chunk: targetRangeRateChunk.chunk + targetRangeRateChunk.extra }, conditionText],
    }
  },

  referenceVector: (values: IForm) => {
    if (values?.type && values?.type?.value === 'DirectionSensor')
      return { alias: 'oneSigmaAngleError.deg' };
    return { alias: 'oneSigmaPerAxisError' };
  },

  fieldOfView: { alias: '_default' },

  ...componentThermalGuidance,
};

export const useGuidance = makeGuidance(sensorsGuidance);

const sensorsSegmentGuidance = {
  heading: 'Sensors',
  body: [
    {
      chunk: sensorsMainParagraph,
    },
  ],
};

export default sensorsSegmentGuidance;
