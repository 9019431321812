// @ts-nocheck
import { IConOpsData } from 'components/general/types';
import { ICondition, IOperationalMode } from 'components/general/types/cdh';
import _ from 'lodash';
import { TCompiledModel } from 'middleware/SatelliteApi/template';
import { makeStream, useDataContext, useStream } from 'providers/DataProvider';
import { useMemo } from 'react';

// TODO: Remove this once DSv2 is no longer supported
const useOldConOpsData = (model: TCompiledModel, agentId: string) => {
  const _conditions = model.Condition.all();
  const {
    series: [, ...conditionSeries],
  } = useStream(agentId, ..._conditions.map(({ id }) => `${id}.compliance`));
  const conditions: ICondition[] = useMemo(
    () =>
      _conditions.map((e, i) => ({
        ...e,
        series: conditionSeries[i],
      })),
    [_conditions, conditionSeries]
  );

  const _operationalModes = model.OperationalMode.all();
  const {
    series: [, ...opModeSeries],
  } = useStream(agentId, ..._operationalModes.map(({ id }) => `${id}.compliance`));
  const operationalModes: IOperationalMode[] = useMemo(
    () =>
      _operationalModes.map((e, i) => ({
        ...e,
        series: opModeSeries[i],
      })),
    [_operationalModes, opModeSeries]
  );

  const {
    series: [t, activeOpModeSeries],
  } = useStream(_operationalModes.length && agentId, `activeOpMode`);
  return useMemo(() => {
    const barData: unknown[] = [];
    const pieData: unknown[] = [];
    const conOpsPieUnits = '';

    // Restructuring condition compliance data for plotting
    // Adding an "Extra" time series to each compliance with a one step truth period beyond complaince
    // change from compliant-to-non-compliant - this will convey that the true status continues through
    // the following time step in plotting
    let conditionCompliances = conditions.map((condition) =>
      condition.series?.map((compliance, ind, arr) => {
        return {
          compliance: compliance ? 1 : 0,
        };
      })
    );

    let conditionCompliancesExtra = conditions.map((condition) =>
      condition.series?.map((compliance, ind, arr) => {
        return {
          compliance:
            (ind !== arr.length - 1 && compliance && !arr[ind + 1]) ||
            (ind !== 0 && !compliance && arr[ind - 1])
              ? 1
              : 0,
        };
      })
    );

    // Spreading into n timeseries of objects with a "compliance" at each timestep
    // For each condition at each timestep, setting compliance undefined if false
    // zipping together for an array of n complainces at each time step
    conditionCompliances = _.zipWith(...conditionCompliances, (...args) => {
      return args.map((arg) => (arg?.compliance ? 1 : undefined));
    });

    conditionCompliancesExtra = _.zipWith(...conditionCompliancesExtra, (...args) => {
      return args.map((arg) => (arg?.compliance ? 1 : undefined));
    });

    // Restructuring and creating "Extra" time series to extend plotting of truth periods
    // for operational mode complaince viz. Same process as above for condition compliance.
    let operationalModeCompliances = operationalModes.map((operationalMode) =>
      operationalMode.series?.map((compliance, ind, arr) => {
        return {
          compliance: compliance ? 1 : 0,
        };
      })
    );
    operationalModeCompliances = _.zipWith(...operationalModeCompliances, (...args) => {
      return args.map((arg) => (arg?.compliance ? 1 : undefined));
    });

    let operationalModeCompliancesExtra = operationalModes.map((operationalMode) =>
      operationalMode.series?.map((compliance, ind, arr) => {
        return {
          compliance:
            (ind !== arr.length - 1 && compliance && !arr[ind + 1]) ||
            (ind !== 0 && !compliance && arr[ind - 1])
              ? 1
              : 0,
        };
      })
    );
    operationalModeCompliancesExtra = _.zipWith(...operationalModeCompliancesExtra, (...args) => {
      return args.map((arg) => (arg?.compliance ? 1 : undefined));
    });

    // Pulling op mode status for each mode at each time step
    // Adding "Extra" time series to be plotted and extend mode status one step beyond
    // the end of each active period
    const operationalModeStatuses = activeOpModeSeries.map((activeOpMode, ind, arr) =>
      operationalModes.map((oM) => (activeOpMode === oM.id ? 1 : undefined))
    );

    const operationalModeStatusesExtra = activeOpModeSeries.map((activeOpMode, ind, arr) =>
      operationalModes.map((oM) =>
        (ind !== arr.length - 1 && activeOpMode === oM.id && activeOpMode !== arr[ind + 1]) ||
        (ind !== 0 && activeOpMode !== arr[ind - 1] && arr[ind - 1] === oM.id)
          ? 1
          : undefined
      )
    );

    return {
      conOpsData: {
        timeSeries: _.zipWith(
          t,
          conditionCompliances,
          conditionCompliancesExtra,
          operationalModeCompliances,
          operationalModeCompliancesExtra,
          operationalModeStatuses,
          operationalModeStatusesExtra,
          activeOpModeSeries,
          (
            _t,
            conditionCompliances,
            conditionCompliancesExtra,
            operationalModeCompliances,
            operationalModeCompliancesExtra,
            operationalModeStatuses,
            operationalModeStatusesExtra,
            activeOpMode
          ) => {
            return {
              times: {
                mjd: _t,
              },
              conditionCompliances,
              conditionCompliancesExtra,
              operationalModeCompliances,
              operationalModeCompliancesExtra,
              operationalModeStatuses,
              operationalModeStatusesExtra,
              activeOpMode,
            };
          }
        ),
        bars: barData,
        pie: pieData,
      },
      conOpsPieUnits,
    };
  }, [conditions, operationalModes, activeOpModeSeries, t]);
};

export const useConOpsData = (model: TCompiledModel, agentId: string) => {
  let mainRoutine;
  try {
    mainRoutine = model.CombinationalLogic.all().find(
      (e) => e.name === 'Root Routine' && e.parentRoutines.length === 0
    );
  } catch (e) {
    mainRoutine = null;
  }
  const _conditions = model.Condition.all();
  const {
    series: [, ...conditionSeries],
  } = useStream(agentId, ..._conditions.map(({ id }) => `${id}.compliance`));
  const conditions: ICondition[] = useMemo(
    () =>
      _conditions.map((e, i) => ({
        ...e,
        series: conditionSeries[i],
      })),
    [_conditions, conditionSeries]
  );

  const { _state } = useDataContext();

  const activeRoutines = useMemo(() => {
    try {
      return makeStream([`activeRoutines`], _state, agentId);
    } catch (e) {
      return null;
    }
  }, [_state, agentId]);

  const conOpsData = useMemo(() => {
    const conOpsData: IConOpsData = {
      times: [],
      conditions: {},
      operationalModes: {},
    };

    if (!activeRoutines || !mainRoutine || mainRoutine.type !== 'CombinationalLogic') {
      return conOpsData;
    }

    const {
      series: [t, { 1: activeRoutineSeries }],
    } = activeRoutines;

    conOpsData.conditions = conditions.reduce((acc, condition) => {
      acc[condition.id] = {
        id: condition.id,
        name: condition.name,
        compliance: condition.series,
      };
      return acc;
    }, {});

    conOpsData.operationalModes = mainRoutine.logicalConfigurations.reduce((acc, config) => {
      const mode = config.routine;
      acc[mode.id] = {
        id: mode.id,
        name: mode.name,
        priority: config.priority,
        conditions: config.conditions.map((condition) => condition.id),
        status: [],
      };
      return acc;
    }, {});

    t.forEach((time, i) => {
      conOpsData.times.push(time);
      Object.keys(conOpsData.operationalModes).forEach((modeId) => {
        const opMode = conOpsData.operationalModes[modeId];
        if (activeRoutineSeries[i] === modeId) {
          opMode.status.push(2);
        } else if (opMode.conditions.length === 0) {
          opMode.status.push(1);
        } else {
          opMode.status.push(
            opMode.conditions.reduce((acc, conditionId) => {
              return acc && conOpsData.conditions[conditionId].compliance[i];
            }, true)
              ? 1
              : 0
          );
        }
      });
    });
    return conOpsData;
  }, [conditions, mainRoutine, activeRoutines]);

  // TODO: Remove this once DSv2 is no longer supported
  const oldConOpsData = useOldConOpsData(model, agentId);
  return mainRoutine ? conOpsData : oldConOpsData;
};
