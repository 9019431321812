import Dialog from 'components/general/dialogs/Dialog';
import ReactJson from 'react-json-view';
import theme from 'theme';

const exclude = new Set(['rels']);
const normalize = (data, depth = 0) => {
  if (depth > 2 && data?.id) {
    return data.id;
  } else if (Array.isArray(data)) {
    return data.map((d) => normalize(d, depth));
  } else if (data && typeof data === 'object') {
    const result = {};
    for (const key in data) {
      if (data[key] != null && !exclude.has(key)) result[key] = normalize(data[key], depth + 1);
    }
    return result;
  }
  return data;
};

const Xray = ({ data, title, open, onClose, download = false }) => {
  return (
    open && (
      <Dialog
        noButtons
        prompt={`SedaroML: ${title || ''}`}
        open={open}
        onClose={onClose}
        medium
        downloadXray={download}
        downloadData={data}
      >
        <ReactJson
          style={{ backgroundColor: theme.palette.background.default, overflow: 'auto' }}
          src={normalize(data)}
          collapsed={Array.isArray(data) ? 2 : 1}
          displayDataTypes={false}
          theme="brewer"
          name={false}
          enableClipboard={false}
          collapseStringsAfterLength={50}
        />
      </Dialog>
    )
  );
};

export default Xray;
