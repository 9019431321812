import Grid from '@material-ui/core/Grid';
import {
  setActiveMissionId,
  setActiveMissionVersionId,
} from 'components/RootView/missionExplorerSlice';
import GuidanceCard from 'components/general/GuidanceCard';
import DeleteEntityDialog from 'components/general/dialogs/DeleteEntityDialog';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { useFormikForm, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { mmTypeOptions } from 'utils/repoAndMmType';
import * as Yup from 'yup';
import guidance from './guidance';

const defaultValues = {
  name: '',
  description: '',
  metamodelType: '',
};

const repoSchema = Yup.object().shape({
  name: Yup.string()
    .required('A repository name is required')
    .max(64, 'Repository name must be no more than 64 characters'),
  description: Yup.string().max(
    1000,
    'Repository description must be no more than 1000 characters'
  ),
  metamodelType: Yup.object().required('A model type is required.'),
});

const RepoDialog = (props) => {
  const { control, project } = props;
  const { closeDialog: onClose, dialogConfig: config } = control;
  const { open, action, entity: repo } = config;
  let { id: workspaceId } = useParams();
  if (project) {
    workspaceId = project.workspace;
  }

  const {
    Mission: {
      actions: { createMission, updateMission, deleteMission, cloneMission },
    },
  } = SatelliteApi;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const alreadyExistsMessage = 'A repository with that name already exists';
  const notFoundMessage =
    'That repository does not exist - it was either deleted or moved. Refresh the page to see current repositories.';

  const addRepo = (values) => {
    setLoading(true);
    dispatch(
      createMission({
        ...values,
        workspace: workspaceId,
        project: project?.id,
        successCallback: (response) => {
          dispatch(setActiveMissionVersionId(undefined));
          extOnClose();
          enqueueSnackbar('Repository created successfully', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response) => {
          let errorMessage;
          if (response.error.message.includes('already exists')) {
            errorMessage = alreadyExistsMessage;
          } else {
            errorMessage = response.error.message;
          }
          enqueueSnackbar(errorMessage);
          setLoading(false);
        },
      })
    );
  };

  const editRepo = (values) => {
    delete values.metamodelType;
    setLoading(true);
    dispatch(
      updateMission({
        id: repo.id,
        ...values,
        successCallback: () => {
          dispatch(setActiveMissionId(repo.id));
          dispatch(setActiveMissionVersionId(undefined));

          extOnClose();
          enqueueSnackbar('Repository updated successfully', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response) => {
          let errorMessage;
          if (response.error.message.includes('already exists')) {
            errorMessage = alreadyExistsMessage;
          } else {
            errorMessage = response.error.message;
          }
          enqueueSnackbar(errorMessage);
          setLoading(false);
        },
      })
    );
  };

  const copyRepo = (values) => {
    setLoading(true);
    dispatch(
      cloneMission({
        queryParams: { sourceId: repo.id },
        ...values,
        successCallback: (response) => {
          dispatch(setActiveMissionId(response.id));
          dispatch(setActiveMissionVersionId(undefined));
          extOnClose();
          enqueueSnackbar('Repository cloned successfully.', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response) => {
          let errorMessage;
          if (response.error.message.includes('already exists')) {
            errorMessage = alreadyExistsMessage;
          } else if (response.error.code === 'RESOURCE_NOT_FOUND') {
            errorMessage = notFoundMessage;
          } else {
            errorMessage = response.error.message;
          }
          enqueueSnackbar(errorMessage);
          setLoading(false);
        },
      })
    );
  };

  let prompt, submit, subheader;
  switch (action) {
    case 'create':
      prompt = `Create a repository${project ? ' in this project' : ''}`;
      submit = addRepo;
      subheader = 'Create a repository by filling out the fields below.';
      break;
    case 'clone':
      prompt = 'Clone repository';
      submit = copyRepo;
      subheader = 'Enter a new name and optional description below.';
      break;
    default:
      prompt = 'Edit repository';
      submit = editRepo;
      break;
  }

  const { formik } = useFormikForm(defaultValues, submit, repoSchema, repo, {
    allowedEmptyFields: ['description'],
    options: { metamodelType: mmTypeOptions },
  });

  const { handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;
  const extOnClose = () => {
    onClose();
    setTimeout(() => resetForm(), 200);
  };

  // Set the name in the cloned repo dialog if cloning the repo
  useEffect(() => {
    if (action === 'clone') {
      setFieldValue('name', '');
    }
  }, [action, repo, setFieldValue]);

  if (action === 'delete') {
    return (
      <DeleteEntityDialog
        action={deleteMission}
        entity={repo}
        entityTypeText={'Repository'}
        childEntities="branches"
        setup={() => {
          dispatch(setActiveMissionId(undefined));
          dispatch(setActiveMissionVersionId(undefined));
        }}
        onClose={extOnClose}
        open={open}
        skipHandleRunningSimulation
        doubleConfirmDelete={true}
      />
    );
  }

  return (
    <Dialog
      prompt={prompt}
      open={open}
      onSubmit={handleSubmit}
      onClose={extOnClose}
      loading={loading}
      xlarge
    >
      {subheader && <p>{subheader}</p>}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Repository Name"
            type="text"
            placeholder="Repository Name"
            autoFocus
          />
          <LabeledInput
            {...getFieldProps('description')}
            label="Repository Description"
            type="text"
            placeholder="Repository Description"
            multiline
            rows={9}
            optional
          />
          <LabeledSelect
            label="Model Type"
            isDisabled={action === 'edit'}
            options={mmTypeOptions}
            {...getFieldProps('metamodelType')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <GuidanceCard guidance={guidance} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RepoDialog;
