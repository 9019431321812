import { makeStyles } from '@material-ui/core/styles';

const thumbSize = 22;
const topThumbMargin = -thumbSize / 2 + 1.5;
const leftThumbMargin = -thumbSize / 2;
const fullWidth = 185;

const useStyles = makeStyles((theme) => ({
  playbackViewer: {
    width: '100%',
    '& .MuiTooltip-tooltip': {
      backgroundColor: theme.palette.background.main,
      padding: '4px 16px',
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.background.main,
    },
  },
  cesiumViewer: {
    height: '100%',
  },
  menuButton: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    left: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  viewerButton: {
    paddingRight: '5px',
    '& .MuiButton-label > div': {
      '& div': {
        fontSize: '.75rem',
      },
    },
    width: '32px',
  },
  followSpacecraftButton: {
    [theme.breakpoints.up('xl')]: {
      padding: '0 5px',
    },
  },
  playbackViewerContent: {
    height: '100%',
  },
  cadScaleSlider: {
    width: fullWidth,
    '& .MuiSlider-thumb': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      marginTop: topThumbMargin,
      marginLeft: leftThumbMargin,
      height: thumbSize,
      width: thumbSize,
      border: `0px solid`,
    },
    '&.Mui-disabled': {
      color: theme.palette.action.disabled,
    },
    '&.Mui-disabled .MuiSlider-thumb': {
      backgroundColor: 'rgb(111, 111, 111)',
    },
  },
  selectWrapper: {
    width: fullWidth,
    marginTop: 8,
  },
  filterMenu: {
    '& .MuiTooltip-tooltip': {
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarGutter: 'stable',
      minWidth: 300,
      width: 'max-content',
      maxWidth: 500,
      maxHeight: 600,
      /* width */
      '&::-webkit-scrollbar': {
        width: '10px',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: theme.palette.background.main,
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: theme.palette.background.paperNested,
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.background.contrastText,
      },
    },
  },
  infoBox: {
    position: 'absolute',
    top: 50,
    right: 10,
    width: '33%',
    maxWidth: 450,
    borderRadius: 10,
    backgroundColor: theme.palette.background.main,
  },
  infoBoxHeader: {
    backgroundColor: theme.palette.background.paper,
    padding: 8,
  },
  infoBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    maxHeight: 500,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarGutter: 'stable',
    /* width */
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.main,
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: theme.palette.background.paperNested,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.background.contrastText,
    },
  },
  listHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiIconButton-root': {
      width: 25,
      height: 25,
    },
  },
  listItem: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.background.contrastText,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
