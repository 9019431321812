import StyledButton from 'components/general/StyledButton';
import { TClickEvent } from 'components/general/types';
import { useSnackbar } from 'hooks';
import ReactDropzone, { Accept, DropEvent, FileRejection } from 'react-dropzone';
import useStyles from './styles';

interface IProps {
  onDropAccepted: <T extends File>(files: T[], event: DropEvent) => void;
  accept: Accept;
  disabled?: boolean;
  maxSizeMiB?: number; // Maximum file size (in MiB), omit for unlimited
  multiple?: boolean;
}

const Dropzone = (props: IProps) => {
  const { accept, onDropAccepted, disabled = false, maxSizeMiB, multiple = false } = props;
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const validOptions = Object.values(accept).reduce((acc, curr) => acc.concat(curr), []);

  const maxSizeBytes = maxSizeMiB !== undefined ? maxSizeMiB * 1024 * 1024 : undefined;

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    const errs = [];
    if (maxSizeBytes && rejectedFiles.some((f) => f.file.size > maxSizeBytes)) {
      errs.push(`File must be less than ${maxSizeMiB}MiB.`);
    }
    if (rejectedFiles.some((f) => !validOptions.includes(f.file.type))) {
      errs.push(`File must be a valid type: ${validOptions.join(', ')}.`);
    }
    if (!errs.length) errs.push('File upload failed.');
    enqueueSnackbar(errs.join(' '));
  };

  return (
    <ReactDropzone
      maxSize={maxSizeBytes}
      multiple={multiple}
      accept={accept}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <div
            {...getRootProps({ className: styles.dropzone })}
            style={{ cursor: disabled ? 'no-drop' : 'auto' }}
          >
            <input type="file" {...getInputProps()} />
            <h4 className="sub">
              + Drag and drop file here or{' '}
              <StyledButton
                framed
                disabled={disabled}
                onClick={(e: TClickEvent) => e.preventDefault()}
              >
                Browse
              </StyledButton>
            </h4>
            <span className={styles.allowed}>
              Accepts: {validOptions.map((v) => `"${v}"`).join(', ')}
            </span>
          </div>
        );
      }}
    </ReactDropzone>
  );
};

export default Dropzone;
