import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@material-ui/core';
import { IMissionVersion } from 'components/general/types';
import { useActiveEntities } from 'hooks';
import { useState } from 'react';
import theme from 'theme';
import useStyles from '../styles';
import Tier2ValidationDialog from './Tier2ValidationDialog';

const Tier2ValidationIcon = () => {
  const classes = useStyles();
  const { tier2issues } = useActiveEntities().branch;
  const [open, setOpen] = useState(false);

  const { tooltip, style, icon } = iconProps(tier2issues);

  return (
    <>
      <Tooltip arrow title={tooltip}>
        <IconButton className={classes.branchNavIcon} onClick={() => setOpen(true)} style={style}>
          <FontAwesomeIcon icon={icon} />
        </IconButton>
      </Tooltip>
      <Tier2ValidationDialog open={open} closeDialog={() => setOpen(false)} issues={tier2issues} />
    </>
  );
};

function iconProps(tier2issues: IMissionVersion['tier2issues']) {
  const { errors, warnings } = tier2issues;
  let tooltip, style, icon;

  if (errors.length) {
    tooltip = 'Address issues before use in a simulation';
    style = { color: theme.palette.error.light };
    icon = faCircleExclamation;
  } else if (warnings.length) {
    tooltip = 'Warnings present -- review before use in a simulation';
    style = { color: theme.palette.warning.light };
    icon = faCircleExclamation;
  } else {
    tooltip = 'Model ready to simulate';
    style = {};
    icon = faCircleCheck;
  }
  return { tooltip, style, icon };
}

export default Tier2ValidationIcon;
