import ConOpsLogicPlaybackWidget from 'components/AgentAnalyzeView/AnalyzeBoards/CdhAnalyzeBoards/playback/ConOpsLogicPlaybackWidget';
import ModePlaybackWidget from 'components/AgentAnalyzeView/AnalyzeBoards/general/ModePlaybackWidget';
import {
  ElectricalPowerFlow,
  EPSStateWidget,
} from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/playback';
import DataPlotting from 'components/general/DataPlotting';
import DataPlottingWidgetGroup from 'components/general/DataPlotting/DataPlottingWidgetGroup';
import PlaybackWGroups from 'components/general/PlaybackWGroups';
import { IWidgetSpec } from 'components/general/types';
import { useActiveEntities } from 'hooks';
import { ContextNavContext, MomentProvider, PlaybackStatusProvider, RangeContext } from 'providers';
import AnalyticsProvider from 'providers/AnalyticsProvider';
import { Fragment, useContext, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import AgentAnalyzeMenu from '../menu';
import GNCStateWidget from './GncAnalyzeBoards/playback/GNCStateWidget';
import ThermalFlowWidget from './ThermalAnalyzeBoards/playback/ThermalFlowWidget';
import ThermalStateWidget from './ThermalAnalyzeBoards/playback/ThermalStateWidget';

const AnalyzeBoards = () => {
  // Consider changing this to use moduleTypes
  const activeEntities = useActiveEntities();
  const { menu, playbackItems } = useMemo(() => AgentAnalyzeMenu(activeEntities), [activeEntities]);
  const activeKey = useContext(ContextNavContext)?.state?.activeKey;
  const { echartsData } = useContext(RangeContext);
  const masonryConfig = { mdLg: [55, 45] };

  const playbackWidgets = (item: string) => {
    let result = null;
    if (item === 'power-flow') result = <ElectricalPowerFlow key={'power-flow'} />;
    else if (item === 'thermal-flow') result = <ThermalFlowWidget key={'thermal-flow'} />;
    else if (item === 'eps-state') result = <EPSStateWidget key={'eps-state'} />;
    else if (item === 'mode') result = <ModePlaybackWidget key={'modes'} />;
    else if (item === 'gnc-state') result = <GNCStateWidget key={'gnc-state'} />;
    else if (item === 'thermal-state') result = <ThermalStateWidget key={'thermal-state'} />;
    else if (item === 'conops') result = <ConOpsLogicPlaybackWidget key={'con-ops-logic'} />;

    // When a playback widget errors, last resort is a silent error
    return <ErrorBoundary FallbackComponent={Fragment}>{result}</ErrorBoundary>;
  };

  return (
    <>
      <AnalyticsProvider>
        <MomentProvider>
          <PlaybackStatusProvider>
            <PlaybackWGroups index={Object.keys(playbackItems)} masonryConfig={masonryConfig}>
              {playbackItems[activeKey] &&
                playbackItems[activeKey].playback.map((item: string) => playbackWidgets(item))}
              {playbackItems[activeKey]?.chartspec &&
                playbackItems[activeKey].chartspec.map((item: IWidgetSpec) => (
                  <DataPlottingWidgetGroup
                    wGroupIndex={activeKey}
                    data={echartsData}
                    chartSpec={[item]}
                    key={activeKey + item.id}
                  />
                ))}
            </PlaybackWGroups>
          </PlaybackStatusProvider>
        </MomentProvider>
      </AnalyticsProvider>
      <DataPlotting menu={menu} echartsData={echartsData} />
    </>
  );
};

export default AnalyzeBoards;
