import { ISelectOption } from 'components/general/types';
import { FieldOfViewVables } from 'utils/vable';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  boresightBodyFrameVector: Yup.object().required('Boresight body frame vector is required.'),
  type: Yup.object().required('Field of view type is required.'),
  halfAngle: Yup.object().when('type', {
    is: (type: ISelectOption) => type?.value === FieldOfViewVables.Type.CircularFieldOfView.value,
    then: Yup.object({
      deg: Yup.number().required('Conical half angle is required.'),
    }),
  }),
  heightHalfAngle: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value === FieldOfViewVables.Type.RectangularFieldOfView.value,
    then: Yup.object({
      deg: Yup.number().required('Height half angle is required.'),
    }),
  }),
  widthHalfAngle: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value === FieldOfViewVables.Type.RectangularFieldOfView.value,
    then: Yup.object({
      deg: Yup.number().required('Width half angle is required.'),
    }),
  }),
  heightBodyFrameVector: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value === FieldOfViewVables.Type.RectangularFieldOfView.value,
    then: Yup.object().required('Height body frame vector is required.'),
  }),
});

export default validation;
