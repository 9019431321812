import { Children, useCallback, useEffect, useMemo, useState } from 'react';
import theme from 'theme';
import useStyles from './styles';

const defaultConfig = {
  // above viewport of 1280, masonry will display 2 columns each with 50% width, etc
  lg: [50, 50],
  xl: [33.33, 33.33, 33.33],
};
const muiBreakpoints = theme.breakpoints.values;

const Masonry = (props) => {
  let { masonryConfig, children, maxWidth } = props;
  if (!masonryConfig) masonryConfig = defaultConfig;

  const classes = useStyles({ maxWidth });

  const [currentColumnLayout, setCurrentColumnLayout] = useState([]);
  const childArray = useMemo(() => Children.toArray(children), [children]);

  const recalculate = useCallback(() => {
    let maxBp = 0;
    let matchedColumnLayout = [100];

    for (const [breakpoint, columnLayout] of Object.entries(masonryConfig)) {
      const parsedBreakpoint = muiBreakpoints[breakpoint] ?? parseInt(breakpoint);
      if (
        parsedBreakpoint <= window.innerWidth &&
        childArray.length >= columnLayout.length &&
        parsedBreakpoint >= maxBp
      ) {
        maxBp = parsedBreakpoint;
        matchedColumnLayout = columnLayout;
      }
    }

    setCurrentColumnLayout(matchedColumnLayout);
  }, [childArray, masonryConfig]);

  useEffect(() => {
    recalculate();
    let timeoutId = null;
    // Limits the amount of times resize event will fire for better performance
    const recalculateBreakpointDebounce = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(recalculate, 100);
    };
    window.addEventListener('resize', recalculateBreakpointDebounce);
    return () => window.removeEventListener('resize', recalculateBreakpointDebounce);
  }, [children]); // eslint-disable-line

  const itemsInColumns = useMemo(() => {
    const columnCount = currentColumnLayout.length;

    const itemsInColumns = new Array(columnCount);

    for (let i = 0; i < childArray.length; i++) {
      const columnIndex = i % columnCount;

      if (!itemsInColumns[columnIndex]) {
        itemsInColumns[columnIndex] = [];
      }

      itemsInColumns[columnIndex].push(childArray[i]);
    }

    return itemsInColumns;
  }, [currentColumnLayout, childArray]);

  return (
    <div className={classes.masonryGrid}>
      {itemsInColumns.map((items, i) => (
        <div
          className={classes.masonryColumn}
          style={{ width: `${currentColumnLayout[i]}%` }}
          key={i}
        >
          {items}
        </div>
      ))}
    </div>
  );
};

export default Masonry;
