import { makeStyles } from '@material-ui/core/styles';
import { padding } from '../styles';

export const subPadding = 25;

const useStyles = makeStyles((theme) => ({
  listItemText: {
    ...theme.typography.h3,
    color: theme.palette.background.contrastText,
  },

  subList: {
    paddingTop: 0,
    marginBottom: 5,
  },

  subListItemText: {
    ...theme.typography.h5,
  },

  expandIcon: {
    fill: theme.palette.background.contrastText,
  },
  editSpacecraftText: {
    paddingLeft: padding,
  },
}));

export default useStyles;
