import LoadingView from 'components/LoadingView';
import ViewPortInlay from 'components/general/ViewPortInlay';
import WGroup from 'components/general/WGroup';
import { useActiveEntities, useLatestJob } from 'hooks';
import _ from 'lodash';
import { useDataContext } from 'providers';
import { ModuleVables } from 'utils/vable';
import { wGroupIndicesScenario } from './menu';

const { FAILED, PENDING, QUEUED } = ModuleVables.SimulationStatuses;

const AnalyzeInlay = () => {
  const { branch } = useActiveEntities();
  const [, targetJob] = useLatestJob();
  const { fetching, fetchError } = useDataContext();

  const inlayText =
    _.isEmpty(branch) || (targetJob?.analyzeState?.fetchWhenTrue && !fetchError) || fetching
      ? 'Loading...'
      : _.isEmpty(targetJob) ||
        targetJob.status === FAILED.value ||
        targetJob.status === PENDING.value ||
        targetJob.status === QUEUED.value ||
        fetchError
      ? 'Analytics will populate here once a simulation completes'
      : 'Fetch a window of data to view analytics';

  if (!branch.model?.ready || !targetJob) return <LoadingView message="Sedaro is loading..." />;

  return (
    <WGroup index={wGroupIndicesScenario.ANALYZE}>
      <ViewPortInlay text={inlayText} />
    </WGroup>
  );
};

export default AnalyzeInlay;
