import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  backgroundColor: string;
  color: string;
}

const useStyles = makeStyles((theme) => ({
  alert: ({ backgroundColor, color }: IProps) => ({
    color,
    backgroundColor,
    display: 'flex',
    padding: 4,
    borderRadius: 4,
    marginBottom: 5,
    marginTop: 5,
    '& svg': {
      height: 20,
      width: 20,
    },
    '& > p': {
      color,
      fontSize: 12,
      margin: 0,
      marginLeft: 7,
    },
  }),
  alertTooltip: {
    fontSize: 15,
    fontWeight: 200,
    lineHeight: '20px',
  },
}));

export default useStyles;
