import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CdhAccent, MdAccent } from 'components/general/Accent/variants';
import GuidanceCard from 'components/general/GuidanceCard';
import DeleteEntityDialog from 'components/general/dialogs/DeleteEntityDialog';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IErrorResponse, IGenericObject } from 'components/general/types';
import { ICondition, IRoutine } from 'components/general/types/cdh';
import { IPointingMode } from 'components/general/types/gnc';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useFormikForm, useSelectBlocks, useSnackbar } from 'hooks';
import _ from 'lodash';
import { SatelliteApi, multiBlockCrud } from 'middleware/SatelliteApi/api';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { translateIn, translateOut } from 'utils/forms';
import useGuidance from './guidance';
import operationalModeSchema from './validation';

interface IProps {
  pointingModes: IPointingMode[];
  conditions: ICondition[];
  config: {
    open: boolean;
    action: 'create' | 'edit' | 'clone' | 'delete';
    nextHighestPriority: number;
    routine: IRoutine;
  };
  onClose: () => void;
}

export const conditionsTableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

const quantityKinds = [
  'minOccurrenceDuration',
  'minTimeBetweenOccurrences',
  'maxOccurrenceDuration',
];

const defaultValues = {
  name: '',
  pointingMode: '',
  limitDurationAndFrequency: false,
  requireMinDuration: false,
  maxOccurrenceDuration: { min: '' },
  minTimeBetweenOccurrences: { min: '' },
  minOccurrenceDuration: { min: '' },
};

/**
 * This dialog creates routines, but it pretends to create operational modes in the interim
 * TODO: Make a full transition
 */
const OperationalModesDialog = (props: IProps) => {
  const { pointingModes, conditions, config, onClose } = props;
  const { open, action, routine, nextHighestPriority } = config;
  const tableRef = useRef(null);

  const {
    OperationalMode: {
      actions: { deleteOperationalMode },
    },
  } = SatelliteApi;

  const { branch, logicalConfigurations, routines } = useActiveEntities();

  // Root routine, the parent of all current (op-mode-like) routines
  const rootRoutine = useMemo(() => {
    return routines.find((r) => r.type === 'CombinationalLogic');
  }, [routines]);

  // Configuration for the routine being edited, which contains things like priority, min/max occurrence duration, etc.
  const logicalConfiguration = useMemo(() => {
    return logicalConfigurations.find((lc) => lc.routine.id === routine?.id);
  }, [logicalConfigurations, routine?.id]);

  // The related pointing mode.
  // There is a pointingMode --> routines relation, but not a routine --> pointingMode relation
  const pointingMode = useMemo(() => {
    return pointingModes.find((pM) => pM.routines.find((r) => r.id === routine?.id));
  }, [pointingModes, routine?.id]);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    parsedBlocks: parsedConditions,
    setParsedBlocks: setParsedConditions,
    initBlocks: initConditions,
  } = useSelectBlocks(conditions, logicalConfiguration?.conditions);

  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const options = useMemo(() => {
    return {
      pointingMode: pointingModes
        ?.filter((pM) => pM != null)
        .map((pM) => {
          return { value: pM.id, label: pM.name };
        }),
    };
  }, [pointingModes]);

  const customTranslateIn = useCallback(
    (values, defaultValues, options) => {
      // While we're pretending that routines are still opmodes, copy logicalConfiguration fields over for the form
      values.minOccurrenceDuration = logicalConfiguration?.minOccurrenceDuration;
      values.maxOccurrenceDuration = logicalConfiguration?.maxOccurrenceDuration;
      values.minTimeBetweenOccurrences = logicalConfiguration?.minTimeBetweenOccurrences;
      values.pointingMode = pointingMode;

      // The operational mode form has booleans to determine whether the optional fields are rendered
      // But the backend only returns the values for those fields so we must set the boolean to true if those fields are not zero
      if (
        typeof values.maxOccurrenceDuration?.min === 'number' ||
        typeof values.minTimeBetweenOccurrences?.min === 'number'
      ) {
        values.limitDurationAndFrequency = true;
      } else {
        values.limitDurationAndFrequency = false;
      }
      if (typeof values.minOccurrenceDuration?.min === 'number') {
        values.requireMinDuration = true;
      } else {
        values.requireMinDuration = false;
      }
      return translateIn(values, defaultValues, options);
    },
    [logicalConfiguration, pointingMode]
  );

  const customTranslateOut = useCallback((values, allowedEmptyFields, options) => {
    // Delete checkboxes that the backend is not expecting as they are front end only fields
    delete values.limitDurationAndFrequency;
    delete values.requireMinDuration;
    // Nullify empty quantity kinds
    for (const key of quantityKinds) {
      if (
        typeof values[key] === 'object' &&
        !Array.isArray(values[key]) &&
        (_.isEmpty(values[key]) || !values[key].min || values[key].min === '')
      ) {
        values[key] = null;
      }
    }
    return translateOut(values, allowedEmptyFields, options);
  }, []);

  const addOperationalMode = useCallback(
    (values) => {
      values.conditions = parsedConditions.filter((c) => c.tableData.checked).map((c) => c.id);
      const chosenPointingMode = pointingModes.find((pm) => pm.id === values.pointingMode);
      setLoading(true);

      const blocks: IGenericObject[] = [
        {
          // Routine
          id: '$routine',
          type: 'Routine',
          name: values.name,
          parentRoutines: [rootRoutine?.id],
        },
        {
          // Logical configuration
          id: '$logicalConfiguration',
          type: 'LogicalConfiguration',
          routine: '$routine',
          combinationalLogicRoutines: [rootRoutine?.id],
          conditions: values.conditions,
          priority: nextHighestPriority,
          minOccurrenceDuration: values.minOccurrenceDuration,
          maxOccurrenceDuration: values.maxOccurrenceDuration,
          minTimeBetweenOccurrences: values.minTimeBetweenOccurrences,
        },
      ];
      if (chosenPointingMode) {
        blocks.push({
          // Pointing mode
          id: values.pointingMode,
          type: chosenPointingMode?.type,
          routines: [...(chosenPointingMode?.routines.map((r) => r.id) || []), '$routine'],
        });
      }
      dispatch(
        multiBlockCrud({
          branchId: branch.id,
          blocks,
          successCallback: (response: IGenericObject) => {
            onClose();
            enqueueSnackbar('Operational mode created successfully', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response: IErrorResponse) => {
            enqueueSnackbar(response.error.message);
            setLoading(false);
          },
        })
      );
    },
    [
      rootRoutine?.id,
      pointingModes,
      nextHighestPriority,
      onClose,
      enqueueSnackbar,
      parsedConditions,
      dispatch,
      branch,
    ]
  );

  const editOperationalMode = useCallback(
    (values) => {
      values.conditions = parsedConditions.filter((c) => c.tableData.checked).map((c) => c.id);
      const chosenPointingMode = pointingModes.find((pm) => pm.id === values.pointingMode);
      setLoading(true);

      const blocks: IGenericObject[] = [
        {
          // Routine
          id: routine.id,
          type: 'Routine',
          name: values.name,
          parentRoutines: [rootRoutine?.id],
        },
        {
          // Logical configuration
          id: logicalConfiguration?.id,
          type: 'LogicalConfiguration',
          routine: routine.id,
          combinationalLogicRoutines: [rootRoutine?.id],
          conditions: values.conditions,
          priority: logicalConfiguration?.priority, // no change
          minOccurrenceDuration: values.minOccurrenceDuration,
          maxOccurrenceDuration: values.maxOccurrenceDuration,
          minTimeBetweenOccurrences: values.minTimeBetweenOccurrences,
        },
      ];
      if (chosenPointingMode) {
        blocks.push({
          // Pointing mode
          id: values.pointingMode,
          type: chosenPointingMode?.type,
          routines: Array.from(
            new Set([...(chosenPointingMode?.routines.map((r) => r.id) || []), routine.id])
          ),
        });
      }

      if (pointingMode?.id && values.pointingMode !== pointingMode?.id) {
        blocks.push({
          // Previous pointing mode
          id: pointingMode?.id,
          type: pointingMode?.type,
          routines: pointingMode?.routines.map((r) => r.id).filter((id) => id !== routine.id) || [],
        });
      }

      dispatch(
        multiBlockCrud({
          branchId: branch.id,
          blocks,
          successCallback: (response: IGenericObject) => {
            onClose();
            enqueueSnackbar('Operational mode updated successfully', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response: IErrorResponse) => {
            let errorMessage = response.error.message;
            if (response.error.message.includes('greater than or equal to')) {
              errorMessage =
                'Max occurrence duration must be greater than or equal to min occurrence duration.';
            }
            enqueueSnackbar(errorMessage);
            setLoading(false);
          },
        })
      );
    },
    [
      rootRoutine,
      pointingModes,
      onClose,
      enqueueSnackbar,
      parsedConditions,
      dispatch,
      branch,
      logicalConfiguration,
      routine?.id,
      pointingMode,
    ]
  );

  const { formik, guidance } = useFormikForm(
    defaultValues,
    action === 'create' ? addOperationalMode : editOperationalMode,
    operationalModeSchema,
    routine,
    {
      useGuidance,
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
      allowedEmptyFields: quantityKinds,
    }
  );
  const { handleSubmit, getFieldProps, values, setValues, resetForm } = formik;
  const { limitDurationAndFrequency } = values;

  if (action === 'delete') {
    return (
      <DeleteEntityDialog
        action={deleteOperationalMode}
        entity={routine}
        entityTypeText={'Operational Mode'}
        onClose={onClose}
        open={open}
      />
    );
  }

  return (
    <Dialog
      prompt={
        action === 'create'
          ? 'Create an operational mode'
          : action === 'clone'
          ? 'Clone operational mode'
          : 'Edit operational mode'
      }
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
      submitActionText="Save"
      secondaryActionText="Reset"
      onSecondaryAction={() => {
        initConditions();
        resetForm();
      }}
      large
      xray={{ ...routine, ...formik.values }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.swapRight}>
          <div className={classes.inputs}>
            <div className={classes.inputGroup}>
              <LabeledInput
                {...getFieldProps('name')}
                label="Operational Mode Name"
                type="text"
                placeholder="Name"
                autoFocus
              />
            </div>
            <div className={classes.inputGroup}>
              <MdAccent header="Pointing Mode (optional)">
                <LabeledSelect
                  options={options.pointingMode}
                  {...getFieldProps('pointingMode')}
                  isClearable
                />
              </MdAccent>
            </div>
            <LabeledCheckbox
              {...getFieldProps('limitDurationAndFrequency')}
              label="Limit duration and/or frequency"
              formikOnChange={() => {
                setValues({
                  ...values,
                  maxOccurrenceDuration: defaultValues.maxOccurrenceDuration,
                  minTimeBetweenOccurrences: defaultValues.minTimeBetweenOccurrences,
                });
              }}
            />
            {limitDurationAndFrequency && (
              <div className={classes.inputGroup}>
                <LabeledInput
                  {...getFieldProps('maxOccurrenceDuration.min')}
                  label="Max Occurrence Duration"
                  type="number"
                  endAdornment={<InputAdornment position="end">{'min'}</InputAdornment>}
                  optional
                />
                <LabeledInput
                  {...getFieldProps('minTimeBetweenOccurrences.min')}
                  label="Min Time Between Occurrences"
                  type="number"
                  endAdornment={<InputAdornment position="end">{'min'}</InputAdornment>}
                />
              </div>
            )}
          </div>
          <div className={classes.inputGroup}>
            <CdhAccent header="Conditions">
              <WidgetTable
                tableRef={tableRef}
                className={classes.table}
                columns={conditionsTableColumns}
                data={parsedConditions}
                setData={setParsedConditions}
                emptyMessage={'No conditions found'}
                title="Select Conditions"
                search={true}
                selection={true}
              />
            </CdhAccent>
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.swapLeft}>
          <GuidanceCard guidance={guidance} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default OperationalModesDialog;
