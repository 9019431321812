import { InputAdornment } from '@material-ui/core';
import {
  CdhAccent,
  DataHandlingAccent,
  SpacecraftAccent,
} from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IDataSide, TBlockId } from 'components/general/types';
import { IDataMode, IDataType } from 'components/general/types/dataHandling';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectBlocks } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { translateIn, translateOut } from 'utils/forms';
import * as Yup from 'yup';
import { useGuidance } from './guidance';

interface IForm {
  name: string;
  alwaysActive: boolean;
  inputField: number[] | '';
  outputField: number[] | '';
  dataTypeField: IDataType[] | '';
}

interface IProps {
  control: TEntityDialogControl<IDataMode>;
}

interface INamedBlock {
  name: string;
  id: TBlockId;
}

const defaultValues: IForm = {
  name: '',
  alwaysActive: false,
  inputField: '',
  outputField: '',
  dataTypeField: '',
};

const tableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

const componentColumns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Subsystem',
    field: 'subsystem.name',
  },
];

const extraComponentFields = ['subsystem'];

const DataModeDialog = ({ control }: IProps) => {
  const { dataTypes, components, leafRoutines: routines } = useActiveEntities();
  const {
    dialogConfig: { entity: dataMode },
  } = control;

  // Any datatype in input or output should be checked
  const ioTypes = useMemo(() => {
    const ioTypes_ = dataMode?.input?.map((i) => {
      return { name: i.name, id: i.id };
    });
    const ioIds = ioTypes_?.map((t) => t.id);
    dataMode?.output?.forEach((o) => {
      if (!ioIds?.includes(o.id)) {
        ioTypes_?.push({ name: o.name, id: o.id });
        ioIds?.push(o.id);
      }
    });
    return ioTypes_;
  }, [dataMode?.input, dataMode?.output]);

  // Set up table data
  const [typeData, setTypeData] = useState<INamedBlock[]>(
    () =>
      ioTypes?.map((t) => {
        return { id: t.id, name: t.name };
      }) || []
  );
  const { parsedBlocks: parsedDataTypes, setParsedBlocks: setDataTypes } = useSelectBlocks(
    dataTypes,
    ioTypes
  );
  const { parsedBlocks: parsedRoutines, setParsedBlocks: setRoutines } = useSelectBlocks(
    routines,
    dataMode?.routines
  );
  const { parsedBlocks: parsedComponents, setParsedBlocks: setComponents } = useSelectBlocks(
    components,
    dataMode?.components,
    extraComponentFields
  );

  const dataTableRef = useRef(null);
  const routineTableRef = useRef(null);
  const componentTableRef = useRef(null);

  // Form hookup
  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      // Prepare table data
      values.routines = parsedRoutines
        .filter((routine) => routine.tableData?.checked)
        .map((routine) => routine.id);
      values.components = parsedComponents
        .filter((component) => component.tableData?.checked)
        .map((component) => component.id);
      // Get form values before translating
      const dataTypes = parsedDataTypes
        .filter((dataType) => dataType.tableData?.checked)
        .map((dataType) => dataType.id);
      const inputs = values.inputField;
      const outputs = values.outputField;
      // Translate
      const result = translateOut(values, allowedEmptyFields, options);
      // Populate ManySide
      result.input = {};
      result.output = {};
      dataTypes.forEach((id, index) => {
        // Populate dataSide if bitrate is not 0
        if (inputs[index]) result.input[id] = { bitRate: inputs[index] };
        if (outputs[index]) result.output[id] = { bitRate: outputs[index] };
      });
      return result;
    },
    [parsedRoutines, parsedComponents, parsedDataTypes]
  );

  const customTranslateIn = useCallback((dataMode, defaultValues, options) => {
    // Translate in dataSide for input and output
    const inputDataTypes = dataMode.input.map((dataType: IDataType & IDataSide) => dataType.id);
    const outputDataTypes = dataMode.output.map((dataType: IDataType & IDataSide) => dataType.id);
    const allDataTypes = [...inputDataTypes, ...outputDataTypes].filter(
      (dataType, index, array) => array.indexOf(dataType) === index
    );
    dataMode.inputField = [];
    dataMode.outputField = [];
    allDataTypes.forEach((dataType) => {
      dataMode.inputField.push(
        inputDataTypes.includes(dataType)
          ? dataMode.input.find((inputData: IDataType & IDataSide) => inputData.id === dataType)
              .dataSide.bitRate
          : 0
      );
      dataMode.outputField.push(
        outputDataTypes.includes(dataType)
          ? dataMode.output.find((outputData: IDataType & IDataSide) => outputData.id === dataType)
              .dataSide.bitRate
          : 0
      );
    });
    return translateIn(dataMode, defaultValues, options);
  }, []);

  const classes = useStyles();
  const entityForm = useEntityForm<IDataMode, IForm>({
    entityTypeText: 'Data Mode',
    entityDialogControl: control,
    defaultValues,
    valuesToRemove: ['inputField', 'outputField', 'dataTypeField'],
    additionalCreateValues: { type: 'DataMode' },
    validationSchema: Yup.object().shape({}),
    formikOptionalParams: {
      useGuidance,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
  });
  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  return (
    <EntityDialog entityForm={entityForm} customDirty={true}>
      <div className={classes.inputs}>
        <LabeledInput
          {...getFieldProps('name')}
          label="Data Mode Name"
          type="text"
          placeholder="Name"
          autoFocus
        />
      </div>
      <SpacecraftAccent header="Components">
        <WidgetTable
          tableRef={componentTableRef}
          className={classes.table}
          columns={componentColumns}
          data={parsedComponents}
          setData={setComponents}
          emptyMessage={'No components found'}
          title="Select Components"
          search={true}
          selection={true}
        />
      </SpacecraftAccent>
      <DataHandlingAccent header="Data Types">
        <WidgetTable
          tableRef={dataTableRef}
          className={classes.table}
          columns={tableColumns}
          data={parsedDataTypes}
          setData={setDataTypes}
          emptyMessage={'No data types found'}
          title="Select Data Types"
          search={true}
          selection={true}
          onSelectionChange={() => {
            setTypeData(
              parsedDataTypes
                .filter((dataType) => dataType.tableData?.checked)
                .map((dataType) => {
                  return { name: dataType.name, id: dataType.id };
                })
            );
          }}
        />
        {typeData.map((data, index) => {
          return (
            // TODO: This isn't a proper use of key, which results in some values sliding around when you select new
            // data types.  I tried fixing this, but I got a different warning, probably since the new array elements
            // are undefined.
            <React.Fragment key={index}>
              <LabeledInput
                label={`"${data.name}" Input Bitrate`}
                {...getFieldProps(`inputField.${index}`)}
                type="number"
                endAdornment={<InputAdornment position="end">bit/s</InputAdornment>}
              />
              <LabeledInput
                label={`"${data.name}" Output Bitrate`}
                {...getFieldProps(`outputField.${index}`)}
                type="number"
                endAdornment={<InputAdornment position="end">bit/s</InputAdornment>}
              />
            </React.Fragment>
          );
        })}
      </DataHandlingAccent>
      <LabeledCheckbox {...getFieldProps('alwaysActive')} label={'Always active'}></LabeledCheckbox>
      {!values.alwaysActive && (
        <CdhAccent header={'Operational Modes'}>
          <WidgetTable
            tableRef={routineTableRef}
            className={classes.table}
            columns={tableColumns}
            setData={setRoutines}
            data={parsedRoutines}
            emptyMessage={'No operational modes found'}
            title="Select Operational Modes"
            search={true}
            selection={true}
          />
        </CdhAccent>
      )}
    </EntityDialog>
  );
};

export default DataModeDialog;
