import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DEMO_WORKSPACE_BOOKMARKS } from 'config';
import { useSelectById } from 'hooks';
import { ReactNode, createContext, useCallback, useMemo, useState } from 'react';
import ReactJoyride, {
  CallBackProps,
  Locale,
  STATUS,
  Step,
  TooltipRenderProps,
} from 'react-joyride';
import { useHistory } from 'react-router-dom';
import Routes, { routePathsCommon } from 'routes';
import theme from 'theme';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.main,
    minWidth: 400,
    maxWidth: 600,
    borderRadius: 10,
    boxShadow: `0px 0px 0px 2px ${theme.palette.primary.dark}88`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  progress: {
    borderRadius: '50%',
    backgroundColor: theme.palette.background.mainNested,
    border: '1px solid ' + theme.palette.secondary.main,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
  },
  closeButton: {
    color: theme.palette.text.primary,
    marginLeft: 10,
    height: 30,
    width: 30,
    fontSize: 20,
  },
  content: {
    '& h3': {
      lineHeight: 1.5,
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    '& .MuiButton-contained': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text.primary,
      margin: 5,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .MuiButton-outlined': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.primary.dark,
      margin: 5,
    },
  },
}));

interface IJoyrideContext {
  running: boolean;
  setRunning: (running: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setTour: (tour: string) => void;
  setCurrentBranch: (branch: string) => void;
}

interface IProps {
  children: ReactNode;
}
interface ILocale extends Locale {
  loading?: string;
}

interface IStep extends Step {
  locale?: ILocale;
  link?: string;
  showSkip?: boolean;
  awaitLoad?: boolean;
}

const defaultState: IJoyrideContext = {
  running: false,
  setRunning: () => {
    // intentionally empty
  },
  loading: false,
  setLoading: () => {
    // intentionally empty
  },
  setTour: () => {
    // intentionally empty
  },
  setCurrentBranch: () => {
    // intentionally empty
  },
};

/**
 * Uses a React Context to provide state and functions for the Joyride component.
 */
export const JoyrideContext = createContext<IJoyrideContext>(defaultState);

const JoyrideProvider = (props: IProps) => {
  const { children } = props;
  const history = useHistory();
  const demoBranch = useSelectById('MissionVersion', DEMO_WORKSPACE_BOOKMARKS[0]);
  const [currentBranch, setCurrentBranch] = useState<string>();
  const tours = useMemo<{ [key: string]: IStep[] }>(() => {
    return {
      welcome: [
        {
          title: 'Welcome to Sedaro!',
          content: (
            <h3>
              Sedaro empowers engineers to unlock their full potential through cloud-scalable
              simulation technology. We push the boundaries of system simulation, enabling
              breakthrough designs and optimized performance beyond what's thought possible today.
            </h3>
          ),
          placement: 'center',
          target: 'body',
          locale: { next: 'Start Tour!' },
          showSkip: true,
          awaitLoad: true,
          link: Routes.ROOT(),
        },
        {
          title: 'Workspaces',
          content: (
            <h3>
              Workspaces are collaborative environments that contain your team's projects,
              repositories, and branches.
            </h3>
          ),
          spotlightPadding: 0,
          target: '.joyride-workspaces',
          placement: 'top',
        },
        {
          title: 'Bookmarks Bar',
          content: (
            <h3>
              Your recently viewed or bookmarked branches. Branches can be{' '}
              <strong>Agent Templates</strong>, where you create and edit templates for your Digital
              Twins, or <strong>Scenarios</strong>, where you design a mission using any number of
              Agents in a simulation.
            </h3>
          ),
          target: '.joyride-bookmarks',
          locale: { next: demoBranch?.id ? 'View Demo Scenario' : 'Next' },
          link: demoBranch?.id ? Routes.SCENARIO(demoBranch.id, routePathsCommon.EDIT) : undefined,
        },
        {
          title: 'Scenario Edit',
          content: (
            <h3>
              Scenarios are where you design and run mission simulations consisting of any number of
              Agents. In a Scenario, you can visualize the simulation results and view the
              time-series data and subsystem analytics of the Agents.
            </h3>
          ),
          target: 'body',
          placement: 'center',
        },
        {
          title: 'Simulation Controls',
          content: (
            <h3>
              Execute the Scenario simulation and monitor the status of simulation jobs. Click on a
              job to view the results of the simulation.
            </h3>
          ),
          target: '.joyride-sim-controls',
          placement: 'right',
        },
        {
          title: 'Edit Scenario',
          content: (
            <h3>
              You may define the start and end time of the simulation, assign Agents to the
              simulation, and define Agent Groups.
            </h3>
          ),
          target: '.joyride-nav',
          placement: 'top-end',
          awaitLoad: true,
          locale: { loading: 'Fetching Data' },
          link: demoBranch?.id
            ? Routes.SCENARIO(demoBranch.id, routePathsCommon.ANALYZE)
            : undefined,
        },
        {
          title: 'Scenario Analyze',
          content: (
            <h3>
              You can view the overall playback of the simulation or view detailed subsystem
              analytics of any of the Agents defined in the Scenario.
            </h3>
          ),
          target: 'body',
          placement: 'center',
        },
        {
          title: 'Support',
          content: (
            <h3>
              Need help? Click here to get in touch with us. We're happy to help with any issues you
              may have. You can also check out the latest features and view our documentation for
              more information.
            </h3>
          ),
          target: '.joyride-support-button',
          placement: 'right',
        },
      ],
      scenario: [
        {
          title: 'Scenario Tour',
          content: <h3>This is a tour of the Scenario page.</h3>,
          target: 'body',
          placement: 'center',
          locale: { next: 'Start Tour!' },
          link: currentBranch && Routes.SCENARIO(currentBranch, routePathsCommon.EDIT),
          showSkip: true,
        },
        {
          title: 'Simulation Controls',
          content: (
            <h3>
              In the Simulation Controls, you can view the progress of running simulations,
              information on all previous jobs, and start new simulations. Click on a job to view
              the results of the simulation.
            </h3>
          ),
          target: '.joyride-sim-controls',
          placement: 'right',
          link: currentBranch && Routes.SCENARIO(currentBranch, routePathsCommon.EDIT, 'agents'),
        },
        {
          title: 'Edit Scenario - Time',
          content: <h3>Define the start and end time of the simulation execution.</h3>,
          target: '.joyride-scenario-time',
        },
        {
          title: 'Edit Scenario - Agents',
          content: (
            <h3>
              Populate the Scenario with your developed Templated Agents and define Peripheral
              Agents.
            </h3>
          ),
          target: '.joyride-scenario-agents',
        },
        {
          title: 'Templated Agent',
          content: (
            <h3>
              Templated Agents are full Digital Twins. Digital Twins are instantiated from a
              specific branch of an Agent Template repository. The initial state used to
              differentiate Agent Templates currently includes Target assignments and an initial
              orbit.
            </h3>
          ),
          target: '.joyride-scenario-agents',
          link:
            currentBranch && Routes.SCENARIO(currentBranch, routePathsCommon.EDIT, 'agent-groups'),
        },
        {
          title: 'Peripheral Agent',
          content: (
            <h3>
              Peripheral Agents are simple targets. They consist of Space Targets, Ground Targets,
              or Celestial Targets.
            </h3>
          ),
          target: '.joyride-scenario-agents',
        },
        {
          title: 'Edit Scenario - Agent Groups',
          content: (
            <h3>
              Organize your Agents into Agent Groups. Agent Groups can be used for target mapping in
              your simulation.
            </h3>
          ),
          target: '.joyride-scenario-agent-groups',
          placement: 'top',
          link: currentBranch && Routes.SCENARIO(currentBranch, routePathsCommon.ANALYZE),
        },
        {
          title: 'Scenario Analysis',
          content: <h3>View the results of your simulation</h3>,
          target: 'body',
          placement: 'center',
          showSkip: true,
        },
        {
          title: 'Analysis Controls',
          content: (
            <h3>
              In the Analysis Controls, you can view information about the selected simulation and
              check its progress. Underneath, you can adjust the Analysis Window to fetch the range
              of time you want to view simulation playback for or choose a different job to view.
            </h3>
          ),
          target: '.joyride-analysis-controls',
          placement: 'right',
        },
        {
          title: 'Scenario Playback',
          content: (
            <h3>
              View a 3D playback of your scenario, complete with agent models, orbit trails, data
              communication lines, and more!
            </h3>
          ),
          target: 'body',
          placement: 'center',
        },
        {
          title: 'Playback Settings',
          content: (
            <h3>
              Customize your playback visualization settings and filter fields-of-view and body
              frame vectors.
            </h3>
          ),
          target: '.joyride-playback-menu',
          placement: 'right',
          link: currentBranch && Routes.SCENARIO(currentBranch, routePathsCommon.ANALYZE, 'agents'),
          disableScrolling: true,
        },
        {
          title: 'Agent Analyze',
          content: (
            <h3>Click on a specific agent for detailed results with intuitive visualizations.</h3>
          ),
          target: 'body',
          placement: 'center',
        },
      ],
      agentAnalyze: [
        {
          title: 'Agent Analyze',
          content: (
            <h3>
              The Agent Analyze view allows you to analyze your simulation, centered on a specific
              agent by combining 3D playback, time-series plots, and simulation statistics.
            </h3>
          ),
          target: 'body',
          placement: 'center',
          locale: { next: 'Start Tour!' },
          showSkip: true,
        },
        {
          title: 'Simulation Controls',
          content: (
            <h3>
              In the Simulation Controls, you can view information about the most recent simulation,
              check the progress of a running simulation, and start or abort new simulations.
              Underneath, you can adjust the Analysis Window to tune the range of time you want to
              view data for.
            </h3>
          ),
          disableScrolling: true,
          target: '.joyride-sim-controls',
          placement: 'right',
        },
        {
          title: 'Navigation Menu',
          content: (
            <h3>
              Analytics are neatly organized by subsystem so you can quickly find the data you need.
            </h3>
          ),
          target: '.joyride-nav',
          placement: 'right',
        },
        {
          title: 'Customize Menu',
          content: <h3>Customize the navigation menu and define your own plots and analytics.</h3>,
          target: '.joyride-nav-layout',
          placement: 'right',
        },
      ],
      agentTemplate: [
        {
          title: 'Agent Template',
          content: <h3>This is a tour of the Agent Template page</h3>,
          target: 'body',
          placement: 'center',
          showSkip: true,
        },
        {
          title: 'Navigation Menu',
          content: (
            <h3>
              Use the navigation menu to switch between the different subsystems of your agent.
            </h3>
          ),
          placement: 'right',
          target: '.joyride-nav',
        },
        {
          title: 'Toggle Subsystems',
          content: <h3>Some optional subsytems can be toggled on and off.</h3>,
          placement: 'right',
          target: '.joyride-nav-toggle',
        },
        {
          title: 'Spacecraft Dialog',
          content: (
            <h3>
              Click here to open the Spacecraft Dialog and edit details of your model including CAD
              models, reference vectors, external surfaces, and sensor fields-of-view.
            </h3>
          ),
          target: '.joyride-spacecraft-dialog-button',
        },
      ],
    };
  }, [currentBranch, demoBranch]);

  const [running, setRunning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [stepsKey, setSteps] = useState('welcome');
  const steps = useMemo(() => tours[stepsKey], [tours, stepsKey]);

  const setTour = useCallback(
    (tour: string) => {
      // Don't reset index in the middle of welcome tour
      if (!(tour === 'welcome' && stepsKey === 'welcome')) setStepIndex(0);
      setSteps(tour);
    },
    [stepsKey, setStepIndex, setSteps]
  );

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status, type } = data;
      const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.ERROR];
      // If the target is not found, skip the step
      // Use a delay to allow for page loads
      if (type.includes('error')) {
        if (data.action === 'prev') {
          setTimeout(() => {
            steps[data.index - 1].link && history.goBack();
            setStepIndex(data.index - 1);
          }, 250);
        } else {
          setTimeout(() => {
            steps[data.index].link && history.push(steps[data.index].link || '');
            setStepIndex(data.index + 1);
          }, 250);
        }
      } else if (finishedStatuses.includes(status)) {
        if (stepIndex === steps.length) setStepIndex(0);
        setRunning(false);
      }
    },
    [stepIndex, steps, history]
  );

  const contextValue = useMemo(() => {
    return {
      running,
      setRunning,
      loading,
      setLoading,
      setTour,
      setCurrentBranch,
    };
  }, [running, setRunning, loading, setLoading, setTour]);

  const CustomTooltip = (props: TooltipRenderProps) => {
    const {
      continuous,
      index,
      size,
      step,
      backProps,
      closeProps,
      skipProps,
      primaryProps,
      tooltipProps,
      isLastStep,
    } = props;
    const classes = useStyles();
    return (
      <Card className={classes.tooltip} {...tooltipProps}>
        <CardHeader
          className={classes.header}
          avatar={
            step.showProgress && (
              <div className={classes.progress}>
                <h6>
                  <sup>{index + 1}</sup>&frasl;<sub>{size}</sub>
                </h6>
              </div>
            )
          }
          title={<h1>{step.title}</h1>}
          action={
            <IconButton
              className={classes.closeButton}
              {...closeProps}
              onClick={(e) => {
                setRunning(false);
                closeProps.onClick(e);
              }}
            >
              <div>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </IconButton>
          }
        />
        <CardContent className={classes.content}>{step.content}</CardContent>
        <CardActions className={classes.footer} disableSpacing>
          {continuous && (
            <Button
              variant="contained"
              disabled={steps[index].awaitLoad && loading}
              {...primaryProps}
              onClick={(e) => {
                if (steps[index].link) {
                  history.push(steps[index].link || '');
                }
                setStepIndex(index + 1);
                primaryProps.onClick(e);
              }}
            >
              {steps[index].awaitLoad && loading ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <h3 style={{ paddingRight: 10 }}>{steps[index].locale?.loading || 'Loading'}</h3>
                  <CircularProgress size={20} color="inherit" />
                </div>
              ) : (
                <h3>{isLastStep ? step.locale?.last || 'Finish' : step.locale?.next || 'Next'}</h3>
              )}
            </Button>
          )}
          {steps[index].showSkip ? (
            <Button variant="outlined" {...skipProps}>
              <h3>{step.locale?.skip || 'Skip'}</h3>
            </Button>
          ) : (
            <Button
              variant="outlined"
              {...backProps}
              onClick={(e) => {
                if (steps[index - 1].link) {
                  history.goBack();
                }
                setStepIndex(index - 1);
              }}
            >
              <h3>{step.locale?.back || 'Back'}</h3>
            </Button>
          )}
        </CardActions>
      </Card>
    );
  };

  return (
    <JoyrideContext.Provider value={contextValue}>
      {
        <>
          <ReactJoyride
            run={running}
            steps={steps}
            callback={handleJoyrideCallback}
            stepIndex={stepIndex}
            continuous
            showProgress
            spotlightPadding={5}
            disableOverlayClose
            disableCloseOnEsc
            disableScrollParentFix
            beaconComponent={() => null}
            locale={{
              last: 'Get Started!',
            }}
            tooltipComponent={CustomTooltip}
            styles={{
              options: {
                arrowColor: theme.palette.background.main,
                zIndex: 10000,
              },
              spotlight: {
                border: '1px solid ' + theme.palette.background.contrastText,
              },
            }}
          />
          {children}
        </>
      }
    </JoyrideContext.Provider>
  );
};

export default JoyrideProvider;
