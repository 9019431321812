import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';
import { makeGuidance } from 'hooks';
import { ActuatorVables } from 'utils/vable';

const actuatorsHeading = 'Create and Edit Actuator';

const actuatorsMainParagraph =
  'Actuators orient the spacecraft passively or actively by the attitude control algorithm. Each algorithm is assigned specific actuators by the user.';

const mtGuidance = {
  heading: 'Magnetorquer',
  body: [
    {
      chunk:
        'Specify the device’s maximum rated magnetic moment and the power draw at the maximum. The control algorithm uses Pulse-Width Modulation (PWM) to drive a variable moment according to the assigned modulation type.',
    },
    {
      chunk:
        'Select a modulation method for the magnetorquer. Analog is a continuously-varying current and digital is a Pulse-Width Modulated (PWM) current.',
    },
  ],
};

const rwGuidance = {
  heading: 'Reaction Wheel',
  body: [
    {
      chunk:
        'Specify the reaction wheel’s inertia, rated angular momentum (maximum angular momentum storage), rated torque (maximum instantaneous torque), and efficiency (a measure of how much power is lost to heat).',
    },
  ],
};

const barMagnetGuidance = {
  heading: 'Permanent Dipole Magnet',
  body: [
    {
      chunk:
        "A permanent magnet induces a torque the causes the dipole moment of the magnet to align with Earth's magnetic field.",
    },
    {
      subHeading: 'Magnetic Moment',
      chunk:
        'Specify the magnitude and orientation of the magnetic moment of the magnet. The torque on the spacecraft is the cross product of the dipole moment (pointing south to north) and the magnetic field vector.',
    },
  ],
};

const hysteresisRodGuidance = {
  heading: 'Magnetic Hyteresis Rod',
  body: [
    {
      chunk:
        "A magnetic hysteresis rod is a ferromagnetic rod with a dynamic magnetic moment. Magnetic hysteresis causes dampening of satellite rotation in Earth's magnetic field, so these rods are often used as a form of passive attitude control. The details of the hysteresis model we use can be found here: https://ntrs.nasa.gov/citations/19950021380.",
    },
    {
      subHeading: 'Extensive Physical Properties',
      chunk:
        'Specify the physical properties of the rod, including the length, diameter, and orientation. These are used to determine the dipole moment of the rod at a given magnetization.',
    },
    {
      subHeading: 'Hysteresis Curve Properties',
      chunk:
        'These properties are used to model the boundary of the hysteresis curve of the ferromagnetic rod: \n - Magnetic Coercivity - Otherwise known as the "coercive force", this is the magnetic field strength required to reduce the magnetization of the rod to zero from magnetic saturation. \n Magnetic Remanence - The remaining magnetic flux density in T when the external field is reduced to 0 after the rod is fully magnetized. \n Saturation Flux - the maximum magnetic flux density in T that the rod can achieve.',
    },
    {
      subHeading: 'Curve-Interior Behavior',
      chunk:
        'These are used to model the changing magnetization of the rod on the interior of the hysteresis curve: \n Rate Scaling Power - The rate of change of magnetization with respect to the external field is proportional the slope of the curve at the same magnetization times the horizontal distance to the curve to this power. \n Slope Offset - The rate of change of magnetization with respect to the external field near the boundary is the product of the slope of the boundary and this value.',
    },
  ],
};

export const actuatorsGuidance = {
  _default: {
    heading: actuatorsHeading,
    body: [
      {
        chunk: actuatorsMainParagraph,
      },
    ],
  },
  bodyFrameVector: {
    heading: 'Torque Axis',
    body: [
      {
        subHeading: 'For Reaction Wheels:',
        chunk:
          'Input the torque body frame vector: the direction around which the reaction wheel spins and produces torque on the spacecraft.',
      },
      {
        subHeading: 'For Magnetorquers:',
        chunk:
          ' Input the vector along the magnetorquer: this is the direction of the magnetic moment. The produced torque will always be orthogonal to this vector.',
      },
    ],
  },
  ratedMagneticMoment: () => mtGuidance,
  powerAtRatedMagneticMoment: {
    alias: 'ratedMagneticMoment',
  },
  modulationType: {
    alias: 'ratedMagneticMoment',
  },
  dutyCyclePeriod: {
    s: {
      alias: 'ratedMagneticMoment',
    },
  },
  //TODO add Drawing
  inertia: () => rwGuidance,
  ratedTorque: {
    alias: 'inertia',
  },
  ratedMomentum: {
    alias: 'inertia',
  },
  efficiency: {
    alias: 'inertia',
  },
  dipoleMomentMagnitude: () => barMagnetGuidance,
  length: () => hysteresisRodGuidance,
  diameter: {
    alias: 'length',
  },
  coerciveField: {
    alias: 'length',
  },
  fluxRemanence: {
    alias: 'length',
  },
  saturationFlux: {
    alias: 'length',
  },
  rateScalingPower: {
    alias: 'length',
  },
  slopeOffset: {
    alias: 'length',
  },
  // @ts-ignore
  dipoleOrientation: (values) => {
    return values?.type?.value === ActuatorVables.Type.PermanentDipoleMagnet.value
      ? barMagnetGuidance
      : hysteresisRodGuidance;
  },
  isp: {
    heading: 'Specific Impulse (Isp)',
    body: [
      {
        chunk:
          'The specific impulse, in seconds, is the nozzle exhaust velocity divided by the standard gravitational acceleration.',
      },
    ],
  },
  minThrust: {
    heading: 'Minumum Thrust',
    body: [
      {
        chunk:
          'The minimum thrust (N) is the smallest nonzero thrust that can be produced, or zero if arbitrarily small thrusts are allowed.',
      },
    ],
  },
  maxThrust: {
    heading: 'Maximum Thrust',
    body: [
      {
        chunk: 'The maximum thrust (N) is the largest thrust that can be produced.',
      },
    ],
  },
  location: {
    heading: 'Thruster Location',
    body: [
      {
        chunk: 'Enter the location at which the force of thrust is applied to the spacecraft.',
      },
    ],
  },
  orientation: {
    heading: 'Thruster Orientation',
    body: [
      {
        chunk:
          'Select a body frame vector parallel to the exhaust velocity produced by the thruster. The direction of thrust will be anti-parallel to this vector.',
      },
    ],
  },
  fuelReservoir: {
    heading: 'Fuel Reservoir',
    body: [
      {
        chunk: 'This thruster will consume fuel from the selected Fuel Reservoir.',
      },
    ],
  },
  // @ts-ignore
  powerSource: (values) => {
    if (values?.type?.value === ActuatorVables.Type.Thruster.value) {
      return {
        heading: 'Power Source',
        body: [
          {
            chunk:
              'Dynamic power consumption is currently not implemented for thrusters, so the choice of Power Source will not have an effect in the simulation. Loads can still be added to this component. ',
          },
        ],
      };
    } else return { alias: '_default' };
  },
  ...componentThermalGuidance,
};

export const useGuidance = makeGuidance(actuatorsGuidance);

const actuatorsSegmentGuidance = {
  heading: 'Actuators',
  body: [
    {
      chunk: actuatorsMainParagraph,
    },
  ],
};

export default actuatorsSegmentGuidance;
