import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  upcomingModulesPrompt: {
    maxWidth: 200,
    margin: 'auto',
    marginTop: 10,
    color: theme.palette.text.tertiary,
  },
  up: {
    transition: 'all 0.25s ease',
  },
  down: {
    transition: 'all 0.25s ease',
    transform: 'rotate(180deg)',
  },
}));

export default useStyles;
