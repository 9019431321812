import A from 'components/general/A';
import StyledDivider from 'components/general/StyledDivider';
import WaveDecoration from 'components/general/WaveDecoration';
import {
  LICENSE_AGREEMENT_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_USE_URL,
  VERSION_NUMBER,
  hotkeys,
} from 'config';
import { useActiveEntities, useSelectById, useUser } from 'hooks';
import moment from 'moment-timezone';
import { logoStacked } from 'multimedia/brand';
import { ContextNavContext } from 'providers';
import { useContext } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { NavLink, useHistory } from 'react-router-dom';
import Routes, { getSearchParams } from 'routes';
import theme from 'theme';
import ClipboardCopy from '../ClipboardCopy';
import InfoBadge from '../InfoBadge';
import StyledButton from '../StyledButton';
import BranchIcons from './BranchIcons';
import ContextNavList from './ContextNavList';
import useStyles from './styles';

/* Navigation Sidebar
 * Renders left-justified sidebar for global and contextual navigation
 */
const Nav = (props) => {
  let {
    children,
    contextName,
    contextHeader,
    contextSubHeader,
    control,
    layoutEdit,
    contextIcon,
    contextImg,
  } = props;

  const user = useUser();
  const { repo, branch } = useActiveEntities();
  const { share } = getSearchParams();
  const history = useHistory();

  const branchCreatedBy = useSelectById('User', branch?.createdBy || '');
  const branchLastModifiedBy = useSelectById('User', branch?.lastModifiedBy || '');

  if (!contextHeader) {
    contextHeader = repo?.name || (share && branch?.id && 'Shareable Link') || undefined;
  }
  if (!contextSubHeader && branch?.id) {
    contextSubHeader = (
      <>
        {/* Branch ID */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {branch.name} <InfoBadge content={`Branch ID: ${branch.id}`} style={{ marginLeft: 5 }} />
          <ClipboardCopy text={branch.id} style={{ paddingLeft: 5 }} displayLabel={false} />
        </div>

        {/* Creator and Most recent editor  */}
        <span style={{ fontSize: 12, color: theme.palette.text.secondary }}>
          {`${
            branchCreatedBy
              ? `Created: ${branchCreatedBy.firstName} ${branchCreatedBy.lastName}`
              : ''
          }`}
          {branchCreatedBy && branchLastModifiedBy ? <br /> : ''}
          {`${
            branchLastModifiedBy
              ? `Updated: ${moment(branch.dateModified).fromNow()} by ${
                  branchLastModifiedBy.firstName
                } ${branchLastModifiedBy.lastName}`
              : ''
          }`}
        </span>
      </>
    );
  }

  const classes = useStyles({ user });

  const navContext = useContext(ContextNavContext);

  const goHome = () => history.push(Routes.ROOT());

  useHotkeys(hotkeys.HOME.keys, goHome, [goHome]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.padder}>
            {contextImg || contextIcon || contextName ? (
              <>
                <div className={classes.iconWrapper}>
                  {contextIcon && contextIcon}
                  {contextImg && (
                    <img src={contextImg} className={classes.contextImg} alt="context-icon" />
                  )}
                  <h1 className={classes.contextName}>{contextName}</h1>
                </div>
              </>
            ) : (
              <div className={classes.stackedLogoWrapper}>
                <NavLink exact to={Routes.ROOT()}>
                  <img
                    src={logoStacked}
                    alt="Unified spacecraft modeling and simulation with Sedaro"
                  />
                </NavLink>
              </div>
            )}
          </div>
          <WaveDecoration />
        </div>
        {contextHeader && (
          <div className={classes.padder}>
            {contextHeader != null && <h4>{contextHeader}</h4>}
            {contextSubHeader != null && <h4 className="sub">{contextSubHeader}</h4>}
            {branch.id && navContext && <BranchIcons />}
            <StyledDivider $margin="top" />
          </div>
        )}
        <div className={classes.contextNavMenu}>
          {control && (
            <div className={classes.padder}>
              {control}
              <StyledDivider />
            </div>
          )}
          {navContext && (
            <>
              {layoutEdit && (
                <div className={classes.layoutWrapper + ' joyride-nav-layout'}>
                  <StyledButton style={{ width: 200, height: 24 }} onClick={layoutEdit.onEdit}>
                    Customize
                  </StyledButton>
                </div>
              )}
              <div className="joyride-nav">
                <ContextNavList />
              </div>
            </>
          )}
          <div className={classes.padder}>{children}</div>
        </div>
        <div className={classes.globalNavRoot}>
          <div className={classes.footer}>
            <div className={classes.padder}>
              <div className={classes.footerContent}>
                <div className={classes.versionNumber}>v {VERSION_NUMBER}</div>
                <div className={classes.legal}>
                  <A href={LICENSE_AGREEMENT_URL} target="_blank">
                    Licensing
                  </A>
                  {' | '}
                  <A href={TERMS_OF_USE_URL} target="_blank">
                    Terms
                  </A>
                  {' | '}
                  <A href={PRIVACY_POLICY_URL} target="_blank">
                    Privacy
                  </A>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
