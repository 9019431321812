import { faBookmark as bookmarkIcon } from '@fortawesome/free-regular-svg-icons';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { TARGETS } from 'components/AgentTemplateEditView/menu/targets';
import { useSelectEntityIdMap } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Routes, { routePathsCommon } from 'routes';
import theme from 'theme';
import A from '../A';
import MetamodelTypeIcon from '../MetamodelTypeIcon';
import AgentThumbnail from './AgentThumbnail';
import ScenarioThumbnail from './ScenarioThumbnail';
import useStyles from './styles';

const Bookmark = ({ branchId, onBranchNotFound, pinned, onPinClick }) => {
  const classes = useStyles({
    color1: theme.palette.secondary.main,
    color2: theme.palette.primary.main,
    pinned: pinned,
  });
  const branches = useSelectEntityIdMap('MissionVersion');
  const repos = useSelectEntityIdMap('Mission');
  const branch = branches[branchId];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!branch)
      dispatch(
        SatelliteApi.MissionVersion.actions.getMissionVersion({
          id: branchId,
          queryParams: {
            expand: {
              repository: {},
            },
          },
          failureCallback: () => {
            onBranchNotFound();
          },
        })
      );
  }, [branchId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card className={classes.bookmark}>
      <A
        style={{ textDecoration: 'none', color: theme.palette.text.primary }}
        href={
          branch &&
          (branch.metamodelType === 'Scenario'
            ? `/#${Routes.SCENARIO(branch.id, routePathsCommon.EDIT)}`
            : `/#${Routes.AGENT_TEMPLATE_EDIT(
                branch.id,
                branch.metamodelType === 'Spacecraft' ? TARGETS : 'coming-soon'
              )}`)
        }
      >
        {branch ? (
          <CardHeader
            title={
              <div>
                <h4>{repos[branch.repository].name}</h4>
                <div style={{ display: 'flex', height: 15 }}>
                  <MetamodelTypeIcon metamodelType={branch.metamodelType} />
                  <h3
                    style={{
                      marginLeft: 10,
                      color: theme.palette.primary.light,
                      fontWeight: 500,
                    }}
                  >
                    {branch.name}
                  </h3>
                </div>
                <h5
                  style={{ paddingTop: 8, color: theme.palette.text.secondary }}
                >{`Last Modified: ${branch.dateModified.local().format('M/DD/YY h:mma')}`}</h5>
              </div>
            }
            action={
              <Tooltip arrow title="Bookmark Page">
                <IconButton
                  className={classes.pinButton}
                  onClick={(e) => {
                    e.preventDefault();
                    onPinClick(!pinned);
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={pinned ? faBookmark : bookmarkIcon} />
                  </div>
                </IconButton>
              </Tooltip>
            }
          />
        ) : (
          <div className={classes.spinnerWrapper}>
            <CircularProgress size={30} thickness={3} color="inherit" />
            <h3 className={classes.message}>Loading bookmark...</h3>
          </div>
        )}
      </A>
      <CardContent>
        {branch?.model && (
          <div className={classes.modelWrapper}>
            {branch.metamodelType === 'Scenario' && <ScenarioThumbnail branch={branch} />}
            {branch.repoType === 'AGENT_TEMPLATE' && <AgentThumbnail branch={branch} />}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default Bookmark;
