import { Button, Card, CardActions, CardContent, CardHeader } from '@material-ui/core/';
import A from 'components/general/A';
import { RELEASE_NOTES, RELEASE_NOTES_URL, VERSION_NUMBER } from 'config';
import { useEffect, useRef, useState } from 'react';
import theme from 'theme';
import useStyles from './styles';

const colors = Object.values(theme.palette.charts.primary);

const ReleaseNotes = () => {
  const classes = useStyles();
  const scrollRef = useRef(null);
  const [feature, setFeature] = useState(0);
  const [expandFeature, setExpandFeature] = useState(false);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: 'instant',
      inline: 'center',
    });
  }, [expandFeature]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
    });
  }, [feature]);

  return RELEASE_NOTES.length ? (
    <div>
      <h3>{`Discover what's new in version ${VERSION_NUMBER}:`}</h3>
      <h6>
        See the full <A href={RELEASE_NOTES_URL}>release notes</A>
      </h6>
      <div style={{ width: '100%', overflow: 'hidden' }}>
        {expandFeature ? (
          <Card
            className={classes.focusedCard}
            style={{
              borderColor: colors[feature % colors.length],
              backgroundColor: theme.palette.background.main,
            }}
          >
            <CardHeader
              style={{
                backgroundColor: colors[feature % colors.length],
              }}
              title={
                <div>
                  <h2>{RELEASE_NOTES[feature].title}</h2>
                  <h6>{RELEASE_NOTES[feature].tags}</h6>
                </div>
              }
            />
            <CardContent style={{ whiteSpace: 'pre-line' }}>
              <h6>{RELEASE_NOTES[feature].description}</h6>
            </CardContent>
            <CardActions>
              <Button variant="outlined" size="small" onClick={() => setExpandFeature(false)}>
                Show Less
              </Button>
            </CardActions>
          </Card>
        ) : (
          <div className={classes.featureCards}>
            <div style={{ minWidth: '20%' }} />
            {RELEASE_NOTES.map((note, index) => (
              <Card
                key={index}
                id={index}
                className={index === feature ? classes.primaryCard : classes.secondaryCard}
                ref={index === feature ? scrollRef : null}
                onClick={() => setFeature(index)}
                style={{
                  backgroundColor: colors[index % colors.length],
                  borderColor: colors[index % colors.length],
                }}
              >
                <CardHeader
                  className={classes.cardHeader}
                  title={
                    <div>
                      <h3>{note.title}</h3>
                      <h6>{note.tags?.map((tag) => `#${tag}`).join(' ')}</h6>
                    </div>
                  }
                />
                <CardContent className={classes.cardContent}>
                  <h5>{note.briefDescription}</h5>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={index !== feature}
                    onClick={() => setExpandFeature(true)}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            ))}
            <div style={{ minWidth: '20%' }} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <h6>
      Check the <A href={RELEASE_NOTES_URL}>release notes</A> to see what's new!
    </h6>
  );
};
export default ReleaseNotes;
