import { ISelectOption } from 'components/general/types';
import { AgentVables } from 'utils/vable';
import * as Yup from 'yup';
import orbitValidation from '../OrbitForm/validation';

const validation = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  type: Yup.object().required('Select an agent type.'),

  // Templated agents
  templateRepo: Yup.object().when('type', {
    is: (type: ISelectOption) => type?.value === AgentVables.AgentType.TemplatedAgent.value,
    then: Yup.object().required('Select an agent template to define this agent.'),
  }),
  templateBranch: Yup.object().when('type', {
    is: (type: ISelectOption) => type?.value === AgentVables.AgentType.TemplatedAgent.value,
    then: Yup.object().required('Select a branch to define this agent.'),
  }),

  // Celestial agents
  body: Yup.object().when('type', {
    is: (type: ISelectOption) =>
      type?.value === AgentVables.AgentType.PeripheralCelestialPoint.value,
    then: Yup.object().required('Select a celestial body.'),
  }),

  // Ground points
  lat: Yup.object().when('type', {
    is: (type: ISelectOption) => type?.value === AgentVables.AgentType.PeripheralGroundPoint.value,
    then: Yup.object({
      deg: Yup.number()
        .required('Latitude is required.')
        .min(-90, 'Latitude must be between -90° and 90°N, inclusive.')
        .max(90, 'Latitude must be between -90° and 90°N, inclusive.'),
    }),
  }),
  lon: Yup.object().when('type', {
    is: (type: ISelectOption) => type?.value === AgentVables.AgentType.PeripheralGroundPoint.value,
    then: Yup.object({
      deg: Yup.number()
        .required('Longitude is required.')
        .min(-180, 'Longitude must be between -180° and 180°E, inclusive.')
        .max(180, 'Longitude must be between -180° and 180°E, inclusive.'),
    }),
  }),
  alt: Yup.object().when('type', {
    is: (type: ISelectOption) => type?.value === AgentVables.AgentType.PeripheralGroundPoint.value,
    then: Yup.object({
      km: Yup.number().required('Altitude is required.'),
    }),
  }),
  ...orbitValidation,
});

export default validation;
