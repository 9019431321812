import { useCesiumData } from 'components/general/PlaybackWGroups/general/data';
import { gaEvents } from 'config';
import { useActiveStaticModel, useSnackbar } from 'hooks';
import { useConOpsData } from 'hooks/useConOpsData';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from 'react-ga4';
import { getSearchParams } from 'routes';
import { a2Period, ae2PerigeeAlt } from 'utils/orbit';
import { useDataContext, useStream } from './DataProvider';

export const AnalyticsContext = createContext();
export const useAnalyticsContext = () => useContext(AnalyticsContext);

const AnalyticsProvider = ({ children }) => {
  const { staticModels, _state, startTime, stopTime } = useDataContext();
  const { agentId: activeAgentId } = getSearchParams();
  const activeStaticModel = useActiveStaticModel();

  // Create orbital elements series for active agent, if there is one
  // Will be [] if no active agent
  const {
    series: [, orbitalElementsSeries],
  } = useStream(
    staticModels.agents[activeAgentId] && activeStaticModel?.missionOrbit?.id && activeAgentId,
    `${activeStaticModel?.missionOrbit?.id}.orbitalElements`
  );

  const perigeeAlt = useMemo(() => {
    if (orbitalElementsSeries.a?.length) {
      const a = orbitalElementsSeries.a[0];
      const ecc = orbitalElementsSeries.e[0];
      if (a >= 0 && ecc <= 1) {
        return ae2PerigeeAlt(a, ecc);
      }
    }
    return 100;
  }, [orbitalElementsSeries]);

  const calculateMultiplier = useCallback(
    (v) => {
      const a = orbitalElementsSeries.a?.length ? orbitalElementsSeries.a[0] : 7000;
      const ecc = orbitalElementsSeries.e?.length ? orbitalElementsSeries.e[0] : 0;
      let scaling = 1;
      if (a >= 0 && ecc <= 1) {
        scaling = a2Period(a) / 150000;
      }
      return scaling * (Math.sign(v) * v ** 2);
    },
    [orbitalElementsSeries]
  );

  const cesiumData = useCesiumData(
    activeAgentId,
    perigeeAlt,
    staticModels,
    _state,
    startTime,
    stopTime
  );

  const conOpsData = useConOpsData(activeStaticModel, activeAgentId);
  let value = useMemo(
    () => ({
      activeAgentData: {
        id: activeAgentId,
        staticModel: activeStaticModel,
        orbitalElementsSeries,
        perigeeAlt,
        calculateMultiplier,
        cesiumData,
        conOpsData,
      },
    }),
    [
      activeAgentId,
      activeStaticModel,
      orbitalElementsSeries,
      perigeeAlt,
      calculateMultiplier,
      cesiumData,
      conOpsData,
    ]
  );

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

const FallbackComponent = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    enqueueSnackbar('An unexpected error occurred.  Please contact us if the error persists.');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Fallback UI is delegated to children here so they can handle conditionally rendering on/off playback boards
  return <>{children}</>;
};

const AnalyticsProviderWrapper = ({ children }) => {
  return (
    <ErrorBoundary
      fallback={<FallbackComponent>{children}</FallbackComponent>}
      onError={(error) => {
        console.error(error);
        ReactGA.event(gaEvents.EXCEPTION, {
          category: 'Simulation',
          action: 'Throw Simulation Exception',
          label: 'Thrown Exception',
        });
      }}
    >
      <AnalyticsProvider>{children}</AnalyticsProvider>
    </ErrorBoundary>
  );
};

export default AnalyticsProviderWrapper;
