import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';
const curAndIncOverlayStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  cursor: 'pointer',
  zIndex: 1,
  opacity: 0.3,
  '&:hover': {
    border: `${theme.palette.primary.light} 3px solid`,
  },
} as const;

const useStyles = makeStyles((theme) => {
  return {
    conflictSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'relative',
      height: 'max-content',
      '& a': {
        // This makes sure the codeFold text is above the current & incoming overlay divs (so it's still clickable), but
        // appears in the same place in the document flow
        zIndex: 10,
        position: 'absolute',
        transform: 'translateY(-13px)',
      },
      '& tr:first-child pre': {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: '15px',
      },
    },
    currentOverlay: {
      ...curAndIncOverlayStyle,
      right: '50%',
    },
    incomingOverlay: {
      ...curAndIncOverlayStyle,
      left: '50%',
    },
  };
});

export default useStyles;
