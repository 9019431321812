import { Grid } from '@material-ui/core';
import BugReportIcon from '@material-ui/icons/BugReport';
import useStyles from './styles';

import MailOutlineIcon from '@material-ui/icons/MailOutline';

import PhoneIcon from '@material-ui/icons/Phone';
import A from 'components/general/A';
import { FEEDBACK_FORM_URL, SALES_EMAIL, SUPPORT_EMAIL, SUPPORT_PHONE } from 'config';

interface IProps {
  sales?: boolean;
}

const ContactIcons = (props: IProps) => {
  const { sales } = props;

  const classes = useStyles();
  const contactEmail = sales ? SALES_EMAIL : SUPPORT_EMAIL;

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <div className={classes.supportChannel}>
          <PhoneIcon />
          <A href={`tel:${SUPPORT_PHONE}`}>{SUPPORT_PHONE}</A>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.supportChannel}>
          <MailOutlineIcon />
          <A href={`mailto:${contactEmail}`}>{contactEmail}</A>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.supportChannel}>
          <BugReportIcon />
          <A href={`${FEEDBACK_FORM_URL}`} target="_blank">
            Submit Ticket
          </A>
        </div>
      </Grid>
    </Grid>
  );
};

export default ContactIcons;
