import LoadingView from 'components/LoadingView';
import StyledButton from 'components/general/StyledButton';
import WaveContainer from 'components/general/WaveContainer';
import { IErrorResponse, IUrlParams, IWorkspace } from 'components/general/types';
import AuthWidget from 'components/general/widgets/AuthWidget';
import { gaEvents } from 'config';
import { useSnackbar, useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import Routes from 'routes';
import useStyles from './styles';

const NewMemberView = () => {
  const {
    Workspace: {
      actions: { verifyNewMember },
    },
    User: {
      actions: { getUser },
    },
  } = SatelliteApi;
  const user = useUser();
  const dispatch = useDispatch();

  const { token } = useParams<IUrlParams>();
  const history = useHistory();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (!user) {
      history.push({
        pathname: Routes.LOGIN(),
        state: { from: location },
      });
      enqueueSnackbar(
        'In order to be added as a member, please login to your Sedaro account or create an account.',
        { variant: 'info' }
      );
    } else if (token !== ':token') {
      dispatch(
        verifyNewMember({
          invitationToken: token,
          successCallback: (workspace: IWorkspace) => {
            // Get user (with workspaces and roles) again
            dispatch(
              getUser({
                successCallback: () => {
                  // Display a notification for successful email verifications
                  enqueueSnackbar('Successfully added as member.', {
                    variant: 'success',
                  });
                  ReactGA.event(gaEvents.JOIN_WORKSPACE, {
                    category: 'Collaborate',
                    action: 'Join Workspace',
                    label: 'Join Workspace Button',
                  });
                  history.replace(Routes.ROOT());
                },
              })
            );
          },
          failureCallback: (response: IErrorResponse) => {
            history.replace(Routes.NEW_MEMBER());
            enqueueSnackbar(response?.error?.message);
          },
        })
      );
    }
  }, [dispatch, token, enqueueSnackbar, history, location, verifyNewMember]); //eslint-disable-line

  if (token !== ':token') {
    return <LoadingView />;
  }
  return (
    <WaveContainer>
      <AuthWidget>
        <form>
          <h2 className={classes.heading}>Workspace</h2>
          <h5>
            Please check your email for the link to join the Sedaro workspace. If you have lost this
            email or the link is not working, please reach out to an owner of the workspace.
          </h5>
          <div className={classes.buttonWrapper}>
            <StyledButton type="button" onClick={() => history.push(Routes.ROOT())} fullWidth>
              Back To Home
            </StyledButton>
          </div>
        </form>
      </AuthWidget>
    </WaveContainer>
  );
};

export default withRouter(NewMemberView);
