import { createSlice } from '@reduxjs/toolkit';

interface IMissionExplorerState {
  userId: string | undefined;
  activeWorkspaceId: string | undefined;
  previousWorkspaceId: string | undefined;
  activeMissionId: string | undefined;
  activeMissionVersionId: string | undefined;
  latestJobId: string | undefined;
  inactiveLicenseWorkspace: string | undefined;
}

const initialState: IMissionExplorerState = {
  userId: undefined,
  activeWorkspaceId: undefined,
  previousWorkspaceId: undefined,
  activeMissionId: undefined,
  activeMissionVersionId: undefined,
  latestJobId: undefined,
  inactiveLicenseWorkspace: undefined,
};

const missionExplorerSlice = createSlice({
  name: 'missionExplorer',
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setActiveWorkspaceId: (state, action) => {
      state.activeWorkspaceId = action.payload;
    },
    setPreviousWorkspaceId: (state, action) => {
      state.previousWorkspaceId = action.payload;
    },
    setActiveMissionId: (state, action) => {
      state.activeMissionId = action.payload;
    },
    setActiveMissionVersionId: (state, action) => {
      state.activeMissionVersionId = action.payload;
    },
    setLatestJobId: (state, action) => {
      state.latestJobId = action.payload;
    },
    resetInactiveLicenseWorkspace: (state) => {
      state.inactiveLicenseWorkspace = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('INSUFFICIENT_LICENSE', (state, action) => {
      // Notify <App/> that the workspace is invalid so that it can redirect appropriately
      // @ts-ignore: next-line
      state.inactiveLicenseWorkspace = action.payload.workspace;
    });
  },
});

export default missionExplorerSlice.reducer;
export const {
  setUserId,
  setActiveWorkspaceId,
  setPreviousWorkspaceId,
  setActiveMissionId,
  setActiveMissionVersionId,
  setLatestJobId,
  resetInactiveLicenseWorkspace,
} = missionExplorerSlice.actions;
