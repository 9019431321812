/* eslint no-template-curly-in-string: 0 */
import * as Yup from 'yup';

const validation = Yup.object().shape({
  numSeries: Yup.number()
    .required('Number of cells in series is required.')
    .integer('Number of cells in series should be a positive integer.')
    .min(1, 'Number of cells in series must be greater than 0.'),

  numParallel: Yup.number()
    .required('Number of cells in parallel is required.')
    .integer('Number of cells in parallel should be a positive integer.')
    .min(1, 'Number of cells in parallel must be greater than 0.'),

  blockingDiodeDrop: Yup.number()
    .required('Blocking diode drop is required.')
    .min(0, 'Blocking diode voltage must be greater than 0.'),
});

export default validation;
