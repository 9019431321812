import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@material-ui/core';
import { useActiveEntities, useSelectAll } from 'hooks';
import useJobPolling from 'hooks/useJobPolling';
import { useCallback, useMemo, useState } from 'react';
import { getSearchParams } from 'routes';
import JobRow from '../JobStatusBoard/JobRow';
import StyledButton from '../StyledButton';
import { IGenericObject, IJob } from '../types';
import useStyles from './styles';

interface IJobMenuProps {
  actionButtonProps: (job: IJob) => IGenericObject;
}

const pageSize = 10;

const JobMenu = ({ actionButtonProps }: IJobMenuProps) => {
  const classes = useStyles();
  const { share } = getSearchParams();
  const {
    branch: { id },
  } = useActiveEntities();
  const [page, setPage] = useState(1);

  const params = useMemo(() => ({ branch: id, page, pageSize, share }), [id, page, share]);
  useJobPolling(params, 5000);

  const actionButton = useCallback(
    (job: IJob) => {
      const buttonProps = actionButtonProps(job);
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StyledButton
            type="button"
            min
            framed
            replaceSpinner
            dontDisableInReadOnly
            style={{ margin: 8 }}
            {...buttonProps}
          >
            <h6>{buttonProps.text || (job.dataArray ? 'Analyze' : 'No Data')}</h6>
          </StyledButton>
        </div>
      );
    },
    [actionButtonProps]
  );

  const jobStore = useSelectAll('Job');
  const jobsSorted = useMemo(() => {
    return jobStore
      .filter((job) => job.branch === id)
      .sort((a, b) => b.dateCreated.diff(a.dateCreated));
  }, [jobStore, id]);

  return (
    <div className={classes.interior}>
      <h3>Simulation Jobs</h3>
      {jobsSorted.slice((page - 1) * pageSize, page * pageSize).map((job) => (
        <JobRow
          key={job.id}
          job={job}
          sortByBranch={true}
          small={true}
          rowOnClick={actionButtonProps(job).onClick}
          actionButton={actionButton}
        />
      ))}
      <div
        style={{
          width: '100%',
          display: 'inline-flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 5,
        }}
      >
        <IconButton disabled={page <= 1} onClick={() => setPage((prev) => prev - 1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </IconButton>
        <h3>{`Viewing Jobs: ${(page - 1) * pageSize + 1} - ${
          jobsSorted.length < page * pageSize ? jobsSorted.length : page * pageSize
        }`}</h3>
        <IconButton
          disabled={page * pageSize >= jobsSorted.length}
          onClick={() => setPage((prev) => prev + 1)}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconButton>
      </div>
    </div>
  );
};

export default JobMenu;
