import {
  faBan,
  faCaretDown,
  faCaretRight,
  faChevronLeft,
  faChevronRight,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Switch, Tooltip } from '@material-ui/core';
import { usePermissionCheck, useSelectAll, useSelectEntityIdMap, useSnackbar } from 'hooks';
import useJobPolling from 'hooks/useJobPolling';
import useWorkspace from 'hooks/useWorkspace';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Routes, { routePathsCommon } from 'routes';
import theme from 'theme';
import { ModuleVables, WorkspaceVables } from 'utils/vable';
import RowedExplorer from '../RowedExplorer';
import ViewPortInlay from '../ViewPortInlay';
import { IJob, IMission, IMissionVersion } from '../types';
import { IDispatchFailureCallback } from '../types/layout';
import JobRow from './JobRow';
import useStyles from './styles';

interface IJobStatusProps {
  small?: boolean;
  pageSize?: number;
  projectId?: string;
  repoId?: string;
  poll?: boolean;
  actionButton?: (job: IJob) => JSX.Element;
}

const JobStatusBoard = (props: IJobStatusProps) => {
  const [canViewSims] = usePermissionCheck([WorkspaceVables.Permission.VIEW_SIMULATION]);
  return canViewSims ? <JobStatus {...props} /> : null;
};

const JobStatus = ({ small = false, pageSize = 5, projectId = '', repoId = '', poll = true }) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [sortByBranch, setSortByBranch] = useState(false);
  const [loading, setLoading] = useState(false);
  const workspace = useWorkspace();
  const history = useHistory();

  const [canRunSims, canViewSims] = usePermissionCheck([
    WorkspaceVables.Permission.RUN_SIMULATION,
    WorkspaceVables.Permission.VIEW_SIMULATION,
  ]);

  const {
    Job: {
      actions: { abortJob },
    },
  } = SatelliteApi;

  const repos = useSelectEntityIdMap('Mission');
  const repoMap = useMemo(() => {
    if (!workspace) return {};
    const filteredRepos = Object.keys(repos).reduce((acc: { [key: string]: IMission }, id) => {
      if (repos[id].workspace === workspace.id) acc[id] = repos[id];
      return acc;
    }, {});
    if (repoId) return { [repoId]: filteredRepos[repoId] };
    if (projectId)
      return Object.keys(filteredRepos).reduce((acc: { [key: string]: IMission }, id) => {
        if (filteredRepos[id].project === projectId) acc[id] = filteredRepos[id];
        return acc;
      }, {});
    return filteredRepos;
  }, [repos, projectId, repoId, workspace]);

  const branches = useSelectEntityIdMap('MissionVersion');
  const branchMap = useMemo(() => {
    return Object.keys(branches).reduce((acc: { [key: string]: IMissionVersion }, id) => {
      if (repoMap[branches[id].repository]) acc[id] = branches[id];
      return acc;
    }, {});
  }, [branches, repoMap]);

  const handleAbort = useCallback(
    (job: IJob) => {
      setLoading(true);
      dispatch(
        abortJob({
          branchId: job.branch,
          id: job.id,
          successCallback: () => {
            setLoading(false);
            enqueueSnackbar('Job aborted.', {
              variant: 'success',
            });
          },
          failureCallback: (response: IDispatchFailureCallback) => {
            setLoading(false);
            enqueueSnackbar(response?.error?.message || 'Error aborting job.');
          },
        })
      );
    },
    [abortJob, dispatch, enqueueSnackbar]
  );

  const params = useMemo(() => {
    let filter = {};
    if (repoId) filter = { repository: repoId };
    else if (projectId) filter = { project: projectId };
    else filter = { workspace: workspace.id };
    return {
      page,
      pageSize,
      ...filter,
    };
  }, [page, pageSize, repoId, projectId, workspace]);
  useJobPolling(params, 5000);

  const jobStore = useSelectAll('Job');
  const jobsSorted = useMemo(() => {
    return jobStore
      .filter((job) => branchMap[job.branch])
      .sort((a, b) => b.dateCreated.diff(a.dateCreated));
  }, [jobStore, branchMap]);

  const [expanded, setExpanded] = useState(new Set());
  useEffect(() => {
    setExpanded(new Set(Object.keys(repoMap)));
  }, [repoMap]);

  const actionButton = useCallback(
    (job: IJob) => (
      <Tooltip
        arrow
        title={
          canRunSims && job.status === ModuleVables.SimulationStatuses.RUNNING.value
            ? 'Abort Job'
            : job.dataArray
            ? 'Analyze'
            : 'No Data'
        }
      >
        {canRunSims && job.status === ModuleVables.SimulationStatuses.RUNNING.value ? (
          <IconButton
            disabled={
              (!canRunSims && job.status === ModuleVables.SimulationStatuses.RUNNING.value) ||
              loading
            }
            style={{
              fontSize: 16,
              padding: 0,
              margin: 8,
              color:
                canRunSims &&
                job.status === ModuleVables.SimulationStatuses.RUNNING.value &&
                !loading
                  ? theme.palette.error.main
                  : theme.palette.action.disabled,
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleAbort(job);
            }}
          >
            <FontAwesomeIcon icon={faBan} />
          </IconButton>
        ) : (
          <IconButton
            component={Link}
            to={`${Routes.SCENARIO(job.branch, routePathsCommon.ANALYZE)}?job=${job.id}`}
            onClick={((e) => e.stopPropagation()) as MouseEventHandler}
            style={{
              fontSize: 16,
              padding: 0,
              margin: 8,
              color: theme.palette.primary.light,
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </IconButton>
        )}
      </Tooltip>
    ),
    [canRunSims, handleAbort, loading]
  );

  if (!canViewSims) return null;
  return (
    <div className={classes.root}>
      <RowedExplorer
        rowsTitle={
          <div>
            <h1>Job Status</h1>
            <h6>
              Sort by branch
              <Switch
                color="primary"
                size="small"
                checked={sortByBranch}
                onChange={() => setSortByBranch(!sortByBranch)}
              />
            </h6>
          </div>
        }
      >
        <div className={classes.interior}>
          {jobsSorted.length ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {sortByBranch ? (
                <>
                  {Object.values(repoMap)
                    .filter((repo) => repo.metamodelType === 'Scenario')
                    .map((repo) => (
                      <div key={repo.id} style={{ padding: 4 }}>
                        <h3>
                          <IconButton
                            onClick={() => {
                              expanded.has(repo.id)
                                ? expanded.delete(repo.id)
                                : expanded.add(repo.id);
                              setExpanded(new Set(expanded));
                            }}
                          >
                            <FontAwesomeIcon
                              icon={expanded.has(repo.id) ? faCaretDown : faCaretRight}
                            />
                          </IconButton>
                          {repo.name}
                        </h3>
                        {expanded.has(repo.id) &&
                          repo.branches.map((branchId) => {
                            const branch = branchMap[branchId];
                            if (!jobsSorted.some((job) => job.branch === branchId)) return null;
                            return (
                              <div key={branchId} style={{ marginLeft: 40 }}>
                                <h3
                                  style={{
                                    padding: 4,
                                    borderBottom: expanded.has(branch.id)
                                      ? '0.5px solid #dddddd'
                                      : '',
                                  }}
                                >
                                  <IconButton
                                    onClick={() => {
                                      expanded.has(branch.id)
                                        ? expanded.delete(branch.id)
                                        : expanded.add(branch.id);
                                      setExpanded(new Set(expanded));
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={expanded.has(branch.id) ? faCaretDown : faCaretRight}
                                    />
                                  </IconButton>

                                  <a
                                    href={`/#${Routes.SCENARIO(branchId, routePathsCommon.EDIT)}`}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    {branch.name}
                                  </a>
                                </h3>
                                {expanded.has(branch.id) && (
                                  <div style={{ marginLeft: 40 }}>
                                    {jobsSorted
                                      .filter((job) => job.branch === branchId)
                                      .map((job) => {
                                        return (
                                          <JobRow
                                            key={job.id}
                                            job={job}
                                            sortByBranch={sortByBranch}
                                            repoMap={repoMap}
                                            branchMap={branchMap}
                                            small={small}
                                            rowOnClick={() => {
                                              history.push(
                                                `${Routes.SCENARIO(
                                                  job.branch,
                                                  routePathsCommon.ANALYZE
                                                )}?job=${job.id}`
                                              );
                                            }}
                                            actionButton={actionButton}
                                          />
                                        );
                                      })}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {jobsSorted.slice((page - 1) * pageSize, page * pageSize).map((job) => {
                    return (
                      <JobRow
                        key={job.id}
                        job={job}
                        sortByBranch={sortByBranch}
                        repoMap={repoMap}
                        branchMap={branchMap}
                        small={small}
                        rowOnClick={() =>
                          history.push(
                            `${Routes.SCENARIO(job.branch, routePathsCommon.ANALYZE)}?job=${job.id}`
                          )
                        }
                        actionButton={actionButton}
                      />
                    );
                  })}{' '}
                </>
              )}
              {jobsSorted.length > pageSize && (
                <div
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    alignSelf: 'center',
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: 5,
                  }}
                >
                  <IconButton disabled={page <= 1} onClick={() => setPage((prev) => prev - 1)}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </IconButton>
                  <h3>{`Viewing Jobs: ${(page - 1) * pageSize + 1} - ${
                    jobsSorted.length < page * pageSize ? jobsSorted.length : page * pageSize
                  }`}</h3>
                  <IconButton
                    disabled={page * pageSize >= jobsSorted.length}
                    onClick={() => setPage((prev) => prev + 1)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </IconButton>
                </div>
              )}
            </div>
          ) : (
            <ViewPortInlay text="No Jobs." />
          )}
        </div>
      </RowedExplorer>
    </div>
  );
};

export default JobStatusBoard;
