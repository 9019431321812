import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { IRowProps, rowStyles } from 'components/general/RowedExplorer/Row/styles';
import { timing } from 'config';
import theme, { offWhite } from 'theme';

interface IProps extends IRowProps {
  isOver: boolean;
  canDrop: boolean;
}

// Styles to apply to row when it transforms into a dropzone
const dropzoneStyles = ({ isOver, canDrop }: IProps) => {
  const result: CSSProperties = {
    transition: `background ${timing.dragDropHighlightDuration}s ease, margin-top ${timing.dragDropHighlightDuration}s ease`,
    background: canDrop
      ? isOver
        ? theme.palette.background.light
        : theme.palette.background.darker
      : undefined,
    borderRadius: canDrop ? '5px' : undefined,
    marginTop: canDrop ? '0.25em' : undefined,
    borderWidth: canDrop ? '2px' : '0px 0px 0.5px 0px',
    borderColor: canDrop ? offWhite(0.4) : '#dddddd',
    borderStyle: canDrop ? 'dashed' : 'solid',
  };
  return result;
};

const useStyles = makeStyles((theme) => ({
  ...rowStyles,
  row: (props: IProps) => ({
    ...rowStyles.row(props),
    ...dropzoneStyles(props),
  }),
  iconSeparator: {
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
