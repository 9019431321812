import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  fontSize: number;
  marginTop: number | string;
  marginBottom: number | string;
}

const useStyles = makeStyles((theme) => ({
  inlayWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  inlayText: (props: IProps) => ({
    color: theme.palette.background.inlayText,
    fontSize: props.fontSize || 25,
    marginTop: props.marginTop || '1.5em',
  }),
  inlaySubtext: (props: IProps) => ({
    color: theme.palette.background.inlayText,
    fontSize: props.fontSize * 0.6 || 15,
    marginBottom: props.marginBottom || '1.5em',
  }),
}));
export default useStyles;
