import {
  faChevronDown,
  faChevronRight,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, IconButton } from '@material-ui/core';
import StyledButton from 'components/general/StyledButton';
import useStyles from './styles';

const FilterMenu = ({
  cesiumData,
  entityFilter,
  setEntityFilter,
  filterMenuExpanded,
  setFilterMenuExpanded,
}) => {
  const classes = useStyles();

  return (
    <div>
      {cesiumData.targets
        .filter((t) => entityFilter[t.name] && Object.keys(entityFilter[t.name]).length)
        .map((target) => {
          return (
            <div key={target.name} style={{ padding: 5 }}>
              <div className={classes.listHeader}>
                <h5>{target.name}</h5>
                <div>
                  <StyledButton
                    className={classes.viewerButton}
                    min
                    type="button"
                    onClick={() => {
                      const off =
                        target.fovs.some((v) => !entityFilter[target.name][v.name]) ||
                        target.bfVectors.some((v) => !entityFilter[target.name][v.name]);
                      setEntityFilter({
                        ...entityFilter,
                        [target.name]: {
                          ...target.fovs.reduce((acc, { name }) => {
                            acc[name] = off;
                            return acc;
                          }, {}),
                          ...target.bfVectors.reduce((acc, { name }) => {
                            acc[name] = off;
                            return acc;
                          }, {}),
                        },
                      });
                    }}
                    off={
                      target.fovs.some((v) => !entityFilter[target.name][v.name]) ||
                      target.bfVectors.some((v) => !entityFilter[target.name][v.name])
                    }
                    dontDisableInReadOnly
                    tooltip={
                      entityFilter[target.name]
                        ? 'Hide Fields of View and Body Frame Vectors'
                        : 'Show Fields of View and Body Frame Vectors'
                    }
                  >
                    <div>
                      <FontAwesomeIcon icon={entityFilter[target.name] ? faEye : faEyeSlash} />
                    </div>
                  </StyledButton>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setFilterMenuExpanded((prev) => {
                        prev.has(target.name) ? prev.delete(target.name) : prev.add(target.name);
                        return new Set(prev);
                      })
                    }
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={filterMenuExpanded.has(target.name) ? faChevronDown : faChevronRight}
                      />
                    </div>
                  </IconButton>
                </div>
              </div>
              <Collapse in={filterMenuExpanded.has(target.name)}>
                <div style={{ paddingLeft: 20, width: '100%' }}>
                  {target.fovs?.length > 0 && (
                    <div>
                      <div className={classes.listHeader}>
                        <h6>Fields of View</h6>
                        <div>
                          <StyledButton
                            className={classes.viewerButton}
                            min
                            type="button"
                            onClick={() => {
                              const off = target.fovs.some(
                                (v) => !entityFilter[target.name][v.name]
                              );
                              setEntityFilter({
                                ...entityFilter,
                                [target.name]: {
                                  ...entityFilter[target.name],
                                  ...target.fovs.reduce((acc, { name }) => {
                                    acc[name] = off;
                                    return acc;
                                  }, {}),
                                },
                              });
                            }}
                            off={target.fovs.some((v) => !entityFilter[target.name][v.name])}
                            dontDisableInReadOnly
                            tooltip={
                              entityFilter[target.name]
                                ? 'Hide All Fields of View'
                                : 'Show All Fields of View'
                            }
                          >
                            <div>
                              <FontAwesomeIcon
                                icon={entityFilter[target.name] ? faEye : faEyeSlash}
                              />
                            </div>
                          </StyledButton>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setFilterMenuExpanded((prev) => {
                                prev.has(target.name + '-FOV')
                                  ? prev.delete(target.name + '-FOV')
                                  : prev.add(target.name + '-FOV');
                                return new Set(prev);
                              })
                            }
                          >
                            <div>
                              <FontAwesomeIcon
                                icon={
                                  filterMenuExpanded.has(target.name + '-FOV')
                                    ? faChevronDown
                                    : faChevronRight
                                }
                              />
                            </div>
                          </IconButton>
                        </div>
                      </div>
                      <Collapse in={filterMenuExpanded.has(target.name + '-FOV')}>
                        <div style={{ paddingLeft: 20, width: '100%' }}>
                          {target.fovs.map(({ name }) => {
                            return (
                              <div className={classes.listItem} key={`FoV: ${name}`}>
                                <h6>{name}</h6>
                                <StyledButton
                                  className={classes.viewerButton}
                                  min
                                  type="button"
                                  onClick={() => {
                                    setEntityFilter({
                                      ...entityFilter,
                                      [target.name]: {
                                        ...entityFilter[target.name],
                                        [name]: !entityFilter[target.name][name],
                                      },
                                    });
                                  }}
                                  off={!entityFilter[target.name][name]}
                                  dontDisableInReadOnly
                                  tooltip={
                                    entityFilter[target.name][name]
                                      ? 'Hide Field of View'
                                      : 'Show Field of View'
                                  }
                                >
                                  <div>
                                    <FontAwesomeIcon
                                      icon={entityFilter[target.name][name] ? faEye : faEyeSlash}
                                    />
                                  </div>
                                </StyledButton>
                              </div>
                            );
                          })}
                        </div>
                      </Collapse>
                    </div>
                  )}
                  {target.bfVectors?.length > 0 && (
                    <div>
                      <div className={classes.listHeader}>
                        <h6>Body Frame Vectors</h6>
                        <div>
                          <StyledButton
                            className={classes.viewerButton}
                            min
                            type="button"
                            onClick={() => {
                              const off = target.bfVectors.some(
                                (v) => !entityFilter[target.name][v.name]
                              );
                              setEntityFilter({
                                ...entityFilter,
                                [target.name]: {
                                  ...entityFilter[target.name],
                                  ...target.bfVectors.reduce((acc, { name }) => {
                                    acc[name] = off;
                                    return acc;
                                  }, {}),
                                },
                              });
                            }}
                            off={target.bfVectors.some((v) => !entityFilter[target.name][v.name])}
                            dontDisableInReadOnly
                            tooltip={
                              entityFilter[target.name]
                                ? 'Hide All Body Frame Vectors'
                                : 'Show All Body Frame Vectors'
                            }
                          >
                            <div>
                              <FontAwesomeIcon
                                icon={entityFilter[target.name] ? faEye : faEyeSlash}
                              />
                            </div>
                          </StyledButton>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setFilterMenuExpanded((prev) => {
                                prev.has(target.name + '-BFV')
                                  ? prev.delete(target.name + '-BFV')
                                  : prev.add(target.name + '-BFV');
                                return new Set(prev);
                              })
                            }
                          >
                            <div>
                              <FontAwesomeIcon
                                icon={
                                  filterMenuExpanded.has(target.name + '-BFV')
                                    ? faChevronDown
                                    : faChevronRight
                                }
                              />
                            </div>
                          </IconButton>{' '}
                        </div>
                      </div>
                      <Collapse in={filterMenuExpanded.has(target.name + '-BFV')}>
                        <div style={{ paddingLeft: 20, width: '100%' }}>
                          {target.bfVectors.map(({ name }) => {
                            return (
                              <div className={classes.listItem} key={`BF Vector: ${name}`}>
                                <h6>{name}</h6>
                                <StyledButton
                                  className={classes.viewerButton}
                                  min
                                  type="button"
                                  onClick={() => {
                                    setEntityFilter({
                                      ...entityFilter,
                                      [target.name]: {
                                        ...entityFilter[target.name],
                                        [name]: !entityFilter[target.name][name],
                                      },
                                    });
                                  }}
                                  off={!entityFilter[target.name][name]}
                                  dontDisableInReadOnly
                                  tooltip={
                                    entityFilter[target.name][name]
                                      ? 'Hide Body Frame Vector'
                                      : 'Show Body Frame Vector'
                                  }
                                >
                                  <div>
                                    <FontAwesomeIcon
                                      icon={entityFilter[target.name][name] ? faEye : faEyeSlash}
                                    />
                                  </div>
                                </StyledButton>
                              </div>
                            );
                          })}
                        </div>
                      </Collapse>
                    </div>
                  )}
                </div>
              </Collapse>
            </div>
          );
        })}
    </div>
  );
};
export default FilterMenu;
