import * as Cesium from 'cesium';
import { useMemo } from 'react';
import {
  BillboardGraphics,
  CylinderGraphics,
  EllipsoidGraphics,
  Entity,
  LabelGraphics,
  PolylineGraphics,
} from 'resium';
import {
  cesiumBfVectorLabelOffsetScale,
  cesiumBfVectorScaleDown,
  cesiumBfVectorScaleUp,
  cesiumScaleAltitude,
  cesiumScaleLinearIntercept,
  cesiumScaleLinearSlope,
  enuOffsetHeading,
  enuOffsetPitch,
  enuOffsetRoll,
  perigeeAltScaleCap,
} from '../general/constants';
import { pathStepLimit } from './SettingsMenu';
import { perigeeCadScale } from './utils';

const configMap = {
  SelectedTarget: {
    colors: {
      primary: Cesium.Color.LIMEGREEN,
      secondary: Cesium.Color.GOLDENROD,
      PathRemaining: Cesium.Color.LIGHTYELLOW,
      path: Cesium.Color.LIMEGREEN,
    },
    pixelSize: 15,
    namePrefix: 'Analyzing',
    width: 3,
  },
  SpaceTarget: {
    colors: {
      primary: Cesium.Color.RED,
      path: Cesium.Color.TOMATO,
    },
    pixelSize: 10,
    namePrefix: 'Space',
    width: 1,
  },
  TerrestrialTarget: {
    colors: {
      primary: Cesium.Color.GOLD,
      secondary: Cesium.Color.GOLDENROD,
      PathRemaining: Cesium.Color.LIGHTYELLOW,
    },
    pixelSize: 10,
    namePrefix: 'Terrestrial',
    width: 1,
  },
  GroundTarget: {
    colors: {
      primary: Cesium.Color.MAGENTA,
    },
    pixelSize: 10,
    namePrefix: 'Ground',
    width: 1,
  },
  CelestialTarget: {
    colors: {
      primary: Cesium.Color.ORANGE,
    },
    namePrefix: 'Celestial',
    width: 1,
  },
};

const enuOffsetQuaternion = Cesium.Quaternion.fromHeadingPitchRoll(
  new Cesium.HeadingPitchRoll(enuOffsetHeading, enuOffsetPitch, enuOffsetRoll),
  new Cesium.Quaternion()
);

const cesiumBfVectorLabelTextScale = 0.5;
const cesiumBfVectorLineWidth = 8;
const cesiumBfVectorColor = Cesium.Color.CYAN;
// REF: sunTrackingVariables
// TODO: Comment cesiumSurfaceVectorColor back in when ready
// const cesiumSurfaceVectorColor = Cesium.Color.YELLOW;

const TargetEntity = (props) => {
  const {
    target,
    data,
    orbitTailFactor,
    showOrbitTails,
    showLabels,
    showComms,
    labelTextScale,
    viewerRef,
    cadSignedUrl,
    cadScaleFactor,
    scaleToRealSize,
    showWayPoints,
    showWayPaths,
    showModels,
    semiMajor,
    cadScaleMultiplier,
    fovScaleMultiplier,
    labelScaleMultiplier,
    entityFilter,
    satelliteEntityRef,
    cadScalar,
    pathStep,
  } = props;
  const config = configMap[target.isModel ? 'SelectedTarget' : target.type];
  const keyPrefix = `${target.type.charAt(0)}T_`;
  const perigeeAlt = target.perigeeAlt || 100;

  const targetEntity = useMemo(() => {
    return (
      <Entity
        ref={satelliteEntityRef}
        name={`${config.namePrefix} Target: ${target.name}`}
        key={`${keyPrefix}${target.name}`}
        availability={
          new Cesium.TimeIntervalCollection([
            new Cesium.TimeInterval({
              start: data.startTime,
              stop: data.stopTime,
            }),
          ])
        }
        position={target.position3D}
        point={
          ((target.type === 'SpaceTarget' || target.type === 'TerrestrialTarget') && {
            pixelSize: config.pixelSize,
            color: config.colors.primary,
            show: !showModels || !cadSignedUrl, // Space targets with no cad always show point
          }) ||
          (target.type === 'GroundTarget' && {
            pixelSize: config.pixelSize,
            color: config.colors.primary,
            show: true,
          })
        }
        model={
          (target.type === 'SpaceTarget' || 'TerrestrialTarget') && {
            uri: cadSignedUrl,
            maximumScale: 2000000 * cadScaleFactor,
            scale: scaleToRealSize
              ? 1
              : perigeeCadScale(perigeeAlt ? perigeeAlt : 500, cadScaleFactor) * cadScaleMultiplier,
            show: showModels,
          }
        }
        path={
          showOrbitTails && target.type === 'SpaceTarget'
            ? {
                // resolution prop for path is the max seconds to step when sampling the position, so the lower it is the more points the orbit path will have
                resolution: pathStepLimit === Math.abs(pathStep) ? Infinity : Math.abs(pathStep),
                show: true,
                trailTime: target.orbitalPeriod
                  ? target.orbitalPeriod * orbitTailFactor
                  : undefined,
                leadTime: 0,
                material: config.colors.path,
                width: config.width,
              }
            : undefined
        }
        orientation={
          (target.type === 'SpaceTarget' || target.type === 'TerrestrialTarget') &&
          target.quaternion
            ? // Created sampled interpolant for Cesium from satellite attitude in ENU
              // Need to apply static HPR offset rotation to ecef to body rotations to be compliant with Cesium ENU
              // The ECEF to ENU offset here only consistently works with CAD models, different offsets are needed
              // for other entities.
              // tldr: CAD Models need a 90 deg heading offset
              new Cesium.CallbackProperty(
                (time) =>
                  Cesium.Quaternion.multiply(
                    target.quaternion.getValue(time),
                    enuOffsetQuaternion,
                    new Cesium.Quaternion()
                  ),
                false
              )
            : undefined
        }
      >
        <LabelGraphics
          text={target.name}
          show={!target.isModel && showLabels}
          scale={labelTextScale}
          fillColor={config.colors.primary}
          pixelOffset={Cesium.Cartesian2.fromElements(
            // Offset is further away the bigger the cad model gets
            20 * Math.sqrt((cadScaleFactor || 1) / (target.cadScaleFactor || 1)),
            20 * Math.sqrt((cadScaleFactor || 1) / (target.cadScaleFactor || 1)),
            new Cesium.Cartesian2()
          )}
        />
      </Entity>
    );
  }, [
    target,
    cadScaleFactor,
    cadScaleMultiplier,
    cadSignedUrl,
    config,
    data.startTime,
    data.stopTime,
    keyPrefix,
    labelTextScale,
    orbitTailFactor,
    perigeeAlt,
    satelliteEntityRef,
    scaleToRealSize,
    showLabels,
    showModels,
    showOrbitTails,
    pathStep,
  ]);

  const commLinks = useMemo(() => {
    const agentNametoIndex = data.targets.reduce((acc, x, i) => ({ ...acc, [x.name]: i }), {});
    return (
      <Entity
        key={`${keyPrefix}LoS_${target.name}`}
        availability={
          new Cesium.TimeIntervalCollection([
            new Cesium.TimeInterval({
              start: data.startTime,
              stop: data.stopTime,
            }),
          ])
        }
        show={
          target.type === 'CelestialTarget'
            ? viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
              Cesium.SceneMode.SCENE2D
            : true
        }
      >
        {target.targetInfo &&
          Object.keys(target.targetInfo).map((x) => {
            if (!target.targetInfo[x]) return null; // REF: canLink backwards compatibility
            // TODO: support data link lines for Celestial Agents
            if (!agentNametoIndex[target.targetInfo[x].targetAgent]) return null; // avoid trying to draw links to agents (Celestial Agents) not included in Cesium targets data
            return (
              <Entity
                key={target.name + ' to ' + target.targetInfo[x].targetAgent}
                availability={
                  new Cesium.TimeIntervalCollection([
                    new Cesium.TimeInterval({
                      start: data.startTime,
                      stop: data.stopTime,
                    }),
                  ])
                }
              >
                <PolylineGraphics
                  positions={
                    new Cesium.PositionPropertyArray([
                      target.position3D,
                      data.targets[agentNametoIndex[target.targetInfo[x].targetAgent]].position3D,
                    ])
                  }
                  show={showComms && target.targetInfo[x].canLink}
                  width={5}
                  material={
                    new Cesium.StripeMaterialProperty({
                      evenColor: target.targetInfo[x].linkColor,
                      oddColor: target.targetInfo[x].fadeColor,
                      repeat: new Cesium.CallbackProperty((time) => {
                        return target.targetInfo[x].activeLink.getValue(time) === 1 ||
                          target.targetInfo[x].activeLink.getValue(time) === 2
                          ? 2
                          : 1;
                      }, false),
                      offset: new Cesium.CallbackProperty((time) => {
                        const activity = target.targetInfo[x].activeLink.getValue(time);
                        const offset = activity
                          ? (viewerRef.current.cesiumElement._clockViewModel.systemTime
                              .secondsOfDay *
                              2) %
                            1
                          : 0;
                        return activity === 2 ? 1 - offset : activity === 3 ? 0 : offset;
                      }, false),
                      orientation: Cesium.StripeOrientation.VERTICAL,
                    })
                  }
                  arcType={Cesium.ArcType.NONE}
                />
              </Entity>
            );
          })}
      </Entity>
    );
  }, [target, data.targets, data.stopTime, data.startTime, showComms, viewerRef, keyPrefix]);

  const waypoints = useMemo(() => {
    const pinBuilder = new Cesium.PinBuilder();
    return (
      target.type === 'TerrestrialTarget' && (
        <Entity
          key={`${keyPrefix}WP_${target.name}`}
          availability={
            new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                start: data.startTime,
                stop: data.stopTime,
              }),
            ])
          }
          show={true}
        >
          {target.waypoints.map(({ llaDeg: p }, i) => {
            return (
              <Entity
                key={`${keyPrefix}WP_${i}_${target.name}`}
                availability={
                  new Cesium.TimeIntervalCollection([
                    new Cesium.TimeInterval({
                      start: data.startTime,
                      stop: data.stopTime,
                    }),
                  ])
                }
                position={Cesium.Cartesian3.fromDegrees(p[1], p[0], p[2] * 1000)}
                point={{
                  pixelSize: 5,
                  color: target.isModel ? Cesium.Color.LIMEGREEN : config.colors.secondary,
                  show: true,
                }}
                show={true}
                name={`${target.name}: Waypoint ${i}`}
              >
                <BillboardGraphics
                  image={pinBuilder
                    .fromText(
                      i,
                      target.isModel ? Cesium.Color.LIMEGREEN : config.colors.secondary,
                      48
                    )
                    .toDataURL()}
                  verticalOrigin={Cesium.VerticalOrigin.BOTTOM}
                  scaleByDistance={new Cesium.NearFarScalar(100, 5, 1000000, 0.5)}
                  show={
                    new Cesium.CallbackProperty((time) => {
                      if (i < target.currentWaypoint.getValue(time)) {
                        return showWayPoints[0];
                      } else if (
                        i === target.currentWaypoint.getValue(time) ||
                        i - 1 === target.currentWaypoint.getValue(time)
                      ) {
                        return showWayPoints[1];
                      } else {
                        return showWayPoints[2];
                      }
                    }, false)
                  }
                />
                {i < target.waypoints.length && (
                  <PolylineGraphics
                    positions={
                      new Cesium.CallbackProperty((time) => {
                        return target.currentWaypoint.getValue(time) === i ||
                          target.currentWaypoint.getValue(time) === i - 1
                          ? [
                              target.position3D.getValue(time),
                              Cesium.Cartesian3.fromDegrees(p[1], p[0], p[2] * 1000),
                            ]
                          : target.currentWaypoint.getValue(time) > i
                          ? [
                              Cesium.Cartesian3.fromDegrees(p[1], p[0], p[2] * 1000),
                              Cesium.Cartesian3.fromDegrees(
                                target.waypoints[i + 1].llaDeg[1],
                                target.waypoints[i + 1].llaDeg[0],
                                target.waypoints[i + 1].llaDeg[2] * 1000
                              ),
                            ]
                          : i !== 0
                          ? [
                              Cesium.Cartesian3.fromDegrees(p[1], p[0], p[2] * 1000),
                              Cesium.Cartesian3.fromDegrees(
                                target.waypoints[i - 1].llaDeg[1],
                                target.waypoints[i - 1].llaDeg[0],
                                target.waypoints[i - 1].llaDeg[2] * 1000
                              ),
                            ]
                          : undefined;
                      }, false)
                    }
                    show={
                      new Cesium.CallbackProperty((time) => {
                        if (i < target.currentWaypoint.getValue(time)) {
                          return showWayPaths[0];
                        } else if (
                          i === target.currentWaypoint.getValue(time) ||
                          i - 1 === target.currentWaypoint.getValue(time)
                        ) {
                          return showWayPaths[1];
                        } else {
                          return showWayPaths[2];
                        }
                      }, false)
                    }
                    width={config.width}
                    material={
                      new Cesium.PolylineDashMaterialProperty({
                        color: new Cesium.CallbackProperty((time) => {
                          if (i < target.currentWaypoint.getValue(time)) {
                            return target.isModel ? Cesium.Color.LIMEGREEN : config.colors.primary;
                          } else if (i === target.currentWaypoint.getValue(time)) {
                            return target.isModel ? Cesium.Color.LIMEGREEN : config.colors.primary;
                          } else {
                            return config.colors.PathRemaining;
                          }
                        }, false),
                        gapColor: new Cesium.CallbackProperty((time) => {
                          if (i < target.currentWaypoint.getValue(time)) {
                            return target.isModel ? Cesium.Color.LIMEGREEN : config.colors.primary;
                          } else {
                            return Cesium.Color.TRANSPARENT;
                          }
                        }, false),
                        dashLength: 16.0,
                        dashPattern: 255.0,
                      })
                    }
                    arcType={Cesium.ArcType.GEODESIC}
                  />
                )}
              </Entity>
            );
          })}
        </Entity>
      )
    );
  }, [target, showWayPaths, showWayPoints, config, data.stopTime, data.startTime, keyPrefix]);

  const sensorDistance = useMemo(() => {
    return new Cesium.CallbackProperty((time) => {
      return Math.min(
        perigeeAltScaleCap * 1000 * cadScalar,
        cadScalar *
          Cesium.Cartesian3.magnitude(
            Cesium.Cartesian3.subtract(
              target.position3D.getValue(time),
              Cesium.Ellipsoid.WGS84.scaleToGeodeticSurface(target.position3D.getValue(time)),
              new Cesium.Cartesian3()
            )
          )
      );
    }, false);
  }, [target, cadScalar]);

  const bfVectorOffsets = useMemo(() => {
    return target.bfVectors?.map((bfVector) => {
      const cappedPerigeeAlt = Math.min(perigeeAlt, perigeeAltScaleCap);
      return new Cesium.CallbackProperty((time) => {
        const positionOffset = Cesium.Matrix3.multiplyByVector(
          new Cesium.Matrix3.fromQuaternion(target.quaternion.getValue(time), new Cesium.Matrix3()),
          Cesium.Cartesian3.fromArray(bfVector.unitVector),
          new Cesium.Cartesian3()
        );
        return Cesium.Cartesian3.multiplyByScalar(
          positionOffset,
          (cappedPerigeeAlt <= cesiumScaleAltitude
            ? 1000 * cesiumBfVectorScaleDown * cappedPerigeeAlt
            : (cesiumScaleLinearSlope * cappedPerigeeAlt + cesiumScaleLinearIntercept) *
              cesiumBfVectorScaleUp) * cadScalar,
          new Cesium.Cartesian3()
        );
      }, false);
    });
  }, [cadScalar, perigeeAlt, target.bfVectors, target.quaternion]);

  const bfVectors = useMemo(() => {
    return (
      target.bfVectors &&
      target.bfVectors.map((bfVector, vectorIndex) => {
        const combinedMultiplier = Math.sqrt(
          Math.max(0.3, cadScaleMultiplier) * labelScaleMultiplier * fovScaleMultiplier
        );
        return (
          <>
            <Entity
              key={bfVector.name}
              name={`Body Frame Vector: ${bfVector.name}`}
              availability={
                new Cesium.TimeIntervalCollection([
                  new Cesium.TimeInterval({
                    start: data.startTime,
                    stop: data.stopTime,
                  }),
                ])
              }
              position={
                new Cesium.CallbackProperty((time) => {
                  const out = Cesium.Cartesian3.add(
                    target.position3D.getValue(time),
                    bfVectorOffsets[vectorIndex].getValue(time),
                    new Cesium.Cartesian3()
                  );
                  return out;
                }, false)
              }
              show={
                viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
                  Cesium.SceneMode.SCENE2D && entityFilter[bfVector.name]
              }
            >
              <PolylineGraphics
                positions={
                  new Cesium.CallbackProperty((time) => {
                    const out = Cesium.Cartesian3.add(
                      target.position3D.getValue(time),
                      bfVectorOffsets[vectorIndex].getValue(time),
                      new Cesium.Cartesian3()
                    );
                    return [target.position3D.getValue(time), out];
                  }, false)
                  // new Cesium.PositionPropertyArray([target.position3D, target.position3D])
                }
                distanceDisplayCondition={{ near: 0, far: 50000000 }}
                width={cesiumBfVectorLineWidth}
                material={new Cesium.PolylineArrowMaterialProperty(cesiumBfVectorColor)}
                arcType={Cesium.ArcType.NONE}
              />
            </Entity>
            <Entity
              key={bfVector.name + 'Label'}
              name={bfVector.name}
              availability={
                new Cesium.TimeIntervalCollection([
                  new Cesium.TimeInterval({
                    start: data.startTime,
                    stop: data.stopTime,
                  }),
                ])
              }
              position={
                new Cesium.CallbackProperty((time) => {
                  return Cesium.Cartesian3.add(
                    target.position3D.getValue(time),
                    Cesium.Cartesian3.multiplyByScalar(
                      bfVectorOffsets[vectorIndex].getValue(time),
                      cesiumBfVectorLabelOffsetScale,
                      new Cesium.Cartesian3()
                    ),
                    new Cesium.Cartesian3()
                  );
                }, false)
              }
              show={
                viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
                  Cesium.SceneMode.SCENE2D && entityFilter[bfVector.name]
              }
            >
              <LabelGraphics
                text={bfVector.name}
                distanceDisplayCondition={{ near: 0, far: 50000000 }}
                scale={combinedMultiplier}
                fillColor={cesiumBfVectorColor}
                scaleByDistance={
                  new Cesium.NearFarScalar(
                    6378 * 1000,
                    cesiumBfVectorLabelTextScale,
                    semiMajor * 1000 * 20,
                    cesiumBfVectorLabelTextScale / 10
                  )
                }
              />
            </Entity>
          </>
        );
      })
    );
  }, [
    target.bfVectors,
    target.position3D,
    bfVectorOffsets,
    cadScaleMultiplier,
    data.startTime,
    data.stopTime,
    entityFilter,
    fovScaleMultiplier,
    labelScaleMultiplier,
    semiMajor,
    viewerRef,
  ]);

  const fovs = useMemo(() => {
    return (
      target.fovs &&
      target.fovs.map((field) => {
        const commonEntityProps = {
          key: field.name,
          name: `FoV: ${field.name}`,
          position:
            field.type === 'CircularFieldOfView'
              ? new Cesium.CallbackProperty((time) => {
                  const offsetVector = Cesium.Matrix3.multiplyByVector(
                    new Cesium.Matrix3.fromQuaternion(
                      field.quaternion.getValue(time),
                      new Cesium.Matrix3()
                    ),
                    new Cesium.Cartesian3(0, 0, -sensorDistance.getValue(time) / 2),
                    new Cesium.Cartesian3()
                  );
                  return Cesium.Cartesian3.add(
                    target.position3D.getValue(time),
                    offsetVector,
                    new Cesium.Cartesian3()
                  );
                }, false)
              : target.position3D,
          orientation: field.quaternion,
        };
        const commonGraphicProps = {
          material: Cesium.Color.PURPLE.withAlpha(0.25),
          outline: false,
        };

        if (
          field.type === 'RectangularFieldOfView' &&
          field.heightHalfAngle.deg < 90 &&
          field.widthHalfAngle.deg < 90
        ) {
          return (
            <Entity {...commonEntityProps} key={field.name} show={entityFilter[field.name]}>
              <EllipsoidGraphics
                {...commonGraphicProps}
                // #TODO: May want to remove dynamically determined radii every timestep, potentially user defined length
                radii={
                  new Cesium.CallbackProperty((time) => {
                    return new Cesium.Cartesian3(
                      sensorDistance.getValue(time),
                      sensorDistance.getValue(time),
                      sensorDistance.getValue(time)
                    );
                  }, false)
                }
                innerRadii={new Cesium.Cartesian3(10.0, 10.0, 10.0)}
                stackPartitions={3}
                slicePartitions={3}
                subdivisions={3}
                minimumClock={((90 - field.heightHalfAngle.deg) * Math.PI) / 180}
                maximumClock={((90 + field.heightHalfAngle.deg) * Math.PI) / 180}
                minimumCone={((90 - field.widthHalfAngle.deg) * Math.PI) / 180}
                maximumCone={((90 + field.widthHalfAngle.deg) * Math.PI) / 180}
                distanceDisplayCondition={{ near: 0, far: 50000000 }}
              />
            </Entity>
          );
        } else if (field.type === 'CircularFieldOfView' && field.halfAngle.deg < 90) {
          return (
            <Entity {...commonEntityProps} key={field.name} show={entityFilter[field.name]}>
              <CylinderGraphics
                {...commonGraphicProps}
                // #TODO: May want to remove dynamically determined length every timestep, potentially user defined length
                length={sensorDistance}
                heightReference={Cesium.HeightReference.CLAMPED_TO_GROUND}
                bottomRadius={
                  new Cesium.CallbackProperty((time) => {
                    return field.radius.getValue(time) * sensorDistance.getValue(time);
                  }, false)
                }
                topRadius={0}
                distanceDisplayCondition={{ near: 0, far: 50000000 }}
              />
            </Entity>
          );
        }
        return null;
      })
    );
  }, [target.fovs, target.position3D, sensorDistance, entityFilter]);

  // REF: sunTrackingVariables
  // target.sunTrackingSurfaces &&
  //   target.sunTrackingSurfaces.map((surface) => {
  //     return [
  //       <Entity
  //         key={surface.name}
  //         name={`Surface: ${surface.name}`}
  //         availability={
  //           new Cesium.TimeIntervalCollection([
  //             new Cesium.TimeInterval({
  //               start: data.startTime,
  //               stop: data.stopTime,
  //             }),
  //           ])
  //         }
  //         position={surface.originPoint}
  //         show={
  //           viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
  //           Cesium.SceneMode.SCENE2D
  //         }
  //       >
  //         <PolylineGraphics
  //           positions={new Cesium.PositionPropertyArray([surface.originPoint, surface.endPoint])}
  //           width={cesiumBfVectorLineWidth}
  //           material={new Cesium.PolylineArrowMaterialProperty(cesiumSurfaceVectorColor)}
  //           arcType={Cesium.ArcType.NONE}
  //         />
  //       </Entity>,
  //       <Entity
  //         key={surface.name + 'Label'}
  //         name={surface.name}
  //         availability={
  //           new Cesium.TimeIntervalCollection([
  //             new Cesium.TimeInterval({
  //               start: data.startTime,
  //               stop: data.stopTime,
  //             }),
  //           ])
  //         }
  //         position={surface.labelPoint}
  //         show={
  //           viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
  //           Cesium.SceneMode.SCENE2D
  //         }
  //       >
  //         <LabelGraphics
  //           text={surface.name}
  //           fillColor={cesiumSurfaceVectorColor}
  //           scaleByDistance={
  //             new Cesium.NearFarScalar(
  //               6378 * 1000,
  //               cesiumBfVectorLabelTextScale,
  //               data[0].orbitalElements.a * 1000 * 20,
  //               cesiumBfVectorLabelTextScale / 10
  //             )
  //           }
  //         />
  //       </Entity>,
  //     ];
  //   }),

  return [targetEntity, commLinks, waypoints, bfVectors, fovs].flat();
};

export default TargetEntity;
