import { ISpacecraftDialogConfig } from 'components/general/types';
import { useActiveEntities } from 'hooks';
import { ReactNode, createContext, useCallback, useMemo, useState } from 'react';
import { TSubsystemCategoriesKeys } from 'utils/vable';

interface ISpacecraftContext {
  spacecraftDialogConfig: ISpacecraftDialogConfig;
  setSpacecraftDialogConfig: (
    _: ISpacecraftDialogConfig | ((prev: ISpacecraftDialogConfig) => ISpacecraftDialogConfig)
  ) => void;
  closeSpacecraftDialog: () => void;
  SpacecraftTabs: {
    CAD_MODEL: number;
    REFERENCE_VECTORS: number;
    GEOMETRY: number;
  } & {
    [key in TSubsystemCategoriesKeys]?: number;
  };
}

interface IProps {
  children: ReactNode;
}

const defaultState: ISpacecraftContext = {
  spacecraftDialogConfig: {
    open: false,
    tabNumber: 0,
  },
  setSpacecraftDialogConfig: (
    _: ISpacecraftDialogConfig | ((prev: ISpacecraftDialogConfig) => ISpacecraftDialogConfig)
  ) => undefined,
  closeSpacecraftDialog: () => undefined,
  SpacecraftTabs: {
    CAD_MODEL: 0,
    REFERENCE_VECTORS: 1,
    GEOMETRY: 2,
  },
};

/**
 * Provides utils for using the spacecraft dialog from anywhere.
 * - Includes an object to allow code to reference names of tabs, not the specific tab numbers
 * - Includes config to control the dialog from anywhere,
 *
 * Subscribing to this context implicitly subscribes a component to:
 * - ActiveBranchContext: provides the subsystems to list the tabs
 */
export const SpacecraftContext = createContext<ISpacecraftContext>(defaultState);

const SpacecraftProvider = (props: IProps) => {
  const { children } = props;

  const { subsystems } = useActiveEntities();

  // Dictionary mapping tab label to tab number
  // e.g. SpacecraftTabs.GEOMETRY = 2 means Geometry is the 2nd panel in the spacecraft dialog
  const SpacecraftTabs = useMemo(() => {
    const res: ISpacecraftContext['SpacecraftTabs'] = {
      CAD_MODEL: 0,
      REFERENCE_VECTORS: 1,
      GEOMETRY: 2,
    };
    const topPanelsLength = Object.keys(res).length + 1;
    subsystems.forEach((s, i) => (res[s.category] = topPanelsLength + i));
    return res;
  }, [subsystems]);

  const [spacecraftDialogConfig, setSpacecraftDialogConfig] = useState({
    open: false,
    tabNumber: SpacecraftTabs.CAD_MODEL,
  });

  const closeSpacecraftDialog = useCallback(() => {
    setSpacecraftDialogConfig((prev) => ({ ...prev, open: false }));
  }, [setSpacecraftDialogConfig]);

  const contextValue: ISpacecraftContext = useMemo(() => {
    return {
      spacecraftDialogConfig,
      setSpacecraftDialogConfig,
      closeSpacecraftDialog,
      SpacecraftTabs,
    };
  }, [spacecraftDialogConfig, setSpacecraftDialogConfig, closeSpacecraftDialog, SpacecraftTabs]);

  return <SpacecraftContext.Provider value={contextValue}>{children}</SpacecraftContext.Provider>;
};

export default SpacecraftProvider;
