import { makeStyles } from '@material-ui/core/styles';

export const inputWidth = 275;

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.shadows[5],
  },

  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  inputs: {
    width: inputWidth,
  },

  largeWidth: {
    width: inputWidth * 1.3,
  },

  inputGroup: {
    margin: '20px 0',
    marginTop: 0,
  },

  indent: {
    borderLeft: '2px solid ' + theme.palette.background.lightest,
    paddingLeft: 8,
  },

  info: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.light,
    borderRadius: 3,
    maxWidth: 500,
    padding: 20,
  },

  buttonGroup: {
    alignSelf: 'flex-end',

    '& button': {
      marginLeft: '10px !important',
    },
  },

  split: {
    display: 'flex',
  },

  table: {
    maxWidth: 440,
  },

  subHeader: {
    fontSize: '0.90rem',
    marginBottom: 10,
  },
  subGroup: {
    marginBottom: 40,
  },
  swapLeft: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  swapRight: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  targetGroupWidget: {
    paddingTop: 30,
  },
  attitudeDisplay: {
    textAlign: 'center',
  },
  tleInput: {
    '& > textarea': {
      whiteSpace: 'pre',
    },
  },
  cadFileUploaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cadFileUploaderSubText: {
    padding: '1em 0em',
  },
  cadFileUploaderButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cadFileName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '60ch',
    whiteSpace: 'nowrap',
  },
  babylonLoader: {
    display: 'flex',
    minHeight: '30px',
  },
  preTableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 440,
    marginBottom: -5,
  },
  modelSizeTable: {
    marginTop: '6px',
    marginBottom: '10px',
    backgroundColor: theme.palette.background.light,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& tr': {
      margin: '2px 2px',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    '& th': {
      padding: '2px 6px',
      textAlign: 'center',
    },
    '& td': {
      padding: '2px 4px',
      backgroundColor: theme.palette.background.main,
      textAlign: 'center',
      flex: '1',
    },
  },
}));

export default useStyles;
