import {
  IGenericObject,
  IPlotSpec,
  TChartSpec,
  TPlotDef,
  TVariableSpec,
  TWidgetDef,
} from 'components/general/types';
import { TCompiledModel } from 'middleware/SatelliteApi/template';
import { TUnit } from 'utils/units';

type TKeyTup = [string, IGenericObject];

// WARN: Typing in this file is very loose as of today.

// In the future, can use this to format data: https://www.npmjs.com/package/sprintf-js

// Next steps:
// - Document this well
// - Add support for non-list, string key, legend, and op
// - Improve typing
// - Add developer errors for:
//  - len(legend) === len(keys) === len(ops)
//  - Missing legend, etc.
//  - Unsuccesful prop injection

const ambiguousToIndexable = (ref: IGenericObject) => (ref?.all ? ref.all() : ref);
const eachToPrefix = (def: IGenericObject, ref: IGenericObject) => {
  let each: TKeyTup[] = [['', ref]];
  if (def.each) {
    for (const k of def.each.split('.')) {
      ref = ambiguousToIndexable(ref);
      if (k.charAt(0) === '$') {
        ref = ref[k.slice(1)];
      } else {
        ref = ref[k];
      }
    }
    each = ambiguousToIndexable(ref).map((e: IGenericObject, i: number) => [
      `${def.each}.$${i}.`,
      e,
    ]);
  }
  return each;
};
const injectAttributes = (s: string, o: IGenericObject): string => {
  for (const k of Object.keys(o)) {
    if (s.includes(`{${k}}`)) s = s.replace(`{${k}}`, o[k]);
  }
  return s;
};

const expandKeys = (key: string, model: TCompiledModel) => {
  if (key.includes('root')) {
    const after = key.split('root.');
    key = after[after.length - 1];
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let ref: any = model;
  let endOfString = 0;
  const keys: [string, IGenericObject][] = [];
  for (const k of key.split('.')) {
    endOfString += k.length + 1;
    const restOfString = (endOfString > key.length ? '' : '.') + key.slice(endOfString);
    ref = ambiguousToIndexable(ref);
    if (!ref) break;
    if (k.charAt(0) === '$') {
      if (k === '$each') {
        for (const block of ref) {
          keys.push([block.id + restOfString, block]);
        }
        return keys;
      } else {
        ref = ref[k.slice(1)];
        if (ref && !restOfString.includes('$')) {
          keys.push([ref.id + restOfString, ref]);
          return keys;
        }
        continue;
      }
    }
    if (ref.id) {
      if (Array.isArray(ref[k])) {
        if (!ref[k].length) {
          // Empty relationship
          return keys;
        } else if (ref[k][0].id) {
          // Non-empty relationship, expand in next iteration
          ref = ref[k];
          continue;
        } else {
          // At the containing block
          keys.push([ref.id + '.' + k + restOfString, ref]);
          return keys;
        }
        // Else keep going
      } else if (ref.rels?.has(k) && !ref[k]) {
        // Empty relationship
        return keys;
      } else if (!ref[k] || !ref[k].id) {
        // At the containing block
        keys.push([ref.id + '.' + k + restOfString, ref]);
        return keys;
      }
    }
    ref = ref[k];
  }
  keys.push([key, model]);
  return keys;
};

type TRider = { level: number };

const expandVariables = (
  variables: TVariableSpec[],
  tup: TKeyTup,
  level = 0
): [TKeyTup, TVariableSpec, TRider][] => {
  return variables
    ? variables.flatMap((v) => {
        const each = eachToPrefix(v, tup[1]);
        return each.flatMap((innerTup) => {
          const newTup: TKeyTup = [tup[0] + innerTup[0], innerTup[1]];
          const result: [TKeyTup, TVariableSpec, TRider][] = [[newTup, v, { level }]];
          if (v.variables) {
            return result.concat(expandVariables(v.variables, newTup, level + 1));
          }
          return result;
        });
      })
    : [];
};

const genPlots = (
  plotSpec: IPlotSpec,
  [parentBase, initialRef]: TKeyTup,
  model: TCompiledModel
): TPlotDef[] => {
  const each = eachToPrefix(plotSpec, initialRef);
  return each.flatMap((tup) => {
    const _variables = expandVariables(plotSpec.variables, [parentBase + tup[0], tup[1]]);

    // Validation
    let rightCounts = 0;
    for (const v of _variables) {
      const [, varSpec] = v;
      if ('right' in varSpec && varSpec.right) {
        rightCounts += varSpec.keys.length;
        if (varSpec.keys.some((s) => s.includes('$each.'))) {
          // Force error below
          rightCounts = 100;
          break;
        }
      }
    }
    if (rightCounts > 1) throw Error('Only a single right-axis variable is currently supported');

    const variables = _variables.flatMap(([[base, p], varSpec, rider]) => {
      if (!('keys' in varSpec && varSpec.keys)) {
        return varSpec.legend.flatMap((k) => ({
          name: injectAttributes(k, p),
          level: rider.level,
        }));
      }
      return varSpec.keys.flatMap((k, i) => {
        return expandKeys(base + k, model).map((e) => ({
          key: injectAttributes(e[0], e[1]),
          name: varSpec.legend && varSpec.legend[i] && injectAttributes(varSpec.legend[i], e[1]),
          right: varSpec.right,
          op: varSpec.ops && varSpec.ops[i],
          level: rider.level,
          unit: varSpec.unit || plotSpec.unit,
        }));
      });
    });

    if (!(variables.length || plotSpec.type === 'conops')) return []; // Used in conjunction with flatMap to filter out empty plots
    return {
      type: plotSpec.type,
      step: plotSpec.step,
      title: plotSpec.name && injectAttributes(plotSpec.name, tup[1]),
      variables,
      unit: plotSpec.unit as TUnit,
      unitRight: plotSpec.unitRight as TUnit,
      label: plotSpec.label,
      labelRight: plotSpec.labelRight,
    };
  });
};

export const interpret = (spec: TChartSpec, model: TCompiledModel): TWidgetDef[] => {
  return spec.flatMap((widgetDef) => {
    const each = eachToPrefix(widgetDef, model);
    return each.flatMap((tup) => {
      const title = injectAttributes(widgetDef.title, tup[1]);
      const plots = widgetDef.plots?.flatMap((plot) => genPlots(plot, tup, model));
      if (!plots || !plots.length) return [];
      return {
        title,
        subtitle: widgetDef.subtitle,
        plots,
      };
    });
  });
};
