import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dropzone from 'components/general/Dropzone';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledTimePicker from 'components/general/inputs/LabeledPickers/LabeledTimePicker';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IGenericObject, INestedSelectOption, ISelectOption } from 'components/general/types';
import { IAgent } from 'components/general/types/agent'; // eslint-disable-line @typescript-eslint/no-unused-vars
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import _ from 'lodash';
import React, { useCallback } from 'react';
import { OrbitVables, TInitialRefOrbitTypes, TOrbitalDynamics } from 'utils/vable';

interface IForm {
  name: string;
  kinematics: {
    type: string;
    fileName?: string;
    objectCode?: number;
    initialStateDefType: ISelectOption | '';
    initialStateDefParams: IGenericObject & { initialRefOrbit: ISelectOption | '' };
  };
}

const defaultValues: IForm = {
  name: '',
  kinematics: {
    type: '',
    fileName: '',
    objectCode: undefined,
    initialStateDefType: '',
    initialStateDefParams: {
      a: '',
      e: '',
      inc: '',
      nu: '',
      om: '',
      raan: '',
      alt: '',
      lon: '',
      altPerigee: '',
      mltAscNode: null,
      initialRefOrbit: '',
      stateEci: ['', '', '', '', '', ''],
    },
  },
};

export const ephemFileToType = {
  '.bsp': 'SpkEphemeris',
  '.e': 'StkEphemeris',
};

// --------------------------------------------------------------------------
// Define input fields
// --------------------------------------------------------------------------
interface ISpec {
  name: string;
  label: string;
  units?: string;
}

const altitudeField: ISpec = {
  name: 'alt',
  label: 'Altitude',
  units: 'km',
};
const raanField: ISpec = {
  name: 'raan',
  label: 'RAAN',
  units: 'deg',
};
const argOfLatField: ISpec = {
  name: 'nu',
  label: 'Argument of Latitude',
  units: 'deg',
};
const trueLonField: ISpec = {
  ...argOfLatField,
  label: 'True Longitude',
};
const trueAnomalyField: ISpec = {
  ...argOfLatField,
  label: 'True Anomaly',
};
const longitudeField: ISpec = {
  name: 'lon',
  label: 'Longitude (East)',
  units: 'deg',
};
const altPerigeeField: ISpec = {
  name: 'altPerigee',
  label: 'Perigee Altitude',
  units: 'km',
};
const trueLonOfPerigeeField: ISpec = {
  name: 'om',
  label: 'True Longitude of Perigee',
  units: 'deg',
};
const argOfPerigeeField: ISpec = {
  ...trueLonOfPerigeeField,
  label: 'Argument of Perigee',
};
const semimajorAxisField: ISpec = {
  name: 'a',
  label: 'Semimajor Axis',
  units: 'km',
};
const inclinationField: ISpec = {
  name: 'inc',
  label: 'Inclination',
  units: 'deg',
};
const eccentricityField: ISpec = {
  name: 'e',
  label: 'Eccentricity',
};

interface IProps {
  // Use 'any' type because it depends on where OrbitForm is called from
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entityForm: ReturnType<typeof useEntityForm<IAgent, any>>;
  options: { [key: string]: (ISelectOption | INestedSelectOption)[] };
  orbitProps: {
    setEphemFile: React.Dispatch<React.SetStateAction<File | null>>;
    ephemFile: File | null;
    ephemFileName: string | undefined;
    ephemFileExt: keyof typeof ephemFileToType | undefined;
    orbitalDynamics: ISelectOption;
    setOrbitalDynamics: React.Dispatch<React.SetStateAction<ISelectOption>>;
  };
}
const OrbitForm = ({ entityForm, options, orbitProps }: IProps) => {
  const {
    setEphemFile,
    ephemFile,
    ephemFileName,
    ephemFileExt,
    orbitalDynamics,
    setOrbitalDynamics,
  } = orbitProps;

  // Set up styles
  const classes = useStyles();

  const { formik } = entityForm as ReturnType<typeof useEntityForm<IAgent, IForm>>;
  const { getFieldProps, values, setFieldValue } = formik;
  const { kinematics } = values;
  const { initialStateDefType, initialStateDefParams } = kinematics;

  const renderInputField = useCallback(
    (spec: ISpec, value?: number) => {
      // Conditionally use setValue to manually update the value and rerender the input
      if (value !== undefined && initialStateDefParams[spec.name] !== value) {
        setFieldValue(`kinematics.initialStateDefParams.${spec.name}`, value);
      }

      return (
        <LabeledInput
          {...getFieldProps(`kinematics.initialStateDefParams.${spec.name}`)}
          key={spec.name}
          type="number"
          endAdornment={spec.units && <InputAdornment position="end">{spec.units}</InputAdornment>}
          label={spec.label}
          readOnly={value !== undefined}
        />
      );
    },
    [initialStateDefParams, setFieldValue, getFieldProps]
  );

  const renderInitialStateDefParamFields = useCallback(
    (initialRefOrbit: TInitialRefOrbitTypes) => {
      const fieldSpecs: { [key in TInitialRefOrbitTypes]: ISpec[] } = {
        POLAR_CIRC: [altitudeField, raanField, argOfLatField],
        EQUATORIAL_CIRC: [altitudeField, trueLonField],
        SUN_SYNC_CIRC: [altitudeField, trueAnomalyField],
        GEOSTAT: [longitudeField],
        GEOSTAT_TRANSFER: [altPerigeeField, trueLonOfPerigeeField, trueAnomalyField],
        ISS: [raanField, trueAnomalyField],
      };
      const fields = fieldSpecs[initialRefOrbit].map((spec) => renderInputField(spec));
      if (initialRefOrbit === 'SUN_SYNC_CIRC') {
        fields.push(
          <LabeledTimePicker
            label="Mean Local Time at the Ascending Node"
            {...getFieldProps('kinematics.initialStateDefParams.mltAscNode')}
            key="kinematics.initialStateDefParams.mltAscNode"
          />
        );
      }
      return fields;
    },
    [getFieldProps, renderInputField]
  );

  return (
    <>
      <div>
        <LabeledSelect
          label="Orbital Dynamics"
          options={OrbitVables.OrbitalDynamics.options}
          onChange={(v: { value: TOrbitalDynamics; label: string }) => {
            if (v.value === OrbitVables.OrbitalDynamics.PROPAGATED.value) {
              if (!('initialStateDefParams' in values.kinematics)) {
                setFieldValue(
                  'kinematics.initialStateDefParams',
                  _.cloneDeep(defaultValues.kinematics.initialStateDefParams)
                );
              }
            }

            setOrbitalDynamics(v);
          }}
          value={orbitalDynamics}
        />
      </div>
      {orbitalDynamics.value === OrbitVables.OrbitalDynamics.EPHEMERIS.value && (
        <div>
          <Dropzone
            onDropAccepted={(files) => setEphemFile(files[0])}
            accept={{ 'model/vnd.valve.source.compiled-map': Object.keys(ephemFileToType) }}
            maxSizeMiB={20}
          />

          {ephemFileName && (
            <div>
              {ephemFileName}
              {ephemFile && (
                <IconButton>
                  <CloseIcon onClick={() => setEphemFile(null)} />
                </IconButton>
              )}
            </div>
          )}
          {ephemFileExt === '.bsp' && (
            <LabeledInput
              type="number"
              label="Object Code (number)"
              {...getFieldProps('kinematics.objectCode')}
            />
          )}
        </div>
      )}
      {orbitalDynamics.value === OrbitVables.OrbitalDynamics.PROPAGATED.value && (
        <>
          <div className={classes.inputGroup}>
            <LabeledSelect
              label="Define Initial Orbit Using:"
              options={options['kinematics.initialStateDefType']}
              {...getFieldProps('kinematics.initialStateDefType')}
              formikOnChange={(val: ISelectOption) => {
                if (
                  typeof initialStateDefType === 'object' &&
                  val.value !== initialStateDefType.value
                ) {
                  setFieldValue(
                    'kinematics.initialStateDefParams',
                    defaultValues.kinematics.initialStateDefParams
                  );
                }
              }}
            />
            {initialStateDefType === OrbitVables.InitialStateDefType.ECI_STATE && // --------- POSITION & VELOCITY
              [
                {
                  name: 'kinematics.initialStateDefParams.stateEci.0',
                  label: 'x-Position',
                  units: 'km',
                },
                {
                  name: 'kinematics.initialStateDefParams.stateEci.1',
                  label: 'y-Position',
                  units: 'km',
                },
                {
                  name: 'kinematics.initialStateDefParams.stateEci.2',
                  label: 'z-Position',
                  units: 'km',
                },
                {
                  name: 'kinematics.initialStateDefParams.stateEci.3',
                  label: 'x-Velocity',
                  units: 'km/s',
                },
                {
                  name: 'kinematics.initialStateDefParams.stateEci.4',
                  label: 'y-Velocity',
                  units: 'km/s',
                },
                {
                  name: 'kinematics.initialStateDefParams.stateEci.5',
                  label: 'z-Velocity',
                  units: 'km/s',
                },
              ].map((spec: ISpec) => (
                <LabeledInput
                  {...getFieldProps(spec.name)}
                  key={spec.name}
                  type="number"
                  endAdornment={
                    spec.units && <InputAdornment position="end">{spec.units}</InputAdornment>
                  }
                  label={spec.label}
                />
              ))}
            {initialStateDefType === OrbitVables.InitialStateDefType.ORBITAL_ELEMENTS && (
              <>
                {renderInputField(semimajorAxisField)}
                {renderInputField(inclinationField)}
                {renderInputField(eccentricityField)}
                {initialStateDefParams &&
                  initialStateDefParams.e !== '' &&
                  renderInputField(
                    raanField,
                    parseFloat(initialStateDefParams.inc) === 0 ? 0 : undefined
                  )}
                {initialStateDefParams &&
                  initialStateDefParams.inc !== '' &&
                  parseFloat(initialStateDefParams.inc) !== 0 &&
                  renderInputField(
                    argOfPerigeeField,
                    parseFloat(initialStateDefParams.e) === 0 ? 0 : undefined
                  )}
                {initialStateDefParams &&
                  parseFloat(initialStateDefParams.inc) === 0 &&
                  renderInputField(
                    trueLonOfPerigeeField,
                    parseFloat(initialStateDefParams.e) === 0 ? 0 : undefined
                  )}
                {initialStateDefParams &&
                  parseFloat(initialStateDefParams.inc) === 0 &&
                  parseFloat(initialStateDefParams.e) === 0 &&
                  renderInputField(trueLonField)}
                {initialStateDefParams &&
                  initialStateDefParams.inc !== '' &&
                  parseFloat(initialStateDefParams.inc) !== 0 &&
                  parseFloat(initialStateDefParams.e) === 0 &&
                  renderInputField(argOfLatField)}
                {initialStateDefParams &&
                  parseFloat(initialStateDefParams.e) > 0 &&
                  renderInputField(trueAnomalyField)}
              </>
            )}
            {initialStateDefType === OrbitVables.InitialStateDefType.REF_ORBIT && ( // --------- REFERENCE ORBIT
              <>
                <LabeledSelect
                  label="Reference Orbit Type"
                  options={options['kinematics.initialStateDefParams.initialRefOrbit']}
                  {...getFieldProps('kinematics.initialStateDefParams.initialRefOrbit')}
                  formikOnChange={(val: ISelectOption) => {
                    if (
                      initialStateDefParams.initialRefOrbit &&
                      val.value !== initialStateDefParams.initialRefOrbit.value
                    ) {
                      setFieldValue('kinematics.initialStateDefParams', {
                        ...defaultValues.kinematics.initialStateDefParams,
                      });
                    }
                  }}
                />
                <div className={classes.indent}>
                  {initialStateDefParams &&
                    initialStateDefParams.initialRefOrbit &&
                    renderInitialStateDefParamFields(
                      initialStateDefParams.initialRefOrbit.value as TInitialRefOrbitTypes
                    )}
                </div>
              </>
            )}
            {initialStateDefType === OrbitVables.InitialStateDefType.TLE && ( // --------- TWO-LINE ELEMENT
              <LabeledInput
                {...getFieldProps('kinematics.initialStateDefParams.tle')}
                label={'Two-Line Element (TLE)'}
                multiline
                rows={4}
                className={classes.tleInput}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setFieldValue(
                    'kinematics.initialStateDefParams.tle',
                    e.target.value
                      .replace(/,+/g, '\n') // Replace commas with newlines (to support csv TLE entry)
                      .replace(/\r+/g, '') // Remove CRs
                      .replace(/^[ \t]+/gm, '') // Removing leading whitespace
                      // .replace(/[ \t]+$/gm, '') // Remove trailing whitespace #Disabled for now (MVP)
                      .replace(/\n+/g, '\n') // Replace line-separating whitespace with a single \n
                    // .replace(/[ \t]+(?=\n)/g, '') // Replace whitespace before line-separating newlines  #Disabled for now (MVP)
                  );
                }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrbitForm;
