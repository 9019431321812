/* eslint no-template-curly-in-string: 0 */
import getThermalProps from 'hooks/getThermalProps';
import * as Yup from 'yup';

const { thermalValidation } = getThermalProps();

const validation = Yup.object().shape({
  cell: Yup.object().required('Select a battery cell.'),

  numSeries: Yup.number()
    .required('Number of cells in series is required.')
    .integer('Number of cells in series should be a positive integer.')
    .min(1, 'Number of cells in series must be at least ${min}.'),

  numParallel: Yup.number()
    .required('Number of cells in parallel is required.')
    .integer('Number of cells in parallel should be a positive integer.')
    .min(1, 'Number of cells in parallel must be at least ${min}.'),

  ...thermalValidation,
});

export default validation;
