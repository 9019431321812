import clsx from 'clsx';
import StyledBasicTable from 'components/general/StyledBasicTable';
import { FallbackInlay } from 'components/general/ViewPortInlay';
import Widget from 'components/general/widgets/Widget';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { MomentContext, TimeContext } from 'providers';
import { useStream } from 'providers/DataProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { getSearchParams } from 'routes';
import { binarySearch } from 'utils/arrays';
import { direction2String } from 'utils/strings';
import { dateFormatLong, diff2Duration, mjd2Moment } from 'utils/time';
import useStyles from './styles';

const columns = [
  {
    title: 'Start Time (UTC)',
    field: 'startTime',
  },
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Duration',
    field: 'duration',
  },
];

// create a new one each time, so they don't all reference the same object and mess up the rendering
const emptyRow = () => ({ duration: '-', name: '-', startTime: '-' });

const getStartAndEnd = (series, index, activeOpModeId) => {
  let i = index;
  let j = index;
  const [t, activeOpMode] = series;

  // Find the least recent timestep that has the right activeOpMode since the last change
  while (i > 0 && activeOpMode[i] === activeOpModeId) {
    i--;
  }

  // Find the latest timestep that is the right activeOpMode before it changes again
  while (j < activeOpMode.length - 1 && activeOpMode[j] === activeOpModeId) {
    j++;
  }
  i = i < t.length - 2 && i !== 0 ? i + 1 : i;
  return [mjd2Moment(t[i]), mjd2Moment(t[j])];
};

const ModePlaybackWidget = () => {
  const classes = useStyles();

  const { time } = useContext(TimeContext);
  const { model } = useContext(MomentContext);

  const { agentId } = getSearchParams();
  const { series } = useStream(agentId, `activeOpMode`);

  const activeOpMode = model.activeOpMode;
  const activePointingMode = activeOpMode.pointingMode;

  const secondPointingLabel = useMemo(() => {
    switch (activePointingMode?.type) {
      case 'LockSpinPointingMode':
        return 'Spin Rate:';
      case 'MaxAlignPointingMode':
        return 'Max Align Direction:';
      default:
        return '';
    }
  }, [activePointingMode]);

  const secondPointingValue = useMemo(() => {
    switch (activePointingMode?.type) {
      case 'LockSpinPointingMode':
        return `${activePointingMode.spinRate.rpm} rpm`;
      case 'MaxAlignPointingMode':
        return direction2String(activePointingMode.type, activePointingMode.maxAlignVector);
      default:
        return '';
    }
  }, [activePointingMode]);

  const currentTime = mjd2Moment(time);
  const index = Math.max(0, binarySearch(series[0], time, { fuzzy: true }));

  const [startTime, endTime] = useMemo(() => {
    return getStartAndEnd(series, index, activeOpMode.id);
  }, [activeOpMode, series, index]);

  const getHistory = useCallback(
    (index) => {
      let count = 3;
      let i = index;
      let h = [];
      const [, activeModeIds] = series;
      let currentOpMode = activeModeIds[i];
      let s;
      while (count > 0 && i >= 0) {
        s = activeModeIds[i];
        if (currentOpMode !== s) {
          currentOpMode = s;
          const [start, end] = getStartAndEnd(series, i, currentOpMode);
          h.push({
            startTime: start.format(dateFormatLong),
            name: model.OperationalMode.byId(currentOpMode).name,
            duration: diff2Duration(start, end),
          });
          count--;
        }
        i--;
      }
      const emptyRowsToAdd = 3 - h.length;
      for (let i = 0; i < emptyRowsToAdd; i++) {
        h.push(emptyRow());
      }
      return h;
    },
    [series, model]
  );

  const [history, setHistory] = useState([emptyRow(), emptyRow(), emptyRow()]);

  useEffect(() => {
    const _history = getHistory(index);
    for (let i = 0; i < Math.max(_history.length, history.length); i++) {
      if (history.length !== _history.length || _history[i].startTime !== history[i].startTime) {
        setHistory(_history);
        break;
      }
    }
  }, [index]); //eslint-disable-line

  return (
    <Widget
      className={clsx(classes.modePlaybackWidget)}
      title="Spacecraft Mode"
      minWidth={300}
      collapsibleConfig
    >
      <StyledBasicTable className={classes.summary}>
        <tbody>
          <tr>
            <td>
              <h5>
                <b className={classes.subHeading}>Current Mode</b>
              </h5>
            </td>
            <td>
              <h5>{activeOpMode.name}</h5>
            </td>
          </tr>
          <tr>
            <td>
              <h6>
                <b className={classes.subHeading}>Pointing Mode</b>
              </h6>
            </td>
            <td>
              <h6>{activePointingMode?.name}</h6>
            </td>
          </tr>
          <tr>
            <td>
              <b>Locked Direction:</b>
            </td>
            <td>
              {activePointingMode &&
                direction2String(activePointingMode.type, activePointingMode.lockVector)}
            </td>
          </tr>
          <tr>
            <td>
              <b>{secondPointingLabel}</b>
            </td>
            <td>{secondPointingValue}</td>
          </tr>
          <tr>
            <td>
              <b>Start Time:</b>
            </td>
            <td>{startTime.format(dateFormatLong)} UTC</td>
          </tr>
          <tr>
            <td>
              <b>End Time:</b>
            </td>
            <td>{endTime.format(dateFormatLong)} UTC</td>
          </tr>
          <tr>
            <td>
              <b>Time Elapsed:</b>
            </td>
            <td>{currentTime < startTime ? '0 ms' : diff2Duration(startTime, currentTime)}</td>
            {/* Don't allow ^^^ to show up as negative b/c it can potentially at beginning of a sim */}
          </tr>
          <tr>
            <td>
              <b>Time Remaining:</b>
            </td>
            <td>{diff2Duration(currentTime, endTime)}</td>
          </tr>
          <tr>
            <td>
              <b>Total Duration:</b>
            </td>
            <td>{diff2Duration(startTime, endTime)}</td>
          </tr>
        </tbody>
      </StyledBasicTable>
      <h5>
        <b className={classes.subHeading}>Recent Modes</b>
      </h5>
      <WidgetTable
        className={classes.table}
        columns={columns}
        data={history}
        emptyMessage={'No prior operational modes'}
        paging={false}
        noXray
      />
    </Widget>
  );
};

const ModePlaybackWidgetWrapper = (props) => {
  const classes = useStyles();

  return (
    <ErrorBoundary
      fallback={
        <Widget
          className={clsx(classes.modePlaybackWidget)}
          title="Spacecraft Mode"
          minWidth={300}
          collapsibleConfig
        >
          <FallbackInlay
            text="This chart is under construction."
            subText="Our team is working to restore this feature."
          />
        </Widget>
      }
    >
      <ModePlaybackWidget {...props} />
    </ErrorBoundary>
  );
};

export default ModePlaybackWidgetWrapper;
