import theme from 'theme';
import StyledReactDiffViewer from '../../../general/StyledReactDiffViewer';
import { IConflict } from '../../index';
import useStyles from './styles';

export type CurrentOrIncoming = 'current' | 'incoming';

interface IProps {
  conflict: IConflict;
  i: number;
  resolutions: CurrentOrIncoming[];
  setCurrentOrIncoming: (a: CurrentOrIncoming, b: number) => void;
}

const ConflictSection = (props: IProps) => {
  const { conflict, setCurrentOrIncoming, i, resolutions } = props;
  const { incoming, current } = conflict;

  const styleIfSelected = (curOrInc: CurrentOrIncoming, i: number) => {
    return resolutions[i] === curOrInc ? { backgroundColor: theme.palette.primary.main } : {};
  };

  const classes = useStyles();

  return (
    <div className={classes.conflictSection}>
      <StyledReactDiffViewer
        leftTitle={`${current.type} (id=${conflict.block})`}
        rightTitle={`${incoming.type} (id=${conflict.block})`}
        oldValue={JSON.stringify(current, undefined, 4)}
        newValue={JSON.stringify(incoming, undefined, 4)}
      />
      <div
        className={classes.currentOverlay}
        onClick={() => setCurrentOrIncoming('current', i)}
        style={styleIfSelected('current', i)}
      />
      <div
        className={classes.incomingOverlay}
        onClick={() => setCurrentOrIncoming('incoming', i)}
        style={styleIfSelected('incoming', i)}
      />
    </div>
  );
};

export default ConflictSection;
