import { useSnackbar } from 'hooks';
import useMountStatus from 'hooks/useMountStatus';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

const useJobPolling = (params, _delay) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    Job: {
      actions: { pagedJobs },
    },
  } = SatelliteApi;

  const isMounted = useMountStatus();
  const [delay, setDelay] = useState(_delay);
  const savedCallback = useRef();
  const timeout = useRef();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setDelay(null);
      } else {
        setDelay(_delay);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [_delay]);

  // Get next page without polling
  const nextPage = useCallback(() => {
    dispatch(
      pagedJobs({
        queryParams: { ...params, page: params.page + 1 },
        failureCallback: (response) => {
          enqueueSnackbar(response?.error?.message || 'Error fetching jobs.');
        },
      })
    );
  }, [params, dispatch, enqueueSnackbar, pagedJobs]);

  const callback = useCallback(() => {
    dispatch(
      pagedJobs({
        queryParams: { ...params },
        successCallback: () => {
          clearTimeout(timeout.current);
          if (isMounted() && delay)
            timeout.current = setTimeout(() => savedCallback.current(), delay);
        },
        failureCallback: (response) => {
          enqueueSnackbar(response?.error?.message || 'Error fetching jobs.');
        },
      })
    );
  }, [params, delay, dispatch, enqueueSnackbar, isMounted, pagedJobs]);

  // When page changes, pre-fetch next page
  useEffect(() => {
    nextPage();
  }, [params.page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    clearTimeout(timeout.current);
    if (delay !== null) {
      savedCallback.current();
    }
    return () => clearTimeout(timeout.current);
  }, [delay]);
};

export default useJobPolling;
