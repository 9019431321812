import Dropzone from 'components/general/Dropzone';
import Dialog from 'components/general/dialogs/Dialog';
import { IErrorResponse, IGenericObject } from 'components/general/types';
import { useSnackbar } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import useWorkspace from 'hooks/useWorkspace';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

const UploadDialog = ({ control }: { control: TEntityDialogControl<IGenericObject> }) => {
  const { closeDialog, dialogConfig } = control;

  const { import: importRepo } = SatelliteApi.Mission.actions;
  const { getWorkspace } = SatelliteApi.Workspace.actions;

  const workspace = useWorkspace();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    closeDialog();
    setFile(null);
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    if (!file) {
      enqueueSnackbar('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('workspace', workspace.id);
    formData.append('file', file);

    dispatch(
      importRepo({
        formData,
        successCallback: () => {
          dispatch(
            getWorkspace({
              id: workspace.id,
              successCallback: () => {
                enqueueSnackbar('Imported successfully', { variant: 'success' });
                onClose();
              },
              failureCallback: () => {
                enqueueSnackbar(
                  'Imported successfully, but failed to refresh workspace. Please refresh the page.',
                  { variant: 'warning' }
                );
                onClose();
              },
            })
          );
        },
        failureCallback: (res: IErrorResponse) => {
          enqueueSnackbar(res.error.message);
          onClose();
        },
      })
    );
  };

  return (
    <Dialog
      title="Upload a Sedaro Export"
      open={dialogConfig.open}
      onClose={onClose}
      onSubmit={onSubmit}
      disableSubmit={!file}
      submitActionText="Upload"
      loading={loading}
      setLoading={setLoading}
    >
      <p>Upload a Sedaro Export zip file to workspace: "{workspace.name}"</p>
      <Dropzone
        onDropAccepted={(files) => {
          setFile(files[0]);
        }}
        accept={{ 'application/zip': ['.zip'] }}
      />
      {file && <p>File: {file.name}</p>}
    </Dialog>
  );
};

export default UploadDialog;
