import LoadingView from 'components/LoadingView';
import Nav from 'components/general/Nav';
import AnalysisControls from 'components/general/SimulationControls/AnalysisControls';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import ViewPortInlay from 'components/general/ViewPortInlay';
import { useActiveEntities, useSnackbar } from 'hooks';
import { DataContext, RangeProvider, TimeProvider } from 'providers';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Routes, { getSearchParams } from 'routes';
import AnalyzeBoards from './AnalyzeBoards';
import CustomizeMenuDialog from './CustomizeDialog';

const AgentAnalyzeView = () => {
  const { repo, branch } = useActiveEntities();
  const { staticModels, currentJob } = useContext(DataContext);
  const { agentId } = getSearchParams();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  const reroute = useCallback(() => {
    history.push(Routes.AGENT_ANALYZE_AGENT_SELECTION(branch.id));
  }, [history, branch.id]);

  if (staticModels.scenario && !staticModels.scenario?._blocksById?.[agentId]) {
    reroute();
    return <FallbackComponent />;
  }

  if (!branch.model?.ready) return <LoadingView message="Sedaro is loading..." />;

  return (
    <ViewContainer>
      <Nav
        contextName={
          staticModels.scenario && 'Analyze - ' + staticModels.scenario._blocksById[agentId].name
        }
        contextHeader={repo.name}
        control={<AnalysisControls />}
        layoutEdit={{ onEdit: () => setOpenDialog(true) }}
      ></Nav>
      <ViewPort minWidth={650}>
        {
          // If data is here, show analyze boards
          currentJob?.analyzeState?.dataState && staticModels?.agents[agentId] ? (
            <TimeProvider>
              <RangeProvider>
                <AnalyzeBoards />
              </RangeProvider>
            </TimeProvider>
          ) : (
            // Otherwise, show placeholders
            <ViewPortInlay text="Fetch a window of data to view analytics" />
          )
        }
      </ViewPort>
      <CustomizeMenuDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </ViewContainer>
  );
};

export default AgentAnalyzeView;

const FallbackComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    enqueueSnackbar('The agent no longer exists.  Please select a different agent.');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};
