import CircularProgress from 'components/general/CircularProgress';
import StyledButton from 'components/general/StyledButton';
import { SALES_EMAIL, gaEvents, hotkeys } from 'config';
import { useLatestJob, usePermissionCheck, useSelectById, useSnackbar } from 'hooks';
import useMountStatus from 'hooks/useMountStatus';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import moment from 'moment';
import { ActiveBranchContext } from 'providers';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import { getSearchParams } from 'routes';
import { precision } from 'utils/units';
import { ModuleVables, TSimulationStatus, WorkspaceVables } from 'utils/vable';
import ClipboardCopy from '../ClipboardCopy';
import StyledDivider from '../StyledDivider';
import { IErrorResponse, IJob } from '../types';
import AlertCard from './AlertCard';
import DataWindow from './DataWindow';
import useStyles from './styles';

export const isJobRunning = (job: IJob) =>
  job?.status === ModuleVables.SimulationStatuses.RUNNING.value ||
  job?.status === ModuleVables.SimulationStatuses.QUEUED.value ||
  job?.status === ModuleVables.SimulationStatuses.PENDING.value;

const AnalysisControls = () => {
  const classes = useStyles();
  const [, currentJob] = useLatestJob();
  const { share } = getSearchParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const canSimulate = usePermissionCheck(WorkspaceVables.Permission.RUN_SIMULATION);

  const {
    Job: {
      actions: { abortJob, getJob, pagedJobs, updateAnalyzeState },
    },
  } = SatelliteApi;

  const {
    branch: { id },
  } = useContext(ActiveBranchContext);
  const progress = useMemo(() => currentJob?.progress?.percentComplete, [currentJob]);
  const running = useMemo(() => isJobRunning(currentJob), [currentJob]);
  const jobCreator = useSelectById('User', currentJob?.createdBy || '');

  // Fetch first page of jobs on mount to prepopulate the job menu in DataWindow
  useEffect(() => {
    dispatch(
      pagedJobs({
        queryParams: { branch: id, page: 1, pageSize: 10, share },
        failureCallback: (response: IErrorResponse) => {
          enqueueSnackbar(response?.error?.message || 'Error fetching jobs.');
        },
      })
    );
  }, [id, dispatch, pagedJobs, share, enqueueSnackbar]);

  // Component data dictating when polling can run
  const isMounted = useMountStatus();
  const [loading, setLoading] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();

  const run = useCallback(() => {
    const _getStatus = () => {
      dispatch(
        getJob({
          branchId: id,
          id: currentJob.id,
          queryParams: { share },
          successCallback: (response: IJob) => {
            if (isMounted()) {
              if (isJobRunning(response)) {
                timeout.current = setTimeout(run, 2000);
              } else if (
                response.status !== ModuleVables.SimulationStatuses.SUCCEEDED.value &&
                response.message
              ) {
                enqueueSnackbar('Simulation halted early: ' + response.message.split('\n')[0]);
                setLoading(false);
                dispatch(
                  updateAnalyzeState({
                    id: response.id,
                    fetchWhenTrue: response.dataArray !== null,
                  })
                ); // Trigger fetch if no data is cached
              } else {
                setLoading(false);
                dispatch(
                  updateAnalyzeState({
                    id: response.id,
                    fetchWhenTrue: response.dataArray !== null,
                  })
                ); // Trigger fetch if no data is cached
              }
            }
          },
          failureCallback: (response: IErrorResponse) => {
            if (isMounted()) {
              enqueueSnackbar(response?.error?.message || 'Error fetching job.');
              setLoading(false);
            }
          },
        })
      );
    };

    if (isMounted()) _getStatus();
  }, [dispatch, getJob, enqueueSnackbar, id, currentJob, isMounted, updateAnalyzeState, share]);

  // Clear timeout on unmount
  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  // When latest job is first fetched,
  // set loading to false, and start polling if appropriate
  const [gotFirstJob, setGotFirstJob] = useState(false);
  useEffect(() => {
    if (currentJob?.id && !gotFirstJob && isMounted()) {
      setGotFirstJob(true);
      setLoading(false);
      if (running) run();
    }
  }, [currentJob, gotFirstJob, run, isMounted, running]);

  const abortSimulation = useCallback(() => {
    setLoading(true);
    dispatch(
      abortJob({
        branchId: id,
        id: currentJob?.id,
        successCallback: () => {
          ReactGA.event(gaEvents.SIM_ABORT, {
            category: 'Simulation',
            action: 'Abort Simulation',
            label: 'Abort Simulation Button',
          });
        },
        failureCallback: (response: IErrorResponse) => {
          enqueueSnackbar(response?.error?.message || 'Error aborting job.');
          setLoading(false);
        },
      })
    );
  }, [currentJob?.id, dispatch, abortJob, id, enqueueSnackbar]);

  const label = useMemo(() => {
    if (currentJob?.status) {
      if (loading) {
        if (running) return 'Aborting...';
        else return 'Loading...';
      }
      return (
        ModuleVables.SimulationStatuses[currentJob?.status as TSimulationStatus]?.label ||
        'Loading...'
      );
    } else {
      if (loading) return 'Loading...';
      return 'Ready';
    }
  }, [currentJob?.status, loading, running]);

  useHotkeys(
    hotkeys.SIMULATE.keys,
    () => {
      if (canSimulate && running) abortSimulation();
    },
    [abortSimulation, setLoading, isJobRunning]
  );

  const performance = useMemo(() => {
    // These two values in days
    const start = currentJob?.startTime;
    const stop = currentJob?.stopTime;

    if (
      start !== undefined &&
      stop !== undefined &&
      currentJob.status === ModuleVables.SimulationStatuses.SUCCEEDED.value
    ) {
      // These two values in milliseconds
      const realTimeDuration = (stop - start) * 24 * 60 * 60 * 1000;
      const simDuration = currentJob.dateModified.diff(
        moment(currentJob.dateStarted || currentJob.dateCreated) // backwards compatibility
      );
      return realTimeDuration / simDuration;
    } else return undefined;
  }, [currentJob]);

  return (
    <div className="joyride-sim-controls">
      <div className={classes.root}>
        <div className={classes.toolStatus}>
          <CircularProgress
            size={30}
            value={progress ?? 0}
            loading={
              loading ||
              currentJob?.status === ModuleVables.SimulationStatuses.PENDING.value ||
              currentJob?.status === ModuleVables.SimulationStatuses.QUEUED.value
            }
            status={currentJob?.status || ModuleVables.SimulationStatuses.READY.value}
          />
        </div>
        {<h5>{label}</h5>}
        <StyledButton
          className={classes.toolBtn}
          type="button"
          min
          onClick={abortSimulation}
          disabled={!canSimulate || !running}
          framed
          replaceSpinner
          error
        >
          Abort
        </StyledButton>
      </div>
      {currentJob?.status === ModuleVables.SimulationStatuses.QUEUED.value && (
        <AlertCard
          message="This simulation is queued due to insufficient workspace capacity."
          details={`Other simulations in your organization's workspace have exhausted the workspace's capacity. This job will begin when sufficient capacity is freed. To increase capacity, please contact ${SALES_EMAIL}.`}
          variant="schedule"
        />
      )}
      {currentJob?.progress?.minTimeStep && currentJob?.progress?.minTimeStep < 0.5 / 86400 && (
        <AlertCard
          message="The simulation is currently stepping at an abnormally small time step."
          details={`This may be intentional depending on the static definition of your model but it may also mean that one or more agent's is experiencing abnormally high dynamic behavior (e.g., uncontrolled spinning). If this warning persists, fetch the latest data window to investigate further.`}
          variant="info"
        />
      )}
      {currentJob?.dateCreated && (
        <>
          <div className={classes.simDesriptors}>
            <p>Job ID: {<ClipboardCopy text={currentJob.id} />}</p>
            <p>Initiated: {currentJob.dateCreated.local().format('M/DD/YY h:mma')}</p>
            {jobCreator && (
              <p>
                {jobCreator.firstName} {jobCreator.lastName}
              </p>
            )}
            {performance && (
              <p>
                <strong>Performance</strong>: {precision(performance)}x real time
              </p>
            )}
          </div>
          {(currentJob.status === ModuleVables.CompletedStatuses.ERROR.value ||
            currentJob.status === ModuleVables.CompletedStatuses.FAILED.value) &&
            currentJob.message && (
              <AlertCard message={`Error: ${currentJob.message.split('\n')[0]}`} variant="error" />
            )}
        </>
      )}
      <StyledDivider />
      <DataWindow />
    </div>
  );
};

export default AnalysisControls;
