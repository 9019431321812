import { makeStyles } from '@material-ui/core/styles';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IGenericObject } from 'components/general/types';
import { ISubsystem } from 'components/general/types/power';
import { useActiveEntities, useAppDispatch, useEscapeKeyDown, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import * as React from 'react';
import { Dispatch, useState } from 'react';

const useStyles = makeStyles(() => ({
  moreIcon: { position: 'absolute', transform: 'translate(15%, -25%)' },
  subsystemTitleForm: {
    width: '10rem',
    margin: '0 0 -0.95rem 0',
    transform: 'translateY(-25%)',
    '& div': { marginBottom: 0 },
  },
}));

const EditNameForm = ({
  subsystem,
  setEditSelected,
}: {
  subsystem: ISubsystem;
  setEditSelected: Dispatch<boolean>;
}) => {
  const dispatch = useAppDispatch();
  const { Subsystem } = SatelliteApi;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { branch } = useActiveEntities();

  const [name, setName] = useState(subsystem.name);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      Subsystem.actions.updateSubsystem({
        branchId: branch.id,
        name,
        id: subsystem.id,
        type: 'Subsystem',
        successCallback: () => {
          enqueueSnackbar('Subsystem updated successfully', {
            variant: 'success',
          });
          setEditSelected(false);
        },
        failureCallback: (res: IGenericObject) => {
          enqueueSnackbar(res.error.message);
        },
      })
    );
  };

  return (
    <form onSubmit={handleSubmit} className={classes.subsystemTitleForm}>
      <LabeledInput
        placeholder="name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        onKeyDown={useEscapeKeyDown(() => setEditSelected(false))}
        onBlur={() => setEditSelected(false)}
        value={name}
        autoFocus
      ></LabeledInput>
    </form>
  );
};

export default EditNameForm;
