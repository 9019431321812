import InputAdornment from '@material-ui/core/InputAdornment';
import { FallbackInlay } from 'components/general/ViewPortInlay';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IGenericObject, IGuidanceCard, ISelectOption } from 'components/general/types';
import { IBattery } from 'components/general/types/power';
import EntitySegment from 'components/general/wizards/EntitySegment';
import WizardSegment from 'components/general/wizards/WizardSegment';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useFormikForm, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { translateIn } from 'utils/forms';
import { BatteryVables } from 'utils/vable';
import useGuidance from './guidance';
import validation from './validation';

interface IProps {
  index: string;
}

interface IForm {
  configurationType: ISelectOption | '';
  initialSoc: number | '';
  maxChargeCurrentOverride?: number | '';
  maxDischargeCurrentOverride?: number | '';
  minSocOverride: number | '';
}

const defaultValues: IForm = {
  configurationType: '',
  initialSoc: '',
  maxChargeCurrentOverride: '',
  maxDischargeCurrentOverride: '',
  minSocOverride: '',
};

const SEGMENT_TITLE = 'Battery System';

const BatterySystemSegment = (props: IProps) => {
  // Handle props and set up state
  const { index } = props;
  const [loading, setLoading] = useState(false);

  // Get current battery and get update battery action
  const {
    Battery: {
      actions: { updateBattery },
    },
  } = SatelliteApi;
  const { battery, branch } = useActiveEntities();
  const dispatch = useDispatch();

  const classes = useStyles();

  // Set up options
  const options = useMemo(() => {
    return {
      configurationType: BatteryVables.ConfigurationTypes.options,
    };
  }, []);

  // Set up dispatch action (Battery will only be edited)
  const { enqueueSnackbar } = useSnackbar();
  const editBattery = useCallback(
    (values) => {
      setLoading(true);
      dispatch(
        updateBattery({
          branchId: branch.id,
          id: battery?.id,
          type: 'Battery',
          ...values,
          successCallback: (response: IGenericObject) => {
            enqueueSnackbar('Battery updated successfully.', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response: IGenericObject) => {
            const errorMessage = response.error.message;
            enqueueSnackbar(errorMessage);
            setLoading(false);
          },
        })
      );
    },
    [battery, dispatch, enqueueSnackbar, updateBattery, branch]
  );

  // Batteries are created upon mission version creation so the default values are always translated in and should never be undefined
  // So the values passed to the formik hook (used when resetForm is called) do not have the override values
  // Custom translate in sets them to empty strings in order to allow resetForm to function properly
  const customTranslateIn = useCallback(
    (values, defaultValues, options, datetimes, percentages) => {
      if (!values.maxChargeCurrentOverride) values.maxChargeCurrentOverride = '';
      if (!values.maxDischargeCurrentOverride) values.maxDischargeCurrentOverride = '';
      if (!values.minSocOverride) values.minSocOverride = '';
      return translateIn(values, defaultValues, options, datetimes, percentages);
    },
    []
  );

  // Hook up form
  const { formik, guidance } = useFormikForm<IBattery, IForm>(
    defaultValues,
    editBattery,
    validation,
    battery,
    {
      options,
      useGuidance,
      percentages: ['initialSoc', 'minSocOverride'],
      translateIn: customTranslateIn,
      allowedEmptyFields: [
        'maxChargeCurrentOverride',
        'minSocOverride',
        'maxDischargeCurrentOverride',
      ],
    }
  );

  const { handleSubmit, getFieldProps, resetForm, dirty } = formik;

  return (
    <EntitySegment
      title={SEGMENT_TITLE}
      index={index}
      // Guidance is optional for the formik hook, so we typecast it since it will be returned whenever we pass the hook useGuidance
      guidance={guidance as IGuidanceCard}
      onSubmit={handleSubmit}
      onReset={resetForm}
      disableSubmit={!dirty}
      loading={loading}
      xray={{ ...battery, ...formik.values }}
    >
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledSelect
            label="Pack Configuration"
            options={options.configurationType}
            {...getFieldProps('configurationType')}
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            label="Initial State of Charge"
            {...getFieldProps('initialSoc')}
            type="number"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
          />
        </div>
        <h3 className={classes.subHeader}>Override Cell Specs (Optional)</h3>
        <div className={classes.indent}>
          <div className={classes.inputGroup}>
            <LabeledInput
              label="Minimum State of Charge"
              {...getFieldProps('minSocOverride')}
              type="number"
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
          </div>
          <div className={classes.inputGroup}>
            <LabeledInput
              label="Maximum Charge Current"
              type="number"
              endAdornment={<InputAdornment position="end">A</InputAdornment>}
              {...getFieldProps('maxChargeCurrentOverride')}
            />
          </div>
          <div className={classes.inputGroup}>
            <LabeledInput
              label="Maximum Discharge Current"
              type="number"
              endAdornment={<InputAdornment position="end">A</InputAdornment>}
              {...getFieldProps('maxDischargeCurrentOverride')}
            />
          </div>
        </div>
      </div>
    </EntitySegment>
  );
};

const BatterySystemSegmentWrapper = ({ index }: IProps) => {
  return (
    <ErrorBoundary
      fallback={
        <WizardSegment index={index} title={SEGMENT_TITLE}>
          <FallbackInlay marginTop={'0em'} />
        </WizardSegment>
      }
    >
      <BatterySystemSegment index={index} />
    </ErrorBoundary>
  );
};

export default BatterySystemSegmentWrapper;
