import Grid from '@material-ui/core/Grid';
import GuidanceCard from 'components/general/GuidanceCard';
import Dialog from 'components/general/dialogs/Dialog';
import { IGuidanceCard, IMissionVersion } from 'components/general/types';
import WidgetTable from 'components/general/widgets/WidgetTable';

interface IProps {
  open: boolean;
  closeDialog: () => void;
  issues: IMissionVersion['tier2issues'];
}

interface ISubComponentProps {
  data: string[];
  staticProps: {
    columnTitle: string;
    emptyMessage: string;
    guidance: IGuidanceCard;
  };
}

const errorsStaticProps = {
  columnTitle: 'Errors:',
  emptyMessage: 'No errors to address -- model ready to simulate.',
  guidance: {
    heading: 'Addressing Errors',
    body: 'All errors in this dialog must be addressed before you can simulate this model. Use the edit boards to address them.',
  },
};

const warningsStaticProps = {
  columnTitle: 'Warnings:',
  emptyMessage: 'No warnings to address',
  guidance: {
    heading: 'Addressing Warnings',
    body: 'Warnings are not required to be addressed, but you should review them before simulating.',
  },
};

const Tier2ValidationDialog = ({ open, closeDialog, issues }: IProps) => {
  const { errors, warnings } = issues;

  return (
    <Dialog
      title="Simulateable Model Issues"
      large
      open={open}
      onClose={closeDialog}
      secondaryActionText="Close"
    >
      <Tier2WidgetTableAndGuidance data={errors} staticProps={errorsStaticProps} />
      <Tier2WidgetTableAndGuidance data={warnings} staticProps={warningsStaticProps} />
    </Dialog>
  );
};

function Tier2WidgetTableAndGuidance({ data, staticProps }: ISubComponentProps) {
  const { emptyMessage, guidance, columnTitle } = staticProps;
  const columns = [{ title: columnTitle, field: 'data' }];
  const data_ = data.map((data) => ({ data }));
  return (
    <Grid container spacing={1}>
      <Grid item md={8}>
        <WidgetTable data={data_} columns={columns} emptyMessage={emptyMessage} noXray />
      </Grid>
      <Grid item md={4}>
        <GuidanceCard guidance={guidance} />
      </Grid>
    </Grid>
  );
}

export default Tier2ValidationDialog;
