import { Tooltip } from '@material-ui/core';
import { Handle, NodeProps, Position, useStore } from 'react-flow-renderer';
import { precision } from 'utils/units';
import { useStylesNode, useStylesToolTipTitle } from './styles';
import { Layers, Settings, AcUnit } from '@material-ui/icons';

const ToolTipTitle = ({ label, temp }: { label: string; temp?: string }) => {
  const classes = useStylesToolTipTitle();
  return (
    <div className={classes.root}>
      <p>{label}</p>
      {temp && <p>{temp}</p>}
    </div>
  );
};

export function TempNode({ data, connectable }: NodeProps & { connectable?: boolean }) {
  const classes = useStylesNode(data);
  const temp = precision(data.temp) + '°C';
  const store = useStore();
  return (
    <>
      <Handle
        type="source"
        position={Position.Bottom}
        style={
          // REF: invisibleNode
          // Make handle invisible and unusable if not connectable
          connectable
            ? {
                opacity: 0.85,
                height: 15,
                width: 15,
                bottom: -7,
                border: '1px dotted rgba(256, 256, 256, 0.6)',
              }
            : {
                height: '0%',
                width: '0%',
                border: 0,
                opacity: 0,
              }
        }
        // Don't connect to self
        isValidConnection={({ source, target }) => Boolean(connectable) && source !== target}
      />
      <Tooltip title={<ToolTipTitle label={data.label} temp={temp} />} placement="top" arrow>
        <div className={classes.nodeWrapper}>
          <div className={classes.labelIconWrapper}>
            <div className={classes.iconWrapper} style={{ color: data.tempColor }}>
              {data.nodeType === 'Surface' && <Layers />}
              {data.nodeType === 'Component' && <Settings />}
              {data.nodeType === 'Cooler' && <AcUnit />}
            </div>
            <div className={classes.labelWrapper}>
              <div className={classes.subsystemLabel}>{data.subsystem}</div>
              <div className={classes.componentLabel}>{data.label}</div>
            </div>
          </div>
          <div className={classes.detailWrapper}>
            {data.temp && <div className={classes.temp}>{data.temp.toFixed(2)}°C</div>}
          </div>
        </div>
      </Tooltip>
      <Handle
        type="target"
        position={Position.Top}
        style={{
          // REF: dropzoneNode
          // This handle effectively turns the whole node into a dropzone, but only when a new
          // edge is currently being defined. This is marked with store.connectionNodeId, which
          // is the id of the source node of an edge that's being drawn.
          // This maintains click-and-drag node capability.
          height: store.connectionNodeId && connectable ? '100%' : '0%',
          width: store.connectionNodeId && connectable ? '100%' : '0%',
          border: 0,
          top: 0,
          opacity: 0,
        }}
      />
    </>
  );
}

export function ConnectableTempNode(props: NodeProps) {
  return <TempNode connectable {...props} />;
}

export function ChildNode({ data, connectable }: NodeProps & { connectable?: boolean }) {
  const classes = useStylesNode();
  const store = useStore();

  return (
    <>
      <Handle
        type="source"
        position={Position.Bottom}
        style={
          // REF: invisibleNode
          connectable
            ? {
                opacity: 0.85,
                height: 12,
                width: 12,
                bottom: -7,
                border: '1px dotted rgba(256, 256, 256, 0.6)',
              }
            : {
                height: '0%',
                width: '0%',
                border: 0,
                opacity: 0,
              }
        }
        // Don't connect to self
        isValidConnection={({ source, target }) => Boolean(connectable) && source !== target}
      />
      <div className={classes.childWrapper}>
        <p className={classes.child}>{data.label}</p>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        style={{
          // REF: dropzoneNode
          height: store.connectionNodeId && connectable ? '100%' : '0%',
          width: store.connectionNodeId && connectable ? '100%' : '0%',
          border: 0,
          top: 0,
          opacity: 0,
        }}
      />
    </>
  );
}

export function ConnectableChildNode(props: NodeProps) {
  return <ChildNode connectable {...props} />;
}

// Unused for now
// function Tri({ data }) {
//   // const onChange = useCallback((evt) => {
//   //   console.log(evt.target.value);
//   // }, []);

//   return (
//     <>
//       <Handle type="source" position={Position.Bottom} />
//       <div>{data.label}</div>
//       <Handle
//         type="target"
//         position={Position.Top}
//         id="default"
//         style={{ right: 30, left: 'unset' }}
//       />
//       <Handle type="source" position={Position.Bottom} id="cool" style={{ left: 30 }} />
//     </>
//   );
// }
