import Dialog from 'components/general/dialogs/Dialog';
import { Fragment } from 'react';
import ReleaseNotes from './ReleaseNotes';

const ReleaseDialog = (props) => {
  const { open, onClose } = props;

  return (
    <Fragment>
      <Dialog
        title={<h1>What's New</h1>}
        open={open}
        onClose={(e) => {
          if (e._reactName !== 'onMouseUp') onClose();
        }}
        dontDisableInReadOnly
        secondaryActionText={'Close'}
      >
        <div style={{ margin: 10 }}>
          <ReleaseNotes />
        </div>
      </Dialog>
    </Fragment>
  );
};

export default ReleaseDialog;
