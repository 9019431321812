import _, { round } from 'lodash';
import { mjd2Moment, moment2Mjd } from 'utils/time';

/**
 * @param {any} value
 * @param {{}[]} options
 * @returns option corresponding to value
 */
const findOption = (value, options) => {
  if (!value || !options.length) return null;
  let optionsToCheck;

  // for nested options:
  if (Object.hasOwn(options[0], 'options')) {
    optionsToCheck = [];
    for (const o of options) {
      optionsToCheck.push(...o.options);
    }
  } else {
    optionsToCheck = options;
  }

  const res = optionsToCheck.find((o) => {
    return (
      o.value === value ||
      (o.value.id && o.value.id === value) ||
      (value.id && value.id === o.value) ||
      (o.value.id && value.id && o.value.id === value.id)
    );
  });
  return res;
};

export const translateIn = (ormModel, defaultValues, options, datetimes, percentages) => {
  if (ormModel != null) {
    let { series, ...model } = ormModel;
    let m = _.cloneDeep(model);

    // For each option available to the entity, we check if the data coming in has that value
    // If it does then we convert the field into an object for a select input in the format of {value, label}
    for (const key in options) {
      const v = _.get(options, key);
      if (v.length && v[0].backendField) {
        // Check if the options have suboptions defined with a backendField key
        for (const subOptions of v) {
          // Check if the model has the suboption backendField since the backend will return that specific field and compare for the correct type of entity
          const foundOption = findOption(_.get(m, subOptions.backendField), subOptions.options);
          // set the original key that the form expects
          if (foundOption) {
            _.set(m, key, foundOption);
            // If the option has a resolver field then loop through and set the values based on the resolver
            for (const [field, value] of Object.entries(subOptions.resolver || {})) {
              _.set(m, field, value);
            }
          }
        }
      } else if (_.get(m, key)) {
        // If no backend field (aka frontend matches backend) perform usual find/set value operation
        _.set(m, key, findOption(_.get(m, key), v));
      }
    }

    // Handle any unneeded data from the backend
    // NOTE: This is done after looping through options/suboptions so backend fields labeled differently in the form have already been set and can be deleted
    for (const field of Object.keys(m)) {
      // Delete any fields that are not required by the form, and therefore not in defaultValues, such as id
      if (!_.has(defaultValues, field)) {
        delete m[field];
      }
      // Backend can have null values for fields
      // If the field is null then it is set to an empty string to be consistent with default values in formik
      // The second check is specifically for date time pickers, which require null as an initial value
      else if (_.get(m, field) === null && _.get(defaultValues, field) !== null) {
        _.set(m, field, '');
      }
    }

    // correctly format any datetimes in the form
    if (datetimes) {
      datetimes.forEach((field) => {
        if (_.has(m, field)) {
          let v = _.get(m, field);
          if (v && v !== '') {
            _.set(m, field, mjd2Moment(v));
          }
        }
      });
    }
    // Format any percentages as the backend expects to receive them as a decimal
    if (percentages) {
      percentages.forEach((field) => {
        if (_.get(m, field)) {
          _.set(m, field, round(_.get(m, field) * 100, 3));
        }
      });
    }
    return m;
  }
  return null;
};

const sanitizeOut = (data, allowedEmptyFields) => {
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      return data.map((v) => sanitizeOut(v, allowedEmptyFields));
    }
    for (const key in data) {
      data[key] = sanitizeOut(data[key], allowedEmptyFields);
      // Formik forms require default values, which are typically empty strings, so we check if they are allowed for the field otherwise delete them
      // If they are an allowed null field then set the values explicitly to null
      const isEmptyObject =
        data[key] != null &&
        typeof data[key] === 'object' &&
        !Array.isArray(data[key]) &&
        _.isEmpty(data[key]);
      if (data[key] === '' || isEmptyObject) {
        if (allowedEmptyFields.includes(key)) {
          data[key] = isEmptyObject ? {} : null;
        } else {
          delete data[key];
        }
      }
    }
    return data;
  }
  return data;
};

const _getOptionId = (v) => {
  return !!v && typeof v === 'object' ? v.id : v;
};

export const translateOut = (data, allowedEmptyFields, options, datetimes, percentages) => {
  for (const key in options) {
    // If the data being submitted has an actual value for that option, proceed
    const v = _.get(data, key);
    if (v) {
      // Set the field to the id of the object, as that is what the backend expects
      _.set(data, key, _getOptionId(v.value));
      // If there are assumptions with this option then loop through to delete the frontend field name and apply these assumptions to the data
      for (const [assumptionKey, value] of Object.entries(v.assumptions || {})) {
        // self value is used to assign the value of the input to the backend field name
        if (value === 'self') {
          _.set(data, assumptionKey, _getOptionId(v.value));
        } else {
          _.set(data, assumptionKey, value); // otherwise set to the assumption value
        }
      }
    } else if (!allowedEmptyFields.includes(key)) {
      // if field is not used set to undefined
      delete data[key];
    }
  }

  if (datetimes) {
    datetimes.forEach((key) => {
      if (_.has(data, key) && _.get(data, key) !== null) {
        _.set(data, key, moment2Mjd(_.get(data, key)));
      }
    });
  }
  if (percentages) {
    percentages.forEach((key) => {
      if (_.get(data, key)) {
        _.set(data, key, _.get(data, key) / 100);
      }
    });
  }
  sanitizeOut(data, allowedEmptyFields);
  return data;
};

export const exclude = (options, optionToExclude) => {
  if (!optionToExclude) {
    return options;
  }
  // Allows optionToExclude to be either an id or a previously defined option
  const optionToExcludeId = optionToExclude.id ?? _getOptionId(optionToExclude.value);
  return options.filter((o) => _getOptionId(o.value) !== optionToExcludeId);
};

/**
 * Use this function to create options (value/label objects) from an array of entities: `{value: {id: entity.id}, label: entity.name}`
 *
 * @param {Array} entities an array of entities
 * @param {[]} [additionalKeysInValue] this is used to extend the "value" part of the value/label oject to include other keys from the entity, for example if you pass `['targetType']`, the "value" will become `{id: entity.id, targetType: entity.targetType}`. The purpose of this is if you need to store other data to be accessed later in the dynamically created form. Nothing other than `'id'` remains once the `translateOut` function is called in Formik.
 * @param {{}} [assumptions] an object with key/values for fields that should be set, ex for condition form: `{targetGroupA: 'self', paramACategory: 'TARGET_GROUP',targetA: undefined}` would set targetGroupA to the actual field value, targetA to undefined, and paramACat to target group
 * @returns
 */
export const createOptionsFromEntities = (entities, additionalKeysInValue, assumptions) => {
  return entities?.map((e) => {
    const value = { id: e.id };

    if (additionalKeysInValue) {
      for (const k of additionalKeysInValue) value[k] = e[k];
    }

    const option = { value, label: e.name };
    if (assumptions) {
      option.assumptions = assumptions;
    }
    return option;
  });
};

/**
 * Use this function for any select fields that require grouped options
 *
 * @param {{[key:string]: Any}[]} entities a list of options, ex: [{value: "SOLAR_AZ", label: "Solar Azimuth"}, ...]
 * @param {string} label the string for the Sub Options shown in the select drop down.
 * @param {{}} resolver The resolver argument is used when translating in to set the correct front end field from the backend data
 * @param {string} backendField backendField is a string utilized in translate out to format the field value to what the backend is expecting
 * @param {string[]} [additionalKeysInValue] by default, the the created value/label objects under the options key are just `{value: {id: entity.id}, label: entity.name}`, and this is used to extend the "value" to include other keys from the entity, for example if you pass `['targetType']`, the "value" will become `{id: entity.id, targetType: entity.targetType}`. The purpose of this is if you need to store other data to be accessed later in the dynamically created form. Nothing other than `'id'` remains once the `translateOut` function is called in Formik.
 * @param {{}} [assumptions] an object with key/values for fields that should be set, ex for condition form: `{targetGroupA: 'self', paramACategory: 'TARGET_GROUP',targetA: undefined}` would set targetGroupA to the actual field value, targetA to undefined, and paramACat to target group
 * @param {boolean} [optionsFromEntities=true] if true, the options will be created from the entities, otherwise the entities will be used as the options
 * @returns {{label: string, resolver: {}, backendField: string, options: {value: any, label: string}[]}} an object containing the keys "label", "resolver", "backendField", and "options"
 */
export const createNestedOption = (
  entities,
  label,
  resolver,
  backendField,
  additionalKeysInValue = [],
  assumptions = {},
  optionsFromEntities = true
) => {
  return {
    label,
    resolver,
    backendField,
    options: optionsFromEntities
      ? createOptionsFromEntities(entities, additionalKeysInValue, assumptions)
      : entities,
  };
};

const SANCTIONED_COUNTRIES = [
  'AF',
  'BY',
  'CF',
  'CN',
  'CI',
  'CU',
  'KP',
  'CD',
  'CG',
  'CY',
  'ER',
  'ET',
  'HT',
  'HK',
  'IR',
  'IQ',
  'LB',
  'LR',
  'LY',
  'MM',
  'RU',
  'SO',
  'SS',
  'LK',
  'SD',
  'SY',
  'UA',
  'VE',
  'VN',
  'YE',
  'ZW',
];

// Map country names to their ISO-ALPHA2 country code
const ALL_COUNTRIES = [
  {
    label: 'Afghanistan',
    value: 'AF',
  },
  {
    label: 'Aland Islands',
    value: 'AX',
  },
  {
    label: 'Albania',
    value: 'AL',
  },
  {
    label: 'Algeria',
    value: 'DZ',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Andorra',
    value: 'AD',
  },
  {
    label: 'Angola',
    value: 'AO',
  },
  {
    label: 'Anguilla',
    value: 'AI',
  },
  {
    label: 'Antarctica',
    value: 'AQ',
  },
  {
    label: 'Antigua & Barbuda',
    value: 'AG',
  },
  {
    label: 'Argentina',
    value: 'AR',
  },
  {
    label: 'Armenia',
    value: 'AM',
  },
  {
    label: 'Aruba',
    value: 'AW',
  },
  {
    label: 'Australia',
    value: 'AU',
  },
  {
    label: 'Austria',
    value: 'AT',
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
  },
  {
    label: 'Bahamas',
    value: 'BS',
  },
  {
    label: 'Bahrain',
    value: 'BH',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
  },
  {
    label: 'Barbados',
    value: 'BB',
  },
  {
    label: 'Belarus',
    value: 'BY',
  },
  {
    label: 'Belgium',
    value: 'BE',
  },
  {
    label: 'Belize',
    value: 'BZ',
  },
  {
    label: 'Benin',
    value: 'BJ',
  },
  {
    label: 'Bermuda',
    value: 'BM',
  },
  {
    label: 'Bhutan',
    value: 'BT',
  },
  {
    label: 'Bolivia',
    value: 'BO',
  },
  {
    label: 'Bonaire, Sint Eustatius & Saba',
    value: 'BQ',
  },
  {
    label: 'Bosnia & Herzegovina',
    value: 'BA',
  },
  {
    label: 'Botswana',
    value: 'BW',
  },
  {
    label: 'Bouvet Island',
    value: 'BV',
  },
  {
    label: 'Brazil',
    value: 'BR',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO',
  },
  {
    label: 'Brunei',
    value: 'BN',
  },
  {
    label: 'Bulgaria',
    value: 'BG',
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
  },
  {
    label: 'Burundi',
    value: 'BI',
  },
  {
    label: 'Cabo Verde',
    value: 'CV',
  },
  {
    label: 'Cambodia',
    value: 'KH',
  },
  {
    label: 'Cameroon',
    value: 'CM',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'Cayman Islands',
    value: 'KY',
  },
  {
    label: 'Central African Republic',
    value: 'CF',
  },
  {
    label: 'Chad',
    value: 'TD',
  },
  {
    label: 'Chile',
    value: 'CL',
  },
  {
    label: 'China',
    value: 'CN',
  },
  {
    label: 'Christmas Island',
    value: 'CX',
  },
  {
    label: 'Cocos',
    value: 'CC',
  },
  {
    label: 'Colombia',
    value: 'CO',
  },
  {
    label: 'Comoros',
    value: 'KM',
  },
  {
    label: 'Congo',
    value: 'CG',
  },
  {
    label: 'Cook Islands',
    value: 'CK',
  },
  {
    label: 'Costa Rica',
    value: 'CR',
  },
  {
    label: 'Croatia',
    value: 'HR',
  },
  {
    label: 'Cuba',
    value: 'CU',
  },
  {
    label: 'Curaçao',
    value: 'CW',
  },
  {
    label: 'Cyprus',
    value: 'CY',
  },
  {
    label: 'Czechia',
    value: 'CZ',
  },
  {
    label: "Côte d'Ivoire",
    value: 'CI',
  },
  {
    label: 'Democratic Republic of the Congo',
    value: 'CD',
  },
  {
    label: 'Denmark',
    value: 'DK',
  },
  {
    label: 'Djibouti',
    value: 'DJ',
  },
  {
    label: 'Dominica',
    value: 'DM',
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
  },
  {
    label: 'Ecuador',
    value: 'EC',
  },
  {
    label: 'Egypt',
    value: 'EG',
  },
  {
    label: 'El Salvador',
    value: 'SV',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
  },
  {
    label: 'Eritrea',
    value: 'ER',
  },
  {
    label: 'Estonia',
    value: 'EE',
  },
  {
    label: 'Eswatini',
    value: 'SZ',
  },
  {
    label: 'Ethiopia',
    value: 'ET',
  },
  {
    label: 'Falkland Islands',
    value: 'FK',
  },
  {
    label: 'Faroe Islands',
    value: 'FO',
  },
  {
    label: 'Fiji',
    value: 'FJ',
  },
  {
    label: 'Finland',
    value: 'FI',
  },
  {
    label: 'France',
    value: 'FR',
  },
  {
    label: 'French Guiana',
    value: 'GF',
  },
  {
    label: 'French Polynesia',
    value: 'PF',
  },
  {
    label: 'French Southern Territories',
    value: 'TF',
  },
  {
    label: 'Gabon',
    value: 'GA',
  },
  {
    label: 'Gambia',
    value: 'GM',
  },
  {
    label: 'Georgia',
    value: 'GE',
  },
  {
    label: 'Germany',
    value: 'DE',
  },
  {
    label: 'Ghana',
    value: 'GH',
  },
  {
    label: 'Gibraltar',
    value: 'GI',
  },
  {
    label: 'Greece',
    value: 'GR',
  },
  {
    label: 'Greenland',
    value: 'GL',
  },
  {
    label: 'Grenada',
    value: 'GD',
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Guatemala',
    value: 'GT',
  },
  {
    label: 'Guernsey',
    value: 'GG',
  },
  {
    label: 'Guinea',
    value: 'GN',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
  },
  {
    label: 'Guyana',
    value: 'GY',
  },
  {
    label: 'Haiti',
    value: 'HT',
  },
  {
    label: 'Heard Island & McDonald Islands',
    value: 'HM',
  },
  {
    label: 'Holy See',
    value: 'VA',
  },
  {
    label: 'Honduras',
    value: 'HN',
  },
  {
    label: 'Hong Kong',
    value: 'HK',
  },
  {
    label: 'Hungary',
    value: 'HU',
  },
  {
    label: 'Iceland',
    value: 'IS',
  },
  {
    label: 'India',
    value: 'IN',
  },
  {
    label: 'Indonesia',
    value: 'ID',
  },
  {
    label: 'Iran',
    value: 'IR',
  },
  {
    label: 'Iraq',
    value: 'IQ',
  },
  {
    label: 'Ireland',
    value: 'IE',
  },
  {
    label: 'Isle of Man',
    value: 'IM',
  },
  {
    label: 'Israel',
    value: 'IL',
  },
  {
    label: 'Italy',
    value: 'IT',
  },
  {
    label: 'Jamaica',
    value: 'JM',
  },
  {
    label: 'Japan',
    value: 'JP',
  },
  {
    label: 'Jersey',
    value: 'JE',
  },
  {
    label: 'Jordan',
    value: 'JO',
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
  },
  {
    label: 'Kenya',
    value: 'KE',
  },
  {
    label: 'Kiribati',
    value: 'KI',
  },
  {
    label: 'Kuwait',
    value: 'KW',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
  },
  {
    label: 'Laos',
    value: 'LA',
  },
  {
    label: 'Latvia',
    value: 'LV',
  },
  {
    label: 'Lebanon',
    value: 'LB',
  },
  {
    label: 'Lesotho',
    value: 'LS',
  },
  {
    label: 'Liberia',
    value: 'LR',
  },
  {
    label: 'Libya',
    value: 'LY',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
  },
  {
    label: 'Lithuania',
    value: 'LT',
  },
  {
    label: 'Luxembourg',
    value: 'LU',
  },
  {
    label: 'Macao',
    value: 'MO',
  },
  {
    label: 'Madagascar',
    value: 'MG',
  },
  {
    label: 'Malawi',
    value: 'MW',
  },
  {
    label: 'Malaysia',
    value: 'MY',
  },
  {
    label: 'Maldives',
    value: 'MV',
  },
  {
    label: 'Mali',
    value: 'ML',
  },
  {
    label: 'Malta',
    value: 'MT',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Martinique',
    value: 'MQ',
  },
  {
    label: 'Mauritania',
    value: 'MR',
  },
  {
    label: 'Mauritius',
    value: 'MU',
  },
  {
    label: 'Mayotte',
    value: 'YT',
  },
  {
    label: 'Mexico',
    value: 'MX',
  },
  {
    label: 'Micronesia',
    value: 'FM',
  },
  {
    label: 'Moldova',
    value: 'MD',
  },
  {
    label: 'Monaco',
    value: 'MC',
  },
  {
    label: 'Mongolia',
    value: 'MN',
  },
  {
    label: 'Montenegro',
    value: 'ME',
  },
  {
    label: 'Montserrat',
    value: 'MS',
  },
  {
    label: 'Morocco',
    value: 'MA',
  },
  {
    label: 'Mozambique',
    value: 'MZ',
  },
  {
    label: 'Myanmar',
    value: 'MM',
  },
  {
    label: 'Namibia',
    value: 'NA',
  },
  {
    label: 'Nauru',
    value: 'NR',
  },
  {
    label: 'Nepal',
    value: 'NP',
  },
  {
    label: 'Netherlands',
    value: 'NL',
  },
  {
    label: 'New Caledonia',
    value: 'NC',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
  },
  {
    label: 'Nicaragua',
    value: 'NI',
  },
  {
    label: 'Niger',
    value: 'NE',
  },
  {
    label: 'Nigeria',
    value: 'NG',
  },
  {
    label: 'Niue',
    value: 'NU',
  },
  {
    label: 'Norfolk Island',
    value: 'NF',
  },
  {
    label: "Democratic People's Republic of Korea",
    value: 'KP',
  },
  {
    label: 'North Macedonia',
    value: 'MK',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Norway',
    value: 'NO',
  },
  {
    label: 'Oman',
    value: 'OM',
  },
  {
    label: 'Pakistan',
    value: 'PK',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Palestine',
    value: 'PS',
  },
  {
    label: 'Panama',
    value: 'PA',
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
  },
  {
    label: 'Paraguay',
    value: 'PY',
  },
  {
    label: 'Peru',
    value: 'PE',
  },
  {
    label: 'Philippines',
    value: 'PH',
  },
  {
    label: 'Pitcairn',
    value: 'PN',
  },
  {
    label: 'Poland',
    value: 'PL',
  },
  {
    label: 'Portugal',
    value: 'PT',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Qatar',
    value: 'QA',
  },
  {
    label: 'Romania',
    value: 'RO',
  },
  {
    label: 'Russia',
    value: 'RU',
  },
  {
    label: 'Rwanda',
    value: 'RW',
  },
  {
    label: 'Réunion',
    value: 'RE',
  },
  {
    label: 'Saint Barthélemy',
    value: 'BL',
  },
  {
    label: 'Saint Helena, Ascension & Tristan da Cunha',
    value: 'SH',
  },
  {
    label: 'Saint Kitts & Nevis',
    value: 'KN',
  },
  {
    label: 'Saint Lucia',
    value: 'LC',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'MF',
  },
  {
    label: 'Saint Pierre & Miquelon',
    value: 'PM',
  },
  {
    label: 'Saint Vincent & the Grenadines',
    value: 'VC',
  },
  {
    label: 'Samoa',
    value: 'WS',
  },
  {
    label: 'San Marino',
    value: 'SM',
  },
  {
    label: 'Sao Tome & Principe',
    value: 'ST',
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
  },
  {
    label: 'Senegal',
    value: 'SN',
  },
  {
    label: 'Serbia',
    value: 'RS',
  },
  {
    label: 'Seychelles',
    value: 'SC',
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
  },
  {
    label: 'Singapore',
    value: 'SG',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'SX',
  },
  {
    label: 'Slovakia',
    value: 'SK',
  },
  {
    label: 'Slovenia',
    value: 'SI',
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
  },
  {
    label: 'Somalia',
    value: 'SO',
  },
  {
    label: 'South Africa',
    value: 'ZA',
  },
  {
    label: 'South Georgia & the South Sandwich Islands',
    value: 'GS',
  },
  {
    label: 'South Korea',
    value: 'KR',
  },
  {
    label: 'South Sudan',
    value: 'SS',
  },
  {
    label: 'Spain',
    value: 'ES',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
  },
  {
    label: 'Sudan',
    value: 'SD',
  },
  {
    label: 'Suriname',
    value: 'SR',
  },
  {
    label: 'Svalbard & Jan Mayen',
    value: 'SJ',
  },
  {
    label: 'Sweden',
    value: 'SE',
  },
  {
    label: 'Switzerland',
    value: 'CH',
  },
  {
    label: 'Syria',
    value: 'SY',
  },
  {
    label: 'Taiwan',
    value: 'TW',
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
  },
  {
    label: 'Tanzania',
    value: 'TZ',
  },
  {
    label: 'Thailand',
    value: 'TH',
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
  },
  {
    label: 'Togo',
    value: 'TG',
  },
  {
    label: 'Tokelau',
    value: 'TK',
  },
  {
    label: 'Tonga',
    value: 'TO',
  },
  {
    label: 'Trinidad & Tobago',
    value: 'TT',
  },
  {
    label: 'Tunisia',
    value: 'TN',
  },
  {
    label: 'Turkey',
    value: 'TR',
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
  },
  {
    label: 'Turks & Caicos Islands',
    value: 'TC',
  },
  {
    label: 'Tuvalu',
    value: 'TV',
  },
  {
    label: 'Uganda',
    value: 'UG',
  },
  {
    label: 'Ukraine',
    value: 'UA',
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
  },
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UM',
  },
  {
    label: 'Uruguay',
    value: 'UY',
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
  },
  {
    label: 'Vanuatu',
    value: 'VU',
  },
  {
    label: 'Venezuela',
    value: 'VE',
  },
  {
    label: 'Vietnam',
    value: 'VN',
  },
  {
    label: 'Virgin Islands (British)',
    value: 'VG',
  },
  {
    label: 'Virgin Islands (U.S.)',
    value: 'VI',
  },
  {
    label: 'Wallis & Futuna',
    value: 'WF',
  },
  {
    label: 'Western Sahara',
    value: 'EH',
  },
  {
    label: 'Yemen',
    value: 'YE',
  },
  {
    label: 'Zambia',
    value: 'ZM',
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
  },
];

export const COUNTRIES = ALL_COUNTRIES.filter(
  (country) => !SANCTIONED_COUNTRIES.includes(country.value)
);
