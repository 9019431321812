import ConOpsTimeSeriesWidget from 'components/AgentAnalyzeView/AnalyzeBoards/CdhAnalyzeBoards/general/ConOpsSeriesWidget';
import { PowerBarGraph } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general';
import StatsTable from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/StatsTable';
import { FallbackInlay } from 'components/general/ViewPortInlay';
import WGroup from 'components/general/WGroup';
import BooleanSeriesChart from 'components/general/charts/BooleanSeriesChart';
import PieChart from 'components/general/charts/PieChart';
import TimeSeriesChart from 'components/general/charts/TimeSeriesChart';
import {
  IGenericObject,
  TChartSpec,
  TPlotDef,
  TPlotVariable,
  TWidgetDef,
} from 'components/general/types';
import Widget from 'components/general/widgets/Widget';
import { useActiveStaticModel } from 'hooks';
import { DataContext } from 'providers/DataProvider';
import { defineStat, finalizeStat, initStatsData } from 'providers/DataProvider/stats';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { getSearchParams } from 'routes';
import { represent } from 'utils/units';
import { interpret } from './specInterpreter';

interface IProps {
  wGroupIndex: string;
  data: IGenericObject;
  chartSpec: TChartSpec;
}

interface IWidget {
  def: TWidgetDef;
  plots: {
    def: TPlotDef;
    vars: {
      xKey: string;
      yKey: string;
      name: string;
      right?: boolean;
    }[];
  }[];
}

const DataPlottingWidgetGroup = ({ wGroupIndex, data, chartSpec }: IProps) => {
  const { resolveSeriesDataKeyPair, seriesData } = useContext(DataContext);
  const { agentId } = getSearchParams();
  const model = useActiveStaticModel();
  const [state, setState] = useState<{
    widgets: IWidget[];
    statsData: IGenericObject;
    agentId: string;
  }>({
    widgets: [],
    statsData: initStatsData(),
    agentId,
  });

  // Reinterpret spec and update stats whenever series data changes
  useEffect(() => {
    const statsData = initStatsData();
    setState({
      widgets: interpret(chartSpec, model).map((widgetDef) => {
        return {
          def: widgetDef,
          plots: widgetDef.plots.flatMap((plotDef) => {
            const vars = plotDef.variables
              .filter((v) => v.key)
              .map(
                ({ name, right, key }): TPlotVariable => ({
                  name,
                  right,
                  ...resolveSeriesDataKeyPair(agentId, key),
                })
              )
              .filter((v) => {
                return v.yKey && data[v.yKey];
              });

            if (['table', 'pie', 'horizontal-bar', 'margin'].includes(plotDef.type)) {
              try {
                vars.forEach((v) => defineStat(statsData, seriesData, v.yKey));
              } catch (e) {
                return []; // skip missing stats
              }
            }
            if (vars.length === 0 && plotDef.type !== 'conops') return [];
            return {
              def: plotDef,
              vars,
            };
          }),
        };
      }),
      statsData,
      agentId,
    });
  }, [model, seriesData, chartSpec, agentId, resolveSeriesDataKeyPair]); //eslint-disable-line

  const widgets = useMemo(
    () =>
      state.widgets.map(({ def: widgetDef, plots }, index) => {
        return (
          <Widget
            // It should not be possible but having no title causes an error so it defaults to 'Untitled'.
            title={widgetDef.title || 'Untitled'}
            collapsibleConfig
            subtitle={widgetDef.subtitle}
            key={widgetDef.title + index}
          >
            <ErrorBoundary
              fallback={
                <FallbackInlay
                  text="This chart is under construction."
                  subText="Our team is working to restore this feature."
                />
              }
            >
              {plots.map(({ def: plotDef, vars }, i) => {
                if (plotDef.type === 'line' || plotDef.type === 'scatter') {
                  return (
                    <TimeSeriesChart
                      title={plotDef.title}
                      key={i}
                      data={data}
                      withZoom={true}
                      lines={vars}
                      type={plotDef.type}
                      step={plotDef.step}
                      leftLabel={plotDef.label || ''}
                      leftUnits={plotDef.unit || ''}
                      rightLabel={plotDef.labelRight}
                      rightUnits={plotDef.unitRight}
                    />
                  );
                } else if (plotDef.type === 'bool') {
                  return (
                    <BooleanSeriesChart
                      title={plotDef.title}
                      key={i}
                      data={data}
                      withZoom={true}
                      variables={vars}
                      rightLabel={plotDef.labelRight}
                      rightUnits={plotDef.unitRight}
                    />
                  );
                } else if (plotDef.type === 'pie') {
                  return (
                    <PieChart
                      title={plotDef.title}
                      key={i}
                      statsData={state.statsData}
                      variables={vars}
                      baseUnit={plotDef.unit}
                    />
                  );
                } else if (plotDef.type === 'table') {
                  return (
                    <StatsTable
                      title={plotDef.title}
                      unit={plotDef.unit}
                      data={plotDef.variables.flatMap((v) => {
                        let value;
                        if (v.key) {
                          const key = resolveSeriesDataKeyPair(state.agentId, v.key)?.yKey;
                          if (!key) return [];
                          value = (finalizeStat(state.statsData, key) as IGenericObject)[key][
                            v.op || 'avg'
                          ];
                          const unit = v.unit || plotDef.unit;
                          value = unit ? represent(value, unit) : value;
                        }
                        return {
                          name: v.name,
                          value,
                          hierarchy: (v.level || 0) + 1,
                        };
                      })}
                      key={i}
                    />
                  );
                } else if (['horizontal-bar', 'margin'].includes(plotDef.type)) {
                  return (
                    <PowerBarGraph
                      title={plotDef.title}
                      data={plotDef.variables.flatMap((v) => {
                        let value;
                        if (v.key) {
                          const key = resolveSeriesDataKeyPair(state.agentId, v.key)?.yKey;
                          if (!key) return [];
                          value = (finalizeStat(state.statsData, key) as IGenericObject)[key][
                            v.op || 'avg'
                          ];
                        }
                        if (!value || isNaN(value) || value === Infinity || value === -Infinity)
                          return []; // if the value is null, NaN, Infinity, or negative Infinity don't return it and instead return an empty list
                        return {
                          barName: v.name,
                          value,
                          hierarchy: (v.level || 0) + (plotDef.type === 'margin' ? 1 : 2), // This is junk.  Need to fix the underlying issue in the component
                        };
                      })}
                      baseUnit={plotDef.unit}
                      colorBySign={plotDef.type === 'margin'}
                      key={i}
                    />
                  );
                } else if (plotDef.type === 'conops') {
                  return <ConOpsTimeSeriesWidget key={i} />;
                } else {
                  console.warn('Unknown plot type', plotDef.type);
                  return null;
                }
              })}
            </ErrorBoundary>
          </Widget>
        );
      }),
    [data, state, resolveSeriesDataKeyPair]
  );

  return <WGroup index={wGroupIndex}>{widgets}</WGroup>;
};

export default DataPlottingWidgetGroup;
