import { useModel } from 'middleware/SatelliteApi/template';
import { useDataContext } from 'providers';
import { getSearchParams } from 'routes';

/**
 * Returns the static model for the active agent
 * Implicitly subscribes the component to the DataProvider
 */
const useActiveStaticModel = () => {
  const { agentId: activeAgentId } = getSearchParams();
  const { staticModels } = useDataContext();
  const activeStaticModel = useModel(staticModels.agents[activeAgentId]);
  return activeStaticModel;
};

export default useActiveStaticModel;
