import { CHART_SPEC, ITEMS, SUB_ITEMS, TITLE } from 'config';
import _ from 'lodash';
import { ReactElement, useMemo } from 'react';
import { IGenericObject, IMenu } from '../types';
import DataPlottingWidgetGroup from './DataPlottingWidgetGroup';
// import TimeSeriesPlaybackControls from 'components/general/PlaybackWGroups/TimeSeriesPlaybackControls';

interface IProps {
  menu: IMenu;
  echartsData: IGenericObject;
}

const createPlottingWGroups = (menu: IMenu, data: IGenericObject) => {
  const plottingWGroups: ReactElement[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _recurIterKeyVals = (obj: { [key: string]: any }, keys = '') => {
    _.forOwn(obj, (v, k) => {
      if (k === CHART_SPEC) {
        plottingWGroups.push(
          <DataPlottingWidgetGroup wGroupIndex={keys} data={data} chartSpec={v} key={keys + v.id} />
        );
        return;
      }
      let newKeys = keys;
      if (![ITEMS, SUB_ITEMS, TITLE].includes(k)) {
        if (newKeys.length) newKeys += '.';
        newKeys += k;
      }

      if (typeof v === 'object' && v !== null) {
        _recurIterKeyVals(v, newKeys);
      }
    });
  };

  _recurIterKeyVals(menu);
  return plottingWGroups;
};

const DataPlotting = ({ menu, echartsData }: IProps) => {
  const plottingWGroups = useMemo(
    () => createPlottingWGroups(menu, echartsData),
    [menu, echartsData]
  );
  return (
    <>
      {/* TODO: commented out for small sat, coment back in later */}
      {/* <TimeSeriesPlaybackControls /> */}
      {plottingWGroups}
    </>
  );
};

export default DataPlotting;
