import { Input } from '@material-ui/core';
import clsx from 'clsx';
import StyledLabel from 'components/general/StyledLabel.jsx';
import { useInReadOnlyBranch } from 'hooks';
import _ from 'lodash';
import { memo } from 'react';
import theme from 'theme/index';
import useStyles from './styles';

const errorStyle = { borderColor: theme.palette.error.main };

const LabeledInput = (props) => {
  const {
    label,
    errors,
    name,
    guide,
    setFieldValue,
    autoComplete,
    className,
    wrap,
    optional,
    onFocus,
    fwdRef,
    dontDisableInReadOnly,
    onChange,
    ...remainingProps
  } = props;
  const classes = useStyles(props);
  const inReadOnlyBranch = useInReadOnlyBranch();

  return (
    <div style={{ width: '100%' }}>
      <StyledLabel>{optional ? `${label} (optional)` : label}</StyledLabel>
      <Input
        {...remainingProps}
        name={name}
        disableUnderline
        onFocus={onFocus ? onFocus : guide && ((e) => guide(e, name, label))}
        //onBlur={guide && ((e) => guide(e))}
        style={errors && _.get(errors, name) ? errorStyle : null}
        className={clsx(className, classes.root)}
        inputProps={{ step: 'any', ...(wrap || {}) }}
        autoComplete={autoComplete ? autoComplete : 'off'}
        onChange={onChange}
        ref={fwdRef}
        disabled={props.disabled || (inReadOnlyBranch && !dontDisableInReadOnly)}
      />
    </div>
  );
};

export default memo(LabeledInput);
