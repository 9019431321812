/* eslint no-template-curly-in-string: 0 */
import getThermalProps from 'hooks/getThermalProps';
import * as Yup from 'yup';

const { thermalValidation } = getThermalProps();

const validation = Yup.object().shape({
  ...thermalValidation,
});

export default validation;
