import { Box, IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DragIndicator } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import StyledButton from 'components/general/StyledButton';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IGenericObject, ISelectOption } from 'components/general/types';
import { ItemTypes, hotkeys } from 'config';
import _ from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useHotkeys } from 'react-hotkeys-hook';
import { FixedSizeList } from 'react-window';
import theme from 'theme';
import { AgentVables } from 'utils/vable';
import useStyles, { dropTargetHeight, rowHeight } from './styles';

interface IProps {
  formik: {
    getFieldProps: (name: string) => IGenericObject;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValues: (f: any) => void;
    values: {
      kinematics: {
        type: ISelectOption | '';
        waypoints: { llaDeg: [number | '', number | '', number | '']; formKey?: number | string }[];
        timestamps: (number | '')[];
        speeds: (number | '')[];
        durations: ({ s: number } | '')[];
      };
    };
  };
  waypointListRef: React.MutableRefObject<FixedSizeList<IGenericObject> | null>;
}

interface IWaypointProps {
  id?: number | string;
  index: number;
  classes: ReturnType<typeof useStyles>;
  movePoint: (fromIndex: number, toIndex: number) => void;
  deletePoint: (index: number) => void;
  formik: IProps['formik'];
  disableFirstInput?: boolean;
  style: IGenericObject;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const WaypointTypeUnits = {
  [AgentVables.WaypointType.WaypointPathWithTimestamps.label]: 'MJD',
  [AgentVables.WaypointType.WaypointPathWithSpeed.label]: 'km/s',
  [AgentVables.WaypointType.WaypointPathWithDuration.label]: 's',
};

const WaypointTypeKeys = {
  [AgentVables.WaypointType.WaypointPathWithTimestamps.label]: (i: number) =>
    `kinematics.${AgentVables.WaypointType.WaypointPathWithTimestamps.label.toLowerCase()}.${i}`,
  [AgentVables.WaypointType.WaypointPathWithSpeed.label]: (i: number) =>
    `kinematics.${AgentVables.WaypointType.WaypointPathWithSpeed.label.toLowerCase()}.${i}`,
  [AgentVables.WaypointType.WaypointPathWithDuration.label]: (i: number) =>
    `kinematics.${AgentVables.WaypointType.WaypointPathWithDuration.label.toLowerCase()}.${i}.s`,
};

const Waypoint = memo((props: IWaypointProps) => {
  const {
    id,
    index,
    formik: {
      getFieldProps,
      values: {
        kinematics: { type: waypointType },
      },
    },
    classes,
    movePoint,
    deletePoint,
    disableFirstInput,
    style,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [dragHover, setDragHover] = useState(0);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: unknown }>({
    accept: ItemTypes.WAYPOINT,
    collect: (monitor) => {
      if (!monitor.isOver()) setDragHover(0);
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item: DragItem, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      setDragHover(dragIndex < hoverIndex ? 2 : 1);
    },
    drop: (item: DragItem) => {
      // Time to actually perform the action
      if (dragHover !== 0) {
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        movePoint(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: ItemTypes.WAYPOINT,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(ref);
  const opacity = isDragging ? 0 : 1;

  return (
    <div style={{ ...style, opacity }} ref={ref}>
      <div
        style={{
          height: dropTargetHeight,
          width: '90%',
          backgroundColor: dragHover === 1 ? theme.palette.primary.main : 'transparent',
        }}
      />
      <div className={classes.waypointRow} data-handler-id={handlerId}>
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ width: '3ch' }}>{index}</h3>
          <div ref={drag}>
            <DragIndicator
              htmlColor={theme.palette.text.secondary}
              style={{
                cursor: 'grab',
                margin: '0',
              }}
            />
          </div>
        </div>
        <div style={{ width: 'min-content' }} ref={dragPreview}>
          <div className={classes.inputRow}>
            <LabeledInput
              placeholder="Latitude"
              type="number"
              endAdornment={<InputAdornment position="end">°N</InputAdornment>}
              {...getFieldProps(`kinematics.waypoints.${index}.llaDeg.0`)}
            />
            <LabeledInput
              placeholder="Longitude"
              type="number"
              endAdornment={<InputAdornment position="end">°E</InputAdornment>}
              {...getFieldProps(`kinematics.waypoints.${index}.llaDeg.1`)}
            />
          </div>
          <div className={classes.inputRow}>
            <LabeledInput
              placeholder="Altitude"
              type="number"
              endAdornment={<InputAdornment position="end">km</InputAdornment>}
              {...getFieldProps(`kinematics.waypoints.${index}.llaDeg.2`)}
            />
            {waypointType && (
              <Box
                width={'100%'}
                visibility={index === 0 && disableFirstInput ? 'hidden' : 'visible'}
              >
                <LabeledInput
                  placeholder={_.trim(waypointType.label, 's')}
                  type="number"
                  endAdornment={
                    <InputAdornment position="end">
                      {WaypointTypeUnits[waypointType.label]}
                    </InputAdornment>
                  }
                  {...getFieldProps(WaypointTypeKeys[waypointType.label](index))}
                  style={{ marginTop: 0 }}
                  disabled={index === 0 && disableFirstInput}
                />
              </Box>
            )}
          </div>
        </div>
        <IconButton
          className={classes.deleteIcon}
          onClick={() => {
            deletePoint(index);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>

      <div
        style={{
          height: dropTargetHeight,
          width: '90%',
          backgroundColor: dragHover === 2 ? theme.palette.primary.main : 'transparent',
        }}
      />
    </div>
  );
});
Waypoint.displayName = 'Waypoint';

const rowItems = ({
  index,
  data,
  style,
}: {
  index: number;
  data: IGenericObject;
  style: IGenericObject;
}) => {
  const formik = data.formik;
  const deletePoint = data.deletePoint;
  const movePoint = data.movePoint;
  const classes = data.classes;
  const disableFirstInput = data.disableFirstInput;
  const wp = data.data[index];
  return (
    <Waypoint
      index={index}
      style={style}
      id={wp.formKey}
      key={wp.formKey}
      formik={formik}
      classes={classes}
      movePoint={movePoint}
      deletePoint={deletePoint}
      disableFirstInput={disableFirstInput}
    />
  );
};

const itemKey = (index: number, data: IGenericObject) => data.data[index].formKey;

const WaypointInputs = (props: IProps) => {
  const {
    formik: {
      setValues,
      values: { kinematics },
    },
    waypointListRef,
  } = props;
  const { type: waypointType } = kinematics;
  const [prevLength, setPrevLength] = useState(kinematics.waypoints.length);
  const classes = useStyles();
  const disableFirstInput = useMemo(
    () =>
      Boolean(
        waypointType &&
          waypointType.label !== AgentVables.WaypointType.WaypointPathWithTimestamps.label
      ),
    [waypointType]
  );

  const addPoint = useCallback(() => {
    if (waypointType) {
      setValues((prev: IProps['formik']['values']) => ({
        ...prev,
        kinematics: {
          ...prev.kinematics,
          waypoints: [
            ...prev.kinematics.waypoints,
            { llaDeg: ['', '', ''], formKey: Math.random() },
          ],
          [waypointType.label.toLowerCase()]: [
            // @ts-ignore-next-line: TS doesn't know that waypointType is a valid key of kinematics
            ...prev.kinematics[waypointType.label.toLowerCase()],
            '',
          ],
        },
      }));
    }
  }, [setValues, waypointType]);

  const deletePoint = useCallback(
    (index: number) => {
      if (waypointType)
        setValues((prev: IProps['formik']['values']) => ({
          ...prev,
          kinematics: {
            ...prev.kinematics,
            waypoints: prev.kinematics.waypoints.filter((_, i) => i !== index),
            // @ts-ignore-next-line: TS doesn't know that waypointType is a valid key of kinematics
            [waypointType.label.toLowerCase()]: prev.kinematics[waypointType.label.toLowerCase()]
              .filter((_: number, i: number) => i !== index)
              .map((_: number, i: number) => (i === 0 && disableFirstInput ? '' : _)),
          },
        }));
    },
    [setValues, waypointType, disableFirstInput]
  );

  const movePoint = useCallback(
    (fromIndex, toIndex) => {
      if (waypointType) {
        setValues((prev: IProps['formik']['values']) => {
          const waypoint = prev.kinematics.waypoints.splice(fromIndex, 1)[0];
          prev.kinematics.waypoints.splice(toIndex, 0, waypoint);
          const waypointTypeKey = waypointType.label.toLowerCase() as
            | 'durations'
            | 'speeds'
            | 'timestamps';
          const waypointTypeValue = prev.kinematics[waypointTypeKey].splice(fromIndex, 1)[0];
          // @ts-ignore-next-line: TS doesn't trust us to choose the right value for the right list
          prev.kinematics[waypointTypeKey].splice(toIndex, 0, waypointTypeValue);
          if (disableFirstInput) prev.kinematics[waypointTypeKey][0] = '';
          // Use structuredClone to force the preview to re-render the waypoints
          return structuredClone(prev);
        });
      }
    },
    [setValues, waypointType, disableFirstInput]
  );

  // When a new point is added, scroll to the bottom of the list
  useEffect(() => {
    if (prevLength < kinematics.waypoints.length)
      waypointListRef.current?.scrollToItem(kinematics.waypoints.length);
    setPrevLength(kinematics.waypoints.length);
  }, [waypointListRef, kinematics.waypoints.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useHotkeys(hotkeys.NEW.keys, addPoint, [addPoint]);

  const [shadowState, setShadowState] = useState([0, kinematics.waypoints.length > 5 ? 1 : 0]);

  const virtualizedList = useMemo(
    () => (
      <FixedSizeList
        className={classes.waypointList}
        ref={waypointListRef}
        height={rowHeight * 4.5}
        width={'100%'}
        style={{
          scrollBehavior: 'smooth',
          overflowX: 'hidden',
        }}
        itemData={{
          data: kinematics.waypoints,
          formik: props.formik,
          deletePoint,
          movePoint,
          disableFirstInput,
          classes,
        }}
        itemKey={itemKey}
        itemSize={rowHeight}
        itemCount={kinematics.waypoints.length}
        onScroll={({ scrollOffset }) => {
          const scrollMax = rowHeight * (kinematics.waypoints.length - 4.5);
          const topShadow = Math.min(scrollOffset / scrollMax, 1);
          setShadowState([topShadow, scrollMax < 0 ? 0 : 1 - topShadow]);
        }}
      >
        {rowItems}
      </FixedSizeList>
    ),
    [
      classes,
      deletePoint,
      disableFirstInput,
      kinematics.waypoints,
      movePoint,
      props.formik,
      waypointListRef,
    ]
  );

  return (
    <>
      <StyledButton onClick={addPoint} fullWidth addIcon>
        Add point to path (N)
      </StyledButton>
      <div style={{ position: 'relative' }}>
        {virtualizedList}
        <div
          className={classes.topShadow}
          style={{ height: (shadowState[0] === 0 ? 0 : 10 + 50 * shadowState[0]) + '%' }}
        />
        <div
          className={classes.bottomShadow}
          style={{ height: (shadowState[1] === 0 ? 0 : 10 + 50 * shadowState[1]) + '%' }}
        />
      </div>
    </>
  );
};

export default memo(WaypointInputs);
