import { IWorkspace } from 'components/general/types';
import { useSelectById } from './stateHooks';
import { useAppSelector } from './typedHooks';

// Returns the latest job in a simulation, based on a redux value that is set in ActiveBranchProvider
// (or reset by SimulationControls in ScenarioView)
const useWorkspace = () => {
  const activeWorkspaceId = useAppSelector((state) => state.ui.missionExplorer.activeWorkspaceId);
  const workspace = useSelectById('Workspace', activeWorkspaceId || '') as IWorkspace;

  return workspace;
};

export default useWorkspace;
