import ViewPortInlay from 'components/general/ViewPortInlay';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { useActiveEntities, useAppDispatch, useSnackbar } from 'hooks';
import { multiBlockCrud } from 'middleware/SatelliteApi/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import theme from 'theme';
import MenuItemEdit from './MenuItemEdit';
import PlotForm from './PlotForm';
import { presets } from './Presets';
import StyledTreeView from './StyledTreeView';
import WidgetForm from './WidgetForm';
import useStyles from './styles';

// Chart types for the plot form dropdown and colors for the chips
const chartTypes = {
  line: {
    label: 'Line',
    value: 'line',
    color: Object.values(theme.palette.charts.primary)[0],
  },
  bool: {
    label: 'Boolean',
    value: 'bool',
    color: Object.values(theme.palette.charts.primary)[1],
  },
  pie: {
    label: 'Pie',
    value: 'pie',
    color: Object.values(theme.palette.charts.primary)[2],
  },
  margin: {
    label: 'Margin',
    value: 'margin',
    color: Object.values(theme.palette.charts.primary)[3],
  },
  'horizontal-bar': {
    label: 'Horizontal Bars',
    value: 'horizontal-bar',
    color: Object.values(theme.palette.charts.primary)[4],
  },
  table: {
    label: 'Stats Table',
    value: 'table',
    color: Object.values(theme.palette.charts.primary)[5],
  },
  conops: {
    label: 'Operational Modes',
    value: 'conops',
    color: Object.values(theme.palette.charts.primary)[7],
  },
};

const CustomizeMenuDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const activeEntities = useActiveEntities();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dirtyMenuItems, setDirtyMenuItems] = useState([]); // Menu items that have been edited
  const [dirtyWidgets, setDirtyWidgets] = useState([]); // Widgets that have been edited
  const [expanded, setExpanded] = useState([]); // Menu items that are expanded in tree view
  const [selectedMenuItem, setSelectedMenuItem] = useState();
  const [selectedWidget, setSelectedWidget] = useState();
  const [selectedPlot, setSelectedPlot] = useState(0); // Index of selected plot in the selected widget's plots list
  const [deletedBlocks, setDeletedBlocks] = useState([]);
  const [tempId, setTempId] = useState(0); // Used to create temporary ids for new menu items and widgets

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const menu = useMemo(() => {
    return structuredClone(activeEntities?.model?.Menu.first());
  }, [activeEntities?.model?.Menu, open]); //eslint-disable-line

  // Reset state when dialog is opened
  useEffect(() => {
    setSelectedMenuItem(undefined);
    setSelectedWidget(undefined);
    setDirtyMenuItems([]);
    setDirtyWidgets([]);
    setDeletedBlocks([]);
    setTempId(0);
  }, [open]);

  // Expand the first layer of menu items by default
  useEffect(() => {
    setExpanded(menu?.menuItems ? menu.menuItems.map((m) => m.id) : []);
  }, [activeEntities, menu?.menuItems]);

  useEffect(() => {
    setSelectedWidget(undefined);
  }, [selectedMenuItem]);

  useEffect(() => {
    setSelectedPlot(0);
  }, [selectedWidget]);

  // Get the path from the root menu to a specific menu item
  const getPathToItem = useCallback((target, currentItem, path) => {
    path.push(currentItem);
    if (currentItem.id === target) {
      return path;
    }
    for (let i = 0; i < currentItem.menuItems?.length; i++) {
      const childPath = getPathToItem(target, currentItem.menuItems[i], [...path]);
      if (childPath) {
        return childPath;
      }
    }
    return null;
  }, []);

  const editMenuTitle = useCallback(
    (id, label) => {
      const path = getPathToItem(id, menu, []);
      if (path) {
        path[path.length - 1].title = label || 'New Tab';
        setDirtyMenuItems((d) => [...d.filter((item) => item.id !== id), path[path.length - 1]]);
      }
    },
    [getPathToItem, setDirtyMenuItems, menu]
  );

  // Drag and drop handler - Nest a menu item under another menu item
  const nestMenuItem = useCallback(
    (item, targetId) => {
      if (item.id === targetId) return;
      const targetPath = getPathToItem(targetId, menu, []);
      const itemPath = getPathToItem(item.id, menu, []);
      if (targetPath && itemPath && !targetPath[targetPath.length - 1].menuItems.includes(item)) {
        const target = targetPath[targetPath.length - 1];
        const parent = itemPath[itemPath.length - 2];
        parent.menuItems = parent.menuItems.filter((i) => i.id !== item.id);
        target.menuItems = [...target.menuItems, item];
        if (!expanded.includes(targetId)) setExpanded([...expanded, targetId]);
        setDirtyMenuItems((d) => [
          ...d.filter((item) => item.id !== targetId && item.id !== parent.id),
          target,
          parent,
        ]);
      }
    },
    [getPathToItem, expanded, menu, setDirtyMenuItems, setExpanded]
  );

  // Drag and drop handler - Move a menu item above another menu item
  const moveItemAbove = useCallback(
    (item, targetId) => {
      if (item.id === targetId) return;
      const targetPath = getPathToItem(targetId, menu, []);
      const itemPath = getPathToItem(item.id, menu, []);
      if (targetPath && itemPath) {
        const target = targetPath[targetPath.length - 2];
        const parent = itemPath[itemPath.length - 2];
        parent.menuItems = parent.menuItems.filter((i) => i.id !== item.id);
        const index = target.menuItems.findIndex((i) => i.id === targetId);
        target.menuItems.splice(index, 0, item);
        setDirtyMenuItems((d) => [
          ...d.filter((item) => item.id !== targetId && item.id !== parent.id),
          target,
          parent,
        ]);
      }
    },
    [getPathToItem, menu, setDirtyMenuItems]
  );

  const nodeSelect = useCallback(
    (event, id) => {
      const path = getPathToItem(id, menu, []);
      if (path) {
        setSelectedMenuItem(path[path.length - 1]);
      }
    },
    [getPathToItem, menu, setSelectedMenuItem]
  );

  const deleteMenuItem = useCallback(
    (id) => {
      const path = getPathToItem(id, menu, []);
      if (path) {
        const parent = path[path.length - 2];
        if (parent) {
          parent.menuItems = parent.menuItems.filter((item) => item.id !== id);
          setDirtyMenuItems((d) => [...d.filter((item) => item.id !== parent.id), parent]);
        }
      }

      setDirtyMenuItems((d) => d.filter((item) => item.id !== id));
      if (id.charAt(0) !== '$') setDeletedBlocks((d) => [...d, id]);
      if (selectedMenuItem?.id === id) setSelectedMenuItem(undefined);
    },
    [
      getPathToItem,
      menu,
      setDirtyMenuItems,
      setDeletedBlocks,
      selectedMenuItem,
      setSelectedMenuItem,
    ]
  );

  const addMenuItem = useCallback(
    (id) => {
      const newItem = {
        id: '$temp-' + tempId,
        type: 'MenuItem',
        title: 'New Tab',
        menuItems: [],
        widgetSpecs: [],
        playback: null,
      };
      selectedMenuItem.menuItems = [...selectedMenuItem.menuItems, newItem];
      setTempId(tempId + 1);
      setSelectedMenuItem(selectedMenuItem);
      setDirtyMenuItems((d) => [
        ...d.filter((item) => item.id !== selectedMenuItem.id),
        selectedMenuItem,
        newItem,
      ]);
      if (!expanded.includes(id)) setExpanded([...expanded, id]);
    },
    [
      selectedMenuItem,
      setSelectedMenuItem,
      setDirtyMenuItems,
      setExpanded,
      expanded,
      tempId,
      setTempId,
    ]
  );

  const addWidget = useCallback(() => {
    const newWidget = {
      id: '$temp-' + tempId,
      type: 'WidgetSpec',
      name: 'New Widget',
      title: 'New Widget',
      plots: [],
    };
    selectedMenuItem.widgetSpecs = [...selectedMenuItem.widgetSpecs, newWidget];
    setTempId(tempId + 1);
    setSelectedMenuItem(selectedMenuItem);
    setDirtyMenuItems((d) => [
      ...d.filter((item) => item.id !== selectedMenuItem.id),
      selectedMenuItem,
    ]);
    setDirtyWidgets((d) => [...d, newWidget]);
    setSelectedWidget(newWidget);
  }, [
    selectedMenuItem,
    setSelectedMenuItem,
    setDirtyMenuItems,
    setDirtyWidgets,
    tempId,
    setTempId,
    setSelectedWidget,
  ]);

  const deleteWidget = useCallback(
    (id) => {
      selectedMenuItem.widgetSpecs = selectedMenuItem.widgetSpecs.filter(
        (widget) => widget.id !== id
      );
      setSelectedMenuItem(selectedMenuItem);
      setDirtyMenuItems((d) => [
        ...d.filter((item) => item.id !== selectedMenuItem.id),
        selectedMenuItem,
      ]);
      setDirtyWidgets((d) => d.filter((item) => item.id !== id));
      if (id.charAt(0) !== '$') setDeletedBlocks((d) => [...d, id]);
      if (selectedWidget?.id === id) setSelectedWidget(undefined);
    },
    [
      selectedMenuItem,
      setSelectedMenuItem,
      setDirtyMenuItems,
      setDirtyWidgets,
      selectedWidget,
      setSelectedWidget,
      setDeletedBlocks,
    ]
  );

  // Submit handler to save menu changes
  const handleSubmit = useCallback(() => {
    const widgetBlocks = dirtyWidgets.map(({ id, name, title, subtitle, plots, each }) => {
      return {
        id,
        type: 'WidgetSpec',
        name: name,
        title: title,
        subtitle: subtitle,
        plots: plots,
        each: each,
      };
    });
    const menuBlocks = dirtyMenuItems.map(({ rels, parentMenuItem, ...item }) => {
      return {
        ...item,
        menuItems: item.menuItems.map((item) => {
          return item.id;
        }),
        widgetSpecs: item.widgetSpecs?.map((item) => item.id),
      };
    });
    const blocks = widgetBlocks.concat(menuBlocks);
    setLoading(true);
    dispatch(
      multiBlockCrud({
        branchId: activeEntities.branch.id,
        blocks,
        delete: deletedBlocks,
        successCallback: () => {
          // hacky way to force the menu to update by loading an invalid url (only works with 'edit' or 'analyze')
          // and relying on the fallback routing to redirect to the new, correct url
          history.replace(
            Routes.AGENT_ANALYZE(activeEntities.branch.id, 'edit') + history.location.search
          );
          enqueueSnackbar('Menu updated succesfully', {
            variant: 'success',
          });
          setLoading(false);
          onClose();
        },
        failureCallback: (response) => {
          const errorMessage =
            response?.error?.message ||
            'Something went wrong. Please try again. If this problem persists, please contact our support team.';
          enqueueSnackbar(
            errorMessage.length > 1000 ? errorMessage.slice(0, 1000) + '...' : errorMessage
          );
          setLoading(false);
        },
      })
    );
  }, [
    enqueueSnackbar,
    dispatch,
    onClose,
    deletedBlocks,
    activeEntities.branch.id,
    history,
    dirtyMenuItems,
    dirtyWidgets,
  ]);

  return (
    <Dialog
      title="Customize Analyze Tabs"
      open={open}
      onClose={useCallback(() => {
        if (
          !(dirtyMenuItems.length + dirtyWidgets.length + deletedBlocks.length) ||
          window.confirm('Do you want to close without saving?')
        )
          onClose();
      }, [dirtyMenuItems, dirtyWidgets, deletedBlocks, onClose])}
      submitActionText="Save"
      secondaryActionText="Close"
      fullscreen
      onSubmit={handleSubmit}
      disableSubmit={!(dirtyMenuItems.length + dirtyWidgets.length + deletedBlocks.length)}
      loading={loading}
      xray={activeEntities?.model?.Menu.first()}
    >
      <div className={classes.root}>
        {/* Menu Items tree view */}
        <div className={classes.menuTree}>
          {menu && (
            <>
              <div style={{ marginRight: 8 }}>
                <LabeledSelect
                  label="Add Presets"
                  value={null}
                  onChange={({ value }) => {
                    const newItem = value();
                    const newItems = [];
                    const newWidgets = [];
                    const addNewBlocks = (item) => {
                      newItems.push(item);
                      item.menuItems.forEach((i) => {
                        addNewBlocks(i);
                      });
                      item.widgetSpecs.forEach((i) => {
                        newWidgets.push(i);
                      });
                    };
                    addNewBlocks(newItem);
                    menu.menuItems = [...menu.menuItems, newItem];
                    setSelectedMenuItem(newItem);
                    setDirtyMenuItems((d) => [
                      ...d.filter(({ id }) => id !== menu.id),
                      ...newItems,
                      menu,
                    ]);
                    setDirtyWidgets((d) => [...d, ...newWidgets]);
                  }}
                  options={presets}
                />
              </div>
              <StyledTreeView
                menu={menu}
                selected={selectedMenuItem ? selectedMenuItem.id : ''}
                onNodeSelect={nodeSelect}
                expanded={expanded}
                setExpanded={setExpanded}
                editMenuTitle={editMenuTitle}
                onDelete={deleteMenuItem}
                onAdd={addMenuItem}
                nestItem={nestMenuItem}
                moveBetween={moveItemAbove}
              />
            </>
          )}
        </div>
        {/* Menu Item edit form */}
        <div className={classes.menuItemEdit}>
          {selectedMenuItem ? (
            <>
              <MenuItemEdit
                selectedMenuItem={selectedMenuItem}
                selectedWidget={selectedWidget}
                addWidget={addWidget}
                deleteWidget={deleteWidget}
                setSelectedMenuItem={setSelectedMenuItem}
                setSelectedWidget={setSelectedWidget}
                getPathToItem={(x) => getPathToItem(x, menu, [])}
                setDirtyMenuItems={setDirtyMenuItems}
              />
              {/* Widget edit form */}
              {selectedWidget && (
                <WidgetForm
                  selectedWidget={selectedWidget}
                  setDirtyWidgets={setDirtyWidgets}
                  setSelectedPlot={setSelectedPlot}
                  selectedPlot={selectedPlot}
                  chartTypes={chartTypes}
                />
              )}
            </>
          ) : (
            <ViewPortInlay text="Select an analysis tab to view widgets" />
          )}
        </div>
        {/* Plot edit form */}
        <div
          className={selectedWidget?.plots[selectedPlot] ? classes.chartEdit : classes.emptyChart}
        >
          {selectedWidget?.plots[selectedPlot] ? (
            <PlotForm
              selectedPlot={selectedPlot}
              selectedWidget={selectedWidget}
              setDirtyWidgets={setDirtyWidgets}
              chartTypes={chartTypes}
            />
          ) : (
            <ViewPortInlay text="Select a plot to edit" />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default CustomizeMenuDialog;
