import * as Yup from 'yup';

const dataStorageSchema = Yup.object().shape({
  capacity: Yup.number()
    .required('Capacity is required')
    .moreThan(0, 'Capacity must be positive')
    .integer('Capacity must be an integer number of bits'),

  maxBitRateRead: Yup.number()
    .required('Max Read Bitrate is required')
    .moreThan(0, 'Max Read Bitrate must be positive'),

  maxBitRateWrite: Yup.number()
    .required('Max Write Bitrate is required')
    .moreThan(0, 'Max Write Bitrate must be positive'),

  component: Yup.object().required('A component is required'),
});

export default dataStorageSchema;
