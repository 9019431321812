import MdAccent from 'components/general/Accent/variants/MdAccent';
import SelectPriorityQueue from 'components/general/PriorityQueue/SelectPriorityQueue';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IDataSide, ISelectOption } from 'components/general/types';
import { IAgent, IAgentGroup } from 'components/general/types/agent';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { translateOut } from 'utils/forms';
import { AgentVables, TargetVables } from 'utils/vable';
import * as Yup from 'yup';
import { useGuidance } from './guidance';

interface IForm {
  name: string;
  agentType: ISelectOption | '';
}
const defaultValues: IForm = {
  name: '',
  agentType: '',
};

interface IProps {
  control: TEntityDialogControl<IAgentGroup>;
}

const validation = Yup.object().shape({
  agentType: Yup.object().required('The agent type for this agent group is required.'),
});

const AgentGroupDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action, entity: agentGroup },
  } = control;
  const classes = useStyles();
  const { model, agents } = useActiveEntities();

  const [queueDirty, setQueueDirty] = useState(false);
  // This array is the actual agent objects
  const [agentGroupPriorityArray, setAgentGroupPriorityArray] = useState<(IAgent & IDataSide)[]>(
    []
  );
  // Grid state is the array of agent id's in the correct order. It's used in place of agentGroupPriorityArray to avoid rerendering when priorities are changed.
  // if agentGroupPriorityArray changes then a new grid is made and causes the dialog to flicker briefly so we avoid that with gridState
  const [gridState, setGridState] = useState([]);

  const agentGroupAssociations = useMemo(
    () => (agentGroup ? agentGroup.agentAssociations : []),
    [agentGroup]
  );

  // Uses the agent group association to set an array of agent objects
  useEffect(() => {
    setAgentGroupPriorityArray(
      [...agentGroupAssociations].sort((t1, t2) => t2.dataSide.priority - t1.dataSide.priority)
    );
  }, [setAgentGroupPriorityArray, agentGroupAssociations, model]);

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      // Grid is listed top to bottom, in reverse priority order
      const priorityList = gridState.reverse();
      const agentAssociations: { [index: string]: { priority: number } } = {};
      for (let i = 0; i < priorityList.length; i++)
        agentAssociations[priorityList[i]] = { priority: i };

      return translateOut(
        {
          name: values.name,
          agentType: values.agentType,
          agentAssociations,
        },
        allowedEmptyFields,
        options
      );
    },
    [gridState]
  );

  const onReset = useCallback(() => {
    if (agentGroup !== null) {
      // loop through the associations and add them in order to reset the priority order
      setAgentGroupPriorityArray(
        [...agentGroupAssociations].sort((t1, t2) => t2.dataSide.priority - t1.dataSide.priority)
      );
    }
    setQueueDirty(false);
  }, [agentGroup, agentGroupAssociations]);

  const options = useMemo(() => {
    return {
      agents: agents.map((a) => {
        return {
          value: a.id,
          label: a.name,
          ...a,
        };
      }),
      agentType: TargetVables.Type.options,
    };
  }, [agents]);

  const entityForm = useEntityForm<IAgentGroup, IForm>({
    entityTypeText: 'Agent Group',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    extendReset: onReset,
    formikOptionalParams: {
      useGuidance,
      options,
      translateOut: customTranslateOut,
    },
    additionalCreateValues: { type: 'AgentGroup' },
  });

  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  const filteredAgentOptions = useMemo(
    () =>
      options.agents.filter((agent) => {
        let comparisonValue;

        switch (agent.type) {
          case AgentVables.AgentType.TemplatedAgent.value:
            comparisonValue = TargetVables.Type.SpaceTarget.value;
            break;
          case AgentVables.AgentType.PeripheralGroundPoint.value:
            comparisonValue = TargetVables.Type.GroundTarget.value;
            break;
          case AgentVables.AgentType.PeripheralSpacePoint.value:
            comparisonValue = TargetVables.Type.SpaceTarget.value;
            break;
          case AgentVables.AgentType.PeripheralCelestialPoint.value:
            comparisonValue = TargetVables.Type.CelestialTarget.value;
            break;
          case AgentVables.AgentType.PeripheralGroundArea.value:
            comparisonValue = TargetVables.Type.AreaTarget.value;
            break;
          default:
            break;
        }

        return (
          typeof values.agentType === 'object' &&
          comparisonValue === values.agentType.value &&
          agentGroupPriorityArray.every((previousAgent) => previousAgent.id !== agent.id)
        );
      }),
    [values, options, agentGroupPriorityArray]
  );

  return (
    <EntityDialog entityForm={entityForm} customDirty={queueDirty}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Agent Group Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          <LabeledSelect
            {...getFieldProps('agentType')}
            // agent type for a agent group is only selected at creation, it cannot be updated
            isDisabled={values.agentType && action !== 'create'}
            label="Type of Agents in this Group"
            placeholder="Select Agent Type..."
            options={options.agentType}
          />
        </div>
        {values.agentType && (
          <div className={classes.inputGroup}>
            <MdAccent header="Agents">
              <SelectPriorityQueue
                setGridState={setGridState}
                name="agent"
                selectPlaceholder="Add Agent..."
                options={filteredAgentOptions}
                data={agentGroupPriorityArray}
                getFieldProps={getFieldProps}
                setData={setAgentGroupPriorityArray}
                setQueueDirty={setQueueDirty}
              />
            </MdAccent>
          </div>
        )}
      </div>
    </EntityDialog>
  );
};

export default AgentGroupDialog;
