// Presetes users can add to their UDP menu

// Created by outputting the default menu to the console via the following code and replacing the ids with rel Ids
// console.log(JSON.stringify(activeEntities?.model?.Menu.first(), (key, value) => {if (!['parentMenuItem', 'rels'].includes(key) && value !== null) return value;}));
export const presets = [
  {
    label: 'GNC',
    value: () => ({
      id: `$GNC-${Math.random()}`,
      title: 'GNC',
      type: 'MenuItem',
      playback: null,
      widgetSpecs: [],
      menuItems: [
        {
          id: `$GNCPlayback-${Math.random()}`,
          title: 'Playback',
          type: 'MenuItem',
          playback: ['gnc-state'],
          widgetSpecs: [],
          menuItems: [],
        },
        {
          id: `$GNCTS-${Math.random()}`,
          title: 'Time Series',
          type: 'MenuItem',
          playback: null,
          widgetSpecs: [],
          menuItems: [
            {
              id: `$GNCDynamics-${Math.random()}`,
              title: 'Dynamics',
              type: 'MenuItem',
              playback: null,
              widgetSpecs: [
                {
                  id: `$GNCAttitude-${Math.random()}`,
                  name: 'Attitude',
                  title: 'Attitude',
                  type: 'WidgetSpec',
                  subtitle: 'Attitude Quaternion and Angular Velocity',
                  plots: [
                    {
                      type: 'line',
                      label: 'Quaternion',
                      variables: [
                        {
                          keys: [
                            'attitude.body_eci.0',
                            'attitude.body_eci.1',
                            'attitude.body_eci.2',
                            'attitude.body_eci.3',
                          ],
                          legend: ['x', 'y', 'z', 'w'],
                        },
                      ],
                    },
                    {
                      type: 'line',
                      label: 'Angular Velocity',
                      unit: 'rpm',
                      variables: [
                        {
                          keys: [
                            'angularVelocity.rpm.0',
                            'angularVelocity.rpm.1',
                            'angularVelocity.rpm.2',
                          ],
                          legend: ['x', 'y', 'z'],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: `$GNCPos-${Math.random()}`,
                  name: 'Geodetic Position',
                  title: 'Geodetic Position',
                  type: 'WidgetSpec',
                  subtitle: 'True position, WGS84 ellipsoid',
                  plots: [
                    {
                      type: 'line',
                      label: 'Angle',
                      labelRight: 'Altitude',
                      unit: 'deg',
                      unitRight: 'km',
                      variables: [
                        {
                          keys: ['position.llaDeg.0', 'position.llaDeg.1'],
                          legend: ['Latitude', 'Longitude'],
                        },
                        {
                          keys: ['position.llaDeg.2'],
                          legend: ['Altitude'],
                          right: true,
                        },
                      ],
                    },
                  ],
                },
                {
                  id: `$GNCAxis-${Math.random()}`,
                  name: 'Semi-Major Axis and Apsides',
                  title: 'Semi-Major Axis and Apsides',
                  type: 'WidgetSpec',
                  subtitle: 'Semi-major axis, apogee, and perigee',
                  plots: [
                    {
                      type: 'line',
                      label: 'Value',
                      unit: 'km',
                      variables: [
                        {
                          keys: [
                            'orbitalElements.a',
                            'missionOrbit.radiusApogee',
                            'missionOrbit.radiusPerigee',
                          ],
                          legend: ['Semi-Major Axis', 'Apogee', 'Perigee'],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: `$GNCOrbit-${Math.random()}`,
                  name: 'Orbital Elements',
                  title: 'Orbital Elements',
                  type: 'WidgetSpec',
                  plots: [
                    {
                      type: 'line',
                      unit: 'rad',
                      variables: [{ keys: ['orbitalElements.a'], legend: ['Inclination'] }],
                    },
                    {
                      type: 'line',
                      variables: [{ keys: ['orbitalElements.e'], legend: ['Eccentricity'] }],
                    },
                    {
                      type: 'line',
                      unit: 'rad',
                      variables: [
                        {
                          keys: [
                            'orbitalElements.eAnom',
                            'orbitalElements.mAnom',
                            'orbitalElements.nu',
                          ],
                          legend: ['Eccentric Anomaly', 'Mean Anomaly', 'True Anomaly'],
                        },
                      ],
                    },
                    {
                      type: 'line',
                      unit: 'rad',
                      variables: [
                        { keys: ['orbitalElements.om'], legend: ['Agument of Periapsis'] },
                      ],
                    },
                    {
                      type: 'line',
                      unit: 'rad',
                      variables: [{ keys: ['orbitalElements.raan'], legend: ['RAAN'] }],
                    },
                  ],
                },
                {
                  id: `$GNCTorques-${Math.random()}`,
                  name: 'Total Torques',
                  plots: [
                    {
                      label: 'Torque, S/C Body-Frame',
                      type: 'line',
                      unit: 'N-m',
                      variables: [
                        {
                          keys: [
                            'torque.0',
                            'torque.1',
                            'torque.2',
                            'perturbationTorque.0',
                            'perturbationTorque.1',
                            'perturbationTorque.2',
                            'controlTorque.0',
                            'controlTorque.1',
                            'controlTorque.2',
                          ],
                          legend: [
                            'Total: x',
                            'Total: y',
                            'Total: z',
                            'Perturbations: x',
                            'Perturbations: y',
                            'Perturbations: z',
                            'Control: x',
                            'Control: y',
                            'Control: z',
                          ],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Total torque, total perturbation torque, and total control torque',
                  title: 'Total Torques',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCGravity-${Math.random()}`,
                  name: 'Gravity Gradient Torque',
                  plots: [
                    {
                      label: 'Torque, S/C Body-Frame',
                      type: 'line',
                      unit: 'N-m',
                      variables: [
                        {
                          keys: [
                            'gravityGradientTorque.0',
                            'gravityGradientTorque.1',
                            'gravityGradientTorque.2',
                          ],
                          legend: ['x-Axis', 'y-Axis', 'z-Axis'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Point-mass gravity field model',
                  title: 'Gravity Gradient Torque',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCDrag-${Math.random()}`,
                  name: 'Drag Torque',
                  plots: [
                    {
                      label: 'Torque, S/C Body-Frame',
                      type: 'line',
                      unit: 'N-m',
                      variables: [
                        {
                          keys: ['dragTorque.0', 'dragTorque.1', 'dragTorque.2'],
                          legend: ['x-Axis', 'y-Axis', 'z-Axis'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Exponential atmosphere model, panelized external geometry',
                  title: 'Drag Torque',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCSolar-${Math.random()}`,
                  name: 'Solar Radiation Pressure Torque',
                  plots: [
                    {
                      label: 'Torque, S/C Body-Frame',
                      type: 'line',
                      unit: 'N-m',
                      variables: [
                        {
                          keys: ['srpTorque.0', 'srpTorque.1', 'srpTorque.2'],
                          legend: ['x-Axis', 'y-Axis', 'z-Axis'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Panelized external geometry',
                  title: 'Solar Radiation Pressure Torque',
                  type: 'WidgetSpec',
                },
              ],
              menuItems: [],
            },
            {
              id: `$GNCEstimation-${Math.random()}`,
              menuItems: [],
              title: 'Estimation',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$GNCAttitudeSolution-${Math.random()}`,
                  name: 'Attitude Solution',
                  plots: [
                    {
                      label: 'ECI Error Angles',
                      type: 'line',
                      unit: 'rad',
                      variables: [
                        {
                          keys: [
                            'attitudeSolutionError.0',
                            'attitudeSolutionError.1',
                            'attitudeSolutionError.2',
                          ],
                          legend: [
                            'AD Solution Error: Yaw',
                            'AD Solution Error: Pitch',
                            'AD Solution Error: Roll',
                          ],
                        },
                      ],
                    },
                    {
                      label: 'Body Frame Angular Velocity Error',
                      type: 'line',
                      unit: 'rpm',
                      variables: [
                        {
                          keys: [
                            'angularVelocitySolutionError.rpm.0',
                            'angularVelocitySolutionError.rpm.1',
                            'angularVelocitySolutionError.rpm.2',
                          ],
                          legend: [
                            'AV Solution Error: Axis-x',
                            'AV Solution Error: Axis-y',
                            'AV Solution Error: Axis-z',
                          ],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Estimate minus truth from attitude determination (AD) algorithms',
                  title: 'Attitude Solution',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCOrbitSolution-${Math.random()}`,
                  name: 'Orbit Solution',
                  plots: [
                    {
                      label: 'ECI Position Error',
                      type: 'line',
                      unit: 'km',
                      variables: [
                        {
                          keys: [
                            'positionSolutionError.0',
                            'positionSolutionError.1',
                            'positionSolutionError.2',
                          ],
                          legend: [
                            'OD Solution Error: x-Position',
                            'OD Solution Error: y-Position',
                            'OD Solution Error: z-Position',
                          ],
                        },
                      ],
                    },
                    {
                      label: 'ECI Velocity Error',
                      type: 'line',
                      unit: 'km/s',
                      variables: [
                        {
                          keys: [
                            'velocitySolutionError.0',
                            'velocitySolutionError.1',
                            'velocitySolutionError.2',
                          ],
                          legend: [
                            'OD Solution Error: x-Velocity',
                            'OD Solution Error: y-Velocity',
                            'OD Solution Error: z-Velocity',
                          ],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Estimate minus truth from orbit determination (OD) algorithms',
                  title: 'Orbit Solution',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCDirectionSensors-${Math.random()}`,
                  each: 'DirectionSensor',
                  name: 'All Direction Sensors',
                  plots: [
                    {
                      label: 'Unit Vector Components, S/C Body-Frame',
                      type: 'line',
                      variables: [
                        {
                          keys: [
                            'truth.0',
                            'truth.1',
                            'truth.2',
                            'measurement.0',
                            'measurement.1',
                            'measurement.2',
                          ],
                          legend: [
                            'Truth: x-Component',
                            'Truth: y-Component',
                            'Truth: z-Component',
                            'Measurement: x-Component',
                            'Measurement: y-Component',
                            'Measurement: z-Component',
                          ],
                        },
                      ],
                    },
                  ],
                  title: '"{name}" Direction Sensor',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCVectorSensors-${Math.random()}`,
                  each: 'VectorSensor',
                  name: 'All Vector Sensors',
                  plots: [
                    {
                      label: 'Vector Components, S/C Body Frame',
                      type: 'line',
                      variables: [
                        {
                          keys: [
                            'referenceVector.truth.eci.0',
                            'referenceVector.truth.eci.1',
                            'referenceVector.truth.eci.2',
                            'measurement.0',
                            'measurement.1',
                            'measurement.2',
                          ],
                          legend: [
                            'Truth: x-Component',
                            'Truth: y-Component',
                            'Truth: z-Component',
                            'Measurement: x-Component',
                            'Measurement: y-Component',
                            'Measurement: z-Component',
                          ],
                        },
                      ],
                    },
                  ],
                  title: '"{name}" Vector Sensor',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCAngularVelocitySensors-${Math.random()}`,
                  each: 'AngularVelocitySensor',
                  name: 'All Angular Velocity Sensors',
                  plots: [
                    {
                      label: 'Rates, S/C Body Frame',
                      type: 'line',
                      unit: 'rpm',
                      variables: [
                        {
                          keys: [
                            'root.angularVelocity.rpm.0',
                            'root.angularVelocity.rpm.1',
                            'root.angularVelocity.rpm.2',
                            'measurement.rpm.0',
                            'measurement.rpm.1',
                            'measurement.rpm.2',
                          ],
                          legend: [
                            'Truth: x-Component',
                            'Truth: y-Component',
                            'Truth: z-Component',
                            'Measurement: x-Component',
                            'Measurement: y-Component',
                            'Measurement: z-Component',
                          ],
                        },
                      ],
                    },
                  ],
                  title: '"{name}" Angular Velocity Sensor',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCPositionSensors-${Math.random()}`,
                  each: 'PositionSensor',
                  name: 'All Position Sensors',
                  plots: [
                    {
                      label: 'Position, ECI',
                      type: 'line',
                      unit: 'km',
                      variables: [
                        {
                          keys: [
                            'root.position.eci.0',
                            'root.position.eci.1',
                            'root.position.eci.2',
                            'measurement.0',
                            'measurement.1',
                            'measurement.2',
                          ],
                          legend: [
                            'Truth: x-Position',
                            'Truth: y-Position',
                            'Truth: z-Position',
                            'Measurement: x-Position',
                            'Measurement: y-Position',
                            'Measurement: z-Position',
                          ],
                        },
                      ],
                    },
                  ],
                  subtitle: '',
                  title: '"{name}" Position Sensor',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCAttitudeSensors-${Math.random()}`,
                  each: 'OpticalAttitudeSensor',
                  name: 'All Optical Attitude Sensors',
                  plots: [
                    {
                      label: 'Quaternion Components, ECI-to-S/C Body Frame',
                      type: 'line',
                      variables: [
                        {
                          keys: [
                            'root.attitude.body_eci.0',
                            'root.attitude.body_eci.1',
                            'root.attitude.body_eci.2',
                            'root.attitude.body_eci.3',
                            'measurement.0',
                            'measurement.1',
                            'measurement.2',
                            'measurement.3',
                          ],
                          legend: [
                            'Truth: Axis-x',
                            'Truth: Axis-y',
                            'Truth: Axis-z',
                            'Truth: Scalar',
                            'Measurement: Axis-x',
                            'Measurement: Axis-y',
                            'Measurement: Axis-z',
                            'Measurement: Scalar',
                          ],
                        },
                      ],
                    },
                  ],
                  title: '"{name}" Optical Attitude Sensor',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$GNCAttitudeControl-${Math.random()}`,
              menuItems: [],
              title: 'Attitude Control',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$GNCAttitudeError-${Math.random()}`,
                  name: 'Attitude Error',
                  plots: [
                    {
                      label: 'ECI Error Angles',
                      type: 'line',
                      unit: 'rad',
                      variables: [
                        {
                          keys: ['attitudeError.0', 'attitudeError.1', 'attitudeError.2'],
                          legend: ['Error: Yaw', 'Error: Pitch', 'Error: Roll'],
                        },
                      ],
                    },
                    {
                      label: 'Body Frame Angular Velocity Error',
                      type: 'line',
                      unit: 'rpm',
                      variables: [
                        {
                          keys: [
                            'angularVelocityError.rpm.0',
                            'angularVelocityError.rpm.1',
                            'angularVelocityError.rpm.2',
                          ],
                          legend: ['Error: x', 'Error: y', 'Error: z'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Commanded minus truth attitude and angular velocity',
                  title: 'Attitude Error',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCReactionWheels-${Math.random()}`,
                  name: 'Reaction Wheels',
                  plots: [
                    {
                      label: 'Commanded Torque',
                      type: 'line',
                      unit: 'N-m',
                      variables: [
                        {
                          keys: ['ReactionWheel.$each.commandedTorqueMagnitude'],
                          legend: ['{name}'],
                        },
                      ],
                    },
                    {
                      label: 'Speed',
                      type: 'line',
                      unit: 'rad/s',
                      variables: [{ keys: ['ReactionWheel.$each.speed'], legend: ['{name}'] }],
                    },
                    {
                      label: 'Angular Momentum',
                      type: 'line',
                      unit: 'kg m^2 / s',
                      variables: [{ keys: ['ReactionWheel.$each.momentum'], legend: ['{name}'] }],
                    },
                    {
                      label: 'Torque Margin',
                      type: 'line',
                      variables: [
                        { keys: ['ReactionWheel.$each.torqueMargin'], legend: ['{name}'] },
                      ],
                    },
                    {
                      label: 'Momentum Margin',
                      type: 'line',
                      variables: [
                        { keys: ['ReactionWheel.$each.momentumMargin'], legend: ['{name}'] },
                      ],
                    },
                  ],
                  title: 'Reaction Wheels',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCMagnetorquers-${Math.random()}`,
                  name: 'Magnetorquers',
                  plots: [
                    {
                      label: 'Commanded Torque',
                      type: 'line',
                      unit: 'N-m',
                      variables: [
                        {
                          keys: ['Magnetorquer.$each.commandedTorqueMagnitude'],
                          legend: ['{name}'],
                        },
                      ],
                    },
                    {
                      label: 'Magnetic Moment',
                      type: 'line',
                      unit: 'N-m/T',
                      variables: [
                        { keys: ['Magnetorquer.$each.magneticMoment'], legend: ['{name}'] },
                      ],
                    },
                    {
                      label: 'Magnetic Moment Margin',
                      type: 'line',
                      variables: [
                        { keys: ['Magnetorquer.$each.magneticMomentMargin'], legend: ['{name}'] },
                      ],
                    },
                    {
                      label: 'Duty Cycle',
                      type: 'line',
                      variables: [
                        { keys: ['Magnetorquer.$each.pwmDutyCycle'], legend: ['{name}'] },
                      ],
                    },
                  ],
                  title: 'Magnetorquers',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$GNCPropulsion-${Math.random()}`,
              menuItems: [],
              title: 'Propulsion',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$GNCThrust-${Math.random()}`,
                  name: 'Thrust',
                  plots: [
                    {
                      label: 'Thrust Magnitude',
                      type: 'line',
                      unit: 'N',
                      variables: [{ keys: ['Thruster.$each.thrust'], legend: ['{name}'] }],
                    },
                  ],
                  title: 'Thrust',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCFuel-${Math.random()}`,
                  name: 'Fuel',
                  plots: [
                    {
                      label: 'Fuel Mass',
                      type: 'line',
                      unit: 'kg',
                      variables: [{ keys: ['FuelTank.$each.wetMass'], legend: ['{name}'] }],
                    },
                  ],
                  title: 'Fuel',
                  type: 'WidgetSpec',
                },
              ],
            },
          ],
        },
        {
          id: `$GNCSteps-${Math.random()}`,
          menuItems: [
            {
              id: `$GNCAttitudeControl-${Math.random()}`,
              menuItems: [],
              title: 'Attitude Control',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$GNCRWUnit-${Math.random()}`,
                  name: 'Reaction Wheel Utilization',
                  plots: [
                    {
                      type: 'pie',
                      unit: 'wday',
                      variables: [
                        {
                          keys: ['ReactionWheel.$each.powerConsumed'],
                          legend: ['{name}'],
                          ops: ['integral'],
                        },
                      ],
                    },
                  ],
                  title: 'Reaction Wheel Utilization',
                  type: 'WidgetSpec',
                },
                {
                  id: `$GNCMagUtil-${Math.random()}`,
                  name: 'Magnetorquer Utilization',
                  plots: [
                    {
                      type: 'pie',
                      unit: 'wday',
                      variables: [
                        {
                          keys: ['Magnetorquer.$each.powerConsumed'],
                          legend: ['{name}'],
                          ops: ['integral'],
                        },
                      ],
                    },
                  ],
                  title: 'Magnetorquer Utilization',
                  type: 'WidgetSpec',
                },
              ],
            },
          ],
          title: 'Window Statistics',
          type: 'MenuItem',
          widgetSpecs: [],
        },
      ],
    }),
  },
  {
    label: 'Command & Control',
    value: () => ({
      id: `$CDH-${Math.random()}`,
      menuItems: [
        {
          id: `$CDHPlayback-${Math.random()}`,
          menuItems: [],
          playback: ['mode', 'conops'],
          title: 'Playback',
          type: 'MenuItem',
          widgetSpecs: [],
        },
        {
          id: `$CDHTS-${Math.random()}`,
          menuItems: [
            {
              id: `$OpModes-${Math.random()}`,
              menuItems: [],
              title: 'Operational Modes',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$ConOpsChart-${Math.random()}`,
                  name: 'ConOps',
                  plots: [{ type: 'conops' }],
                  title: 'ConOps Logic and Active Operational Mode',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$Conditions-${Math.random()}`,
              menuItems: [],
              title: 'Conditions',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$ConditionsChart-${Math.random()}`,
                  name: 'Conditions',
                  plots: [
                    {
                      name: 'Condition Status',
                      type: 'bool',
                      variables: [{ keys: ['Condition.$each.compliance'], legend: ['{name}'] }],
                    },
                  ],
                  title: 'Conditions',
                  type: 'WidgetSpec',
                },
                {
                  each: 'CompoundCondition',
                  id: `$CompoundConditions-${Math.random()}`,
                  name: 'All Compound Conditions',
                  plots: [
                    {
                      name: 'Condition Status',
                      type: 'bool',
                      variables: [
                        {
                          keys: ['compliance', 'conditions.$each.compliance'],
                          legend: ['Overall Status', '{name}'],
                        },
                      ],
                    },
                  ],
                  title: 'Compound Condition: "{name}"',
                  type: 'WidgetSpec',
                },
              ],
            },
          ],
          title: 'Time Series',
          type: 'MenuItem',
          widgetSpecs: [],
        },
        {
          id: `$CDHWS-${Math.random()}`,
          menuItems: [
            {
              id: `$OperationalModes-${Math.random()}`,
              menuItems: [],
              title: 'Operational Modes',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$OpModeOverview-${Math.random()}`,
                  name: 'OpMode Overview',
                  plots: [
                    {
                      type: 'pie',
                      unit: 'days',
                      variables: [
                        {
                          each: 'OperationalMode',
                          keys: ['isActive'],
                          legend: ['{name}'],
                          ops: ['integral'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Proportion of time each operational mode was active',
                  title: 'Operational Modes Overview',
                  type: 'WidgetSpec',
                },
              ],
            },
          ],
          title: 'Window Statistics',
          type: 'MenuItem',
          widgetSpecs: [],
        },
      ],
      title: 'Command & Control',
      type: 'MenuItem',
      widgetSpecs: [],
    }),
  },
  {
    label: 'Targets',
    value: () => ({
      id: `$Targets-${Math.random()}`,
      menuItems: [
        {
          id: `$TargetSeries-${Math.random()}`,
          menuItems: [],
          title: 'Targets',
          type: 'MenuItem',
          widgetSpecs: [
            {
              id: `$LoS-${Math.random()}`,
              name: 'Line Of Sight',
              plots: [
                {
                  name: 'Ground Target',
                  type: 'bool',
                  variables: [{ keys: ['GroundTarget.$each.lineOfSight'], legend: ['{name}'] }],
                },
                {
                  name: 'Space Target',
                  type: 'bool',
                  variables: [{ keys: ['SpaceTarget.$each.lineOfSight'], legend: ['{name}'] }],
                },
                {
                  name: 'Celestial Target',
                  type: 'bool',
                  variables: [{ keys: ['CelestialTarget.$each.lineOfSight'], legend: ['{name}'] }],
                },
              ],
              title: 'Line Of Sight',
              type: 'WidgetSpec',
            },
            {
              id: `$Shadow-${Math.random()}`,
              name: 'Shadow',
              plots: [
                {
                  name: 'Ground Target',
                  type: 'bool',
                  variables: [{ keys: ['GroundTarget.$each.shadow'], legend: ['{name}'] }],
                },
                {
                  name: 'Space Target',
                  type: 'bool',
                  variables: [{ keys: ['SpaceTarget.$each.shadow'], legend: ['{name}'] }],
                },
              ],
              title: 'Shadow',
              type: 'WidgetSpec',
            },
            {
              each: 'GroundTarget',
              id: `$GTs-${Math.random()}`,
              name: 'All Ground Targets',
              plots: [
                {
                  label: 'Angle',
                  labelRight: 'Range',
                  name: 'Azimuth, Elevation, and Range',
                  type: 'line',
                  unit: 'deg',
                  unitRight: 'km',
                  variables: [
                    {
                      keys: ['agentAzimuth.deg', 'agentElevation.deg'],
                      legend: ['Azimuth', 'Elevation'],
                    },
                    { keys: ['range'], legend: ['Range'], right: true },
                  ],
                },
                {
                  label: 'Angle',
                  labelRight: 'Local Sidereal Time',
                  name: 'Solar Location and Sidereal Time',
                  type: 'line',
                  unit: 'deg',
                  unitRight: 'deg',
                  variables: [
                    {
                      keys: ['solarAz.deg', 'solarEl.deg'],
                      legend: ['Solar Azimuth', 'Solar Elevation'],
                    },
                    { keys: ['lst.deg'], legend: ['Local Sidereal Time'], right: true },
                  ],
                },
              ],
              title: 'Ground Target: "{name}"',
              type: 'WidgetSpec',
            },
            {
              each: 'SpaceTarget',
              id: `$STs-${Math.random()}`,
              name: 'All Space Targets',
              plots: [
                {
                  label: 'Angle',
                  labelRight: 'Range',
                  name: 'Beta Angle, and Range',
                  type: 'line',
                  unit: 'deg',
                  unitRight: 'km',
                  variables: [
                    { keys: ['beta.deg'], legend: ['Beta Angle'] },
                    { keys: ['range.km'], legend: ['Range'], right: true },
                  ],
                },
              ],
              title: 'Space Target: "{name}"',
              type: 'WidgetSpec',
            },
            {
              each: 'CelestialTarget',
              id: `$CTs-${Math.random()}`,
              name: 'All Celestial Targets',
              plots: [
                {
                  label: 'Angle',
                  labelRight: 'Status',
                  name: 'Azimuth, Elevation, and Access',
                  type: 'line',
                  unit: 'deg',
                  variables: [
                    {
                      keys: ['agentAzimuth.deg', 'agentElevation.deg'],
                      legend: ['Azimuth', 'Elevation'],
                    },
                    { keys: ['lineOfSight'], legend: ['Line Of Sight'], right: true },
                  ],
                },
              ],
              title: 'Celestial Target: "{name}"',
              type: 'WidgetSpec',
            },
          ],
        },
      ],
      title: 'Targets',
      type: 'MenuItem',
      widgetSpecs: [],
    }),
  },
  {
    label: 'Data Handling',
    value: () => ({
      id: `$DataHandling-${Math.random()}`,
      menuItems: [
        {
          id: `$DataSeries-${Math.random()}`,
          menuItems: [
            {
              id: `$DataStorage-${Math.random()}`,
              menuItems: [],
              title: 'Data Storage',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  each: 'DataStorage',
                  id: `$Storages-${Math.random()}`,
                  name: 'All Data Storages',
                  plots: [
                    {
                      label: 'Utilization Fraction',
                      type: 'line',
                      variables: [{ keys: ['fillPercent'], legend: ['Total Storage'] }],
                    },
                    {
                      label: 'Transfer Rate',
                      type: 'line',
                      unit: 'bit/s',
                      variables: [{ keys: ['readRate', 'writeRate'], legend: ['Read', 'Write'] }],
                    },
                  ],
                  title: 'Data Storage "{name}"',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$DataTransfer-${Math.random()}`,
              menuItems: [],
              title: 'Data Transfer',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$IntInterfaces-${Math.random()}`,
                  name: 'Internal Data Interfaces',
                  plots: [
                    {
                      name: 'Active Interfaces',
                      type: 'bool',
                      variables: [
                        { keys: ['InternalDataInterface.$each.isActive'], legend: ['{name}'] },
                      ],
                    },
                    {
                      label: 'Transfer Rate',
                      type: 'line',
                      unit: 'bit/s',
                      variables: [
                        { keys: ['InternalDataInterface.$each.bitRate'], legend: ['{name}'] },
                      ],
                    },
                  ],
                  title: 'Internal Data Interfaces',
                  type: 'WidgetSpec',
                },
                {
                  id: `$ExtInterfaces-${Math.random()}`,
                  name: 'External Data Interfaces',
                  plots: [
                    {
                      name: 'Active Interfaces',
                      type: 'bool',
                      variables: [
                        { keys: ['ExternalDataInterface.$each.isActive'], legend: ['{name}'] },
                      ],
                    },
                    {
                      name: 'Link Possible',
                      type: 'bool',
                      variables: [
                        { keys: ['ExternalDataInterface.$each.canLink'], legend: ['{name}'] },
                      ],
                    },
                    {
                      label: 'Transfer Rate',
                      type: 'line',
                      unit: 'bit/s',
                      variables: [
                        { keys: ['ExternalDataInterface.$each.bitRate'], legend: ['{name}'] },
                      ],
                    },
                  ],
                  title: 'External Data Interfaces',
                  type: 'WidgetSpec',
                },
                {
                  id: `$Buses-${Math.random()}`,
                  name: 'Data Buses',
                  plots: [
                    {
                      label: 'Transfer Rate',
                      type: 'line',
                      unit: 'bit/s',
                      variables: [{ keys: ['DataBus.$each.bitRate'], legend: ['{name}'] }],
                    },
                  ],
                  title: 'Data Buses',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$DataModes-${Math.random()}`,
              menuItems: [],
              title: 'Data Modes',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$Modes-${Math.random()}`,
                  name: 'Data Modes',
                  plots: [
                    {
                      name: 'Active Modes',
                      type: 'bool',
                      variables: [{ keys: ['DataMode.$each.isActive'], legend: ['{name}'] }],
                    },
                  ],
                  title: 'Data Modes',
                  type: 'WidgetSpec',
                },
              ],
            },
          ],
          title: 'Time Series',
          type: 'MenuItem',
          widgetSpecs: [],
        },
      ],
      title: 'Data Handling',
      type: 'MenuItem',
      widgetSpecs: [],
    }),
  },
  {
    label: 'Power',
    value: () => ({
      id: `$Power-${Math.random()}`,
      menuItems: [
        {
          id: `$PowerPlayback-${Math.random()}`,
          menuItems: [],
          playback: ['eps-state', 'mode', 'power-flow'],
          title: 'Playback',
          type: 'MenuItem',
          widgetSpecs: [],
        },
        {
          id: `$PowerTS-${Math.random()}`,
          menuItems: [
            {
              id: `$PowerGen-${Math.random()}`,
              menuItems: [],
              title: 'Power Generation',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$GenOverview-${Math.random()}`,
                  name: 'Power Generation Overview',
                  plots: [
                    {
                      label: 'Power',
                      labelRight: 'Utilization',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        {
                          keys: ['totalSolarPowerGeneration', 'totalMaxSolarPower'],
                          legend: ['Total Power', 'Total Available Power'],
                        },
                        {
                          keys: ['totalSolarPowerUtilization'],
                          legend: ['Total Utilization'],
                          right: true,
                        },
                      ],
                    },
                  ],
                  subtitle: 'Total power for all solar arrays',
                  title: 'Power Generation Overview',
                  type: 'WidgetSpec',
                },
                {
                  each: 'SolarArray',
                  id: `$SolarArrays-${Math.random()}`,
                  name: 'All Solar Arrays',
                  plots: [
                    {
                      label: 'Current',
                      labelRight: 'Voltage',
                      name: 'Operating Point',
                      type: 'line',
                      unit: 'A',
                      unitRight: 'V',
                      variables: [
                        { keys: ['current'], legend: ['Current'] },
                        { keys: ['voltage'], legend: ['Voltage'], right: true },
                      ],
                    },
                    {
                      label: 'Power',
                      labelRight: 'Utilization',
                      name: 'Power Generation',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        { keys: ['power', 'maxPower'], legend: ['Power', 'Available Power'] },
                        { keys: ['utilization'], legend: ['Utilization'], right: true },
                      ],
                    },
                    {
                      label: 'Current',
                      name: 'Current By Panel',
                      type: 'line',
                      unit: 'A',
                      variables: [{ keys: ['panels.$each.current'], legend: ['{name}'] }],
                    },
                    {
                      label: 'Voltage',
                      name: 'Voltage By Panel',
                      type: 'line',
                      unit: 'V',
                      variables: [{ keys: ['panels.$each.voltage'], legend: ['{name}'] }],
                    },
                    {
                      label: 'Power',
                      name: 'Power By Panel',
                      type: 'line',
                      unit: 'W',
                      variables: [{ keys: ['panels.$each.power'], legend: ['{name}'] }],
                    },
                  ],
                  subtitle: 'For the array and all constituent solar panels',
                  title: '"{name}" Solar Array',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$EnergyStorage-${Math.random()}`,
              menuItems: [],
              title: 'Energy Storage',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$SoC-${Math.random()}`,
                  name: 'Battery SOC',
                  plots: [
                    {
                      label: 'State of Charge',
                      type: 'line',
                      variables: [{ keys: ['Battery.$0.soc'], legend: ['State of Charge (SoC)'] }],
                    },
                  ],
                  subtitle: '',
                  title: 'Battery State of Charge (SoC)',
                  type: 'WidgetSpec',
                },
                {
                  id: `$Voltage-${Math.random()}`,
                  name: 'Battery Voltage and Current',
                  plots: [
                    {
                      label: 'Voltage',
                      labelRight: 'Current',
                      step: 'end',
                      type: 'line',
                      unit: 'V',
                      unitRight: 'A',
                      variables: [
                        {
                          keys: ['Battery.$0.voltage', 'Battery.$0.voc'],
                          legend: ['Voltage', 'Open Circuit Voltage'],
                        },
                        { keys: ['Battery.$0.current'], legend: ['Current'], right: true },
                      ],
                    },
                  ],
                  subtitle: '',
                  title: 'Battery Voltage and Current',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$Processing-${Math.random()}`,
              menuItems: [],
              title: 'Power Processing',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$Controller-${Math.random()}`,
                  name: 'Power Controller',
                  plots: [
                    {
                      label: 'Power',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        {
                          keys: [
                            'powerProcessor.outputPowers.busRegulators',
                            'powerProcessor.outputPowers.loads',
                            'powerProcessor.outputPowers.total',
                          ],
                          legend: ['To Bus Regulators', 'To Loads', 'Total'],
                        },
                      ],
                    },
                    {
                      label: 'Output Power Margin',
                      step: 'end',
                      type: 'line',
                      variables: [
                        {
                          keys: ['powerProcessor.outputPowerMargin'],
                          legend: ['Output Power Margin'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Output powers and total output power margin',
                  title: 'Power Controller',
                  type: 'WidgetSpec',
                },
                {
                  each: 'BusRegulator',
                  id: `$Regulators-${Math.random()}`,
                  name: 'All Bus Regulators',
                  plots: [
                    {
                      label: 'Power',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        {
                          keys: [
                            'outputPowers.busRegulators',
                            'outputPowers.loads',
                            'outputPowers.total',
                          ],
                          legend: ['To Bus Regulators', 'To Loads', 'Total'],
                        },
                      ],
                    },
                    {
                      label: 'Output Power Margin',
                      step: 'end',
                      type: 'line',
                      variables: [{ keys: ['outputPowerMargin'], legend: ['Output Power Margin'] }],
                    },
                  ],
                  subtitle: 'Output power and total output power margin',
                  title: '"{name}" Bus Regulator',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$SubLoading-${Math.random()}`,
              menuItems: [],
              title: 'Subsystem Loading',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  each: 'Subsystem',
                  id: `$Loading-${Math.random()}`,
                  name: 'All Subsystem Electronic Loading',
                  plots: [
                    {
                      label: 'Power Consumed',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [{ keys: ['components.$each.powerConsumed'], legend: ['{name}'] }],
                    },
                  ],
                  subtitle: 'Loading by component',
                  title: '"{name}" Subsystem Electronic Loading',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$Dissipation-${Math.random()}`,
              menuItems: [],
              title: 'Dissipation',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$DissOverview-${Math.random()}`,
                  name: 'Dissipation Overview',
                  plots: [
                    {
                      label: 'Dissipation',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        { keys: ['Subsystem.$each.dissipations.total'], legend: ['{name}'] },
                      ],
                    },
                  ],
                  subtitle: 'Dissipation of each subsystem',
                  title: 'Dissipation Overview',
                  type: 'WidgetSpec',
                },
                {
                  id: `$ProcessingDiss-${Math.random()}`,
                  name: 'Power Processing Dissipation',
                  plots: [
                    {
                      label: 'Dissipation',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        {
                          keys: [
                            'powerProcessor.dissipations.powerController',
                            'powerProcessor.dissipations.internalLoads',
                            'powerProcessor.dissipations.busRegulators',
                            'powerProcessor.busRegulators.$each.dissipations.total',
                          ],
                          legend: [
                            'Power Controller Losses',
                            'Internal Loads',
                            'Bus Regulator Total',
                            '{name} Losses',
                          ],
                        },
                      ],
                    },
                  ],
                  subtitle:
                    'Dissipation of the power controller losses, internal loads, and bus regulator losses',
                  title: 'Power Processing Dissipation',
                  type: 'WidgetSpec',
                },
                {
                  each: 'SolarArray',
                  id: `$SolarDiss-${Math.random()}`,
                  name: 'All Solar Array Dissipation',
                  plots: [
                    {
                      each: 'panels',
                      label: 'Dissipation',
                      name: '"{name}" Solar Panel',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        {
                          keys: [
                            'dissipations.total',
                            'dissipations.blockingDiode',
                            'dissipations.internalLoads',
                          ],
                          legend: ['Total', 'Blocking Diode Losses', 'Internal Loads'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Dissipations by solar panel',
                  title: 'Power Generation Dissipation: "{name}" Solar Array',
                  type: 'WidgetSpec',
                },
                {
                  each: 'Battery',
                  id: `$StorageDiss-${Math.random()}`,
                  name: 'Energy Storage Dissipation',
                  plots: [
                    {
                      each: 'packs',
                      label: 'Dissipation',
                      name: '"{name}" Battery Pack',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        {
                          keys: [
                            'dissipations.total',
                            'dissipations.esr',
                            'dissipations.internalLoads',
                          ],
                          legend: ['Total', 'ESR Losses', 'Internal Loads'],
                        },
                      ],
                    },
                  ],
                  subtitle:
                    'Equivalent Series resistance (ESR) and internal load dissipations by battery pack',
                  title: 'Energy Storage Dissipation',
                  type: 'WidgetSpec',
                },
                {
                  each: 'Subsystem',
                  id: `$SubDiss-${Math.random()}`,
                  name: 'All Subsytem Dissipations',
                  plots: [
                    {
                      label: 'Dissipation',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        { keys: ['components.$each.dissipations.total'], legend: ['{name}'] },
                      ],
                    },
                  ],
                  subtitle: 'Dissipation by component',
                  title: '"{name}" Subsystem Dissipation',
                  type: 'WidgetSpec',
                },
              ],
            },
          ],
          title: 'Time Series',
          type: 'MenuItem',
          widgetSpecs: [],
        },
        {
          id: `$PowerWS-${Math.random()}`,
          menuItems: [
            {
              id: `$WSPowerGen-${Math.random()}`,
              menuItems: [],
              title: 'Power Generation',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$WSGen-${Math.random()}`,
                  name: 'Power Generation Overview',
                  plots: [
                    {
                      name: 'Average Power Generation',
                      type: 'horizontal-bar',
                      unit: 'w',
                      variables: [
                        {
                          each: 'SolarArray',
                          keys: ['power'],
                          legend: ['{name}'],
                          ops: ['avg'],
                          variables: [
                            { each: 'panels', keys: ['power'], legend: ['{name}'], ops: ['avg'] },
                          ],
                        },
                      ],
                    },
                    {
                      name: 'Average Utilization',
                      type: 'horizontal-bar',
                      unit: 'pct',
                      variables: [
                        {
                          each: 'SolarArray',
                          keys: ['utilization'],
                          legend: ['{name}'],
                          ops: ['avg'],
                          variables: [
                            {
                              each: 'panels',
                              keys: ['utilization'],
                              legend: ['{name}'],
                              ops: ['avg'],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                  subtitle:
                    'Average power generation and percent utilization of available power for solar arrays and constituent panels',
                  title: 'Power Generation Overview',
                  type: 'WidgetSpec',
                },
                {
                  each: 'SolarArray',
                  id: `$WSSolarGen-${Math.random()}`,
                  name: 'All Solar Array Power Generation',
                  plots: [
                    {
                      name: 'Energy Generation by Panel',
                      type: 'pie',
                      unit: 'wday',
                      variables: [{ keys: ['panels.$each.power'], legend: ['{name}'] }],
                    },
                  ],
                  title: '"{name}" Power Generation',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$WSStorage-${Math.random()}`,
              menuItems: [],
              title: 'Energy Storage',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$WSEnergyStore-${Math.random()}`,
                  name: 'Energy Storage Overview',
                  plots: [
                    {
                      type: 'table',
                      variables: [
                        {
                          keys: [
                            'powerProcessor.battery.soc',
                            'powerProcessor.battery.soc',
                            'powerProcessor.battery.dischargeCurrentMargin',
                          ],
                          legend: [
                            'Average State of Charge',
                            'Minimum State of Charge',
                            'Minimum Discharge Current Margin',
                          ],
                          ops: ['avg', 'min', 'min'],
                          unit: 'pct',
                        },
                        {
                          keys: [
                            'powerProcessor.battery.current',
                            'powerProcessor.battery.current',
                          ],
                          legend: ['Max Charge Current', 'Max Discharge Current'],
                          ops: ['positiveMax', 'negativeMax'],
                          unit: 'a',
                        },
                      ],
                    },
                  ],
                  subtitle: 'Overview of battery performance metrics',
                  title: 'Energy Storage Overview',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$WSProcessing-${Math.random()}`,
              menuItems: [],
              title: 'Power Processing',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$WSOutPower-${Math.random()}`,
                  name: 'Output Power Overview',
                  plots: [
                    {
                      type: 'table',
                      variables: [
                        {
                          legend: ['Power Processor'],
                          variables: [
                            {
                              keys: [
                                'powerProcessor.outputPowers.total',
                                'powerProcessor.outputPowers.total',
                              ],
                              legend: ['Average Output Power', 'Max Output Power'],
                              ops: ['avg', 'max'],
                              unit: 'w',
                            },
                          ],
                        },
                        {
                          each: 'BusRegulator',
                          legend: ['{name}'],
                          variables: [
                            {
                              keys: ['outputPowers.total', 'outputPowers.total'],
                              legend: ['Average Output Power', 'Max Output Power'],
                              ops: ['avg', 'max'],
                              unit: 'w',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                  subtitle:
                    'Maximum and average output power of power controller and bus regulators',
                  title: 'Output Power Overview',
                  type: 'WidgetSpec',
                },
                {
                  id: `$MinMargin-${Math.random()}`,
                  name: 'Minimum Margins',
                  plots: [
                    {
                      type: 'margin',
                      unit: 'pct',
                      variables: [
                        {
                          keys: [
                            'powerProcessor.outputPowerMargin',
                            'powerProcessor.busRegulators.$each.outputPowerMargin',
                          ],
                          legend: ['Power Processor', '{name}'],
                          ops: ['min', 'min'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Minimum power margin of power controller and bus regulators',
                  title: 'Minimum Margins',
                  type: 'WidgetSpec',
                },
                {
                  id: `$WSPowProcess-${Math.random()}`,
                  name: 'Power Processing',
                  plots: [
                    {
                      name: 'Energy Output of Power Controller and Bus Regulators',
                      type: 'pie',
                      unit: 'wday',
                      variables: [
                        {
                          keys: [
                            'powerProcessor.outputPowers.loads',
                            'powerProcessor.busRegulators.$each.outputPowers.total',
                          ],
                          legend: ['Power Controller', '{name}'],
                        },
                      ],
                    },
                  ],
                  title: 'Power Processing',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$WSSubLoading-${Math.random()}`,
              menuItems: [],
              title: 'Subsystem Loading',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$SubConsume-${Math.random()}`,
                  name: 'Power Consumption by Subsystem',
                  plots: [
                    {
                      name: 'Energy Consumption by Subsystem',
                      type: 'pie',
                      unit: 'wday',
                      variables: [{ keys: ['Subsystem.$each.powerConsumed'], legend: ['{name}'] }],
                    },
                  ],
                  title: 'Power Consumption by Subsystem',
                  type: 'WidgetSpec',
                },
                {
                  each: 'Subsystem',
                  id: `$SubsConsume-${Math.random()}`,
                  name: 'All Subsystem Power Consumption',
                  plots: [
                    {
                      name: 'Energy Consumption by Component',
                      type: 'pie',
                      unit: 'wday',
                      variables: [{ keys: ['components.$each.powerConsumed'], legend: ['{name}'] }],
                    },
                  ],
                  title: '"{name}" Power Consumption',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$WSDissipation-${Math.random()}`,
              menuItems: [],
              title: 'Dissipation',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$WSSubDiss-${Math.random()}`,
                  name: 'Subsystem Dissipation',
                  plots: [
                    {
                      type: 'pie',
                      unit: 'wday',
                      variables: [
                        { keys: ['Subsystem.$each.dissipations.total'], legend: ['{name}'] },
                      ],
                    },
                  ],
                  title: 'Subsystem Dissipation',
                  type: 'WidgetSpec',
                },
                {
                  id: `$WSProcessDiss-${Math.random()}`,
                  name: 'Power Processing Dissipation',
                  plots: [
                    {
                      type: 'pie',
                      unit: 'wday',
                      variables: [
                        {
                          keys: [
                            'powerProcessor.dissipations.powerController',
                            'powerProcessor.dissipations.internalLoads',
                            'powerProcessor.dissipations.busRegulators',
                          ],
                          legend: ['Power Controller', 'Internal Loads', 'Bus Regulators'],
                        },
                      ],
                    },
                  ],
                  title: 'Power Processing Dissipation',
                  type: 'WidgetSpec',
                },
                {
                  id: `$WSGenDiss-${Math.random()}`,
                  name: 'Power Generation Dissipation',
                  plots: [
                    {
                      each: 'SolarArray',
                      name: 'Solar Array: {name}',
                      type: 'pie',
                      unit: 'wday',
                      variables: [
                        { keys: ['panels.$each.dissipations.total'], legend: ['{name}'] },
                      ],
                    },
                  ],
                  title: 'Power Generation Dissipation',
                  type: 'WidgetSpec',
                },
                {
                  id: `$WSStoreDiss-${Math.random()}`,
                  name: 'Energy Storage Dissipation',
                  plots: [
                    {
                      type: 'pie',
                      unit: 'wday',
                      variables: [
                        {
                          keys: ['powerProcessor.battery.packs.$each.dissipations.total'],
                          legend: ['{name}'],
                        },
                      ],
                    },
                  ],
                  title: 'Energy Storage Dissipation',
                  type: 'WidgetSpec',
                },
                {
                  each: 'Subsystem',
                  id: `$WSSubsDiss-${Math.random()}`,
                  name: 'All Subsystem Dissipation',
                  plots: [
                    {
                      type: 'pie',
                      unit: 'wday',
                      variables: [
                        { keys: ['components.$each.dissipations.total'], legend: ['{name}'] },
                      ],
                    },
                  ],
                  title: '"{name}" Subsystem Dissipation',
                  type: 'WidgetSpec',
                },
              ],
            },
          ],
          title: 'Window Statistics',
          type: 'MenuItem',
          widgetSpecs: [],
        },
      ],
      title: 'Power',
      type: 'MenuItem',
      widgetSpecs: [],
    }),
  },
  {
    label: 'Thermal',
    value: () => ({
      id: `$Thermal-${Math.random()}`,
      menuItems: [
        {
          id: `$ThermalPlayback-${Math.random()}`,
          menuItems: [],
          playback: ['thermal-state', 'thermal-flow'],
          title: 'Playback',
          type: 'MenuItem',
          widgetSpecs: [],
        },
        {
          id: `$ThermalTS-${Math.random()}`,
          menuItems: [
            {
              id: `$TCD-${Math.random()}`,
              menuItems: [],
              title: 'Component Dissipation',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$TDO-${Math.random()}`,
                  name: 'Dissipation Overview',
                  plots: [
                    {
                      label: 'Thermal Dissipation',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        {
                          keys: ['Subsystem.$each.dissipations.total'],
                          legend: ['"{name}" Total Dissipation'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Dissipation by Subsystem',
                  title: 'Dissipation Overview',
                  type: 'WidgetSpec',
                },
                {
                  each: 'Subsystem',
                  id: `$TASD-${Math.random()}`,
                  name: 'All Subsystem Dissipations',
                  plots: [
                    {
                      label: 'Dissipation',
                      step: 'end',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        {
                          keys: ['dissipations.total', 'components.$each.dissipations.total'],
                          legend: ['Total Dissipation', '"{name}" Dissipation'],
                        },
                      ],
                    },
                  ],
                  subtitle: 'Dissipation by Component',
                  title: '"{name}" Subsystem Dissipation',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$TSRHT-${Math.random()}`,
              menuItems: [],
              title: 'Surface Radiative Heat Transfer',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  each: 'Surface',
                  id: `$TASRHT-${Math.random()}`,
                  name: 'All Surface Radiative Heat Transfers',
                  plots: [
                    {
                      label: 'Thermal Dissipation Rate',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        {
                          keys: [
                            'heatFlowRate',
                            'spaceHeatFlowRate',
                            'solarHeatFlowRate',
                            'earthIrHeatFlowRate',
                            'earthAlbedoHeatFlowRate',
                          ],
                          legend: ['Total', 'Space', 'Solar', 'Earth IR', 'Earth Albedo'],
                        },
                      ],
                    },
                  ],
                  subtitle: '',
                  title: '"{name}" Radiative Heat Transfer',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$TTC-${Math.random()}`,
              menuItems: [],
              title: 'Temperature Controllers',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  each: 'Cooler',
                  id: `$TACTC-${Math.random()}`,
                  name: 'All Cooler Temperature Controllers',
                  plots: [
                    {
                      label: 'Heat Flow Rate',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        { keys: ['regHeatFlowRate'], legend: ['"{name}" Heat Flow Rate'] },
                      ],
                    },
                    {
                      label: 'Regulated Component Temperature',
                      type: 'line',
                      unit: 'C',
                      variables: [
                        {
                          keys: ['commandedTemperature.degC', 'temperature.degC'],
                          legend: ['Target Temperature', '"{name}" Actual Temperature'],
                        },
                      ],
                    },
                  ],
                  subtitle: '',
                  title: '"{name}" Temperature Controller - COOLER',
                  type: 'WidgetSpec',
                },
                {
                  each: 'Heater',
                  id: `$TAHTC-${Math.random()}`,
                  name: 'All Heater Temperature Controllers',
                  plots: [
                    {
                      label: 'Heat Flow Rate',
                      type: 'line',
                      unit: 'W',
                      variables: [
                        { keys: ['regHeatFlowRate'], legend: ['"{name}" Heat Flow Rate'] },
                      ],
                    },
                    {
                      label: 'Regulated Component Temperature',
                      type: 'line',
                      unit: 'C',
                      variables: [
                        {
                          keys: ['commandedTemperature.degC', 'temperature.degC'],
                          legend: ['Commanded Temperature', '"{name}" Actual Temperature'],
                        },
                      ],
                    },
                  ],
                  subtitle: '',
                  title: '"{name}" Temperature Controller - HEATER',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$TTI-${Math.random()}`,
              menuItems: [],
              title: 'Thermal Interface',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  each: 'ThermalInterface',
                  id: `$TATI-${Math.random()}`,
                  name: 'All Thermal Interfaces',
                  plots: [
                    {
                      label: 'Thermal Heat Flow Rate',
                      type: 'line',
                      unit: 'W',
                      variables: [{ keys: ['heatFlowRate'], legend: ['Component Heat Flow Rate'] }],
                    },
                    {
                      label: 'Temperatures',
                      labelRight: 'Delta',
                      type: 'line',
                      unit: 'C',
                      unitRight: 'C',
                      variables: [
                        {
                          keys: ['hotTemp.degC', 'coldTemp.degC'],
                          legend: ['Hot Temp', 'Cold Temp'],
                        },
                        { keys: ['tempDelta'], legend: ['Temperature Delta'], right: true },
                      ],
                    },
                    {
                      label: 'Hot/Cold Margin',
                      type: 'line',
                      unit: 'C',
                      variables: [
                        {
                          keys: ['hotMargin', 'coldMargin'],
                          legend: ['Hot Margin', 'Cold Margin'],
                        },
                      ],
                    },
                  ],
                  subtitle: '',
                  title: '"{name}" Thermal Interface',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$TCT-${Math.random()}`,
              menuItems: [],
              title: 'Component Temperatures',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  each: 'Subsystem',
                  id: `$TAS-${Math.random()}`,
                  name: 'All Subsystems',
                  plots: [
                    {
                      label: 'Temperature',
                      type: 'line',
                      unit: 'C',
                      variables: [
                        { keys: ['components.$each.temperature.degC'], legend: ['"{name}" Temp'] },
                      ],
                    },
                    {
                      label: 'Hot Margin',
                      type: 'line',
                      unit: 'C',
                      variables: [
                        { keys: ['components.$each.hotMargin'], legend: ['"{name}" Hot Margin'] },
                      ],
                    },
                    {
                      label: 'Cold Margin',
                      type: 'line',
                      unit: 'C',
                      variables: [
                        { keys: ['components.$each.coldMargin'], legend: ['"{name}" Cold Margin'] },
                      ],
                    },
                  ],
                  subtitle: '',
                  title: '"{name}"',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$TST-${Math.random()}`,
              menuItems: [],
              title: 'Surface Temperatures',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  each: 'Surface',
                  id: `$TASS-${Math.random()}`,
                  name: 'All Surfaces',
                  plots: [
                    {
                      label: 'Temperature',
                      type: 'line',
                      unit: 'C',
                      variables: [{ keys: ['temperature.degC'], legend: ['"{name}" Temperature'] }],
                    },
                    {
                      label: 'Hot Margin',
                      type: 'line',
                      unit: 'C',
                      variables: [{ keys: ['hotMargin'], legend: ['"{name}" Hot Margin'] }],
                    },
                    {
                      label: 'Cold Margin',
                      type: 'line',
                      unit: 'C',
                      variables: [{ keys: ['coldMargin'], legend: ['"{name}" Cold Margin'] }],
                    },
                  ],
                  subtitle: '',
                  title: '"{name}"',
                  type: 'WidgetSpec',
                },
              ],
            },
          ],
          title: 'Time Series',
          type: 'MenuItem',
          widgetSpecs: [],
        },
        {
          id: `$ThermalWS-${Math.random()}`,
          menuItems: [
            {
              id: `$WSTSRHT-${Math.random()}`,
              menuItems: [],
              title: 'Surface Radiative Heat Transfer',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$TSATRHT-${Math.random()}`,
                  name: 'Surface Average Total Radiative Heat Transfer',
                  plots: [
                    {
                      type: 'margin',
                      unit: 'wday',
                      variables: [
                        {
                          each: 'Surface',
                          keys: ['heatFlowRate'],
                          legend: ['{name}'],
                          ops: ['integral'],
                          variables: [{ keys: [], legend: [], ops: [], variables: [] }],
                        },
                      ],
                    },
                  ],
                  title: 'Surface Average Total Radiative Heat Transfer',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$WSTTI-${Math.random()}`,
              menuItems: [],
              title: 'Thermal Interface',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$TTIATD-${Math.random()}`,
                  name: 'Thermal Interface Average Temperature Delta',
                  plots: [
                    {
                      type: 'horizontal-bar',
                      unit: 'c',
                      variables: [
                        {
                          each: 'ThermalInterface',
                          keys: ['tempDelta'],
                          legend: ['{name}'],
                          ops: ['absAvg'],
                          variables: [{ keys: [], legend: [], ops: [], variables: [] }],
                        },
                      ],
                    },
                  ],
                  subtitle: '(Absolute values)',
                  title: 'Thermal Interface Average Temperature Delta',
                  type: 'WidgetSpec',
                },
                {
                  id: `$TIMHM-${Math.random()}`,
                  name: 'Thermal Interface Minimum Hot Margin',
                  plots: [
                    {
                      type: 'margin',
                      unit: 'c',
                      variables: [
                        {
                          each: 'ThermalInterface',
                          keys: ['hotMargin'],
                          legend: ['{name}'],
                          ops: ['min'],
                        },
                      ],
                    },
                  ],
                  title: 'Thermal Interface Minimum Hot Margin',
                  type: 'WidgetSpec',
                },
                {
                  id: `$TTIMCM-${Math.random()}`,
                  name: 'Thermal Interface Minimum Cold Margin',
                  plots: [
                    {
                      type: 'margin',
                      unit: 'c',
                      variables: [
                        {
                          each: 'ThermalInterface',
                          keys: ['coldMargin'],
                          legend: ['{name}'],
                          ops: ['min'],
                        },
                      ],
                    },
                  ],
                  title: 'Thermal Interface Minimum Cold Margin',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$WSTCT-${Math.random()}`,
              menuItems: [],
              title: 'Component Temperatures',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$WSTCAT-${Math.random()}`,
                  name: 'Component Average Temperature',
                  plots: [
                    {
                      type: 'horizontal-bar',
                      unit: 'c',
                      variables: [
                        {
                          each: 'Subsystem',
                          keys: ['components.$each.temperature.degC'],
                          legend: ['{name}'],
                          ops: ['avg'],
                          variables: [{ keys: [], legend: [], ops: [], variables: [] }],
                        },
                      ],
                    },
                  ],
                  title: 'Component Average Temperature',
                  type: 'WidgetSpec',
                },
                {
                  id: `$TCMHM-${Math.random()}`,
                  name: 'Component Minimum Hot Margin',
                  plots: [
                    {
                      type: 'margin',
                      unit: 'c',
                      variables: [
                        {
                          each: 'Subsystem',
                          keys: ['components.$each.hotMargin'],
                          legend: ['{name}'],
                          ops: ['min'],
                        },
                      ],
                    },
                  ],
                  title: 'Component Minimum Hot Margin',
                  type: 'WidgetSpec',
                },
                {
                  id: `$TCMCM-${Math.random()}`,
                  name: 'Component Minimum Cold Margin',
                  plots: [
                    {
                      type: 'margin',
                      unit: 'c',
                      variables: [
                        {
                          each: 'Subsystem',
                          keys: ['components.$each.coldMargin'],
                          legend: ['{name}'],
                          ops: ['min'],
                        },
                      ],
                    },
                  ],
                  title: 'Component Minimum Cold Margin',
                  type: 'WidgetSpec',
                },
              ],
            },
            {
              id: `$WSTST-${Math.random()}`,
              menuItems: [],
              title: 'Surface Temperatures',
              type: 'MenuItem',
              widgetSpecs: [
                {
                  id: `$WSTSAT-${Math.random()}`,
                  name: 'Surface Average Temperature',
                  plots: [
                    {
                      type: 'horizontal-bar',
                      unit: 'c',
                      variables: [
                        {
                          each: 'Surface',
                          keys: ['temperature.degC'],
                          legend: ['{name}'],
                          ops: ['avg'],
                          variables: [{ keys: [], legend: [], ops: [], variables: [] }],
                        },
                      ],
                    },
                  ],
                  title: 'Surface Average Temperature',
                  type: 'WidgetSpec',
                },
                {
                  id: `$WSTSMHM-${Math.random()}`,
                  name: 'Surface Minimum Hot Margin',
                  plots: [
                    {
                      type: 'margin',
                      unit: 'c',
                      variables: [
                        { each: 'Surface', keys: ['hotMargin'], legend: ['{name}'], ops: ['min'] },
                      ],
                    },
                  ],
                  title: 'Surface Minimum Hot Margin',
                  type: 'WidgetSpec',
                },
                {
                  id: `$WSTSMCM-${Math.random()}`,
                  name: 'Surface Minimum Cold Margin',
                  plots: [
                    {
                      type: 'margin',
                      unit: 'c',
                      variables: [
                        { each: 'Surface', keys: ['coldMargin'], legend: ['{name}'], ops: ['min'] },
                      ],
                    },
                  ],
                  title: 'Surface Minimum Cold Margin',
                  type: 'WidgetSpec',
                },
              ],
            },
          ],
          title: 'Window Statistics',
          type: 'MenuItem',
          widgetSpecs: [],
        },
      ],
      title: 'Thermal',
      type: 'MenuItem',
      widgetSpecs: [],
    }),
  },
  {
    label: 'Simulation',
    value: () => ({
      id: `$Simulation-${Math.random()}`,
      title: 'Simulation',
      type: 'MenuItem',
      playback: null,
      widgetSpecs: [],
      menuItems: [
        {
          id: `$SimulationTS-${Math.random()}`,
          title: 'Time Series',
          type: 'MenuItem',
          playback: null,
          widgetSpecs: [],
          menuItems: [
            {
              id: `$SimulationSteps-${Math.random()}`,
              title: 'Time Steps',
              type: 'MenuItem',
              playback: null,
              widgetSpecs: [
                {
                  id: `$GNCSteps-${Math.random()}`,
                  name: 'GNC Time Steps',
                  title: 'GNC',
                  type: 'WidgetSpec',
                  subtitle: 'Step taken at each iteration of the GNC engine in seconds',
                  plots: [
                    {
                      label: 'Time Step',
                      type: 'line',
                      step: 'end',
                      unit: 's',
                      variables: [{ keys: ['{selfId}/0.timeStep.s'], legend: ['Time Step'] }],
                    },
                  ],
                },
                {
                  id: `$CC&DHSteps-${Math.random()}`,
                  name: 'CC&DH Time Steps',
                  title: 'CC&DH',
                  type: 'WidgetSpec',
                  subtitle: 'Step taken at each iteration of the CC&DH engine in seconds',
                  plots: [
                    {
                      label: 'Time Step',
                      type: 'line',
                      step: 'end',
                      unit: 's',
                      variables: [{ keys: ['{selfId}/1.timeStep.s'], legend: ['Time Step'] }],
                    },
                  ],
                },
                {
                  id: `$PowerSteps-${Math.random()}`,
                  name: 'Power Time Steps',
                  title: 'Power',
                  type: 'WidgetSpec',
                  subtitle: 'Step taken at each iteration of the Power engine in seconds',
                  plots: [
                    {
                      label: 'Time Step',
                      type: 'line',
                      step: 'end',
                      unit: 's',
                      variables: [{ keys: ['{selfId}/2.timeStep.s'], legend: ['Time Step'] }],
                    },
                  ],
                },
                {
                  id: `$ThermalSteps-${Math.random()}`,
                  name: 'Thermal Time Steps',
                  title: 'Thermal',
                  type: 'WidgetSpec',
                  subtitle: 'Step taken at each iteration of the Thermal engine in seconds',
                  plots: [
                    {
                      label: 'Time Step',
                      type: 'line',
                      step: 'end',
                      unit: 's',
                      variables: [{ keys: ['{selfId}/3.timeStep.s'], legend: ['Time Step'] }],
                    },
                  ],
                },
              ],
              menuItems: [],
            },
          ],
        },
      ],
    }),
  },
];
