import * as Cesium from 'cesium';
import { useSelectById } from 'hooks';
import { useMemo, useRef } from 'react';
import {
  Camera,
  CameraFlyTo,
  Entity,
  Globe,
  LabelGraphics,
  PolylineGraphics,
  Scene,
  ScreenSpaceCameraController,
  ShadowMap,
  SkyAtmosphere,
  SkyBox,
  Sun,
  Viewer,
} from 'resium';
import {
  cesiumModelScaleDown,
  cesiumScaleLinearIntercept,
  cesiumScaleLinearSlope,
} from '../PlaybackWGroups/general/constants';

const ScenarioThumbnail = ({ branch }) => {
  const blocks = branch.model._metamodel.blocks;
  const agent =
    Object.values(blocks).find((block) => block.templateRef) ||
    Object.values(blocks).find((block) => block.type === 'Agent');
  const agentTemplate = useSelectById('MissionVersion', agent?.templateRef);
  const viewerRef = useRef(null);
  const [lat, long, pos, orbitPos] = useMemo(() => {
    const lat = Math.random() * 120 - 60;
    const long = Math.random() * 360 - 180;
    const alt = Math.random() * 2000000 + 1000000;
    const latOffset = Math.random() * 4 + 1;
    const longOffset = Math.random() * 4 + 1;
    const pos = new Cesium.Cartesian3.fromDegrees(long, lat, alt);
    const orbitPos = [
      pos,
      Cesium.Cartesian3.fromDegrees(
        (180 - Math.abs(long)) * (long < 0 ? 1 : -1) + latOffset,
        -lat + longOffset,
        alt
      ),
      new Cesium.Cartesian3.fromDegrees(
        (180 - Math.abs(long)) * (long < 0 ? 1 : -1) - latOffset,
        -lat - longOffset,
        alt
      ),
      pos,
    ];
    return [lat, long, pos, orbitPos];
  }, []);

  return (
    <Viewer
      ref={viewerRef}
      style={{
        height: '100%',
        width: '100%',
      }}
      timeline={false}
      animation={false}
      shouldAnimate={false}
      baseLayerPicker={false}
      sceneModePicker={false}
      scene3DOnly={true}
      infoBox={false}
      selectionIndicator={false}
      homeButton={false}
      geocoder={false}
      fullscreenButton={false}
      navigationHelpButton={false}
      msaaSamples={4}
    >
      <ScreenSpaceCameraController enableLook={false} enableZoom={false} />
      <SkyAtmosphere show={true} />
      <SkyBox show={true} />
      <ShadowMap darkness={0} />
      <Camera />
      <CameraFlyTo
        destination={Cesium.Cartesian3.fromDegrees(
          long + (Math.random() < 0.5 ? -1 : 1) * 16,
          lat + (Math.random() < 0.5 ? -1 : 1) * 8,
          15000000
        )}
        duration={0}
      />
      <Globe
        dynamicAtmosphereLighting={true}
        dynamicAtmosphereLightingFromSun={false}
        baseColor={Cesium.Color.SKYBLUE}
        enableLighting={true}
        depthTestAgainstTerrain={false}
      />
      <Sun />
      <Scene
        light={
          new Cesium.DirectionalLight({
            direction: new Cesium.Cartesian3.multiplyByScalar(pos, -1, new Cesium.Cartesian3()),
          })
        }
      />
      {agent && (
        <Entity
          name={agent.name}
          key={agent.name}
          position={pos}
          point={{
            pixelSize: 15,
            color: Cesium.Color.ORANGE,
            show: !agentTemplate,
          }}
          model={
            agentTemplate?.model && {
              uri: agentTemplate.model._root.cadSignedUrl,
              scale:
                agentTemplate.model._root.cadScaleFactor > 1
                  ? (cesiumScaleLinearSlope * 1000 + cesiumScaleLinearIntercept) *
                    agentTemplate.model._root.cadScaleFactor
                  : 1000 * cesiumModelScaleDown * agentTemplate.model._root.cadScaleFactor * 3000,
              show: true,
            }
          }
        >
          <PolylineGraphics
            positions={Math.random() > 0.5 ? orbitPos.reverse() : orbitPos}
            show={true}
            width={2}
            material={
              new Cesium.StripeMaterialProperty({
                evenColor: Cesium.Color.TOMATO,
                oddColor: Cesium.Color.TRANSPARENT,
                repeat: 2,
                offset: new Cesium.CallbackProperty(
                  (time) =>
                    viewerRef.current.cesiumElement._clockViewModel.systemTime.secondsOfDay % 2,
                  false
                ),
                orientation: Cesium.StripeOrientation.VERTICAL,
              })
            }
            arcType={Cesium.ArcType.GEODESIC}
          />
          <LabelGraphics
            text={agent.name}
            show={true}
            scale={0.5}
            fillColor={Cesium.Color.WHITE}
            pixelOffset={Cesium.Cartesian2.fromElements(25, 25, new Cesium.Cartesian2())}
          />
        </Entity>
      )}
    </Viewer>
  );
};

export default ScenarioThumbnail;
