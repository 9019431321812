import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragIndicator } from '@material-ui/icons';
import clsx from 'clsx';
import MetamodelTypeIcon from 'components/general/MetamodelTypeIcon';
import StyledButton from 'components/general/StyledButton';
import { IMission, IProject, TClickEvent } from 'components/general/types';
import { ItemTypes } from 'config';
import { usePermissionCheck, useSelectById } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import theme from 'theme';
import { toTitleCase } from 'utils/strings';
import { WorkspaceVables } from 'utils/vable';
import useStyles from './styles';

interface IProps {
  repo: IMission;
  repoDialogControl: TEntityDialogControl<IMission>;
  draggable?: boolean;
  tag?: ReactNode;
  setDragging?: (dragging: boolean) => void;
}

const RepoRow = (props: IProps) => {
  // Props
  const { repo, repoDialogControl, draggable, tag, setDragging } = props;
  const createdBy = useSelectById('User', repo.createdBy || '');
  const lastModifiedBy = useSelectById('User', repo.lastModifiedBy || '');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    Mission: {
      actions: { updateMission },
    },
    Project: {
      actions: { getProject },
    },
  } = SatelliteApi;

  // Utils
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [canEdit, canViewBranches] = usePermissionCheck([
    WorkspaceVables.Permission.EDIT_REPOSITORY,
    WorkspaceVables.Permission.VIEW_BRANCH,
  ]);
  const { openDialogForExisting: openRepoDialog } = repoDialogControl;
  const classes = useStyles({ enabledDelete: canEdit, noHover: !canViewBranches });

  // Project
  const project = useSelectById('Project', repo.project || '') as IProject;

  // Form hooks
  const tooltip = useCallback(
    (action) => {
      return !canEdit
        ? `Insufficient permissions to ${action} the repository`
        : toTitleCase(action);
    },
    [canEdit]
  );

  const openRepoDialogCB = (e: TClickEvent, type: 'edit' | 'delete') => {
    e.stopPropagation();
    openRepoDialog(repo, type);
  };

  const changeProject = useCallback(
    (item: IMission, dropResult: IProject) => {
      setLoading(true);
      dispatch(
        updateMission({
          id: item.id,
          project: dropResult.id === 'workspace' ? null : dropResult.id,
          successCallback: () => {
            if (project && dropResult.id === 'workspace') {
              dispatch(
                getProject({
                  id: project.id,
                  successCallback: () => {
                    enqueueSnackbar(`'${repo.name}' successfully moved to parent workspace.`, {
                      variant: 'success',
                    });
                    setLoading(false);
                  },
                  failureCallback: () => {
                    enqueueSnackbar(
                      `Error fetching changes. Please refresh the page and try again, if necessary.`
                    );
                    setLoading(false);
                  },
                })
              );
            } else {
              enqueueSnackbar(
                `'${repo.name}' successfully moved to project '${dropResult.name}.'`,
                {
                  variant: 'success',
                }
              );
              setLoading(false);
            }
          },
          failureCallback: () => {
            enqueueSnackbar(`Error moving '${repo.name}'. Please refresh the page and try again`);
            setLoading(false);
          },
        })
      );
    },
    [dispatch, enqueueSnackbar, updateMission, getProject, repo, project, setLoading]
  );

  // `monitor.canDrag` wasn't updating properly when `draggable` changed, so this is outside the `useDrag` hook
  const canDrag = useMemo(() => !!draggable && canEdit, [draggable, canEdit]);

  // Drag and drop
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.REPO,
      item: () => {
        if (setDragging) setDragging(true);
        return repo;
      },
      canDrag: () => canDrag,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<IProject>();
        if (setDragging) setDragging(false);
        if (item && dropResult) {
          changeProject(item, dropResult);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [canDrag, repo, changeProject]
  );

  const opacity = loading || isDragging ? 0.4 : 1;

  return (
    <div
      ref={drag}
      className={classes.row}
      style={{ opacity }}
      onClick={() => canViewBranches && history.push(Routes.BRANCH(repo.id))}
    >
      {canDrag && <DragIndicator htmlColor={theme.palette.text.secondary} />}
      <div className={classes.infoContainer}>
        <h3 style={{ height: '1rem' }}>
          {repo.name} <MetamodelTypeIcon metamodelType={repo.metamodelType} />
        </h3>
        <div className={classes.description}>
          {createdBy && `Created by ${createdBy.firstName} ${createdBy.lastName}`}
          {createdBy && lastModifiedBy && ' | '}
          {`Updated ${moment(repo.dateModified).fromNow()}${
            lastModifiedBy ? ` by ${lastModifiedBy.firstName} ${lastModifiedBy.lastName}` : ''
          }`}
          {tag && <> {tag}</>}
          <br />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <StyledButton
          framed
          disabled={!canEdit}
          tooltip={tooltip('update')}
          className={classes.iconButton}
          onClick={(e: TClickEvent) => openRepoDialogCB(e, 'edit')}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </StyledButton>
        <StyledButton
          disabled={!canEdit}
          tooltip={tooltip('delete')}
          className={clsx(classes.iconButton, classes.deleteButton)}
          onClick={(e: TClickEvent) => openRepoDialogCB(e, 'delete')}
        >
          <FontAwesomeIcon icon={faTrash} />
        </StyledButton>
      </div>
    </div>
  );
};

export default RepoRow;
