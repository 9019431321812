import { faImage } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowTrendUp,
  faArrowsAlt,
  faArrowsToCircle,
  faCameraRotate,
  faCircleCheck,
  faCircleNotch,
  faCircleXmark,
  faClock,
  faCube,
  faCubes,
  faDotCircle,
  faEyeLowVision,
  faLightbulb,
  faLocationDot,
  faSun,
  faTags,
  faTowerBroadcast,
  faVideoCamera,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Tooltip } from '@material-ui/core';
import StyledButton from 'components/general/StyledButton';
import StyledSlider from 'components/general/StyledSlider';
import LabeledSelect from 'components/general/inputs/LabeledSelect/index.jsx';
import { useMemo } from 'react';
import useStyles from './styles';

export const pathStepLimit = 3600; // maximum allowed path step [s]

const SettingsMenu = ({
  cesiumData,
  viewerRef,
  showFollowSc,
  satelliteEntityRef,
  trackedEntity,
  terrain,
  imagery,
  terrainOptions,
  imageryOptions,
  cesiumOptions,
  setCesiumOptions,
  entityFilter,
  setEntityFilter,
  scaleCesiumVectorData,
  updateTerrain,
  updateImagery,
  cadScale,
  cameraRef,
  isScenarioPlayback,
}) => {
  const classes = useStyles();

  function CubeThumbComponentIcon(props) {
    const { children, multi, ...other } = props;
    return (
      <span {...other}>
        {children}
        <FontAwesomeIcon icon={multi ? faCubes : faCube} />
      </span>
    );
  }

  function TagThumbComponentIcon(props) {
    const { children, ...other } = props;
    return (
      <span {...other}>
        {children}
        <FontAwesomeIcon icon={faTags} />
      </span>
    );
  }

  function ArrowsThumbComponentIcon(props) {
    const { children, ...other } = props;
    return (
      <span {...other}>
        {children}
        <FontAwesomeIcon icon={faArrowsAlt} />
      </span>
    );
  }

  function CircleNotchComponentIcon(props) {
    const { children, ...other } = props;
    return (
      <span {...other}>
        {children}
        <FontAwesomeIcon icon={faCircleNotch} />
      </span>
    );
  }

  const [bfVectorsOff, fovsOff] = useMemo(
    () => [
      cesiumData.targets.some((target) =>
        target.bfVectors?.some((v) => !entityFilter[target.name][v.name])
      ),
      cesiumData.targets.some((target) =>
        target.fovs?.some((v) => !entityFilter[target.name][v.name])
      ),
    ],
    [cesiumData.targets, entityFilter]
  );

  const hasTerrestrialAgent = useMemo(
    () => cesiumData.targets?.some((t) => t.type === 'TerrestrialTarget'),
    [cesiumData]
  );

  return (
    <div>
      {
        // Slider for agent cad models, if there are any
        cesiumData.targets.some((t) => Boolean(t.cadSignedUrl)) && (
          <Tooltip title="Scale Agents' CAD Models">
            <StyledSlider
              className={classes.cadScaleSlider}
              value={cesiumOptions.cadScaleMultiplier}
              ThumbComponent={CubeThumbComponentIcon}
              min={0.01}
              max={2}
              step={10 ** -10}
              onChange={(e, v) => {
                scaleCesiumVectorData(v, cesiumOptions.cadScaleMultiplier);
                setCesiumOptions((prev) => ({ ...prev, cadScaleMultiplier: v }));
              }}
              disabled={cesiumOptions.scaleToRealSize}
            />
          </Tooltip>
        )
      }
      {
        // Slider for FoVs, BFVs
        <Tooltip title="Scale Fields of View and Body Frame Vectors">
          <StyledSlider
            className={classes.cadScaleSlider}
            value={cesiumOptions.fovScaleMultiplier}
            ThumbComponent={ArrowsThumbComponentIcon}
            min={0.1}
            max={2}
            step={10 ** -2}
            onChange={(e, v) => {
              scaleCesiumVectorData(v, cesiumOptions.fovScaleMultiplier);
              setCesiumOptions((prev) => ({ ...prev, fovScaleMultiplier: v }));
            }}
          />
        </Tooltip>
      }
      <Tooltip title="Scale Labels">
        <StyledSlider
          className={classes.cadScaleSlider}
          value={cesiumOptions.labelScaleMultiplier}
          ThumbComponent={TagThumbComponentIcon}
          min={0.25}
          max={2.5}
          step={2 * 10 ** -5}
          onChange={(e, v) => {
            setCesiumOptions((prev) => ({ ...prev, labelScaleMultiplier: v }));
          }}
        />
      </Tooltip>
      <Tooltip title="Path Resolution">
        <StyledSlider
          className={classes.cadScaleSlider}
          value={cesiumOptions.pathStep}
          ThumbComponent={CircleNotchComponentIcon}
          min={-pathStepLimit}
          max={-10}
          step={-10}
          onChange={(e, v) => {
            setCesiumOptions((prev) => ({ ...prev, pathStep: v }));
          }}
        />
      </Tooltip>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() => {
            setCesiumOptions((prev) => ({
              ...prev,
              useFlashlight: !prev.useFlashlight,
            }));
          }}
          off={!cesiumOptions.useFlashlight}
          dontDisableInReadOnly
          fullWidth
          tooltip="Toggle Light Source"
        >
          <div>
            {cesiumOptions.useFlashlight ? (
              <FontAwesomeIcon icon={faLightbulb} />
            ) : (
              <FontAwesomeIcon icon={faSun} />
            )}
          </div>
        </StyledButton>
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() => {
            if (viewerRef.current?.cesiumElement.trackedEntity) {
              viewerRef.current.cesiumElement.trackedEntity = undefined;
            } else
              setCesiumOptions((prev) => ({
                ...prev,
                useEciCamera: !prev.useEciCamera,
              }));
          }}
          off={!cesiumOptions.useEciCamera}
          dontDisableInReadOnly
          fullWidth
          tooltip={!cesiumOptions.useEciCamera ? 'Use ECI Camera' : 'Use ECEF Camera'}
        >
          <div>
            <FontAwesomeIcon icon={faCameraRotate} />
          </div>
        </StyledButton>
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() => {
            setCesiumOptions((prev) => ({
              ...prev,
              scaleToRealSize: !prev.scaleToRealSize,
            }));
            cesiumOptions.scaleToRealSize
              ? scaleCesiumVectorData(1, 1 / cadScale)
              : scaleCesiumVectorData(1 / cadScale, 1);
          }}
          off={!cesiumOptions.scaleToRealSize}
          dontDisableInReadOnly
          fullWidth
          tooltip="Scale Models to Real Size"
        >
          <div>
            <FontAwesomeIcon icon={faArrowsToCircle} />
          </div>
        </StyledButton>
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() =>
            setEntityFilter((prev) => {
              const newEntityFilter = { ...prev };
              Object.keys(newEntityFilter).forEach((targetName) => {
                newEntityFilter[targetName] = {
                  ...newEntityFilter[targetName],
                  ...cesiumData.targets
                    .find((target) => target.name === targetName)
                    .bfVectors?.reduce((acc, { name }) => {
                      acc[name] = bfVectorsOff;
                      return acc;
                    }, {}),
                };
              });
              return newEntityFilter;
            })
          }
          off={bfVectorsOff}
          disabled={cesiumData.targets.every((t) => !t.bfVectors || t.bfVectors.length === 0)}
          dontDisableInReadOnly
          fullWidth
          tooltip="Show Body Frame Vectors"
        >
          <div>
            <FontAwesomeIcon icon={faArrowsAlt} />
          </div>
        </StyledButton>
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() => {
            setCesiumOptions((prev) => ({
              ...prev,
              showTargetNames: !prev.showTargetNames,
            }));
          }}
          off={!cesiumOptions.showTargetNames}
          dontDisableInReadOnly
          fullWidth
          tooltip="Show Agent Labels"
        >
          <div>
            <FontAwesomeIcon icon={faTags} />
          </div>
        </StyledButton>
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() => {
            setCesiumOptions((prev) => ({
              ...prev,
              showCommsLines: !prev.showCommsLines,
            }));
          }}
          off={!cesiumOptions.showCommsLines}
          dontDisableInReadOnly
          fullWidth
          tooltip="Show Communication Lines"
        >
          <div>
            <FontAwesomeIcon icon={faTowerBroadcast} />
          </div>
        </StyledButton>
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() =>
            setEntityFilter((prev) => {
              const newEntityFilter = { ...prev };
              Object.keys(newEntityFilter).forEach((targetName) => {
                newEntityFilter[targetName] = {
                  ...newEntityFilter[targetName],
                  ...cesiumData.targets
                    .find((target) => target.name === targetName)
                    .fovs?.reduce((acc, { name }) => {
                      acc[name] = fovsOff;
                      return acc;
                    }, {}),
                };
              });
              return newEntityFilter;
            })
          }
          off={fovsOff}
          disabled={cesiumData.targets.every((t) => !t.fovs || t.fovs.length === 0)}
          dontDisableInReadOnly
          fullWidth
          tooltip="Show Fields of View"
        >
          <div>
            <FontAwesomeIcon icon={faDotCircle} />
          </div>
        </StyledButton>
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() => {
            setCesiumOptions((prev) => ({
              ...prev,
              showOrbitTails: !prev.showOrbitTails,
            }));
          }}
          off={!cesiumOptions.showOrbitTails}
          dontDisableInReadOnly
          fullWidth
          tooltip="Show Orbit Tails"
        >
          <div>
            <FontAwesomeIcon icon={faCircleNotch} />
          </div>
        </StyledButton>
        {viewerRef.current && showFollowSc && (
          <>
            {!isScenarioPlayback && (
              <StyledButton
                className={classes.viewerButton}
                min
                type="button"
                onClick={() => {
                  // If the satellite is currently tracked, setting it back to undefined resets the view to not follow it. Otherwise track the satellite
                  if (
                    viewerRef.current?.cesiumElement.trackedEntity ===
                    satelliteEntityRef.current?.cesiumElement
                  ) {
                    viewerRef.current.cesiumElement.trackedEntity = undefined;
                  } else {
                    // cancelFlight will cancel the current flight if there is one, otherwise it does nothing. This protects against the user clicking the button before the flight finishes
                    cameraRef.current.cesiumElement.cancelFlight();
                    viewerRef.current.cesiumElement.trackedEntity =
                      satelliteEntityRef.current.cesiumElement;
                  }
                }}
                off={trackedEntity !== satelliteEntityRef.current?.cesiumElement}
                dontDisableInReadOnly
                fullWidth
                tooltip="Follow Satellite"
              >
                <div>
                  <FontAwesomeIcon icon={faVideoCamera} />
                </div>
              </StyledButton>
            )}
          </>
        )}
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() => {
            setCesiumOptions((prev) => ({
              ...prev,
              showAgentModels: !prev.showAgentModels,
            }));
          }}
          off={!cesiumOptions.showAgentModels}
          dontDisableInReadOnly
          fullWidth
          tooltip="Show Agent 3D Models"
        >
          <div>
            <FontAwesomeIcon icon={faEyeLowVision} />
          </div>
        </StyledButton>
        <StyledButton
          className={classes.viewerButton}
          min
          type="button"
          onClick={() => {
            setCesiumOptions((prev) => ({
              ...prev,
              showSkybox: !prev.showSkybox,
            }));
          }}
          off={!cesiumOptions.showSkybox}
          dontDisableInReadOnly
          fullWidth
          tooltip="Show Background"
        >
          <div>
            <FontAwesomeIcon icon={faImage} />
          </div>
        </StyledButton>
      </Grid>
      {/* Show/Hide terrestrial agent waypoints/paths */}
      {hasTerrestrialAgent && (
        <div>
          <Grid container direction="column" justifyContent="flex-start" alignItems="center">
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={4} md={5}>
                <StyledButton
                  className={classes.viewerButton}
                  min
                  type="button"
                  onClick={() => {
                    cesiumOptions.showWayPoints.reduce((sum, next) => sum && next, true)
                      ? setCesiumOptions((prev) => ({
                          ...prev,
                          showWayPoints: [false, false, false],
                        }))
                      : setCesiumOptions((prev) => ({
                          ...prev,
                          showWayPoints: [true, true, true],
                        }));
                  }}
                  off={!cesiumOptions.showWayPoints.reduce((sum, next) => sum && next, true)}
                  dontDisableInReadOnly
                  tooltip="Show All Waypoints"
                  fullWidth
                >
                  <div>
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                </StyledButton>
              </Grid>
              <StyledButton
                className={classes.viewerButton}
                min
                type="button"
                onClick={() => {
                  setCesiumOptions((prev) => {
                    const prevShowWayPoints = prev.showWayPoints;
                    return {
                      ...prev,
                      showWayPoints: [
                        !prevShowWayPoints[0],
                        prevShowWayPoints[1],
                        prevShowWayPoints[2],
                      ],
                    };
                  });
                }}
                off={!cesiumOptions.showWayPoints[0]}
                dontDisableInReadOnly
                tooltip="Show Passed Waypoints"
                fullWidth
              >
                <div>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
              </StyledButton>
              <StyledButton
                className={classes.viewerButton}
                min
                type="button"
                onClick={() => {
                  setCesiumOptions((prev) => {
                    const prevShowWayPoints = prev.showWayPoints;
                    return {
                      ...prev,
                      showWayPoints: [
                        prevShowWayPoints[0],
                        !prevShowWayPoints[1],
                        prevShowWayPoints[2],
                      ],
                    };
                  });
                }}
                off={!cesiumOptions.showWayPoints[1]}
                dontDisableInReadOnly
                tooltip="Show In Progress Waypoints"
                fullWidth
              >
                <div>
                  <FontAwesomeIcon icon={faClock} />
                </div>
              </StyledButton>
              <StyledButton
                className={classes.viewerButton}
                min
                type="button"
                onClick={() => {
                  setCesiumOptions((prev) => {
                    const prevShowWayPoints = prev.showWayPoints;
                    return {
                      ...prev,
                      showWayPoints: [
                        prevShowWayPoints[0],
                        prevShowWayPoints[1],
                        !prevShowWayPoints[2],
                      ],
                    };
                  });
                }}
                off={!cesiumOptions.showWayPoints[2]}
                dontDisableInReadOnly
                tooltip="Show Remaining Waypoints"
                fullWidth
              >
                <div>
                  <FontAwesomeIcon icon={faCircleXmark} />
                </div>
              </StyledButton>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={4} md={5}>
                <StyledButton
                  className={classes.viewerButton}
                  min
                  type="button"
                  onClick={() => {
                    cesiumOptions.showWayPaths.reduce((sum, next) => sum && next, true)
                      ? setCesiumOptions((prev) => ({
                          ...prev,
                          showWayPaths: [false, false, false],
                        }))
                      : setCesiumOptions((prev) => ({
                          ...prev,
                          showWayPaths: [true, true, true],
                        }));
                  }}
                  off={!cesiumOptions.showWayPaths.reduce((sum, next) => sum && next, true)}
                  dontDisableInReadOnly
                  tooltip="Show All Legs"
                  fullWidth
                >
                  <div>
                    <FontAwesomeIcon icon={faArrowTrendUp} />
                  </div>
                </StyledButton>
              </Grid>
              <StyledButton
                className={classes.viewerButton}
                min
                type="button"
                onClick={() => {
                  setCesiumOptions((prev) => {
                    const prevShowWayPaths = prev.showWayPaths;
                    return {
                      ...prev,
                      showWayPaths: [
                        !prevShowWayPaths[0],
                        prevShowWayPaths[1],
                        prevShowWayPaths[2],
                      ],
                    };
                  });
                }}
                off={!cesiumOptions.showWayPaths[0]}
                dontDisableInReadOnly
                tooltip="Show Completed Legs"
                fullWidth
              >
                <div>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
              </StyledButton>
              <StyledButton
                className={classes.viewerButton}
                min
                type="button"
                onClick={() => {
                  setCesiumOptions((prev) => {
                    const prevShowWayPaths = prev.showWayPaths;
                    return {
                      ...prev,
                      showWayPaths: [
                        prevShowWayPaths[0],
                        !prevShowWayPaths[1],
                        prevShowWayPaths[2],
                      ],
                    };
                  });
                }}
                off={!cesiumOptions.showWayPaths[1]}
                dontDisableInReadOnly
                tooltip="Show In Progress Legs"
                fullWidth
              >
                <div>
                  <FontAwesomeIcon icon={faClock} />
                </div>
              </StyledButton>
              <StyledButton
                className={classes.viewerButton}
                min
                type="button"
                onClick={() => {
                  setCesiumOptions((prev) => {
                    const prevShowWayPaths = prev.showWayPaths;
                    return {
                      ...prev,
                      showWayPaths: [
                        prevShowWayPaths[0],
                        !prevShowWayPaths[1],
                        !prevShowWayPaths[2],
                      ],
                    };
                  });
                }}
                off={!cesiumOptions.showWayPaths[2]}
                dontDisableInReadOnly
                tooltip="Show Remaining Legs "
                fullWidth
              >
                <div>
                  <FontAwesomeIcon icon={faCircleXmark} />
                </div>
              </StyledButton>
            </Grid>
          </Grid>
        </div>
      )}
      <div className={classes.selectWrapper}>
        <LabeledSelect
          label="Terrain"
          name="terrain-dd"
          options={terrainOptions}
          value={terrain.selectedValue}
          onChange={(option) => updateTerrain(option)}
          dontDisableInReadOnly
        />
        <LabeledSelect
          label="Imagery"
          name="imagery-dd"
          options={imageryOptions}
          value={imagery.selectedValue}
          onChange={(option) => updateImagery(option)}
          dontDisableInReadOnly
          disabled={terrain.selectedValue.disabledImagerySelect}
        />
      </div>
    </div>
  );
};

export default SettingsMenu;
