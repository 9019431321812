import {
  setActiveMissionVersionId,
  setLatestJobId,
} from 'components/RootView/missionExplorerSlice';
import JobStatusBoard from 'components/general/JobStatusBoard';
import Nav from 'components/general/Nav';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import { hotkeys } from 'config';
import { useSelectById, useSnackbar } from 'hooks';
import useWorkspace from 'hooks/useWorkspace';
import { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Routes from 'routes';
import BranchExplorer from './BranchExplorer';

const BranchesView = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const repo = useSelectById('Mission', id);
  useEffect(() => {
    dispatch(setActiveMissionVersionId(undefined));
    dispatch(setLatestJobId(undefined));
  }, [dispatch]);

  const workspace = useWorkspace();
  const history = useHistory();
  const goToWorkspace = useCallback(
    () => history.push(Routes.WORKSPACE(workspace.id, 'repositories')),
    [history, workspace]
  );

  useHotkeys(hotkeys.WORKSPACE.keys, goToWorkspace, [goToWorkspace]);

  useEffect(() => {
    // If workspace has inactive, no branches will be visible
    if (workspace && !workspace.isActive) {
      enqueueSnackbar('The requested data requires an active license.');
      goToWorkspace();
    }
  }, [workspace, goToWorkspace, enqueueSnackbar]);

  return (
    <ViewContainer>
      <Nav>
        <p>Select a branch to open its modules or interact with it via the options on the right.</p>
      </Nav>
      <ViewPort>
        <BranchExplorer />
        {repo && repo.metamodelType === 'Scenario' && <JobStatusBoard repoId={id} />}
      </ViewPort>
    </ViewContainer>
  );
};

export default BranchesView;
