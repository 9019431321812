import { faGripVertical, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  IconButton,
  TextField,
  alpha,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { TreeItem } from '@material-ui/lab';
import { ItemTypes } from 'config';
import { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { animated, useSpring } from 'react-spring';
import theme from 'theme';
import useStyles from './styles';

// Collapse/expand animation
function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles((theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  })
)(
  ({
    nodeId,
    item,
    editLabel,
    selected,
    expanded,
    setExpanded,
    onAdd,
    onDelete,
    nestItem,
    moveBetween,
    ...props
  }) => {
    const classes = useStyles();

    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
      type: ItemTypes.MENU_ITEM,
      item: { id: nodeId, item: item, selected: selected },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));

    // Collapse node if it is being dragged to save space
    useEffect(() => {
      if (isDragging && expanded.indexOf(nodeId) >= 0) {
        setExpanded(expanded.filter((id) => id !== nodeId));
      }
    }, [isDragging, nodeId, expanded, setExpanded]);

    const [{ isOverBetween }, dropBetween] = useDrop({
      accept: ItemTypes.MENU_ITEM,
      drop: (item, monitor) => {
        if (!monitor.didDrop()) {
          if (item.id !== nodeId) moveBetween(item.item, nodeId);
          return { targetId: nodeId };
        }
      },
      collect(monitor) {
        return {
          isOverBetween: monitor.isOver({ shallow: true }),
        };
      },
    });

    const [{ isOverNest }, dropNest] = useDrop({
      accept: ItemTypes.MENU_ITEM,
      drop: (item, monitor) => {
        if (!monitor.didDrop()) {
          if (item.id !== nodeId) nestItem(item.item, nodeId);
          return { targetId: nodeId };
        }
      },
      collect(monitor) {
        return {
          isOverNest: monitor.isOver({ shallow: true }),
        };
      },
    });

    return (
      <>
        {!isDragging && (
          <div
            ref={dropBetween}
            style={
              isOverBetween
                ? { height: 50, backgroundColor: theme.palette.primary.dark }
                : { height: 5, backgroundColor: theme.palette.background.main }
            }
          />
        )}
        <TreeItem
          {...props}
          ref={dropNest}
          nodeId={nodeId}
          style={{
            opacity: isDragging ? 0 : 1,
          }}
          TransitionComponent={TransitionComponent}
          onLabelClick={(event) => {
            event.preventDefault();
          }}
          label={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: isOverNest ? theme.palette.primary.dark : 'transparent',
                justifyContent: 'center',
                height: isOverNest ? 50 : 'auto',
              }}
            >
              <div ref={dragPreview} className={classes.treeItem}>
                {selected ? (
                  <>
                    <TextField
                      size="small"
                      defaultValue={item?.title}
                      variant="outlined"
                      onChange={(e) => editLabel(nodeId, e.target.value)}
                      style={{ padding: 4, maxWidth: '60%' }}
                    />
                    <IconButton
                      onClick={() => {
                        onAdd(nodeId);
                      }}
                      style={{
                        width: 24,
                        height: 24,
                        fontSize: 16,
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                    <IconButton
                      onClick={() => onDelete(nodeId)}
                      style={{
                        width: 24,
                        height: 24,
                        fontSize: 16,
                        color: theme.palette.primary.contrastText,
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  </>
                ) : (
                  item?.title
                )}
                <div style={{ marginLeft: 'auto', paddingRight: 8, cursor: 'move' }} ref={drag}>
                  <FontAwesomeIcon icon={faGripVertical} />
                </div>
              </div>
            </div>
          }
        />
      </>
    );
  }
);

export default StyledTreeItem;
