import clsx from 'clsx';
import { gaEvents } from 'config';
import { useEffect } from 'react';
import ReactGa from 'react-ga4';
import useStyles from './styles';

export default function ViewPortInlay(props) {
  const { text, children, className } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(className, classes.inlayWrapper)}>
      <div className={classes.inlayText}>{text}</div>
      {children}
    </div>
  );
}

export const FallbackInlay = (props) => {
  // Pass in `text to override the header
  // Pass in `subText` to override the subheader

  useEffect(() => {
    ReactGa.event(gaEvents.EXCEPTION, {
      category: 'UI Feature',
      action: 'Throw Form Exception',
      label: 'Thrown Exception',
    });
  }, []);
  const classes = useStyles({ ...props });

  return (
    <ViewPortInlay text={props.text || 'An error occurred while rendering.'} {...props}>
      <div className={classes.inlaySubtext}>
        {props.subText ||
          'Please refresh the page and try again. If the problem persists, please contact support.'}
      </div>
    </ViewPortInlay>
  );
};
