import * as Cesium from 'cesium';
import { IGenericObject } from 'components/general/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import {
  Billboard,
  BillboardCollection,
  Camera,
  CesiumComponentRef,
  Entity,
  Globe,
  LabelGraphics,
  PolylineGraphics,
  ScreenSpaceCameraController,
  ScreenSpaceEvent,
  ScreenSpaceEventHandler,
  Sun,
  Viewer,
} from 'resium';
import useStyles from './styles';

const placeholderString = '...';

interface IProps {
  waypoints?: { llaDeg: [number | '', number | '', number | '']; formKey?: number | string }[];
  agent?: { name: string; lat: number | ''; lon: number | ''; alt: number | '' };
  onClick: (pos: number[]) => void;
  waypointListRef?: React.MutableRefObject<FixedSizeList<IGenericObject> | null>;
}

const waypointPos = (
  waypoint: [number | '', number | '', number | '']
): [number, number, number] => [
  typeof waypoint[1] === 'string' ? 0 : waypoint[1],
  typeof waypoint[0] === 'string' ? 0 : waypoint[0],
  typeof waypoint[2] === 'string' ? 0 : waypoint[2],
];

const AgentPreview = ({ waypoints, onClick, agent, waypointListRef }: IProps) => {
  const classes = useStyles();
  const viewerRef = useRef<CesiumComponentRef<Cesium.Viewer>>(null);
  const [mousePos, setMousePos] = useState<number[] | string[]>([0, 0, 0]);

  const onLeftClick = useCallback(
    ({ position }) => {
      if (viewerRef.current?.cesiumElement) {
        const viewer = viewerRef.current.cesiumElement;
        const pick = viewer.scene.pick(position);
        if (pick && pick.id && pick.id.index) {
          waypointListRef?.current?.scrollToItem(pick.id.index);
        } else {
          const cartesian = viewer.camera.pickEllipsoid(position, viewer.scene.globe.ellipsoid);
          if (cartesian) {
            const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
            const longitude = Cesium.Math.toDegrees(cartographic.longitude);
            const latitude = Cesium.Math.toDegrees(cartographic.latitude);
            onClick([latitude, longitude, 0]);
          }
        }
      }
    },
    [viewerRef, onClick, waypointListRef]
  );

  const onMove = useCallback(
    ({ endPosition }) => {
      if (viewerRef.current?.cesiumElement) {
        const viewer = viewerRef.current.cesiumElement;
        const cartesian = viewer.camera.pickEllipsoid(endPosition, viewer.scene.globe.ellipsoid);
        if (cartesian) {
          const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          const longitudeString = Cesium.Math.toDegrees(cartographic.longitude);
          const latitudeString = Cesium.Math.toDegrees(cartographic.latitude);
          const heightString = cartographic.height;
          setMousePos([latitudeString, longitudeString, heightString]);
        } else {
          setMousePos([placeholderString, placeholderString, placeholderString]);
        }
      }
    },
    [viewerRef]
  );

  const [billboards, polylines] = useMemo(() => {
    if (!waypoints) return [null, null];

    const getLineColor = (index: number) => {
      // Use a Yellow -> Green gradient
      return (
        waypoints &&
        new Cesium.Color(1 - (index + 1 - waypoints.length) / waypoints.length, 1, 0, 1)
      );
      // Use a Red -> Green gradient if path is 10 waypoints or longer
      // if (!waypoints || waypoints.length < 10) return Cesium.Color.GOLD;
      // const halfLength = waypoints.length / 2;
      // if (index < halfLength) {
      //   return new Cesium.Color(1, index / halfLength, 0, 1);
      // }
      // return new Cesium.Color(1 - (index + 1 - halfLength) / halfLength, 1, 0, 1);
    };

    const pinBuilder = new Cesium.PinBuilder();
    const billboards = (
      <BillboardCollection>
        {waypoints.map((waypoint, index) => (
          <Billboard
            id={{ index }}
            key={index}
            position={Cesium.Cartesian3.fromDegrees(...waypointPos(waypoint.llaDeg))}
            image={pinBuilder.fromText(index.toString(), Cesium.Color.DEEPSKYBLUE, 40).toDataURL()}
            verticalOrigin={Cesium.VerticalOrigin.BOTTOM}
            scale={1}
          />
        ))}
      </BillboardCollection>
    );
    const polylines = waypoints.map(
      (waypoint, index) =>
        index < waypoints.length - 1 && (
          <Entity
            key={index}
            position={Cesium.Cartesian3.fromDegrees(...waypointPos(waypoint.llaDeg))}
            show={true}
            name={`Waypoint ${index}`}
          >
            <PolylineGraphics
              positions={[
                Cesium.Cartesian3.fromDegrees(...waypointPos(waypoint.llaDeg)),
                Cesium.Cartesian3.fromDegrees(...waypointPos(waypoints[index + 1].llaDeg)),
              ]}
              material={getLineColor(index)}
              arcType={Cesium.ArcType.GEODESIC}
              width={3}
            />
          </Entity>
        )
    );
    return [billboards, polylines];
  }, [waypoints]);

  useEffect(() => {
    if (agent?.lat && agent?.lon && viewerRef.current?.cesiumElement) {
      viewerRef.current?.cesiumElement?.camera.flyTo({
        duration: 1,
        destination: Cesium.Cartesian3.fromDegrees(
          agent.lat,
          agent.lon,
          Math.max((agent.alt || 1) * 5000, 100000)
        ),
      });
    }
  }, [agent?.lat, agent?.lon, agent?.alt, viewerRef]);

  useEffect(() => {
    if (waypoints) {
      const point = waypoints[waypoints.length - 1];
      if (point) {
        const position = waypointPos(point.llaDeg);
        viewerRef.current?.cesiumElement?.camera.flyTo({
          duration: 1,
          destination: Cesium.Cartesian3.fromDegrees(
            position[0],
            position[1],
            Math.max(position[2] * 10000, 100000)
          ),
        });
      }
    }
  }, [viewerRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <span>
        <h1>Preview:</h1>{' '}
        <h6>{waypoints ? 'Click to add waypoint' : 'Click to select target location'}</h6>
      </span>
      <Viewer
        className={classes.viewer}
        ref={viewerRef}
        timeline={false}
        animation={false}
        shouldAnimate={false}
        baseLayerPicker={false}
        sceneModePicker={false}
        scene3DOnly={true}
        infoBox={false}
        selectionIndicator={false}
        homeButton={false}
        geocoder={false}
        fullscreenButton={false}
        navigationHelpButton={false}
      >
        <div className={classes.mousePos}>
          <h6>{`Lat: ${
            typeof mousePos[0] === 'string' ? placeholderString : mousePos[0].toFixed(6)
          }`}</h6>
          <h6>{`Lon: ${
            typeof mousePos[1] === 'string' ? placeholderString : mousePos[1].toFixed(6)
          }`}</h6>
        </div>
        <ScreenSpaceCameraController enableLook={false} />
        <ScreenSpaceEventHandler>
          <ScreenSpaceEvent type={Cesium.ScreenSpaceEventType.MOUSE_MOVE} action={onMove} />
          <ScreenSpaceEvent type={Cesium.ScreenSpaceEventType.LEFT_CLICK} action={onLeftClick} />
        </ScreenSpaceEventHandler>
        <Camera />
        <Globe />
        <Sun />
        {agent && agent.lat && agent.lon && (
          <Entity
            name={agent.name}
            position={Cesium.Cartesian3.fromDegrees(
              agent.lat,
              agent.lon,
              agent.alt ? agent.alt * 1000 : undefined
            )}
            point={{
              pixelSize: 10,
              color: Cesium.Color.MAGENTA,
              show: true,
            }}
          >
            <LabelGraphics
              text={agent.name}
              scale={0.5}
              fillColor={Cesium.Color.MAGENTA}
              pixelOffset={Cesium.Cartesian2.fromElements(0, 20)}
            />
          </Entity>
        )}
        {billboards}
        {polylines}
      </Viewer>
    </>
  );
};

export default AgentPreview;
