import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, Dialog as MaterialDialog, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';
import StyledButton from 'components/general/StyledButton';
import Xray from 'components/general/Xray';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { useCallback, useState } from 'react';
import useStyles from './styles';

// Necessary to enable safe form nesting in children due to react-dom upgrade from 16->17
const FormContents = (props) => {
  const {
    onClose,
    onSubmit,
    onSecondaryAction,
    submitActionText,
    submitButtonTooltip,
    secondaryActionText,
    loading,
    children,
    noButtons,
    disableSubmit,
    large,
    small,
    xlarge,
    dontDisableInReadOnly,
  } = props;

  const classes = useStyles({ large, small, xlarge });

  return (
    <>
      {children}
      {!noButtons && (
        <div className={classes.footer}>
          {(onClose || onSecondaryAction) && (
            <StyledButton
              type="button"
              framed
              onClick={onSecondaryAction || onClose}
              dontDisableInReadOnly={dontDisableInReadOnly}
            >
              {secondaryActionText || 'Cancel'}
            </StyledButton>
          )}
          {onSubmit && (
            <StyledButton
              disabled={loading || disableSubmit}
              loading={loading}
              replaceSpinner
              type="submit"
              tooltip={submitButtonTooltip}
              dontDisableInReadOnly={dontDisableInReadOnly}
            >
              {submitActionText ? submitActionText : 'Confirm'}
            </StyledButton>
          )}
        </div>
      )}
    </>
  );
};

const Dialog = (props) => {
  const {
    onClose,
    onSubmit,
    open,
    title,
    prompt,
    large,
    small,
    xlarge,
    xxlarge,
    xray,
    fullscreen,
    downloadXray = false, // Enable downloading in the xray view contained in this dialog
    downloadData, // Data to download
  } = props;

  const classes = useStyles({ large, small, xlarge, xxlarge, fullscreen });
  const [openXray, setOpenXray] = useState(false);
  const [filename, setFilename] = useState('filename');
  const [showFilename, setShowFilename] = useState(false);

  const saveTemplateAsFile = useCallback((filename, dataObjToWrite) => {
    const blob = new Blob([JSON.stringify(dataObjToWrite)], { type: 'text/json' });
    const link = document.createElement('a');

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');
    link.click();
    link.remove();
  }, []);

  return (
    <>
      <MaterialDialog
        onClose={(e) => {
          setShowFilename(false);
          onClose(e);
        }}
        open={open}
        className={classes.root}
        scroll="body"
      >
        <div className={classes.content}>
          <div className={classes.header}>
            {title ? <h3>{title}</h3> : <h4>{prompt}</h4>}
            <div>
              {downloadXray && downloadData && (
                <Tooltip title="Download SedaroML">
                  <>
                    <div style={{ display: 'inline-flex' }}>
                      <div
                        style={{
                          opacity: showFilename ? '1' : '0',
                          transition: 'all .2s',
                          visibility: showFilename ? 'visible' : 'hidden',
                        }}
                      >
                        <LabeledInput
                          name={'Filename'}
                          variant="outlined"
                          defaultValue={filename}
                          onChange={(event) => {
                            setFilename(event.target.value);
                          }}
                          endAdornment={<InputAdornment position="end">.json</InputAdornment>}
                        />
                      </div>
                    </div>
                    <IconButton
                      onClick={() => {
                        showFilename
                          ? saveTemplateAsFile(filename + '.json', downloadData)
                          : setShowFilename((prev) => !prev);
                      }}
                      style={{ margin: 10 }}
                      className={classes.iconButton}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </IconButton>
                  </>
                </Tooltip>
              )}
              {xray && (
                <Tooltip title="View as SedaroML">
                  <IconButton
                    onClick={() => setOpenXray(true)}
                    style={{ marginRight: 10 }}
                    className={classes.iconButton}
                  >
                    <Visibility />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                onClick={(e) => {
                  setShowFilename(false);
                  onClose(e);
                }}
                className={classes.iconButton}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          {onSubmit ? (
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (onSubmit) {
                  onSubmit();
                }
              }}
            >
              <FormContents {...props} />
            </form>
          ) : (
            <div className={classes.form}>
              <FormContents {...props} />
            </div>
          )}
        </div>
      </MaterialDialog>
      {xray && (
        <Xray
          data={xray}
          open={openXray}
          download={downloadXray}
          onClose={() => setOpenXray(false)}
        />
      )}
    </>
  );
};

export default Dialog;
