import { wGroupIndicesScenario } from 'components/ScenarioView/ScenarioAnalyzeView/menu';
import PlaybackWGroups from 'components/general/PlaybackWGroups';
import { MomentProvider } from 'providers';

const Playback = () => {
  return (
    <MomentProvider>
      <PlaybackWGroups
        index={wGroupIndicesScenario.ANALYZE_PLAYBACK}
        masonryConfig={{ mdLg: [55, 45] }}
      ></PlaybackWGroups>
    </MomentProvider>
  );
};
export default Playback;
