import Box from '@material-ui/core/Box';
import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FallbackInlay } from '../ViewPortInlay';
import useStyles from './styles';

interface IProps {
  value: number;
  index: number;
  children: ReactNode;
}

const TabPanel = (props: IProps) => {
  const { children, value, index } = props;
  const classes = useStyles();
  return (
    <ErrorBoundary fallback={<>{value === index ? <FallbackInlay /> : <></>}</>}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        className={classes.panel}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    </ErrorBoundary>
  );
};

export default TabPanel;
