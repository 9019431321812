import { IAgentGroup } from 'components/general/types/agent';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import { TargetVables, TTargetTypes } from 'utils/vable';
import AgentGroupDialog from './AgentGroupDialog';
import guidance from './guidance';

interface IProps {
  index: string;
}

const agentGroupsColumns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (row: IAgentGroup) =>
      TargetVables.Type[row.agentType as TTargetTypes].label.split(' ')[0],
  },
  {
    title: 'Agents',
    field: 'agentAssociations.length',
  },
];

const AgentGroupsSegment = ({ index }: IProps) => {
  const { agentGroups } = useActiveEntities();

  return (
    <EntityTableSegment<IAgentGroup>
      className="joyride-scenario-agent-groups"
      title="Agent Groups"
      index={index}
      guidance={guidance}
      entityColumns={agentGroupsColumns}
      entityData={agentGroups}
      DialogComponent={AgentGroupDialog}
      modelName="agent group"
    ></EntityTableSegment>
  );
};

export default AgentGroupsSegment;
