import MaterialTable from '@material-table/core';
import { IconButton } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Visibility from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import EntityMenu from 'components/general/EntityMenu';
import TableIcons from 'components/general/TableIcons';
import Xray from 'components/general/Xray';
import { useInReadOnlyBranch } from 'hooks';
import _ from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import useStyles from './styles';

// Memoize to improve performance as MaterialTable can be slow to render
const WidgetTable = memo((props) => {
  let {
    className,
    columns,
    data,
    setData,
    modelName,
    emptyMessage,
    isLoading,
    onRowClick,
    onFabClick,
    onSelectionChange,
    actionTooltip,
    detailPanelContents,
    onActionClick,
    selection,
    title,
    search,
    paging,
    tableRef,
    fabTooltip,
    cloningAllowed,
    customColumns,
    disableActionMenuOptions,
    disableActionsMenu,
    actions,
    noXray,
    sort = (a, b) => Number(b?.id) - Number(a?.id), // note: does nothing if no `id` in object
  } = props;

  const [[anchorEl, selectedRow], setAnchorEl] = useState([null, undefined]);
  const inReadOnlyBranch = useInReadOnlyBranch();
  if (inReadOnlyBranch && !emptyMessage) emptyMessage = 'None added';

  const classes = useStyles({ detailPanelContents, onActionClick, selection });

  const [openXray, setOpenXray] = useState(false);

  useEffect(() => {
    columns[0] = {
      ...columns[0],
      headerStyle: {
        ...columns[0].headerStyle,
        paddingLeft: data?.length > 0 && detailPanelContents ? 0 : 16,
      },
    };
  }, [columns]); //eslint-disable-line

  const handleClick = (event, rowData) => {
    setAnchorEl([event.currentTarget, rowData]);
  };

  const handleClose = () => {
    setAnchorEl([null, undefined]);
  };

  const toolTipTextAction = inReadOnlyBranch
    ? 'View '
    : cloningAllowed
    ? 'Edit, clone, or delete '
    : 'Edit, or delete ';
  const tooltipText = actionTooltip || (modelName && toolTipTextAction + modelName);

  const sanitizedOrderedData = useMemo(() => {
    if (!data.length) return data;

    // remove any null items in array
    const _data = data?.filter((d) => d != null);

    // return early if items in data don't have 'id' property
    if (typeof _data[0] !== 'object') return _data;
    if (!Object.hasOwn(_data[0], 'id')) return _data;

    // order by id highest to lowest, so most recently created blocks are at the beginning.
    // except material-table reverses our list (confusingly), so actually sort lowest to highest
    _data.sort(sort);
    return _data;
  }, [data, sort]);

  const xraySupported = Boolean(!noXray && !search && sanitizedOrderedData.length);

  return (
    <>
      <div className={clsx(className, classes.root)}>
        <div className={classes.tableWrapper}>
          <MaterialTable
            tableRef={tableRef}
            title={title}
            boxShadow={3}
            icons={TableIcons}
            columns={columns}
            onSelectionChange={(checkedRows, newRow) => {
              // New in @material-table/core, not in previous versions of material table:
              // Internal table data won't automatically update our state,
              // so we have to update state ourselves
              // Data is only mutable if setData is passed in
              if (setData) {
                let newTableData;
                setData((prev) => {
                  // If single row is checked/unchecked
                  if (newRow) {
                    const indexSelected = prev.findIndex((row) => row.id === newRow.id);
                    prev[indexSelected].tableData.checked = !prev[indexSelected].tableData.checked;
                  }
                  // If "Select All" button is checked/unchecked
                  else {
                    for (let i = 0; i < prev.length; i++)
                      prev[i].tableData.checked = checkedRows.length > 0;
                  }

                  newTableData = prev;
                  return prev;
                });
                // custom onSelectionChange is run afterwards
                if (onSelectionChange) onSelectionChange(newTableData);
              }
            }}
            data={sanitizedOrderedData}
            isLoading={isLoading}
            detailPanel={
              detailPanelContents && [
                // Array of detail panel objects, we only have one
                {
                  disabled: true, // Makes arrow button unclickable, onRowClick is the only 'click' action now
                  render: ({ rowData }) => {
                    return (
                      <div className={'widget-table-detail-panel'}>
                        {detailPanelContents(rowData)}
                      </div>
                    );
                  },
                },
              ]
            }
            onRowClick={
              onRowClick &&
              ((event, rowData, togglePanel) => {
                togglePanel(); // Calls render() on first detail panel by default, takes detailPanel array index as arg
                if (onRowClick) {
                  onRowClick(rowData);
                }
              })
            }
            localization={{
              header: {
                actions: '',
              },
              body: {
                emptyDataSourceMessage: (
                  <span className={classes.emptyMessage}>
                    {emptyMessage ||
                      (modelName &&
                        `Click the + button to add a${
                          ('aeiouAEIOU'.includes(modelName[0]) ? 'n ' : ' ') + modelName
                        }`)}
                  </span>
                ),
              },
            }}
            options={{
              tableLayout: customColumns ? 'fixed' : 'auto',
              padding: 'dense',
              showTitle: !!title,
              draggable: false,
              showEmptyDataSourceMessage: true,
              search: !!search,
              toolbar: !!title || !!search,
              emptyRowsWhenPaging: data && data.length > 5 ? true : false,
              detailPanelType: 'single',
              actionsColumnIndex: -1,
              selection: !!selection,
              showSelectAllCheckbox: !inReadOnlyBranch,
              selectionProps: useCallback(
                (rowData) => ({
                  disabled: inReadOnlyBranch,
                }),
                [inReadOnlyBranch]
              ),
              paging: paging != null ? paging : true,
              pageSizeOptions: [],
              paginationAlignment: 'flex-start',
              searchFieldStyle: {
                maxWidth: 150,
                minWidth: 130,
              },
            }}
            actions={
              actions || [
                {
                  icon: TableIcons.More,
                  tooltip: tooltipText,
                  onClick: handleClick,
                  hidden: disableActionsMenu ? true : !onActionClick,
                },
              ]
            }
            cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) =>
                new Promise((resolve, reject) => {
                  if (rowData?.tableData?.checked) {
                    setData(
                      (prev) =>
                        prev &&
                        prev.map((row) => {
                          row = _.cloneDeep(row);
                          if (row.id === rowData.id) {
                            _.set(row, columnDef.field, newValue);
                          }
                          return row;
                        })
                    );
                  }
                  resolve();
                }),
              isCellEditable: (rowData, columnDef) => {
                return (
                  !inReadOnlyBranch &&
                  rowData?.tableData?.checked &&
                  columnDef.editable &&
                  columnDef.editable !== 'never'
                );
              },
            }}
          />
        </div>
        <EntityMenu
          anchorEl={anchorEl}
          selectedData={selectedRow}
          onActionClick={onActionClick}
          cloningAllowed={cloningAllowed != null ? cloningAllowed : true}
          handleClose={handleClose}
          disableActionMenuOptions={disableActionMenuOptions}
        />
        {onFabClick && !inReadOnlyBranch && (
          <Tooltip
            title={fabTooltip || (modelName && 'Create ' + modelName) || ''}
            aria-label="add"
          >
            <Fab
              color="primary"
              size="medium"
              className={classes.addEntityButton}
              onClick={onFabClick}
              aria-label="add"
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
        {xraySupported && (
          <Tooltip title="View as SedaroML" aria-label="add">
            <IconButton onClick={() => setOpenXray(true)} className={classes.xrayButton}>
              <Visibility />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {xraySupported && (
        <Xray
          title={_.startCase(modelName || '')}
          data={sanitizedOrderedData.map(({ tableData, ...rest }) => ({ ...rest }))}
          open={openXray}
          onClose={() => setOpenXray(false)}
        />
      )}
    </>
  );
});
WidgetTable.displayName = 'WidgetTable';

export default WidgetTable;
