import { makeStyles } from '@material-ui/core/styles';

export const padding = 8;
export const width = 240;
const avatarDims = 38;
const footerHeight = 22;
const hrHeight = 2;
const globalNavContainerHeight = avatarDims + footerHeight + padding + hrHeight;

const useStyles = makeStyles((theme) => ({
  root: {
    width,
    height: '100%',
    backgroundColor: theme.palette.background.main,
    boxShadow: theme.shadows[5],
    zIndex: 900,
    position: 'relative',
    float: 'left',
    display: 'flex',
    flexFlow: 'column',
  },

  header: {
    paddingTop: padding,
    height: 200,
    position: 'relative',
    color: theme.palette.primary.contrastText,

    // Temp styling
    background:
      'linear-gradient(20deg, ' +
      theme.palette.secondary.light +
      ' -10%, ' +
      theme.palette.primary.dark +
      ' 100%)',

    // Temp
    '& h1': {
      textAlign: 'center',
      margin: '8px 0',
      zIndex: 1000,
    },
    '& hr': {
      backgroundColor: theme.palette.primary.contrastText,
      height: 1,
      marginTop: 4,
    },
  },

  iconWrapper: {
    '& svg': {
      marginTop: 3,
      fontSize: 72,
      marginBottom: -10,
      textAlign: 'center',
      margin: 'auto',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
  },

  stackedLogoWrapper: {
    textAlign: 'center',
    margin: '0 auto',
    '& img': {
      marginTop: -20,
      width: 200,
      height: 'auto',
      marginLeft: 'auto',
    },
  },

  padder: {
    paddingLeft: padding,
    paddingRight: padding,

    '& hr': {
      marginLeft: padding / -2,
      marginRight: padding / -2,
    },
  },

  contextName: {
    ...theme.typography.h1,
  },

  contextNavMenu: {
    ...theme.mixins.noScroll,
    overflowY: 'scroll',
    flexGrow: 1,
    paddingBottom: globalNavContainerHeight + 10,
    height: 100,
    maskImage: `linear-gradient(to bottom, black calc(90%), transparent 108%)`,
  },

  contextImg: {
    height: 83,
    width: 'auto',
  },

  globalNavRoot: {
    display: 'flex',
    flexDirection: 'column',
    // Place on bottom of parent container
    marginTop: 'auto',
    backgroundColor: theme.palette.background.main,

    '& hr': {
      marginBottom: padding,
      marginTop: 0,
    },
  },

  footer: {
    backgroundColor: theme.palette.background.darkest,
    height: footerHeight,
    left: 0,
    right: 0,
    ...theme.typography.highlight2,
    fontSize: 11,
    letterSpacing: '0em',
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 2,
  },
  legal: {
    color: theme.palette.primary.main,
  },
  layoutWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
