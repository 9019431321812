// Data parsed from NASA's Station Locations (NDOSL)
//   https://naif.jpl.nasa.gov/pub/naif/generic_kernels/spk/stations/ndosl_190716_v02.cmt
//   Last updated: 2019/11/27
// Units:
//   lat: degrees
//   lon: degrees
//   alt: km

export type TStation = {
  stationCode: string;
  nasaNumber: string;
  location: string;
  equipment: string;
  lat: number;
  lon: number;
  alt: number;
};

export const stations: TStation[] = [
  {
    stationCode: 'AC2J',
    nasaNumber: '0208',
    location: 'Ascension Island',
    equipment: 'BRT 2-ft manual az-el',
    lat: -7.9179155833333335,
    lon: -14.390767916666618,
    alt: 0.07147,
  },
  {
    stationCode: 'ACNJ',
    nasaNumber: '0207',
    location: 'Ascension Island',
    equipment: 'BRT 2-ft manual az-el',
    lat: -7.9179155833333335,
    lon: -14.390767916666618,
    alt: 0.07147,
  },
  {
    stationCode: 'ADRQ',
    nasaNumber: '4284',
    location: 'Kourou, Fr.Guiana',
    equipment: 'C-bd',
    lat: 5.20912725,
    lon: -52.74840111111109,
    alt: -0.013807999999999999,
  },
  {
    stationCode: 'AG1S',
    nasaNumber: '1701',
    location: 'Poker Flat, AK',
    equipment: 'S-bd X-bd, 11 meter, az-el',
    lat: 65.11670027777777,
    lon: -147.46120416666668,
    alt: 0.4367,
  },
  {
    stationCode: 'AG23',
    nasaNumber: '1377',
    location: 'Santiago, Chile',
    equipment: 'S-bd 7-meter X-Y N-S',
    lat: -33.15179413888889,
    lon: -70.66731169444444,
    alt: 0.73,
  },
  {
    stationCode: 'AG33',
    nasaNumber: '1404',
    location: 'Santiago, Chile',
    equipment: 'USB S-bd 12-meter X-Y N-S',
    lat: -33.151478527777776,
    lon: -70.66830755555554,
    alt: 0.730853,
  },
  {
    stationCode: 'AGO3',
    nasaNumber: '1319',
    location: 'Santiago, Chile',
    equipment: 'USB S-band 9-meter X/Y N-S',
    lat: -33.15110747222222,
    lon: -70.666403,
    alt: 0.7333010000000001,
  },
  {
    stationCode: 'AGOS',
    nasaNumber: '1318',
    location: 'Santiago, Chile',
    equipment: 'Az-El, 13m, S-band',
    lat: -33.14832283333333,
    lon: -70.6676316666667,
    alt: 0.73024,
  },
  {
    stationCode: 'AGU3',
    nasaNumber: '1319',
    location: 'Santiago, Chile',
    equipment: 'USB S band, 9m',
    lat: -33.15110747222222,
    lon: -70.666403,
    alt: 0.7333010000000001,
  },
  {
    stationCode: 'AGUS',
    nasaNumber: '1321',
    location: 'Santiago, Chile',
    equipment: 'Az-El, 13m, S-band',
    lat: -33.14832283333333,
    lon: -70.6676316666667,
    alt: 0.73024,
  },
  {
    stationCode: 'AL2J',
    nasaNumber: '0209',
    location: 'Alice Springs, N.T., Australia',
    equipment: 'BRT 2-ft manual az-el',
    lat: -23.758810444444446,
    lon: 133.8825985,
    alt: 0.576542,
  },
  {
    stationCode: 'ALAY',
    nasaNumber: '1707',
    location: 'Alamo Peak, NM',
    equipment: 'Tlm S-bd 7.3m TAS az-el',
    lat: 32.87253311111111,
    lon: -105.8123794722222,
    alt: 2.7962399999999996,
  },
  {
    stationCode: 'ALSJ',
    nasaNumber: '0204',
    location: 'Alice Springs, N.T., Aust',
    equipment: 'BRT 2-ft manual az-el',
    lat: -23.758810444444446,
    lon: 133.8825985,
    alt: 0.576542,
  },
  {
    stationCode: 'AN3S',
    nasaNumber: '1704',
    location: 'Antigua',
    equipment: 'S-bd 10m az-el',
    lat: 17.136950833333334,
    lon: -61.77435000000003,
    alt: -0.01659,
  },
  {
    stationCode: 'AN8S',
    nasaNumber: '1705',
    location: 'Antigua',
    equipment: 'S-bd 24m az-el',
    lat: 17.13662488888889,
    lon: -61.77428983333334,
    alt: -0.00456,
  },
  {
    stationCode: 'ANRQ',
    nasaNumber: '4082',
    location: 'Antigua Island',
    equipment: 'C-band Az-El',
    lat: 17.137289944444444,
    lon: -61.77522336111116,
    alt: -0.017318,
  },
  {
    stationCode: 'ANTQ',
    nasaNumber: '4087',
    location: 'Antigua',
    equipment: 'C-bd FPQ-14 8.8m az-el on-axis',
    lat: 17.14365111111111,
    lon: -61.79251249999999,
    alt: 0.00363,
  },
  {
    stationCode: 'APLS',
    nasaNumber: '1725',
    location: 'APL, Clarksville, MD',
    equipment: 'S/X-band, X-Y, 18m',
    lat: 39.1674,
    lon: -76.10000000000002,
    alt: 0.14651,
  },
  {
    stationCode: 'AS2Q',
    nasaNumber: '4765',
    location: 'Ascension Island',
    equipment: 'C-bd TPQ-11 8.8m, az-el',
    lat: -7.9728062500000005,
    lon: -14.400950861111141,
    alt: 0.1435,
  },
  {
    stationCode: 'AS2S',
    nasaNumber: '1743',
    location: 'Alaska Satellite Facility Univ. of Alas Fairbank',
    equipment: 'USB S-bnd, X-bnd az-el 9.1m',
    lat: 64.85946080555556,
    lon: -147.84974566666665,
    alt: 0.23777,
  },
  {
    stationCode: 'AS3S',
    nasaNumber: '1744',
    location: 'Alaska Satellite Facility Univ. of Alas Fairbank',
    equipment: 'S-band az-el 11 meter',
    lat: 64.85887538888889,
    lon: -147.85411511111113,
    alt: 0.22021700000000002,
  },
  {
    stationCode: 'ASCQ',
    nasaNumber: '4045',
    location: 'Ascension Island',
    equipment: 'C-bd FPQ-15 8.5m az-el on-axis',
    lat: -7.906635194444445,
    lon: -14.402500000000032,
    alt: 0.056,
  },
  {
    stationCode: 'ASFS',
    nasaNumber: '1720',
    location: 'Alaska Satellite Facility Univ. of Alas Fairbank',
    equipment: 'S-band az-el 11 meter',
    lat: 64.85871213888889,
    lon: -147.85759516666667,
    alt: 0.21749700000000002,
  },
  {
    stationCode: 'ASNS',
    nasaNumber: '1726',
    location: 'AscensioN Island',
    equipment: 'S-bd 4m az-el',
    lat: -7.916667166666667,
    lon: -14.333333333333314,
    alt: 0.023004,
  },
  {
    stationCode: 'ATDQ',
    nasaNumber: '4862',
    location: 'Atlanta, Ga.',
    equipment: 'S-bd',
    lat: 33.930884166666665,
    lon: -84.10862994444443,
    alt: 0.282637,
  },
  {
    stationCode: 'ATDS',
    nasaNumber: '4862',
    location: 'Atlanta, Ga.',
    equipment: 'S-bd',
    lat: 33.930884166666665,
    lon: -84.10862994444443,
    alt: 0.282637,
  },
  {
    stationCode: 'ATFS',
    nasaNumber: '1972',
    location: 'Yatharagga, Western Australia.',
    equipment: '11-m az-el, S-Band TT&C w/ TDRS',
    lat: -29.04494002777778,
    lon: 115.35126588888889,
    alt: 0.24736000000000002,
  },
  {
    stationCode: 'ATLS',
    nasaNumber: '1736',
    location: 'Atlanta, Georgia',
    equipment: 'S-bd 11m az-el',
    lat: 33.93086666666667,
    lon: -84.10836666666665,
    alt: 0.26,
  },
  {
    stationCode: 'ATMY',
    nasaNumber: '1708',
    location: 'Atom Peak, NM',
    equipment: 'Tlm S-bd 7.3m TAS az-el',
    lat: 33.73962980555556,
    lon: -106.36455683333335,
    alt: 2.395244,
  },
  {
    stationCode: 'AUSS',
    nasaNumber: '4259',
    location: 'Aussaguel, France',
    equipment: 'S-bd az-el 11m',
    lat: 43.428695999999995,
    lon: 1.499412,
    alt: 0.26047899999999996,
  },
  {
    stationCode: 'AUWS',
    nasaNumber: '1902',
    location: 'Dongara,WA Australia',
    equipment: 'S-band, 13m az-el',
    lat: -29.045768083333336,
    lon: 115.34866805555555,
    alt: 0.2506,
  },
  {
    stationCode: 'BANF',
    nasaNumber: '4020',
    location: 'Bangalore',
    equipment: 'C-bd az-el',
    lat: 13.030000000000001,
    lon: 77.51,
    alt: 0.838,
  },
  {
    stationCode: 'BD1S',
    nasaNumber: '4056',
    location: 'NEN Bermuda',
    equipment: 'USB, S-band, Az-El, 7m',
    lat: 32.35104391666667,
    lon: -64.658841,
    alt: -0.01242,
  },
  {
    stationCode: 'BLKQ',
    nasaNumber: '4263',
    location: 'Black Is. Antarctica',
    equipment: 'K-band az-el',
    lat: -78.12957888888889,
    lon: 166.15043277777778,
    alt: 0.162512,
  },
  {
    stationCode: 'BLTA',
    nasaNumber: '1316',
    location: 'Greenbelt, MD',
    equipment: 'USB 9m X-Y e-w',
    lat: 38.9982475,
    lon: -76.84193894444445,
    alt: 0.024834,
  },
  {
    stationCode: 'BLTJ',
    nasaNumber: '0290',
    location: 'Greenbelt, MD',
    equipment: 'BRT 3-ft manual az-el',
    lat: 39.002703527777776,
    lon: -76.83792783333337,
    alt: 0.01366,
  },
  {
    stationCode: 'BP1K',
    nasaNumber: '4754',
    location: 'Blossom Point Ground Terminal North Antenna',
    equipment: 'TDRSS K-bd AZ-EL 20m',
    lat: 38.429165194444444,
    lon: -77.08394252777782,
    alt: -0.015734,
  },
  {
    stationCode: 'BP1S',
    nasaNumber: '1322',
    location: 'Blossom Point Ground Terminal North Antenna',
    equipment: 'TT&C S-bd AZ-EL 20m',
    lat: 38.429165194444444,
    lon: -77.08394252777782,
    alt: -0.015734,
  },
  {
    stationCode: 'BP2K',
    nasaNumber: '4755',
    location: 'Blossom Point Ground Terminal South Antenna',
    equipment: 'TDRSS K-bd AZ-EL 20m',
    lat: 38.428780833333334,
    lon: -77.08394213888891,
    alt: -0.015743,
  },
  {
    stationCode: 'BP2S',
    nasaNumber: '1323',
    location: 'Blossom Point Ground Terminal South Antenna',
    equipment: 'TT&C S-bd AZ-EL 20m',
    lat: 38.428780833333334,
    lon: -77.08394213888891,
    alt: -0.015743,
  },
  {
    stationCode: 'BREQ',
    nasaNumber: '4283',
    location: 'Kourou, Fr Guiana',
    equipment: 'C-bd',
    lat: 4.948878916666667,
    lon: -52.309544277777775,
    alt: 0.05196,
  },
  {
    stationCode: 'BRKS',
    nasaNumber: '1732',
    location: 'Berkeley, CA',
    equipment: 'S-bd 11m az-el',
    lat: 37.87937658333333,
    lon: -122.24278380555555,
    alt: 0.357034,
  },
  {
    stationCode: 'CA2F',
    nasaNumber: '4241',
    location: 'Tranquillon Peak, CA',
    equipment: 'C-bd FPS-16 3.7m az-el SN 18',
    lat: 34.58302833333334,
    lon: -120.56111488888888,
    alt: 0.6275499999999999,
  },
  {
    stationCode: 'CALF',
    nasaNumber: '4018',
    location: 'Vandenberg AFB, CA',
    equipment: 'C-bd FPS-16 3.7m az-el SN 21',
    lat: 34.58273855555556,
    lon: -120.56157233333332,
    alt: 0.62754,
  },
  {
    stationCode: 'CALT',
    nasaNumber: '4280',
    location: 'Vandenberg AFB, CA',
    equipment: 'C-bd TPQ-18 8.8m az-el',
    lat: 34.6658445,
    lon: -120.58144669444445,
    alt: 0.08852,
  },
  {
    stationCode: 'CALY',
    nasaNumber: '1835',
    location: 'S.Vandenberg AFB, CA',
    equipment: 'Tlm S-bd VTRS 10m az-el',
    lat: 34.56562375,
    lon: -120.50101308333333,
    alt: 0.62391,
  },
  {
    stationCode: 'CANS',
    nasaNumber: '4723',
    location: 'Canberra, Australia',
    equipment: 'S-bd 9m az-el YAGI VHF array',
    lat: -35.404666666666664,
    lon: 148.983058,
    alt: 0.68,
  },
  {
    stationCode: 'CB1D',
    nasaNumber: '1567',
    location: 'Cebreros, Spain',
    equipment: 'Az-El, S-bd',
    lat: 40.452690833333335,
    lon: -4.36754727777776,
    alt: 0.794073,
  },
  {
    stationCode: 'CN4F',
    nasaNumber: '4223',
    location: 'Cape Canaveral, FL',
    equipment: 'C-bd MCB-17 4.2m az-el',
    lat: 28.463167916666666,
    lon: -80.58311147222219,
    alt: -0.01481,
  },
  {
    stationCode: 'CN5F',
    nasaNumber: '4344',
    location: 'Cape Canaveral, Fl.',
    equipment: 'C-bd MPS-39 az-el',
    lat: 28.51702438888889,
    lon: -80.56341988888886,
    alt: 0.013689999999999999,
  },
  {
    stationCode: 'CNVF',
    nasaNumber: '4041',
    location: 'Cape Canaveral, FL',
    equipment: 'C-bd FPS-16 3.7m az-el',
    lat: 28.481605888888886,
    lon: -80.57650916666665,
    alt: -0.01422,
  },
  {
    stationCode: 'COCS',
    nasaNumber: '4085',
    location: 'Cocos Island, Australia',
    equipment: 'C-bd az-el',
    lat: -12.2,
    lon: 96.85,
    alt: 0.0,
  },
  {
    stationCode: 'CT2J',
    nasaNumber: '0294',
    location: 'White Sands, NM',
    equipment: 'BRT CTV2',
    lat: 32.500492111111114,
    lon: -106.60855719444444,
    alt: 1.450172,
  },
  {
    stationCode: 'CTSS',
    nasaNumber: '1756',
    location: 'Colorado Spgs. CO.',
    equipment: 'S-bd 10m az-el',
    lat: 38.805988416666665,
    lon: -104.52846913888888,
    alt: 1.907519,
  },
  {
    stationCode: 'CTVJ',
    nasaNumber: '0293',
    location: 'White Sands, NM',
    equipment: 'BRT CTV',
    lat: 32.500492111111114,
    lon: -106.60855719444444,
    alt: 1.450172,
  },
  {
    stationCode: 'D26D',
    nasaNumber: '1526',
    location: 'Goldstone, CA',
    equipment: 'DSN, 34m BWG',
    lat: 35.33568922222222,
    lon: -116.8730165,
    alt: 0.968686,
  },
  {
    stationCode: 'D27D',
    nasaNumber: '1516',
    location: 'Goldstone, CA',
    equipment: 'DSN, 34m HSB',
    lat: 35.238271777777776,
    lon: -116.77665044444444,
    alt: 1.0524680000000002,
  },
  {
    stationCode: 'D36D',
    nasaNumber: '1536',
    location: 'DSN DSS Canberra, Australia',
    equipment: 'DSN USB 34m BWG, S-bd, AZ-EL',
    lat: -35.39510175,
    lon: 148.97854419444445,
    alt: 0.6855030000000001,
  },
  {
    stationCode: 'DAKS',
    nasaNumber: '4072',
    location: 'Dakar, Senegal',
    equipment: 'Tlm S-bd 4.3m az-el',
    lat: 14.724762222222223,
    lon: -17.12876688888889,
    alt: 0.09127800000000001,
  },
  {
    stationCode: 'DFRS',
    nasaNumber: '4067',
    location: 'Dryden FRC, CA',
    equipment: 'S-bd and L-bd 4.3m az-el',
    lat: 34.94979075,
    lon: -117.88739405555557,
    alt: 0.679907,
  },
  {
    stationCode: 'DGIS',
    nasaNumber: '4073',
    location: 'Diego Garcia Island',
    equipment: 'S-bd 10m az-el',
    lat: -7.2700305555555556,
    lon: 72.3699986111111,
    alt: -0.068375,
  },
  {
    stationCode: 'DS12',
    nasaNumber: '1612',
    location: 'Goldstone, CA',
    equipment: 'USB-DSN 34m HA-DEC',
    lat: 35.29993941666667,
    lon: -116.80544338888888,
    alt: 0.969669,
  },
  {
    stationCode: 'DS14',
    nasaNumber: '1514',
    location: 'Goldstone, CA',
    equipment: 'DSN az-el, 70m',
    lat: 35.42590086111111,
    lon: -116.88953822222223,
    alt: 1.00139,
  },
  {
    stationCode: 'DS15',
    nasaNumber: '1515',
    location: 'Goldstone, CA',
    equipment: 'DSN 34m HEF',
    lat: 35.42185327777778,
    lon: -116.88719511111111,
    alt: 0.973211,
  },
  {
    stationCode: 'DS16',
    nasaNumber: '1312',
    location: 'Goldstone, CA',
    equipment: 'DSN 26m X-Y',
    lat: 35.34153938888889,
    lon: -116.87364975,
    alt: 0.943977,
  },
  {
    stationCode: 'DS17',
    nasaNumber: '1327',
    location: 'Goldstone, CA',
    equipment: 'USB-DSN 9m x-y n-s',
    lat: 35.34222994444445,
    lon: -116.87345527777777,
    alt: 0.942701,
  },
  {
    stationCode: 'DS24',
    nasaNumber: '4252',
    location: 'Goldstone, CA',
    equipment: 'DSN 34m BWG',
    lat: 35.33989283333334,
    lon: -116.87479441666665,
    alt: 0.951499,
  },
  {
    stationCode: 'DS25',
    nasaNumber: '1525',
    location: 'Goldstone, CA',
    equipment: 'DSN 34m BWG',
    lat: 35.33761197222223,
    lon: -116.87536319444445,
    alt: 0.959634,
  },
  {
    stationCode: 'DS34',
    nasaNumber: '1534',
    location: 'Canberra, Australia',
    equipment: 'DSN 34m BWG',
    lat: -35.398478833333336,
    lon: 148.98196441666667,
    alt: 0.69202,
  },
  {
    stationCode: 'DS35',
    nasaNumber: '1535',
    location: 'DSN Canberra, Australia',
    equipment: 'DSN 34m BWG, S-bd, AZ-EL',
    lat: -35.39579552777778,
    lon: 148.98145580555556,
    alt: 0.694889,
  },
  {
    stationCode: 'DS42',
    nasaNumber: '1547',
    location: 'Canberra, Aust',
    equipment: 'USB-DSN 34m HA-DEC',
    lat: -35.40068386111111,
    lon: 148.98124419444446,
    alt: 0.684755,
  },
  {
    stationCode: 'DS43',
    nasaNumber: '1548',
    location: 'Canberra, Australia',
    equipment: 'DSN 70m',
    lat: -35.40242422222222,
    lon: 148.98126730555555,
    alt: 0.688867,
  },
  {
    stationCode: 'DS45',
    nasaNumber: '1549',
    location: 'Canberra, Australia',
    equipment: 'DSN 34m HEF',
    lat: -35.398457694444446,
    lon: 148.9776856388889,
    alt: 0.674347,
  },
  {
    stationCode: 'DS46',
    nasaNumber: '1546',
    location: 'Canberra, Australia',
    equipment: 'DSN 26m X-Y',
    lat: -35.40501063888889,
    lon: 148.98308169444445,
    alt: 0.676812,
  },
  {
    stationCode: 'DS54',
    nasaNumber: '1554',
    location: 'Madrid Spain',
    equipment: 'DSN 34m BWG',
    lat: 40.425621666666665,
    lon: -4.254096833333335,
    alt: 0.837051,
  },
  {
    stationCode: 'DS55',
    nasaNumber: '1555',
    location: 'Madrid Spain',
    equipment: 'X/Ka band 34m BWG',
    lat: 40.424295916666665,
    lon: -4.252633305555548,
    alt: 0.819061,
  },
  {
    stationCode: 'DS61',
    nasaNumber: '1662',
    location: 'Madrid, SPAIN',
    equipment: 'USB-DSN 34m HA-DEC',
    lat: 40.42874444444444,
    lon: -4.248928027777765,
    alt: 0.848658,
  },
  {
    stationCode: 'DS63',
    nasaNumber: '1564',
    location: 'Madrid Spain',
    equipment: '70m',
    lat: 40.43120975,
    lon: -4.248008555555543,
    alt: 0.864816,
  },
  {
    stationCode: 'DS65',
    nasaNumber: '1565',
    location: 'Madrid Spain',
    equipment: 'USB-DSN 34m HEF',
    lat: 40.42720636111111,
    lon: -4.250698888888849,
    alt: 0.8338540000000001,
  },
  {
    stationCode: 'DS66',
    nasaNumber: '1566',
    location: 'Madrid Spain',
    equipment: 'USB-DSN 26m X-Y',
    lat: 40.429974861111106,
    lon: -4.251417638888881,
    alt: 0.849874,
  },
  {
    stationCode: 'DS87',
    nasaNumber: '1587',
    location: 'Wallops Island, VA',
    equipment: 'S-bd 18m az-el ADAS receive only',
    lat: 37.92658986111111,
    lon: -75.47630452777781,
    alt: -0.012762,
  },
  {
    stationCode: 'DXAS',
    nasaNumber: '1711',
    location: 'Poker Flat, Alaska',
    equipment: 'S-bd, 11 meter, az-el',
    lat: 65.11792972222221,
    lon: -147.43350388888888,
    alt: 0.5189,
  },
  {
    stationCode: 'EA2F',
    nasaNumber: '4065',
    location: 'Edwards AFB, CA',
    equipment: 'C-bd FPS-16 3.7m az-el R-41',
    lat: 34.97045388888889,
    lon: -117.93056166666668,
    alt: 0.799726,
  },
  {
    stationCode: 'EA3F',
    nasaNumber: '4221',
    location: 'Edwards AFB, CA',
    equipment: 'TACAN C-bd mobile',
    lat: 34.93811433333333,
    lon: -118.09132969444443,
    alt: 0.743537,
  },
  {
    stationCode: 'EAFF',
    nasaNumber: '4064',
    location: 'Edward AFB, CA',
    equipment: 'C-bd FPS-16, 3.7 meter, az-el',
    lat: 34.96065983333334,
    lon: -117.91155094444443,
    alt: 0.780567,
  },
  {
    stationCode: 'EG2F',
    nasaNumber: '4345',
    location: 'Eglin AFB, FL',
    equipment: 'C-bd FPS-85 az-el',
    lat: 30.572527944444445,
    lon: -86.21471741666664,
    alt: 0.037711,
  },
  {
    stationCode: 'ET1S',
    nasaNumber: '1973',
    location: 'White Sands, NM',
    equipment: 'Az-El, S-bd 6.1m',
    lat: 32.50457488888889,
    lon: -106.61133730555557,
    alt: 1.44319,
  },
  {
    stationCode: 'ET2S',
    nasaNumber: '1974',
    location: 'White Sands, NM',
    equipment: 'Az-El, S-bd, 9m',
    lat: 32.5043845,
    lon: -106.6111578888889,
    alt: 1.463649,
  },
  {
    stationCode: 'EULY',
    nasaNumber: '4205',
    location: 'Merritt Island, FL',
    equipment: 'Tlm S-bd 10.1m az-el',
    lat: 28.46356408333333,
    lon: -80.65301219444444,
    alt: -0.01051,
  },
  {
    stationCode: 'EVCS',
    nasaNumber: '1363',
    location: 'Cape Canaveral,Fl.',
    equipment: 'Tlm S-bd 9m, az-el',
    lat: 28.486,
    lon: -80.57599999999996,
    alt: 0.014996,
  },
  {
    stationCode: 'FR1X',
    nasaNumber: '1844',
    location: 'Armstrong Flight Research Center',
    equipment: 'VHF, Az-El',
    lat: 34.950694444444444,
    lon: -117.89544444444445,
    alt: 0.717,
  },
  {
    stationCode: 'FR2F',
    nasaNumber: '4249',
    location: 'Armstrong Flight Research Center',
    equipment: 'C-bd FPS-16 3.7m az-el',
    lat: 34.95044616666667,
    lon: -117.89524366666666,
    alt: 0.723967,
  },
  {
    stationCode: 'FR2X',
    nasaNumber: '1845',
    location: 'Armstrong Flight Research Center',
    equipment: 'VHF, Az-El',
    lat: 34.950722222222225,
    lon: -117.89527777777778,
    alt: 0.717,
  },
  {
    stationCode: 'FRCF',
    nasaNumber: '4069',
    location: 'Edwards AFB, CA',
    equipment: 'C-bd FPS-16V 4.9m az-el R-34',
    lat: 34.96080469444445,
    lon: -117.91149608333333,
    alt: 0.751842,
  },
  {
    stationCode: 'FT2F',
    nasaNumber: '4138',
    location: 'Fort.Huachuca, AZ',
    equipment: 'C-bd FPS-16 3.7m az-el',
    lat: 31.55676686111111,
    lon: -110.43817333333331,
    alt: 1.79496,
  },
  {
    stationCode: 'FTHF',
    nasaNumber: '4115',
    location: 'Fort.Huachuca, AZ',
    equipment: 'C-bd FPS-16 3.7m az-el',
    lat: 31.57102425,
    lon: -110.37079797222222,
    alt: 1.486316,
  },
  {
    stationCode: 'GB2Y',
    nasaNumber: '1814',
    location: 'Grand.Bahama Island',
    equipment: 'Tlm S-bd 10.1m az-el',
    lat: 26.625471277777777,
    lon: -78.29852533333337,
    alt: -0.010997,
  },
  {
    stationCode: 'GBIY',
    nasaNumber: '1813',
    location: 'Grand.Bahama Island',
    equipment: 'Tlm S-bd 26m az-el',
    lat: 26.625446555555556,
    lon: -78.29909119444443,
    alt: 0.000878,
  },
  {
    stationCode: 'GD28',
    nasaNumber: '1517',
    location: 'Goldstone, CA',
    equipment: 'USB-DSN 26m X-Y e-w',
    lat: 35.34154263888889,
    lon: -116.87360466666667,
    alt: 0.940941,
  },
  {
    stationCode: 'GILE',
    nasaNumber: '4047',
    location: 'Gilmore Creek, AK',
    equipment: 'Tlm 26m X-Y n-s',
    lat: 64.97850711111111,
    lon: -147.49800047222223,
    alt: 0.322434,
  },
  {
    stationCode: 'GLAS',
    nasaNumber: '1712',
    location: 'Gilmore Creek, AK',
    equipment: 's/x/l band, 13 meter',
    lat: 64.97367197222222,
    lon: -147.51283866666668,
    alt: 0.38748200000000005,
  },
  {
    stationCode: 'GLBS',
    nasaNumber: '1713',
    location: 'Gilmore Creek, AK',
    equipment: 's/x/l band, 13 meter',
    lat: 64.97348202777778,
    lon: -147.5086985277778,
    alt: 0.405478,
  },
  {
    stationCode: 'GLCS',
    nasaNumber: '1714',
    location: 'Gilmore Creek, AK',
    equipment: 's/x/l band, 13 meter',
    lat: 64.97314211111112,
    lon: -147.50477836111114,
    alt: 0.42447399999999996,
  },
  {
    stationCode: 'GT2S',
    nasaNumber: '1375',
    location: 'Guam',
    equipment: 'Tlm-SGLS S-bd 46-ft az-el',
    lat: 13.615880638888889,
    lon: 144.85543775,
    alt: 0.20915999999999998,
  },
  {
    stationCode: 'GTKQ',
    nasaNumber: '4086',
    location: 'Grand.Turk Island',
    equipment: 'C-bd FPQ-14 8.8m az-el on-axis',
    lat: 21.46263161111111,
    lon: -71.1320882222222,
    alt: -0.005582,
  },
  {
    stationCode: 'GTSS',
    nasaNumber: '1368',
    location: 'Guam',
    equipment: 'Tlm-SGLS S-bd 60-ft az-el',
    lat: 13.61518911111111,
    lon: 144.85605225,
    alt: 0.21906,
  },
  {
    stationCode: 'GW1J',
    nasaNumber: '1971',
    location: 'Guam',
    equipment: 'TDRS grnd xpnder',
    lat: 13.588622500000001,
    lon: 144.84098397222223,
    alt: 0.199022,
  },
  {
    stationCode: 'GW2J',
    nasaNumber: '0210',
    location: 'Guam',
    equipment: 'BRT 2-ft manual az-el',
    lat: 13.588622472222223,
    lon: 144.84098830555556,
    alt: 0.19906800000000002,
  },
  {
    stationCode: 'GW2K',
    nasaNumber: '1968',
    location: 'Guam',
    equipment: 'S-bd, 16 meters az-el',
    lat: 13.587588277777778,
    lon: 144.84087447222223,
    alt: 0.19766499999999998,
  },
  {
    stationCode: 'GW2S',
    nasaNumber: '1969',
    location: 'Guam',
    equipment: 'S-bd, 16 meters az-el',
    lat: 13.587588277777778,
    lon: 144.84087447222223,
    alt: 0.19766499999999998,
  },
  {
    stationCode: 'GW3S',
    nasaNumber: '1970',
    location: 'Guam',
    equipment: 'Ku-bd, S-bd, 16 meters az-el',
    lat: 13.587309611111111,
    lon: 144.84087494444447,
    alt: 0.197738,
  },
  {
    stationCode: 'GWE2',
    nasaNumber: '1936',
    location: 'GRGT, Guam',
    equipment: '5m Ku/S-bd,az-el,G-EET-2',
    lat: 13.58675638888889,
    lon: 144.84080419444444,
    alt: 0.19114799999999998,
  },
  {
    stationCode: 'GWM3',
    nasaNumber: '1309',
    location: 'Guam',
    equipment: 'USB 9m X-Y n-s',
    lat: 13.310689444444446,
    lon: 144.73681530555555,
    alt: 0.14864,
  },
  {
    stationCode: 'GWMK',
    nasaNumber: '1965',
    location: 'Guam',
    equipment: 'S-bd 11-meter K-bd az-el',
    lat: 13.588017861111112,
    lon: 144.84092950000002,
    alt: 0.19261,
  },
  {
    stationCode: 'GWMS',
    nasaNumber: '1966',
    location: 'Guam',
    equipment: 'S-bd 11-meter az-el TT&C',
    lat: 13.588017861111112,
    lon: 144.84092950000002,
    alt: 0.19261,
  },
  {
    stationCode: 'HAW3',
    nasaNumber: '1311',
    location: 'Kauai, HI',
    equipment: 'USB 9m X-Y n-s',
    lat: 22.126272555555555,
    lon: -159.66515502777776,
    alt: 1.1572,
  },
  {
    stationCode: 'HAWQ',
    nasaNumber: '4285',
    location: 'Hawaii, Univ.',
    equipment: 'C-bd az-el TLM',
    lat: 21.316100000000002,
    lon: -157.8864,
    alt: 0.0,
  },
  {
    stationCode: 'HAWS',
    nasaNumber: '1706',
    location: 'South Point, Hawaii',
    equipment: 'S-bd telemetry, command',
    lat: 19.013583722222222,
    lon: -155.66299999999998,
    alt: 0.274314,
  },
  {
    stationCode: 'HB33',
    nasaNumber: '1325',
    location: 'Hartebeesthoek, S. Africa',
    equipment: 'S-bd 12m x-y (n/s)',
    lat: -25.886427777777776,
    lon: 27.70744722222222,
    alt: 1.56372,
  },
  {
    stationCode: 'HB4S',
    nasaNumber: '1378',
    location: 'Hartebeesthoek, S. Africa',
    equipment: 'S-band 6 m',
    lat: -25.886725444444444,
    lon: 27.712603305555554,
    alt: 1.5500209999999999,
  },
  {
    stationCode: 'HB5S',
    nasaNumber: '1403',
    location: 'Hartebeesthoek, S. Africa',
    equipment: 'S-band 10 m',
    lat: -25.886933499999998,
    lon: 27.70667183333333,
    alt: 1.568221,
  },
  {
    stationCode: 'HBK3',
    nasaNumber: '1324',
    location: 'Hartebeesthoek, S. Africa',
    equipment: 'S-bd 9m x-y (n/s)',
    lat: -25.88672777777778,
    lon: 27.7126,
    alt: 1.54904,
  },
  {
    stationCode: 'HBKS',
    nasaNumber: '1402',
    location: 'Hartebeesthoek, S. Africa',
    equipment: 'S-bd x-y 12m',
    lat: -25.887,
    lon: 27.712,
    alt: 1.54483,
  },
  {
    stationCode: 'HOLF',
    nasaNumber: '4144',
    location: 'Holloman AFB, NM',
    equipment: 'C-bd FPS-16 3.7m az-el',
    lat: 32.90146386111111,
    lon: -106.09916955555556,
    alt: 1.2415,
  },
  {
    stationCode: 'HR1S',
    nasaNumber: '1718',
    location: 'Wallops, VA',
    equipment: 'S-bd, 16.4 meter, az-el',
    lat: 37.945499166666664,
    lon: -75.46113555555553,
    alt: -0.018510000000000002,
  },
  {
    stationCode: 'HR2S',
    nasaNumber: '1719',
    location: 'Wallops, VA',
    equipment: 'S-bd, 16.4 meter, az-el',
    lat: 37.94542583333333,
    lon: -75.46208999999999,
    alt: -0.01743,
  },
  {
    stationCode: 'HR3S',
    nasaNumber: '1749',
    location: 'Greenbelt, MD',
    equipment: 'USB S-bd, 16.4m, az-el',
    lat: 39.00060277777778,
    lon: -76.84143833333337,
    alt: -0.030420000000000003,
  },
  {
    stationCode: 'HT2S',
    nasaNumber: '1373',
    location: 'Kaena Point, HI',
    equipment: 'Tlm-SGLS S-bd 46-ft az-el',
    lat: 21.568971805555556,
    lon: -158.26227883333334,
    alt: 0.319658,
  },
  {
    stationCode: 'HTSS',
    nasaNumber: '1367',
    location: 'Kaena Point, HI',
    equipment: 'Tlm-SGLS S-bd 60-ft az-el',
    lat: 21.562272194444446,
    lon: -158.24208955555557,
    alt: 0.430423,
  },
  {
    stationCode: 'HWIS',
    nasaNumber: '1903',
    location: 'South Point, HI',
    equipment: 'S-band az-el, 13m',
    lat: 19.0139045,
    lon: -155.66330125,
    alt: 0.36719999999999997,
  },
  {
    stationCode: 'JD2Y',
    nasaNumber: '1818',
    location: 'Jonathan Dickenson, FL',
    equipment: 'Tlm S-Bd 24.4m az-el',
    lat: 26.982229861111108,
    lon: -80.10756080555558,
    alt: -0.00196,
  },
  {
    stationCode: 'JDIQ',
    nasaNumber: '4248',
    location: 'Jonathan Dickinson, Fla.',
    equipment: 'C-bd FPQ-14',
    lat: 26.98299969444444,
    lon: -80.10820405555557,
    alt: -0.006403,
  },
  {
    stationCode: 'JDIY',
    nasaNumber: '1817',
    location: 'Jonathan Dickinson, FL',
    equipment: 'Tlm S-Bd 15m az-el',
    lat: 26.983803944444446,
    lon: -80.10874725000002,
    alt: -0.01053,
  },
  {
    stationCode: 'JSCJ',
    nasaNumber: '0291',
    location: 'JSC, TX',
    equipment: 'BRT STS gnd xpnder',
    lat: 29.561689611111113,
    lon: -95.09000000000003,
    alt: 0.049531,
  },
  {
    stationCode: 'KA2S',
    nasaNumber: '1735',
    location: 'Tokyo, Japan',
    equipment: 'S-bd 11-meter Az-El',
    lat: 35.708761861111114,
    lon: 139.49177777777777,
    alt: -0.641245,
  },
  {
    stationCode: 'KENS',
    nasaNumber: '4722',
    location: 'Malindi, Kenya',
    equipment: 'S-bd 10m az-el',
    lat: -2.9955575,
    lon: 40.194505,
    alt: 0.012314,
  },
  {
    stationCode: 'KERS',
    nasaNumber: '4253',
    location: 'Kergueles Is.',
    equipment: 'S-bd az-el 10m',
    lat: -49.352906000000004,
    lon: 70.257282,
    alt: 0.082471,
  },
  {
    stationCode: 'KGLQ',
    nasaNumber: '4261',
    location: 'S.Pac.Fr.Kerguelen',
    equipment: 'C-bd az-el 9m',
    lat: -49.351915444444444,
    lon: 70.25598388888889,
    alt: 0.0060999999999999995,
  },
  {
    stationCode: 'KI2S',
    nasaNumber: '1727',
    location: 'Kiruna, Sweden',
    equipment: 'S-bd 15m az-el',
    lat: 67.85712516666666,
    lon: 20.964341694444443,
    alt: 0.402275,
  },
  {
    stationCode: 'KICS',
    nasaNumber: '4255',
    location: 'Kiruna, Sweden',
    equipment: 'S-bd az-el 6m',
    lat: 67.88423200000001,
    lon: 21.060769,
    alt: 0.440604,
  },
  {
    stationCode: 'KILS',
    nasaNumber: '4256',
    location: 'Kiruna, Sweden',
    equipment: 'S-bd az-el 9m',
    lat: 67.876532,
    lon: 21.062337,
    alt: 0.514892,
  },
  {
    stationCode: 'KIXS',
    nasaNumber: '4257',
    location: 'Kiruna, Sweden',
    equipment: 'S-bd az-el 9m',
    lat: 67.87815699999999,
    lon: 21.063398,
    alt: 0.5085729999999999,
  },
  {
    stationCode: 'KLMS',
    nasaNumber: '1710',
    location: 'Svalbard, Norway',
    equipment: 'S-bd X-bd, 13 meter, az-el',
    lat: 78.23022166666667,
    lon: 15.39813813888889,
    alt: 0.49851999999999996,
  },
  {
    stationCode: 'KM2F',
    nasaNumber: '4971',
    location: 'Kwajalein Atoll',
    equipment: 'C-bd ALTAIR 46m az-el',
    lat: 9.395428805555555,
    lon: 167.47928833333333,
    alt: 0.06286,
  },
  {
    stationCode: 'KMPF',
    nasaNumber: '4110',
    location: 'Kwajalein Atoll',
    equipment: 'C-bd Radar',
    lat: 8.721676916666667,
    lon: 167.72649005555556,
    alt: 0.039263,
  },
  {
    stationCode: 'KMQF',
    nasaNumber: '4111',
    location: 'Kwajalein Atoll',
    equipment: 'C-bd Radar',
    lat: 8.721635388888888,
    lon: 167.726622,
    alt: 0.039264,
  },
  {
    stationCode: 'KMRF',
    nasaNumber: '4968',
    location: 'Kwajalein Atoll',
    equipment: 'C-bd TRADEX 26m',
    lat: 9.39874711111111,
    lon: 167.48214819444445,
    alt: 0.05737,
  },
  {
    stationCode: 'KMRQ',
    nasaNumber: '4969',
    location: 'Kwajalein Atoll',
    equipment: 'C-bd ALCOR 12.2m az-el',
    lat: 9.398599583333333,
    lon: 167.4828493611111,
    alt: 0.04248,
  },
  {
    stationCode: 'KPTQ',
    nasaNumber: '4282',
    location: 'Oahu, HI',
    equipment: 'C-bd FPQ-14 8.8m az-el on-axis',
    lat: 21.572119722222222,
    lon: -158.26658533333335,
    alt: 0.30132,
  },
  {
    stationCode: 'KRCS',
    nasaNumber: '1797',
    location: 'Weipa, Australia',
    equipment: 'Tlm S-bd, az-el',
    lat: -12.694,
    lon: 141.93066666666667,
    alt: 0.021,
  },
  {
    stationCode: 'KRUF',
    nasaNumber: '8501',
    location: 'Kourou, Fr Guiana',
    equipment: 'C-bd Bretagne',
    lat: 5.114006416666666,
    lon: -52.644989916666646,
    alt: 0.148008,
  },
  {
    stationCode: 'KRUS',
    nasaNumber: '4258',
    location: 'Kourou, Fr. Guiana',
    equipment: 'S-bd az-el 11m',
    lat: 5.098847999999999,
    lon: -52.63987199999997,
    alt: 0.110039,
  },
  {
    stationCode: 'KSWC',
    nasaNumber: '1855',
    location: 'Jeju, South Korea',
    equipment: 'az-el',
    lat: 33.42805555555555,
    lon: 126.29563888888889,
    alt: 0.084,
  },
  {
    stationCode: 'KU1S',
    nasaNumber: '1905',
    location: 'Kiruna, Sweden',
    equipment: 'S & X band',
    lat: 67.88955833333334,
    lon: 21.065654722222224,
    alt: 0.4004,
  },
  {
    stationCode: 'KU2S',
    nasaNumber: '1906',
    location: 'Kiruna, Sweden',
    equipment: 'S & X band',
    lat: 67.88318249999999,
    lon: 21.060448333333333,
    alt: 0.42819999999999997,
  },
  {
    stationCode: 'KU3S',
    nasaNumber: '1909',
    location: 'Kiruna, Sweden',
    equipment: 'az-el, 13 meter, S-bd',
    lat: 67.87907083333333,
    lon: 21.038,
    alt: 0.527,
  },
  {
    stationCode: 'KUSS',
    nasaNumber: '4055',
    location: 'NEN Kennedy Uplink Station 6.1m',
    equipment: 'USB 6.1m, S-bd, Az-El',
    lat: 28.542064916666668,
    lon: -80.6429523611111,
    alt: 0.00983,
  },
  {
    stationCode: 'LANS',
    nasaNumber: '1728',
    location: 'Lannion, France',
    equipment: 'S-bd az-el',
    lat: 48.751415361111114,
    lon: -3.4700000000000273,
    alt: 0.110676,
  },
  {
    stationCode: 'LBVS',
    nasaNumber: '4250',
    location: 'Liberville, Africa',
    equipment: 'S-bd 10m az-el',
    lat: 0.35462977777777777,
    lon: 9.675300277777778,
    alt: 0.111269,
  },
  {
    stationCode: 'LE1S',
    nasaNumber: '1721',
    location: 'Poker Flat, AK',
    equipment: 'S-bd, 5 meter, az-el',
    lat: 65.11685166666666,
    lon: -147.462255,
    alt: 0.414,
  },
  {
    stationCode: 'LE2S',
    nasaNumber: '1722',
    location: 'Wallops Island. VA',
    equipment: 'S-bd, 5 meter, az-el',
    lat: 37.92352944444444,
    lon: -75.47613916666666,
    alt: -0.033500999999999996,
  },
  {
    stationCode: 'MC1S',
    nasaNumber: '4848',
    location: 'McMurdo,Antarctica',
    equipment: 'S-bd 10m az-el',
    lat: -77.8391295,
    lon: 166.66708233333333,
    alt: 0.153,
  },
  {
    stationCode: 'MCMS',
    nasaNumber: '4847',
    location: 'McMurdo,Antarctica',
    equipment: 'S-bd 6m az-el',
    lat: -77.8,
    lon: 166.4,
    alt: 0.02,
  },
  {
    stationCode: 'MDLS',
    nasaNumber: '1904',
    location: 'Laurel, Maryland',
    equipment: 'S-band 18m az-el',
    lat: 39.167361111111106,
    lon: -76.89877777777775,
    alt: 0.1464,
  },
  {
    stationCode: 'MG1D',
    nasaNumber: '1574',
    location: 'Malargue, Argentina',
    equipment: 'X-bd, Ka-band,az-el,35m',
    lat: -35.77597022222222,
    lon: -69.39818155555554,
    alt: 1.571768,
  },
  {
    stationCode: 'MIL3',
    nasaNumber: '1301',
    location: 'Merritt Island, FL',
    equipment: 'USB 9m X-Y n-s',
    lat: 28.50812388888889,
    lon: -80.69339994444442,
    alt: -0.02595,
  },
  {
    stationCode: 'MILJ',
    nasaNumber: '0292',
    location: 'Merritt Island, FL',
    equipment: 'BRT',
    lat: 28.505989472222222,
    lon: -80.69301288888886,
    alt: -0.02131,
  },
  {
    stationCode: 'MIMF',
    nasaNumber: '4220',
    location: 'Merritt Island, FL',
    equipment: 'C-bd MCB-17 4.2m az-el',
    lat: 28.625943194444446,
    lon: -80.68279380555555,
    alt: -0.01812,
  },
  {
    stationCode: 'MLAQ',
    nasaNumber: '4084',
    location: 'Merritt Island, FL',
    equipment: 'C-bd FPQ-14 8.8m az-el on-axis',
    lat: 28.424711194444445,
    lon: -80.6643876666667,
    alt: -0.01735,
  },
  {
    stationCode: 'MMTF',
    nasaNumber: '4347',
    location: 'Merritt Island, FL',
    equipment: 'C-bd, MPS-39, az-el',
    lat: 28.478576805555555,
    lon: -80.67478863888891,
    alt: -0.012298,
  },
  {
    stationCode: 'MPLS',
    nasaNumber: '1967',
    location: 'Maspalomas, Spain',
    equipment: 'S-bd, 11 meter, az-el',
    lat: 27.762892,
    lon: -15.633799999999951,
    alt: 0.2049,
  },
  {
    stationCode: 'MTLF',
    nasaNumber: '4155',
    location: 'Mt.Lemmon, AZ',
    equipment: 'C-bd Capri 4.9m az-el',
    lat: 32.44166322222222,
    lon: -110.78880305555558,
    alt: 2.7728200000000003,
  },
  {
    stationCode: 'MTLS',
    nasaNumber: '4156',
    location: 'Mt.Lemmon, AZ',
    equipment: 'Tlm S-bd 4.3m az-el',
    lat: 32.44213655555555,
    lon: -110.7894506388889,
    alt: 2.769192,
  },
  {
    stationCode: 'NH2S',
    nasaNumber: '1374',
    location: 'New.Boston, NH',
    equipment: 'Tlm-SGLS S-bd 46-ft az-el',
    lat: 42.944741666666665,
    lon: -71.63032172222222,
    alt: 0.193259,
  },
  {
    stationCode: 'NHSS',
    nasaNumber: '1366',
    location: 'New.Boston, NH',
    equipment: 'Tlm-SGLS S-bd 60-ft az-el',
    lat: 42.94782133333333,
    lon: -71.62656252777776,
    alt: 0.20328,
  },
  {
    stationCode: 'NN1D',
    nasaNumber: '1573',
    location: 'New Norcia, Australia',
    equipment: 'Az-El, S-bd',
    lat: -31.048218194444445,
    lon: 116.19150591666667,
    alt: 0.252257,
  },
  {
    stationCode: 'NSGS',
    nasaNumber: '1724',
    location: 'Neustrelitz, Germany',
    equipment: 'az-el, S-Band',
    lat: 53.32972222222222,
    lon: 13.07,
    alt: 0.115,
  },
  {
    stationCode: 'OTSS',
    nasaNumber: '1364',
    location: 'Oakhanger, UK',
    equipment: 'Tlm-SGLS S-bd 60-ft az-el',
    lat: 51.11411733333333,
    lon: -0.894897055555532,
    alt: -0.018852,
  },
  {
    stationCode: 'PA2Q',
    nasaNumber: '4089',
    location: 'Patrick AFB, FL',
    equipment: 'C-bd FPQ-13 6.1m az-el on-axis',
    lat: 28.227328166666666,
    lon: -80.60609819444443,
    alt: -0.01438,
  },
  {
    stationCode: 'PATQ',
    nasaNumber: '4060',
    location: 'Patrick AFB, FL',
    equipment: 'C-bd FPQ-14 8.8m az-el on-axis',
    lat: 28.226402416666666,
    lon: -80.59927636111115,
    alt: -0.01376,
  },
  {
    stationCode: 'PDLS',
    nasaNumber: '4054',
    location: 'NEN Ponce De Leon 6.1m',
    equipment: 'USB 6.1m, S-bd, Az-El',
    lat: 29.066647444444445,
    lon: -80.91302325000004,
    alt: 0.009845000000000001,
  },
  {
    stationCode: 'PFTQ',
    nasaNumber: '4864',
    location: 'Poker Flat, Ak.',
    equipment: 'S-bd',
    lat: 65.11679308333332,
    lon: -147.46329083333333,
    alt: 0.413284,
  },
  {
    stationCode: 'PFTS',
    nasaNumber: '4864',
    location: 'Poker Flat, Ak.',
    equipment: 'S-bd',
    lat: 65.11679308333332,
    lon: -147.46329083333333,
    alt: 0.413284,
  },
  {
    stationCode: 'PM2F',
    nasaNumber: '4445',
    location: 'Point.Mugu, CA',
    equipment: 'C-bd FPS-16 3.7m az-el SN 10',
    lat: 34.12250291666667,
    lon: -119.15379855555554,
    alt: -0.0222,
  },
  {
    stationCode: 'PM3F',
    nasaNumber: '4446',
    location: 'Point.Mugu, CA',
    equipment: 'C-bd FPS-16V 4.9m az-el SN 24',
    lat: 34.122887722222224,
    lon: -119.15476741666666,
    alt: -0.021670000000000002,
  },
  {
    stationCode: 'PM4F',
    nasaNumber: '4441',
    location: 'Point.Mugu, CA',
    equipment: 'C-bd FPS-16 3.7m az-el SN 3',
    lat: 34.12211825,
    lon: -119.15283013888887,
    alt: -0.02219,
  },
  {
    stationCode: 'PP2F',
    nasaNumber: '7399',
    location: 'Pt. Pillar, Ca.',
    equipment: 'C-bd MPS-36 4.3m az-el',
    lat: 37.49685436111111,
    lon: -122.4966838888889,
    alt: 0.00378,
  },
  {
    stationCode: 'PPTF',
    nasaNumber: '4240',
    location: 'Pillar Point, CA',
    equipment: 'C-bd FPS-16V 4.9m az-el',
    lat: 37.49769666666667,
    lon: -122.49869897222223,
    alt: 0.015049,
  },
  {
    stationCode: 'PPTQ',
    nasaNumber: '4260',
    location: 'Pillar Point, CA',
    equipment: 'C-bd FPQ-6 8.8m az-el',
    lat: 37.497816916666665,
    lon: -122.49971472222222,
    alt: 0.020149999999999998,
  },
  {
    stationCode: 'PPTY',
    nasaNumber: '4216',
    location: 'Pillar Point, CA',
    equipment: 'Tlm 12m S-bd az-el',
    lat: 37.49777411111111,
    lon: -122.49918072222223,
    alt: 0.027690000000000003,
  },
  {
    stationCode: 'PRTS',
    nasaNumber: '1342',
    location: 'Perth, Australia',
    equipment: 'S-bd az-el 15m',
    lat: -31.802,
    lon: 115.885,
    alt: 0.02216,
  },
  {
    stationCode: 'RALS',
    nasaNumber: '1700',
    location: 'Rutherford Appelton Laboratory, UK',
    equipment: 'S-bd 12-meter az-el',
    lat: 51.572026,
    lon: -1.311463888888909,
    alt: 0.16312000000000001,
  },
  {
    stationCode: 'RGTS',
    nasaNumber: '1963',
    location: 'Tidbinbilla, Aust',
    equipment: 'S-bd 9m az-el',
    lat: -35.40453633333333,
    lon: 148.98241830555557,
    alt: 0.663255,
  },
  {
    stationCode: 'RTKS',
    nasaNumber: '1964',
    location: 'Tidbinbilla, Aust',
    equipment: 'S-bd 4.5m az-el',
    lat: -35.404744944444445,
    lon: 148.9826048611111,
    alt: 0.66143,
  },
  {
    stationCode: 'S22S',
    nasaNumber: '1734',
    location: 'Svalbard, Norway',
    equipment: 'S-band AzEl 7.3m',
    lat: 78.23290761111112,
    lon: 15.381773,
    alt: 0.481103,
  },
  {
    stationCode: 'SARS',
    nasaNumber: '1739',
    location: 'Saudi Arabia',
    equipment: 'S-bd, az-el',
    lat: 17.667,
    lon: 53.883,
    alt: 0.03048,
  },
  {
    stationCode: 'SEYS',
    nasaNumber: '4071',
    location: 'Seychelles, Mahe',
    equipment: 'Tlm S-bd 18m az-el',
    lat: -4.671748111111111,
    lon: 55.47782052777778,
    alt: 0.560495,
  },
  {
    stationCode: 'SF1S',
    nasaNumber: '1703',
    location: 'Sioux Falls, S. Dakota',
    equipment: 'S-bd az-el',
    lat: 43.73607263888889,
    lon: -96.62251461111111,
    alt: 0.468752,
  },
  {
    stationCode: 'SF2S',
    nasaNumber: '1716',
    location: 'Sioux Falls, S. Dakota',
    equipment: 'S-bd, 5m, x-y',
    lat: 43.73428666666667,
    lon: -96.61943777777776,
    alt: 0.459336,
  },
  {
    stationCode: 'SG1S',
    nasaNumber: '1702',
    location: 'Svalbard, Norway',
    equipment: 'S-band, Az-El, 11m',
    lat: 78.23076669444444,
    lon: 15.389533694444443,
    alt: 0.50028,
  },
  {
    stationCode: 'SG3S',
    nasaNumber: '1733',
    location: 'Svalbard, Norway',
    equipment: 'S-band az-el 13.6 meters',
    lat: 78.229735,
    lon: 15.408095833333334,
    alt: 0.501378,
  },
  {
    stationCode: 'SG4S',
    nasaNumber: '1723',
    location: 'Svalbard, Norway',
    equipment: 'S-band',
    lat: 78.22802300000001,
    lon: 15.409667222222222,
    alt: 0.508591,
  },
  {
    stationCode: 'SG6S',
    nasaNumber: '1750',
    location: 'KSat Svalbard, Norway',
    equipment: 'USB S-band, Az-El, 11m',
    lat: 78.230802,
    lon: 15.417288,
    alt: 0.446184,
  },
  {
    stationCode: 'SI1S',
    nasaNumber: '1742',
    location: 'Kongsberg Satellite Services, Singapore',
    equipment: 'S-bd, Az-El,9m',
    lat: 1.391918,
    lon: 103.835,
    alt: 0.03,
  },
  {
    stationCode: 'SIPQ',
    nasaNumber: '4003',
    location: 'Saipan',
    equipment: 'C-bd az-el',
    lat: 15.249145833333332,
    lon: 145.79621666666665,
    alt: 0.3482,
  },
  {
    stationCode: 'SN2F',
    nasaNumber: '4443',
    location: 'San.Nicolas Island, CA',
    equipment: 'C-bd FPS-16V 4.9m az-el SN 13',
    lat: 33.247685000000004,
    lon: -119.52074280555556,
    alt: 0.24668,
  },
  {
    stationCode: 'SN3F',
    nasaNumber: '4444',
    location: 'San.Nicolas Island, CA',
    equipment: 'C-bd FPS-16 3.7m az-el SN 15',
    lat: 33.24839275,
    lon: -119.52139116666666,
    alt: 0.24612,
  },
  {
    stationCode: 'SNIF',
    nasaNumber: '4442',
    location: 'San.Nicolas Island, CA',
    equipment: 'C-bd FPS-16 3.7m az-el SN 7',
    lat: 33.24697758333333,
    lon: -119.52009433333333,
    alt: 0.24616,
  },
  {
    stationCode: 'SOCA',
    nasaNumber: '4139',
    location: 'Suitland, Md.',
    equipment: 'S-bd 30ft x-y',
    lat: 38.85002611111111,
    lon: -76.93222222222221,
    alt: 0.118566,
  },
  {
    stationCode: 'ST1F',
    nasaNumber: '4224',
    location: 'St. Thomas, Virgin Island',
    equipment: 'Capri',
    lat: 18.357253333333336,
    lon: -64.97321647222225,
    alt: 0.13045400000000001,
  },
  {
    stationCode: 'ST2K',
    nasaNumber: '4751',
    location: 'White Sands, NM',
    equipment: 'TDRSS-STGT 18m K-bd az-el',
    lat: 32.542977361111106,
    lon: -106.61208894444445,
    alt: 1.45231,
  },
  {
    stationCode: 'ST3K',
    nasaNumber: '4752',
    location: 'White Sands, NM',
    equipment: 'TDRSS-STGT 18m K-bd az-el',
    lat: 32.542674999999996,
    lon: -106.6120888888889,
    alt: 1.4523,
  },
  {
    stationCode: 'STE1',
    nasaNumber: '1934',
    location: 'STGT White Sands, NM',
    equipment: '1.8m Ka-bd,az-el,S-EET-1',
    lat: 32.54357430555555,
    lon: -106.61201730555555,
    alt: 1.44781,
  },
  {
    stationCode: 'STE2',
    nasaNumber: '1935',
    location: 'STGT White Sands, NM',
    equipment: '4.5m Ku/S-bd,az-el,S-EET-2',
    lat: 32.54227538888889,
    lon: -106.61203830555556,
    alt: 1.4463199999999998,
  },
  {
    stationCode: 'STGK',
    nasaNumber: '4750',
    location: 'White Sands, NM',
    equipment: 'TDRSS-STGT 18m K-bd az-el',
    lat: 32.54327958333333,
    lon: -106.61208894444445,
    alt: 1.45228,
  },
  {
    stationCode: 'STGS',
    nasaNumber: '4753',
    location: 'White Sands, NM',
    equipment: 'TDRSS-STGT TT&C S1',
    lat: 32.542451666666665,
    lon: -106.61208894444445,
    alt: 1.44946,
  },
  {
    stationCode: 'STSS',
    nasaNumber: '1741',
    location: 'White Sands, NM',
    equipment: 'S-bd, az-el, Ka-bd',
    lat: 32.54171675,
    lon: -106.61209905555557,
    alt: 1.4560899999999999,
  },
  {
    stationCode: 'STWS',
    nasaNumber: '1740',
    location: 'White Sands, NM',
    equipment: 'S-bd, az-el, Ka-bd',
    lat: 32.49950677777778,
    lon: -106.60856383333333,
    alt: 1.460383,
  },
  {
    stationCode: 'SWNS',
    nasaNumber: '1796',
    location: 'Port Hedland, Australia',
    equipment: 'Tlm S-bd,az-el',
    lat: -20.38,
    lon: 118.635,
    alt: 0.242,
  },
  {
    stationCode: 'SYOQ',
    nasaNumber: '4262',
    location: 'Syowa, Antarctica',
    equipment: 'C-bd az-el 9m',
    lat: -69.00609644444444,
    lon: 39.59015388888889,
    alt: 0.005372,
  },
  {
    stationCode: 'TH2S',
    nasaNumber: '1731',
    location: 'Thule, Greenland',
    equipment: 'S-bd az-el',
    lat: 76.51536388888888,
    lon: -68.59881472222224,
    alt: 0.14737,
  },
  {
    stationCode: 'THUS',
    nasaNumber: '1730',
    location: 'Thule, Greenland',
    equipment: 'S-bd az-el',
    lat: 76.51629305555555,
    lon: -68.59901777777782,
    alt: 0.14116,
  },
  {
    stationCode: 'TR2S',
    nasaNumber: '1738',
    location: 'Antarctica',
    equipment: '7.3m S-band AzEl',
    lat: -72.00222205555555,
    lon: 2.5241237777777776,
    alt: 1.416582,
  },
  {
    stationCode: 'TR3S',
    nasaNumber: '1748',
    location: 'Antarctica',
    equipment: '7.3m USB S-band AzEl',
    lat: -72.00214708333333,
    lon: 2.52501175,
    alt: 1.409348,
  },
  {
    stationCode: 'TSMF',
    nasaNumber: '4080',
    location: 'University of Tasmania, Australia',
    equipment: 'C-bd az-el',
    lat: -42.805,
    lon: 147.439,
    alt: 0.043,
  },
  {
    stationCode: 'TT2S',
    nasaNumber: '1376',
    location: 'Thule, Greenland',
    equipment: 'Tlm-SGLS S-bd 46-ft az-el',
    lat: 76.51536441666667,
    lon: -68.59885830555555,
    alt: 0.146985,
  },
  {
    stationCode: 'TTSS',
    nasaNumber: '1369',
    location: 'Thule, Greenland',
    equipment: 'Tlm-SGLS S-bd 14-ft az-el',
    lat: 76.51593455555556,
    lon: -68.5999722777778,
    alt: 0.135906,
  },
  {
    stationCode: 'TULF',
    nasaNumber: '4151',
    location: 'Tularosa, NM',
    equipment: 'C-bd FPS-16 3.7m az-el',
    lat: 33.09616152777778,
    lon: -106.15915416666667,
    alt: 1.2412539999999999,
  },
  {
    stationCode: 'U2HS',
    nasaNumber: '1779',
    location: 'USN, Hawaii',
    equipment: 'S-bd X-bd, 13.4 meter, az-el',
    lat: 19.013793444444445,
    lon: -155.66294408333331,
    alt: 0.38230000000000003,
  },
  {
    stationCode: 'U2PS',
    nasaNumber: '1771',
    location: 'Dongara, Australia',
    equipment: 'USB S-bd X-bd, 7.3m, az-el',
    lat: -29.045694444444447,
    lon: 115.34902777777778,
    alt: 0.24969999999999998,
  },
  {
    stationCode: 'U3AS',
    nasaNumber: '1745',
    location: 'North Pole, Alaska',
    equipment: 'USB S-bd cmd,S/X-bd rcv,X-Y 5.4m',
    lat: 64.80441769444444,
    lon: -147.5018818888889,
    alt: 0.1579,
  },
  {
    stationCode: 'U4AS',
    nasaNumber: '1746',
    location: 'North Pole, Alaska',
    equipment: 'USB S-bd cmd,S/X-bd rcv,AzEl 7.3',
    lat: 64.80472008333334,
    lon: -147.50421247222224,
    alt: 0.15719999999999998,
  },
  {
    stationCode: 'U5AS',
    nasaNumber: '1747',
    location: 'North Pole, Alaska',
    equipment: 'USB S-band Az-El 11m',
    lat: 64.80341405555555,
    lon: -147.50059425,
    alt: 0.1603,
  },
  {
    stationCode: 'UL1S',
    nasaNumber: '1854',
    location: 'Fairbanks, AK',
    equipment: 'Az-El 21m S-band',
    lat: 64.97275,
    lon: -147.5011,
    alt: 0.44730000000000003,
  },
  {
    stationCode: 'UL23',
    nasaNumber: '1371',
    location: 'Fairbanks, AK',
    equipment: 'USB 6m X-Y n-s',
    lat: 64.97240711111111,
    lon: -147.51806566666667,
    alt: 0.331108,
  },
  {
    stationCode: 'UL33',
    nasaNumber: '1332',
    location: 'Fairbanks, AK',
    equipment: 'USB 26m X-Y n-s',
    lat: 64.97681388888888,
    lon: -147.51806616666667,
    alt: 0.308056,
  },
  {
    stationCode: 'ULA4',
    nasaNumber: '1401',
    location: 'Gilmore Creek, AK',
    equipment: 'X-Y tracker 12m n-s',
    lat: 64.97659575,
    lon: -147.5212828888889,
    alt: 0.299159,
  },
  {
    stationCode: 'ULAE',
    nasaNumber: '1853',
    location: 'Fairbanks, AK',
    equipment: 'X-Y tracker 26m n-s',
    lat: 64.97671555555556,
    lon: -147.51755472222223,
    alt: 0.308,
  },
  {
    stationCode: 'USAS',
    nasaNumber: '1709',
    location: 'Bradway Rd, AK',
    equipment: 'S-bd X-bd, 13 meter, az-el',
    lat: 64.80424111111111,
    lon: -147.50021416666667,
    alt: 0.16058,
  },
  {
    stationCode: 'USDS',
    nasaNumber: '1717',
    location: 'Dongara, Australia',
    equipment: 'S-bd, az-el',
    lat: -29.045772000000003,
    lon: 115.34867799999999,
    alt: 0.25184,
  },
  {
    stationCode: 'USHS',
    nasaNumber: '1778',
    location: 'USN, Hawaii',
    equipment: 'S-bd X-bd, 13.4 meter, az-el',
    lat: 19.0139525,
    lon: -155.66333183333333,
    alt: 0.38519400000000004,
  },
  {
    stationCode: 'USPS',
    nasaNumber: '1770',
    location: 'Perth, Australia',
    equipment: 'S-bd X-bd, 13.4 meter, az-el',
    lat: -29.04577216666667,
    lon: 115.34867763888889,
    alt: 0.25047,
  },
  {
    stationCode: 'VD2F',
    nasaNumber: '4247',
    location: 'Vandenberg AFB, CA',
    equipment: 'C-bd FPQ-14 8.8m az-el HAIR',
    lat: 34.75823183333333,
    lon: -120.6271213888889,
    alt: 0.02604,
  },
  {
    stationCode: 'VD3F',
    nasaNumber: '4251',
    location: 'Vandenberg AFB, Ca.',
    equipment: 'C-bd TLM 9m az-el',
    lat: 34.58304294444445,
    lon: -120.56111494444443,
    alt: 0.6272519999999999,
  },
  {
    stationCode: 'VD4F',
    nasaNumber: '4254',
    location: 'Vandenberg AFB, CA.',
    equipment: 'C-bd MPS-39 MOTR 9m az-el',
    lat: 34.58304294444445,
    lon: -120.56111494444443,
    alt: 0.627248,
  },
  {
    stationCode: 'VDB3',
    nasaNumber: '1333',
    location: 'Vandenberg AFB, CA',
    equipment: 'USB 9m X-Y n-s',
    lat: 34.56562591666666,
    lon: -120.50161730555556,
    alt: 0.6094400000000001,
  },
  {
    stationCode: 'VDBF',
    nasaNumber: '4246',
    location: 'Vandenberg AFB, CA',
    equipment: 'C-bd MPS-36 #2 4.3m az-el',
    lat: 34.77487769444444,
    lon: -120.53610972222222,
    alt: 0.122607,
  },
  {
    stationCode: 'VEND',
    nasaNumber: '1513',
    location: 'Goldstone, CA',
    equipment: 'JPL DSS13 34m R & D',
    lat: 35.247164250000004,
    lon: -116.79445900000002,
    alt: 1.070444,
  },
  {
    stationCode: 'VT2S',
    nasaNumber: '1372',
    location: 'Vandenberg AFB, CA',
    equipment: 'Tlm-SGLS S-bd 46-ft az-el',
    lat: 34.82564077777778,
    lon: -120.50539766666668,
    alt: 0.26861,
  },
  {
    stationCode: 'VTSS',
    nasaNumber: '1365',
    location: 'Vandenberg AFB, CA',
    equipment: 'Tlm-SGLS S-bd 60-ft az-el',
    lat: 34.822616555555555,
    lon: -120.50184844444445,
    alt: 0.27251,
  },
  {
    stationCode: 'WAPS',
    nasaNumber: '1341',
    location: 'Wallops Island, VA',
    equipment: 'S-bd az-el 11m',
    lat: 37.924925555555554,
    lon: -75.47652249999999,
    alt: -0.0201,
  },
  {
    stationCode: 'WD3F',
    nasaNumber: '4846',
    location: 'Wallops Island, VA',
    equipment: 'C-bd FPS-16V 4.9m az-el',
    lat: 37.85636525,
    lon: -75.51143536111107,
    alt: -0.018126,
  },
  {
    stationCode: 'WD4F',
    nasaNumber: '4843',
    location: 'Wallops Island, VA',
    equipment: 'C-band RIR-778C 12-ft',
    lat: 37.85636638888889,
    lon: -75.511439,
    alt: 0.01866,
  },
  {
    stationCode: 'WH2J',
    nasaNumber: '0202',
    location: 'White Sands, NM',
    equipment: 'BRT 2-ft manual az-el',
    lat: 32.50628147222222,
    lon: -106.61196575,
    alt: 1.44261,
  },
  {
    stationCode: 'WH2K',
    nasaNumber: '1921',
    location: 'White Sands, NM',
    equipment: 'TDRSS 18m K-bd az-el',
    lat: 32.50073719444445,
    lon: -106.60855172222222,
    alt: 1.45974,
  },
  {
    stationCode: 'WH2S',
    nasaNumber: '1962',
    location: 'White Sands, NM',
    equipment: 'TDRSS S-bd 3m az-el sim',
    lat: 32.501296555555555,
    lon: -106.6085538888889,
    alt: 1.457665,
  },
  {
    stationCode: 'WH3K',
    nasaNumber: '1922',
    location: 'White Sands, NM',
    equipment: 'TDRSS 18m K-bd az-el',
    lat: 32.50046227777778,
    lon: -106.60855158333334,
    alt: 1.45974,
  },
  {
    stationCode: 'WH4K',
    nasaNumber: '1925',
    location: 'White Sands, NM',
    equipment: 'TDRSS 4.5m K-bd az-el sim',
    lat: 32.50138961111111,
    lon: -106.6085538888889,
    alt: 1.458675,
  },
  {
    stationCode: 'WH5K',
    nasaNumber: '1940',
    location: 'White Sands, NM',
    equipment: 'TDRSS K/S-bd 2m az-el axial rati',
    lat: 32.50124183333333,
    lon: -106.60855361111112,
    alt: 1.456635,
  },
  {
    stationCode: 'WH6F',
    nasaNumber: '4145',
    location: 'White Sands, NM',
    equipment: 'C-bd FPS-16V 4.9m az-el SN 12',
    lat: 33.81386394444444,
    lon: -106.65901227777778,
    alt: 1.50897,
  },
  {
    stationCode: 'WH7F',
    nasaNumber: '4147',
    location: 'White Sands, NM',
    equipment: 'C-bd FPS-16V 4.9m az-el SN 22',
    lat: 33.81307827777778,
    lon: -106.65901447222222,
    alt: 1.4974539999999998,
  },
  {
    stationCode: 'WH9F',
    nasaNumber: '4146',
    location: 'Phillips Hill, NM',
    equipment: 'C-bd FPS-16 3.7m az-el',
    lat: 33.44521866666666,
    lon: -106.1321083888889,
    alt: 1.592508,
  },
  {
    stationCode: 'WHSF',
    nasaNumber: '4143',
    location: 'White Sands, NM',
    equipment: 'C-bd FPS-16 3.7m az-el SN 6',
    lat: 32.35804211111111,
    lon: -106.36980741666665,
    alt: 1.20986,
  },
  {
    stationCode: 'WHSJ',
    nasaNumber: '0201',
    location: 'White Sands, NM',
    equipment: 'BRT 2-ft manual az-el',
    lat: 32.50628147222222,
    lon: -106.61196575,
    alt: 1.44261,
  },
  {
    stationCode: 'WHSK',
    nasaNumber: '1920',
    location: 'White Sands, NM',
    equipment: 'TDRSS 18m K-bd az-el',
    lat: 32.501012055555556,
    lon: -106.60855172222222,
    alt: 1.45973,
  },
  {
    stationCode: 'WHSS',
    nasaNumber: '1961',
    location: 'White Sands, NM',
    equipment: 'TDRSS S-bd 10m az-el TT&C',
    lat: 32.50026977777778,
    lon: -106.60855188888888,
    alt: 1.4522599999999999,
  },
  {
    stationCode: 'WL2F',
    nasaNumber: '4841',
    location: 'Wallops Island, VA',
    equipment: 'C-bd FPS-16V 4.9m az-el',
    lat: 37.94409913888889,
    lon: -75.46422336111107,
    alt: -0.014039999999999999,
  },
  {
    stationCode: 'WL2S',
    nasaNumber: '4206',
    location: 'Wallops Island, VA',
    equipment: 'Tlm S-bd 18m az-el',
    lat: 37.94642969444444,
    lon: -75.46205786111108,
    alt: -0.006764999999999999,
  },
  {
    stationCode: 'WL3F',
    nasaNumber: '4845',
    location: 'Wallops Island, VA',
    equipment: 'C-bd FPS-16V 4.9m az-el',
    lat: 37.85636525,
    lon: -75.51143536111107,
    alt: -0.018126,
  },
  {
    stationCode: 'WL3S',
    nasaNumber: '4207',
    location: 'Wallops Island, VA',
    equipment: 'S-bd 18m az-el',
    lat: 37.94579497222222,
    lon: -75.46043669444441,
    alt: -0.0056890000000000005,
  },
  {
    stationCode: 'WL4F',
    nasaNumber: '4842',
    location: 'Wallops Island, VA',
    equipment: 'C-band RIR-778C 12-ft',
    lat: 37.85636638888889,
    lon: -75.511439,
    alt: 0.01866,
  },
  {
    stationCode: 'WL4S',
    nasaNumber: '4208',
    location: 'Wallops Island, VA',
    equipment: 'S-bd 13m az-el',
    lat: 37.94631330555555,
    lon: -75.46059947222221,
    alt: -0.028793,
  },
  {
    stationCode: 'WL53',
    nasaNumber: '4209',
    location: 'Wallops',
    equipment: 'S-bd 14.2 meter X-Y N-S CDA',
    lat: 37.946799999999996,
    lon: -75.46009999999995,
    alt: -0.025806000000000003,
  },
  {
    stationCode: 'WL6S',
    nasaNumber: '4210',
    location: 'Wallops',
    equipment: 'S-bd 7-meter Az-El CDA',
    lat: 37.9456,
    lon: -75.46109999999999,
    alt: 0.013215999999999999,
  },
  {
    stationCode: 'WLPF',
    nasaNumber: '4840',
    location: 'Wallops Island, VA',
    equipment: 'C-bd FPS-16 3.7m az-el',
    lat: 37.84133972222222,
    lon: -75.48508908333332,
    alt: -0.024,
  },
  {
    stationCode: 'WLPQ',
    nasaNumber: '4860',
    location: 'Wallops Island, VA',
    equipment: 'C-bd FPQ-6 8.8m az-el',
    lat: 37.86026147222223,
    lon: -75.50929555555552,
    alt: -0.0217,
  },
  {
    stationCode: 'WP2S',
    nasaNumber: '1337',
    location: 'Wallops Island, VA',
    equipment: 'S-bd 7.3m az-el receive only',
    lat: 37.92806697222222,
    lon: -75.47441624999999,
    alt: -0.020960999999999997,
  },
  {
    stationCode: 'WP2Y',
    nasaNumber: '1838',
    location: 'Wallops Island, VA',
    equipment: 'Tlm Satan RCVR (SRE-VHF)',
    lat: 37.925585166666664,
    lon: -75.47685741666669,
    alt: -0.015234,
  },
  {
    stationCode: 'WP2Z',
    nasaNumber: '1840',
    location: 'Wallops Island, Va.',
    equipment: 'UHF & VHF 24-ft X-Y',
    lat: 37.92891233333333,
    lon: -75.47375113888893,
    alt: -0.017846,
  },
  {
    stationCode: 'WP3S',
    nasaNumber: '1338',
    location: 'Wallops Island, VA',
    equipment: 'S-bd North 7.3m az-el recr',
    lat: 37.92836116666666,
    lon: -75.47418797222224,
    alt: -0.019631,
  },
  {
    stationCode: 'WP3Z',
    nasaNumber: '1841',
    location: 'Wallops, Va',
    equipment: 'VHF2 Quad Yagi',
    lat: 37.92363122222222,
    lon: -75.47752844444449,
    alt: -0.020388000000000003,
  },
  {
    stationCode: 'WPDA',
    nasaNumber: '1339',
    location: 'Wallops Island, VA',
    equipment: 'USB 9m X-Y e-w',
    lat: 37.92737297222222,
    lon: -75.47495275,
    alt: -0.010538,
  },
  {
    stationCode: 'WPS8',
    nasaNumber: '1336',
    location: 'Wallops Island, VA',
    equipment: 'S-bd 6m X-Y e-w xmit',
    lat: 37.927359027777776,
    lon: -75.47583319444448,
    alt: -0.020647,
  },
  {
    stationCode: 'WPSA',
    nasaNumber: '1334',
    location: 'Wallops Island, VA',
    equipment: 'USB 9m X-Y e-w',
    lat: 37.92727766666666,
    lon: -75.47498580555555,
    alt: -0.019736,
  },
  {
    stationCode: 'WPSS',
    nasaNumber: '1335',
    location: 'Wallops Island, VA',
    equipment: 'S-bd 18m az-el receive only',
    lat: 37.92658986111111,
    lon: -75.47630452777781,
    alt: -0.012762,
  },
  {
    stationCode: 'WS1S',
    nasaNumber: '1931',
    location: 'White Sands, NM',
    equipment: 'S/ka-bd uplk,S-bd downlk,18.3m',
    lat: 32.540754944444444,
    lon: -106.61209952777779,
    alt: 1.456545,
  },
  {
    stationCode: 'WSCZ',
    nasaNumber: '1871',
    location: 'White Sands, NM',
    equipment: 'az-el, VHF-1',
    lat: 32.50472222222222,
    lon: -106.61083333333335,
    alt: 1.47066,
  },
  {
    stationCode: 'WSE1',
    nasaNumber: '1932',
    location: 'WSGT White Sands, NM',
    equipment: '1.8m Ka-bd,az-el, W-EET-1',
    lat: 32.5013735,
    lon: -106.60845561111111,
    alt: 1.4496300000000002,
  },
  {
    stationCode: 'WSE2',
    nasaNumber: '1933',
    location: 'WSGT White Sands, NM',
    equipment: '4.5m Ku/S-bd,az-el,W-EET-2',
    lat: 32.5013735,
    lon: -106.60854269444445,
    alt: 1.45786,
  },
  {
    stationCode: 'WSSH',
    nasaNumber: '1870',
    location: 'White Sands Space Harbor',
    equipment: 'UHF',
    lat: 32.88027777777778,
    lon: -106.46472222222224,
    alt: 1.198474,
  },
  {
    stationCode: 'WT1S',
    nasaNumber: '4865',
    location: 'Poker Flat, AK',
    equipment: 'S-bd TOTS',
    lat: 65.11723691666666,
    lon: -147.45906027777778,
    alt: 0.431444,
  },
  {
    stationCode: 'WT2S',
    nasaNumber: '4866',
    location: 'Poker Flat, Ak.',
    equipment: 'S-bd TOTS',
    lat: 65.11673325,
    lon: -147.46154869444445,
    alt: 0.430342,
  },
  {
    stationCode: 'WT3S',
    nasaNumber: '4867',
    location: 'Wallops Island, Va.',
    equipment: 'S-bd 26ft az-el TOTS',
    lat: 37.923065916666665,
    lon: -75.4774688888889,
    alt: -0.010644,
  },
  {
    stationCode: 'WTDQ',
    nasaNumber: '4861',
    location: 'Wallops Island, Va.',
    equipment: 'S-bd',
    lat: 37.923065916666665,
    lon: -75.4774688888889,
    alt: -0.010644,
  },
  {
    stationCode: 'WTDS',
    nasaNumber: '4861',
    location: 'Wallops Island, Va.',
    equipment: 'S-bd',
    lat: 37.923065916666665,
    lon: -75.4774688888889,
    alt: -0.010644,
  },
  {
    stationCode: 'WU1S',
    nasaNumber: '1907',
    location: 'Weilheim, Germany',
    equipment: 'S band',
    lat: 47.880069444444445,
    lon: 11.085302500000001,
    alt: 0.6633920000000001,
  },
  {
    stationCode: 'WU2S',
    nasaNumber: '1908',
    location: 'Weilheim, Germany',
    equipment: 'S band',
    lat: 47.88119888888889,
    lon: 11.08361888888889,
    alt: 0.663374,
  },
  {
    stationCode: 'WULY',
    nasaNumber: '4215',
    location: 'Vandenberg AFB, CA',
    equipment: 'Tlm S-bd 8.5m az-el',
    lat: 34.65541336111111,
    lon: -120.5559847222222,
    alt: 0.07523,
  },
];
