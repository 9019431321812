import { conditionsTableColumns } from 'components/AgentTemplateEditView/EditBoards/CDHEditBoard/OperationalModesSegment/OperationalModesDialog';
import { CdhAccent } from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { ISelectOption } from 'components/general/types';
import { ITargetGroup } from 'components/general/types/target';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectBlocks } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useCallback, useMemo, useRef } from 'react';
import { translateOut } from 'utils/forms';
import { TargetGroupVables } from 'utils/vable';
import { useGuidance } from './guidance';
import validation from './validation';

interface IForm {
  name: string;
  targetType: ISelectOption | '';
}
const defaultValues: IForm = {
  name: '',
  targetType: '',
};

interface IProps {
  control: TEntityDialogControl<ITargetGroup>;
}

const TargetGroupDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action, entity: targetGroup },
  } = control;
  const tgId = targetGroup?.id;
  const filterConditions = targetGroup?.filterConditions;
  const classes = useStyles();
  const { conditions } = useActiveEntities();

  const tgConditions = useMemo(() => {
    if (tgId) {
      return conditions.filter((c) => c?.targetGroup?.id === tgId);
    }
    return '';
  }, [conditions, tgId]);

  const { parsedBlocks: parsedConditions, setParsedBlocks: setParsedConditions } = useSelectBlocks(
    tgConditions,
    filterConditions
  );
  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      const checkedConditions = parsedConditions
        .filter((c) => c.tableData.checked)
        .map((c) => c.id);
      return translateOut(
        {
          name: values.name,
          targetType: values.targetType,
          filterConditions: checkedConditions,
        },
        allowedEmptyFields,
        options
      );
    },
    [parsedConditions]
  );

  const options = useMemo(() => {
    return {
      targetType: TargetGroupVables.TargetTypes.options,
    };
  }, []);

  const entityForm = useEntityForm<ITargetGroup, IForm>({
    entityTypeText: 'Target Group',
    entityDialogControl: control,
    defaultValues,
    additionalCreateValues: { type: 'TargetGroup' },
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      options,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  return (
    <EntityDialog entityForm={entityForm} customDirty={true}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Target Group Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          <LabeledSelect
            {...getFieldProps('targetType')}
            // target type for a target group is only selected at creation, it cannot be updated
            isDisabled={values.targetType && action !== 'create'}
            label="Type of Targets in this Group"
            placeholder="Select Target Type..."
            options={options.targetType}
          />
        </div>
      </div>
      <div className={classes.inputGroup}>
        <CdhAccent header="Conditions">
          <WidgetTable
            tableRef={useRef(null)}
            className={classes.table}
            columns={conditionsTableColumns}
            setData={setParsedConditions}
            data={parsedConditions}
            emptyMessage={'No conditions found for this target group.'}
            title="Select Conditions"
            search={true}
            selection={true}
          />
        </CdhAccent>
      </div>
    </EntityDialog>
  );
};

export default TargetGroupDialog;
