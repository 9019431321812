import { IColumn } from 'components/general/types';
import { IAgent } from 'components/general/types/agent';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import { AgentVables, TAgentType } from 'utils/vable';
import AgentDialog from './AgentDialog';
import guidance from './guidance';

const agentColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (rowData: IAgent) => AgentVables.AgentType[rowData.type as TAgentType].label,
  },
];

interface IProps {
  index: string;
}

const AgentSegment = ({ index }: IProps) => {
  const { agents } = useActiveEntities();

  return (
    <EntityTableSegment<IAgent>
      className="joyride-scenario-agents"
      title="Agents"
      index={index}
      guidance={guidance}
      entityColumns={agentColumns}
      entityData={agents}
      modelName="agent"
      DialogComponent={AgentDialog}
    ></EntityTableSegment>
  );
};

export default AgentSegment;
