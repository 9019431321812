import { InputAdornment } from '@material-ui/core';
import { MdAccent } from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import {
  IErrorResponse,
  IGenericObject,
  IMission,
  IMissionVersion,
  ISelectOption,
} from 'components/general/types';
import { IAgent } from 'components/general/types/agent';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useSelectAll, useSelectByIds, useSnackbar } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import useWorkspace from 'hooks/useWorkspace';
import _ from 'lodash';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FixedSizeList } from 'react-window';
import { translateIn, translateOut } from 'utils/forms';
import { TMetamodelTypes } from 'utils/repoAndMmType';
import { AgentVables, OrbitVables, RepoVables, TAgentType, TargetVables } from 'utils/vable';
import AttitudeForm from '../AttitudeForm';
import OrbitForm, { ephemFileToType } from '../OrbitForm';
import AgentPreview from './AgentPreview';
import WaypointInputs from './WaypointInputs';
import { useGuidance } from './guidance';
import { TStation, stations } from './stations';
import validation from './validation';

interface IForm {
  name: string;
  type: ISelectOption | '';
  templateRepo: (ISelectOption & { branches: string[] }) | '';
  templateBranch:
    | (ISelectOption & {
        targets: IMissionVersion['targets'];
        targetGroups: IMissionVersion['targetGroups'];
        metamodelType: TMetamodelTypes;
      })
    | '';

  // Initial attitude and angular velocity
  specifyAttitude: boolean;
  attitude: [number, number, number, number] | '';
  angularVelocity: [number, number, number] | '';

  // Kinematics
  kinematics: {
    type: ISelectOption | '';

    // Ephemeris (only spk)
    objectCode?: number;

    // Orbits
    initialStateDefType: ISelectOption | '';
    initialStateDefParams: IGenericObject & { initialRefOrbit: ISelectOption | '' };

    // Waypoints
    waypoints: { llaDeg: [number | '', number | '', number | '']; formKey?: number | string }[];
    timestamps: (number | '')[];
    speeds: (number | '')[];
    durations: ({ s: number } | '')[];
  };

  // Ground points
  lat: { deg: number | '' };
  lon: { deg: number | '' };
  alt: { km: number | '' };

  // Celestial points
  body: ISelectOption | '';
}

const defaultValues: IForm = {
  name: '',
  type: '',
  templateRepo: '',
  templateBranch: '',

  // Initial attitude and angular velocity
  specifyAttitude: false,
  attitude: [0, 0, 0, 1],
  angularVelocity: [0, 0, 0],

  // Kinematics
  kinematics: {
    // Ephemeris (only spk)
    objectCode: undefined,

    // Orbits
    initialStateDefType: '',
    initialStateDefParams: {
      a: '',
      e: '',
      inc: '',
      nu: '',
      om: '',
      raan: '',
      alt: '',
      lon: '',
      altPerigee: '',
      mltAscNode: null,
      initialRefOrbit: '',
      stateEci: ['', '', '', '', '', ''],
    },

    // Waypoints
    type: '',
    waypoints: [],
    timestamps: [],
    speeds: [],
    durations: [],
  },

  // Ground points
  lat: { deg: '' },
  lon: { deg: '' },
  alt: { km: '' },

  // Celestial points
  body: '',
};

interface IProps {
  control: TEntityDialogControl<IAgent>;
}

type TStationOption = {
  label: string;
  value: TStation;
};

// Map stations to options for selecting
const stationOptions: TStationOption[] = [];
for (const station of stations) {
  stationOptions.push({ label: `${station.stationCode}: ${station.location}`, value: station });
}

const targetTypeToAgentType: { [key: string]: TAgentType } = {
  [TargetVables.Type.GroundTarget.value]: AgentVables.AgentType.PeripheralGroundPoint.value,
  [TargetVables.Type.AreaTarget.value]: AgentVables.AgentType.PeripheralGroundArea.value,
  [TargetVables.Type.SpaceTarget.value]: AgentVables.AgentType.PeripheralSpacePoint.value,
  [TargetVables.Type.CelestialTarget.value]: AgentVables.AgentType.PeripheralCelestialPoint.value,
};

const AgentDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { entity: agent, action },
  } = control;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { agents, agentGroups } = useActiveEntities();
  const waypointListRef = useRef<FixedSizeList<IGenericObject>>(null);

  // orbit form and ephem related:
  const [orbitalDynamics, setOrbitalDynamics] = useState<ISelectOption>(() => {
    if (agent?.kinematics?.type) {
      if (Object.values(ephemFileToType).includes(agent.kinematics.type))
        return OrbitVables.OrbitalDynamics.EPHEMERIS;
      if (agent?.kinematics.type === 'PropagatedOrbitKinematics')
        return OrbitVables.OrbitalDynamics.PROPAGATED;
    }
    return { label: '', value: '' };
  });
  const [ephemFile, setEphemFile] = useState<File | null>(null);
  const [ephemFileName, ephemFileExt] = useMemo(() => {
    const ephemFileName = ephemFile
      ? ephemFile.name
      : agent?.kinematics && 'fileName' in agent?.kinematics
      ? agent.kinematics.fileName
      : undefined;
    const ephemFileExt = (ephemFileName ? `.${ephemFileName.split('.').at(-1)}` : undefined) as
      | keyof typeof ephemFileToType
      | undefined;
    return [ephemFileName, ephemFileExt];
  }, [ephemFile, agent?.kinematics]);

  const orbitProps = {
    setEphemFile,
    ephemFile,
    ephemFileName,
    ephemFileExt,
    orbitalDynamics,
    setOrbitalDynamics,
  };

  const {
    MissionVersion: {
      actions: { uploadEphemFile },
    },
  } = SatelliteApi;

  // Workspaces, repos, and branches
  const workspace = useWorkspace();
  const allRepos = useSelectAll('Mission') as IMission[];
  const templateRepos = useMemo(() => {
    return allRepos.filter(
      (repo) =>
        repo.repoType === RepoVables.RepoTypes.AGENT_TEMPLATE.value &&
        repo.workspace === workspace?.id
    );
  }, [allRepos, workspace?.id]);
  const branchIds = useMemo(
    () => templateRepos.map((repo) => repo.branches).flat(),
    [templateRepos]
  );
  const templateBranches = useSelectByIds('MissionVersion', branchIds) as IMissionVersion[];

  const waypointKinematics = useMemo(() => {
    if (!(agent?.kinematics && 'waypoints' in agent.kinematics)) return;
    // Even though the waypoints may be reordered, rerendering isn't too expensive here.
    // Trying to give each new waypoint (non-unique, no IDs) a unique key is a disaster.
    // Memoized to keep react and formik from getting trigger happy overwriting the path.
    const result = _.cloneDeep(agent?.kinematics);
    if (result) {
      result.waypoints.forEach((wp, i) => {
        // @ts-ignore: next-line
        wp.formKey = i;
      });
      // @ts-ignore: next-line
      result.type = AgentVables.WaypointType.options.find((o) => o.value === result.type)!;
    }
    return result;
  }, [agent?.kinematics]);

  const options = useMemo(
    () => ({
      type: AgentVables.AgentType.options.filter(
        (o) => o.value !== AgentVables.AgentType.PeripheralGroundArea.value
      ),
      templateRepo: templateRepos.map((repo) => ({
        value: repo.id,
        label: repo.name,
        // Include branches to help filter the templateBranch dropdown
        branches: repo.branches,
      })),
      templateBranch: templateBranches.map((branch) => ({
        value: branch.id,
        label: branch.name,
        // Include targets and target groups for the mapping dropdowns
        targets: branch.targets,
        targetGroups: branch.targetGroups,
        // Include metamodelType to differentiate kinematics
        metamodelType: branch.metamodelType,
      })),
      agent: agents
        .filter((a) => agent?.id !== a.id)
        .map((a) => {
          return {
            value: a.id,
            label: a.name,
            type: a.type,
            // _isGroup differentiates agents from agentGroups in the values.
            // Otherwise, they hold similar structures and can be confused in pre/post-processing
            _isGroup: false,
          };
        }),
      agentGroup: agentGroups.map((a) => {
        return { value: a.id, label: a.name, type: a.agentType, _isGroup: true };
      }),
      'kinematics.initialStateDefType': OrbitVables.InitialStateDefType.options,
      'kinematics.initialStateDefParams.initialRefOrbit': OrbitVables.InitialRefOrbit.options,
      'kinematics.type': AgentVables.WaypointType.options,
      body: TargetVables.PolynomialEphemerisBody.options,
    }),
    [templateRepos, templateBranches, agentGroups, agents, agent?.id]
  );

  const customTranslateIn = useCallback(
    (_agent, defaultValues, options, datetimes) => {
      _agent = { ..._agent };
      // Set templateRepo and templateBranch based on agent's templateRef
      _agent.templateBranch = _agent.templateRef;
      _agent.templateRepo = templateBranches.find((b) => b.id === _agent.templateRef)?.repository;

      // Convert targetMapping into dropdowns for each target,
      // using the target's id as the key and the associated agent as the value
      if (_agent?.targetMapping) {
        const targetMapping = _agent.targetMapping as IAgent['targetMapping'];
        for (const mappedAgent of targetMapping) {
          for (const mappedTarget of mappedAgent.dataSide.associatedTargets) {
            const targetId = mappedTarget;
            _agent[targetId] = options.agent.find(
              (option: ISelectOption) => option.value === mappedAgent.id
            );
            defaultValues[targetId] = '';
          }
        }
      }

      // Convert targetGroupMapping into dropdowns for each target group,
      // using the target group's id as the key and the associated agent group as the value
      if (_agent?.targetGroupMapping) {
        const targetGroupMapping = _agent.targetGroupMapping as IAgent['targetGroupMapping'];
        for (const mappedAgentGroup of targetGroupMapping) {
          for (const mappedTargetGroup of mappedAgentGroup.dataSide.associatedTargetGroups) {
            const targetGroupId = mappedTargetGroup;
            _agent[targetGroupId] = options.agentGroup.find(
              (option: ISelectOption) => option.value === mappedAgentGroup.id
            );
            defaultValues[targetGroupId] = '';
          }
        }
      }

      // Initial attitude and angular velocity
      if (agent?.attitude || agent?.angularVelocity) {
        _agent.specifyAttitude = true;
      }
      _agent.attitude = _agent.attitude?.body_eci || defaultValues.attitude;
      _agent.angularVelocity = _agent.angularVelocity?.['rad/s'] || defaultValues.angularVelocity;

      const kinematicsTypePreTranslate = _agent.kinematics?.type;
      const result = translateIn(_agent, defaultValues, options, datetimes);
      // b/c not all kinematics types are used in the form (see kinematics.type in options), some get "translated in" as
      // undefined, so change it back in this case
      if (result.kinematics)
        result.kinematics.type = result.kinematics.type ?? kinematicsTypePreTranslate;

      // Causes an infinite loop if done before translateIn, for some reason
      // Copy in kinematics, update so all lists (waypoints, durations, speeds, timestamps) are all the same length
      if (result.kinematics?.waypoints) {
        result.kinematics = waypointKinematics;
        if (result.kinematics.durations && result.kinematics.durations[0] !== '') {
          result.kinematics.durations.unshift('');
        } else if (result.kinematics.speeds && result.kinematics.speeds[0] !== '') {
          result.kinematics.speeds.unshift('');
        }
      }

      return result;
    },
    [templateBranches, waypointKinematics, agent]
  );

  const customTranslateOut = useCallback(
    (_agent, allowedEmptyFields, options, datetimes) => {
      const result = translateOut(_agent, allowedEmptyFields, options, datetimes);

      if (agent?.id) {
        result.id = agent.id;
      }

      // templateRef renamed to templateBranch for the sake of clarity in this form
      result.templateRef = _agent.templateBranch;

      // Initial attitude and angular velocity
      if (!_agent.specifyAttitude) {
        result.attitude = null;
        result.angularVelocity = null;
      } else {
        // #TODO: Everything coming out of the backend should be camelCase
        // eslint-disable-next-line @typescript-eslint/naming-convention
        result.attitude = { body_eci: result.attitude };
        result.angularVelocity = { 'rad/s': result.angularVelocity };
      }

      // Kinematics:
      // - Templated Agent: Kinematics is handled by the form (waypoints, orbits, etc.)
      // - Peripheral Space Point: Kinematics is handled by the form (orbits)
      // - Peripheral Ground Point: Kinematics only needs the `EcefStationaryKinematics` type
      let kinematics: IGenericObject = {};

      if (orbitalDynamics === OrbitVables.OrbitalDynamics.EPHEMERIS && ephemFileExt) {
        kinematics = {
          id: result.id ? result.kinematics.id : '$kinematicsId',
          type: ephemFileToType[ephemFileExt],
        };
        if (ephemFileExt === '.bsp') {
          kinematics.objectCode = result.kinematics.objectCode;
        }
      }
      //
      else if (
        result.type === AgentVables.AgentType.TemplatedAgent.value ||
        result.type === AgentVables.AgentType.PeripheralSpacePoint.value
      ) {
        kinematics = {
          id: result.id ? result.kinematics.id : '$kinematicsId',
        };

        // Handle templated agents with waypoints
        const relatedBranch = templateBranches.find((b) => b.id === result.templateRef);
        if (relatedBranch?.metamodelType === 'TerrestrialVehicle') {
          kinematics.name = `${result.name} Waypoints`;
          kinematics.waypoints = result.kinematics.waypoints;
          kinematics.waypoints.forEach((wp: IForm['kinematics']['waypoints'][0]) => {
            delete wp.formKey;
          });
          kinematics.type = result.kinematics.type;

          // Durations
          if (kinematics.type === AgentVables.WaypointType.WaypointPathWithDuration.value) {
            kinematics.durations = result.kinematics.durations;
            if (kinematics.durations[0] === '') kinematics.durations.shift();
          }
          // Speeds
          else if (kinematics.type === AgentVables.WaypointType.WaypointPathWithSpeed.value) {
            kinematics.speeds = result.kinematics.speeds;
            if (kinematics.speeds[0] === '') kinematics.speeds.shift();
          }
          // Timestamps
          else if (kinematics.type === AgentVables.WaypointType.WaypointPathWithTimestamps.value) {
            kinematics.timestamps = result.kinematics.timestamps;
          }
        }

        // Handle templated or peripheral agents with orbits
        else {
          kinematics.type = 'PropagatedOrbitKinematics';
          kinematics.initialStateDefType = result.kinematics.initialStateDefType;
          kinematics.initialStateDefParams = result.kinematics.initialStateDefParams;
          kinematics.initialStateDefType !== OrbitVables.InitialStateDefType.ECI_STATE.value &&
            delete kinematics.initialStateDefParams.stateEci;
        }
      } else if (result.type === AgentVables.AgentType.PeripheralGroundPoint.value) {
        // Handle ground points
        kinematics = {
          id: result.id ? result.kinematics.id : '$kinematicsId',
          type: 'EcefStationaryKinematics',
        };
      }

      // Remove kinematics from the agent result
      delete result.kinematics;

      // Target and target group mappings for templated agents
      if (result.type === AgentVables.AgentType.TemplatedAgent.value) {
        result.targetMapping = {};
        result.targetGroupMapping = {};
        for (const key in result) {
          // Collapse agent ids into single targetMapping (or targetGroupMapping) field
          if (_.has(result[key], 'type') && key !== 'kinematics') {
            if (result[key]._isGroup === false) {
              // Target mapping
              const agentId = result[key].value;
              if (result.targetMapping[agentId]?.associatedTargets) {
                if (!result.targetMapping[agentId].associatedTargets.includes(key)) {
                  result.targetMapping[agentId].associatedTargets.push(key);
                }
              } else {
                result.targetMapping[agentId] = { associatedTargets: [key] };
              }
              delete result[key];
            } else {
              // Target group mapping
              const agentGroupId = result[key].value;
              if (result.targetGroupMapping[agentGroupId]?.associatedTargetGroups) {
                if (!result.targetGroupMapping[agentGroupId].associatedTargetGroups.includes(key)) {
                  result.targetGroupMapping[agentGroupId].associatedTargetGroups.push(key);
                }
              } else {
                result.targetGroupMapping[agentGroupId] = { associatedTargetGroups: [key] };
              }
              delete result[key];
            }
          }
        }
      }

      if (kinematics.initialStateDefParams && kinematics.initialStateDefParams.initialRefOrbit !== 'SUN_SYNC_CIRC') {
        delete kinematics.initialStateDefParams.mltAscNode;
      }

      // Return a list instead of an object - multi-block crud
      const _result: IGenericObject[] = [result];
      if (!_.isEmpty(kinematics)) {
        result.kinematics = kinematics.id;
        _result.push(kinematics);
      }
      return _result;
    },
    [agent?.id, templateBranches, ephemFileExt, orbitalDynamics]
  );

  const extendOnSuccess = (res: IGenericObject) => {
    if (!ephemFile) return;
    const formData = new FormData();
    formData.append('ephemFile', ephemFile);
    const snackbarKey = enqueueSnackbar(`Uploading file "${ephemFile.name}"...`, {
      variant: 'info',
      persist: true,
    });
    dispatch(
      uploadEphemFile({
        id: res.branch.id,
        modelId: res.crud.blocks[1],
        formData,
        successCallback: () => {
          closeSnackbar(snackbarKey);
          enqueueSnackbar(`Successfully uploaded file "${ephemFile.name}"`, { variant: 'success' });
        },
        failureCallback: (response: IErrorResponse) => {
          closeSnackbar(snackbarKey);
          enqueueSnackbar(response.error.message);
        },
      })
    );
  };

  const entityForm = useEntityForm<IAgent, IForm, IGenericObject[]>({
    entityTypeText: 'Agent',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    extendOnSuccess,
    extendReset: () => {
      if (agent?.kinematics?.type) {
        if (Object.values(ephemFileToType).includes(agent.kinematics.type))
          setOrbitalDynamics(OrbitVables.OrbitalDynamics.EPHEMERIS);
        if (agent?.kinematics.type === 'PropagatedOrbitKinematics')
          setOrbitalDynamics(OrbitVables.OrbitalDynamics.PROPAGATED);
      } else {
        setOrbitalDynamics({ label: '', value: '' });
      }
    },
    valuesToRemove: ['templateRepo', 'templateBranch', 'specifyAttitude'],
    formikOptionalParams: {
      useGuidance,
      options,
      datetimes: ['kinematics.initialStateDefParams.mltAscNode'],
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setFieldValue } = formik;

  // Convenience boolean for checking if the agent is templated or peripheral
  const isTemplated = useMemo(
    () => _.isEqual(values.type, AgentVables.AgentType.TemplatedAgent),
    [values.type]
  );

  const previewProps = useMemo(
    () =>
      _.isEqual(values.type, AgentVables.AgentType.PeripheralGroundPoint)
        ? {
            agent: {
              name: values.name,
              lon: values.lat.deg,
              lat: values.lon.deg,
              alt: values.alt.km,
            },
            onClick: (pos: number[]) => {
              setFieldValue('lat.deg', pos[0]);
              setFieldValue('lon.deg', pos[1]);
              setFieldValue('alt.km', pos[2]);
            },
          }
        : // @ts-ignore: next-line
        AgentVables.WaypointType._keys.includes(values.kinematics?.type?.value)
        ? {
            waypoints: values.kinematics.waypoints,
            waypointListRef,
            onClick: (pos: number[]) => {
              setFieldValue('kinematics', {
                ...values.kinematics,
                waypoints: [
                  ...values.kinematics.waypoints,
                  {
                    llaDeg: [pos[0], pos[1], pos[2]],
                    formKey: Math.random(),
                  },
                ],
              });
            },
          }
        : undefined,
    [values, setFieldValue]
  );

  return (
    <EntityDialog
      entityForm={entityForm}
      customDirty={true}
      childrenRight={previewProps && <AgentPreview {...previewProps} />}
    >
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Agent Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          <LabeledSelect
            {...getFieldProps('type')}
            label="Agent Type"
            options={options.type}
            isDisabled={action !== 'create'}
            formikOnChange={(val: IForm['type']) => {
              if (val !== values.type) {
                setFieldValue('templateRepo', '');
                setFieldValue('templateBranch', '');
                setFieldValue('kinematics', defaultValues.kinematics);
              }
            }}
          />
        </div>
        {isTemplated && (
          <div className={classes.inputGroup}>
            <LabeledSelect
              {...getFieldProps('templateRepo')}
              label="Template Repository"
              options={options.templateRepo}
              formikOnChange={(val: IForm['templateRepo']) => {
                if (val !== values.templateRepo) {
                  setFieldValue('templateBranch', '');
                  setFieldValue('kinematics', defaultValues.kinematics);
                }
              }}
            />
            {values.templateRepo && (
              <div className={classes.indent}>
                <LabeledSelect
                  {...getFieldProps('templateBranch')}
                  label="Template Branch"
                  options={options.templateBranch.filter(
                    (branchOption) =>
                      values.templateRepo &&
                      values.templateRepo.branches.includes(branchOption.value)
                  )}
                />
                {values.templateBranch && values.templateBranch.targetGroups.length > 0 && (
                  // Create a dropdown for each target group in this branch,
                  // letting the user map each to an actual agent group in the scenario
                  <MdAccent header="Target Group Mapping">
                    {values.templateBranch.targetGroups.map(({ id, name, targetType }) => (
                      <LabeledSelect
                        {...getFieldProps(id)}
                        key={id}
                        label={name}
                        isClearable
                        options={options.agentGroup.filter(
                          (a) =>
                            // Double mapping allowed
                            a.type === targetType
                        )}
                      />
                    ))}
                  </MdAccent>
                )}
                {values.templateBranch && values.templateBranch.targets.length > 0 && (
                  // Create a dropdown for each target in this branch,
                  // letting the user map each to an actual agent in the scenario
                  <MdAccent header="Target Mapping">
                    {values.templateBranch.targets.map((target) => (
                      <LabeledSelect
                        {...getFieldProps(target.id)}
                        key={target.id}
                        label={target.name}
                        isClearable
                        options={options.agent.filter(
                          (a) =>
                            // Double mapping allowed
                            a.type === targetTypeToAgentType[target.targetType]
                        )}
                      />
                    ))}
                  </MdAccent>
                )}
                {values.templateBranch &&
                  values.templateBranch.metamodelType !== 'TerrestrialVehicle' && (
                    <>
                      <div className={classes.inputGroup}>
                        <OrbitForm
                          entityForm={entityForm}
                          options={options}
                          orbitProps={orbitProps}
                        />
                      </div>
                      <LabeledCheckbox
                        {...getFieldProps('specifyAttitude')}
                        label={'Specify initial attitude'}
                      ></LabeledCheckbox>
                      {values.specifyAttitude && (
                        <div className={classes.inputGroup}>
                          <AttitudeForm entityForm={entityForm} />
                        </div>
                      )}
                    </>
                  )}
                {values.templateBranch &&
                  values.templateBranch.metamodelType === 'TerrestrialVehicle' && (
                    <div className={classes.inputGroup}>
                      <LabeledSelect
                        {...getFieldProps('kinematics.type')}
                        label="Waypoint Type"
                        options={options['kinematics.type']}
                        formikOnChange={(val: IForm['kinematics']['type']) => {
                          if (val && values.kinematics.type && val !== values.kinematics.type) {
                            // When type changes, clear out the previous column
                            setFieldValue(
                              `kinematics.${values.kinematics.type.label.toLowerCase()}`,
                              new Array(values.kinematics.waypoints.length).fill('')
                            );
                            setFieldValue(
                              `kinematics.${val.label.toLowerCase()}`,
                              new Array(values.kinematics.waypoints.length).fill('')
                            );
                          }
                        }}
                        isDisabled={action !== 'create' && action !== 'clone'}
                      />
                      {values.kinematics.type && (
                        <>
                          <h5 style={{ marginBottom: 5 }}>Waypoints</h5>
                          <div style={{ paddingLeft: 0, width: 350 }}>
                            <WaypointInputs formik={formik} waypointListRef={waypointListRef} />
                          </div>
                        </>
                      )}
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
        {!isTemplated && (
          <>
            {values.type === AgentVables.AgentType.PeripheralGroundPoint && (
              <>
                <LabeledSelect
                  label="Choose from NASA's Directory of Stations"
                  options={stationOptions}
                  onChange={(v: TStationOption) => {
                    setFieldValue('lat.deg', v.value.lat);
                    setFieldValue('lon.deg', v.value.lon);
                    setFieldValue('alt.km', v.value.alt);
                  }}
                  optional
                />
                <LabeledInput
                  {...getFieldProps('lat.deg')}
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  label="Latitude (North)"
                />
                <LabeledInput
                  {...getFieldProps('lon.deg')}
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  label="Longitude (East)"
                />
                <LabeledInput
                  {...getFieldProps('alt.km')}
                  type="number"
                  endAdornment={<InputAdornment position="end">km</InputAdornment>}
                  label="Altitude"
                />
              </>
            )}
            {values.type === AgentVables.AgentType.PeripheralSpacePoint && (
              <OrbitForm entityForm={entityForm} options={options} orbitProps={orbitProps} />
            )}
            {values.type === AgentVables.AgentType.PeripheralCelestialPoint && (
              <LabeledSelect
                label="Celestial Bodies"
                options={options.body}
                {...getFieldProps('body')}
              />
            )}
          </>
        )}
      </div>
    </EntityDialog>
  );
};

export default AgentDialog;
