import {
  faChevronDown,
  faChevronRight,
  faEye,
  faEyeSlash,
  faMagnifyingGlassChart,
  faVideo,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, CardHeader, Collapse, IconButton, Slide } from '@material-ui/core';
import StyledButton from 'components/general/StyledButton';
import { useActiveEntities } from 'hooks';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Routes from 'routes';
import useStyles from './styles';

const InfoBox = (props) => {
  const {
    onClose,
    selectedEntity,
    trackedEntity,
    agentData,
    entityFilter,
    setEntityFilter,
    viewer,
  } = props;
  const classes = useStyles();
  const [expandFovs, setExpandFovs] = useState(true);
  const [expandBfVectors, setExpandBfVectors] = useState(true);
  const { branch, model } = useActiveEntities();
  const menu = model.Menu.first();
  const queryParams = useLocation().search;
  const analyzeLink =
    agentData?.agentId &&
    Routes.AGENT_ANALYZE(branch.id, menu.menuItems[0].id) +
      queryParams +
      `&agentId=${agentData.agentId}`;

  return (
    <Slide direction="down" in={selectedEntity !== undefined}>
      <Card className={classes.infoBox}>
        <CardHeader
          className={classes.infoBoxHeader}
          title={
            <div style={{ display: 'flex' }}>
              <StyledButton
                className={classes.viewerButton}
                min
                type="button"
                onClick={() => {
                  trackedEntity === selectedEntity
                    ? (viewer.trackedEntity = undefined)
                    : (viewer.trackedEntity = selectedEntity);
                }}
                off={trackedEntity !== selectedEntity}
                dontDisableInReadOnly
                tooltip="Track Agent"
              >
                <div>
                  <FontAwesomeIcon icon={faVideo} />
                </div>
              </StyledButton>
              {analyzeLink && (
                <StyledButton
                  className={classes.viewerButton}
                  min
                  type="button"
                  to={analyzeLink}
                  onClick={() => {
                    viewer.trackedEntity = selectedEntity;
                  }}
                  disabled={!menu}
                  component={Link}
                  off={true}
                  dontDisableInReadOnly
                  tooltip="Analyze Agent"
                >
                  <div>
                    <FontAwesomeIcon icon={faMagnifyingGlassChart} />
                  </div>
                </StyledButton>
              )}
              <h2
                style={{
                  width: '100%',
                  textAlign: 'center',
                  padding: 4,
                }}
              >
                {analyzeLink ? (
                  <a
                    className={classes.link}
                    onClick={() => {
                      viewer.trackedEntity = selectedEntity;
                    }}
                    href={`/#${analyzeLink}`}
                  >
                    {selectedEntity?.name}
                  </a>
                ) : (
                  selectedEntity?.name
                )}
              </h2>
            </div>
          }
          action={
            <IconButton style={{ margin: 4 }} size="small" onClick={onClose}>
              <FontAwesomeIcon style={{ width: 14, height: 14 }} icon={faXmark} />
            </IconButton>
          }
        />
        {agentData &&
          agentData.type !== 'GroundTarget' &&
          agentData.fovs?.length + agentData.bfVectors?.length > 0 && (
            <CardContent className={classes.infoBoxContent}>
              {agentData.fovs.length > 0 && (
                <div style={{ width: '100%' }}>
                  <div className={classes.listHeader}>
                    <h3>Fields of View</h3>
                    <IconButton size="small" onClick={() => setExpandFovs(!expandFovs)}>
                      <div>
                        <FontAwesomeIcon icon={expandFovs ? faChevronDown : faChevronRight} />
                      </div>
                    </IconButton>
                  </div>
                  <Collapse in={expandFovs}>
                    <div style={{ paddingLeft: 20, width: '100%' }}>
                      {agentData.fovs.map(({ name }) => {
                        return (
                          <div className={classes.listItem} key={`FoV: ${name}`}>
                            <h6>{name}</h6>
                            <StyledButton
                              className={classes.viewerButton}
                              min
                              type="button"
                              onClick={() => {
                                setEntityFilter({
                                  ...entityFilter,
                                  [agentData.name]: {
                                    ...entityFilter[agentData.name],
                                    [name]: !entityFilter[agentData.name][name],
                                  },
                                });
                              }}
                              off={!entityFilter[agentData.name][name]}
                              dontDisableInReadOnly
                              tooltip={
                                entityFilter[agentData.name][name]
                                  ? 'Hide Field of View'
                                  : 'Show Field of View'
                              }
                            >
                              <div>
                                <FontAwesomeIcon
                                  icon={entityFilter[agentData.name][name] ? faEye : faEyeSlash}
                                />
                              </div>
                            </StyledButton>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
              )}

              {agentData.bfVectors.length > 0 && (
                <div style={{ width: '100%', paddingTop: 5 }}>
                  <div className={classes.listHeader}>
                    <h3>Body Frame Vectors</h3>
                    <IconButton size="small" onClick={() => setExpandBfVectors(!expandBfVectors)}>
                      <div>
                        <FontAwesomeIcon icon={expandBfVectors ? faChevronDown : faChevronRight} />
                      </div>
                    </IconButton>
                  </div>
                  <Collapse in={expandBfVectors}>
                    <div style={{ paddingLeft: 20, width: '100%' }}>
                      {agentData.bfVectors.map(({ name }) => {
                        return (
                          <div className={classes.listItem} key={`BF Vector: ${name}`}>
                            <h6>{name}</h6>
                            <StyledButton
                              className={classes.viewerButton}
                              min
                              type="button"
                              onClick={() => {
                                setEntityFilter({
                                  ...entityFilter,
                                  [agentData.name]: {
                                    ...entityFilter[agentData.name],
                                    [name]: !entityFilter[agentData.name][name],
                                  },
                                });
                              }}
                              off={!entityFilter[agentData.name][name]}
                              dontDisableInReadOnly
                              tooltip={
                                entityFilter[agentData.name][name]
                                  ? 'Hide Body Frame Vector'
                                  : 'Show Body Frame Vector'
                              }
                            >
                              <div>
                                <FontAwesomeIcon
                                  icon={entityFilter[agentData.name][name] ? faEye : faEyeSlash}
                                />
                              </div>
                            </StyledButton>
                          </div>
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
              )}
            </CardContent>
          )}
      </Card>
    </Slide>
  );
};

export default InfoBox;
