import { Breadcrumbs, Chip, Typography } from '@material-ui/core';
import StyledCheckbox from 'components/general/inputs/StyledCheckbox';
import useStyles from './styles';

const MenuItemEdit = ({
  selectedMenuItem,
  selectedWidget,
  addWidget,
  deleteWidget,
  setSelectedMenuItem,
  setSelectedWidget,
  getPathToItem,
  setDirtyMenuItems,
}) => {
  const classes = useStyles();

  const playbackWidgets = {
    mode: 'Vehicle Mode',
    conops: 'Condition Compliance',
    'gnc-state': 'GNC State',
    'eps-state': 'Electrical Power State',
    'power-flow': 'Electrical Power Flow',
    'thermal-state': 'Thermal State',
    'thermal-flow': 'Thermal Flow',
  };

  return (
    <div className={classes.widgetView}>
      <Breadcrumbs separator={'/'}>
        {getPathToItem(selectedMenuItem.id)
          ?.slice(1, -1)
          .map((item) => (
            <Typography
              className={classes.breadcrumbText}
              onClick={() => {
                setSelectedMenuItem(item);
              }}
              key={item.id}
            >
              {item.title}
            </Typography>
          ))}
        <div></div>
      </Breadcrumbs>
      <h1>{selectedMenuItem.title}</h1>
      <h3>
        <StyledCheckbox
          onChange={(event) => {
            selectedMenuItem.playback = event.target.checked ? [] : null;
            setDirtyMenuItems((d) => [
              ...d.filter((item) => item.id !== selectedMenuItem.id),
              selectedMenuItem,
            ]);
          }}
          checked={selectedMenuItem.playback !== null}
        />
        Include 3D Geospatial Visualization
      </h3>
      {selectedMenuItem.playback && (
        <div>
          {Object.keys(playbackWidgets).map((item, index) => {
            return (
              <Chip
                className={selectedMenuItem.playback.includes(item) ? classes.widgetChip : ''}
                style={{ margin: 5 }}
                key={index}
                label={playbackWidgets[item]}
                clickable
                variant={selectedMenuItem.playback.includes(item) ? 'default' : 'outlined'}
                onClick={() => {
                  selectedMenuItem.playback.includes(item)
                    ? selectedMenuItem.playback.splice(selectedMenuItem.playback.indexOf(item), 1)
                    : (selectedMenuItem.playback = [
                        ...Object.keys(playbackWidgets).filter(
                          (playbackWidget) =>
                            playbackWidget === item ||
                            selectedMenuItem.playback.includes(playbackWidget)
                        ),
                      ]);
                  setDirtyMenuItems((d) => [
                    ...d.filter((item) => item.id !== selectedMenuItem.id),
                    selectedMenuItem,
                  ]);
                }}
              />
            );
          })}
        </div>
      )}
      <h3>Chart Widgets</h3>
      {selectedMenuItem.widgetSpecs && (
        <>
          {selectedMenuItem.widgetSpecs.map((item, index) => {
            return (
              <Chip
                className={selectedWidget === item ? classes.highlightedChip : classes.widgetChip}
                key={index}
                label={item.name || item.title}
                clickable
                onClick={() => setSelectedWidget(item)}
                onDelete={() => {
                  deleteWidget(item.id);
                }}
              />
            );
          })}
          <Chip
            style={{ margin: 5 }}
            variant="outlined"
            label="Add Widget"
            clickable
            onClick={() => {
              addWidget();
            }}
          />
        </>
      )}
    </div>
  );
};
export default MenuItemEdit;
