import { SHOW_RELEASE_NOTES } from 'config';
import { useUser } from 'hooks';
import { JoyrideContext } from 'providers';
import { ReactNode, useContext, useEffect, useState } from 'react';
import ReleaseDialog from '../dialogs/SupportDialog/ReleaseDialog';
import Wayfinder from './Wayfinder';
import useStyles from './styles';

const ViewContainer = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();
  const user = useUser();
  const [showReleaseDialog, setShowReleaseDialog] = useState(false);
  const joyride = useContext(JoyrideContext);

  useEffect(() => {
    if (user?.id) {
      const userData = JSON.parse(window.localStorage.getItem(user.id) || '{}');
      const lastVersion = userData && userData?.latestVersion;
      if (!lastVersion) {
        joyride.setTour('welcome');
        joyride.setRunning(true);
      }
      setShowReleaseDialog(lastVersion !== SHOW_RELEASE_NOTES);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <Wayfinder />
      <ReleaseDialog
        open={!joyride.running && showReleaseDialog}
        onClose={() => {
          const userData = JSON.parse(window.localStorage.getItem(user.id) || '{}');
          window.localStorage.setItem(
            user.id,
            JSON.stringify({ ...userData, latestVersion: SHOW_RELEASE_NOTES })
          );
          setShowReleaseDialog(false);
        }}
      />
      <div className={classes.body}>{children}</div>
    </div>
  );
};

export default ViewContainer;
