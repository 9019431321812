import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { ISelectOption } from 'components/general/types';
import { ICondition } from 'components/general/types/cdh';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useCallback, useMemo } from 'react';
import {
  createOptionsFromEntities,
  translateIn as defaultTranslateIn,
  translateOut as defaultTranslateOut,
} from 'utils/forms';
import { ConditionVables, FieldOfViewVables, TargetVables } from 'utils/vable';
import * as Yup from 'yup';
import useGuidance from '../guidance';

interface IProps {
  control: TEntityDialogControl<ICondition>;
}

interface IForm {
  name: '';
  fieldOfView: ISelectOption | '';
  targetA: ISelectOption | '';
  relationship: ISelectOption | '';
  scalar: ISelectOption | '';
  terminator: boolean;
}

const defaultValues: IForm = {
  name: '',
  fieldOfView: '',
  targetA: '',
  relationship: '',
  scalar: '',
  terminator: false,
};

const BodyInFovConditionDialog = ({ control }: IProps) => {
  const classes = useStyles();

  const { fieldsOfView, targets } = useActiveEntities();

  const fovList = useMemo(
    () => createOptionsFromEntities(fieldsOfView) as unknown as ISelectOption[],
    [fieldsOfView]
  );
  const targetList = useMemo(
    // Celestial bodies only
    () =>
      createOptionsFromEntities(
        targets.filter((t) => t.type === TargetVables.Type.CelestialTarget.value)
      ) as unknown as ISelectOption[],
    [targets]
  );

  const options = useMemo(
    () => ({
      scalar: FieldOfViewVables.VisibilityTypes.options,
      relationship: ConditionVables.NonRangeRelationshipTypes.options,
      fieldOfView: fovList,
      targetA: targetList,
    }),
    [fovList, targetList]
  );

  const customTranslateIn = useCallback((condition, defaultValues, options) => {
    // Convert scalar number to string so it can be used as a more accessible dropdown
    // e.g. 0.5 -> FieldOfViewVables.VisibilityTypes["0.5"].value
    condition.scalar = condition.scalar?.toString();
    return defaultTranslateIn(condition, defaultValues, options);
  }, []);

  const customTranslateOut = useCallback((refVector, allowedEmptyFields, options) => {
    // Convert scalar dropdown key from string to number, which is its true type
    const result = defaultTranslateOut(refVector, allowedEmptyFields, options);
    result.scalar = Number(result.scalar);
    return result;
  }, []);

  const entityForm = useEntityForm<ICondition, IForm>({
    entityTypeText: 'Body in FOV Condition',
    overrideEntityText: 'Condition',
    entityDialogControl: control,
    defaultValues,
    validationSchema: Yup.object(),
    additionalCreateValues: {
      type: 'BodyInFovCondition',
    },
    formikOptionalParams: {
      useGuidance,
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Name"
            placeholder="Condition Name"
            autoFocus
          />
          <div className={classes.inputGroup}>
            <LabeledSelect
              {...getFieldProps('fieldOfView')}
              label="Field Of View"
              options={options.fieldOfView}
            />
            <LabeledSelect
              {...getFieldProps('targetA')}
              label="Celestial Target"
              options={options.targetA}
            />
            <LabeledSelect
              {...getFieldProps('relationship')}
              label="Visibility is ..."
              options={options.relationship}
            />
            <LabeledSelect {...getFieldProps('scalar')} label="" options={options.scalar} />
          </div>
          <div className={classes.inputGroup}>
            <LabeledCheckbox
              {...getFieldProps('terminate')}
              label={'Terminate simulation when met'}
            />
          </div>
        </div>
      </div>
    </EntityDialog>
  );
};

export default BodyInFovConditionDialog;
