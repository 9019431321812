import { makeStyles } from '@material-ui/core/styles';
import theme, { offWhite } from 'theme';

const miniBarStyles = {
  height: 4,
  top: 0,
};

const sliderTextStyles = {
  fontSize: 12,
  fontWeight: 'normal',
};

const colorTotal = theme.palette.action.disabledBackground;

const useStyles = makeStyles((theme) => ({
  root: {},
  miniBarWrapper: {
    position: 'relative',
    height: 8,
  },
  miniBarTotal: {
    ...miniBarStyles,
    position: 'absolute',
    backgroundColor: colorTotal,
    zIndex: 100,
    width: '100%',
  },
  miniBarProgress: {
    ...miniBarStyles,
    position: 'absolute',
    backgroundColor: theme.palette.action.disabled,
    zIndex: 101,
  },
  miniBarWindow: {
    ...miniBarStyles,
    position: 'absolute',
    backgroundColor: theme.palette.primary.light,
    zIndex: 102,
  },
  dates: {
    fontSize: 11,
    padding: 0,
    margin: 0,
    minHeight: 16,
  },
  jobMenu: {
    margin: '10px 0px',
    display: 'flex',
    '& p': {
      margin: 0,
    },
  },
  jobButton: {
    '& .MuiButtonBase-root': {
      minWidth: '90px',
      padding: '6px 4px',
    },
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  expandBtn: {
    height: 30,
    width: 30,
    color: offWhite(0.8),
  },
  sliderWrapper: {
    position: 'relative',
    marginTop: 50,
    marginLeft: 59,
    marginRight: 59,
  },
  slider: {
    padding: 0,
    height: 20,

    '& .MuiSlider-valueLabel': {
      ...sliderTextStyles,
      top: -20,
      backgroundColor: 'unset',
      color: theme.palette.background.contrastText,
      '&:before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        whiteSpace: 'nowrap',
      },
    },
  },
  sliderTotal: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 3,
    backgroundColor: colorTotal,
  },
  dialogTitle: {
    display: 'flex',
  },
  sampleWrapper: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    '& > p': {
      margin: 0,
    },
    minHeight: 23,
  },
  limitsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > p': {
      ...sliderTextStyles,
      margin: 0,
      marginTop: -8,
    },
  },
  dialogParagraph: {
    marginTop: 5,
  },
  notificationBubble: {
    position: 'absolute',
    top: 3,
    right: 0,
    height: 8,
    width: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.light,
  },
  fetchInputs: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      border: '1px solid ' + theme.palette.background.contrastText,
      borderRadius: '0px 3px 3px 0px',
    },
  },
}));

export default useStyles;
