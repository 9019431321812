import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TreeView } from '@material-ui/lab';
import StyledTreeItem from './StyledTreeItem';

const StyledTreeView = (props) => {
  const {
    menu,
    selected,
    setSelected,
    expanded,
    setExpanded,
    editMenuTitle,
    onAdd,
    onDelete,
    nestItem,
    moveBetween,
    ...remainingProps
  } = props;
  const toTreeItem = (item) => {
    return (
      <StyledTreeItem
        nodeId={item.id}
        selected={selected === item.id}
        item={item}
        editLabel={editMenuTitle}
        key={item.id}
        onAdd={onAdd}
        onDelete={onDelete}
        expanded={expanded}
        setExpanded={setExpanded}
        nestItem={nestItem}
        moveBetween={moveBetween}
      >
        {item.menuItems.map((i) => toTreeItem(i))}
      </StyledTreeItem>
    );
  };

  return (
    <TreeView
      {...remainingProps}
      selected={selected}
      expanded={expanded}
      onNodeToggle={(event, ids) => {
        setExpanded(ids);
      }}
      defaultCollapseIcon={<FontAwesomeIcon icon={faChevronDown} />}
      defaultExpandIcon={<FontAwesomeIcon icon={faChevronRight} />}
    >
      {menu.menuItems.map((item) => toTreeItem(item))}
    </TreeView>
  );
};

export default StyledTreeView;
