import * as Cesium from 'cesium';
import { cross, dot, multiply, norm, subtract, tan } from 'mathjs';
import { useModel } from 'middleware/SatelliteApi/template';
import { makeStream } from 'providers/DataProvider';
import { useMemo } from 'react';
import { deg2Rad } from 'utils/math';
import { a2Period, ae2PerigeeAlt, computeMoonEphemeris } from 'utils/orbit';
import { jd2Mjd, mjd2Moment } from 'utils/time';
import { AgentVables, SurfaceVables, TargetVables } from 'utils/vable';
import {
  cesiumBfVectorLabelOffsetScale,
  cesiumBfVectorScaleDown,
  cesiumBfVectorScaleUp,
  cesiumScaleAltitude,
  cesiumScaleLinearIntercept,
  cesiumScaleLinearSlope,
  perigeeAltScaleCap,
} from './constants';

const INTERPOLATION_OPTIONS = {
  interpolationDegree: 3,
  interpolationAlgorithm: Cesium.HermitePolynomialApproximation,
};

export const useCesiumData = (modelId, perigeeAlt, staticModels, _state, startMjd, stopMjd) => {
  const models = staticModels.agents;
  const scenarioModel = useModel(staticModels.scenario);

  // agents from useActiveEntities may have been updated since the simulation occurred,
  // so the agents list has to come from the simulation itself
  const agents = scenarioModel.Agent.all();

  const [startTime, stopTime] = useMemo(
    () => [
      Cesium.JulianDate.fromIso8601(mjd2Moment(startMjd).format()),
      Cesium.JulianDate.fromIso8601(mjd2Moment(stopMjd).format()),
    ],
    [startMjd, stopMjd]
  );

  const targetsData = useMemo(() => {
    return agents.flatMap((agent) => {
      const isPeripheral = agent.type !== AgentVables.AgentType.TemplatedAgent.value;
      if (models[agent.id] || isPeripheral) {
        if (isPeripheral) {
          if (agent?.type === AgentVables.AgentType.PeripheralGroundPoint.value) {
            const position3D = new Cesium.ConstantPositionProperty(
              Cesium.Cartesian3.fromRadians(
                deg2Rad(agent.lon.deg),
                deg2Rad(agent.lat.deg),
                agent.alt.km * 1000
              ) // ground point position does not need to be interpolated
            );
            let cesiumTarget = {
              lineOfSight: new Cesium.ConstantProperty(false),
              position3D: position3D,
              type: TargetVables.Type.GroundTarget.value,
              name: agent.name,
            };
            return cesiumTarget;
          } else if (agent.type === AgentVables.AgentType.PeripheralSpacePoint.value) {
            const orbit = agent.kinematics;
            const {
              series: [, , orbitalElements],
              transposeSeries,
            } = makeStream(['position', `${orbit.id}.orbitalElements`], _state, agent.id);
            const position3D = new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL);
            position3D.setInterpolationOptions(INTERPOLATION_OPTIONS);
            let cesiumTarget = {
              type: TargetVables.Type.SpaceTarget.value,
              name: agent.name,
              orbitalPeriod: a2Period(orbitalElements.a[0]),
              perigeeAlt: ae2PerigeeAlt(orbitalElements.a[0], orbitalElements.e[0]),
              position3D: position3D,
              lineOfSight: new Cesium.ConstantProperty(false),
            };
            for (const [t, position] of transposeSeries()) {
              const time = Cesium.JulianDate.addSeconds(
                startTime,
                (t - startMjd) * 86400,
                new Cesium.JulianDate()
              );
              let targetEciSatPosition = Cesium.Cartesian3.fromElements(
                position.eci[0] * 1000,
                position.eci[1] * 1000,
                position.eci[2] * 1000
              );
              cesiumTarget.position3D.addSample(time, targetEciSatPosition);
            }
            return cesiumTarget;
          }
        } else {
          const agentRoot = models[agent.id]._root;

          const targetInfo = {};
          const externalInterfaces = models[agent.id]._idsByGroup['ExternalDataInterface'].map(
            (x) => models[agent.id]._blocksById[x]
          );
          externalInterfaces.forEach((x) => {
            const targetBlockIds = x.linkTarget
              ? [
                  models[agent.id]._blocksById[x.linkTarget].agentId ||
                    models[agent.id]._blocksById[x.linkTarget].rel_agentId, // backwards compatible with rel_agentId
                ]
              : x.linkTargetGroup
              ? models[agent.id]._blocksById[x.linkTargetGroup].targets.map(
                  (id) => models[agent.id]._blocksById[id].agentId
                )
              : [];
            targetBlockIds.forEach((agentId) => {
              const targetAgent = scenarioModel.Agent.byId(agentId);
              const isPeripheral = targetAgent.type !== AgentVables.AgentType.TemplatedAgent.value;
              // Ignore receive interfaces that do not connect to peripheral targets
              if (isPeripheral || x.type !== 'ReceiveInterface') {
                agentId in targetInfo
                  ? targetInfo[agentId].externalInterfaces.push(x)
                  : (targetInfo[agentId] = {
                      targetAgent: targetAgent.name,
                      externalInterfaces: [x],
                      canLink: new Cesium.TimeIntervalCollectionProperty(),
                      activeLink: new Cesium.TimeIntervalCollectionProperty(), // 0-inactive; 1-transmitting; 2-receiving; 3-both;
                      linkColor: new Cesium.SampledProperty(Cesium.Color),
                      fadeColor: new Cesium.SampledProperty(Cesium.Color),
                    });
              }
            });
          });

          const bodyFrameVectors = models[agent.id]._idsByGroup['BodyFrameVector'].map(
            (x) => models[agent.id]._blocksById[x]
          );
          const surfaces =
            agentRoot.type === 'TerrestrialVehicle'
              ? []
              : models[agent.id]._idsByGroup['Surface'].map((x) => models[agent.id]._blocksById[x]);
          const fieldsOfView = models[agent.id]._idsByGroup['FieldOfView'].map(
            (x) => models[agent.id]._blocksById[x]
          );

          let moonPosition;
          // For sun-tracking surfaces
          // let eci2BodyQuaternion;
          let eci2BodyRotMat;
          let thisBfVectorOffset;
          let thisSurfaceBfVector;
          let thisSurfaceNormalOrigin;
          let thisSurfaceNormalEndPoint;
          let thisBfVectorScale;
          let thisSurfaceNormal;
          let thisSurfaceLabelPoint;
          let axis;
          let angle;
          let fovQuaternion;
          let rejectH;
          let rejectHDefault;
          let moonEphem;
          let boreAngle;
          let boreCart;
          let boreQuaternion;
          let fovRotMat;
          let heightVec;
          let boreVec;
          let hDefault;
          let hDefaultCart;
          let bfVectors;
          let sunTrackingSurfaces;
          let fovs;

          // Preload necessary non-series props and initialize Sampled Properties
          bfVectors = bodyFrameVectors.map(({ name, unitVector }) => {
            return {
              name,
              unitVector,
            };
          });
          // TODO: condition is "if power", remove this eslint-disable when fixed
          // eslint-disable-next-line no-constant-condition
          if (false /* if power */) {
            const originPoint = new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL);
            const endPoint = new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL);
            const labelPoint = new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL);
            originPoint.setInterpolationOptions(INTERPOLATION_OPTIONS);
            endPoint.setInterpolationOptions(INTERPOLATION_OPTIONS);
            labelPoint.setInterpolationOptions(INTERPOLATION_OPTIONS);
            sunTrackingSurfaces = surfaces
              .filter((surface) => surface.type === SurfaceVables.Type.SunTrackingSurface.value)
              .map(({ name }) => {
                return {
                  name,
                  originPoint: originPoint,
                  endPoint: endPoint,
                  labelPoint: labelPoint,
                };
              });
          }

          fovs = fieldsOfView.map(
            ({
              name,
              halfAngle,
              heightHalfAngle,
              widthHalfAngle,
              boresightBodyFrameVector,
              heightBodyFrameVector,
              type,
            }) => {
              const position3D = new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL);
              const quaternion = new Cesium.SampledProperty(Cesium.Quaternion);
              const radius = new Cesium.SampledProperty(Number);
              position3D.setInterpolationOptions(INTERPOLATION_OPTIONS);
              quaternion.setInterpolationOptions(INTERPOLATION_OPTIONS);
              radius.setInterpolationOptions(INTERPOLATION_OPTIONS);
              return {
                name,
                halfAngle,
                heightHalfAngle,
                widthHalfAngle,
                boresightBodyFrameVector,
                heightBodyFrameVector,
                type,
                position3D: position3D,
                quaternion: quaternion,
                radius: radius,
              };
            }
          );

          moonPosition = new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL); // TODO: interpolate this?

          // Load FoV quaternion data
          const fovAttitudeKeys = [];
          for (const i in fieldsOfView) {
            fovAttitudeKeys.push(fieldsOfView[i].id + '.attitude.body_ecef'); // make array of FoV attitude keys ["<FoV-Block-ID>.attitude.body_ecef", ...]
          }
          let fovQuaternionData;
          try {
            // Backwards compatibility for Fovs without attitude data
            const {
              series: [, ..._fovQuaternionData],
            } = makeStream(fovAttitudeKeys, _state, agent.id); // get FoV attitude quaternion data from keys
            fovQuaternionData = _fovQuaternionData;
          } catch (e) {
            // pass
          }

          const {
            series: [, , , orbitalElements],
            transposeSeries,
          } = makeStream(
            agentRoot.type === 'TerrestrialVehicle'
              ? ['position', `attitude`]
              : ['position', `attitude`, `${agentRoot.missionOrbit}.orbitalElements`],
            _state,
            agent.id
          );
          const position3D =
            agentRoot.type === 'TerrestrialVehicle'
              ? new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.FIXED)
              : new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL);
          const quaternion = new Cesium.SampledProperty(Cesium.Quaternion);
          position3D.setInterpolationOptions(INTERPOLATION_OPTIONS);
          quaternion.setInterpolationOptions(INTERPOLATION_OPTIONS);
          let cesiumTarget =
            agentRoot.type === 'TerrestrialVehicle'
              ? {
                  type: 'TerrestrialTarget',
                  agentId: agent.id,
                  name: agent.name,
                  position3D: position3D,
                  quaternion: quaternion,
                  lineOfSight: new Cesium.ConstantProperty(false),
                  cadSignedUrl: models[agent.id]._root.cadSignedUrl,
                  cadScaleFactor: models[agent.id]._root.cadScaleFactor,
                  isModel: agent.id === modelId, // Used for agent analyze view to avoid rendering duplicate
                  currentWaypoint: new Cesium.TimeIntervalCollectionProperty(),
                  waypoints:
                    models[agent.id]._blocksById[models[agent.id]._idsByGroup['WaypointPath'][0]]
                      .waypoints,
                  targetInfo,
                }
              : {
                  type: TargetVables.Type.SpaceTarget.value,
                  agentId: agent.id,
                  name: agent.name,
                  orbitalPeriod: a2Period(orbitalElements.a[0]),
                  perigeeAlt: ae2PerigeeAlt(orbitalElements.a[0], orbitalElements.e[0]),
                  position3D: position3D,
                  quaternion: quaternion,
                  lineOfSight: new Cesium.ConstantProperty(false),
                  cadSignedUrl: models[agent.id]._root.cadSignedUrl,
                  cadScaleFactor: models[agent.id]._root.cadScaleFactor,
                  isModel: agent.id === modelId, // Used for agent analyze view to avoid rendering duplicate
                  targetInfo,
                };

          let i = 0;
          for (const [t, position, attitude] of transposeSeries()) {
            const time = Cesium.JulianDate.addSeconds(
              startTime,
              (t - startMjd) * 86400,
              new Cesium.JulianDate()
            );

            let targetSatPosition =
              agentRoot.type === 'TerrestrialVehicle'
                ? Cesium.Cartesian3.fromElements(
                    position.ecef[0] * 1000,
                    position.ecef[1] * 1000,
                    position.ecef[2] * 1000
                  )
                : Cesium.Cartesian3.fromElements(
                    position.eci[0] * 1000,
                    position.eci[1] * 1000,
                    position.eci[2] * 1000
                  );
            cesiumTarget.position3D.addSample(time, targetSatPosition);

            let targetEcef2bodyQuaternion = new Cesium.Quaternion(
              attitude.body_ecef[0],
              attitude.body_ecef[1],
              attitude.body_ecef[2],
              attitude.body_ecef[3]
            );

            cesiumTarget.quaternion.addSample(time, targetEcef2bodyQuaternion);

            const ephemeris = computeMoonEphemeris(
              jd2Mjd(time.dayNumber + time.secondsOfDay / 86400)
            );
            moonEphem = new Cesium.Cartesian3.fromElements(
              ephemeris[0] * 1000,
              ephemeris[1] * 1000,
              ephemeris[2] * 1000
            );
            moonPosition.addSample(time, moonEphem);

            let j = 0;
            // Sun-Tracking Vectors
            // eci2BodyQuaternion = new Cesium.Quaternion(
            //   attitude.body_eci[0],
            //   attitude.body_eci[1],
            //   attitude.body_eci[2],
            //   attitude.body_eci[3]
            // );
            // eci2BodyRotMat = new Cesium.Matrix3.fromQuaternion(
            //   eci2BodyQuaternion,
            //   new Cesium.Matrix3()
            // );
            // TODO: condition is "if power", remove this eslint-disable when fixed
            // eslint-disable-next-line no-constant-condition
            if (false /* if power */) {
              j = 0;
              const cappedPerigeeAlt = Math.min(perigeeAlt, perigeeAltScaleCap); // To limit scale in HEO or deep space
              for (let surface of surfaces) {
                if (surface.motionType === SurfaceVables.MotionTypes.SUN_TRACKING.value) {
                  // Get articulation angle
                  let articulationAngle = surface.series.data[i].articulationAngle;

                  // Get reference frame vectors
                  thisSurfaceBfVector = surface.bodyFrameVector;
                  let bfVector = Cesium.Cartesian3.fromArray(thisSurfaceBfVector.unitVector);
                  let normalVector = Cesium.Cartesian3.fromArray(surface.normalVector);

                  // Body frame vector offset
                  thisBfVectorScale =
                    cappedPerigeeAlt <= cesiumScaleAltitude
                      ? 1000 * cesiumBfVectorScaleDown * cappedPerigeeAlt
                      : (cesiumScaleLinearSlope * cappedPerigeeAlt + cesiumScaleLinearIntercept) *
                        cesiumBfVectorScaleUp;
                  thisBfVectorOffset = Cesium.Cartesian3.multiplyByScalar(
                    Cesium.Matrix3.multiplyByVector(
                      eci2BodyRotMat,
                      bfVector,
                      new Cesium.Cartesian3()
                    ),
                    thisBfVectorScale,
                    new Cesium.Cartesian3()
                  );

                  // Origin of normal vector
                  thisSurfaceNormalOrigin = Cesium.Cartesian3.add(
                    targetSatPosition,
                    thisBfVectorOffset,
                    new Cesium.Cartesian3()
                  );

                  // Normal vector in ECI
                  let rotationQuaternion = Cesium.Quaternion.fromAxisAngle(
                    bfVector,
                    articulationAngle,
                    new Cesium.Quaternion()
                  );
                  let rotationMatrix = Cesium.Matrix3.fromQuaternion(
                    rotationQuaternion,
                    new Cesium.Matrix3()
                  );
                  let rotatedNormalVector = Cesium.Matrix3.multiplyByVector(
                    rotationMatrix,
                    normalVector,
                    new Cesium.Cartesian3()
                  );
                  thisSurfaceNormal = Cesium.Cartesian3.multiplyByScalar(
                    Cesium.Matrix3.multiplyByVector(
                      eci2BodyRotMat,
                      rotatedNormalVector,
                      new Cesium.Cartesian3()
                    ),
                    thisBfVectorScale / 3,
                    new Cesium.Cartesian3()
                  );

                  // Endpoint of normal vector
                  thisSurfaceNormalEndPoint = Cesium.Cartesian3.add(
                    thisSurfaceNormalOrigin,
                    thisSurfaceNormal,
                    new Cesium.Cartesian3()
                  );
                  thisSurfaceLabelPoint = Cesium.Cartesian3.add(
                    thisSurfaceNormalOrigin,
                    Cesium.Cartesian3.multiplyByScalar(
                      thisSurfaceNormal,
                      cesiumBfVectorLabelOffsetScale,
                      new Cesium.Cartesian3()
                    ),
                    new Cesium.Cartesian3()
                  );

                  sunTrackingSurfaces[j].originPoint.addSample(time, thisSurfaceNormalOrigin);
                  sunTrackingSurfaces[j].endPoint.addSample(time, thisSurfaceNormalEndPoint);
                  sunTrackingSurfaces[j].labelPoint.addSample(time, thisSurfaceLabelPoint);
                  j++;
                }
              }
            }

            // Data for field of view (FoV) visualization in Cesium
            j = 0;
            for (let fov of fovs) {
              fovs[j].name = fov.name;

              // Get FoV ECEF -> body quaternion from FoV attitude data
              let fovEcef2bodyQuaternion = targetEcef2bodyQuaternion;
              if (fovQuaternionData) {
                fovEcef2bodyQuaternion = new Cesium.Quaternion(
                  fovQuaternionData[j][0][i],
                  fovQuaternionData[j][1][i],
                  fovQuaternionData[j][2][i],
                  fovQuaternionData[j][3][i]
                );
              }

              // Circular cross-section FoVs
              if (fov.type === 'CircularFieldOfView') {
                let vector = models[agent.id]._blocksById[fov.boresightBodyFrameVector].unitVector;

                // Default cone pointing is -z in body frame, so we need to compute rotation to boresight vector
                // using the rotation axis and angle
                if (Math.abs(vector[0]) === 0 && Math.abs(vector[1]) === 0) {
                  if (vector[2] < 0) {
                    fovQuaternion = new Cesium.Quaternion(0, 0, 0, 1);
                  } else {
                    fovQuaternion = new Cesium.Quaternion(0, 1, 0, 0);
                  }
                } else {
                  axis = new Cesium.Cartesian3.fromElements(-vector[1], vector[0], 0);
                  angle = Math.acos(-vector[2]);
                  fovQuaternion = new Cesium.Quaternion.fromAxisAngle(axis, -angle);
                }

                // Add sample to Cesium interpolant sample sets to orient (in ENU) and position (in ECI) the
                // vectors. ECEF-to-body is multiplied by the body to FoV boresight quaternion to orient the
                // FoV.
                fovs[j].quaternion.addSample(
                  time,
                  Cesium.Quaternion.multiply(
                    fovEcef2bodyQuaternion,
                    fovQuaternion,
                    new Cesium.Quaternion()
                  ),
                  new Cesium.Quaternion()
                );
                fovs[j].radius.addSample(time, tan((fov.halfAngle.deg * Math.PI) / 180));

                // Rectangular FoVs
              } else if (fov.type === 'RectangularFieldOfView') {
                heightVec = models[agent.id]._blocksById[fov.heightBodyFrameVector].unitVector;
                boreVec = models[agent.id]._blocksById[fov.boresightBodyFrameVector].unitVector;

                // Default rect pointing is +y in body frame, so we need to compute rotation to boresight vector
                // using the rotation axis and angle
                if (Math.abs(boreVec[0]) === 0 && Math.abs(boreVec[2]) === 0) {
                  if (boreVec[1] < 0) {
                    fovQuaternion = new Cesium.Quaternion(0, 0, 1, 0);
                  } else {
                    fovQuaternion = new Cesium.Quaternion(0, 0, 0, 1);
                  }
                } else {
                  axis = new Cesium.Cartesian3.fromElements(-boreVec[2], 0, boreVec[0]);
                  angle = Math.acos(boreVec[1]);
                  fovQuaternion = new Cesium.Quaternion.fromAxisAngle(axis, -angle);
                }

                // For rectangular FoVs, we have to make a second rotation about the boresight to align the "height"
                // axis of the FoV cross-section with the projection of the height BF vector in the plane perpendicular
                // to the FoV boresight. This is the rejection of the the height BF vector on the boresight vector.
                // default orientation of the height axis is x. We compute the rotation angle about the boresight by
                // computing the angle between the height BF vector projection and the default axis after the the initial
                // boresight alignment rotation. We then use this angle and the boresight BF vector to generate an
                // additional quaternion to fully orient the FoV primitive.
                rejectH = subtract(heightVec, multiply(dot(heightVec, boreVec), boreVec));
                hDefault = [1, 0, 0];
                fovRotMat = new Cesium.Matrix3.fromQuaternion(fovQuaternion, new Cesium.Matrix3());
                hDefaultCart = Cesium.Matrix3.multiplyByVector(
                  fovRotMat,
                  Cesium.Cartesian3.fromArray(hDefault),
                  new Cesium.Cartesian3()
                );
                hDefault = [hDefaultCart['x'], hDefaultCart['y'], hDefaultCart['z']];
                rejectHDefault = subtract(hDefault, multiply(dot(hDefault, boreVec), boreVec));
                let v = dot(rejectH, rejectHDefault) / norm(rejectH) / norm(rejectHDefault);
                if (v > 1) v = 1;
                if (v < -1) v = -1;
                boreAngle = Math.acos(v);
                // Accounting for direction of rotation
                if (dot(cross(rejectHDefault, boreVec), rejectH) > 0) {
                  boreAngle = -boreAngle;
                }

                boreCart = new Cesium.Cartesian3.fromArray(boreVec);
                boreQuaternion = new Cesium.Quaternion.fromAxisAngle(boreCart, boreAngle);

                // Combining rotations from body to fov
                fovQuaternion = Cesium.Quaternion.multiply(
                  boreQuaternion,
                  fovQuaternion,
                  new Cesium.Quaternion()
                );

                // Add sample to Cesium interpolant sample sets to orient (in ENU) and position (in ECI) the
                // vectors. ECEF-to-body is multiplied by the body to FoV boresight quaternion to orient the
                // FoV.
                fovs[j].quaternion.addSample(
                  time,
                  Cesium.Quaternion.multiply(
                    fovEcef2bodyQuaternion,
                    fovQuaternion,
                    new Cesium.Quaternion()
                  ),
                  new Cesium.Quaternion()
                );
              }
              j++;
            }
            i++;
          }

          // Terrestrial Agent Waypoint Information
          if (agentRoot.type === 'TerrestrialVehicle') {
            const {
              series: [timeSeries, legSeries],
            } = makeStream(
              [models[agent.id]._idsByGroup['WaypointPath'][0] + '.leg'],
              _state,
              agent.id
            );
            let prevTime = startTime;
            timeSeries.forEach((t, ind) => {
              const time = Cesium.JulianDate.addSeconds(
                startTime,
                (t - startMjd) * 86400,
                new Cesium.JulianDate()
              );
              cesiumTarget.currentWaypoint.intervals.addInterval(
                new Cesium.TimeInterval({ start: prevTime, stop: time, data: legSeries[ind] })
              );
              prevTime = time;
            });
          }

          // Interface information is stored in a seperate stream
          Object.keys(targetInfo).forEach((targetAgentId) => {
            try {
              const {
                series: [timeSeries, ...values],
              } = makeStream(
                // Add activeLinkTarget for target groups
                targetInfo[targetAgentId].externalInterfaces.flatMap((x) =>
                  x.linkTarget
                    ? [x.id + '.canLink', x.id + '.bitRate']
                    : [x.id + '.activeLinkTarget', x.id + '.canLink', x.id + '.bitRate']
                ),
                _state,
                agent.id
              );
              let prevTime = startTime;
              timeSeries.forEach((t, ind) => {
                const time = Cesium.JulianDate.addSeconds(
                  startTime,
                  (t - startMjd) * 86400,
                  new Cesium.JulianDate()
                );
                let canLink = 0;
                let bitRate = 0;
                let activity = 0;
                let interfaceIndex = 0;
                let valIndex = 0;
                while (valIndex < values.length) {
                  const currentInterface =
                    targetInfo[targetAgentId].externalInterfaces[interfaceIndex];
                  if (currentInterface.linkTarget || values[valIndex][ind] === targetAgentId) {
                    const canLinkIndex = valIndex + (currentInterface.linkTarget ? 0 : 1);
                    canLink = canLink || values[canLinkIndex][ind];
                    bitRate = Math.max(bitRate, values[canLinkIndex + 1][ind]);
                    if (values[canLinkIndex][ind] && values[canLinkIndex + 1][ind] > 0) {
                      if (currentInterface.type === 'ReceiveInterface') {
                        activity === 3 || activity === 1 ? (activity = 3) : (activity = 2);
                      } else {
                        activity >= 2 ? (activity = 3) : (activity = 1);
                      }
                    }
                  }
                  interfaceIndex++;
                  valIndex += currentInterface.linkTarget ? 2 : 3;
                }

                targetInfo[targetAgentId].canLink.intervals.addInterval(
                  new Cesium.TimeInterval({
                    start: prevTime,
                    stop:
                      ind < timeSeries.length - 1
                        ? time
                        : Cesium.JulianDate.addSeconds(stopTime, 10000, new Cesium.JulianDate()), // Push last interval out past stop time to avoid floating point errors causing sampling outside of any interval
                    data: canLink,
                  })
                );

                targetInfo[targetAgentId].activeLink.intervals.addInterval(
                  new Cesium.TimeInterval({
                    start: prevTime,
                    stop:
                      ind < timeSeries.length - 1
                        ? time
                        : Cesium.JulianDate.addSeconds(stopTime, 10000, new Cesium.JulianDate()), // Push last interval out past stop time to avoid floating point errors causing sampling outside of any interval
                    data: activity,
                  })
                );

                if (canLink && bitRate > 0) {
                  targetInfo[targetAgentId].linkColor.addSample(time, Cesium.Color.LAWNGREEN);
                  targetInfo[targetAgentId].fadeColor.addSample(
                    time,
                    Cesium.Color.GREEN.withAlpha(0.25)
                  );
                } else {
                  targetInfo[targetAgentId].linkColor.addSample(time, Cesium.Color.LIGHTGRAY);
                  targetInfo[targetAgentId].fadeColor.addSample(
                    time,
                    Cesium.Color.DARKGRAY.withAlpha(0.25)
                  );
                }
                prevTime = time;
              });
            } catch (e) {
              // REF: canLink backwards compatibility
              // This is to support the visualizations of scenarios that were created before canLink was output
              console.error(e);
              targetInfo[targetAgentId] = null;
              return;
            }
          });

          cesiumTarget.fovs = fovs;
          cesiumTarget.bfVectors = bfVectors;
          cesiumTarget.sunTrackingSurfaces = sunTrackingSurfaces;
          cesiumTarget.moonPosition = moonPosition;

          return cesiumTarget;
        }
      }
      return [];
    });
  }, [
    agents,
    scenarioModel.Agent,
    _state,
    startTime,
    stopTime,
    startMjd,
    models,
    modelId,
    perigeeAlt,
  ]);

  return useMemo(() => {
    return {
      targets: targetsData,
      moonPosition:
        targetsData.length &&
        (targetsData.find((target) => target.isModel)?.moonPosition || targetsData[0].moonPosition), //Use selected model's moon position if it exists, otherwise use first target's moon position
      startTime,
      stopTime,
    };
  }, [targetsData, startTime, stopTime]);
};
