import * as Yup from 'yup';

const pointingModeSchema = Yup.object().shape({
  type: Yup.object().required('Select a pointing mode type.'),

  spinRateBool: Yup.bool(),

  spinRate: Yup.object().when('spinRateBool', {
    is: (spinRateBool) => Boolean(spinRateBool),
    then: Yup.object({
      rpm: Yup.number()
        .required('Spin rate is required')
        .max(
          10,
          'The magnitude of the spin rate must be within 10 RPM for sufficient attitude simulation sampling.'
        )
        .min(
          -10,
          'The magnitude of the spin rate must be within 10 RPM for sufficient attitude simulation sampling.'
        ),
    }),
  }),
});

export default pointingModeSchema;
