import { InputAdornment } from '@material-ui/core';
import { CdhAccent, MdAccent } from 'components/general/Accent/variants';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { TBlockId } from 'components/general/types';
import { IRoutine } from 'components/general/types/cdh';
import { IComponent, ILoad, ILoadState } from 'components/general/types/power';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityDialogControl, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { translateOut } from 'utils/forms';
import * as Yup from 'yup';
import LoadDialog from './LoadDialog';
import { useGuidance } from './guidance';

const validation = Yup.object().shape({
  efficiency: Yup.number()
    .required('A load state efficiency is required')
    .min(0, 'Load state efficiency must be greater than or equal to 0%')
    .max(100, 'Load state efficiency must be less than or equal to 100%'),
});

const formatLoadDefParams = (rowData: ILoad) =>
  'power' in rowData.loadDefParams
    ? `${rowData.loadDefParams.power} W`
    : `${rowData.loadDefParams.resistance} Ω`;

const loadsColumns = [
  { title: 'Name', field: 'name' },
  {
    render: (rowData: ILoad) => formatLoadDefParams(rowData),
  },
];

const routineTableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

// routines is an optional field as it is not needed on create but will be set on edit
interface IForm {
  name: string;
  routines?: IRoutine[];
}

const defaultValues = {
  name: '',
  efficiency: 0,
};

interface IParsedRoutine {
  id: TBlockId;
  name: string;
  tableData: {
    checked: boolean;
  };
}

interface IProps {
  control: TEntityDialogControl<ILoadState>;
  component: IComponent;
}
const LoadStateDialog = (props: IProps) => {
  // Handle props
  const { control, component } = props;
  const { dialogConfig } = control;
  const { action } = dialogConfig;
  const { entity: loadState } = dialogConfig;

  // Grab entities and actions
  const associatedLoads = useMemo(() => (loadState ? loadState.loads : []), [loadState]);
  const siblingStates = component.loadStates;

  // Set up table data
  const tableRef = useRef(null);
  const { leafRoutines: routines } = useActiveEntities();
  const [parsedRoutines, setParsedRoutines] = useState<IParsedRoutine[]>([]);

  // Finds routines not connected to component's other load states to ensure that an routine can't be attached to more than one load state per component
  const attachableRoutines = useMemo(() => {
    let _routines = routines;
    for (const siblingState of siblingStates) {
      if (siblingState.id !== loadState?.id)
        _routines = _routines.filter(
          (r) => !siblingState.routines.map((routine: IRoutine) => routine.id).includes(r.id)
        );
    }
    return _routines;
    // putting component.loadStates in dep array instead of siblingStates because siblingStates's useSelector leads to unnecessary recreation despite load states not changing
  }, [component.loadStates, loadState, routines]); //eslint-disable-line react-hooks/exhaustive-deps

  // Due to the load state dialog loading the loads as a prop, whenever a load is changed the load state dialog will also rerender
  // During this rerender we want to ensure we are keeping the routines that have been selected by the user
  // So a boolean switch is used to determine if it is the first render and routines should be filtered over
  // This gives the desired behaviour of initializing the routines on load and reset, while maintaining selection on load changes
  const initRoutines = useCallback(
    (inUseEffect = false) => {
      setParsedRoutines((prev) => {
        if (inUseEffect && (!routines || prev.length)) return prev;
        return attachableRoutines.map((o) => ({
          id: o.id,
          name: o.name,
          tableData: {
            checked: !!loadState?.routines.map((routine: IRoutine) => routine.id).includes(o.id),
          },
        }));
      });
    },
    [loadState, routines, setParsedRoutines, attachableRoutines]
  );

  useEffect(() => {
    initRoutines(true);
  }, [initRoutines]);

  // Set up styles
  const classes = useStyles();

  // Set up config for loads dialog
  const loadsDialogControl = useEntityDialogControl<ILoad>();
  const { openDialogForExisting, openDialogForNew } = loadsDialogControl;

  // Custom translateOut for edit action only as create does not require loads or routines
  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options, datetimes, percentages) => {
      if (action === 'edit') {
        values.routines = parsedRoutines
          .filter((o: IParsedRoutine) => o.tableData.checked)
          .map((o) => o.id);
      }
      return translateOut(values, allowedEmptyFields, options, datetimes, percentages);
    },
    [parsedRoutines, action]
  );

  const entityForm = useEntityForm<ILoadState, IForm>({
    entityTypeText: 'Load State',
    entityDialogControl: control,
    defaultValues,
    additionalCreateValues: { component: component.id, type: 'LoadState' },
    validationSchema: validation,
    extendReset: initRoutines,
    editAfterCreate: true,
    formikOptionalParams: {
      translateOut: customTranslateOut,
      percentages: ['efficiency'],
      useGuidance,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, setFieldValue } = formik;

  return (
    <>
      <EntityDialog entityForm={entityForm} keepSaveEnabled>
        <div className={classes.inputs}>
          <div className={classes.inputGroup}>
            <LabeledInput
              {...getFieldProps('name')}
              label="Load State Name"
              type="text"
              placeholder="Load State Name"
              autoFocus
            />
          </div>
        </div>
        {action === 'create' ? (
          <p>Create a load state to associate loads to operational modes. </p>
        ) : (
          <>
            <div className={classes.inputGroup}>
              <MdAccent header="Loads">
                <WidgetTable
                  columns={loadsColumns}
                  data={associatedLoads}
                  modelName="load"
                  onActionClick={openDialogForExisting}
                  onFabClick={openDialogForNew}
                />
              </MdAccent>
            </div>
            <div className={classes.inputGroup}>
              <LabeledInput
                {...getFieldProps('efficiency')}
                label="Efficiency"
                type="number"
                placeholder="Efficiency"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div>
            <div className={classes.inputGroup}>
              <CdhAccent header="Operational Modes">
                <WidgetTable
                  tableRef={tableRef}
                  className={classes.table}
                  columns={routineTableColumns}
                  data={parsedRoutines}
                  setData={setParsedRoutines}
                  emptyMessage="No unassociated operational modes"
                  // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
                  // It is used through a level of indirection here to set the value on the form data and therefore mark
                  // the form as dirty. This will enable the save button for the form.
                  onSelectionChange={(newTableData) => setFieldValue('routines', newTableData)}
                  selection={true}
                  title="Select Operational Modes"
                  search={true}
                />
              </CdhAccent>
            </div>
          </>
        )}
      </EntityDialog>
      {/* Similar to load state dialog, only load the dialog once the config is opened to avoid many dialogs being rendered */}
      {loadsDialogControl.dialogConfig.open && (
        <LoadDialog loadState={loadState} control={loadsDialogControl} />
      )}
    </>
  );
};

export default LoadStateDialog;
