import { IMenu } from 'components/general/types';
import { ITEMS, SUB_ITEMS, TITLE } from 'config';
import Routes, { routePathsCommon } from 'routes';

const { ANALYZE, PLAYBACK } = routePathsCommon;

const AGENTS = 'agents';

const ScenarioAnalyzeMenu: IMenu = {
  routeFn: Routes.SCENARIO,
  dynamicItems: [],
  [ITEMS]: {
    [ANALYZE]: {
      [TITLE]: 'Analyze',
      [SUB_ITEMS]: {
        [PLAYBACK]: {
          [TITLE]: 'Playback',
        },
        [AGENTS]: { [TITLE]: 'Agents' },
      },
    },
  },
};

export const wGroupIndicesScenario = {
  ANALYZE: `${ANALYZE}*`,
  ANALYZE_PLAYBACK: `${ANALYZE}.${PLAYBACK}`,
  ANALYZE_AGENTS: `${ANALYZE}.${AGENTS}`,
};

export default ScenarioAnalyzeMenu;
