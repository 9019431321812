import AgentAnalyzeMenu from 'components/AgentAnalyzeView/menu';
import AgentTemplateEditMenu from 'components/AgentTemplateEditView/menu';
import WorkspaceMenu from 'components/RootView/menu';
import ScenarioAnalyzeMenu from 'components/ScenarioView/ScenarioAnalyzeView/menu';
import ScenarioEditMenu from 'components/ScenarioView/ScenarioEditView/menu';
import { IGenericObject, IMenu } from 'components/general/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { routePathsCommon } from 'routes';
import missionDesignerMenu from './missionDesignerMenu';

const { MISSION_DESIGNER, WORKSPACE, AGENT_TEMPLATE_EDIT, AGENT_ANALYZE, SCENARIO, EDIT, ANALYZE } =
  routePathsCommon;

const menus: {
  [key: string]: IMenu | ((activeEntities?: IGenericObject) => IMenu);
} = {
  [MISSION_DESIGNER]: missionDesignerMenu,
  // TODO ZACH: remove MISSION_DESIGNER and ENERGY_BALANCE
  [AGENT_TEMPLATE_EDIT]: AgentTemplateEditMenu,
  [AGENT_ANALYZE]: (activeEntities) => AgentAnalyzeMenu(activeEntities).menu,
  [SCENARIO + EDIT]: ScenarioEditMenu,
  [SCENARIO + ANALYZE]: ScenarioAnalyzeMenu,
  [WORKSPACE]: WorkspaceMenu,
};

const useMenu = (activeEntities?: IGenericObject) => {
  const location = useLocation();

  // `menuKey` = 'scenario', 'agent-template-edit' ...etc.,
  // otherwise ''
  const menuKey = location.pathname.split('/')[1] || '';

  // menuActionKey = 'edit' or 'analyze' otherwise ''
  // Determines if we use the ScenarioEditMenu or ScenarioAnalyzeMenu based on the url moduleAction
  // Restricted to edit/analyze to avoid unnecessary reseting of the menu in other views
  const menuActionKey =
    location.pathname.split('/')[3] === routePathsCommon.EDIT ||
    location.pathname.split('/')[3] === routePathsCommon.ANALYZE
      ? location.pathname.split('/')[3]
      : '';

  // menu will either be the respective menu if there is one, otherwise `null`
  const result = useMemo(() => {
    const menu = menus[menuKey + menuActionKey] || menus[menuKey] || null;

    // Menu can be the raw object, or a function that takes in current active entities and returns the object
    return typeof menu === 'function' ? menu(activeEntities) : menu;
  }, [menuKey, menuActionKey, activeEntities?.branch?.id]); // eslint-disable-line react-hooks/exhaustive-deps
  return result;
};

export default useMenu;
