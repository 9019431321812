import { ISelectOption } from 'components/general/types';

type TVable<T extends string> = {
  _keys: T[];
  readonly options: ISelectOption[];
} & Record<T, { label: string; value: T }>;

function createVable<T extends string>(obj: { [Properties in T]: string }): TVable<T> {
  const vable: TVable<T> = {
    _keys: [] as T[],
    get options() {
      return this._keys.map((k: T) => this[k]);
    },
  } as TVable<T>;

  for (const k in obj) {
    //@ts-ignore // wonkiness with Record
    vable[k as T] = {
      label: obj[k],
      value: k,
    };
    vable._keys.push(k);
  }

  return vable;
}

/*============================================================================*/
// VABLE DOCUMENTATION
/*============================================================================*/
/*
**What is it?**
- "value" + "label" = "vable"

**Purpose**
- Render user friendly strings based on CAPITAL_CASE keys:

  `HardwareVables.SubsystemCategories['CDH'].label` --> 'Command & Data Handling'

  Note: typically a variable would be passed in where 'CDH' is. This just shows one case where
  the variable would resolve to 'CDH'.

- Auto create value/label objects for use in forms

  `HardwareVables.SubsystemCategories.options` -->

    [
      { value: 'POWER', label: 'Power' },
      { value: 'CDH', label: 'Command & Data Handling' },
      { value: 'COMMS', label: 'Communications' },
      { value: 'GNC', label: 'Guidance, Navigation, and Control' },
      { value: 'THERMAL', label: 'Thermal' },
      { value: 'CUSTOM', label: 'Custom Subsystem' },
    ]

- Avoid errors in comparing strings:

    if (paramACategory.value === 'POWER') {...}
    vs.
    if (paramACategory.value === HardwareVables.SubsystemCategories.POWER.value) {...}

**Adding new vables**
- Add them below in alphabetical order with commented headings

*/

/*============================================================================*/
// Actuator
/*============================================================================*/
export type TActuatorTypes =
  | 'Magnetorquer'
  | 'ReactionWheel'
  | 'Thruster'
  | 'PermanentDipoleMagnet'
  | 'MagneticHysteresisRod';

const ActuatorType = createVable<TActuatorTypes>({
  Magnetorquer: 'Magnetorquer',
  ReactionWheel: 'Reaction Wheel',
  Thruster: 'Thruster',
  PermanentDipoleMagnet: 'Permanent Dipole Magnet',
  MagneticHysteresisRod: 'Magnetic Hysteresis Rod',
});

export type TMagnetorquerModulationTypes = 'ANALOG' | 'DIGITAL';

const MagnetorquerModulationType = createVable<TMagnetorquerModulationTypes>({
  ANALOG: 'Analog',
  DIGITAL: 'Digital',
});

export const ActuatorVables = {
  Type: ActuatorType,
  MagnetorquerModulationType,
};

/*============================================================================*/
// Agent
/*============================================================================*/
export type TAgentType = 'TemplatedAgent' | TPeripheralAgentType;
export type TPeripheralAgentType =
  | 'PeripheralGroundPoint'
  | 'PeripheralGroundArea'
  | 'PeripheralSpacePoint'
  | 'PeripheralCelestialPoint';
export type TWaypointType =
  | 'WaypointPathWithDuration'
  | 'WaypointPathWithSpeed'
  | 'WaypointPathWithTimestamps';

const AgentType = createVable<TAgentType>({
  TemplatedAgent: 'Templated Agent',
  PeripheralGroundPoint: 'Peripheral Ground Point',
  PeripheralGroundArea: 'Peripheral Ground Area',
  PeripheralSpacePoint: 'Peripheral Space Point',
  PeripheralCelestialPoint: 'Peripheral Celestial Point',
});

const WaypointType = createVable<TWaypointType>({
  WaypointPathWithDuration: 'Durations',
  WaypointPathWithSpeed: 'Speeds',
  WaypointPathWithTimestamps: 'Timestamps',
});

export const AgentVables = {
  AgentType,
  WaypointType,
};

/*============================================================================*/
// Algorithm
/*============================================================================*/
export type TAlgorithmTypes =
  | 'AveragingAlgorithm'
  | 'DirectMeasurementAttitudeInitializer'
  | 'EkfAlgorithm'
  | 'FiniteDifferenceOrbitInitializer'
  | 'GenericAdAlgorithm'
  | 'GenericOdAlgorithm'
  | 'GpsAlgorithm'
  | 'MekfAlgorithm'
  | 'PidAlgorithm'
  | 'StaticThrustControlAlgorithm'
  | 'RelativePointPidThrustControlAlgorithm'
  | 'RelativePointSlidingModeThrustControlAlgorithm'
  | 'PulseRelativeSmTca'
  | 'PulseRelativePidTca'
  | 'SlidingModeAlgorithm'
  | 'StaticAttitudeInitializer'
  | 'StaticThrustControlAlgorithm'
  | 'TriadAlgorithm'
  | 'TriadAttitudeInitializer';

export type TAlgorithmSuper =
  | 'AttitudeControlAlgorithm'
  | 'AttitudeDeterminationAlgorithm'
  | 'AttitudeInitializer'
  | 'FiniteDifferenceOrbitInitializer'
  | 'OrbitDeterminationAlgorithm'
  | 'OrbitInitializer'
  | 'PeriodicAlgorithm'
  | 'SporadicAlgorithm'
  | 'ThrustControlAlgorithm';

const AlgorithmType = createVable<TAlgorithmTypes>({
  AveragingAlgorithm: 'Averaging',
  DirectMeasurementAttitudeInitializer: 'Direct Measurement Attitude Initializer',
  EkfAlgorithm: 'EKF',
  FiniteDifferenceOrbitInitializer: 'Finite Difference Orbit Initializer',
  GenericAdAlgorithm: 'Generic Attitude Determination',
  GenericOdAlgorithm: 'Generic Orbit Determination',
  GpsAlgorithm: 'GPS Direct',
  MekfAlgorithm: 'MEKF',
  PidAlgorithm: 'PID',
  SlidingModeAlgorithm: 'Sliding Mode',
  StaticAttitudeInitializer: 'Static Attitude Initializer',
  StaticThrustControlAlgorithm: 'Static Thrust Control',
  RelativePointPidThrustControlAlgorithm: 'Relative Point PID Thrust Control Algorithm',
  RelativePointSlidingModeThrustControlAlgorithm:
    'Relative Point Sliding Mode Thrust Control Algorithm',
  PulseRelativeSmTca: 'Pulse Relative Sliding Mode Thrust Control Algorithm',
  PulseRelativePidTca: 'Pulse Relative PID Thrust Control Algorithm',
  TriadAlgorithm: 'Triad',
  TriadAttitudeInitializer: 'Triad Attitude Initializer',
});

const AlgorithmSuper = createVable<TAlgorithmSuper>({
  AttitudeControlAlgorithm: 'Attitude Control Algorithm',
  AttitudeDeterminationAlgorithm: 'Attitude Determination Algorithm',
  AttitudeInitializer: 'Attitude Initializer',
  FiniteDifferenceOrbitInitializer: 'Finite Difference Orbit Initializer',
  OrbitDeterminationAlgorithm: 'Orbit Determination Algorithm',
  OrbitInitializer: 'Orbit Initializer',
  PeriodicAlgorithm: 'Periodic Algorithm',
  SporadicAlgorithm: 'Sporadic Algorithm',
  ThrustControlAlgorithm: 'Thrust Control Algorithm',
});

export const AlgorithmVables = {
  Super: AlgorithmSuper,
  Type: AlgorithmType,
};

const is = (algo: { type: string | ISelectOption }, value: string) => {
  if (typeof algo.type === 'string') return algo.type === value;
  return algo.type.value === value;
};
export const isAc = (algo: { type: string | ISelectOption }) =>
  is(algo, AlgorithmVables.Type.SlidingModeAlgorithm.value) ||
  is(algo, AlgorithmVables.Type.PidAlgorithm.value);
export const isOd = (algo: { type: string | ISelectOption }) =>
  is(algo, AlgorithmVables.Type.EkfAlgorithm.value) ||
  is(algo, AlgorithmVables.Type.GpsAlgorithm.value) ||
  is(algo, AlgorithmVables.Type.GenericOdAlgorithm.value) ||
  is(algo, AlgorithmVables.Type.FiniteDifferenceOrbitInitializer.value);
export const isAd = (algo: { type: string | ISelectOption }) =>
  is(algo, AlgorithmVables.Type.TriadAlgorithm.value) ||
  is(algo, AlgorithmVables.Type.AveragingAlgorithm.value) ||
  is(algo, AlgorithmVables.Type.MekfAlgorithm.value) ||
  is(algo, AlgorithmVables.Type.GenericAdAlgorithm.value) ||
  is(algo, AlgorithmVables.Type.DirectMeasurementAttitudeInitializer.value) ||
  is(algo, AlgorithmVables.Type.StaticAttitudeInitializer.value) ||
  is(algo, AlgorithmVables.Type.TriadAttitudeInitializer.value);
export const isTc = (algo: { type: string | ISelectOption }) =>
  is(algo, AlgorithmVables.Type.StaticThrustControlAlgorithm.value);

/*============================================================================*/
// Battery
/*============================================================================*/
export type TConfigurationTypesKeys = 'SERIES' | 'PARALLEL';

const ConfigurationTypes = createVable<TConfigurationTypesKeys>({
  SERIES: 'Series',
  PARALLEL: 'Parallel',
});

export const BatteryVables = {
  ConfigurationTypes,
};

/*============================================================================*/
// Body Frame Vector
/*============================================================================*/
export type TBodyFrameVectorTypesKeys = 'SPHERICAL_ANGLES' | 'VECTOR';

const BodyFrameVectorTypes = createVable<TBodyFrameVectorTypesKeys>({
  SPHERICAL_ANGLES: 'Spherical Angles',
  VECTOR: 'Vector',
});

export const BodyFrameVectorVables = {
  BodyFrameVectorTypes,
};

/*============================================================================*/
// Bus Regulator
/*============================================================================*/
export type TInputTypesKeys = 'EPS_ROOT_NODE' | 'BUS_REGULATOR';

const InputTypes = createVable<TInputTypesKeys>({
  EPS_ROOT_NODE: 'EPS Root Node Output',
  BUS_REGULATOR: 'Bus Regulator Output',
});

export const BusRegulatorVables = {
  InputTypes,
};

/*============================================================================*/
// Component
/*============================================================================*/
// TODO: Should we get rid of the component `vable`? Either that or we should make `vable`s work with inheritance
// somehow. We could do it in a hacky way by just making `type` that combines `type`s of all components and spreads
// together the objects passed to all component `vable`s to make a Component `type` and `vable`. As it stands, this
// `vable` duplicates values from lots of other ones.
export type TComponentTypes =
  | 'Component'
  | 'BatteryPack'
  | 'SolarPanel'
  | 'PowerProcessor'
  | 'Cooler'
  | 'Heater'
  | 'ReactionWheel'
  | 'Magnetorquer'
  | 'Thruster'
  | 'DirectionSensor'
  | 'PositionSensor'
  | 'OpticalAttitudeSensor'
  | 'AngularVelocitySensor'
  | 'VectorSensor'
  | 'TargetAttitudeSensor'
  | 'TargetPositionSensor'
  | 'TargetRangeSensor'
  | 'TargetRangeRateSensor'
  | 'SingleConvMpptPowerProcessor'
  | 'TwoConvMpptPowerProcessor'
  | 'QuasiRegDetPowerProcessor'
  | 'SingleConvHybridPowerProcessor'
  | 'FullyRegDetPowerProcessor'
  | 'PermanentDipoleMagnet'
  | 'MagneticHysteresisRod';

const ComponentType = createVable<TComponentTypes>({
  Component: 'Component',
  BatteryPack: 'Battery Pack',
  SolarPanel: 'Solar Panel',
  PowerProcessor: 'Power Processor',
  ReactionWheel: 'Reaction Wheel',
  Magnetorquer: 'Magnetorquer',
  DirectionSensor: 'Direction Sensor',
  PositionSensor: 'Position Sensor',
  OpticalAttitudeSensor: 'Optical Attitude Sensor',
  AngularVelocitySensor: 'Angular Velocity Sensor',
  TargetAttitudeSensor: 'Target Attitude Sensor',
  TargetPositionSensor: 'Target Position Sensor',
  TargetRangeSensor: 'Target Range Sensor',
  TargetRangeRateSensor: 'Target Range Rate Sensor',
  Heater: 'Heater',
  Cooler: 'Cooler',
  VectorSensor: 'Vector Sensor',
  Thruster: 'Thruster',
  SingleConvMpptPowerProcessor: 'Single Converter Maximum Peak Power Point Tracking',
  TwoConvMpptPowerProcessor: 'Two Converter Maximum Peak Power Point Tracking',
  QuasiRegDetPowerProcessor: 'Quasi-Regulated Direct Energy Transfer',
  SingleConvHybridPowerProcessor: 'Single Converter Hybrid',
  FullyRegDetPowerProcessor: 'Fully-Regulated Direct Energy Transfer',
  PermanentDipoleMagnet: 'Permanent Dipole Magnet',
  MagneticHysteresisRod: 'Magnetic Hysteresis Rod',
});

export const ComponentVables = {
  Type: ComponentType,
};

/*============================================================================*/
// Condition
/*============================================================================*/
export type TConditionRelationship = TNonRangeRelationship | TRangeRelationship;

const ConditionRelationshipTypes = createVable<TConditionRelationship>({
  GREATER: 'Greater Than ( > )',
  LESS: 'Less Than ( < )',
  GREATER_EQUAL: 'Greater Than or Equal to ( >= )',
  LESS_EQUAL: 'Less Than or Equal to ( <= )',
  EQUAL: 'Equal to ( == )',
  NOT_EQUAL: 'Not Equal to ( != )',
  IN_INCLUSIVE_RANGE: 'Within range (inclusive)',
  IN_EXCLUSIVE_RANGE: 'Within range (exclusive)',
  NOT_IN_INCLUSIVE_RANGE: 'Outside range (inclusive)',
  NOT_IN_EXCLUSIVE_RANGE: 'Outside range (exclusive)',
});

export type TRestrictedRelationship = 'EQUAL' | 'NOT_EQUAL';

const RestrictedRelationshipTypes = createVable<TRestrictedRelationship>({
  EQUAL: 'Equal to ( == )',
  NOT_EQUAL: 'Not Equal to ( != )',
});

export type TNonRangeRelationship =
  | 'GREATER'
  | 'LESS'
  | 'GREATER_EQUAL'
  | 'LESS_EQUAL'
  | TRestrictedRelationship;

const NonRangeRelationshipTypes = createVable<TNonRangeRelationship>({
  GREATER: 'Greater Than ( > )',
  LESS: 'Less Than ( < )',
  GREATER_EQUAL: 'Greater Than or Equal to ( >= )',
  LESS_EQUAL: 'Less Than or Equal to ( <= )',
  EQUAL: 'Equal to ( == )',
  NOT_EQUAL: 'Not Equal to ( != )',
});

export type TRangeRelationship =
  | 'IN_INCLUSIVE_RANGE'
  | 'IN_EXCLUSIVE_RANGE'
  | 'NOT_IN_INCLUSIVE_RANGE'
  | 'NOT_IN_EXCLUSIVE_RANGE';

const RangeRelationshipTypes = createVable<TRangeRelationship>({
  IN_INCLUSIVE_RANGE: 'Within range (inclusive)',
  IN_EXCLUSIVE_RANGE: 'Within range (exclusive)',
  NOT_IN_INCLUSIVE_RANGE: 'Outside range (inclusive)',
  NOT_IN_EXCLUSIVE_RANGE: 'Outside range (exclusive)',
});

export type TGroupRoller =
  | 'ANY'
  | 'ALL'
  | 'MAX'
  | 'MIN'
  | 'MEAN'
  | 'MEDIAN'
  | 'COUNT'
  | 'NOT_ANY'
  | 'NOT_ALL';

const GroupRollerTypes = createVable<TGroupRoller>({
  ANY: 'any',
  ALL: 'all',
  MAX: 'max',
  MIN: 'min',
  MEAN: 'mean',
  MEDIAN: 'median',
  COUNT: 'count',
  NOT_ANY: 'not any',
  NOT_ALL: 'not all',
});

export type TConditionType =
  | 'TimeCondition'
  | 'ElapsedTimeCondition'
  | 'SatelliteToScalarCondition'
  | 'TargetToScalarCondition'
  | 'TargetGroupToScalarCondition'
  | 'SatelliteToSatelliteCondition'
  | 'SatelliteToTargetCondition'
  | 'TargetToTargetCondition'
  | 'TargetGroupToTargetCondition'
  | 'TargetGroupToSatelliteCondition'
  | 'TargetInFovCondition'
  | 'VectorInFovCondition'
  | 'BodyInFovCondition'
  | 'TargetGroupInFovCondition'
  | 'CompoundCondition'
  | 'CountCondition'
  | 'SameTargetMultiCondition';

const ConditionTypes = createVable<TConditionType>({
  TimeCondition: 'TimeCondition',
  ElapsedTimeCondition: 'ElapsedTimeCondition',
  SatelliteToScalarCondition: 'SatelliteToScalarCondition',
  TargetToScalarCondition: 'TargetToScalarCondition',
  TargetGroupToScalarCondition: 'TargetGroupToScalarCondition',
  SatelliteToSatelliteCondition: 'SatelliteToSatelliteCondition',
  SatelliteToTargetCondition: 'SatelliteToTargetCondition',
  TargetToTargetCondition: 'TargetToTargetCondition',
  TargetGroupToTargetCondition: 'TargetGroupToTargetCondition',
  TargetGroupToSatelliteCondition: 'TargetGroupToSatelliteCondition',
  TargetInFovCondition: 'TargetInFovCondition',
  VectorInFovCondition: 'VectorInFovCondition',
  BodyInFovCondition: 'BodyInFovCondition',
  TargetGroupInFovCondition: 'TargetGroupInFovCondition',
  CompoundCondition: 'CompoundCondition',
  CountCondition: 'CountCondition',
  SameTargetMultiCondition: 'SameTargetMultiCondition',
});

export type TParameter = 'TIME' | 'ELAPSED_TIME' | 'SCALAR' | 'SATELLITE' | 'TARGET';

const ParamCategories = createVable<TParameter>({
  TIME: 'TIME',
  ELAPSED_TIME: 'ELAPSED_TIME',
  SCALAR: 'SCALAR',
  SATELLITE: 'SATELLITE',
  TARGET: 'TARGET',
});

export type TTopType = 'COMPARE' | 'FOV' | 'MULTI' | 'SAME';

const TopLevelConditionTypes = createVable<TTopType>({
  COMPARE: 'COMPARE',
  FOV: 'FOV',
  MULTI: 'MULTI',
  SAME: 'SAME',
});

// TEMPORARY: While we're experimenting with condition dialog
export type TNewConditionType = 'BodyInFovCondition';

const NewConditionTypes = createVable<TNewConditionType>({
  BodyInFovCondition: 'BodyInFovCondition',
});

export const ConditionVables = {
  ConditionRelationshipTypes,
  RestrictedRelationshipTypes,
  NonRangeRelationshipTypes,
  RangeRelationshipTypes,
  GroupRollerTypes,
  Type: ConditionTypes,
  ParamCategories,
  TopLevelConditionTypes,
  NewConditionTypes,
};

/*============================================================================*/
// Data Handling Device
/*============================================================================*/
export type TDataHandlingDevice = 'Modem' | 'Antenna' | 'LaserCommModule';

const DataHandlingDeviceTypes = createVable<TDataHandlingDevice>({
  Modem: 'Modem',
  Antenna: 'Antenna',
  LaserCommModule: 'Laser Comm Module',
});

export const DataHandlingDeviceVables = {
  Type: DataHandlingDeviceTypes,
};

/*============================================================================*/
// Data Interface
/*============================================================================*/
export type TDataInterfaceTypes =
  | 'InternalDataInterface'
  | 'PassiveTransmitInterface'
  | 'CooperativeTransmitInterface'
  | 'ReceiveInterface';
const DataInterfaceTypes = createVable<TDataInterfaceTypes>({
  InternalDataInterface: 'Internal Data Interface',
  PassiveTransmitInterface: 'Passive Transmit Interface',
  CooperativeTransmitInterface: 'Cooperative Transmit Interface',
  ReceiveInterface: 'Receive Interface',
});

export const DataInterfaceVables = {
  InterfaceType: DataInterfaceTypes,
};

/*============================================================================*/
// Directed Energy Device
/*============================================================================*/
export type TDirectedEnergyDeviceTypes = 'LaserCommModule' | 'Antenna';
const DirectedEnergyDeviceTypes = createVable<TDirectedEnergyDeviceTypes>({
  LaserCommModule: 'Laser Comm Module',
  Antenna: 'Antenna',
});

export const DirectedEnergyDeviceVables = {
  Type: DirectedEnergyDeviceTypes,
};

/*============================================================================*/
// Field of View
/*============================================================================*/
export type TFieldOfViewTypes = 'CircularFieldOfView' | 'RectangularFieldOfView';

const FieldOfViewTypes = createVable<TFieldOfViewTypes>({
  CircularFieldOfView: 'Circular',
  RectangularFieldOfView: 'Rectangular',
});

const VisibilityTypes = createVable<string>({
  0: 'None',
  0.5: 'Partial',
  1: 'Full',
});

export const FieldOfViewVables = {
  Type: FieldOfViewTypes,
  VisibilityTypes,
};

/*============================================================================*/
// Fuel Tank
/*============================================================================*/
export type TFuelTankTypes = 'SphericalFuelTank' | 'SpherocylinderFuelTank';

const FuelTankTypes = createVable<TFuelTankTypes>({
  SphericalFuelTank: 'Spherical',
  SpherocylinderFuelTank: 'Spherocylinder',
});

export const FuelTankVables = {
  Type: FuelTankTypes,
};

/*============================================================================*/
// Load
/*============================================================================*/
export type TEpsOutputTypeKeys = 'CORE_OUTPUT' | 'BUS_REGULATOR';

const EpsOutputType = createVable<TEpsOutputTypeKeys>({
  CORE_OUTPUT: 'Power Controller Bus',
  BUS_REGULATOR: 'Bus Regulator Output',
});

export type TLoadDefinitionTypeKeys = 'PowerLoad' | 'ResistanceLoad';

const LoadDefinitionType = createVable<TLoadDefinitionTypeKeys>({
  PowerLoad: 'Constant Power',
  ResistanceLoad: 'Constant Resistance',
});

export const LoadVables = {
  EpsOutputType,
  Type: LoadDefinitionType,
};

/*============================================================================*/
// Modems
/*============================================================================*/
export type TModemTypes = 'Modem';
const ModemTypes = createVable<TModemTypes>({
  Modem: 'Modem',
});

export const ModemVables = {
  Type: ModemTypes,
};

/*============================================================================*/
// Modules
/*============================================================================*/
export type TModuleType =
  | 'TARGETS'
  | 'GNC'
  | 'CDH'
  | 'POWER'
  | 'THERMAL'
  | 'DATA_HANDLING'
  | 'PAYLOAD'
  | 'COMING_SOON';

export type TSimulationStatus =
  | 'READY'
  | 'INCOMPLETE'
  | 'SUCCEEDED'
  | 'RUNNING'
  | 'FAILED'
  | 'ERROR'
  | 'MD_FAILURE'
  | 'PARTIAL_RESULTS'
  | 'OUTDATED'
  | 'PENDING'
  | 'QUEUED'
  | 'TERMINATED';

export type TCompletedStatus = 'SUCCEEDED' | 'FAILED' | 'ERROR' | 'TERMINATED';

const ModuleTypes = createVable<TModuleType>({
  TARGETS: 'Targets',
  GNC: 'GNC',
  CDH: 'Command & Control',
  POWER: 'Power',
  THERMAL: 'Thermal',
  DATA_HANDLING: 'Data Handling',
  PAYLOAD: 'Payload',
  COMING_SOON: 'Coming Soon',
});

const SimulationStatuses = createVable<TSimulationStatus>({
  READY: 'Ready',
  INCOMPLETE: 'Incomplete design',
  SUCCEEDED: 'Success',
  RUNNING: 'Running...',
  FAILED: 'Failed',
  ERROR: 'Error',
  MD_FAILURE: 'MD Failed',
  PARTIAL_RESULTS: 'Incomplete simulation',
  OUTDATED: 'Outdated',
  PENDING: 'Deploying...',
  QUEUED: 'Queued...',
  TERMINATED: 'Aborted',
});

const CompletedStatuses = createVable<TCompletedStatus>({
  SUCCEEDED: 'Success',
  FAILED: 'Failed',
  ERROR: 'Error',
  TERMINATED: 'Aborted',
});

export const ModuleVables = {
  ModuleTypes,
  SimulationStatuses,
  CompletedStatuses,
};

/*============================================================================*/
// Orbit
/*============================================================================*/
export type TInitialStateDefTypes = 'ECI_STATE' | 'ORBITAL_ELEMENTS' | 'REF_ORBIT' | 'TLE';
export type TInitialRefOrbitTypes =
  | 'POLAR_CIRC'
  | 'EQUATORIAL_CIRC'
  | 'SUN_SYNC_CIRC'
  | 'GEOSTAT'
  | 'GEOSTAT_TRANSFER'
  | 'ISS';

const InitialStateDefType = createVable<TInitialStateDefTypes>({
  ECI_STATE: 'Position & Velocity',
  ORBITAL_ELEMENTS: 'Orbital Elements',
  REF_ORBIT: 'Reference Orbit',
  TLE: 'Two-Line Element',
});

const InitialRefOrbit = createVable<TInitialRefOrbitTypes>({
  POLAR_CIRC: 'Polar Circular',
  EQUATORIAL_CIRC: 'Equatorial Circular',
  SUN_SYNC_CIRC: 'Sun-Synchronous Circular',
  GEOSTAT: 'Geostationary',
  GEOSTAT_TRANSFER: 'Geostationary Transfer',
  ISS: 'International Space Station',
});

export type TOrbitalDynamics = 'PROPAGATED' | 'EPHEMERIS';
const OrbitalDynamics = createVable<TOrbitalDynamics>({
  PROPAGATED: 'Propagated',
  EPHEMERIS: 'Ephemeris',
});

export const OrbitVables = {
  InitialStateDefType,
  InitialRefOrbit,
  OrbitalDynamics,
};

// Pointing Modes
/*============================================================================*/

export type TPointingModeType =
  | 'PassivePointingMode'
  | 'MaxAlignPointingMode'
  | 'LockSpinPointingMode';

const PointingModeTypes = createVable<TPointingModeType>({
  PassivePointingMode: 'Passive',
  MaxAlignPointingMode: 'Max Secondary Alignment',
  LockSpinPointingMode: 'Direction Lock',
});

export const PointingModeVables = {
  Type: PointingModeTypes,
};

/*============================================================================*/
// Power Processor
/*============================================================================*/
export type TTopologyTypes =
  | 'SingleConvMpptPowerProcessor'
  | 'TwoConvMpptPowerProcessor'
  | 'QuasiRegDetPowerProcessor'
  | 'SingleConvHybridPowerProcessor'
  | 'FullyRegDetPowerProcessor';

const TopologyTypes = createVable<TTopologyTypes>({
  SingleConvMpptPowerProcessor: 'Single Converter Maximum Peak Power Point Tracking',
  TwoConvMpptPowerProcessor: 'Two Converter Maximum Peak Power Point Tracking',
  QuasiRegDetPowerProcessor: 'Quasi-Regulated Direct Energy Transfer',
  FullyRegDetPowerProcessor: 'Fully-Regulated Direct Energy Transfer',
  SingleConvHybridPowerProcessor: 'Single Converter Hybrid',
});

export const PowerProcessorVables = {
  Type: TopologyTypes,
};

/*============================================================================*/
// Reference Vectors
/*============================================================================*/

export type TReferenceVectorTypes =
  | 'CelestialVector'
  | 'LocalVector'
  | 'TargetVector'
  | 'TargetGroupVector';
export type TReferenceVectorRepresentations = 'TRUTH' | 'ESTIMATE';
export type TCelestialPointingDirections =
  | 'SUN'
  | 'MOON'
  | 'EARTH'
  | 'MERCURY'
  | 'VENUS'
  | 'MARS'
  | 'JUPITER'
  | 'SATURN'
  | 'URANUS'
  | 'NEPTUNE'
  | 'PLUTO';
export type TLocalPointingDirections =
  | 'NADIR'
  | 'ZENITH'
  | 'CROSS_TRACK_POS'
  | 'CROSS_TRACK_NEG'
  | 'ALONG_TRACK_POS'
  | 'ALONG_TRACK_NEG'
  | 'RAM'
  | 'ANTI_RAM'
  | 'MAGNETIC_FIELD';
export type TLocalReferenceFrames = 'LVLH_COORDINATES' | 'ENU_COORDINATES' | 'ECI_COORDINATES';

const VectorTypes = createVable<TReferenceVectorTypes>({
  CelestialVector: 'Celestial',
  LocalVector: 'Local',
  TargetVector: 'Target',
  TargetGroupVector: 'Target Group',
});

const CelestialPointingDirections = createVable<TCelestialPointingDirections>({
  SUN: 'Sun',
  MOON: 'Moon',
  EARTH: 'Earth',
  MERCURY: 'Mercury',
  VENUS: 'Venus',
  MARS: 'Mars',
  JUPITER: 'Jupiter',
  SATURN: 'Saturn',
  URANUS: 'Uranus',
  NEPTUNE: 'Neptune',
  PLUTO: 'Pluto',
});

const LocalPointingDirections = createVable<TLocalPointingDirections>({
  NADIR: 'Nadir',
  ZENITH: 'Zenith',
  CROSS_TRACK_POS: 'Positive Cross Track',
  CROSS_TRACK_NEG: 'Negative Cross Track',
  ALONG_TRACK_POS: 'Positive Along Track',
  ALONG_TRACK_NEG: 'Negative Along Track',
  RAM: 'Ram',
  ANTI_RAM: 'Anti-Ram',
  MAGNETIC_FIELD: 'Magnetic Field',
});

const LocalReferenceFrames = createVable<TLocalReferenceFrames>({
  LVLH_COORDINATES: 'LVLH',
  ENU_COORDINATES: 'ENU',
  ECI_COORDINATES: 'ECI',
});

const Representation = createVable<TReferenceVectorRepresentations>({
  TRUTH: 'Truth',
  ESTIMATE: 'Estimate',
});

export const ReferenceVectorVables = {
  Representation,
  CelestialPointingDirections,
  LocalPointingDirections,
  LocalReferenceFrames,
  Type: VectorTypes,
};

/*============================================================================*/
// Repository
/*============================================================================*/

export type TRepoTypesKeys = 'SCENARIO' | 'AGENT_TEMPLATE';

const RepoTypes = createVable<TRepoTypesKeys>({
  AGENT_TEMPLATE: 'Agent Template',
  SCENARIO: 'Scenario',
});

export const RepoVables = {
  RepoTypes,
};

/*============================================================================*/
// Sensor
/*============================================================================*/
export type TSensorTypes =
  | 'AngularVelocitySensor'
  | 'DirectionSensor'
  | 'OpticalAttitudeSensor'
  | 'PositionSensor'
  | 'TargetAttitudeSensor'
  | 'TargetPositionSensor'
  | 'TargetRangeSensor'
  | 'TargetRangeRateSensor'
  | 'VectorSensor';

const SensorType = createVable<TSensorTypes>({
  AngularVelocitySensor: 'Angular Velocity Sensor',
  DirectionSensor: 'Direction Sensor',
  OpticalAttitudeSensor: 'Optical Attitude Sensor',
  PositionSensor: 'Position Sensor',
  TargetAttitudeSensor: 'Target Attitude Sensor',
  TargetPositionSensor: 'Target Position Sensor',
  TargetRangeSensor: 'Target Range Sensor',
  TargetRangeRateSensor: 'Target Range Rate Sensor',
  VectorSensor: 'Vector Sensor',
});

export const SensorVables = {
  Type: SensorType,
};

/*============================================================================*/
// Subsystem
/*============================================================================*/
export type TSubsystemCategoriesKeys =
  | 'POWER'
  | 'CDH'
  | 'DATA_HANDLING'
  | 'GNC'
  | 'THERMAL'
  | 'STRUCTURE'
  | 'CUSTOM';

const Categories = createVable<TSubsystemCategoriesKeys>({
  POWER: 'Power',
  CDH: 'Command & Control',
  DATA_HANDLING: 'Data Handling',
  GNC: 'Guidance, Navigation, and Control',
  THERMAL: 'Thermal',
  STRUCTURE: 'Structure',
  CUSTOM: 'Custom Subsystem',
});

export const SubsystemVables = {
  Categories,
};

/*============================================================================*/
// Surface
/*============================================================================*/
export type TSurfaceTypesKeys = 'FixedSurface' | 'SunTrackingSurface' | 'VectorTrackingSurface';

const SurfaceTypes = createVable<TSurfaceTypesKeys>({
  FixedSurface: 'Fixed',
  SunTrackingSurface: 'Sun Tracking',
  VectorTrackingSurface: 'Vector Tracking',
});

export const SurfaceVables = {
  Type: SurfaceTypes,
};

/*============================================================================*/
// Target
/*============================================================================*/
export type TTargetTypes = 'AreaTarget' | 'SpaceTarget' | 'CelestialTarget' | 'GroundTarget';
export type TPolynomialEphemerisBodyTypes =
  | 'SUN'
  | 'MOON'
  | 'EARTH'
  | 'MERCURY'
  | 'VENUS'
  | 'MARS'
  | 'JUPITER'
  | 'SATURN'
  | 'URANUS'
  | 'NEPTUNE'
  | 'PLUTO';

const TargetTypes = createVable<TTargetTypes>({
  AreaTarget: 'Area Target',
  SpaceTarget: 'Space Target',
  CelestialTarget: 'Celestial Target',
  GroundTarget: 'Ground Target',
});

const PolynomialEphemerisBody = createVable<TPolynomialEphemerisBodyTypes>({
  SUN: 'Sun',
  MOON: 'Moon',
  EARTH: 'Earth',
  MERCURY: 'Mercury',
  VENUS: 'Venus',
  MARS: 'Mars',
  JUPITER: 'Jupiter',
  SATURN: 'Saturn',
  URANUS: 'Uranus',
  NEPTUNE: 'Neptune',
  PLUTO: 'Pluto',
});

export const TargetVables = {
  Type: TargetTypes,
  PolynomialEphemerisBody,
};

export const TargetGroupVables = {
  TargetTypes,
};

/*============================================================================*/
// Temperature Controller
/*============================================================================*/
export type TTempControllerTypes = 'Heater' | 'Cooler';

const TempControllerType = createVable<TTempControllerTypes>({
  Heater: 'Heater',
  Cooler: 'Cooler',
});

export const TempControllerVables = {
  Type: TempControllerType,
};

/*============================================================================*/
// Workspace
/*============================================================================*/
export type TPermission =
  | 'VIEW_PAYMENT_HISTORY'
  | 'SUBMIT_PAYMENT'
  | 'VIEW_WORKSPACE_MEMBERS'
  | 'ADD_REMOVE_WORKSPACE_MEMBER'
  | 'ASSIGN_REVOKE_WORKSPACE_ROLE'
  | 'CREATE_SHAREABLE_LINK'
  | 'EDIT_WORKSPACE'
  | 'DELETE_WORKSPACE'
  | 'VIEW_REPOSITORY'
  | 'EDIT_REPOSITORY'
  | 'RESTORE_REPOSITORY'
  | 'VIEW_BRANCH'
  | 'EDIT_BRANCH'
  | 'EXPORT_BRANCH'
  | 'RUN_SIMULATION'
  | 'VIEW_SIMULATION';

const Permission = {
  VIEW_PAYMENT_HISTORY: Math.pow(2, 0),
  SUBMIT_PAYMENT: Math.pow(2, 0) | Math.pow(2, 1),
  VIEW_WORKSPACE_MEMBERS: Math.pow(2, 2),
  ADD_REMOVE_WORKSPACE_MEMBER: Math.pow(2, 2) | Math.pow(2, 3),
  ASSIGN_REVOKE_WORKSPACE_ROLE: Math.pow(2, 2) | Math.pow(2, 4),
  CREATE_SHAREABLE_LINK: Math.pow(2, 5),
  EDIT_WORKSPACE: Math.pow(2, 6),
  DELETE_WORKSPACE: Math.pow(2, 6) | Math.pow(2, 7),
  VIEW_REPOSITORY: Math.pow(2, 8),
  EDIT_REPOSITORY: Math.pow(2, 8) | Math.pow(2, 9),
  RESTORE_REPOSITORY: Math.pow(2, 8) | Math.pow(2, 10),
  VIEW_BRANCH: Math.pow(2, 11),
  EDIT_BRANCH: Math.pow(2, 11) | Math.pow(2, 12),
  EXPORT_BRANCH: Math.pow(2, 11) | Math.pow(2, 15),
  RUN_SIMULATION: Math.pow(2, 13),
  VIEW_SIMULATION: Math.pow(2, 14),
};

const PermissionName = createVable<TPermission>({
  VIEW_PAYMENT_HISTORY: 'View payment history (temporarily disabled)',
  SUBMIT_PAYMENT: 'Submit payments (temporarily disabled)',
  VIEW_WORKSPACE_MEMBERS: 'View workspace members',
  ADD_REMOVE_WORKSPACE_MEMBER: 'Add/remove workspace members',
  ASSIGN_REVOKE_WORKSPACE_ROLE: 'Assign workspace roles',
  CREATE_SHAREABLE_LINK: 'Create shareable links',
  EDIT_WORKSPACE: 'Edit workspace attributes',
  DELETE_WORKSPACE: 'Delete the workspace',
  VIEW_REPOSITORY: 'View project & repository contents',
  EDIT_REPOSITORY: 'Create/delete/edit projects & repositories',
  RESTORE_REPOSITORY: 'Restore deleted repositories (temporarily disabled)',
  VIEW_BRANCH: 'View branch contents',
  EDIT_BRANCH: 'Create/delete/edit branches and contents',
  EXPORT_BRANCH: "Export branch's model data to file",
  RUN_SIMULATION: 'Launch simulations',
  VIEW_SIMULATION: 'View simulation progress and results',
});

export const WorkspaceVables = {
  Permission,
  PermissionName,
};
