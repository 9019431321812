import { makeStyles } from '@material-ui/core/styles';
import { inputWidth } from 'components/general/wizards/WizardSegment/styles';
const useStyles = makeStyles(() => ({
  pointInputRow: {
    display: 'inline-flex',
    alignItems: 'end',
  },
  pointInnerInputRow: {
    width: inputWidth * 1.1,
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  deleteIcon: {
    padding: 8,
    margin: '0 0 4px 0px',
  },
}));

export default useStyles;
