import Grid from '@material-ui/core/Grid';
import { wGroupIndicesATCDH } from 'components/AgentTemplateEditView/menu/cdh';
import GuidanceCard from 'components/general/GuidanceCard';
import { PriorityQueue } from 'components/general/PriorityQueue';
import FallbackDialog from 'components/general/dialogs/FallbackDialog';
import WizardSegment from 'components/general/wizards/WizardSegment';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useSnackbar } from 'hooks';
import { multiBlockCrud } from 'middleware/SatelliteApi/api';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import OperationalModesDialog from './OperationalModesDialog';

/**
 * This segment displays routines, but it pretends to display operational modes in the interim
 * TODO: Make a full transition
 */
const OperationalModesSegment = () => {
  const {
    leafRoutines: _routines,
    logicalConfigurations,
    pointingModes,
    conditions,
    branch,
  } = useActiveEntities();

  const dispatch = useDispatch();

  const [operationalModeDialogConfig, setOperationalModeDialogConfig] = useState({ open: false });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Util to get the LogicalConfiguration corresponding to a Routine
  const routineToConfig = useMemo(() => {
    const result = {};
    for (const lc of logicalConfigurations) {
      result[lc.routine.id] = lc;
    }
    return result;
  }, [logicalConfigurations]);

  const routines = useMemo(() => {
    return [..._routines]
      .filter((routine) => routineToConfig[routine.id])
      .sort(
        (routineA, routineB) =>
          routineToConfig[routineB.id].priority - routineToConfig[routineA.id].priority
      );
  }, [_routines, routineToConfig]);

  const onAddClick = useCallback(() => {
    const highestPriority = logicalConfigurations.reduce(
      (currMax, lc) => Math.max(currMax, lc.priority),
      -1
    );
    setOperationalModeDialogConfig({
      open: true,
      action: 'create',
      nextHighestPriority: highestPriority + 1,
    });
  }, [setOperationalModeDialogConfig, logicalConfigurations]);

  const onActionClick = useCallback(
    (slotData, action) =>
      setOperationalModeDialogConfig({
        open: true,
        action: action,
        routine: slotData,
      }),
    [setOperationalModeDialogConfig]
  );

  // OperationalModes formerly had the priority field
  // Now the LogicalConfiguration corresponding to each Routine has the priority field
  const onChange = useCallback(
    (items, setLoading) => {
      const blocks = items
        .filter((i) => i.priority !== i.oldPriority)
        .map(({ id, priority }) => ({
          id: routineToConfig[id].id,
          priority,
          type: 'LogicalConfiguration',
        }));
      if (blocks.length > 0) {
        setLoading(true);
        dispatch(
          multiBlockCrud({
            branchId: branch.id,
            blocks,
            successCallback: () => {
              enqueueSnackbar('Priorities updated successfully', {
                variant: 'success',
              });
              setLoading(false);
            },
            failureCallback: () => {
              enqueueSnackbar(
                'An error occurred while updating operational mode priorities.  Please reload the page and try again.'
              );
              setLoading(false);
            },
          })
        );
      }
    },
    [enqueueSnackbar, dispatch, branch, routineToConfig]
  );

  const closeDialog = () =>
    setOperationalModeDialogConfig({
      ...operationalModeDialogConfig,
      open: false,
      // set operational mode to an empty object when it closes so our form will reset on open
      routine: {},
    });

  return (
    <Fragment>
      <WizardSegment
        title="Operational Modes"
        index={wGroupIndicesATCDH.OP_MODES}
        xray={{ routines, logicalConfigurations }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.swapRight}>
            <PriorityQueue
              className={classes.table}
              data={routines}
              onAddClick={onAddClick}
              onActionClick={onActionClick}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.swapLeft}>
            <GuidanceCard
              guidance={{
                heading: 'Create and Edit Operational Modes',
                body: [
                  {
                    chunk:
                      'Operational modes are the building blocks of your ConOps. Each operational mode is assigned a pointing mode and - optionally - conditions and timing constraints. Other Sedaro modules will assign mode-dependent factors to these operational modes.\nThe order of modes in this interface indicates their respective priority levels.  Drag and drop operational modes to re-order them by priority.',
                  },
                  {
                    subHeading: 'Operational Mode Logic',
                    chunk:
                      'An operational mode can only be active if it is the default mode or if all of the conditions assigned to it are satisfied. At each time step in your mission simulation, the highest-priority operational mode with all conditions satisfied will become the active mode.',
                  },
                  {
                    subHeading: '"Default" Operational Mode',
                    chunk:
                      'The lowest priority mode is the "default" operational mode. Any conditions attached to a default mode are not evaluated during conops simulation.',
                  },
                ],
              }}
            />
          </Grid>
        </Grid>
      </WizardSegment>
      <ErrorBoundary
        fallback={
          <FallbackDialog
            config={operationalModeDialogConfig}
            onClose={closeDialog}
            title={`Something went wrong while editing this operational mode.`}
          />
        }
      >
        <OperationalModesDialog
          config={operationalModeDialogConfig}
          onClose={closeDialog}
          pointingModes={pointingModes}
          conditions={conditions}
        />
      </ErrorBoundary>
    </Fragment>
  );
};

export default OperationalModesSegment;
