import {
  faArrowsLeftRightToLine,
  faCircleChevronLeft,
  faCircleChevronRight,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, CircularProgress, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import ClipboardCopy from 'components/general/ClipboardCopy';
import InfoBadge from 'components/general/InfoBadge';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IJob } from 'components/general/types';
import { useFormikForm, useLatestJob, useSelectById, useSnackbar } from 'hooks';
import { JoyrideContext, useDataContext } from 'providers';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'routes';
import theme from 'theme';
import { dateFormatLong, mjd2Moment } from 'utils/time';
import { represent } from 'utils/units';
import * as Yup from 'yup';
import { IInlineBadgeProps } from '../../InlineBadge';
import StyledSlider from '../../StyledSlider';
import JobMenu from '../JobMenu';
import useStyles from './styles';

interface IConfig {
  positions: number[];
  open: boolean;
}

interface IProps {
  config: IConfig;
  setConfig: React.Dispatch<React.SetStateAction<IConfig>>;
  metadata: {
    id?: number;
    binWidth?: number;
  };
  notifyOutdated?: boolean;
}

interface ISearch {
  resolution?: { label: string; value: number };
  recursive?: boolean;
  start?: number;
  stop?: number;
}

interface IForm {
  resolution: { label: string; value: number };
  recursive: boolean;
}

const defaultValues: IForm = {
  resolution: { label: '', value: 0 },
  recursive: false,
};

const fetchSchema = Yup.object().shape({
  resolution: Yup.object().shape({
    label: Yup.string(),
    value: Yup.number()
      .required('Select a resolution to fetch data.')
      .min(0, 'Resolution must be greater than or equal to 0.'),
  }),
  recursive: Yup.boolean(),
});

// DSv2 Form is maintained for backwards compatiblity
// TODO: Remove when DSv2 is no longer supported
interface ISearchOld {
  limit?: number;
  start?: number;
  stop?: number;
}
// TODO: Remove when DSv2 is no longer supported
interface IFormOld {
  limit: number | '';
}
// TODO: Remove when DSv2 is no longer supported
const defaultValuesOld: IFormOld = {
  limit: '',
};
// TODO: Remove when DSv2 is no longer supported
const fetchSchemaOld = Yup.object().shape({
  limit: Yup.number()
    .required('An upper limit on the number of data points to fetch is required.')
    .min(2, 'Limit must be greater than or equal to 2.'),
});

const clampSliderPositions = (positions: number[], bounds: number[]) => [
  Math.max(bounds[0], positions[0] || 0),
  Math.min(bounds[1], positions[1] || Number.MAX_SAFE_INTEGER),
];

const getProgressAndBounds = (job: IJob | null) => {
  if (!job) return { progressPct: 0, bounds: [0, 0] };
  let progressPct = Math.max(0, job.progress?.percentComplete || 0);
  if ((progressPct !== 100 && progressPct !== 0) || job.exitTime) {
    progressPct =
      (((job.progress?.currentTime || job.exitTime || job.stopTime) - job.startTime) * 100) /
      (job.stopTime - job.startTime);
  }
  const bounds = [
    job.startTime,
    job.startTime + ((job.stopTime - job.startTime) * progressPct) / 100,
  ];
  return { progressPct, bounds };
};

const limitInputLabel = 'Max Data Points';

const DataWindowDialog = ({ config, setConfig, metadata, notifyOutdated }: IProps) => {
  const classes = useStyles();
  const [latestJob, currentJob] = useLatestJob();
  const { fetchData, fetching, fetchError, seriesData } = useDataContext();
  const { enqueueSnackbar } = useSnackbar();
  const [search] = useSearchParams();
  const [targetJob, setTargetJob] = useState(currentJob);
  const [expandJobs, setExpandJobs] = useState(false);

  // When the dialog opens, close the job status board and set the fetch target to the currentJob
  useEffect(() => {
    if (config.open) {
      setTargetJob(currentJob.dataArray ? currentJob : latestJob);
      setExpandJobs(false);
    }
  }, [config.open]); // eslint-disable-line react-hooks/exhaustive-deps

  // Sync the fetch target with the latest job when it changes
  useEffect(() => {
    if (!targetJob?.id || targetJob.id === currentJob.id) setTargetJob(currentJob);
  }, [currentJob]); // eslint-disable-line react-hooks/exhaustive-deps

  const { progressPct, bounds } = useMemo(() => getProgressAndBounds(targetJob), [targetJob]);

  // Check for out of bounds positions and adjust
  useEffect(
    () =>
      setConfig((config: IConfig) => ({
        ...config,
        positions: clampSliderPositions(config.positions, bounds),
      })),
    [bounds, setConfig]
  );

  const submit = useCallback(
    ({ resolution, recursive }: IForm) => {
      fetchData({
        start: config.positions[0],
        stop: config.positions[1],
        job: targetJob,
        resolution: resolution.value,
        recursive,
      });
      config.positions[0] === config.positions[1]
        ? enqueueSnackbar('Data window must have a range of at least 1 second')
        : setConfig({ ...config, open: false });
    },
    [targetJob, fetchData, config, setConfig, enqueueSnackbar]
  );

  const { formik } = useFormikForm<ISearch, IForm>(defaultValues, submit, fetchSchema, search);
  const { handleSubmit, getFieldProps, resetForm } = formik;

  // DSv2 Form is maintained for backwards compatiblity
  // TODO: Remove when DSv2 is no longer supported
  const submitOld = useCallback(
    ({ limit }: IFormOld) => {
      fetchData({ start: config.positions[0], stop: config.positions[1], job: targetJob, limit });
      config.positions[0] === config.positions[1]
        ? enqueueSnackbar('Data window must have a range of at least 1 second')
        : setConfig({ ...config, open: false });
    },
    [targetJob, fetchData, config, setConfig, enqueueSnackbar]
  );
  const { formik: formikOld } = useFormikForm<ISearchOld, IFormOld>(
    defaultValuesOld,
    submitOld,
    fetchSchemaOld,
    search
  );
  const {
    handleSubmit: handleSubmitOld,
    getFieldProps: getFieldPropsOld,
    resetForm: resetFormOld,
  } = formikOld;

  const _positions = [Number(search.start), Number(search.stop)];

  const actionButtonProps = useCallback(
    (job: IJob) => ({
      className: classes.jobButton,
      onClick: job.dataArray
        ? () => {
            setTargetJob(job);
            setExpandJobs(false);
          }
        : null,
      disabled: !job.dataArray,
      framed: job.id !== targetJob?.id,
      text: job.dataArray ? `Select${job.id === targetJob?.id ? 'ed' : ''} Job` : 'No Data',
    }),
    [targetJob?.id, classes.jobButton]
  );

  const jobMenu = useMemo(
    () => <JobMenu actionButtonProps={actionButtonProps} />,
    [actionButtonProps]
  );

  // Check which Data Service version is being used
  // TODO: Remove when DSv2 is no longer supported
  const useDsv2 = useMemo(() => targetJob?.dataArrayVersion === 2, [targetJob]);

  return (
    <Dialog
      title={'Data Window'}
      xray={seriesData.hierarchical}
      downloadXray={true}
      open={config.open}
      onClose={() => {
        useDsv2 ? resetFormOld() : resetForm();
        setConfig({
          ...config,
          positions: clampSliderPositions(_positions, bounds),
          open: false,
        });
      }}
      submitActionText="Fetch Window"
      disableSubmit={
        progressPct === 0 ||
        (!fetchError &&
          targetJob?.id === currentJob?.id &&
          config.positions[0] === _positions[0] &&
          config.positions[1] === _positions[1] &&
          (useDsv2
            ? Number(formikOld.values.limit) === Number(search.limit)
            : formik.values.resolution?.value.toString() === search.res))
      }
      large
      onSubmit={useDsv2 ? handleSubmitOld : handleSubmit}
      loading={fetching}
      dontDisableInReadOnly
    >
      <div style={{ display: 'flex', overflow: 'clip' }}>
        <div
          style={{
            width: expandJobs ? '450px' : '0px',
            paddingRight: expandJobs ? '10px' : '0px',
            opacity: expandJobs ? 1 : 0,
            transition: 'all 0.4s',
          }}
        >
          {config.open && jobMenu}
        </div>
        <div style={{ width: '100%' }}>
          {notifyOutdated && (
            <>
              <h5 style={{ marginTop: 20 }}>Updated Results Available:</h5>
              <p className={classes.dialogParagraph}>
                Use the interface below to fetch the most recent results.
              </p>
            </>
          )}
          <div className={classes.jobMenu}>
            <IconButton
              onClick={() => setExpandJobs(!expandJobs)}
              style={{
                width: 20,
                height: 20,
                fontSize: 20,
                marginRight: 5,
                color: theme.palette.primary.light,
              }}
            >
              <FontAwesomeIcon icon={expandJobs ? faCircleChevronLeft : faCircleChevronRight} />
            </IconButton>
            <div>
              <h5>Change Jobs</h5>
              {targetJob?.id === latestJob?.id ? (
                <p>Fetching Latest Job</p>
              ) : (
                <p>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ color: theme.palette.warning.main, paddingRight: 5 }}
                  />
                  Fetching Outdated Job
                </p>
              )}
            </div>
          </div>
          {search.start && search.stop && currentJob && (
            <>
              <h5>Most Recent Window:</h5>
              <div className={classes.dialogTitle}>
                <InfoBadge
                  content={`Job ID: ${currentJob.id}`}
                  style={{ margin: '5px 5px 0px 0px' }}
                />
                <p className={classes.dialogParagraph}>
                  {mjd2Moment(search.start).format(dateFormatLong)} to{' '}
                  {mjd2Moment(search.stop).format(dateFormatLong)} | Limit: {search.limit}{' '}
                </p>
                <SamplingBadge style={{ marginLeft: 10 }} binWidth={metadata.binWidth} />
              </div>
            </>
          )}
          <h5 style={{ marginTop: 20 }}>New Window:</h5>
          {/* TODO: Remove when DSv2 is no longer supported */}
          {useDsv2 ? (
            <p className={classes.dialogParagraph}>
              Select a window of data to view. When viewing large regions of the simulation,
              downsampling can be used to reduce data volumes. Enter a value for "{limitInputLabel}"
              to adjust the downsampling rate. Only data from the completed portion of the
              simulation is available and will be returned in the query result.
            </p>
          ) : (
            <p className={classes.dialogParagraph}>
              Select a window of data to view. When viewing large regions of the simulation,
              downsampling can be used to reduce data volumes. Choose the resolution to view data
              at. Only data from the completed portion of the simulation is available and will be
              returned in the query result.
            </p>
          )}

          {progressPct > 0 ? (
            <>
              <div className={classes.dialogTitle}>
                <p className={classes.dialogParagraph}>
                  {mjd2Moment(config.positions[0]).format(dateFormatLong)} to{' '}
                  {mjd2Moment(config.positions[1]).format(dateFormatLong)}
                </p>
              </div>
              {useDsv2 ? (
                <>
                  <p>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{ color: theme.palette.warning.main, paddingRight: 5 }}
                    />
                    This job was simulated on a deprecated version of Sedaro and will soon be
                    unavailable. But don't worry - our simulations are deterministic so if you want
                    to keep these results around, simply resimulate!
                  </p>
                  <LabeledInput
                    {...getFieldPropsOld('limit')}
                    label={limitInputLabel}
                    type="number"
                    placeholder="Limit"
                    dontDisableInReadOnly
                  />
                </>
              ) : (
                <div className={classes.fetchInputs}>
                  <div style={{ width: 200 }}>
                    <LabeledSelect
                      {...getFieldProps('resolution')}
                      label="Select Resolution"
                      options={[
                        { label: 'Full Resolution', value: 1 },
                        { label: '1/2 Resolution', value: 2 },
                        { label: '1/4 Resolution', value: 4 },
                        { label: '1/8 Resolution', value: 8 },
                        { label: 'Min Resolution', value: 0 },
                      ]}
                      dontDisableInReadOnly
                      menuPosition="fixed"
                    />
                  </div>
                  {/* TODO: Add back in when recursive fetching is supported
                  <Tooltip title="Recursively fetch higher resolution data">
                    <ToggleButton
                      style={{
                        height: 36,
                        marginTop: 10,
                        marginLeft: -2,
                        color: theme.palette.background.contrastText,
                        backgroundColor:
                          formik.values.resolution?.value === 1
                            ? theme.palette.action.disabledBackground
                            : formik.values.recursive
                            ? theme.palette.primary.main
                            : theme.palette.background.main,
                      }}
                      value="recursive"
                      selected={formik.values.recursive}
                      onChange={() => {
                        formik.setFieldValue('recursive', !formik.values.recursive);
                      }}
                      disabled={formik.values.resolution?.value === 1}
                    >
                      <div style={{ fontSize: 14, height: 24.5 }}>
                        <FontAwesomeIcon icon={faRotate} />
                      </div>
                    </ToggleButton>
                  </Tooltip> */}
                </div>
              )}
              <div className={classes.sliderWrapper}>
                <div className={classes.sliderTotal}></div>
                <StyledSlider
                  value={config.positions}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => mjd2Moment(value).format(dateFormatLong)}
                  onChange={(e, v) => {
                    if (Array.isArray(v))
                      setConfig({
                        ...config,
                        positions: v,
                      });
                  }}
                  min={bounds[0]}
                  max={bounds[1]}
                  step={1 / 86400} // 1 second
                  style={{ width: `${progressPct}%` }}
                  className={classes.slider}
                />
              </div>
              <div className={classes.limitsWrapper}>
                <p>{mjd2Moment(targetJob.startTime).format(dateFormatLong)}</p>
                <p>{mjd2Moment(targetJob.stopTime).format(dateFormatLong)}</p>
              </div>
            </>
          ) : (
            <p style={{ display: 'flex', alignItems: 'center' }}>
              <b>Please wait until the simulation has progressed further </b>
              <InfoBadge
                style={{ marginLeft: 5 }}
                content="If the simulation is running, please wait until sufficient progress is reported by all Agents before fetching. If the simulation terminated or was aborted, please resimulate. An interactive windowing interface will display here once data is available."
              />
            </p>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const DataWindow = () => {
  const classes = useStyles();
  const [latestJob, targetJob] = useLatestJob();
  const [search] = useSearchParams();
  const { meta: metadata, jobId, fetching, fetchError } = useDataContext();
  const latestJobWithDataFetched = {
    startTime: 0,
    stopTime: 0,
    dataArray: null,
    ...(useSelectById('Job', jobId) as IJob | null),
  };

  const start = Number(search.start);
  const stop = Number(search.stop);
  const { progressPct, bounds } = useMemo(() => getProgressAndBounds(targetJob), [targetJob]);
  const duration = latestJobWithDataFetched.stopTime - latestJobWithDataFetched.startTime;
  const progressLeft = ((start - latestJobWithDataFetched.startTime) * 100) / duration;
  const progressWidth = ((stop - start) * 100) / duration;

  const dataIsCurrent = latestJob?.id === jobId && Boolean(latestJob?.dataArray);
  const notifyOutdated = Boolean(!dataIsCurrent && progressPct && !fetching);
  // const [prevStatus, setPrevStatus] = useState<string | undefined>(undefined);

  const [config, setConfig] = useState<IConfig>({
    positions: [start, stop],
    open: false,
  });

  // useEffect(() => {
  //   // Only trigger fetch reminder when simulation transitions from running to not running
  //   if (latestJob?.status && prevStatus === 'RUNNING' && prevStatus !== latestJob.status) {
  //     if (jobId)
  //       // Don't open on first fetch of a fresh scenario
  //       setConfig({
  //         positions: [latestJob.startTime, latestJob.stopTime],
  //         open: true,
  //       });
  //   }
  //   setPrevStatus(latestJob?.status);
  // }, [latestJob?.status]); // eslint-disable-line react-hooks/exhaustive-deps

  // Open dialog on fetch error
  useEffect(() => {
    if (fetchError) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        open: true,
      }));
    }
  }, [fetchError]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.headerWrapper}>
          <h5 className={classes.header}>
            Analysis Window
            {latestJobWithDataFetched.dataArray && (
              <>
                <InfoBadge
                  content={`Data Array ID: ${latestJobWithDataFetched.dataArray}`}
                  style={{ marginLeft: 5 }}
                />
                <ClipboardCopy
                  text={latestJobWithDataFetched.dataArray}
                  style={{ marginLeft: 5, fontSize: 15 }}
                  displayLabel={false}
                />
              </>
            )}
          </h5>
          <Tooltip
            title={
              // notifyOutdated
              //   ? 'Currently viewing outdated results. Fetch the latest.'
              //   : 'Fetch new window
              'Fetch new window'
            }
          >
            <IconButton
              className={classes.expandBtn}
              disabled={jobId === undefined && latestJob?.id === undefined}
              onClick={() =>
                setConfig({
                  ...config,
                  positions: clampSliderPositions([start, stop], bounds),
                  open: true,
                })
              }
            >
              <FontAwesomeIcon icon={faArrowsLeftRightToLine} style={{ height: 18 }} />
              {/* {notifyOutdated && <div className={classes.notificationBubble}></div>} */}
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <div className={classes.miniBarWrapper}>
            <div className={classes.miniBarTotal}></div>
            {dataIsCurrent && (
              <div className={classes.miniBarProgress} style={{ width: `${progressPct}%` }}></div>
            )}
            <div
              className={classes.miniBarWindow}
              style={{
                left: `${progressLeft}%`,
                width: `${progressWidth}%`,
              }}
            ></div>
          </div>
          {search.start &&
            search.stop &&
            (latestJobWithDataFetched.dataArray || fetching || fetchError) && (
              <p className={classes.dates}>
                {`${mjd2Moment(start).format(dateFormatLong)} to ${mjd2Moment(stop).format(
                  dateFormatLong
                )}`}
              </p>
            )}
          <div className={classes.sampleWrapper}>
            <SamplingBadge binWidth={metadata.binWidth} />
          </div>
        </div>
      </div>
      <DataWindowDialog
        config={config}
        setConfig={setConfig}
        metadata={metadata}
        notifyOutdated={notifyOutdated}
      />
    </>
  );
};

interface ISamplingBadgeProps extends IInlineBadgeProps {
  binWidth?: number;
  fetching?: boolean;
  fetchError?: string;
  downsampled?: boolean;
}

const useBadgeStyles = makeStyles((theme) => ({
  samplingBadge: (props: ISamplingBadgeProps) => ({
    textAlign: 'center',
    marginTop: 3,
    '& > span': {
      backgroundColor: props.fetching
        ? theme.palette.primary.light
        : props.fetchError
        ? theme.palette.error.light
        : props.downsampled && props.binWidth != null
        ? theme.palette.warning.main
        : theme.palette.success.light,
      color: theme.palette.background.main,
      top: 'unset',
      right: 'unset',
      transform: 'none',
      transformOrigin: 'center',
      position: 'relative',
      cursor: 'default',
    },
  }),
  samplingTooltip: {
    ...theme.typography.body,
  },
}));

const SamplingBadge = (props: ISamplingBadgeProps) => {
  const { binWidth, style, ...restOfProps } = props;
  const { meta: metadata, fetching, fetchError } = useDataContext();
  const joyride = useContext(JoyrideContext);
  useEffect(() => {
    joyride.setLoading(fetching);
  }, [fetching, joyride]);

  const downsampled =
    metadata.resolutions &&
    Object.values(metadata.resolutions as { [key: string]: number }).some((r) => r < 1);
  const classes = useBadgeStyles({ ...restOfProps, downsampled, fetching, fetchError });
  let title;
  try {
    title = fetchError
      ? 'Error ' +
        fetchError.error.status +
        ' - ' +
        fetchError.error.code +
        ': ' +
        fetchError.error.message
      : !fetching && downsampled
      ? `Your current window requires downsampling because the selected data set is larger than the specified "${limitInputLabel}". This creates risk of aliasing and other issues. For full-resolution analytics, choose either a smaller window of time or enter a larger limit in the "${limitInputLabel}" input. For analyzing slower parameters on a larger timescale (e.g., beta angle), downsampling is a great tool for avoiding excessive data queries.`
      : '';
  } catch (error) {
    title = 'Error occured while fetching data.';
  }

  return (
    <Tooltip arrow title={title} classes={{ tooltip: classes.samplingTooltip }}>
      <Badge
        badgeContent={
          fetching ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Fetching Data{' '}
              <CircularProgress
                style={{
                  height: 10,
                  width: 10,
                  marginLeft: 5,
                  color: theme.palette.background.main,
                }}
              />
            </div>
          ) : fetchError ? (
            'Error fetching data'
          ) : downsampled && binWidth != null ? (
            `Downsampled to ${represent(binWidth * 86400 * 1000, 'ms', {
              plural: false,
              digits: 3,
            })} bins`
          ) : metadata && Object.keys(metadata).length > 0 ? (
            metadata.sampleRate > 1 ? (
              `1/${metadata.sampleRate} Resolution`
            ) : (
              'Full resolution'
            )
          ) : (
            'No Data'
          )
        }
        className={classes.samplingBadge}
        color="primary"
        style={style}
      />
    </Tooltip>
  );
};

export default DataWindow;
