import { Table, TableBody, TableContainer, TableHead } from '@material-ui/core';
import { Fragment, useCallback, useMemo } from 'react';

import { TStatsTableDatum } from '../types';
import StatsTableSection from './StatsTableSection';
import StatsTableRow from './StatsTableSection/StatsTableRow';
import useStyles from './styles';

interface IStatsTableProps {
  title?: string;
  data: TStatsTableDatum[];
  unit?: string;
  maxHeight?: number | null;
  defaultExpanded?: boolean;
}

const StatsTable = (props: IStatsTableProps) => {
  const { title, data, unit, maxHeight, defaultExpanded = true } = props;
  const classes = useStyles();
  // Possibly revisit this function in the future, probably better way to do this, but this method catches all edge cases
  // Recursive function to generate collapsible sections based on data hierarchies
  const renderSection: (index: number) => [number, JSX.Element] = useCallback(
    (index = 0) => {
      const headerDatum = data[index];
      const collapsibleContent = [];
      index++;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (index >= data.length || data[index].hierarchy <= headerDatum.hierarchy) {
          return [
            index,
            collapsibleContent.length === 0 || headerDatum?.notCollapsible ? (
              <Fragment key={headerDatum.name}>
                <StatsTableRow datum={headerDatum} unit={headerDatum?.unit || unit} />
                {headerDatum?.notCollapsible && collapsibleContent}
              </Fragment>
            ) : (
              <StatsTableSection
                key={headerDatum.name}
                headerDatum={headerDatum}
                collapsibleContent={collapsibleContent}
                unit={unit}
                defaultExpanded={defaultExpanded}
              />
            ),
          ];
        } else if (data[index].hierarchy > headerDatum.hierarchy) {
          const currSect = renderSection(index);
          index = currSect[0];
          collapsibleContent.push(currSect[1]);
        }
      }
    },
    [data, unit, defaultExpanded]
  );
  // Acts as a helper function for the recursion
  const renderSections = useMemo(() => {
    const sections = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].hierarchy <= data[0].hierarchy) {
        const currSect = renderSection(i);
        i = currSect[0] - 1;
        sections.push(currSect[1]);
      }
    }
    return <>{sections}</>;
  }, [renderSection, data]);
  return (
    <TableContainer className={classes.powerTableContainer} style={maxHeight ? { maxHeight } : {}}>
      <Table>
        {title && (
          <TableHead>
            <h4 style={{ fontSize: 16 }}>{title}</h4>
          </TableHead>
        )}
        <TableBody>{renderSections}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default StatsTable;
