import { Switch, Tooltip } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { READ_ONLY_TOOLTIP, TOGGLE } from 'config';
import { useActiveEntities, usePermissionCheck, useSnackbar } from 'hooks';
import { ContextNavContext } from 'providers';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from 'theme';
import { WorkspaceVables } from 'utils/vable';
import { padding } from '../styles';
import useStyles, { subPadding } from './styles';

const sortFn = (a, b) => a[1].order - b[1].order;

const ContextNavList = () => {
  const classes = useStyles();
  const {
    state: { items, activeKey },
    dispatch,
  } = useContext(ContextNavContext);
  const { model, branch } = useActiveEntities();
  const [canEdit] = usePermissionCheck([WorkspaceVables.Permission.EDIT_BRANCH]);

  const apiDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleItemClick = useCallback(
    (keyStr) => {
      dispatch({ type: 'CONTEXT_NAV_ITEM_HANDLE_CLICK', key: keyStr });
    },
    [dispatch]
  );

  const getActiveIndicator = useCallback(
    (keyStr) => {
      return keyStr === activeKey
        ? { borderLeft: `1px solid ${theme.palette.background.contrastText}` }
        : { borderLeft: '1px solid transparent' };
    },
    [activeKey]
  );

  // Titles of items for which toggle Switch should be set to true
  // TODO: This isn't very robust
  const [toggledItems, setToggledItems] = useState([]);
  useEffect(() => {
    const result = [];
    if (model?.enabledModules) model.enabledModules.forEach((m) => result.push(m));
    // NOTE: For other toggled items, add them here
    setToggledItems(result);
  }, [model]);

  const navItemStyle = (depth) => ({
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: padding,
    paddingLeft: depth === 0 ? padding : subPadding * depth,
  });

  const createNavItem = (key, item, depth = 0) => {
    return (
      <Fragment key={item.title}>
        <ListItem
          button
          onClick={(event) => {
            // Don't click on row if Switch was clicked
            if (
              !event?.target?.className?.includes ||
              !event.target.className.includes('MuiSwitch')
            )
              handleItemClick(key);
          }}
          className={classes.subListItem}
          style={{
            ...getActiveIndicator(key),
            ...navItemStyle(depth),
          }}
        >
          <ListItemText
            primary={item.title}
            className={depth === 0 ? classes.listItemText : classes.subListItemText}
          />
          {/* Include toggle on row if necessary, and set up dispatch for its usage */}
          {item[TOGGLE] && (
            <Tooltip arrow title={canEdit ? item[TOGGLE].label : READ_ONLY_TOOLTIP}>
              <span style={{ cursor: canEdit ? 'auto' : 'not-allowed' }}>
                <Switch
                  className="joyride-nav-toggle"
                  size="small"
                  color="primary"
                  checked={toggledItems.includes(item.title)}
                  disabled={!canEdit}
                  onChange={(event) => {
                    // For smoothness, set toggle before API-call is actually set
                    // Undo later if it fails
                    let toggled; // true if item was toggled, false if un-toggled
                    if (toggledItems.includes(item.title)) {
                      setToggledItems(toggledItems.filter((i) => i !== item.title));
                      toggled = false;
                    } else {
                      setToggledItems([...toggledItems, item.title]);
                      toggled = true;
                    }
                    // Send api call to set toggle
                    apiDispatch(
                      item[TOGGLE].action({
                        // TODO: Values given to the toggle action need to be generalizable.
                        // For now, these just update the spacecraft to enable/disable certain modules.
                        branchId: branch.id,
                        rootValues: {
                          enabledModules: event.target.checked
                            ? model.enabledModules.concat(item.title)
                            : model.enabledModules.filter((m) => m !== item.title),
                        },
                        failureCallback: (response) => {
                          const errorMessage =
                            response.error?.message ||
                            'Something went wrong. Please try again. If this problem persists, please contact our support team.';
                          enqueueSnackbar(errorMessage);
                          // Undo action if it fail
                          if (toggled)
                            setToggledItems(toggledItems.filter((i) => i !== item.title));
                          else setToggledItems([...toggledItems, item.title]);
                        },
                      })
                    );
                  }}
                />
              </span>
            </Tooltip>
          )}
          {item.subItems &&
            (item.open ? (
              <KeyboardArrowDownIcon className={classes.expandIcon} />
            ) : (
              <ChevronRightIcon className={classes.expandIcon} />
            ))}
        </ListItem>
        {item.subItems && (
          <Collapse in={item.open} timeout="auto" unmountOnExit>
            <List className={classes.subList}>
              {Object.entries(item.subItems)
                .sort(sortFn)
                .map(([subKey, subItem]) => {
                  const subKeyStr = `${key}.${subKey}`;
                  return createNavItem(subKeyStr, subItem, depth + 1);
                })}
            </List>
          </Collapse>
        )}
      </Fragment>
    );
  };

  return <List>{Object.entries(items).map(([key, item]) => createNavItem(key, item))}</List>;
};

export default ContextNavList;
