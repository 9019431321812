import { createAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BLOCK_TYPE_NAMES } from 'blocktypenames';
import { SATELLITE_API_URL as API_URL } from 'config';
import _ from 'lodash';
import { makeModel } from 'middleware/SatelliteApi/template';
import moment from 'moment';
import { normalize } from 'normalizr';
import { call, put, takeEvery } from 'redux-saga/effects';
import downloadBlobData from 'utils/downloadBlobData';
import fileReader from 'utils/fileReader';
import { toSnakeCase } from 'utils/strings';
import { defaultEndpoints, endpoints } from './endpoints';

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

export const customAxios = axios;

// Axios has intercepters to add header to approved requests...
// https://medium.com/@ryanchenkie_40935/react-authentication-how-to-store-jwt-in-a-cookie-346519310e81

// append any queryParams and seriesProps to end of url
export const _queryParamMiddleware = (seriesProps, queryParams = {}) => {
  let params = { ...queryParams };

  if (seriesProps) {
    const { length, limit, start, skip } = seriesProps;
    if (length) {
      params.length = undefined; // Value-less flag
    } else {
      params.series = undefined; // Value-less flag
      params.limit = limit;
      if (start) params.start = start;
      if (skip) params.skip = skip;
    }
  }
  if ('expand' in queryParams) {
    params.expand = JSON.stringify(queryParams.expand);
  }

  let qString = '';
  const queryEntries = Object.entries(params);
  if (queryEntries.length > 0) {
    qString += '?';
    const queries = queryEntries.map((query) => {
      // check to see if the query param has a value
      // allows for creating query param with no value (ex: ?length)
      // handle cases where query[1] === 0
      if (query[1] !== undefined) {
        return query.join('=');
      } else {
        return query[0];
      }
    });
    qString += queries.join('&');
  }

  return qString;
};

// convert dates to moment objects
const _datesMiddleware = (entities) => {
  for (const [entity, data] of Object.entries(entities)) {
    const sanitizedDates =
      'dateCreated' in data
        ? {
            dateCreated: moment(data.dateCreated),
            dateModified: moment(data.dateModified),
          }
        : {};

    entities[entity] = {
      ...data,
      ...sanitizedDates,
    };
  }
  return entities;
};

export function* _responseMiddleware(baseKey, request, model, isBlock, ignoreResponse) {
  let { data, headers } = yield request;
  const isArray = Array.isArray(data);
  const isZip = data.type === 'application/zip';
  let meta = undefined;

  if (isZip) {
    if (!ignoreResponse) {
      // a generic TypeError would be hit later on anyways, so this just makes it easier to debug
      throw Error(
        'Dev Error: The response must be ignored when the "Content-Type" is "application/zip". Set "ignoreResponse" to true on the corresponding endpoint in endpoints.js.'
      );
    }
    const fileName = headers['content-disposition'].split('filename=')[1].replaceAll('"', '');
    downloadBlobData(fileName, data, data.type);
    data = { fileName };
  }

  if (typeof data === 'string' && !isZip) {
    data = JSON.parse(data);
  }

  if (!ignoreResponse) {
    if (model) {
      if (isBlock) {
        ({ branch: data, ...meta } = data);
      }

      const { entities } = normalize(data, isArray ? [model] : model);
      if (entities.MissionVersion) {
        for (const id in entities.MissionVersion) {
          const missionVersion = entities.MissionVersion[id];
          if (missionVersion.data) {
            missionVersion.model = makeModel(missionVersion.data);
            // delete missionVersion.data;
          }
        }
      }

      for (const data of Object.values(entities)) {
        _datesMiddleware(data);
        // Tech Debt: fix this.  It was added to fix the normalizr Union resolution which doesn't return id for nested entities but {id, schema}
        for (const e of Object.values(data)) {
          for (const [k, v] of Object.entries(e)) {
            if (v && typeof v === 'object' && 'schema' in v) {
              e[k] = v.id;
            }
          }
        }
      }
      data = entities;
    } else {
      data = { [baseKey]: data };
    }
  }
  return {
    data,
    meta,
  };
}

const _formatEndpoint = (modelName, endpoint) => {
  let formattedModelName = modelName;
  let formattedEndpoint = endpoint;
  if (endpoint === 'gets') {
    formattedEndpoint = 'get';
    formattedModelName += 's';
  }
  return { formattedModelName, formattedEndpoint };
};

const _generateAction = (modelName, endpoint) => {
  let { formattedModelName } = _formatEndpoint(modelName, endpoint);
  const baseAction = toSnakeCase(`${formattedModelName}_${endpoint}`)
    .replace('gets', 'get')
    .toUpperCase();
  return createAction(baseAction);
};

//==================================================
// Generic Saga Logic
//==================================================

export function* sagaWrapper(action, callback) {
  try {
    return yield callback(action);
  } catch (err) {
    if (action.payload.disableWrap) {
      throw err;
    }
    let e = err;

    if (e.response?.data instanceof Blob) {
      // When `responseType` is set as 'blob' in an axios request, even error responses are blobs, so mimic the
      // parts/shape of a normal error response for use in this function.
      // NOTE: this workaround was added in in 9/2023 when axios in our app was "^0.27.2" (latest is 1.5.0). It may not
      // be necessary in newer versions. See: https://github.com/axios/axios/issues/3779#issuecomment-1153249714
      e = { response: { data: JSON.parse(yield fileReader(e.response.data)) } };
    }

    const tokenErrors = ['MISSING_TOKEN', 'INVALID_TOKEN'];
    if (tokenErrors.includes(e.response?.data?.error?.code)) {
      const action = createAction('INVALID_TOKEN');
      yield put(action());
    }
    if (e.response?.data?.error?.code === 'INSUFFICIENT_LICENSE') {
      // Launch an insufficient license action so the app can redirect appropriately
      // Only occurs for relevant members of workspace
      const action = createAction('INSUFFICIENT_LICENSE');
      yield put(action(e.response.data));
    }

    console.error('Error', e);

    const { failureCallback = () => undefined } = action.payload;
    const failureAction = createAction(action.type + '_FAILURE');
    failureCallback(e.response?.data);
    yield put(failureAction(e.response?.data));
  }
}

const _generateSaga = (baseKey, routeOptions, model, isBlock, ignoreResponse, ignoreDelete) => {
  const { baseRoute, suffix = '', prefix = '', method } = routeOptions;
  return function* (action) {
    return yield sagaWrapper(action, function* (action) {
      const successAction = createAction(action.type + '_SUCCESS');
      // entity is used to differentiate entities with multiple endpoints that utilize the same model
      // for example, targets could be space, ground, or celestial and all have different endpoints
      const {
        id = '',
        branchId,
        successCallback = () => undefined,
        seriesProps,
        entity,
        moduleType,
        modelId = '',
        queryParams,
        formData, // should be used for any data that needs a different format for the backend
        ...values // generic object with any form values that will be sent to the backend
      } = action.payload || {};

      // Work around for differentiating between the different target endpoints
      // See Target in endpoints.js for more details
      const _baseRoute =
        typeof baseRoute === 'function' ? baseRoute({ ...entity, branchId }) : baseRoute;

      const _prefix = typeof prefix === 'function' ? prefix({ ...entity, branchId }) : prefix;

      // ex: http://localhost:8000/mission-design/missions/versions/1?SeriesPropsHere
      //               API_URL              baseRoute              id seriesMiddleware
      let axiosUrl =
        API_URL +
        _baseRoute +
        _prefix +
        id +
        suffix +
        _queryParamMiddleware(seriesProps, queryParams) +
        modelId;

      // call(callbackFn, ...argsForCallbackFn)
      const { data } = yield call(
        _responseMiddleware,
        // use formData if it exists to properly send files to backend ie for CAD file upload
        // subscriptions sends data in different format, so if values has an identifier from subscriptions, use [{...values}] format
        baseKey,
        axios[method](axiosUrl, formData || values),
        model,
        false,
        ignoreResponse
      );

      let formattedData;
      // check to see if there is a model because if there is one, the data will be normalized
      // extract Object.values since normalized data return {entityId: {entityData}}
      // if there is a model, but the data is undefined, use an empty object
      const dataValues = Object.values(model ? data[model._key] || {} : data);
      formattedData = dataValues.length > 1 ? dataValues : dataValues[0];

      // Blocks can be handled through normal saga close out flow
      if (!isBlock && method === 'delete' && !ignoreDelete) {
        yield put(successAction(formattedData?.deletedEntities));
      } else {
        yield put(successAction(data));
      }

      if (formattedData) {
        successCallback(formattedData);
      } else {
        // TODO: note that this if/else was a temporary fix, because some responses were becoming undefined in the
        // formatted data... examples: POST for checking shareable link password, GET git history
        successCallback(data);
      }
      return formattedData;
    });
  };
};

const _generateSelectors = (entityAdapter, baseKey) => {
  // allows using selectors within the slice reducer
  const baseSelectors = entityAdapter.getSelectors((state) =>
    state.entities ? state.entities[baseKey] : state[baseKey]
  );

  return {
    ...baseSelectors,
    // add custom selectors here
    // use createSelector method to create memoized selector
    // https://github.com/reduxjs/reselect#createselectorinputselectors--inputselectors-resultfunc
    selectFirst: createSelector(
      (state) => baseSelectors.selectAll(state),
      (val) => val[0]
    ),
    selectEntities: createSelector(
      // [baseSelectors.selectEntities, (state) => state.ui?.missionExplorer.activeMissionVersionId],
      [baseSelectors.selectEntities],
      // (selectEntities, branchId) => {
      //   const downselect = {};
      //   for (const k in selectEntities) {
      //     if (branchId && k[0] === branchId) {
      //       downselect[k[1]] = selectEntities[k];
      //     }
      //   }
      //   return downselect;
      // }
      (selectEntities) => selectEntities
    ),
    selectById: createSelector(
      [
        baseSelectors.selectEntities,
        (_, id) => id,
        // (state) => state.ui?.missionExplorer.activeMissionVersionId,
      ],
      // (entitiesMap, id, branchId) => (branchId && entitiesMap[[branchId, id]]) || entitiesMap[id]
      (entitiesMap, id) => entitiesMap[id]
    ),
    selectByIds: createSelector(
      [
        baseSelectors.selectAll,
        (_, ids) => ids,
        (state) => state.ui?.missionExplorer.activeMissionVersionId,
      ],
      (entities, ids, branchId) => {
        if (!ids) return [];
        if (!Array.isArray(ids)) {
          throw Error('selectByIds expects an array of ids. Did you mean to use selectById?');
        }
        return entities.filter(
          (entity) => ids.includes(entity.id) && (!entity.branch || entity.branch === branchId)
        ); // Filter (vs. lookup) necessary here to maintain sorted order
      },
      {
        memoizeOptions: {
          resultEqualityCheck: _.isEqual, // if final selector result is found in cache, return old value
          equalityCheck: _.isEqual, // if return value from the interim selectors is found in cache, return old selector value
          maxSize: 200, // maximum amount of LRU cache values
        },
      }
    ),
  };
};

const _generateSagaBinder = (sagas, actions) => {
  return function* () {
    for (const [saga, sagaFn] of Object.entries(sagas)) {
      // since sagas and actions share the same name, we can leverage this and key into the actions generated for each model
      yield takeEvery(actions[saga].type, sagaFn);
    }
  };
};

const _generateEndpointMethod = (baseKey, endpoint) => {
  const crudEndpoints = Object.keys(defaultEndpoints);
  const { formattedModelName, formattedEndpoint } = _formatEndpoint(baseKey, endpoint);
  if (crudEndpoints.includes(endpoint)) {
    return formattedEndpoint + formattedModelName;
  }
  return endpoint;
};

const satelliteApi = { sagaBinders: {} };

const initialState = {};

// loop through collection of endpoints from ./endpoints.js
for (const baseKey in endpoints) {
  const { baseRoute, routeSuffixes, model, sortBy, reverseSortBy, block, sim } = endpoints[baseKey];

  satelliteApi[baseKey] = { actions: {}, sagas: {}, selectors: {} };

  let endpointObj = satelliteApi[baseKey];
  // create normalized entityAdapter --> User: {adapter: {ids: [userId], entities: {userId: user}}}
  const entityAdapter = createEntityAdapter({
    selectId: (m) => (block ? [m.branch, m.id] : m.id),
    sortComparer: (a, b) => {
      if (sortBy) {
        return b[sortBy] - a[sortBy];
      } else if (reverseSortBy) {
        return a[sortBy] - b[sortBy];
      } else {
        // dateModified descending order
        return b.id - a.id;
      }
    },
  });

  // only assign these fields if a model was created in endpoints.js
  if (model) {
    endpointObj.schema = model;
    endpointObj.adapter = entityAdapter;
    initialState[baseKey] = entityAdapter.getInitialState();
    endpointObj.selectors = _generateSelectors(entityAdapter, baseKey);
  }

  if (endpoints[baseKey].noEndpoint) {
    continue;
  }

  for (const [endpoint, action] of Object.entries(routeSuffixes)) {
    const { method, suffix, prefix, customSaga, ignoreResponse, ignoreDelete } = action;
    const endpointMethod = _generateEndpointMethod(baseKey, endpoint); // ex: getUser, getMissionVersions

    // actions = {baseAction: MODEL_ACTIONNAME, successAction: MODEL_ACTIONNAME_SUCCESS, failureAction: MODEL_ACTIONNAME_FAILURE}
    const baseAction = _generateAction(baseKey, endpoint);

    // generate action for Sagas
    endpointObj.actions[endpointMethod] = baseAction;

    const routeOptions = { baseRoute, suffix, prefix, method, sim };

    // generate sagas for each endpoint
    if (!customSaga) {
      endpointObj.sagas[endpointMethod] = _generateSaga(
        baseKey,
        routeOptions,
        model,
        block,
        ignoreResponse,
        ignoreDelete
      );
    }
  }

  // generate saga binder function to listen for dispatched actions for each model --> UserSaga, MissionVersionSaga, MissionSaga, etc
  satelliteApi.sagaBinders[baseKey + 'Saga'] = _generateSagaBinder(
    satelliteApi[baseKey].sagas,
    satelliteApi[baseKey].actions
  );
}

// generate RTK slice to add to the redux store
satelliteApi.entities = createSlice({
  name: 'entities',
  initialState,
  // RTK extraReducers will function as the Redux-ORM reducer equivalent
  extraReducers: (builder) => {
    // add reducers here if the action does not require a Saga
    builder.addCase(satelliteApi.Workspace.actions.setProjectViewState, (state, action) => {
      const workspace = satelliteApi.Workspace.selectors.selectById(state, action.payload.id);
      if (workspace) {
        const updatedWorkspace = { ...workspace, projectViewOn: action.payload.projectViewOn };
        satelliteApi.Workspace.adapter.upsertOne(state.Workspace, updatedWorkspace);
      }
    });
    builder.addCase(satelliteApi.MissionVersion.actions.invalidateSimulation, (state, action) => {
      const missionVersion = satelliteApi.MissionVersion.selectors.selectById(
        state,
        action.payload
      );
      if (missionVersion) {
        const invalidatedMissionVersion = { ...missionVersion, simulationValid: false };
        satelliteApi.MissionVersion.adapter.upsertOne(
          state.MissionVersion,
          invalidatedMissionVersion
        );
      }
    });
    builder.addCase(satelliteApi.Job.actions.updateAnalyzeState, (state, action) => {
      // Update analyze state, for things like playback time or zoom state
      const job = satelliteApi.Job.selectors.selectById(state, action.payload.id);
      if (job) {
        const updatedJob = {
          ...job,
          analyzeState: {
            playbackTime: Object.hasOwnProperty.call(action.payload, 'playbackTime')
              ? action.payload.playbackTime
              : job.analyzeState?.playbackTime,
            dataState: Object.hasOwnProperty.call(action.payload, 'dataState')
              ? action.payload.dataState
              : job.analyzeState?.dataState,
            fetchWhenTrue: Object.hasOwnProperty.call(action.payload, 'fetchWhenTrue')
              ? action.payload.fetchWhenTrue
              : job.analyzeState?.fetchWhenTrue,
            // Add more state here
          },
        };
        satelliteApi.Job.adapter.upsertOne(state.Job, updatedJob);
      }
    });
    builder.addCase('INVALID_TOKEN', (state) => {
      if (Object.keys(state.User.entities).length > 0)
        axios.delete(
          API_URL + endpoints.User.baseRoute + endpoints.User.routeSuffixes.logout.suffix
        );
    });
    // Main reducer logic
    for (const baseKey in endpoints) {
      // add a reducer case for every action created
      for (const [actionType, actionCreator] of Object.entries(satelliteApi[baseKey].actions)) {
        /* builder format: builder.addCase(actionType, (reducerFn))
          Listens for the success actions dispatched by Sagas */
        builder.addCase(actionCreator + '_SUCCESS', (state, action) => {
          const data = action.payload;

          // Deletion logic
          if (
            actionCreator.type.includes('DELETE') &&
            !endpoints[baseKey].block &&
            !endpoints[baseKey].routeSuffixes[actionType]?.ignoreDelete
          ) {
            // data = [{model: modelName, id: modelId}, ...]
            const deletedEntities = {};
            for (const entity of data) {
              let { model: deletedModelName, id } = entity;

              // TODO, FIXME: This is a temporary fix until we rename Mission and MissionVersion!!!
              if (deletedModelName === 'Repository') deletedModelName = 'Mission';
              if (deletedModelName === 'Branch') deletedModelName = 'MissionVersion';

              // if the deletedModelName is not in endpoints, skip and move to next deletedModelName
              if (!endpoints[deletedModelName] || !endpoints[deletedModelName].relatedModels)
                continue;

              // using the selectors we generate, we can use the id to grab the entity from the store
              const deletedEntity = satelliteApi[deletedModelName].selectors.selectById(state, id);

              // removing related entities
              for (const schema of endpoints[deletedModelName].relatedModels) {
                const { modelName: relatedModelName, field, relatedField: _relatedField } = schema;
                const relatedField =
                  typeof _relatedField === 'function' ? _relatedField(entity) : _relatedField;
                if (relatedField && deletedEntity) {
                  // we can once again use selectors to find the proper entity
                  // the field in endpoints will be an id reference to the related entity
                  const relatedEntity = satelliteApi[relatedModelName].selectors.selectById(
                    state,
                    deletedEntity[field]
                  );

                  if (relatedEntity && !deletedEntities[[relatedModelName, relatedEntity.id]]) {
                    // if the related field is an array, filter out the deletedEntityId
                    if (Array.isArray(relatedEntity[relatedField])) {
                      const filteredField = relatedEntity[relatedField].filter(
                        (value) => value !== parseInt(id)
                      );
                      const filteredEntity = { ...relatedEntity, [relatedField]: filteredField };
                      satelliteApi[relatedModelName].adapter.upsertOne(
                        state[relatedModelName],
                        filteredEntity
                      );
                    } else {
                      // if not an array, remove the entire relatedModel from the store
                      satelliteApi[relatedModelName].adapter.removeOne(
                        state[relatedModelName],
                        deletedEntity[relatedField]
                      );
                    }
                  }
                }
              }

              // remove the current entity from the store
              satelliteApi[deletedModelName].adapter.removeOne(state[deletedModelName], id);

              // if current entity inherits from a parent, also delete that parent
              if (satelliteApi[deletedModelName].superSlice) {
                const superSliceModel = satelliteApi[deletedModelName].superSlice;
                satelliteApi[superSliceModel].adapter.removeOne(state[superSliceModel], id);
              }
              deletedEntities[[deletedModelName, id]] = true;
            }

            // Upsertion logic
          } else {
            if (endpoints[baseKey].routeSuffixes[actionType]?.ignoreResponse) {
              return state;
            }

            for (const entityName in data) {
              if (endpoints[entityName].noModel) continue;
              let parsedData = data[entityName];

              // if the current entity inherits from a parent, update the parent slice
              if (endpoints[entityName].superSlice) {
                const superSliceModel = endpoints[entityName].superSlice;
                satelliteApi[superSliceModel].adapter.upsertMany(
                  state[superSliceModel],
                  parsedData
                );
              }

              // replace or add entity to the store
              satelliteApi[entityName].adapter.upsertMany(state[entityName], parsedData);

              // When creating a new entity, loop through the endpoint related models
              // this ensures that if the data is not handled by normalizr, the proper fields are added to all related models
              if (
                (actionCreator.type.includes('CREATE') || actionCreator.type.includes('CLONE')) &&
                endpoints[entityName].relatedModels
              ) {
                for (const schema of endpoints[entityName]?.relatedModels) {
                  const { modelName, field, relatedField: _relatedField } = schema;
                  const dataValue = Object.values(data[entityName])[0];
                  const relatedField =
                    typeof _relatedField === 'function' ? _relatedField(dataValue) : _relatedField;
                  if (relatedField) {
                    const relatedEntity = satelliteApi[modelName].selectors.selectById(
                      state,
                      dataValue[field]
                    );
                    if (
                      relatedEntity &&
                      Array.isArray(relatedEntity[relatedField]) &&
                      !relatedEntity[relatedField].includes(dataValue.id)
                    ) {
                      relatedEntity[relatedField] = [dataValue.id, ...relatedEntity[relatedField]];
                    }
                  }
                }
              }
            }
          }
        });
      }
    }
  },
});

const bc = createAction('BLOCK_CREATE');
const bu = createAction('BLOCK_UPDATE');
const bd = createAction('BLOCK_DELETE');
const blockSuccessAction = createAction('MISSION_VERSION_GET_SUCCESS');

const blockSaga = function* (action) {
  return yield sagaWrapper(action, function* (action) {
    const {
      id,
      branchId,
      successCallback = () => undefined,
      seriesProps,
      entity,
      moduleType,
      queryParams,
      formData,
      rootValues,
      ...values
    } = action.payload || {};

    let axiosUrl =
      API_URL +
      `/models/branches/${branchId}/template/` +
      _queryParamMiddleware(seriesProps, queryParams);

    const type = values.type || entity?.type;
    const body = {
      root: rootValues,
      blocks:
        action.type !== 'BLOCK_DELETE' && (id || type)
          ? [{ id, type, ...(formData || values) }]
          : [],
      delete: action.type === 'BLOCK_DELETE' ? [id] : undefined,
      config: { extra: 'ignore' },
    };

    // call(callbackFn, ...argsForCallbackFn)
    const {
      data,
      meta: { crud },
    } = yield call(
      _responseMiddleware,
      // use formData if it exists to properly send files to backend ie for CAD file upload
      // subscriptions sends data in different format, so if values has an identifier from subscriptions, use [{...values}] format
      'MissionVersion',
      axios.patch(axiosUrl, body),
      endpoints['MissionVersion'].model,
      true,
      false
    );

    yield put(blockSuccessAction(data));

    const formattedData = data[endpoints['MissionVersion'].model._key][branchId];
    // TODO: Update this once multi-block CRUD is truly multi-block
    const upletedId = crud.blocks[0] || crud.delete[0];

    successCallback(formattedData.model._blocksById[upletedId]);
    return data;
  });
};

satelliteApi.sagaBinders['BlockSaga'] = function* () {
  yield takeEvery(bc, blockSaga);
  yield takeEvery(bu, blockSaga);
  yield takeEvery(bd, blockSaga);
};

for (const k of BLOCK_TYPE_NAMES.concat(['Block'])) {
  satelliteApi[k] = {
    actions: {
      [`create${k}`]: bc,
      [`update${k}`]: bu,
      [`delete${k}`]: bd,
    },
    sagas: {
      [`create${k}`]: blockSaga,
      [`update${k}`]: blockSaga,
      [`delete${k}`]: blockSaga,
    },
  };
}

satelliteApi.OperationalMode.actions['updatePriorities'] = createAction(
  'OPERATIONAL_MODE_UPDATE_PRIORITIES'
);

const multiBlockSaga = function* (action) {
  return yield sagaWrapper(action, function* (action) {
    const { branchId, successCallback = () => undefined, ...body } = action.payload || {};

    let axiosUrl = API_URL + `/models/branches/${branchId}/template/`;

    // call(callbackFn, ...argsForCallbackFn)
    const res = yield call(
      _responseMiddleware,
      'MissionVersion',
      axios.patch(axiosUrl, body),
      endpoints['MissionVersion'].model,
      true,
      false
    );
    const { data } = res;
    yield put(blockSuccessAction(data));

    successCallback({
      branch: data.MissionVersion[branchId],
      crud: res.meta.crud,
    });
    return data;
  });
};

export const multiBlockCrud = createAction('MULTI_BLOCK_CRUD');

satelliteApi.sagaBinders['MultiBlockSaga'] = function* () {
  yield takeEvery(multiBlockCrud, multiBlockSaga);
};

export let SatelliteApi = satelliteApi;
