import { InputAdornment } from '@material-ui/core';
import { BfVectorAccent, PowerAccent } from 'components/general/Accent/variants';
import GncAccent from 'components/general/Accent/variants/GncAccent';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { ISelectOption } from 'components/general/types';
import { IActuator } from 'components/general/types/gnc';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import getThermalProps from 'hooks/getThermalProps';
import { SpacecraftContext } from 'providers';
import { useCallback, useContext, useMemo } from 'react';
import { translateIn, translateOut } from 'utils/forms';
import { ActuatorVables, SubsystemVables } from 'utils/vable';
import { useGuidance } from './guidance';
import validation from './validation';

interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  type: ISelectOption | '';
  modulationType: ISelectOption | '';
  bodyFrameVector: ISelectOption | '';
  powerSource: ISelectOption | '';
  ratedMagneticMoment: number | '';
  powerAtRatedMagneticMoment: number | '';
  dutyCyclePeriod: number | '';
  inertia: number | '';
  ratedTorque: number | '';
  ratedMomentum: number | '';
  efficiency: number | '';
  isp: number | '';
  minThrust: number | '';
  maxThrust: number | '';
  location: [string, string, string];
  orientation: ISelectOption | '';
  fuelReservoir: ISelectOption | '';
  dipoleOrientation: ISelectOption | '';
  dipoleMomentMagnitude: number | '';
  length: number | '';
  diameter: number | '';
  coerciveField: number | '';
  fluxRemanence: number | '';
  saturationFlux: number | '';
  rateScalingPower: number | '';
  slopeOffset: number | '';
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  type: '',
  modulationType: '',
  bodyFrameVector: '',
  powerSource: '',
  ratedMagneticMoment: '',
  powerAtRatedMagneticMoment: '',
  dutyCyclePeriod: '',
  inertia: '',
  ratedTorque: '',
  ratedMomentum: '',
  efficiency: '',
  isp: '',
  minThrust: '',
  maxThrust: '',
  location: ['', '', ''],
  orientation: '',
  fuelReservoir: '',
  dipoleOrientation: '',
  dipoleMomentMagnitude: '',
  length: '',
  diameter: '',
  coerciveField: '',
  fluxRemanence: '',
  saturationFlux: '',
  rateScalingPower: '',
  slopeOffset: '',
};

interface IProps {
  control: TEntityDialogControl<IActuator>;
}
const ActuatorDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action },
  } = control;
  const { subsystems, bodyFrameVectors, busRegulators, powerProcessor, fuelReservoirs } =
    useActiveEntities();
  const { setSpacecraftDialogConfig, SpacecraftTabs } = useContext(SpacecraftContext);

  // Set up styles
  const classes = useStyles();

  const bfOptions = bodyFrameVectors.map((c) => {
    return { value: c.id, label: c.name };
  });

  const options = useMemo(() => {
    return {
      bodyFrameVector: bfOptions,
      dipoleOrientation: bfOptions,
      type: ActuatorVables.Type.options,
      modulationType: ActuatorVables.MagnetorquerModulationType.options,
      powerSource: [{ value: powerProcessor.id, label: powerProcessor.name }].concat(
        busRegulators.map((br) => {
          return { value: br.id, label: br.name };
        })
      ),
      orientation: bfOptions,
      fuelReservoir: fuelReservoirs.map((c) => {
        return { value: c.id, label: c.name };
      }),
    };
  }, [busRegulators, powerProcessor, bfOptions, fuelReservoirs]);

  const customTranslateIn = useCallback(
    (actuator, defaultValues, options, datetimes, percentages) => {
      // Power source can be powerProcessor or busRegulator
      actuator.powerSource = actuator.busRegulator || actuator.powerProcessor;
      return translateIn(actuator, defaultValues, options, datetimes, percentages);
    },
    []
  );

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options, datetimes, percentages) => {
      const result = translateOut(values, allowedEmptyFields, options, datetimes, percentages);
      // Power source can be powerProcessor or busRegulator
      if (result.powerSource === powerProcessor.id) {
        result.powerProcessor = result.powerSource;
        result.busRegulator = null;
      } else {
        result.busRegulator = result.powerSource;
        result.powerProcessor = null;
      }
      delete result.powerSource;

      return result;
    },
    [powerProcessor]
  );

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  const entityForm = useEntityForm<IActuator, IForm & typeof thermalDefaultValues>({
    entityTypeText: 'Actuator',
    entityDialogControl: control,
    defaultValues: { ...defaultValues, ...thermalDefaultValues },
    additionalCreateValues: {
      subsystem: subsystems.find((s) => s.category === SubsystemVables.Categories.GNC.value)?.id,
    },
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      options,
      percentages: ['efficiency'],
      allowedEmptyFields: ['manufacturer', 'partNumber'],
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Actuator Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('type')}
            label="Actuator Type"
            options={options.type}
            isDisabled={action !== 'create'}
          />

          {typeof values.type === 'object' &&
            values.type.value === ActuatorVables.Type.Magnetorquer.value && (
              <>
                <div className={classes.indent}>
                  <LabeledInput
                    label="Rated Magnetic Moment"
                    {...getFieldProps('ratedMagneticMoment')}
                    type="number"
                    endAdornment={<InputAdornment position="end">A·m²</InputAdornment>}
                    autoFocus
                  />
                  <LabeledInput
                    label="Power At Rated Magnetic Moment"
                    {...getFieldProps('powerAtRatedMagneticMoment')}
                    type="number"
                    endAdornment={<InputAdornment position="end">W</InputAdornment>}
                  />
                  <LabeledSelect
                    {...getFieldProps('modulationType')}
                    label="Modulation Type"
                    options={options.modulationType}
                  />
                  {typeof values.modulationType === 'object' &&
                    values.modulationType.value === ActuatorVables.MagnetorquerModulationType.DIGITAL.value && (
                      <>
                        <div className={classes.indent}>
                          <LabeledInput
                            label="Pulse-Width Duty Cycle Period"
                            {...getFieldProps('dutyCyclePeriod.s')}
                            type="number"
                            endAdornment={<InputAdornment position="end">{'s'}</InputAdornment>}
                            autoFocus
                          />
                        </div>
                      </>
                    )}
                </div>
              </>
            )}
          {typeof values.type === 'object' &&
            values.type.value === ActuatorVables.Type.ReactionWheel.value && (
              <div className={classes.indent}>
                <LabeledInput
                  label="Inertia"
                  {...getFieldProps('inertia')}
                  type="number"
                  endAdornment={<InputAdornment position="end">kg·m²</InputAdornment>}
                  autoFocus
                />
                <LabeledInput
                  label="Rated Torque"
                  {...getFieldProps('ratedTorque')}
                  type="number"
                  endAdornment={<InputAdornment position="end">N·m</InputAdornment>}
                />
                <LabeledInput
                  label="Rated Angular Momentum"
                  {...getFieldProps('ratedMomentum')}
                  type="number"
                  endAdornment={<InputAdornment position="end">kg·m²/s</InputAdornment>}
                />
                <LabeledInput
                  label="Efficiency"
                  {...getFieldProps('efficiency')}
                  type="number"
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </div>
            )}
          {typeof values.type === 'object' &&
            (values.type.value === ActuatorVables.Type.ReactionWheel.value ||
              values.type.value === ActuatorVables.Type.Magnetorquer.value) && (
              <BfVectorAccent
                onAddAction={() =>
                  setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
                }
              >
                <LabeledSelect
                  {...getFieldProps('bodyFrameVector')}
                  label="Torque Body Frame Vector"
                  options={options.bodyFrameVector}
                />
              </BfVectorAccent>
            )}
          {typeof values.type === 'object' &&
            values.type.value === ActuatorVables.Type.Thruster.value && (
              <>
                <LabeledInput
                  label="Specific Impulse"
                  {...getFieldProps('isp')}
                  placeholder="Isp"
                  type="number"
                  endAdornment={<InputAdornment position="end">s</InputAdornment>}
                  autoFocus
                />
                <LabeledInput
                  label="Minimum Thrust"
                  {...getFieldProps('minThrust')}
                  type="number"
                  endAdornment={<InputAdornment position="end">N</InputAdornment>}
                />
                <LabeledInput
                  label="Maximum Thrust"
                  {...getFieldProps('maxThrust')}
                  type="number"
                  endAdornment={<InputAdornment position="end">N</InputAdornment>}
                />
                <h6>Thruster Location</h6>
                <LabeledInput
                  label="x-Component"
                  type="number"
                  {...getFieldProps('location.0')}
                  endAdornment={<InputAdornment position="end">m</InputAdornment>}
                />
                <LabeledInput
                  label="y-Component"
                  type="number"
                  {...getFieldProps('location.1')}
                  endAdornment={<InputAdornment position="end">m</InputAdornment>}
                />
                <LabeledInput
                  label="z-Component"
                  type="number"
                  {...getFieldProps('location.2')}
                  endAdornment={<InputAdornment position="end">m</InputAdornment>}
                />
                <BfVectorAccent
                  onAddAction={() =>
                    setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
                  }
                >
                  <LabeledSelect
                    {...getFieldProps('orientation')}
                    label="Thruster Orientation"
                    options={options.orientation}
                  />
                </BfVectorAccent>
                <GncAccent header="Fuel Reservoir">
                  <LabeledSelect
                    {...getFieldProps('fuelReservoir')}
                    options={options.fuelReservoir}
                  />
                </GncAccent>
              </>
            )}
        </div>
        {/* Passive components go here so that they can be or'd with power source  */}
        {typeof values.type === 'object' &&
          ((values.type.value === ActuatorVables.Type.PermanentDipoleMagnet.value && (
            <>
              <LabeledInput
                label="Magnetic Moment Magnitude"
                {...getFieldProps('dipoleMomentMagnitude')}
                type="number"
                endAdornment={<InputAdornment position="end">A·m²</InputAdornment>}
                autoFocus
              />
              <BfVectorAccent
                onAddAction={() =>
                  setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
                }
              >
                <LabeledSelect
                  {...getFieldProps('dipoleOrientation')}
                  label="Dipole Orientation"
                  options={options.dipoleOrientation}
                />
              </BfVectorAccent>
            </>
          )) ||
            (values.type.value === ActuatorVables.Type.MagneticHysteresisRod.value && (
              <>
                <LabeledInput
                  label="Rod Length"
                  {...getFieldProps('length')}
                  type="number"
                  endAdornment={<InputAdornment position="end">m</InputAdornment>}
                  autoFocus
                />
                <LabeledInput
                  label="Rod Diameter"
                  {...getFieldProps('diameter')}
                  type="number"
                  endAdornment={<InputAdornment position="end">m</InputAdornment>}
                />
                <BfVectorAccent
                  onAddAction={() =>
                    setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
                  }
                >
                  <LabeledSelect
                    {...getFieldProps('dipoleOrientation')}
                    label="Dipole Orientation"
                    options={options.dipoleOrientation}
                  />
                </BfVectorAccent>
                <LabeledInput
                  label="Magnetic Coercivity"
                  {...getFieldProps('coerciveField')}
                  type="number"
                  endAdornment={<InputAdornment position="end">A/m</InputAdornment>}
                />
                <LabeledInput
                  label="Magnetic Remanence"
                  {...getFieldProps('fluxRemanence')}
                  type="number"
                  endAdornment={<InputAdornment position="end">T</InputAdornment>}
                />
                <LabeledInput
                  label="Saturation Flux"
                  {...getFieldProps('saturationFlux')}
                  type="number"
                  endAdornment={<InputAdornment position="end">T</InputAdornment>}
                />
                <LabeledInput
                  label="Rate Scaling Power"
                  {...getFieldProps('rateScalingPower')}
                  type="number"
                  optional
                />
                <LabeledInput
                  label="Slope Offset"
                  {...getFieldProps('slopeOffset')}
                  type="number"
                  optional
                />
              </>
            )) || (
              <div className={classes.inputGroup}>
                <PowerAccent header="Power Source">
                  <LabeledSelect {...getFieldProps('powerSource')} options={options.powerSource} />
                </PowerAccent>
              </div>
            ))}
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            placeholder="Manufacturer"
            type="text"
            optional
          />
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            type="text"
            optional
          />
          <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
        </div>
      </div>
    </EntityDialog>
  );
};

export default ActuatorDialog;
