import { Tooltip } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';
import ScheduleIcon from '@material-ui/icons/Schedule';
import theme from 'theme';
import useStyles from './styles';

const defaultVariant = {
  icon: InfoIcon,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
};

const variants = {
  info: defaultVariant,
  schedule: { ...defaultVariant, icon: ScheduleIcon },
  error: {
    icon: ErrorOutlineIcon,
    backgroundColor: theme.palette.error.light,
    color: theme.palette.background.default,
  },
};

interface IProps {
  message: string;
  details?: string;
  variant?: keyof typeof variants;
}

const AlertCard = ({ message, details, variant = 'info' }: IProps) => {
  const { icon: Icon, backgroundColor, color } = variants[variant];
  const classes = useStyles({ backgroundColor, color });

  return (
    <div className={classes.alert}>
      <Icon />
      <p>
        {message}{' '}
        {details && (
          <Tooltip title={<div className={classes.alertTooltip}>{details}</div>} arrow>
            <u>Details</u>
          </Tooltip>
        )}
      </p>
    </div>
  );
};

export default AlertCard;
