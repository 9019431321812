import { InputAdornment } from '@material-ui/core';
import AttitudeForm from 'components/ScenarioView/ScenarioEditView/EditBoard/AttitudeForm';
import GncAccent from 'components/general/Accent/variants/GncAccent';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IGenericObject, ISelectOption } from 'components/general/types';
import { IActuator, IAlgorithm, ISensor, IThruster } from 'components/general/types/gnc';
import WidgetTable from 'components/general/widgets/WidgetTable';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useBlockSupers, useEntityForm, useSelectBlocks } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { ITableBlock } from 'hooks/useSelectBlocks';
import _ from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { createNestedOption, translateIn, translateOut } from 'utils/forms';
import { rad2Deg } from 'utils/math';
import {
  ActuatorVables,
  AlgorithmVables,
  TAlgorithmTypes,
  isAc,
  isAd,
  isOd,
  isTc,
} from 'utils/vable';
import { useGuidance } from './guidance';
import validation from './validation';

interface IProps {
  control: TEntityDialogControl<IAlgorithm>;
}

interface IForm {
  name: string;
  rate: number | '';
  actuators?: IActuator[];
  type: ISelectOption | '';
  angularVelocitySensors?: ISensor[];
  gainK: number | '';
  gainG: number | '';
  gainC: number | '';
  epsilon: number | '';
  gainP: number | '';
  gainI: number | '';
  gainD: number | '';
  opticalAttitudeSensors?: ISensor[];
  positionSensor: ISelectOption | '';
  positionSensors?: ISensor[];
  vectorSensors?: ISensor[];
  thrusters: IThruster[];
  oneSigmaYawError: { deg: number | '' };
  oneSigmaPitchError: { deg: number | '' };
  oneSigmaRollError: { deg: number | '' };
  oneSigmaAngularVelocityError: { 'deg/s': number | '' };
  oneSigmaPositionError: { km: number | '' };
  oneSigmaVelocityError: { 'km/s': number | '' };
  specifyAttitude: boolean;
  attitude?: (number | '')[];
  attitudeErrorSigma?: { deg: number | '' };
  angularVelocity?: (number | '')[];
  angularVelocityErrorSigma?: { 'deg/s': number | '' };
  defaultAngularVelocitySigma?: { 'deg/s': number | '' };
  initializer?: ISelectOption | '';
  processNoise?: number | '';
}

const defaultValues: IForm = {
  name: '',
  rate: '',
  type: '',
  gainK: 0.05,
  gainG: 0.005,
  gainC: 0.01,
  gainP: 0.1,
  gainI: 0,
  gainD: 10,
  epsilon: 0.1,
  positionSensor: '',
  thrusters: [],
  oneSigmaYawError: { deg: '' },
  oneSigmaPitchError: { deg: '' },
  oneSigmaRollError: { deg: '' },
  oneSigmaAngularVelocityError: { 'deg/s': '' },
  oneSigmaPositionError: { km: '' },
  oneSigmaVelocityError: { 'km/s': '' },
  specifyAttitude: false,
  attitude: [0, 0, 0, 1],
  attitudeErrorSigma: { deg: 45 },
  angularVelocity: [0, 0, 0],
  angularVelocityErrorSigma: { 'deg/s': 10 },
  defaultAngularVelocitySigma: { 'deg/s': 10 },
  initializer: '',
  processNoise: '',
};

const tableColumns = [
  {
    title: 'Name',
    field: 'name',
    align: 'left',
    type: 'string',
    editable: 'never',
  },
];

const AlgorithmDialog = (props: IProps) => {
  // Handle props
  const { control } = props;
  const {
    dialogConfig: { entity: algorithm, action },
  } = control;
  const {
    actuators,
    angularVelocitySensors,
    directionSensors,
    vectorSensors,
    opticalAttitudeSensors,
    positionSensors,
    algorithms: allAlgorithms,
    model,
  } = useActiveEntities();
  const attitudeControlActuators = useMemo(() => model.Magnetorquer.all().concat(model.ReactionWheel.all()), [model]);
  const getBlockSupers = useBlockSupers();

  // Set up styles
  const classes = useStyles();

  /*********************************************************************************************/
  /***************************************** RELATIONS *****************************************/
  /*********************************************************************************************/

  // Prep actuators
  const actuatorTableRef = useRef(null);
  const {
    initBlocks: initActuators,
    parsedBlocks: parsedActuators,
    setParsedBlocks: setParsedActuators,
  } = useSelectBlocks(
    attitudeControlActuators,
    algorithm?.actuators,
    useMemo(() => ['type'], [])
  );

  // Prep angular velocity sensors
  const angularVelocitySensorTableRef = useRef(null);
  const {
    initBlocks: initAngularVelocitySensors,
    parsedBlocks: parsedAngularVelocitySensors,
    setParsedBlocks: setParsedAngularVelocitySensors,
  } = useSelectBlocks(angularVelocitySensors, algorithm?.angularVelocitySensors);

  // Prep optical attitude sensors
  const opticalAttitudeSensorTableRef = useRef(null);
  const {
    initBlocks: initOpticalAttitudeSensors,
    parsedBlocks: parsedOpticalAttitudeSensors,
    setParsedBlocks: setParsedOpticalAttitudeSensors,
  } = useSelectBlocks(opticalAttitudeSensors, algorithm?.opticalAttitudeSensors);

  // Prep position sensors
  const positionSensorTableRef = useRef(null);
  const {
    initBlocks: initPositionSensors,
    parsedBlocks: parsedPositionSensors,
    setParsedBlocks: setParsedPositionSensors,
  } = useSelectBlocks(positionSensors, algorithm?.positionSensors);

  // Prep direction/vector sensors
  const vectorSensorTableRef = useRef(null);
  const vectorAndDirection = useMemo(() => {
    return vectorSensors.concat(directionSensors);
  }, [vectorSensors, directionSensors]);

  const {
    initBlocks: initVectorSensors,
    parsedBlocks: parsedVectorSensors,
    setParsedBlocks: setParsedVectorSensors,
  } = useSelectBlocks(vectorAndDirection, algorithm?.vectorSensors);

  const {
    initBlocks: initDirectionSensors,
    parsedBlocks: parsedDirectionSensors,
    setParsedBlocks: setParsedDirectionSensors,
  } = useSelectBlocks(directionSensors, algorithm?.directionSensors);

  // Prep thrusters
  const thrusterTableRef = useRef(null);
  const getThrusters = useMemo(
    () => actuators.filter((a) => a.type === ActuatorVables.Type.Thruster.value),
    [actuators]
  );
  const {
    initBlocks: initThrusters,
    parsedBlocks: parsedThrusters,
    setParsedBlocks: setParsedThrusters,
  } = useSelectBlocks(getThrusters, algorithm?.thrusters);

  // Compile a schema of all the tables that should be rendered based on the algorithm type
  const getRelTables = useCallback(
    (type: string) => {
      if (!type) return null;
      const tables = [];
      const supers = getBlockSupers(type);

      // Thrust control algos
      if (supers.includes(AlgorithmVables.Super.ThrustControlAlgorithm.value)) {
        tables.push({
          header: 'Thrusters',
          tableRef: thrusterTableRef,
          dataSideColumn: {
            title: 'Thrust (N)',
            field: 'dataSide.thrust',
            type: 'numeric',
            align: 'left',
            editable: true,
            default: 0,
          },
          data: parsedThrusters,
          setData: setParsedThrusters,
          field: 'thrusters',
        });
      }

      // Attitude control algos
      if (supers.includes(AlgorithmVables.Super.AttitudeControlAlgorithm.value)) {
        tables.push({
          header: 'Actuators',
          tableRef: actuatorTableRef,
          data: parsedActuators.filter((actuator) => {
            // @ts-ignore-next-line
            const supers = getBlockSupers(actuator.type);
            // Thrusters, MagneticHysteresisRods, and PermanentDipoleMagnets not allowed
            return !supers.includes('Magnet') && !supers.includes('Thruster');
          }),
          setData: setParsedActuators,
          field: 'actuators',
        });
      }

      // Attitude determination + attitude initializer algos
      if (
        [
          AlgorithmVables.Type.TriadAlgorithm.value,
          AlgorithmVables.Type.AveragingAlgorithm.value,
        ].includes(type as TAlgorithmTypes)
      ) {
        tables.push({
          header: 'Angular Velocity Sensors',
          tableRef: angularVelocitySensorTableRef,
          data: parsedAngularVelocitySensors,
          setData: setParsedAngularVelocitySensors,
          field: 'angularVelocitySensors',
        });
      }
      if (
        [
          AlgorithmVables.Type.TriadAttitudeInitializer.value,
          AlgorithmVables.Type.DirectMeasurementAttitudeInitializer.value,
          AlgorithmVables.Type.MekfAlgorithm.value,
        ].includes(type as TAlgorithmTypes)
      ) {
        tables.push({
          header: 'Angular Velocity Sensors',
          tableRef: angularVelocitySensorTableRef,
          dataSideColumn: {
            title: 'Assumed Measurement Uncertainty (rad/s)',
            field: 'dataSide.oneSigmaAngularVelocityError.rad/s',
            type: 'numeric',
            align: 'left',
            editable: true,
            default: 0.01,
          },
          data: parsedAngularVelocitySensors,
          setData: setParsedAngularVelocitySensors,
          field: 'angularVelocitySensors',
        });
      }
      if (type === AlgorithmVables.Type.AveragingAlgorithm.value) {
        tables.push({
          header: 'Attitude Sensors',
          tableRef: opticalAttitudeSensorTableRef,
          data: parsedOpticalAttitudeSensors,
          setData: setParsedOpticalAttitudeSensors,
          field: 'opticalAttitudeSensors',
        });
      }
      if (
        [
          AlgorithmVables.Type.DirectMeasurementAttitudeInitializer.value,
          AlgorithmVables.Type.MekfAlgorithm.value,
        ].includes(type as TAlgorithmTypes)
      ) {
        tables.push({
          header: 'Attitude Sensors',
          tableRef: opticalAttitudeSensorTableRef,
          dataSideColumn: {
            title: 'Assumed Measurement Uncertainty (rad)',
            field: 'dataSide.oneSigmaAngleError.rad',
            type: 'numeric',
            align: 'left',
            editable: true,
            default: 0.01,
          },
          data: parsedOpticalAttitudeSensors,
          setData: setParsedOpticalAttitudeSensors,
          field: 'opticalAttitudeSensors',
        });
      }
      if (type === AlgorithmVables.Type.TriadAlgorithm.value) {
        tables.push({
          header: 'Vector and Direction Sensors',
          tableRef: vectorSensorTableRef,
          data: parsedVectorSensors,
          setData: setParsedVectorSensors,
          field: 'vectorSensors',
        });
      }
      if (
        [
          AlgorithmVables.Type.TriadAttitudeInitializer.value,
          AlgorithmVables.Type.MekfAlgorithm.value,
        ].includes(type as TAlgorithmTypes)
      ) {
        tables.push({
          header: `Vector ${
            type !== AlgorithmVables.Type.MekfAlgorithm.value ? 'and Direction ' : ''
          }Sensors`,
          tableRef: vectorSensorTableRef,
          dataSideColumn: {
            title: 'Assumed Measurement Uncertainty (rad)',
            field: 'dataSide.oneSigmaAngleError.rad',
            type: 'numeric',
            align: 'left',
            editable: true,
            default: 0.01,
          },
          data: parsedVectorSensors.filter(
            (sensor) =>
              // For MEKF algos, only vector sensors are allowed here, not direction
              type !== AlgorithmVables.Type.MekfAlgorithm.value ||
              vectorSensors.find((vs) => vs.id === sensor.id)
          ),
          setData: setParsedVectorSensors,
          field: 'vectorSensors',
        });
      }
      if (type === AlgorithmVables.Type.MekfAlgorithm.value) {
        tables.push({
          header: 'Direction Sensors',
          tableRef: vectorSensorTableRef,
          dataSideColumn: {
            title: 'Assumed Measurement Uncertainty (rad)',
            field: 'dataSide.oneSigmaAngleError.rad',
            type: 'numeric',
            align: 'left',
            editable: true,
            default: 1,
          },
          data: parsedDirectionSensors,
          setData: setParsedDirectionSensors,
          field: 'directionSensors',
        });
      }

      // Orbit determination + orbit initializer algos
      if (
        [
          AlgorithmVables.Type.EkfAlgorithm.value,
          AlgorithmVables.Type.FiniteDifferenceOrbitInitializer.value,
        ].includes(type as TAlgorithmTypes)
      ) {
        tables.push({
          header: 'Position Sensors',
          tableRef: positionSensorTableRef,
          dataSideColumn: {
            title: 'Assumed Measurement Uncertainty (km)',
            field: 'dataSide.oneSigmaDistanceError.km',
            type: 'numeric',
            align: 'left',
            editable: true,
            default: 0.001,
          },
          data: parsedPositionSensors,
          setData: setParsedPositionSensors,
          field: 'positionSensors',
        });
      }
      // REF: positionSensor: This is changed from a table to a select
      // if (type === AlgorithmVables.Type.GpsAlgorithm.value) {
      //   tables.push({
      //     header: 'Position Sensor',
      //     tableRef: positionSensorTableRef,
      //     data: parsedPositionSensors,
      //     setData: setParsedPositionSensors,
      //     field: 'positionSensors',
      //   });
      // }

      return tables;
    },
    [
      setParsedThrusters,
      setParsedActuators,
      setParsedAngularVelocitySensors,
      setParsedOpticalAttitudeSensors,
      setParsedPositionSensors,
      setParsedVectorSensors,
      setParsedDirectionSensors,
      parsedThrusters,
      parsedActuators,
      parsedAngularVelocitySensors,
      parsedOpticalAttitudeSensors,
      parsedPositionSensors,
      parsedVectorSensors,
      parsedDirectionSensors,
      vectorSensors,
      getBlockSupers,
    ]
  );

  // Set up select options
  const options = useMemo(
    () => ({
      // For type, group options by Attitude Control, Attitude Determination, Orbit Determination, and Thrust Control
      type: [
        createNestedOption(
          AlgorithmVables.Type.options.filter((o) => isAc({ type: o })),
          'Attitude Control',
          'type',
          'type',
          [],
          {},
          false
        ),
        createNestedOption(
          AlgorithmVables.Type.options.filter((o) => isAd({ type: o })),
          'Attitude Determination',
          'type',
          'type',
          [],
          {},
          false
        ),
        createNestedOption(
          AlgorithmVables.Type.options.filter((o) => isOd({ type: o })),
          'Orbit Determination',
          'type',
          'type',
          [],
          {},
          false
        ),
        createNestedOption(
          AlgorithmVables.Type.options.filter((o) => isTc({ type: o })),
          'Thrust Control',
          'type',
          'type',
          [],
          {},
          false
        ),
      ],
      positionSensor: positionSensors.map((c) => {
        return { value: c.id, label: c.name };
      }),
      initializer: allAlgorithms
        .filter((a) => a.type.toLowerCase().includes('initializer'))
        .map((c) => {
          return { value: c.id, label: c.name, type: c.type };
        }),
    }),
    [positionSensors, allAlgorithms]
  );

  /*********************************************************************************************/
  /*********************************************************************************************/

  // REF: positionSensor
  // GPS Orbit Determination algorithms have one positionSensor
  // Translate that into its own field here so it can be interacted with as a LabeledSelect
  const customTranslateIn = useCallback((algorithm, defaultValues, options) => {
    if (
      algorithm.type === AlgorithmVables.Type.GpsAlgorithm.value &&
      algorithm.positionSensors.length > 0
    )
      algorithm.positionSensor = algorithm.positionSensors[0].id;

    if (
      algorithm.type === AlgorithmVables.Type.DirectMeasurementAttitudeInitializer.value &&
      algorithm.attitudeSensors.length > 0
    )
      algorithm.opticalAttitudeSensors = algorithm.attitudeSensors;

    // Specify initial attitude, translate what's needed
    if (algorithm.type === AlgorithmVables.Type.StaticAttitudeInitializer.value) {
      if (algorithm.initialAngularVelocity?.['rad/s'])
        algorithm.initialAngularVelocity = {
          'deg/s': rad2Deg(algorithm.initialAngularVelocity['rad/s']),
        };
      if (
        (algorithm.initialAttitude &&
          !_.isEqual(algorithm.initialAttitude, defaultValues.attitude)) ||
        (algorithm.initialAngularVelocity &&
          !_.isEqual(algorithm.initialAngularVelocity, {
            'deg/s': defaultValues.angularVelocity,
          })) ||
        (algorithm.attitudeErrorSigma &&
          !_.isEqual(algorithm.attitudeErrorSigma, defaultValues.attitudeErrorSigma)) ||
        (algorithm.angularVelocityErrorSigma &&
          !_.isEqual(algorithm.angularVelocityErrorSigma, defaultValues.angularVelocityErrorSigma))
      ) {
        algorithm.specifyAttitude = true;
        algorithm.attitude = algorithm.initialAttitude;
        algorithm.angularVelocity = algorithm.initialAngularVelocity?.['deg/s'];
      }
    }

    // Translate in dataSide for thruster algorithm
    return translateIn(algorithm, defaultValues, options);
  }, []);

  const customTranslateOut = useCallback(
    (algorithm, allowedEmptyFields, options) => {
      const relTables = getRelTables(algorithm.type.value);

      // Pair up table relations with their field names
      const relsAndNames = _.zip(
        [
          parsedActuators,
          parsedAngularVelocitySensors,
          parsedDirectionSensors,
          parsedOpticalAttitudeSensors,
          parsedPositionSensors,
          parsedThrusters,
          parsedVectorSensors,
        ].map((rels) => rels.filter((rel) => rel.tableData?.checked)),
        [
          'actuators',
          'angularVelocitySensors',
          'directionSensors',
          'opticalAttitudeSensors',
          'positionSensors',
          'thrusters',
          'vectorSensors',
        ]
      ) as [ITableBlock[], string][];

      // Format field names and their values, depending on whether they are DataSide or ManySide relations
      relsAndNames.forEach(([rels, name]) => {
        const tableDef = relTables?.find((table) => table.field === name);

        // Unrelated
        if (!tableDef) {
          algorithm[name] = undefined;
          return;
        }

        // ManySide relations: list of IDs
        if (!tableDef.dataSideColumn) {
          algorithm[name] = rels.map((rel) => rel.id);
        }

        // DataSide relations: map of IDs to their dataSide values
        else {
          algorithm[name] = rels.reduce((acc, rel) => {
            acc[rel.id] = rel.dataSide;
            return acc;
          }, {} as IGenericObject);
        }
      });

      // Adjust fields with outlier names and behavior

      // DirectMeasurementAttitudeInitializer algorithms call opticalAttitudeSensors "attitudeSensors"
      if (
        algorithm.type.value === AlgorithmVables.Type.DirectMeasurementAttitudeInitializer.value
      ) {
        algorithm.attitudeSensors = algorithm.opticalAttitudeSensors;
        delete algorithm.opticalAttitudeSensors;
      }

      // REF: positionSensor
      // ... and translate (positionSensor = sensor) back out into (positionSensors = [sensor])
      if (
        algorithm.type.value === AlgorithmVables.Type.GpsAlgorithm.value &&
        algorithm.positionSensor
      )
        algorithm.positionSensors = [algorithm.positionSensor.value];

      if (algorithm.type.value === AlgorithmVables.Type.StaticAttitudeInitializer.value) {
        if (algorithm.specifyAttitude) {
          algorithm.initialAttitude = algorithm.attitude;
          algorithm.initialAngularVelocity = { 'deg/s': algorithm.angularVelocity };
        } else {
          algorithm.initialAttitude = defaultValues.attitude;
          algorithm.initialAngularVelocity = { 'deg/s': defaultValues.angularVelocity };
          algorithm.attitudeErrorSigma = defaultValues.attitudeErrorSigma;
          algorithm.angularVelocityErrorSigma = defaultValues.angularVelocityErrorSigma;
        }
      }

      return translateOut(algorithm, allowedEmptyFields, options);
    },
    [
      getRelTables,
      parsedActuators,
      parsedPositionSensors,
      parsedAngularVelocitySensors,
      parsedOpticalAttitudeSensors,
      parsedVectorSensors,
      parsedThrusters,
      parsedDirectionSensors,
    ]
  );

  const entityForm = useEntityForm<IAlgorithm, IForm>({
    entityTypeText: 'Algorithm',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    extendReset: () => {
      initActuators();
      initAngularVelocitySensors();
      initDirectionSensors();
      initOpticalAttitudeSensors();
      initPositionSensors();
      initThrusters();
      initVectorSensors();
    },
    valuesToRemove: ['attitude', 'angularVelocity'],
    formikOptionalParams: {
      useGuidance,
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
      allowedEmptyFields: [
        'actuators',
        'angularVelocitySensors',
        'attitudeSensors',
        'directionSensors',
        'opticalAttitudeSensors',
        'positionSensors',
        'thrusters',
        'vectorSensors',
      ],
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setFieldValue, setValues } = formik;
  const [customDirty, setCustomDirty] = useState(false);

  const relTables = useMemo(
    () => getRelTables(typeof values.type === 'string' ? '' : values.type.value),
    [getRelTables, values.type]
  );
  const supers = useMemo(
    () => getBlockSupers(typeof values.type === 'string' ? '' : values.type.value),
    [getBlockSupers, values.type]
  );

  // Reset all tables when the algorithm type changes
  const resetTables = useCallback(() => {
    setValues((prev) => ({
      ...prev,
      thrusters: [],
      actuators: [],
      angularVelocitySensors: [],
      opticalAttitudeSensors: [],
      positionSensors: [],
      vectorSensors: [],
      directionSensors: [],
      attitudeSensors: [],
    }));
    initActuators();
    initAngularVelocitySensors();
    initOpticalAttitudeSensors();
    initPositionSensors();
    initVectorSensors();
    initDirectionSensors();
    initThrusters();
  }, [
    setValues,
    initActuators,
    initAngularVelocitySensors,
    initOpticalAttitudeSensors,
    initPositionSensors,
    initVectorSensors,
    initDirectionSensors,
    initThrusters,
  ]);

  return (
    <EntityDialog entityForm={entityForm} customDirty={customDirty} xlarge>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Algorithm Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          {/* <LabeledInput  // TODO: Re-enable this when update rate works
            {...getFieldProps('rate')}
            label="Update Rate"
            type="number"
            endAdornment={<InputAdornment position="end">Hz</InputAdornment>}
          /> */}
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('type')}
            label="Algorithm Type"
            options={options.type}
            isDisabled={action !== 'create'}
            formikOnChange={(selection: ISelectOption) => {
              setFieldValue('type', selection);
              resetTables();
            }}
            updateGuidanceOnChange
          />
          {supers.includes(AlgorithmVables.Super.AttitudeDeterminationAlgorithm.value) && (
            <LabeledInput {...getFieldProps('processNoise')} label="Process Noise" type="number" />
          )}
          {supers.includes(AlgorithmVables.Super.OrbitDeterminationAlgorithm.value) && (
            <LabeledInput {...getFieldProps('processNoise')} label="Process Noise" type="number" />
          )}
          {values.type && (
            <>
              {values.type === AlgorithmVables.Type.SlidingModeAlgorithm && (
                <div className={classes.indent}>
                  <LabeledInput {...getFieldProps('gainK')} label="Gain K" type="number" />
                  <LabeledInput {...getFieldProps('gainG')} label="Gain G" type="number" />
                  <LabeledInput {...getFieldProps('gainC')} label="Gain C" type="number" />
                  <LabeledInput {...getFieldProps('epsilon')} label="Epsilon" type="number" />
                </div>
              )}
              {values.type === AlgorithmVables.Type.PidAlgorithm && (
                <div className={classes.indent}>
                  <LabeledInput {...getFieldProps('gainP')} label="Gain P" type="number" />
                  <LabeledInput {...getFieldProps('gainI')} label="Gain I" type="number" />
                  <LabeledInput {...getFieldProps('gainD')} label="Gain D" type="number" />
                  <LabeledInput {...getFieldProps('gainC')} label="Gain C" type="number" />
                </div>
              )}
              {values.type === AlgorithmVables.Type.GenericAdAlgorithm && (
                <div className={classes.indent}>
                  <LabeledInput
                    {...getFieldProps('oneSigmaYawError.deg')}
                    label="One Sigma Yaw Error"
                    type="number"
                    endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  />
                  <LabeledInput
                    {...getFieldProps('oneSigmaPitchError.deg')}
                    label="One Sigma Pitch Error"
                    type="number"
                    endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  />
                  <LabeledInput
                    {...getFieldProps('oneSigmaRollError.deg')}
                    label="One Sigma Roll Error"
                    type="number"
                    endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  />
                  <LabeledInput
                    {...getFieldProps('oneSigmaAngularVelocityError.deg/s')}
                    label="One Sigma Angular Velocity Error"
                    type="number"
                    endAdornment={<InputAdornment position="end">deg/s</InputAdornment>}
                  />
                </div>
              )}
              {values.type === AlgorithmVables.Type.GenericOdAlgorithm && (
                <div className={classes.indent}>
                  <LabeledInput
                    {...getFieldProps('oneSigmaPositionError.km')}
                    label="One Sigma Position Error"
                    type="number"
                    endAdornment={<InputAdornment position="end">km</InputAdornment>}
                  />
                  <LabeledInput
                    {...getFieldProps('oneSigmaVelocityError.km/s')}
                    label="One Sigma Velocity Error"
                    type="number"
                    endAdornment={<InputAdornment position="end">km/s</InputAdornment>}
                  />
                </div>
              )}
              {supers.includes(AlgorithmVables.Super.AttitudeInitializer.value) && (
                <>
                  {values.type.value !== AlgorithmVables.Type.StaticAttitudeInitializer.value ? (
                    <LabeledInput
                      {...getFieldProps('defaultAngularVelocitySigma.deg/s')}
                      label="Default Angular Velocity Sigma"
                      type="number"
                      endAdornment={<InputAdornment position="end">deg/s</InputAdornment>}
                    />
                  ) : (
                    <>
                      <LabeledCheckbox
                        {...getFieldProps('specifyAttitude')}
                        label={'Specify initial attitude'}
                        formikOnChange={() => {
                          setFieldValue('attitude', defaultValues.attitude);
                          setFieldValue('angularVelocity', defaultValues.angularVelocity);
                          setFieldValue('attitudeErrorSigma', defaultValues.attitudeErrorSigma);
                          setFieldValue(
                            'angularVelocityErrorSigma',
                            defaultValues.angularVelocityErrorSigma
                          );
                        }}
                      ></LabeledCheckbox>
                      {values.specifyAttitude && (
                        <div className={classes.inputGroup}>
                          <AttitudeForm entityForm={entityForm} deg />
                          <div className={classes.indent}>
                            <LabeledInput
                              {...getFieldProps('attitudeErrorSigma.deg')}
                              label="Attitude Estimate Error"
                              type="number"
                              endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                            />
                            <LabeledInput
                              {...getFieldProps('angularVelocityErrorSigma.deg/s')}
                              label="Angular Velocity Estimate Error"
                              type="number"
                              endAdornment={<InputAdornment position="end">deg/s</InputAdornment>}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {values.type === AlgorithmVables.Type.GpsAlgorithm && (
        <div className={classes.inputs}>
          <GncAccent header={'Position Sensor'}>
            <LabeledSelect {...getFieldProps('positionSensor')} options={options.positionSensor} />
          </GncAccent>
        </div>
      )}
      {(values.type === AlgorithmVables.Type.MekfAlgorithm ||
        values.type === AlgorithmVables.Type.EkfAlgorithm) && (
        <div className={classes.inputs}>
          <GncAccent header={'Initializer'}>
            <LabeledSelect
              {...getFieldProps('initializer')}
              options={options.initializer.filter((initializer) =>
                values.type === AlgorithmVables.Type.MekfAlgorithm
                  ? getBlockSupers(initializer.type).includes(
                      AlgorithmVables.Super.AttitudeInitializer.value
                    )
                  : getBlockSupers(initializer.type).includes(
                      AlgorithmVables.Super.OrbitInitializer.value
                    )
              )}
            />
          </GncAccent>
        </div>
      )}
      {
        // Render all the tables based on the relTables schema, which is based on the algorithm type
        relTables?.map((table) => (
          <GncAccent header={table.header} key={table.header}>
            <WidgetTable
              tableRef={table.tableRef}
              className={classes.table}
              columns={
                // Some tables have a dataSideColumn, for editing the field for a DataSide relation
                [...tableColumns, ...(table.dataSideColumn ? [table.dataSideColumn] : [])]
              }
              data={table.data}
              setData={(data) => {
                table.setData(data);
                setCustomDirty(true);
              }}
              emptyMessage={`No ${table.header.toLowerCase()} found`}
              title={`Select ${table.header}`}
              search={true}
              selection={true}
              onSelectionChange={(newTableData) => {
                if (table.dataSideColumn) {
                  table.setData((prev) =>
                    prev.map((block: ITableBlock) => {
                      if (block.tableData?.checked) {
                        return _.set(
                          {
                            ...block,
                          },
                          table.dataSideColumn.field,
                          _.get(block, table.dataSideColumn.field) || table.dataSideColumn.default
                        );
                      } else {
                        return {
                          ...block,
                          dataSide: undefined,
                        };
                      }
                    })
                  );
                }
                return setFieldValue(table.field, newTableData);
              }}
            />
          </GncAccent>
        ))
      }
    </EntityDialog>
  );
};

export default AlgorithmDialog;
