import { Box } from '@material-ui/core';
import { wGroupIndicesATPower } from 'components/AgentTemplateEditView/menu/power';
import { FallbackInlay } from 'components/general/ViewPortInlay';
import WGroup from 'components/general/WGroup';
import { useActiveEntities } from 'hooks';
import { ErrorBoundary } from 'react-error-boundary';
import SubsystemLoadingSummary from './SubsystemLoadingSummary';
import SubsystemLoadingWizard from './SubsystemLoadingWizard';

const SubsystemLoadingEditBoard = () => {
  const { leafRoutines } = useActiveEntities();

  return (
    <WGroup index={wGroupIndicesATPower.POWER_SSL}>
      <Box display="flex" flexWrap="wrap">
        <SubsystemLoadingWizard />
        <ErrorBoundary
          fallback={
            <FallbackInlay text="An error occurred while processing the Subsystem Loading summary." />
          }
        >
          {leafRoutines.length > 0 && <SubsystemLoadingSummary />}
        </ErrorBoundary>
      </Box>
    </WGroup>
  );
};

export default SubsystemLoadingEditBoard;
