import BranchDialog from 'components/BranchesView/BranchExplorer/BranchDialog';
import { setActiveWorkspaceId } from 'components/RootView/missionExplorerSlice';
import LoadingInlay from 'components/general/LoadingInlay';
import MetamodelTypeIcon from 'components/general/MetamodelTypeIcon';
import RowedExplorer from 'components/general/RowedExplorer';
import { IErrorResponse, IMissionVersion } from 'components/general/types';
import { useEntityDialogControl, useSelectById, useSelectByIds, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import BranchRow from './BranchRow';
import ChangesDialog from './ChangesDialog';
import CommitDialog from './CommitDialog';
import CompareDialog from './CompareDialog';
import HistoryDialog from './HistoryDialog';
import MergeDialog from './MergeDialog';
import useGitChanges from './useGitChanges';
import useGitHistory from './useGitHistory';

export interface ICompareDialogControl {
  open: boolean;
  currentBranch?: IMissionVersion;
  action?: 'compare' | 'merge';
}

const BranchExplorer = () => {
  const { Mission } = SatelliteApi;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const repo = useSelectById('Mission', id);
  const workspace = repo?.workspace;
  const project = repo?.project;
  const branches = useSelectByIds('MissionVersion', repo?.versions || []);
  const [compareConfig, setCompareConfig] = useState<ICompareDialogControl>({ open: false });
  const [openBranchId, setOpenBranchId] = useState('');
  const [loading, setLoading] = useState(false);

  const mergeDialogControl = useEntityDialogControl<IMissionVersion>();
  const branchDialogControl = useEntityDialogControl<IMissionVersion>();
  // this hook is designed typically for entities, but is intentially used with other data types here
  const commitDialogControl = useEntityDialogControl<number>();

  const { historyDialogControl, fetchGitHist, commitHist, loadingGitHistBrId } = useGitHistory();
  const { changesDialogControl, fetchGitChanges, editedAndCommittedData, branchLoadingGitChanges } =
    useGitChanges();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (repo && (!repo?.versions || repo?.versions.length === branches.length)) return;
    setLoading(true);
    // if repo or any of its branches not in store (condition above not met), fetch repo from backend
    dispatch(
      Mission.actions.getMission({
        id,
        successCallback: () => setLoading(false),
        failureCallback: (response: IErrorResponse) => {
          const message =
            response.error.code === 'RESOURCE_NOT_FOUND'
              ? 'The repository does not exist or is not accessible from this account. Please select a different repository.'
              : response.error.message;
          enqueueSnackbar(message);
          setLoading(false);
          history.goBack();
        },
      })
    );
  }, [dispatch, branches, repo, Mission, enqueueSnackbar, history, id]);

  useEffect(() => {
    if (workspace) {
      dispatch(setActiveWorkspaceId(workspace));
    }
  }, [workspace, dispatch]);

  if (!repo) return <></>;

  return (
    <>
      <RowedExplorer
        name={
          <>
            {repo.name} <MetamodelTypeIcon metamodelType={repo.metamodelType} />
          </>
        }
        description={repo.description}
        backButtonProps={
          project
            ? {
                text: 'Back to Project',
                onClick: () => history.push(Routes.PROJECT(project)),
              }
            : {
                text: 'Back to Workspace',
                onClick: () => history.push(Routes.WORKSPACE(workspace, 'repositories')),
              }
        }
        buttonsProps={[
          {
            text: 'Compare Branches',
            onClick: () => setCompareConfig({ open: true, action: 'compare' }),
            framed: true,
          },
        ]}
      >
        {loading && <LoadingInlay text="Your branches are loading" />}
        {!loading &&
          branches.map((branch) => (
            <BranchRow
              key={branch.id}
              branch={branch}
              openBranchDialog={branchDialogControl.openDialogForExisting}
              openMergeDialog={mergeDialogControl.openDialogForExisting}
              openCommitDialog={commitDialogControl.openDialogForExisting}
              fetchGitHistAndOpenDialog={fetchGitHist}
              loadingGitHistBrId={loadingGitHistBrId}
              fetchGitChangesAndOpenDialog={fetchGitChanges}
              branchLoadingGitChanges={branchLoadingGitChanges}
              openBranchId={openBranchId}
              setOpenBranchId={setOpenBranchId}
            />
          ))}
      </RowedExplorer>

      <CompareDialog
        branches={branches}
        compareConfig={compareConfig}
        setCompareConfig={setCompareConfig}
      />
      <ChangesDialog
        editedAndCommittedData={editedAndCommittedData}
        dialogControl={changesDialogControl}
      />
      <MergeDialog dialogControl={mergeDialogControl} />
      <CommitDialog dialogControl={commitDialogControl} />
      <BranchDialog dialogControl={branchDialogControl} />
      <HistoryDialog dialogControl={historyDialogControl} commitHist={commitHist} />
    </>
  );
};

export default BranchExplorer;
