import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, useMediaQuery } from '@material-ui/core';
import { DEMO_WORKSPACE_BOOKMARKS } from 'config';
import { useUser } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import theme from 'theme';
import { width } from '../Nav/styles';
import Bookmark from './Bookmark';
import useStyles, { bookmarkMargin, bookmarkWidth } from './styles';

const BookmarkBar = () => {
  const classes = useStyles();
  const [bookmarks, setBookmarks] = useState([]);
  const user = useUser();
  const mdScreen = useMediaQuery(
    theme.breakpoints.up(2 * (bookmarkWidth + bookmarkMargin * 2) + width)
  );
  const lgScreen = useMediaQuery(
    theme.breakpoints.up(3 * (bookmarkWidth + bookmarkMargin * 2) + width)
  );
  const mdlgScreen = useMediaQuery(
    theme.breakpoints.up(4 * (bookmarkWidth + bookmarkMargin * 2) + width)
  );
  const xlScreen = useMediaQuery(
    theme.breakpoints.up(5 * (bookmarkWidth + bookmarkMargin * 2) + width)
  );
  const pageLength = useMemo(() => {
    if (xlScreen) return 5;
    if (mdlgScreen) return 4;
    if (lgScreen) return 3;
    if (mdScreen) return 2;
    return 1;
  }, [xlScreen, mdlgScreen, lgScreen, mdScreen]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (user) {
      const userData = JSON.parse(localStorage.getItem(user.id) || '{}');
      const storedBookmarks = userData.bookmarks;
      if (!storedBookmarks) {
        const default_bookmarks = DEMO_WORKSPACE_BOOKMARKS.map((id) => ({ id: id, pinned: false }));
        localStorage.setItem(
          user.id,
          JSON.stringify({
            ...userData,
            bookmarks: default_bookmarks,
          })
        );
        setBookmarks(default_bookmarks);
      } else {
        setBookmarks(
          storedBookmarks.map(({ id, pinned }) => {
            return { id, pinned };
          })
        );
      }
    }
  }, [user]);


  return bookmarks.length > 0 ? (
    <div className={classes.root}>
      <div className={classes.bookmarkBar + ' joyride-bookmarks'}>
        {bookmarks.slice(page, page + pageLength).map((bookmark) => {
          const { id, pinned } = bookmark;
          return (
            <Bookmark
              key={id}
              branchId={id}
              pinned={pinned}
              onBranchNotFound={() => {
                if (id && user?.id) {
                  const userData = JSON.parse(localStorage.getItem(user.id) || '{}');
                  const storedBookmarks = userData.bookmarks;
                  if (storedBookmarks) {
                    const newBookmarks = storedBookmarks.filter((b) => b.id !== id);
                    setBookmarks(newBookmarks);
                    localStorage.setItem(
                      user.id,
                      JSON.stringify({
                        ...userData,
                        bookmarks: newBookmarks,
                      })
                    );
                  }
                }
              }}
              onPinClick={(pin) => {
                if (id && user?.id) {
                  const userData = JSON.parse(localStorage.getItem(user.id) || '{}');
                  const storedBookmarks = userData.bookmarks;
                  if (storedBookmarks) {
                    const newBookmarks = pin
                      ? [{ id, pinned: pin }, ...storedBookmarks.filter((b) => b.id !== id)]
                      : [
                          ...storedBookmarks.filter((b) => b.id !== id && b.pinned),
                          { id, pinned: pin },
                          ...storedBookmarks.filter((b) => b.id !== id && !b.pinned),
                        ];
                    setBookmarks(newBookmarks);
                    localStorage.setItem(
                      user.id,
                      JSON.stringify({
                        ...userData,
                        bookmarks: newBookmarks,
                      })
                    );
                  }
                }
              }}
            />
          );
        })}
        {page > 0 && (
          <IconButton
            className={classes.leftButton}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            <div>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          </IconButton>
        )}
        {page + pageLength < bookmarks.length && (
          <IconButton
            className={classes.rightButton}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <div>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </IconButton>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default BookmarkBar;
