import { IMenu } from 'components/general/types';
import { ITEMS, SUB_ITEMS, TITLE } from 'config';
import Routes, { routePathsCommon } from 'routes';

const { EDIT } = routePathsCommon;

const AGENTS = 'agents';
const AGENTGROUPS = 'agent-groups';
const TIME = 'time';

const ScenarioEditMenu: IMenu = {
  routeFn: Routes.SCENARIO,
  dynamicItems: [],
  [ITEMS]: {
    [EDIT]: {
      [TITLE]: 'Edit',
      [SUB_ITEMS]: {
        [TIME]: { [TITLE]: 'Time' },
        [AGENTS]: { [TITLE]: 'Agents' },
        [AGENTGROUPS]: { [TITLE]: 'Agent Groups' },
      },
    },
  },
};

export const wGroupIndicesScenario = {
  EDIT: `${EDIT}*`,
  EDIT_TIME: `${EDIT}.${TIME}`,
  EDIT_AGENTS: `${EDIT}.${AGENTS}`,
  EDIT_AGENTGROUPS: `${EDIT}.${AGENTGROUPS}`,
};

export default ScenarioEditMenu;
