import { IGuidanceCard } from 'components/general/types';

const guidance: IGuidanceCard = {
  heading: 'Model Types',
  body: [
    {
      subHeading: 'Agent Template',
      chunk:
        "Spacecraft - A Spacecraft model is a digital twin template which operates outside of Earth's atmosphere. It has dedicated operational and pointing logic as well as components which model the production and processing of solar power.",
    },
    {
      chunk:
        "Terrestrial Vehicle - A Terrestrial Vehicle model is a digital twin which operates within Earth's atmosphere. This model is newer than the Spacecraft model and is still under development, so the only Terrestrial Vehicle specific block is the WaypointPath which allows for it to travel along the shortest path between two points near Earth's surface.",
    },
    {
      subHeading: 'Scenario',
      chunk:
        'A Scenario is a model of a mission. When defining a Scenario, the user will choose a time frame and combine any number of Templated Agents and simplified Peripheral Agents. After configuring the state of each agent, a Scenario model can be used to start a Simulation.',
    },
  ],
};

export default guidance;
