import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, LinearProgress } from '@material-ui/core';
import { useSelectById } from 'hooks';
import { useMemo, useState } from 'react';
import Routes, { routePathsCommon } from 'routes';
import { ModuleVables } from 'utils/vable';
import A from '../A';
import CircularProgress from '../CircularProgress';
import ClipboardCopy from '../ClipboardCopy';
import InfoBadge from '../InfoBadge';
import { IJob, IMission, IMissionVersion } from '../types';
import useStyles from './styles';

interface IBadgeProps {
  job: IJob;
}

const StyledBadge = (props: IBadgeProps) => {
  const classes = useStyles({ status: props.job.status });
  return (
    <div style={{ width: 75 }}>
      <Badge
        className={classes.styledBadge}
        badgeContent={props.job.status.split('_').reduce((str, word) => {
          return str + ' ' + word.charAt(0) + word.slice(1).toLowerCase();
        }, '')}
      />
    </div>
  );
};

interface IProps {
  job: IJob;
  sortByBranch: boolean;
  repoMap?: { [key: string]: IMission };
  branchMap?: { [key: string]: IMissionVersion };
  small: boolean;
  rowOnClick: ((job?: IJob) => void) | undefined;
  actionButton?: (job: IJob) => JSX.Element;
}

const JobRow = ({
  job,
  sortByBranch,
  repoMap,
  branchMap,
  small,
  rowOnClick,
  actionButton,
}: IProps) => {
  const classes = useStyles({ sortByBranch: sortByBranch, clickable: !!rowOnClick });
  const createdBy = useSelectById('User', job.createdBy || '');
  const [hovered, setHovered] = useState(false);

  const progress = useMemo(
    () =>
      job.dataArray
        ? job.progress?.percentComplete ||
          (100 * (job.exitTime - job.startTime)) / (job.stopTime - job.startTime)
        : 0,
    [job.progress, job.exitTime, job.startTime, job.stopTime, job.dataArray]
  );

  return (
    <div
      key={job.id}
      className={classes.jobContainer}
      onClick={rowOnClick ? () => rowOnClick(job) : undefined}
    >
      {sortByBranch ? (
        <div className={classes.statusTime}>
          <InfoBadge content={`Job ID: ${job.id}`} style={{ marginRight: 5 }} />
          <ClipboardCopy
            text={job.id}
            style={{ marginRight: 5, fontSize: 15 }}
            displayLabel={false}
          />
          <StyledBadge job={job} />
          <h3>{job.dateCreated.local().format('M/DD/YY h:mma')} </h3>
        </div>
      ) : (
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {repoMap && branchMap && (
            <h3>
              {`${repoMap[branchMap[job.branch].mission].name}: `}

              <A
                href={`/#${Routes.SCENARIO(job.branch, routePathsCommon.EDIT)}`}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={(e: MouseEvent) => e.stopPropagation()}
              >
                {branchMap[job.branch].name}
                {hovered && <FontAwesomeIcon icon={faLink} style={{ marginLeft: 3 }} />}
              </A>
            </h3>
          )}
          <div className={classes.statusTime}>
            <InfoBadge content={`Job ID: ${job.id}`} style={{ marginRight: 5 }} />
            <ClipboardCopy
              text={job.id}
              style={{ marginRight: 5, fontSize: 15 }}
              displayLabel={false}
            />
            <StyledBadge job={job} />
            <h3>
              {job.dateCreated.local().format('M/DD/YY h:mma')}
              {createdBy ? ` | Simulated by ${createdBy.firstName} ${createdBy.lastName}` : ''}
            </h3>
          </div>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          width: small ? 'auto' : '50%',
          alignItems: 'center',
        }}
      >
        {small ? (
          <CircularProgress
            size={30}
            value={progress}
            loading={job.status === ModuleVables.SimulationStatuses.PENDING.value}
            status={job.status}
          />
        ) : (
          <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              style={{ width: '100%', margin: 8 }}
            />
            {progress > 0 && (
              <h3 style={{ textAlign: 'end', width: '5ch' }}>{`${Math.round(progress)}%`}</h3>
            )}
          </span>
        )}
        {actionButton ? (
          actionButton(job)
        ) : (
          <div style={{ width: 30, height: 30, padding: 0, margin: 8 }} />
        )}
      </div>
    </div>
  );
};

export default JobRow;
