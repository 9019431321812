import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from '@material-ui/core';
import { setLatestJobId } from 'components/RootView/missionExplorerSlice';
import StyledButton from 'components/general/StyledButton';
import { SALES_EMAIL, gaEvents, hotkeys } from 'config';
import { usePermissionCheck, useSelectAll, useSelectById, useSnackbar } from 'hooks';
import useMountStatus from 'hooks/useMountStatus';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import moment from 'moment';
import { ActiveBranchContext } from 'providers';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Routes, { getSearchParams, routePathsCommon } from 'routes';
import { precision } from 'utils/units';
import { ModuleVables, TSimulationStatus, WorkspaceVables } from 'utils/vable';
import CircularProgress from '../CircularProgress';
import ClipboardCopy from '../ClipboardCopy';
import StyledDivider from '../StyledDivider';
import { IErrorResponse, IJob } from '../types';
import AlertCard from './AlertCard';
import JobMenu from './JobMenu';
import NotSimulateableDialog from './NotSimulateableDialog';
import useStyles from './styles';

export const isJobRunning = (job: IJob) =>
  job?.status === ModuleVables.SimulationStatuses.RUNNING.value ||
  job?.status === ModuleVables.SimulationStatuses.QUEUED.value ||
  job?.status === ModuleVables.SimulationStatuses.PENDING.value;

const JobControls = ({ job }: { job: IJob }) => {
  const classes = useStyles();
  const { share } = getSearchParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const canSimulate = usePermissionCheck(WorkspaceVables.Permission.RUN_SIMULATION);

  const performance = useMemo(() => {
    // These two values in days
    const start = job.startTime;
    const stop = job.stopTime;

    if (
      start !== undefined &&
      stop !== undefined &&
      job.status === ModuleVables.SimulationStatuses.SUCCEEDED.value
    ) {
      // These two values in milliseconds
      const realTimeDuration = (stop - start) * 24 * 60 * 60 * 1000;
      const simDuration = job.dateModified.diff(
        moment(job.dateStarted || job.dateCreated) // backwards compatibility
      );
      return realTimeDuration / simDuration;
    } else return undefined;
  }, [job]);

  const {
    Job: {
      actions: { abortJob },
    },
  } = SatelliteApi;

  const {
    branch: { id },
  } = useContext(ActiveBranchContext);
  const progress = useMemo(() => job.progress?.percentComplete, [job]);
  const running = useMemo(() => isJobRunning(job), [job]);
  const jobCreator = useSelectById('User', job?.createdBy || '');

  // Component data dictating when polling can run
  const isMounted = useMountStatus();
  const [loading, setLoading] = useState(false);

  // When latest job is first fetched,
  // set loading to false, and start polling if appropriate
  const [gotFirstJob, setGotFirstJob] = useState(false);
  useEffect(() => {
    if (job && !gotFirstJob && isMounted()) {
      setGotFirstJob(true);
      setLoading(false);
    }
  }, [job, gotFirstJob, isMounted, running]);

  const abortSimulation = useCallback(() => {
    setLoading(true);
    dispatch(
      abortJob({
        branchId: id,
        id: job.id,
        successCallback: () => {
          ReactGA.event(gaEvents.SIM_ABORT, {
            category: 'Simulation',
            action: 'Abort Simulation',
            label: 'Abort Simulation Button',
          });
        },
        failureCallback: (response: IErrorResponse) => {
          enqueueSnackbar(response?.error?.message || 'Error aborting job.');
          setLoading(false);
        },
      })
    );
  }, [id, job, abortJob, dispatch, enqueueSnackbar]);

  const label = useMemo(() => {
    if (job.status) {
      if (loading) {
        if (running) return 'Aborting...';
        else return 'Loading...';
      }
      return (
        ModuleVables.SimulationStatuses[job.status as TSimulationStatus]?.label || 'Loading...'
      );
    } else {
      if (loading) return 'Loading...';
      return 'Ready';
    }
  }, [job, loading, running]);

  return (
    <>
      <div className={classes.jobControl}>
        <div className={classes.toolStatus}>
          <CircularProgress
            size={30}
            value={progress ?? 0}
            loading={
              loading ||
              job.status === ModuleVables.SimulationStatuses.PENDING.value ||
              job.status === ModuleVables.SimulationStatuses.QUEUED.value
            }
            status={job.status || ModuleVables.SimulationStatuses.READY.value}
          />
        </div>
        <h5>{label}</h5>
        <div style={{ width: 'min-content', textAlign: 'center' }}>
          {job.dataArray && (
            <StyledButton
              className={classes.toolBtn}
              min
              onClick={() =>
                history.push({
                  pathname: Routes.SCENARIO(job.branch, routePathsCommon.ANALYZE),
                  search: `?job=${job.id}${share ? `&share=${share}` : ''}`,
                })
              }
              disabled={!job.dataArray}
              dontDisableInReadOnly
              replaceSpinner
            >
              Analyze
            </StyledButton>
          )}
          {canSimulate && running && (
            <StyledButton
              className={classes.toolBtn}
              min
              onClick={abortSimulation}
              replaceSpinner
              framed
              error
            >
              Abort
            </StyledButton>
          )}
        </div>
      </div>
      <div className={classes.simDesriptors}>
        <p>Job ID: {<ClipboardCopy text={job.id} />}</p>
        <p>Initiated: {job.dateCreated.local().format('M/DD/YY h:mma')}</p>
        {jobCreator && (
          <p>
            {jobCreator.firstName} {jobCreator.lastName}
          </p>
        )}
        {performance && (
          <p>
            <strong>Performance</strong>: {precision(performance)}x real time
          </p>
        )}
      </div>
      {(job.status === ModuleVables.CompletedStatuses.ERROR.value ||
        job.status === ModuleVables.CompletedStatuses.FAILED.value) &&
        job.message && (
          <AlertCard message={`Error: ${job.message.split('\n')[0]}`} variant="error" />
        )}
      {job.status === ModuleVables.SimulationStatuses.QUEUED.value && (
        <AlertCard
          message="This simulation is queued due to insufficient workspace capacity."
          details={`Other simulations in your organization's workspace have exhausted the workspace's capacity. This job will begin when sufficient capacity is freed. To increase capacity, please contact ${SALES_EMAIL}.`}
          variant="schedule"
        />
      )}
    </>
  );
};

const SimulationControls = () => {
  const [badBranchIds, setBadBranchIds] = useState<string[]>([]);
  const { share } = getSearchParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const canSimulate = usePermissionCheck(WorkspaceVables.Permission.RUN_SIMULATION);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [simAnchor, setSimAnchor] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    Job: {
      actions: { createJob },
    },
  } = SatelliteApi;

  const {
    branch: { id },
  } = useContext(ActiveBranchContext);

  const simulateOnClick = useCallback(
    (redirect?: boolean) => {
      setLoading(true);
      // Launch job
      dispatch(
        createJob({
          branchId: id,
          successCallback: async (response: IJob) => {
            dispatch(setLatestJobId(response.id));
            setLoading(false);
            ReactGA.event(gaEvents.SIM_START, {
              category: 'Simulation',
              action: 'Start Simulation',
              label: 'Start Simulation Button',
            });
            redirect &&
              history.push({
                pathname: Routes.SCENARIO(response.branch, routePathsCommon.ANALYZE),
                search: `?job=${response.id}`,
              });
          },
          failureCallback: (response: IErrorResponse & { badBranchIds: string[] }) => {
            if (response?.badBranchIds) {
              setBadBranchIds(response.badBranchIds);
            }
            enqueueSnackbar(
              response?.error?.message ||
                `Error creating job. Please check that your scenario and associated agent templates are
              fully populated, or contact us at support@sedarotech.com if this issue persists.`
            );
            setLoading(false);
          },
        })
      );
    },
    [createJob, dispatch, enqueueSnackbar, history, id]
  );

  useHotkeys(
    hotkeys.SIMULATE.keys,
    () => {
      if (canSimulate && !loading) simulateOnClick(false);
    },
    [simulateOnClick, setLoading, isJobRunning]
  );

  const jobStore = useSelectAll('Job');
  const jobsSorted = useMemo(() => {
    return jobStore
      .filter((job) => job.branch === id)
      .sort((a, b) => b.dateCreated.diff(a.dateCreated));
  }, [jobStore, id]);
  const jobsRunning = useMemo(() => jobsSorted.filter((job) => isJobRunning(job)), [jobsSorted]);

  const [numRunning, numQueued] = useMemo(() => {
    let numRunning = 0;
    let numQueued = 0;
    jobsRunning.forEach((job) => {
      if (job.status === ModuleVables.SimulationStatuses.RUNNING.value) numRunning++;
      else numQueued++;
    });
    return [numRunning, numQueued];
  }, [jobsRunning]);

  const actionButtonProps = useCallback(
    (job) => ({
      onClick: () => {
        history.push({
          pathname: Routes.SCENARIO(job.branch, routePathsCommon.ANALYZE),
          search: `?job=${job.id}${share ? `&share=${share}` : ''}`,
        });
      },
      style: { margin: 8, minWidth: 100 },
    }),
    [history, share]
  );

  return (
    <>
      <div className="joyride-sim-controls">
        <div className={classes.root}>
          <div style={{ width: '50%' }}>
            {numRunning + numQueued > 0 ? (
              <>
                <h5>{`Running: ${numRunning}`}</h5>
                <h5>{`Queued: ${numQueued}`}</h5>
              </>
            ) : (
              <h5>No Simulations Running</h5>
            )}
          </div>
          <div className={classes.simControls}>
            <div style={{ display: 'inline-flex' }}>
              <StyledButton
                className={classes.simulateButton}
                min
                onClick={(e: MouseEvent) => simulateOnClick(true)}
                disabled={!canSimulate || loading}
                replaceSpinner
              >
                Simulate
              </StyledButton>
              <StyledButton
                className={classes.simulateDropdown}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  setSimAnchor(event.currentTarget)
                }
                disabled={!canSimulate || loading}
                min
              >
                <div style={{ fontSize: 14, height: 24.5 }}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </StyledButton>
              <Popover
                open={Boolean(simAnchor)}
                className={classes.popover}
                onClose={() => setSimAnchor(null)}
                anchorEl={simAnchor}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <StyledButton
                  min
                  onClick={() => {
                    setSimAnchor(null);
                    simulateOnClick(false);
                  }}
                >
                  Simulate and Edit
                </StyledButton>
              </Popover>
            </div>
            <StyledButton
              min
              framed
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setAnchorEl(event.currentTarget)
              }
              disabled={jobsSorted.length === 0}
              dontDisableInReadOnly
            >
              {jobsSorted.length === 0 ? 'No Jobs' : 'View Jobs'}
            </StyledButton>
          </div>
        </div>
        {Boolean(jobsSorted.length) && (
          <>
            <StyledDivider />
            <div>
              <h6>{jobsRunning.length ? 'Running Jobs' : 'Most Recent Job'}</h6>
              <div className={classes.runningJobs}>
                {jobsRunning.length ? (
                  jobsRunning.map((job, i) => (
                    <>
                      <JobControls key={job.id} job={job} />
                    </>
                  ))
                ) : (
                  <JobControls job={jobsSorted[0]} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <Popover
        className={classes.popover}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className={classes.jobMenu} onClick={() => setAnchorEl(null)}>
          <div className={classes.interiorWrapper} onClick={(e) => e.stopPropagation()}>
            <JobMenu actionButtonProps={actionButtonProps} />
          </div>
        </div>
      </Popover>
      <NotSimulateableDialog badBranchIds={badBranchIds} />
    </>
  );
};

export default SimulationControls;
