import useStyles from './styles';

import { IconDefinition, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledButton from 'components/general/StyledButton';
import { ReactNode } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import theme from 'theme';

export interface IIconProps {
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
}

export interface IButtonProps extends IIconProps {
  text?: string;
  icon?: IconDefinition;
  framed?: boolean;
}

interface IProps {
  name?: string | ReactElement;
  description?: string | (string | ReactElement)[];
  rowsTitle?: string | ReactElement;
  buttonsProps?: IButtonProps[];
  backButtonProps?: IButtonProps;
  smallTitle?: boolean;
  children: ReactNode;
}

const RowedExplorer = (props: IProps) => {
  const {
    name,
    description,
    rowsTitle,
    buttonsProps = [],
    backButtonProps,
    smallTitle,
    children,
  } = props;

  const classes = useStyles();

  const {
    text: backBtnTxt,
    onClick: backBtnOnClick,
    tooltip: backBtnTooltip,
    icon: backBtnIcon,
  } = backButtonProps || {};

  return (
    <div className={classes.branchExplorerRoot}>
      <div className={classes.headerContainer}>
        {name && (
          <div className={classes.info}>
            <h1>{name}</h1>
            {description && <p>{description}</p>}
          </div>
        )}
        {backBtnOnClick && (
          <StyledButton onClick={backBtnOnClick} tooltip={backBtnTooltip}>
            {backBtnTxt}
            {backBtnIcon && <FontAwesomeIcon icon={backBtnIcon} />}
          </StyledButton>
        )}
      </div>
      <div className={classes.title}>
        <h1
          style={{
            fontSize: name || smallTitle ? undefined : Number(theme.typography.h1.fontSize) * 1.15,
          }}
        >
          {rowsTitle}
        </h1>
        <div className={classes.primaryAndSecondaryButtonContainer}>
          {buttonsProps.map((props) => {
            if (!props) return null;
            const { text, onClick, tooltip, icon, framed } = props;
            return (
              <StyledButton onClick={onClick} tooltip={tooltip} key={text} framed={framed}>
                {text}
                {icon && <FontAwesomeIcon icon={icon} spin={icon === faSpinner} />}
              </StyledButton>
            );
          })}
        </div>
      </div>
      {children}
    </div>
  );
};

export default RowedExplorer;
