import LoadingView from 'components/LoadingView';
import Nav from 'components/general/Nav';
import AnalysisControls from 'components/general/SimulationControls/AnalysisControls';
import StyledButton from 'components/general/StyledButton';
import StyledDivider from 'components/general/StyledDivider';
import ViewContainer from 'components/general/ViewContainer';
import ViewPort from 'components/general/ViewPort';
import { useActiveEntities, useLatestJob } from 'hooks';
import mdLogo from 'multimedia/icons/md.png';
import { JoyrideContext } from 'providers';
import AnalyticsProvider from 'providers/AnalyticsProvider';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Routes, { getSearchParams, routePathsCommon } from 'routes';
import AnalyzeBoards from './AnalyzeBoards';
import AnalyzeInlay from './AnalyzeInlay';

const ScenarioAnalyzeView = () => {
  const joyride = useContext(JoyrideContext);
  const [, targetJob] = useLatestJob();
  const { branch } = useActiveEntities();
  const history = useHistory();
  const { share } = getSearchParams();

  joyride.setLoading(!branch.model?.ready || !targetJob);
  if (!branch.model?.ready || !targetJob) return <LoadingView message="Sedaro is loading..." />;

  return (
    <ViewContainer>
      <Nav
        contextName="Scenario - Analyze"
        contextImg={mdLogo}
        control={
          <div className="joyride-analysis-controls">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                marginTop: 6.5,
              }}
            >
              <StyledButton
                style={{ width: 200, height: 24 }}
                onClick={() =>
                  history.push({
                    pathname: Routes.SCENARIO(branch.id, routePathsCommon.EDIT),
                    search: share ? `?share=${share}` : '',
                  })
                }
                dontDisableInReadOnly
              >
                Edit Scenario
              </StyledButton>
            </div>
            <StyledDivider />
            <AnalysisControls />
          </div>
        }
      ></Nav>
      <ViewPort minWidth={650}>
        <AnalyticsProvider>
          {
            // If data is here, show analyze boards
            // Don't unmount when moving to edit board to minimize cesium latency
            targetJob?.analyzeState?.dataState ? (
              <AnalyzeBoards />
            ) : (
              // Otherwise, show placeholders
              <AnalyzeInlay />
            )
          }
        </AnalyticsProvider>
      </ViewPort>
    </ViewContainer>
  );
};

export default ScenarioAnalyzeView;
