import { ISelectOption } from 'components/general/types';
import { Moment } from 'moment';
import { TMetamodelTypes } from 'utils/repoAndMmType';
import { AgentVables, OrbitVables } from 'utils/vable';
import * as Yup from 'yup';

const validation = {
  kinematics: Yup.object()
    .when(['type', 'templateBranch'], {
      // Spacecraft
      is: (
        type: ISelectOption,
        templateBranch: ISelectOption & { metamodelType: TMetamodelTypes }
      ) =>
        (type?.value === AgentVables.AgentType.TemplatedAgent.value &&
          templateBranch?.metamodelType !== 'TerrestrialVehicle') ||
        type?.value === AgentVables.AgentType.PeripheralSpacePoint.value,
      then: Yup.object({
        initialStateDefParams: Yup.object()
          // Position and velocity
          .when('initialStateDefType', {
            is: (initialStateDefType: ISelectOption) =>
              initialStateDefType?.value === OrbitVables.InitialStateDefType.ECI_STATE.value,
            then: Yup.object({
              stateEci: Yup.array()
                .of(
                  Yup.number().required('Complete 3-dimensional position and velocity is required.')
                )
                .length(6),
            }),
          })

          // Orbital elements
          .when('initialStateDefType', {
            is: (initialStateDefType: ISelectOption) =>
              initialStateDefType?.value === OrbitVables.InitialStateDefType.ORBITAL_ELEMENTS.value,
            then: Yup.object({
              a: Yup.number()
                .required('Semimajor axis is required.')
                .min(6478.1359999999995, 'Semimajor axis must be at least 6478.136km'),
              inc: Yup.number()
                .required('Inclination is required.')
                .min(0, 'Inclination must be between 0 and 180, inclusive.')
                .max(180, 'Inclination must be between 0 and 180, inclusive.'),
              e: Yup.number()
                .required('Eccentricity is required.')
                .min(0, 'Eccentricity must be at least 0.')
                .lessThan(1, 'Eccentricity must be less than 1.'),
              raan: Yup.number()
                .required('RAAN is required.')
                .min(0, 'RAAN must be between 0 and 360°, inclusive.')
                .max(360, 'RAAN must be between 0 and 360°, inclusive.'),
              om: Yup.number().when('inc', {
                is: (inc: number) => inc === 0,
                then: Yup.number()
                  .required('True longitude of perigee is required when inclination is zero.')
                  .min(0, 'True longitude of perigee must be between 0 and 360°, inclusive.')
                  .max(360, 'True longitude of perigee must be between 0 and 360°, inclusive.'),
                otherwise: Yup.number()
                  .required('Argument of perigee is required when inclination is non-zero.')
                  .min(0, 'Argument of perigee must be between 0 and 360°, inclusive.')
                  .max(360, 'Argument of perigee must be between 0 and 360°, inclusive.'),
              }),
              nu: Yup.number().when('e', {
                is: (e: number) => e > 0,
                then: Yup.number()
                  .required('True anomoly is required.')
                  .min(0, 'True anomoly must be between 0 and 360°, inclusive.')
                  .max(360, 'True anomoly must be between 0 and 360°, inclusive.'),
                otherwise: Yup.number().when('inc', {
                  is: (inc: number) => inc !== 0,
                  then: Yup.number()
                    .required('Argument of latitude is required.')
                    .min(0, 'Argument of latitude must be between 0 and 360°, inclusive.')
                    .max(360, 'Argument of latitude must be between 0 and 360°, inclusive.'),
                  otherwise: Yup.number()
                    .required('True longitude is required.')
                    .min(0, 'True longitude must be between 0 and 360°, inclusive.')
                    .max(360, 'True longitude must be between 0 and 360°, inclusive.'),
                }),
              }),
            }),
          })

          // Reference orbit
          .when('initialStateDefType', {
            is: (initialStateDefType: ISelectOption) =>
              initialStateDefType?.value === OrbitVables.InitialStateDefType.REF_ORBIT.value,
            then: Yup.object({
              initialRefOrbit: Yup.object().required('Select a reference orbit type.'),

              // Altitude: polar circular, equatorial circular, sun-sync circular
              alt: Yup.number().when('initialRefOrbit', {
                is: (initialRefOrbit: ISelectOption) =>
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.POLAR_CIRC.value ||
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.EQUATORIAL_CIRC.value ||
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.SUN_SYNC_CIRC.value,
                then: Yup.number()
                  .required('Altitude is required.')
                  .min(100, 'Altitude must be at least 100km.'),
              }),
              // RAAN: polar circular, ISS
              raan: Yup.number().when('initialRefOrbit', {
                is: (initialRefOrbit: ISelectOption) =>
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.POLAR_CIRC.value ||
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.ISS.value,
                then: Yup.number()
                  .required('RAAN is required.')
                  .min(0, 'RAAN must be between 0 and 360°, inclusive.')
                  .max(360, 'RAAN must be between 0 and 360°, inclusive.'),
              }),
              // Argument of latitude: polar circular
              nu: Yup.number().when('initialRefOrbit', {
                is: (initialRefOrbit: ISelectOption) =>
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.POLAR_CIRC.value,
                then: Yup.number()
                  .required('Argument of latitude is required.')
                  .min(0, 'Argument of latitude must be between 0 and 360°, inclusive.')
                  .max(360, 'Argument of latitude must be between 0 and 360°, inclusive.'),
                // True longitude: equatorial circular
                otherwise: Yup.number().when('initialRefOrbit', {
                  is: (initialRefOrbit: ISelectOption) =>
                    initialRefOrbit?.value === OrbitVables.InitialRefOrbit.EQUATORIAL_CIRC.value,
                  then: Yup.number()
                    .required('True longitude is required.')
                    .min(0, 'True longitude must be between 0 and 360°, inclusive.')
                    .max(360, 'True longitude must be between 0 and 360°, inclusive.'),
                  // True anomoly: syn-sync circular, geostationary transfer, ISS
                  otherwise: Yup.number().when('initialRefOrbit', {
                    is: (initialRefOrbit: ISelectOption) =>
                      initialRefOrbit?.value === OrbitVables.InitialRefOrbit.SUN_SYNC_CIRC.value ||
                      initialRefOrbit?.value ===
                        OrbitVables.InitialRefOrbit.GEOSTAT_TRANSFER.value ||
                      initialRefOrbit?.value === OrbitVables.InitialRefOrbit.ISS.value,
                    then: Yup.number()
                      .required('True anomoly is required.')
                      .min(0, 'True anomoly must be between 0 and 360°, inclusive.')
                      .max(360, 'True anomoly must be between 0 and 360°, inclusive.'),
                  }),
                }),
              }),
              // Longitude: goestationary
              lon: Yup.number().when('initialRefOrbit', {
                is: (initialRefOrbit: ISelectOption) =>
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.GEOSTAT.value,
                then: Yup.number()
                  .required('Longitude is required.')
                  .min(-180, 'Longitude must be between -180 and 180°, inclusive.')
                  .max(180, 'Longitude must be between -180 and 180°, inclusive.'),
              }),
              // Perigee altitude: goestationary transfer
              altPerigee: Yup.number().when('initialRefOrbit', {
                is: (initialRefOrbit: ISelectOption) =>
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.GEOSTAT_TRANSFER.value,
                then: Yup.number()
                  .required('Perigee altitude is required.')
                  .min(100, 'Perigee altitude must be at least 100km.'),
              }),
              // True longitude of perigee: goestationary transfer
              om: Yup.number().when('initialRefOrbit', {
                is: (initialRefOrbit: ISelectOption) =>
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.GEOSTAT_TRANSFER.value,
                then: Yup.number()
                  .required('True longitude of perigee is required.')
                  .min(0, 'True longitude of perigee must be between 0 and 360°, inclusive.')
                  .max(360, 'True longitude of perigee must be between 0 and 360°, inclusive.'),
              }),
              // Mean local time at the ascending node: sun-sync circular
              mltAscNode: Yup.mixed().when('initialRefOrbit', {
                is: (initialRefOrbit: ISelectOption) =>
                  initialRefOrbit?.value === OrbitVables.InitialRefOrbit.SUN_SYNC_CIRC.value,
                then: Yup.mixed()
                  .test(
                    'test-moment',
                    'Select or enter a valid mean local time.',
                    (val: string | Moment) => {
                      if (!val) return false;
                      const dateRegex = /^(\d{2}:\d{2}:\d{2})$/;
                      if (typeof val === 'string') return dateRegex.test(val);
                      else return val.isValid();
                    }
                  )
                  .required('Select a mean local time at the ascending node.'),
              }),
            }),
          })

          // TLE
          .when('initialStateDefType', {
            is: (initialStateDefType: ISelectOption) =>
              initialStateDefType?.value === OrbitVables.InitialStateDefType.TLE.value,
            then: Yup.object({
              tle: Yup.string().ensure().required('Enter a two-line element definition.'),
            }),
          }),
      }),
    })
    .when(['type', 'templateBranch'], {
      // Terrestrial vehicles
      is: (
        type: ISelectOption,
        templateBranch: ISelectOption & { metamodelType: TMetamodelTypes }
      ) =>
        type?.value === AgentVables.AgentType.TemplatedAgent.value &&
        templateBranch?.metamodelType === 'TerrestrialVehicle',
      then: Yup.object({
        type: Yup.object().required('Select a waypoint path type.'),
      }),
    }),
};

export default validation;
