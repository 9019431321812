import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import StyledCheckbox from 'components/general/inputs/StyledCheckbox';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import theme from 'theme';
import useStyles from './styles';

// Stats operations
const opTypes = {
  '': { label: 'None', value: '' },
  avg: { label: 'Average', value: 'avg' },
  absAvg: { label: 'Absolute Average', value: 'absAvg' },
  integral: { label: 'Integral', value: 'integral' },
  max: { label: 'Maximum', value: 'max' },
  min: { label: 'Minimum', value: 'min' },
  negativeMax: { label: 'Negative Maximum', value: 'negativeMaximum' },
  positiveMax: { label: 'Positive Maximum', value: 'positiveMax' },
};

const PlotForm = ({ selectedPlot, selectedWidget, setDirtyWidgets, chartTypes }) => {
  const classes = useStyles();
  const plotType = selectedWidget.plots[selectedPlot].type;

  // Create lists of available fields based on plot type
  const [plotFields, variableFields, seriesFields] = useMemo(() => {
    const plotFields = ['type', 'name', 'each', 'variables'];
    const variableFields = ['each'];
    const seriesFields = ['legend', 'keys'];
    switch (plotType) {
      case 'line':
        plotFields.push('label', 'unit', 'step', 'labelRight', 'unitRight');
        variableFields.push('right');
        break;
      case 'bool':
        plotFields.push('labelRight', 'unitRight');
        variableFields.push('right');
        break;
      case 'pie':
        plotFields.push('unit');
        seriesFields.push('ops');
        break;
      case 'margin':
        plotFields.push('unit');
        seriesFields.push('ops', 'variables');
        break;
      case 'horizontal-bar':
        plotFields.push('unit');
        seriesFields.push('ops', 'variables');
        break;
      case 'table':
        plotFields.push('unit');
        variableFields.push('unit');
        seriesFields.push('ops', 'variables');
        break;
      case 'conops':
        plotFields.splice(2, 2);
        break;
      default:
        break;
    }
    // update the current plot's fields to match the new type
    const newPlot = {
      ...plotFields.reduce((acc, field) => {
        if (field === 'variables') {
          acc[field] =
            selectedWidget.plots[selectedPlot].variables?.map((variable) => {
              return {
                ...variableFields.reduce((acc, field) => {
                  acc[field] = variable[field] || null;
                  return acc;
                }, {}),
                ...seriesFields.reduce((acc, field) => {
                  acc[field] = variable[field] || [];
                  return acc;
                }, {}),
              };
            }) || [];
        } else {
          acc[field] = selectedWidget.plots[selectedPlot][field] || null;
        }
        return acc;
      }, {}),
    };
    selectedWidget.plots[selectedPlot] = newPlot;
    setDirtyWidgets((d) => [...d.filter((item) => item.id !== selectedWidget.id), selectedWidget]);
    return [plotFields, variableFields, seriesFields];
  }, [plotType, selectedPlot, selectedWidget, setDirtyWidgets]);

  // Create the input component for a given field
  const getFieldInput = useCallback(
    (field, target = selectedWidget.plots[selectedPlot], key = field) => {
      if (field === 'type') {
        return (
          <LabeledSelect
            label={'Chart Type'}
            value={chartTypes[target[key]]}
            onChange={(v) => {
              target[key] = v.value;
              setDirtyWidgets((d) => [
                ...d.filter((item) => item.id !== selectedWidget.id),
                selectedWidget,
              ]);
            }}
            options={Object.values(chartTypes)}
          />
        );
      }
      if (field === 'ops')
        return (
          <LabeledSelect
            label={'Operation'}
            value={opTypes[target[key]]}
            onChange={(v) => {
              target[key] = v.value;
              setDirtyWidgets((d) => [
                ...d.filter((item) => item.id !== selectedWidget.id),
                selectedWidget,
              ]);
            }}
            options={Object.values(opTypes)}
          />
        );
      else {
        return (
          <LabeledInput
            label={
              field.charAt(0).toUpperCase() +
              (field.slice(-1) === 's'
                ? field
                    .split(/(?=[A-Z])/)
                    .join(' - ')
                    .slice(1, -1)
                : field
                    .split(/(?=[A-Z])/)
                    .join(' - ')
                    .slice(1))
            }
            value={target[key] || ''}
            onChange={(e) => {
              target[key] = e.target.value;
              setDirtyWidgets((d) => [
                ...d.filter((item) => item.id !== selectedWidget.id),
                selectedWidget,
              ]);
            }}
          />
        );
      }
    },
    [selectedWidget, setDirtyWidgets, selectedPlot, chartTypes]
  );

  // Delete button to use for variable/series badges
  const deleteButton = useCallback(
    (onDelete) => (
      <IconButton
        style={{
          marginRight: 20,
          marginTop: 20,
          width: 20,
          height: 20,
          backgroundColor: theme.palette.error.main,
        }}
        onClick={() => {
          onDelete && onDelete();
          setDirtyWidgets((d) => [
            ...d.filter((item) => item.id !== selectedWidget.id),
            selectedWidget,
          ]);
        }}
      >
        <FontAwesomeIcon
          style={{
            width: 16,
            height: 16,
            color: theme.palette.background.contrastText,
          }}
          icon={faXmark}
        />
      </IconButton>
    ),
    [selectedWidget, setDirtyWidgets]
  );

  // Create the input component for a sub-variable given a list of sub-variables and the index of the sub-variable
  const subVariableInput = useCallback(
    (subVariables, index) => {
      if (!subVariables[index]) {
        subVariables[index] = seriesFields.reduce(
          (acc, field) => {
            acc[field] = [''];
            return acc;
          },
          variableFields.reduce((acc, field) => {
            acc[field] = null;
            return acc;
          }, {})
        );
      }
      const subVariable = subVariables[index];
      return (
        <Badge style={{ width: '100%' }}>
          <div className={classes.subVariableInput}>
            <h6>Sub-Variable</h6>
            {variableFields.map((subField) => {
              if (subField.toLowerCase().includes('right') || subField === 'variables')
                return <></>;
              return (
                <div key={subField} style={{ width: '90%' }}>
                  {getFieldInput(subField, subVariable)}
                </div>
              );
            })}
            <h6>
              Add Series
              <IconButton
                style={{ marginLeft: 8, width: 24, height: 24 }}
                onClick={() => {
                  seriesFields.forEach((field) => {
                    if (field !== 'variables')
                      subVariable[field]
                        ? subVariable[field].push('')
                        : (subVariable[field] = ['']);
                  });
                  setDirtyWidgets((d) => [
                    ...d.filter((item) => item.id !== selectedWidget.id),
                    selectedWidget,
                  ]);
                }}
              >
                <FontAwesomeIcon
                  style={{
                    width: 24,
                    height: 24,
                    color: theme.palette.background.contrastText,
                  }}
                  icon={faPlusSquare}
                />
              </IconButton>
            </h6>
            <div style={{ width: '80%' }}>
              {_.range(
                Math.max(...seriesFields.map((field) => subVariable[field]?.length || 0))
              ).map((_, subSeriesIndex) => {
                return (
                  <Badge
                    badgeContent={deleteButton(() =>
                      seriesFields.forEach((field) => {
                        subVariable[field] && subVariable[field].splice(subSeriesIndex, 1);
                      })
                    )}
                    key={subSeriesIndex}
                    className={classes.variableInputs}
                  >
                    {seriesFields.map((subField) => {
                      if (subField.toLowerCase().includes('right') || subField === 'variables')
                        return <></>;
                      if (subVariable[subField] === null) subVariable[subField] = [];
                      return (
                        <div key={subField} style={{ width: '100%' }}>
                          {getFieldInput(subField, subVariable[subField], subSeriesIndex)}
                        </div>
                      );
                    })}
                  </Badge>
                );
              })}
            </div>
          </div>
        </Badge>
      );
    },
    [
      deleteButton,
      getFieldInput,
      seriesFields,
      variableFields,
      selectedWidget,
      setDirtyWidgets,
      classes.variableInputs,
      classes.subVariableInput,
    ]
  );

  // Create the input component for a series given a variable and the index of the series. Use noDelete to hide the delete button for right axis variables
  const seriesInput = useCallback(
    (variable, seriesIndex, noDelete = false) => {
      return (
        <Badge
          badgeContent={
            !noDelete &&
            deleteButton(() =>
              seriesFields.forEach((field) => {
                variable[field].splice(seriesIndex, 1);
              })
            )
          }
          key={seriesIndex}
          className={classes.variableInputs}
        >
          {seriesFields.map((field) => {
            if (field.toLowerCase().includes('right')) return <></>;
            if (variable[field] === null) variable[field] = [];
            if (field === 'variables') {
              return subVariableInput(variable[field], seriesIndex);
            }
            return (
              <div key={field} style={{ width: '100%' }}>
                {getFieldInput(field, variable[field], seriesIndex)}
              </div>
            );
          })}
        </Badge>
      );
    },
    [deleteButton, getFieldInput, seriesFields, subVariableInput, classes.variableInputs]
  );

  // Create the input component for variable given its index
  const variableInput = useCallback(
    (variableIndex) => {
      const variable = selectedWidget.plots[selectedPlot].variables[variableIndex];
      const numSeries = Math.max(...seriesFields.map((field) => variable[field]?.length || 0));
      return (
        <Badge
          badgeContent={deleteButton(() =>
            selectedWidget.plots[selectedPlot].variables.splice(variableIndex, 1)
          )}
          key={variableIndex}
          className={classes.variable}
        >
          <h6>{`Variable ${variableIndex + 1}`}</h6>
          {variableFields.map((field) => {
            if (field.toLowerCase().includes('right') || field === 'variables') return <></>;
            return (
              <div key={field} style={{ width: '90%' }}>
                {getFieldInput(field, variable)}
              </div>
            );
          })}
          <h6>
            Add Series
            <IconButton
              style={{ marginLeft: 8, width: 24, height: 24 }}
              onClick={() => {
                seriesFields.forEach((field) => {
                  if (field === 'variables') {
                    variable[field].push(
                      seriesFields.reduce(
                        (acc, field) => {
                          acc[field] = [''];
                          return acc;
                        },
                        variableFields.reduce((acc, field) => {
                          acc[field] = null;
                          return acc;
                        }, {})
                      )
                    );
                  } else {
                    variable[field].push('');
                  }
                });
                setDirtyWidgets((d) => [
                  ...d.filter((item) => item.id !== selectedWidget.id),
                  selectedWidget,
                ]);
              }}
            >
              <FontAwesomeIcon
                style={{
                  width: 24,
                  height: 24,
                  color: theme.palette.background.contrastText,
                }}
                icon={faPlusSquare}
              />
            </IconButton>
          </h6>
          {numSeries > 0 && (
            <div style={{ width: '90%' }}>
              {_.range(numSeries).map((_, seriesIndex) => {
                return seriesInput(variable, seriesIndex);
              })}
            </div>
          )}
        </Badge>
      );
    },
    [
      selectedPlot,
      deleteButton,
      getFieldInput,
      selectedWidget,
      seriesFields,
      seriesInput,
      setDirtyWidgets,
      variableFields,
      classes.variable,
    ]
  );

  return (
    <>
      {
        // Create inputs for all general plot fields
        plotFields.map((field, i) => {
          if (field.toLowerCase().includes('right') || field === 'variables') return <></>;
          return (
            <div key={field} style={{ width: '100%' }}>
              {getFieldInput(field)}
            </div>
          );
        })
      }

      {
        // Create inputs for all variables
        selectedWidget.plots[selectedPlot].variables && (
          <>
            <h3>Variables</h3>
            {selectedWidget.plots[selectedPlot].variables
              .filter((variable) => !variable.right)
              .map((_, variableIndex) => variableInput(variableIndex))}
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  width: '45%',
                  marginLeft: 50,
                  height: 1,
                  borderTop: `2px solid ${theme.palette.text.secondary}`,
                }}
              />
              <div
                style={{
                  width: '10%',
                  display: 'inline-flex',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Add Variable">
                  <IconButton
                    style={{ width: 36, height: 36 }}
                    onClick={() => {
                      selectedWidget.plots[selectedPlot].variables.push({
                        ...variableFields.reduce((acc, field) => {
                          acc[field] = null;
                          return acc;
                        }, {}),
                        ...seriesFields.reduce((acc, field) => {
                          acc[field] = [];
                          return acc;
                        }, {}),
                      });
                      setDirtyWidgets((d) => [
                        ...d.filter((item) => item.id !== selectedWidget.id),
                        selectedWidget,
                      ]);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        width: 36,
                        height: 36,
                        color: theme.palette.background.contrastText,
                      }}
                      icon={faPlusSquare}
                    />
                  </IconButton>
                </Tooltip>
              </div>
              <div
                style={{
                  width: '45%',
                  marginRight: 50,
                  height: 1,
                  borderTop: `2px solid ${theme.palette.text.secondary}`,
                }}
              />
            </div>
            {
              // Toggle using the right axis if the plot type supports it
              variableFields.includes('right') && (
                <h3 style={{ paddingTop: 10 }}>
                  <StyledCheckbox
                    onChange={(event) => {
                      if (!event.target.checked) {
                        selectedWidget.plots[selectedPlot].variables = selectedWidget.plots[
                          selectedPlot
                        ].variables.filter((x) => !x.right);
                        plotFields
                          .filter((field) => field.toLowerCase().includes('right'))
                          .forEach((field) => {
                            selectedWidget.plots[selectedPlot][field] = null;
                          });
                        setDirtyWidgets((d) => [
                          ...d.filter((item) => item.id !== selectedWidget.id),
                          selectedWidget,
                        ]);
                      } else {
                        selectedWidget.plots[selectedPlot].variables.push(
                          seriesFields.reduce(
                            (acc, field) => {
                              acc[field] = [''];
                              return acc;
                            },
                            variableFields.reduce((acc, field) => {
                              if (field !== 'right') {
                                acc[field] = null;
                              } else {
                                acc[field] = true;
                              }
                              return acc;
                            }, {})
                          )
                        );
                        setDirtyWidgets((d) => [
                          ...d.filter((item) => item.id !== selectedWidget.id),
                          selectedWidget,
                        ]);
                      }
                    }}
                    checked={selectedWidget.plots[selectedPlot].variables.some((x) => x.right)}
                  />
                  Use Right Axis?
                </h3>
              )
            }
            {
              // Create inputs for the right axis if it is being used
              selectedWidget.plots[selectedPlot].variables.some((x) => x.right) && (
                <>
                  {plotFields
                    .filter((field) => field.toLowerCase().includes('right'))
                    .map((field) => getFieldInput(field))}
                  <div style={{ width: '100%' }}>
                    {seriesInput(
                      selectedWidget.plots[selectedPlot].variables.find((x) => x.right),
                      0,
                      true
                    )}
                  </div>
                </>
              )
            }
          </>
        )
      }
    </>
  );
};

export default PlotForm;
