import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from 'components/general/dialogs/Dialog';
import { TDialogConfig } from 'components/general/types';
import { gaEvents } from 'config';
import { useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import ReactGA from 'react-ga4';
import ContactIcons from '../SupportDialog/ContactIcons';

interface IProps {
  config: TDialogConfig<unknown>;
  onClose: () => void;
  title?: string;
}

const FallbackDialog = (props: IProps) => {
  const {
    config: { open },
    onClose,
    title,
  } = props;

  useEffect(() => {
    if (open)
      ReactGA.event(gaEvents.EXCEPTION, {
        category: 'Forms',
        action: 'Throw Form Exception',
        label: 'Thrown Exception',
      });
  }, [open]);

  const { resetBoundary } = useErrorBoundary();
  return (
    <Dialog
      title={
        <>
          <FontAwesomeIcon icon={faWarning} /> {title || 'Something went wrong.'}
        </>
      }
      open={open}
      onClose={() => {
        if (onClose) {
          onClose();
        }
        resetBoundary();
      }}
    >
      <p>
        Please refresh the page and try again. If the problem persists, call or email us using the
        links below. Our team will get back to you within 24 hours.
      </p>
      <ContactIcons />
    </Dialog>
  );
};

export default FallbackDialog;
