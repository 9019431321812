import { IUser } from 'components/general/types/general';
import { useSelectById } from './stateHooks';
import { useAppSelector } from './typedHooks';

const useUser = () => {
  const userId = useAppSelector((state) => state.ui.missionExplorer.userId);
  const user = useSelectById('User', userId || '') as IUser;
  return user;
};

export default useUser;
