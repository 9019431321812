import { ICondition } from 'components/general/types/cdh';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { ConditionVables, TConditionType } from 'utils/vable';
import ConditionDialog from '../ConditionDialog';
import BodyInFovConditionDialog from './BodyInFovConditionDialog';

interface IProps {
  control: TEntityDialogControl<ICondition>;
  type?: TConditionType;
}

/**
 * Switches between different condition dialogs based on the condition type.
 * A drop in for ConditionDialog.
 */
const ConditionDialogSwitch = (props: IProps) => {
  const {
    control: {
      dialogConfig: { entity: condition }, // During edit, use type from condition
    },
    type, // During create, type is passed in as a prop
  } = props;

  switch (condition?.type || type) {
    // Return different dialogs for different condition types
    case ConditionVables.Type.BodyInFovCondition.value:
      return <BodyInFovConditionDialog {...props} />;
    default:
      return <ConditionDialog {...props} />;
  }
};

export default ConditionDialogSwitch;
